import * as React from "react";
// import dayjs, { Dayjs } from "dayjs";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import frLocale from "date-fns/locale/fr";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { frFR } from "@mui/x-date-pickers";

const localeMap = {
  fr: frLocale,
};

export function DatePickerValue(props: any) {
    const today = new Date(); // Obtenez la date d'aujourd'hui
    const year = today.getFullYear(); // Année courante
    const month = today.getMonth(); // Mois courant (janvier = 0, décembre = 11)
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const [locale] = React.useState<keyof typeof localeMap>('fr');
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      dapterLocale={localeMap[locale]}
      localeText={
        frFR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MobileDatePicker
        minDate={props.minDate}
        maxDate={lastDayOfMonth}
        showToolbar={false}
        value={props.value}
        inputFormat="dd/MM/yyyy"
        onChange={(val) => props.callBack(val)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Ex : 24/04/2022"
            sx={{
              width: "100%",
              padding: "-2rem",

              "& .MuiOutlinedInput-root": {
                fontSize: `${props.size}px`,
                fontFamily: "nunito",
              },
              "& fieldset": {
                border: "none",
                textDecoration: "black",
              },
            }}
          />
        )}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
export function EndDatePickerValue(props: any) {
    const today = new Date(); // Obtenez la date d'aujourd'hui
    const year = today.getFullYear(); // Année courante
    const month = today.getMonth(); // Mois courant (janvier = 0, décembre = 11)
    const lastDayOfMonth = new Date(year, month + 1, 0);
    const [locale] = React.useState<keyof typeof localeMap>('fr');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      dapterLocale={localeMap[locale]}
      localeText={
        frFR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <MobileDatePicker
        minDate={props.minDate}
        maxDate={lastDayOfMonth}
        showToolbar={false}
        value={props.value}
        inputFormat="dd/MM/yyyy"
        onChange={(val) => props.callBack(val)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Ex : 24/04/2022"
            sx={{
              width: "100%",
              padding: "-2rem",

              "& .MuiOutlinedInput-root": {
                fontSize: `${props.size}px`,
                fontFamily: "nunito",
              },
              "& fieldset": {
                border: "none",
                textDecoration: "black",
              },
            }}
          />
        )}
      />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  );
}
