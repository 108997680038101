import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import SubjectRepository from '../../Repositories/SubjectRepository';
import { Subject } from '../../Objects/models/Subject';

export default function SubjectsSelects(props: any) {

  const [selectedSubject, setSelectedSubject] = useState<Subject | null>(props.value || null);
  const [open, setOpen] = useState(false);
  const [subject, setSubject] = useState<any>();
  const [options, setOptions] = useState<Subject[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (props.matieres  !== undefined ) {
      if (props.matieres !== selectedSubject) {
        setSelectedSubject(selectedSubject);
        props.callBack(selectedSubject);
        if (selectedSubject === null) {
          setSelectedSubject(props.matieres);
          props.callBack(props.matieres);
        } 
      }else {
        setSelectedSubject(props.matieres);
        props.callBack(props.matieres);
      }
    }
  }, [props.matieres]);
  
  useEffect(() => {
    let active = true;

    (async () => {
      const response = await SubjectRepository.getSubjects()
      if (active) {
        setOptions(response.members);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);
  return (
    <div
      className={
        " text-[#808080] relative rounded-lg font-nunito" + props.customClass
      }
    >
      <Autocomplete
        sx={{ "& .MuiOutlinedInput-root": { fontFamily: "nunito" } }}
        id="dubjects-select"
        fullWidth
        open={open}
        classes={{}}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        multiple={false}
        clearIcon=""
        ///limitTags={1}
        noOptionsText={
          subject === "Maths" || subject === "maths" ? (
            <span className="font-nunito lg:text-lg md:text-md text-sm">
              Écrivez plutôt Mathématiques dans la barre de recherche 😊
            </span>
          ) : (
            <span className="font-nunito lg:text-lg md:text-md text-sm">
              Cette matière n'est pas encore disponible. Veuillez sélectionner
              uniquement une seule matière de la liste.
            </span>
          )
        }
        // onChange={(value, getTagProps) => {props.callBack(getTagProps)}}
        value={selectedSubject} // Utilise l'état pour contrôler la valeur
        onChange={(event, newValue) => {
          if (newValue !== selectedSubject) {
            setSelectedSubject(newValue);
            props.callBack(newValue); // Si nécessaire, invoquez le callback avec la nouvelle valeur
          } else {
            setSelectedSubject(newValue);
            props.callBack(newValue); // Si nécessaire, invoquez le callback avec la nouvelle valeur
          }
        }}
        isOptionEqualToValue={(option, value) => option?.fr_FR === value?.fr_FR}
        getOptionLabel={(option) => option.fr_FR}
        options={options}
        loading={loading}
        loadingText="chargement ..."
        // defaultValue={props.value}
        popupIcon=""
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <span
              key={index}
              className={
                "font-nunito lg:text-lg md:text-md text-sm " + props.customEdit
              }
            >
              {option.fr_FR}{" "}
            </span>
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            onChange={(e) => setSubject(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: <span className=""></span>,
              type: "search",
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                width: "100%",
                fontSize: `${props.sizes}px`,
                fontFamily: "nunito",
              },
              "& fieldset": {
                border: "none",
              },
            }}
          />
        )}
      />
    </div>
  );
}