import hour from "../../../../Assets/Images/hour.png";
import calendar from "../../../../Assets/Images/calender.png";
import lieu from "../../../../Assets/Images/lieu.png";
import pic from "../../../../Assets/Images/pic.png";
import money from "../../../../Assets/Images/money.png";
import toch from "../../../../Assets/Images/toch.png";
// import Update from "../../Paiement/Update";
// import Delete from "../../Paiement/Delete";
import {  useNavigate } from "react-router-dom";
// import { SubscriptionCourse } from "../../../../Objects/models/SubscriptionCourse";
import ClientRepository from "../../../../Repositories/ClientRepository";
import ChildRepository from "../../../../Repositories/ChildRepository";
import AddressRepository from "../../../../Repositories/AddressRepository";
import SubjectRepository from "../../../../Repositories/SubjectRepository";
import SubscriptionCourseProposalRepository from "../../../../Repositories/SubscriptionCourseProposalRepositorys";
import { useEffect, useState } from "react";
import { Address } from "../../../../Objects/models/Address";
import { Subject } from "../../../../Objects/models/Subject";
import Child from "../../../../Objects/models/Child";
import Functions from "../../../../Helpers/Functions";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import { SubscriptionCourseProposal } from "../../../../Objects/models/SubscriptionCourseProposal";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";
import { SubscriptionCourse } from "../../../../Objects/models/SubscriptionCourse";

const days = [
  {
    en: "monday",
    fr: "lundi",
  },
  {
    en: "tuesday",
    fr: "mardi",
  },
  {
    en: "wednesday",
    fr: "mercredi",
  },
  {
    en: "thursday",
    fr: "jeudi",
  },
  {
    en: "friday",
    fr: "vendredi",
  },
  {
    en: "saturday",
    fr: "samedi",
  },
  {
    en: "sunday",
    fr: "dimanche",
  },
];

const translate = (day: string) => {
  const translate = days.filter((item: any) => item.en.toLowerCase() === day);

  if (translate.length >= 1) {
    return translate[0].fr;
  }
  return "";
};

const HistoricSubscription = (course: SubscriptionCourse) => {
  const navigate = useNavigate()
  const [subscriptionCourseProposals, setSubscriptionCourseProposals] =
    useState<any>();
  const [address, setAddress] = useState<Address>();
  const [subject, setSubject] = useState<Subject>();
  const [child, setChild] = useState<Child>();
  const [teachr, setTeachr] = useState<any>();
  const [teachrHours, setTeachrHours] = useState<any>();
  const [proposal, setProposal] = useState<SubscriptionCourseProposal>();



  useEffect(() => {
    ClientRepository.getCurrentClient().then((client) => {
      if (client.id) {
        if (course.forChild_id) {
          ChildRepository.fetchChildById(course.forChild_id).then((child) => {
            if (child) {
              setChild(child);
            }
          });
        }
        if (course.address_id) {
          AddressRepository.fetchAddressById(course.address_id).then(
            (address) => {
              if (address) {
                setAddress(address);
              }
            }
          );
        }
        if (course.subjectAsked_id) {
          SubjectRepository.fetchSubjectById(course.subjectAsked_id).then(
            (subject) => {
              if (subject) {
                setSubject(subject);
              }
            }
          );
        }
        if (client.id && course.id) {
          SubscriptionCourseProposalRepository.getAllProposalToGivenSubCourse(client.id, course.id).then(
            (subscriptionCourseProposalCollection) => {
              if (subscriptionCourseProposalCollection) {
                setSubscriptionCourseProposals(subscriptionCourseProposalCollection)
              }
            }
          );
        }

      }
    });
  }, [course]);

  useEffect(() => {
    subscriptionCourseProposals?.map((proposal: SubscriptionCourseProposal) => (
      setProposal(proposal)
    )

    );
    subscriptionCourseProposals?.map((proposal: SubscriptionCourseProposal) =>
      Teachr_Repository.fetchTeachrByIdClient(proposal?.teachr_id!).then(
        (teachrData) => {
          setTeachr(teachrData);

        }
      )
    );
    subscriptionCourseProposals?.map((proposal: SubscriptionCourseProposal) =>
      Teachr_Repository.getTeachrHours(proposal?.teachr_id!).then(
        (teachrData) => {
          setTeachrHours(teachrData);
        }
      )
    );
  }, [subscriptionCourseProposals])

  const handleNext = () => {
    navigate("/user/coursesubscription", {
      state: { teachr, teachrHours, proposal },
    })
  }




  return (
    <div className="md:flex md:w-1/2 md:m-4 shrink-0 ">
      <div className=" md:w-full">
        <div className="p-4 rounded-lg md:w-full border-grey shadow-res">
          {/* <div className="">
            <p className="hidden font-semibold lg:text-lg md:text-base md:block text-dark_blue">
              Prochain cours :{" "}
              {moment(course.startDate).format(" DD MMMM YYYY")}
            </p>
            <p className="text-base font-semibold md:hidden //text-center text-dark_blue">
              Prochain cours :{" "}
              {moment(course.startDate).format("DD/MM/YY")}
            </p>
          </div> */}
          <div className="mt-4 md:flex md:mt-2 //gap-x-8">
            <div className="space-y-2 md:w-1/2 ">
              <div className="flex space-x-4 md:space-x-0 ">
                <div className="w-1/5 pt-1 pl-3">
                  <img src={pic} alt="" />
                </div>
                <div className="w-4/5">
                  {subject?.fr_FR ? (
                    <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      Cours Régulier {Functions.pronom(subject?.fr_FR)}
                      {subject?.fr_FR}
                    </p>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                  <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-grey">
                    {child?.firstName ? (
                      <span>Pour {child?.firstName} </span>
                    ) : (
                      <Skeleton width="50%" />
                    )}
                  </div>
                </div>
              </div>
              <div className="flex space-x-4 md:space-x-0 ">
                <div className="w-1/5 pt-1 pl-3">
                  <img src={hour} alt="" />
                </div>
                <div className="w-4/5">
                  {/* {course.startDatetime && course.endDatetime ? (
                    <span className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      Entre{" "}
                      {moment(course.startDatetime).format("HH:mm") +
                        " et " +
                        moment(course.endDatetime).format("HH:mm")}
                    </span>
                  ) : (
                    <Skeleton width="75%" />
                  )} */}
                  {course.daysOfTheWeek ? (
                    <p className="flex flex-wrap text-sm font-semibold lg:text-base md:text-base text-dark_blue">
                      {course.daysOfTheWeek.map((day: string, key) => (
                        <span
                          key={key}
                          className="after:content-[','] before:content-[attr(before)] last:after:content-[]"
                        >
                          {translate(day)}
                        </span>
                      ))}
                    </p>
                  ) : (
                    <Skeleton width="75%" />
                  )}

                  <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-grey">
                    {course.durationPerDay ? (
                      <span>{Functions.renderDuration(course.durationPerDay)}</span>
                    ) : (
                      <Skeleton width="75%" />
                    )}

                  </div>
                </div>
              </div>
              <div className="flex space-x-4 md:space-x-0 ">
                <div className="w-1/5 pt-1 pl-3">
                  <img src={calendar} alt="" />
                </div>
                <div className="pt-2">
                  {course.startDate ? (
                    <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      {moment(course.startDate).format("dddd DD MMMM YYYY")}
                    </p>
                  ) : (
                    <Skeleton width="75%" />
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2 space-y-2 md:w-1/2 ">
              <div className="flex space-x-4 md:space-x-0 ">
                <div className="w-1/5 pt-1 pl-4">
                  <img src={lieu} alt="" />
                </div>
                <div className="w-4/5">

                  {course.isRemote ? (
                    <p className="text-sm font-semibold translate-y-2 lg:text-lg md:text-base text-dark_blue">
                      {course.isRemote ? (
                        "Cours à distance"
                      ) : (
                        <Skeleton width="75%" />
                      )}
                    </p>
                  ) : (
                    <p className="text-sm font-semibold lg:text-base md:text-base //translate-y-1 text-dark_blue">
                      {address ? (
                        address?.streetName + ", " + address?.city
                      ) : (
                        <Skeleton width="75%" />
                      )}
                    </p>
                  )}
                </div>
              </div>
              {subscriptionCourseProposals?.map((proposal: SubscriptionCourseProposal) => (
                <div className="flex space-x-4 md:space-x-0 ">
                  <div className="w-1/5 pt-1 pl-3">
                    <img src={money} alt="" />
                  </div>
                  <div className="w-4/5">
                    {proposal.totalDailyPrice ? (
                      <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                        Prix par heure :{" "}
                        {(JSON.parse(proposal.totalDailyPrice!)).toFixed(2) +
                          "€"}
                      </p>
                    ) : (
                      <Skeleton width="100%" />
                    )}

                    {course.isRemote ? null : 
                    <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-grey">
                    {proposal.totalDailyPrice ? (
                      <span>
                        Soit après crédit d’impôt :{" "}
                        {(JSON.parse(proposal.totalDailyPrice!) / 2).toFixed(
                          2
                        ) + "€"}
                      </span>
                    ) : (
                      <Skeleton width="100%" />
                    )}
                  </div>
                  }
                  </div>
                </div>
              ))}
              <div className="flex space-x-4 md:space-x-0 ">
                <div className="w-1/5 pt-1 pl-3">
                  <img src={toch} alt="" />
                </div>
                <div className="w-4/5">
                  {teachr?.firstName ? (
                    <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      Prof : {teachr?.firstName}
                    </p>
                  ) : (
                    <Skeleton width="50%" />
                    // 'pour'
                  )}
                  <div className="text-xs lg:text-base md:text-sm //md:-translate-y-6 //-translate-y-3 text-grey">
                    <span onClick={() => handleNext()} >Voir Profil</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="flex justify-around py-4 space-x-4 translate-y-4 md:space-x-md:space-x-0 md:translate-y-0 ">
            <div className="w-full cursor-pointer ">
              <span className="text-dark_blue " onClick={handleClick}>
                <div className="border-[1px] md:p-2 p-1 md:text-[16px] text-xs text-center text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
                  Modifier
                </div>
              </span>
            </div>
            <div className="w-full cursor-pointer ">
              <span className="text-dark_blue " onClick={handleDelete}>
                <div className="border-[1px] text-center md:p-2 p-1 md:text-[16px] text-xs md:px-3 text-orange  border-orange uppercase active:bg-orange active:border-orange active:text-white font-semibold  rounded-full">
                  Supprimer
                </div>
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default HistoricSubscription;
