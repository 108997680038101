import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TeachrInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: 'transparent',
    border: "none",
    padding: "0",
    fontFamily: "Nunito, 'sans-serif'"
  },
}));

function SimpleSelect(props) {
  const handleChange = (event) => {
    props.callback(event.target.value)
  };
  return (
    <div className={
      "flex flex-col rounded-md py-2 px-3 shadow-[rgba(0,0,0,25%)] " + props.customClass
    }>
      <label htmlFor="" className="text-black" >{props.label}</label>
      <Select
        IconComponent = {KeyboardArrowDownIcon}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        placeholder={props.placeholders}
        defaultValue = {props.defaultValue}
        onChange={handleChange}
        displayEmpty
        input={<TeachrInput  />}
      >
        {!props.defaultValue && <MenuItem value="">
          <span className="text-slate-400">{props.placeholder}</span>
        </MenuItem>}
        {props.data.map((el, key) => (
          <MenuItem sx={{
            '&.MuiMenuItem-root':{
              fontFamily: "Nunito, 'sans-serif'"
            }
          }} key={key} value={key + 1}>{el}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
export default SimpleSelect;
