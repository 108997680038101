import { Button, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import RadioButton from '../../../../../Components/utils/RadioButton';
import TeachrPutTva from '../../../../../Objects/models/TeachrPutTva';
import Teachr_Repository from '../../../../../Repositories/Teachr_Repository';
import { isNull } from 'lodash';
import { useState } from 'react';
const Billing = () => {
    const [value, setValue] = useState(0)
    const MySwal = withReactContent(Swal)
    let tauxTva = 10
    let tvaNumber = ""

    const validateTva = (tvaNumber: any) => {
        let error = false; // Set error to false by default
        const errorsTag: any = document.getElementById('p1')
        if (tvaNumber === "") {
            // errTva = "Veuillez remplir ce champ";
            errorsTag.innerHTML = "Veuillez entrer une adresse valide"
            error = true; // Set error to true if tvaNumber is empty
        } else {
            errorsTag.innerHTML = ""
            error = false; // Set error to true if tvaNumber is empty
            putTva()
        }
        return error; // Return the error status
    }

    const putTva = () => {
        Teachr_Repository.getCurrentTeachr().then((res) => {
            const teachr = new TeachrPutTva(
                tauxTva, tvaNumber
            )

            if (res.id) {
                Teachr_Repository.putTvaByTeachr(res.id, teachr).then((response) => {
                    Swal.close()
                })

            }

        })
    }

    const closeDown =  () => {
        if (tauxTva  == 0 ) {
            Teachr_Repository.getCurrentTeachr().then((res) => {
                const teachr = new TeachrPutTva(
                    0, ""
                )
                if (res.id) {
                    Teachr_Repository.putTvaByTeachr(res.id, teachr).then((response) => {
                        Swal.close()
                    })
    
                }
            })
        }else{
            nextPopup()
        }
    }




    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
       tauxTva = parseInt((event.target as HTMLInputElement).value)
       
       
      };
    
    const openPopup = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            showClass: {
                popup: 'animate__animated animate__heartBeat'
            },
            hideClass: {
                popup: 'animate__animated animate__backOutDown'
            },
            html:
                <div className='flex flex-col text-left gap-y-4'>
                    <h2 className='text-sm font-extrabold text-first lg:text-xl'>Facturation de la TVA :</h2>
                    <h3 className='font-extrabold text-normal lg:text-lg'>Taux de TVA facturé :</h3>

                    <div className="flex items-center ml-2 gap-x-8">
                        <FormControl>
                            <RadioGroup row
                                name="customized-radios"
                                onChange={handleChange}
                                defaultValue = {tauxTva}
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value={0 }  control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="0%" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value={10 }  control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="10%" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito' } }} value={20}  control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="20%" />
                            </RadioGroup>
                        </FormControl>
                        <div>
                            <a href="https://entreprendre.service-public.fr/vosdroits/F31596#:~:text=Quel%20est%20le%20taux%20de%20TVA%20applicable%20%3F,des%20personnes%20%C3%A2g%C3%A9es%20et%20handicap%C3%A9es." target='_blank' className='text-sm font-semibold underline text-normal'>Lien vers le taux réduit </a>
                        </div>
                    </div>
                    <div className="flex justify-end">
                        <Button onClick={() => closeDown()} className='px-20 my-4 font-extrabold uppercase border border-solid rounded-full text-first border-first w-max'> {tauxTva == 0 ? 'VALIDER' : 'SUIVANT'}</Button>
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-profile-isAe')
        MySwal.getPopup()?.classList.add('rounded-lg')
        MySwal.getPopup()?.classList.add('max-w-[742px]')
    }
    const nextPopup = () => {

        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            showClass: {
                popup: 'animate__animated animate__heartBeat'
            },
            hideClass: {
                popup: 'animate__animated animate__backOutDown'
            },
            html:
                <div className='flex flex-col text-left gap-y-4'>
                    <h2 className='text-sm font-extrabold text-first lg:text-xl'>Facturation de la TVA :</h2>
                    <h3 className='font-extrabold text-normal lg:text-lg'>Numéro de TVA intracomunaitaire :</h3>

                    <div className="py-2 rounded-[7px] max-w-[366px] bg-[#F2F5FB]">
                        <input onChange={(e) => tvaNumber = (e.target.value)} type="text" className='w-full bg-transparent' />
                    </div>
                    <div className='py-2 text-sm text-red-600 md:text-base '><p id="p1"></p></div>
                    <div className="flex justify-end">
                        <Button onClick={() => validateTva(tvaNumber)} className='px-20 my-4 font-extrabold text-white uppercase rounded-full bg-first w-max'> valider</Button>
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-profile-isAe')
        MySwal.getPopup()?.classList.add('rounded-lg')
        MySwal.getPopup()?.classList.add('max-w-[742px]')
    }
    return (
        <div className='flex flex-col px-4 py-8 rounded-lg lg:p-10 shadow-profile-isAe gap-y-10 md:px-8'>
            <h3 className='text-base font-extrabold text-first lg:text-2xl'>Facturation de la TVA :</h3>
            <div className='py-4 text-center bg-[#F2F5FB] rounded-[7px]'>
                <p className=' font-bold lg:text-[16px] text-normal'>Je ne facture pas la TVA conformément à l’article 293 B du CGI </p>
            </div>
            <Button onClick={() => openPopup()} id="btn" className='px-20 mx-auto font-extrabold uppercase border border-solid rounded-full text-first border-first w-max '> facturer la TVA</Button>
        </div>
    );
};

export default Billing;