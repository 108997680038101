import { string } from "prop-types";
import errorIcon from "../Assets/Images/error.png";

const validateAddresseAdd = (value) => {
    if ((value === undefined || value === null)) {
        return {
            isValid: false,
            message: "Vous n'avez pas inséré d'adresse",
            icon: < img src = { errorIcon }
            alt = ""
            className = "w-4 h-4 translate-y-1" / >
        }
    }
    if ((value.streetNumber === "")) {
        return {
            isValid: false,
            message: "Veuillez entrer le numéro de la rue",
            icon: < img src = { errorIcon }
            alt = ""
            className = "w-4 h-4 translate-y-1" / >
        }
    } else
        return {
            isValid: true,
            message: "",
            icon: ""
        };
};

export default validateAddresseAdd;