import React from "react";
import { Link } from "react-router-dom";
import error from "../../Assets/Images/erreur404.png";

const Erreur = () => {
  return (
    <div className="h-screen overflow-hidden">
      <div className="">
        <div className="md:w-4/5 lg:mt-0 mt-44 w-full mx-auto">
          <img src={error} alt="" />
          <div className="md:w-1/4 w-4/5  lg:-translate-y-16 mx-auto translate-y-44 hover:bg-blue-500 p-2 lg:text-base bg-light_blue text-center lg:py-2 lg:px-4 rounded-full uppercase md:text-xs  ">
            <Link
              to="/coming-soon"
              className="no-underline text-white font-semibold "
            >
              Menu principal
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Erreur;
