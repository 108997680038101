import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import Image from "../Objects/models/Image";

export default abstract class ImageRepository {
    /**
     * Fetch an image stored into the API by his id.
     * @param id
     */
    public static fetchImageById(id: number) {
        return ApiService.get(`/images/${id}`);
    }

    /**
     * Creates an instance of image into the API.
     * @param imageURI
     */
    public static createImage(imageURI: any) {
        return ApiService.postInstance<Image>(
            Route.IMAGE_BASE,
            Image,
            imageURI,
            true,
            true
        );
    }
    /**
     * Returns all images of the connected user.
     */
    // public static getImages(page: number = 1) {
    //     return ApiService.getCollection<HydraCollection<Image>>(
    //         Route.IMAGE_BASE,
    //         HydraCollection,
    //         true,
    //         page
    //     );
    // }
}
