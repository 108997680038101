import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {  useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import frLocale from 'dayjs/locale/fr';
import { createSvgIcon } from '@mui/material/utils';
import moment from "moment";

const CalendarIcon = createSvgIcon(
  <path d="M0.443359 31.3125C0.443359 33.0723 1.93108 34.5 3.76479 34.5H28.1219C29.9556 34.5 31.4434 33.0723 31.4434 31.3125V13.25H0.443359V31.3125ZM22.5862 18.2969C22.5862 17.8586 22.9599 17.5 23.4166 17.5H26.1844C26.6411 17.5 27.0148 17.8586 27.0148 18.2969V20.9531C27.0148 21.3914 26.6411 21.75 26.1844 21.75H23.4166C22.9599 21.75 22.5862 21.3914 22.5862 20.9531V18.2969ZM22.5862 26.7969C22.5862 26.3586 22.9599 26 23.4166 26H26.1844C26.6411 26 27.0148 26.3586 27.0148 26.7969V29.4531C27.0148 29.8914 26.6411 30.25 26.1844 30.25H23.4166C22.9599 30.25 22.5862 29.8914 22.5862 29.4531V26.7969ZM13.7291 18.2969C13.7291 17.8586 14.1027 17.5 14.5594 17.5H17.3273C17.784 17.5 18.1576 17.8586 18.1576 18.2969V20.9531C18.1576 21.3914 17.784 21.75 17.3273 21.75H14.5594C14.1027 21.75 13.7291 21.3914 13.7291 20.9531V18.2969ZM13.7291 26.7969C13.7291 26.3586 14.1027 26 14.5594 26H17.3273C17.784 26 18.1576 26.3586 18.1576 26.7969V29.4531C18.1576 29.8914 17.784 30.25 17.3273 30.25H14.5594C14.1027 30.25 13.7291 29.8914 13.7291 29.4531V26.7969ZM4.87193 18.2969C4.87193 17.8586 5.24559 17.5 5.70229 17.5H8.47014C8.92684 17.5 9.3005 17.8586 9.3005 18.2969V20.9531C9.3005 21.3914 8.92684 21.75 8.47014 21.75H5.70229C5.24559 21.75 4.87193 21.3914 4.87193 20.9531V18.2969ZM4.87193 26.7969C4.87193 26.3586 5.24559 26 5.70229 26H8.47014C8.92684 26 9.3005 26.3586 9.3005 26.7969V29.4531C9.3005 29.8914 8.92684 30.25 8.47014 30.25H5.70229C5.24559 30.25 4.87193 29.8914 4.87193 29.4531V26.7969ZM28.1219 4.75H24.8005V1.5625C24.8005 0.978125 24.3023 0.5 23.6934 0.5H21.4791C20.8701 0.5 20.3719 0.978125 20.3719 1.5625V4.75H11.5148V1.5625C11.5148 0.978125 11.0166 0.5 10.4076 0.5H8.19336C7.58443 0.5 7.08622 0.978125 7.08622 1.5625V4.75H3.76479C1.93108 4.75 0.443359 6.17773 0.443359 7.9375V11.125H31.4434V7.9375C31.4434 6.17773 29.9556 4.75 28.1219 4.75Z" fill="#4D4D4D" />,
  'Calendar',
);
export default function DateInput(props) {
  const [date, setDate] = useState(props.defaultValue ? props.defaultValue : '')
  useEffect(() => {
    const svg = document.querySelector('[data-testid = CalendarIcon]')
    svg.setAttribute("viewBox", "0 0 32 35")
  },[])
  return (
    <div className="">
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={frLocale}>
        <DesktopDatePicker
          components={{
            OpenPickerIcon: CalendarIcon
          }}
          label="Date de naissance"
          defaultCalendarMonth={moment().subtract('16', 'year').month(0)}
          defaultCalendarYear={moment().subtract('16', 'year')}
          inputFormat = 'DD/MM/YYYY'
          value={date}
          orientation="landscape"
          onChange={(newDate) => { setDate(newDate); props.callback(newDate) }}
          renderInput={(params) => <TextField   InputLabelProps={{ shrink: true}} sx={{
            backgroundColor: 'white',
            borderRadius: ' 0.375rem'
            ,
            '&.MuiTextField-root .MuiFilledInput-root': {
              fontFamily: '"Nunito"',
              background: 'transparent'
            },
            '&.MuiTextField-root label': {
              fontFamily: '"Nunito"',
              fontSize: '20px',
              color: '#000000'
            },
            '&.MuiTextField-root input': {
              fontFamily: '"Nunito"',
              fontSize: '16px',
              color: '#000000',
              height: '1.8em'
            }
          }} fullWidth variant="filled" {...params} />}
        />

      </LocalizationProvider>

    </div>
  );
}
