import lieu from "../../../../Assets/Images/place.png";
import picture from "../../../../Assets/Images/picture.png";
import profile from "../../../../Assets/Images/profilehomme.png";
import starOnly from "../../../../Assets/Images/staronly.png";
import toch from "../../../../Assets/Images/tochh.png";
import money from "../../../../Assets/Images/money.png";
import msg from "../../../../Assets/Images/msg.png";
import favorite from "../../../../Assets/Icons/favorite.svg";
import phone from "../../../../Assets/Icons/phone.svg";
import nofavorite from "../../../../Assets/Icons/nofavorite.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import Functions from "../../../../Helpers/Functions";
import ReviewClient from "../../../../Objects/models/ReviewClient";
import ReviewClientRepository from "../../../../Objects/models/ReviewClientRepository";
import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/checkclose.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import NavBarCominsoon from "../../../../Components/Navbar/NavBarCominsoon";
// import { Elements } from "@stripe/react-stripe-js";
// import PunctualCoursePayment from "../../Paiement/ConfirmationCourse/AcceptCourse/PunctualCoursePayment";
// import { loadStripe } from "@stripe/stripe-js";
import ClientRepository from "../../../../Repositories/ClientRepository";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";
// const stripePromise = loadStripe("pk_test_aTkKFKmFR3oCTGEppE2nxxA7");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF724F",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #FF724F",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0054A3",
  "&.Mui-selected": {
    color: "#FF724F",
  },
  "&.Mui-focusVisible": {},
}));

const ProfileTeacherPunctualActive = () => {
  const navigate = useNavigate();
  const [isFavorite, setIsFavorite] = useState(false);
  const location = useLocation();
  const [state] = useState<any>(location.state);
  // const [loading, setLoading] = useState(false);
  const [review, setReview] = useState<ReviewClient[]>();
  const [teachrInfos, setTeachrInfos] = useState<any>();

  const [value, setValue] = useState(0);
  // const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const handleNext = () => {
  //   setValue(1);
  //   setIsLoading(true);
  // };

  const [open, setOpen] = useState(false);
  // const [opens, setOpens] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleClose = () => {
    setOpen(false);
  };
  // const handleCloses = () => {
  //   setOpens(false);
  // };

  // const onRefuseConfirm = () => {
  //   setLoading(true);
  //   handleCloses();
  //   navigate("/user/response");
  // };
  const loadData = async (page: number) => {
    const reviewsCollection = await ReviewClientRepository.getReviewsClient(
      page,
      ["punctualCourse.acceptedProposal.teachr.id=" + state.teachr.id]
    );
    setReview(reviewsCollection.members);
  };
  useEffect(() => {
    loadData(1);
  }, []);

  // const handleChanges = () => {
  //   handleNext();
  // };

  const onFavoritePress = async (param: "add" | "remove") => {
    // this.setState({ loadingFavorite: true });
    const client = await ClientRepository.getCurrentClient();
    setIsFavorite(!isFavorite)
    if (param === "add") {
      await ClientRepository.addFavoriteTeachr(client!, state.teachr!);
     

      // this.props.dispatch(generalSetError(new Error("Teach'r ajouté","Le Teach'r a bien été ajouté à vos favoris","success")));
    } else {
       await ClientRepository.removeFavoriteTeachr(client!, state.teachr!);
     
      // this.props.dispatch(generalSetError(new Error("Teach'r supprimé","Le Teach'r a bien été supprimé de vos favoris","success")));
    }
    // this.setState({ loadingFavorite: false, isFavorite: !this.state.isFavorite });
    // this.props.dispatch(userClientSetAccountAction(client));
  }

  useEffect(() => {
    (async () => {
      const client = await ClientRepository.getCurrentClient()
      const teachrInfos = await Teachr_Repository.fetchTeachrInfoByIdClient(state.teachr?.id!, client!.id!);
      setTeachrInfos(teachrInfos)

    })()
  }, [state.teachr?.id])



  return (
    <div className="h-screen  w-screen overflow-x-hidden list-teacher font-nunito">
      <div className="fixed bg-white z-50 w-full">
        <NavBarCominsoon />
        <div className="md:mx-10">
          <Box>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="lg:text-lg md:text-base text-sm font-bold">
                    Profil de {state.teachr.firstName}
                  </p>
                }
              />
              <StyledTab
                label={
                  <div
                    className="translate-y-2"
                    onClick={() => navigate("/coming-soon")}
                  >
                    <div className="flex text-dark_blue lg:text-base md:text-sm text-xs  pt-1 px-3 space-x-2 rounded-full border-2 border-dark_blue">
                      <div className=" //w-1/4">
                        <img src={msg} alt="" />
                      </div>
                      <div className=" //w-3/4">
                        <p className="font-bold //truncate">
                          Discuter avec {state.teachr.firstName} !
                        </p>
                      </div>
                    </div>
                  </div>
                }
              />
            </StyledTabs>
          </Box>
        </div>
      </div>
      <div className="//mx-10 mt-[120px] ">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 3 }}>
            <TabPanel value={value} index={0}>
              <div className="md:mt-[50px] mt-16   md:mx-20 mb-8">
                <div className="md:flex lg:gap-x-0 gap-x-4 hidden">
                  <div className=" lg:w-1/2 w-2/3">
                    <div className=" bg-bg-color-tuile rounded-md shadow-profile p-4 w-full">
                      <div className="  flex ">
                        <div className=" w-1/4 ">
                          <div className="rounded-full mx-auto  shadow-profile xl:w-2/3 xl:h-full translate-y-1 ">
                            <img
                              src={
                                state.teachr?.profilePictureURL
                                  ? state.teachr?.profilePictureURL
                                  : picture
                              }
                              alt=""
                              className="relative w-full h-full mx-auto rounded-full"
                            />
                          </div>
                        </div>
                        <div className="my-auto space-y-1  w-1/3  block">
                          <div className="">
                            <p className="text-dark_blue lg:text-xl md:text-lg text-sm font-bold //translate-y-4">
                              {state.teachr.firstName}
                            </p>
                          </div>
                          <div className="">
                            <Rating
                              name="half-rating-read"
                              defaultValue={state.teachr?.averageNote}
                              precision={0.1}
                              readOnly
                            />
                          </div>
                          <div className="lg:text-base md:text-sm text-xs">
                            <p>
                              {Functions.renderTeachrHours(
                                state.teachrHours?.totalHours
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="  my-auto space-y-2 w-5/12">
                          <div className="flex ">
                            <div className=" pl-2 w-1/4">
                              <img src={toch} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg md:text-base truncate text-sm text-dark_blue font-semibold">
                                {state.teachr?.whereStudies},{" "}
                                {state.teachr.yearInStudies.toString() +
                                  (state.teachr.yearInStudies === 1
                                    ? "ère"
                                    : "ème") +
                                  " année"}
                              </p>


                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/4 pl-4 ">
                              <img src={lieu} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.city}
                              </p>
                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/4 pl-4 ">
                              <img src={phone} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-2 w-1/2 mx-auto space-x-2">
                        <div className="pt-1">
                          <img src={money} alt="" />
                        </div>
                        <div className="">
                          <p className="lg:text-lg md:text-base text-sm  text-dark_blue font-semibold">
                            Prix par heure :{" "}
                            {/* {state.proposal.teachrPrice!.toFixed(2) + "€"}
                             */}
                            {(
                              JSON.parse(state.proposal.hourlyPrice!) / 100
                            ).toFixed(2) + "€"}
                          </p>
                          <div className="lg:text-base md:text-sm text-xs  text-[4d4d4d]">
                            <span>
                              Soit après crédit d’impôt :{" "}
                              {(
                                JSON.parse(state.proposal.hourlyPrice!) / 200
                              ).toFixed(2) + "€"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:px-10 mt-4 space-y-2">
                        <div className="  ">
                          <p className="text-dark_blue font-bold lg:text-xl md:text-lg text-base ">
                            Description :
                          </p>
                          <p className="text-[4d4d4d] lg:text-lg md:text-base text-sm ">
                            {state.teachr.description}
                          </p>
                        </div>
                        <div className=" mt-2 ">
                          {state.proposal.description ? (
                            <p className="text-dark_blue font-bold lg:text-xl md:text-lg text-base ">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="text-[4d4d4d] lg:text-lg md:text-base text-sm ">
                            {state.proposal.description}
                          </p>
                        </div>
                      </div>
                      <div onClick={() => onFavoritePress(isFavorite ? 'remove' : 'add')} className="lg:px-10 mt-4 flex space-x-3 cursor-pointer">
                        <div>
                          <img src={isFavorite ? favorite : nofavorite} alt="" />
                        </div>
                        <div>
                          <p className="lg:text-lg md:text-base text-sm text-[4d4d4d]" >{isFavorite ? "Retirer ce Teach’r aux favoris" : 'Ajouter ce Teach’r aux favoris'}</p>
                        </div>
                      </div>
                      <div className="flex  space-x-8 w-4/5 mt-10 lg:text-lg md:text-base text-sm mx-auto justify-around">
                        <div className="w-1/2 border-2 border-orange pt-1 rounded-full active:text-white active:bg-orange">
                          <div
                            onClick={() => handleBack()}
                            className="lg:text-base md:text-sm text-xs text-center w-full font-extrabold text-orange active:text-white  uppercase   "
                          >
                            Retour
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:w-1/2  w-1/3">
                    <div className=" flex xl:w-2/5 lg:w-1/2 -translate-y-14  mt-4  shadow-avis rounded-2xl mx-auto border-2 pt-1  text-dark_blue font-bold justify-around ">
                      <div className="   w-1/4 text-center xl:text-xl  md:text-lg text-base ">
                        <p>AVIS</p>
                      </div>
                      <div className="   w-1/2 text-center xl:text-xl md:text-lg text-base ">
                        <Rating
                          name="half-rating-read"
                          defaultValue={state.teachr?.averageNote}
                          precision={0.1}
                          readOnly
                        />
                      </div>
                      <div className="   w-1/4 text-center lg:text-xl md:text-lg text-base ">
                        <p>({review?.length}) </p>
                      </div>
                    </div>
                    <div className="   lg:-translate-y-16 lg:mt-8 -mt-8 overflow-x-hidden list-teacher h-screen space-y-8">
                      {review?.map((comment: ReviewClient) => (
                        <div className="">
                          <div className="lg:w-1/2 p-2 rounded-md shadow-avis mx-auto ">
                            <div className="flex  justify-around ">
                              <div className=" w-1/4">
                                <div className=" mx-auto xl:w-16 xl:h-16 lg:h-12 lg:w-12 h-10 w-10 rounded-full ">
                                  <img
                                    src={
                                      comment.owner?.profilePicture
                                        ? comment.owner?.profilePicture
                                        : profile
                                    }
                                    // src={profile}
                                    alt=""
                                    className="w-full h-full  relative rounded-full"
                                  />
                                </div>
                              </div>
                              <div className=" w-1/2 pl-2 lg:space-y-0">
                                <div className="">
                                  <p className="text-dark_blue font-bold lg:text-base md:text-sm text-xs">
                                    {comment.owner?.firstName}
                                  </p>
                                </div>
                                <div className="  w-1/2 //mx-auto">
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={comment.owner?.averageNote}
                                    precision={0.1}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="lg:mx-4 mt-2">
                              <p className="text-dark_blue  lg:text-lg md:text-base text-sm font-bold">
                                {comment.comment ? "Commentaire :" : ""}
                              </p>
                              <p className="text-[4d4d4d] lg:text-base md:text-sm text-xs">
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className=" lg:w-1/2 w-full">
                    <div className=" bg-bg-color-tuile rounded-md shadow-profile p-4 w-full">
                      <div className=" justify-around">
                        <div className="flex justify-around ">
                          <div className=" mx-auto w-1/3  ">
                            <div className="rounded-full  mx-auto shadow-profile translate-y-1 w-20 h-20 ">
                              <img
                                src={
                                  state.teachr?.profilePictureURL
                                    ? state.teachr?.profilePictureURL
                                    : picture
                                }
                                alt=""
                                className="h-20 w-20 relative rounded-full"
                              />
                            </div>
                          </div>

                          <div className="block  w-2/3">
                            <p className="text-dark_blue text-lg font-bold ">
                              {state.teachr.firstName}
                            </p>
                            <div className="">
                              <div className="text-lg">
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={state.teachr?.averageNote}
                                  precision={0.1}
                                  readOnly
                                />
                              </div>
                              <p>
                                {Functions.renderTeachrHours(
                                  state.teachrHours?.totalHours
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1 mt-3  ">

                          <div onClick={() => onFavoritePress(isFavorite ? 'remove' : 'add')} className="lg:px-10 mt-4  flex md:space-x-3 space-x-2 cursor-pointer">
                            <div className="pl-8 w-1/3">
                              <img src={isFavorite ? favorite : nofavorite} alt="" />
                            </div>
                            <div>
                              <p className="lg:text-lg md:text-base text-sm text-[4d4d4d]" >{isFavorite ? "Retirer ce Teach’r aux favoris" : 'Ajouter ce Teach’r aux favoris'}</p>
                            </div>
                          </div>

                          <div className="flex ">
                            <div className=" w-1/3 ">
                              <div className=" mx-auto pl-2 w-1/2">
                                <img src={toch} alt="" className="w-8" />
                              </div>
                            </div>
                            <div className=" w-2/3 ">
                              <p className="text-sm text-dark_blue font-semibold">
                                {state.teachr?.whereStudies},{" "}
                                {state.teachr.yearInStudies.toString() +
                                  (state.teachr.yearInStudies === 1
                                    ? "ère"
                                    : "ème") +
                                  " année"}
                              </p>
                            </div>
                          </div>
                          <div className="flex ">
                            <div className=" w-1/3  ">
                              <div className=" pl-4 mx-auto w-1/2">
                                <img src={lieu} alt="" className="w-4" />
                              </div>
                            </div>
                            <div className="">
                              <p className="text-sm translate-y-1 text-dark_blue font-semibold">
                                {teachrInfos?.city}
                              </p>
                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/3 pl-9 ">
                              <img src={phone} alt="" />
                            </div>
                            <div className=" //w-3/4">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.phone}
                              </p>
                            </div>
                          </div>
                          <div className="flex w-full ">
                            <div className="w-1/3 pl-8 ">
                              <img src={money} alt="" className="w-8" />
                            </div>
                            <div className=" w-2/3">
                              <p className="text-sm  text-dark_blue font-semibold">
                                Prix par heure :{" "}
                                {/* {state.proposal.teachrPrice!.toFixed(2) + "€"} */}
                                {(
                                  JSON.parse(state.proposal.hourlyPrice!) / 100
                                ).toFixed(2) + "€"}
                              </p>
                              <div className="text-xs text-[4d4d4d]">
                                <span>
                                  Soit après crédit d’impôt :{" "}
                                  {(
                                    JSON.parse(state.proposal.hourlyPrice!) /
                                    100
                                  ).toFixed(2) + "€"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex  mx-auto w-4/5 mt-3 justify-between gap-x-4 ">
                        <div className=" text-dark_blue text-sm  pt-1 w-1/2 shadow-shadow-button rounded-lg  ">
                          <p className="text-center w-full">Discuter</p>
                          <div className="pl-10 pt-1">
                            <img src={msg} alt="" className="w-8" />
                          </div>
                        </div>
                        <div className=" text-dark_blue text-sm  pt-1 w-1/2   shadow-shadow-button rounded-lg ">
                          <div className="font-nunito md:hidden flex">
                            <span
                              className="text-dark_blue w-full"
                              onClick={handleClickOpen}
                            >
                              <p className="text-center">Avis</p>
                              <div className="mx-auto w-8">
                                <img
                                  src={starOnly}
                                  alt=""
                                  className="w-10 px-auto"
                                />
                              </div>
                            </span>
                            <Dialog open={open} onClose={handleClose}>
                              <div className="w-[295px] overflow-x-hidden">
                                <DialogTitle>
                                  <div className="h-8 z-40  flex fixed bg-white w-[258px]">
                                    <div className="w-4/5">
                                      <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                        Avis
                                      </span>
                                    </div>
                                    <div className=" pl-4 pt-1 w-1/5">
                                      <img
                                        src={close}
                                        alt=""
                                        className="w-5"
                                        onClick={handleClose}
                                      />
                                    </div>
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <div className="">
                                    <div className=" flex mt-6 w-full shadow-avis rounded-2xl mx-auto py-2  text-dark_blue font-bold justify-around ">
                                      <div className="translate-y-1 text-base ">
                                        <p>AVIS</p>
                                      </div>
                                      <div className="translate-y-  text-[15px] ">
                                        <Rating
                                          name="half-rating-read"
                                          defaultValue={
                                            state.teachr?.averageNote
                                          }
                                          precision={0.1}
                                          readOnly
                                        />
                                      </div>
                                      <div className="translate-y-1 text-base ">
                                        <p>({review?.length})</p>
                                      </div>
                                    </div>
                                    <div className=" mt-4 overflow-x-hidden list-teacher space-y-4">
                                      {review?.map((comment: ReviewClient) => (
                                        <div className="">
                                          <div className="w-full p-2 rounded-md shadow-avis mx-auto ">
                                            <div className="flex justify-between ">
                                              <div className=" w-1/2">
                                                <div className=" w-16 h-16  mx-auto rounded-full ">
                                                  <img
                                                    src={
                                                      comment.owner
                                                        ?.profilePicture
                                                        ? comment.owner
                                                          ?.profilePicture
                                                        : profile
                                                    }
                                                    alt=""
                                                    className="w-full h-full relative rounded-full"
                                                  />
                                                </div>
                                              </div>
                                              <div className="  w-1/2 //mx-auto">
                                                <div>
                                                  <Rating
                                                    name="half-rating-read"
                                                    defaultValue={
                                                      comment.owner?.averageNote
                                                    }
                                                    precision={0.1}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="">
                                                  <p className="text-dark_blue font-bold text-base">
                                                    {comment.owner?.firstName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="">
                                              <p className="text-dark_blue  text-base font-bold">
                                                {comment.comment
                                                  ? "Commentaire :"
                                                  : ""}{" "}
                                              </p>
                                              <p className="text-[4d4d4d]  text-sm">
                                                {comment.comment}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </DialogContent>
                              </div>
                            </Dialog>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <p className="text-dark_blue font-bold text-base">
                          Description :
                        </p>
                        <p className="text-[4d4d4d] text-sm w-full">
                          {state.teachr.description}
                        </p>
                      </div>
                      {state.proposal.description ? (
                        <div className="mt-2">
                          {state.proposal.description ? (
                            <p className="text-dark_blue font-bold text-base">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="text-[4d4d4d] text-sm w-full">
                            {state.proposal.description}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex space-x-3 mt-4 mx-auto justify-between">
                        <div className="w-1/2 border-2 mx-auto py-2 border-orange //pt-1 rounded-full active:text-white active:bg-orange">
                          <div
                            onClick={() => handleBack()}
                            className="lg:text-base md:text-sm text-xs text-center w-full font-extrabold text-orange active:text-white  uppercase   "
                          >
                            Retour
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Box>
        </Box>
      </div>
    </div>
  );
};

export default ProfileTeacherPunctualActive;
