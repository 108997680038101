import React, { useEffect, useRef, useState } from 'react';
// import Chart from "chart.js/auto";
import ReactApexChart from 'react-apexcharts';

const ComponentMonthPriceChart = (props) => {
    const { durationByMonth, durationByMonth2, durationByMonth3, priceMonth2, priceMonth, priceMonth3 } = props
    const [chartData, setChartData] = React.useState({ series: [], options: {} });
    React.useEffect(() => {
        const dataRegle = [
            durationByMonth["janvier"] !== undefined && durationByMonth["janvier"],
            durationByMonth["février"] !== undefined && durationByMonth["février"],
            durationByMonth["mars"] !== undefined && durationByMonth["mars"],
            durationByMonth["avril"] !== undefined && durationByMonth["avril"],
            durationByMonth["mai"] !== undefined && durationByMonth["mai"],
            durationByMonth["juin"] !== undefined && durationByMonth["juin"],
            durationByMonth["juillet"] !== undefined && durationByMonth["juillet"],
            durationByMonth["août"] !== undefined && durationByMonth["août"],
            durationByMonth["septembre"] !== undefined && durationByMonth["septembre"],
            durationByMonth["octobre"] !== undefined && durationByMonth["octobre"],
            durationByMonth["novembre"] !== undefined && durationByMonth["novembre"],
            durationByMonth["décembre"] !== undefined && durationByMonth["décembre"]
        ]
        const dataEnCours = [
            durationByMonth2["janvier"] !== undefined && durationByMonth2["janvier"],
            durationByMonth2["février"] !== undefined && durationByMonth2["février"],
            durationByMonth2["mars"] !== undefined && durationByMonth2["mars"],
            durationByMonth2["avril"] !== undefined && durationByMonth2["avril"],
            durationByMonth2["mai"] !== undefined && durationByMonth2["mai"],
            durationByMonth2["juin"] !== undefined && durationByMonth2["juin"],
            durationByMonth2["juillet"] !== undefined && durationByMonth2["juillet"],
            durationByMonth2["août"] !== undefined && durationByMonth2["août"],
            durationByMonth2["septembre"] !== undefined && durationByMonth2["septembre"],
            durationByMonth2["octobre"] !== undefined && durationByMonth2["octobre"],
            durationByMonth2["novembre"] !== undefined && durationByMonth2["novembre"],
            durationByMonth2["décembre"] !== undefined && durationByMonth2["décembre"]
        ]
        const dataError = [
            durationByMonth3["janvier"] !== undefined && durationByMonth3["janvier"],
            durationByMonth3["février"] !== undefined && durationByMonth3["février"],
            durationByMonth3["mars"] !== undefined && durationByMonth3["mars"],
            durationByMonth3["avril"] !== undefined && durationByMonth3["avril"],
            durationByMonth3["mai"] !== undefined && durationByMonth3["mai"],
            durationByMonth3["juin"] !== undefined && durationByMonth3["juin"],
            durationByMonth3["juillet"] !== undefined && durationByMonth3["juillet"],
            durationByMonth3["août"] !== undefined && durationByMonth3["août"],
            durationByMonth3["septembre"] !== undefined && durationByMonth3["septembre"],
            durationByMonth3["octobre"] !== undefined && durationByMonth3["octobre"],
            durationByMonth3["novembre"] !== undefined && durationByMonth3["novembre"],
            durationByMonth3["décembre"] !== undefined && durationByMonth3["décembre"]
        ]
        const chartOptions = {
            series: [
                {
                    name: 'En cours',
                    data: dataEnCours
                },
                {
                    name: 'Réglés',
                    data: dataRegle
                },
                {
                    name: 'Réfusés',
                    data: dataError
                },
    
            ],
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: false, // Définir à true si vous souhaitez afficher la barre d'outils, false pour la masquer
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'string',
                categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jui", "Aôut", "Sept", "Oct", "Nov", "Dec"]
            },
            yaxis: {
                title: {
                    // text: "Rémunération horaire NET d'impôts",
                    style: {
                        color: "#4D4D4D",
                        fontFamily: "nunito",
                        fontSize: "14px",
                        fontWeight: 600
                    },
                    // offsetX: -11,
                },
                min: 50,
                // max: yaxisMax,
                tickAmount: 10,
                labels: {
                    show: true,
                    formatter: function (value) {
                        return value.toFixed(2) + '€'; // Formatage avec des séparateurs de milliers
                    },
                    style: {
                        colors: "#808080",
                        fontSize: '12px',
                        fontFamily: 'nunito',
                        fontWeight: 400,
                    },
                },
            },
            grid: {
                position: 'back',
                borderColor: 'rgba(0,0,0,0.22)',
                strokeDashArray: 5,
                xaxis: {
                    lines: {
                        show: true,
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    }
                },
            },
            // tooltip: {
            //     x: {
            //         format: 'dd/MM/yy HH:mm'
            //     },
            // },
            // legend: {
            //     position: 'top',
            // }
            legend: {
                position: 'top',
                formatter: function (seriesName) {
                    // Assurez-vous que les variables ont une valeur par défaut avant d'utiliser toFixed
                    const safePriceMonth = priceMonth || 0;
                    const safePriceMonth2 = priceMonth2 || 0;
                    const safePriceMonth3 = priceMonth3 || 0;
    
                    if (seriesName === 'En cours') {
                        return `${seriesName} (${safePriceMonth2.toFixed(1)}€)`;
                    } else if (seriesName === 'Réglés') {
                        return `${seriesName} (${safePriceMonth.toFixed(1)}€)`;
                    } else if (seriesName === 'Réfusés') {
                        return `${seriesName} (${safePriceMonth3.toFixed(1)}€)`;
                    }
                }
            }
        };
        setChartData({ ...chartData, series: chartOptions.series, options: chartOptions });
    }, [durationByMonth, durationByMonth2, durationByMonth3, priceMonth, priceMonth2, priceMonth3])

        return (
            <div className='h-full w-full ' id="chart">
                <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} className="w-full md:border-none rounded-lg border-first border-2 md:mt-0 md:shadow-none shadow-lg mt-4" />
            </div>
        );

};

export default ComponentMonthPriceChart;