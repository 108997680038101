import React from 'react';
import updateponc from '../../../../../../../Assets/Images/modifier_cours.gif'
import StepperStore from '../../../../../../../LocalForage/StepperStore';

const EditSubscriptionConfirme = (props:any) => {
    const handleNext = () => {
        StepperStore.clear()
        props.backStep(1)
    }
    return (
        <div className=' p-6 shadow-dashboard-setup-intro-form h-[80%] mt-4 //h-[0%] px-4 py-4 bg-white rounded-xl '>
            <div className='flex'>
                <div className='md:w-2/3 md:p-10  //border-2 md:pr-56 '>
                    <div className="mt-3">
                        <h1 className="lg:text-2xl pl-10 //text-center md:text-lg text-sm font-extrabold text-dark_blue">
                            Modification du cours régulier réussi !
                        </h1>
                    </div>
                    <div className='mt-8 pl-10'>
                        <p className='lg:text-xl md:text-base text-sm font-bold text-dark_blue'>
                            Votre cours régulier a bien été modifié. Vous pourrez retrouver toutes les modifications de celui-ci dans votre tableau.
                        </p>
                    </div>
                </div>
                <div className='w-1/2 hidden md:block -translate-x-16  px-4 pt-4'>
                    <img src={updateponc} alt="" />
                </div>
            </div>
            <div className='md:pl-20 md:-translate-y-16 md:mt-0 mt-10'>
                <div className="bg-dark_blue p-1 rounded-3xl md:w-1/4 cursor-pointer" onClick={()=> { handleNext() }}>
                    <p className="text-white md:text-base text-sm text-center font-extrabold">Mes cours</p>
                </div>
            </div>
        </div>
    );
};

export default EditSubscriptionConfirme;