import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const Second = (props: any) => {
  const [select, setSelect] = useState(0);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const generalProcuration = useSelector((state: any) => state.procurationAe);
  useEffect(() => {
    if (
      generalProcuration &&
      generalProcuration.second &&
      generalProcuration.second.second
    ) {
      setSelect(generalProcuration.second.second);
      // props.nextStep(3)
    }
  }, [generalProcuration]);

  const nextStep = (value: number) => {
    if (value > 0) {
      setSelect(value);
      setError(false);
      // props.callBack({
      //     situation: select
      // })
      if (generalProcuration.second.second) {
        dispatch({
          type: "SET_AE_INFO",
          payload: {
            first: generalProcuration.first,
            second: {
              first: generalProcuration.second.first,
              second: value,
              third: generalProcuration.second.third!,
            },
          },
        });
      } else {
        dispatch({
          type: "SET_AE_INFO",
          payload: {
            first: generalProcuration.first,
            second: {
              step: 3,
              first: generalProcuration.second.first,
              second: value,
            },
          },
        });
      }
      // props.nextStep(3)
    } else {
      setError(true);
    }
  };
  const handleNext = () => {
    if (select === 0) {
      setError(true);
    } else {
      setError(false);
      props.setCurrentStep(3);
    }
  };

  return (
    <div className="">
      {error ? (
        <p className="text-third pb-1 text-xs lg:text-base">
          Veuillez sélectionner une situation
        </p>
      ) : null}
      <label
        htmlFor=""
        className="text-normal lg:text-xl font-semibold text-base"
      >
        Situation matrimoniale :
      </label>
      <ul className="flex flex-col gap-y-4 py-4 cursor-default mx-auto lg:ml-8">
        <li
          onClick={() => nextStep(1)}
          className={`${
            select === 1
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          Célibataire{" "}
        </li>
        <li
          onClick={() => nextStep(2)}
          className={`${
            select === 2
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          Divorcé.e{" "}
        </li>
        <li
          onClick={() => nextStep(3)}
          className={`${
            select === 3
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          Veuf.ve{" "}
        </li>
        <li
          onClick={() => nextStep(4)}
          className={`${
            select === 4
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          Marié.e{" "}
        </li>
        <li
          onClick={() => nextStep(5)}
          className={`${
            select === 5
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          Pacsé.e{" "}
        </li>
        <li
          onClick={() => nextStep(6)}
          className={`${
            select === 6
              ? "border-first bg-[#E8F5FF]"
              : " border-[#808080] bg-white"
          } border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}
        >
          En concubinage{" "}
        </li>
      </ul>
      <Button
        disabled={props.loading}
        onClick={handleNext}
        className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
        style={{
          background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
        }}
      >
        SUIVANT
      </Button>
    </div>
  );
};

export default Second;
