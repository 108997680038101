import ProfileImg from '../../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../../Assets/Icons/clock.svg'
import CalendarIcon from '../../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../../Assets/Icons/clip.svg'
import LocationIcon from '../../../../Assets/Icons/location.svg'
import euroIcon from '../../../../Assets/Icons/euro-circle.svg'
import Functions from '../../../../Helpers/Functions';
import moment from 'moment';
import { Skeleton } from '@mui/material';

const PunctualCandidacyComponent = (proposal: any) => {
    const formatTime = (time: string | undefined) => {
        let format = ''
        if (time) {
            const regex = "(.*):(.*)"
            const match = time.match(regex)
            if (match) {
                format = `${match[1]}h${match[2]}`
            }
        }
        return format
    }
    return (
        <div className="p-6 mx-auto rounded-lg w-[340px] shadow-recap-card">
            <div className="flex flex-col mx-auto">
                <div className="flex flex-col gap-4">
                    <div>
                        <p className='text-xl font-bold whitespace-nowrap text-first text-start'>Récapitulatif du cours ponctuel</p>
                    </div>
                    <div className="flex items-center gap-x-4">
                        <img src={proposal.punctualCourse?.child?.profilePicture ? proposal.punctualCourse?.child?.profilePicture : ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                        <div>
                            {!proposal.punctualCourse?.subjectAsked ?
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="200px"
                                />
                                : <p className='overflow-hidden whitespace-nowrap w-[210px] text-ellipsis text-lg font-bold text-first' title={proposal.punctualCourse?.subjectAsked.fr_FR}>{proposal.punctualCourse?.subjectAsked.fr_FR}</p>
                            }
                            {!proposal.punctualCourse?.forChild ? <Skeleton
                                animation="wave"
                                height={14}
                                width="200px"
                            /> :
                                <p className='text-[#4D4D4D] text-sm'>Pour {proposal.punctualCourse?.forChild.firstName} / {Functions.getLevel(proposal.punctualCourse?.forChild.class)}</p>}
                        </div>
                    </div>
                    {!proposal.punctualCourse?.isRemote
                        ? <div className="flex items-center gap-4">
                            <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            {proposal.punctualCourse?.address ? <a href={`https://www.google.com/maps/search/?api=1&query=${proposal.punctualCourse?.address?.streetName}%2C+${proposal.punctualCourse?.address?.city}`} target='_blanck' className='text-lg font-bold underline text-first'>{proposal.punctualCourse?.address?.streetName + ', ' + proposal.punctualCourse?.address?.city}</a> :
                                <Skeleton
                                    animation="wave"
                                    height={16}
                                    width="200px"
                                />}

                        </div> :
                        <div className="flex items-center gap-4">
                            <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            <p className='text-lg font-bold text-first'>Cours à distance</p>
                        </div>}
                    <div className="flex items-center gap-4">
                        <img src={euroIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <p className='text-lg font-bold text-first'>{Functions.renderPaymentMean(proposal.punctualCourse?.paymentMean!, false)}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-4 py-4">
                    <div>
                        <p className='text-xl font-bold whitespace-nowrap text-first'>Date du cours ponctuel</p>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <div>
                            <p className='text-[16px] font-bold text-first whitespace-nowrap'>Horaires</p>
                            <p className='text-[#4D4D4D] text-sm'>Durée : {Functions.renderDuration(proposal.punctualCourse.duration)}, entre {formatTime(moment(proposal.startDatetime).format('LT'))} - {formatTime(moment(proposal.endDatetime).format('LT'))}</p>
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <p className='text-[16px] font-bold text-first whitespace-nowrap'>{moment(proposal.punctualCourse?.startDatetime).format('dddd DD MMMM YYYY')}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PunctualCandidacyComponent;