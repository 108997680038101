import { Button, CircularProgress } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Functions from '../../../../Helpers/Functions';
import StepperStore from '../../../../LocalForage/StepperStore';
import AiciSetupInterface from '../../../../Objects/interfaces/AiciSetup';
import { Address } from '../../../../Objects/models/Address';
import Teachr from '../../../../Objects/models/Teachr';
import TeachrContact from '../../../../Objects/models/TeachrContact';
import AddressRepository from '../../../../Repositories/AddressRepository';
import TeachrContactRepository from '../../../../Repositories/TeachrContactRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Layout from './Layout';
import config from '../../../../config';
import { checkIfUserExist } from '../../../../Services/FirebaseService';
import {removeExtraSpace} from '../Dashboard/UtilsDashboard/FunctionsDashbord'

const Last = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const EMPTY_FIELD = '_____________________________________________________'
    const [save, setSave] = useState<AiciSetupInterface>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [address, setAddress] = useState<Address>()
    const [defaultAddress, setDefaultAddress] = useState<Address>()
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        AddressRepository.getDefaultAddress().then((response) => {
            setDefaultAddress(response)
        })
        TeachrRepository.getCurrentTeachr().then((response) => {
            setTeachr(response)
        })
        StepperStore.getItem('dashboard_setup').then((response: any) => {
            setSave(response)
            if (response.Parent.second.address) {
                setAddress(response.Parent.second.address)
            }
        })
    }, [])
    const createTeachrContact = (screenState: any) => {
        if (save && teachr) {
            const teachrContact = new TeachrContact(
              0,
              removeExtraSpace(save.Parent.first.mail),
              teachr["@id"]!
            );
            teachrContact.bic = save.paiment.bic!
            teachrContact.birthDay = save.Parent.second.birthDay === "" ? null : save.Parent.second.birthDay!
            teachrContact.country = save.Parent.second.country!
            teachrContact.firstName = save.Parent.first.firstName!
            teachrContact.gender = save.Parent.second.gender!
            teachrContact.iban = save.paiment.iban!
            teachrContact.isBornFrance = save.Parent.second.isBornFrance!
            teachrContact.level = save.student.level ? parseInt(save.student.level!) : undefined
            teachrContact.name = save.Parent.first.name!
            teachrContact.phoneNumber = save.Parent.first.telephone!
            teachrContact.studentName = save.student.firstName!
            teachrContact.birthName = save.Parent.second.birthName!
            teachrContact.zipCode = (save.Parent.second.zipCode!)
            teachrContact.city = save.Parent.second.city!
            if (address) {
                address.owner = teachr['@id']
                address.isDefault = false
                address.streetName = address.streetName;
                AddressRepository.createAddress(address).then((response) => {
                    teachrContact.address = response['@id']!
                    if (teachr && defaultAddress) {
                        const object: any = teachr
                        object.address = defaultAddress['@id']!
                        if (teachr && teachr.proofIdentity) {
                            object.proofIdentity = teachr.proofIdentity['@id']
                        }
                        if (teachr && teachr.proofIsAE) {
                            object.proofIsAE = teachr.proofIsAE['@id']
                        }
                        if (teachr && teachr.proofSignSap) {
                            object.proofSignSap = teachr.proofSignSap['@id']
                        }
                        if (teachr && teachr.proofMandat) {
                            object.proofMandat = teachr.proofMandat['@id']
                        }
                        checkIfUserExist(teachrContact.mail).then((signInMethods) => {
                            if (signInMethods && signInMethods.length > 0) {
                                dispatch({
                                    type: 'SHOW_WARNING', payload: "Cet e-mail est déjà rattaché à un compte , merci de le changer pour continuer."
                                })
                                setLoading(false)
                            } else {
                                TeachrRepository.updateTeachr(object).then(() => {
                                    TeachrContactRepository.createTeachrContact(teachrContact).then((response) => {
                                        if (config.debugMode) {
                                            props.getLink(`https://staging.teachr.fr/client/complete-infos/${response.key}`)
                                        } else {
                                            props.getLink(`https://app.teachr.fr/client/complete-infos/${response.key}`)
                                        }
                                        setLoading(false)
                                        StepperStore.removeItem('dashboard_setup').then(() => props.nextStep(5))
                                    }).catch((errors) => {
                                        setLoading(false)
                                        switch (errors._description) {
                                            case "Invalid Authorization header.":
                                                dispatch({
                                                    type: 'SHOW_ERROR', payload: "Votre session a expiré, Veuillez vous reconnecter!"
                                                })
                                                navigate('/teachr/login')
                                                break;
                                            case "Constraint violation":
                                                errors._arrayInvalidAttributes.map((error: any) => {
                                                    if (error.fieldName === 'mail') {
                                                        dispatch({
                                                            type: 'SHOW_WARNING', payload: "Cet e-mail est déjà rattaché à un compte , merci de le changer pour continuer."
                                                        })
                                                    }
                                                })
                                                break;
        
                                            default:
                                                dispatch({
                                                    type: 'SHOW_ERROR', payload: "Erreur inattendu, merci de nous contacter sur support@teachr.fr"
                                                })
                                                break;
                                        }
        
                                    })
                                }).catch((errors) => {
        
                                    setLoading(false)
                                })
                            }

                        })
                        
                    }

                }).catch((errors) => {
                    setLoading(false)
                    switch (errors._description) {
                        case "Invalid Authorization header.":
                            dispatch({
                                type: 'SHOW_ERROR', payload: "Votre session a expiré, Veuillez vous reconnecter!"
                            })
                            navigate('/teachr/login')
                            break;

                        default:
                            dispatch({
                                type: 'SHOW_ERROR', payload: "Erreur inattendu, merci de nous contacter sur support@teachr.fr"
                            })
                            break;
                    }
                })
            } else {
                checkIfUserExist(teachrContact.mail).then((signInMethods)=>{
                    if (signInMethods && signInMethods.length > 0) {
                        dispatch({
                            type: 'SHOW_WARNING', payload: "Cet e-mail est déjà rattaché à un compte , merci de le changer pour continuer."
                        })
                        setLoading(false)
                    }else{
                        TeachrContactRepository.createTeachrContact(teachrContact).then((response) => {
                            setLoading(false)
                            if (config.debugMode) {
                                props.getLink(`https://staging.teachr.fr/client/complete-infos/${response.key}`)
                            } else {
                                props.getLink(`https://app.teachr.fr/client/complete-infos/${response.key}`)
                            }
                            StepperStore.removeItem('dashboard_setup').then(() => props.nextStep(5))
        
                        }).catch((errors) => {
                            setLoading(false)
                            switch (errors._description) {
                                case "Invalid Authorization header.":
                                    dispatch({
                                        type: 'SHOW_ERROR', payload: "Votre session a expiré, Veuillez vous reconnecter!"
                                    })
                                    navigate('/teachr/login')
                                    break;
                                case "Constraint violation":
                                    errors._arrayInvalidAttributes.map((error: any) => {
                                        if (error.fieldName === 'mail') {
                                            dispatch({
                                                type: 'SHOW_WARNING', payload: "Cet e-mail est déjà rattaché à un compte , merci de le changer pour continuer."
                                            })
                                        }
                                    })
                                    break;
        
                                default:
                                    dispatch({
                                        type: 'SHOW_ERROR', payload: "Erreur inattendue, merci de nous contacter via support@teachr.fr ou au 01.84.80.18.48"
                                    })
                                    break;
                            }
                        })
                    }
                })
               
            }
        }

    }
    const nextStep = () => {
        if (save) {
            setLoading(true)
            createTeachrContact(save)
        }
    }
    const prevStep = () => {
        if (save) {
            props.nextStep(3)
        }

    }
    const renderBirth = () => {
        switch (save?.Parent?.second?.isBornFrance) {
            case true:
                return (<>
                    <li className='text-sm font-bold text-grey'>Code postal de naissance :<span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(2)}>{save?.Parent?.second?.zipCode}</span></li>
                    <li className='text-sm font-bold text-grey'>Ville de naissance :<span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(2)}>{save?.Parent?.second?.city}</span></li>
                </>)
            case false:
                return (
                    <li className='text-sm font-bold text-grey'>Pays de naissance : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(2)}>{save?.Parent?.second?.country}</span></li>
                )

            default:
                return (<>
                    <li className='text-sm font-bold text-grey'>Code postal de naissance / Pays de naissance : {EMPTY_FIELD}</li>
                    <li className='text-sm font-bold text-grey'>Ville de naissance: {EMPTY_FIELD}</li>
                </>)
        }
    }
    const renderGender = (value: string | null) => {
        switch (value) {
            case '1':
                return 'Un homme'
            case '2':

                return 'Une femme'
            default:
                return ''
        }
    }
    return (
        <div className='w-full'>
            <Layout
                next={<Button onClick={() => !loading ? nextStep() : null} className={`${loading && "bg-opacity-40 cursor-default"} w-full py-2 font-extrabold text-white uppercase rounded-full  md:text-base px-14 bg-first md:w-max border-2 border-first border-solid`} >{loading ? <CircularProgress sx={{
                    'color': "#fff",
                }} /> : 'ENVOYER'}</Button>}
                prev={<Button onClick={() => !loading ? prevStep() : null} className='w-full py-2 font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first md:text-base px-14 md:w-max border-first' >précédent</Button>}
            >
                <div className='md:w-3/4'>
                    <h1 className='text-lg font-bold  lg:text-[1.2vw] text-first '>Vous pouvez maintenant envoyer le formulaire à votre client afin que celui-ci remplisse les champs manquants</h1>
                    <ul className='flex flex-col gap-1 py-4'>
                        <li className='text-sm font-bold text-grey'>Prénom de l’élève : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.student?.firstName}</span></li>
                        <li className='text-sm font-bold text-grey'>Classe de l’élève : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.student?.level && Functions.renderClass(parseInt(save?.student?.level))}</span></li>
                        <li className='text-sm font-bold text-grey'>Prénom du client : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.Parent?.first.firstName}</span></li>
                        <li className='text-sm font-bold text-grey'>Nom du client : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.Parent?.first.name}</span></li>

                        {save?.Parent.second.gender === "2" && <li className='text-sm font-bold text-grey'>Nom de naissance du client : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.Parent?.second.birthName}</span></li>}
                        <li className='text-sm font-bold text-grey'>E-mail : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>  {save?.Parent?.first.mail}</span></li>
                        <li className='text-sm font-bold text-grey'>Téléphone : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(1)}>{save?.Parent?.first.telephone}</span></li>
                        <li className='text-sm font-bold text-grey'>Civilité  : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(2)}>{renderGender(save?.Parent.second.gender!)}</span></li>
                        <li className='text-sm font-bold text-grey'>Date de naissance : <span className='pl-2 font-semibold underline cursor-pointer text-first' >{save?.Parent?.second?.birthDay}</span></li>
                        {renderBirth()}
                        {address ?

                            <li className='text-sm font-bold text-grey'>Adresse : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(2)}>{address.formattedAddress}</span></li>
                            :

                            <li className='text-sm font-bold text-grey'>Adresse : {EMPTY_FIELD}</li>
                        }
                        <li className='text-sm font-bold text-grey'>IBAN : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(3)}>{save?.paiment?.iban ? Functions.addEspacesIBAN(save?.paiment?.iban) : save?.paiment?.iban}</span></li>
                        <li className='text-sm font-bold text-grey'>BIC : <span className='pl-2 font-semibold underline cursor-pointer text-first' onClick={() => props.nextStep(4)}>{save?.paiment?.bic}</span></li>
                    </ul>
                </div>
            </Layout>
        </div>
    );
};

export default Last;