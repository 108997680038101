import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import CourseProposal from "../Objects/models/CourseProposal";
import HydraCollection from "../Objects/models/HydraCollection";
import { plainToClass } from "class-transformer";

export default abstract class CourseProposalRepository {
    /**
     * Fetch a courseProposal stored into the API by his id.
     * @param id
     */
    public static fetchCourseProposalById(id: number) {
        return ApiService.getSingleInstance<CourseProposal>(
            Route.COURSE_PROPOSAL_BASE + "/" + id,
            CourseProposal
        );
    }

    /**
     * Creates an instance of courseProposal into the API.
     * @param courseProposal
     */
    public static createCourseProposal(courseProposal: CourseProposal) {
        return ApiService.postInstance<CourseProposal>(
            Route.COURSE_PROPOSAL_BASE,
            CourseProposal,
            courseProposal
        );
    }

    // public static createPunctualCourseProposal(courseProposal: CourseProposal,teachrId? : number,courseId?: number) {
    //     return ApiService.post(`/teachrs/v2/teachrs/${teachrId}/punctual_courses/${courseId}/proposals`, courseProposal);
    // }


    /**
     * Updates the instance of the courseProposal passed as parameter.
     * @param courseProposal
     */
    public static updateCourseProposal(courseProposal: CourseProposal) {
        return ApiService.putInstance<CourseProposal>(
            Route.COURSE_PROPOSAL_BASE + "/" + courseProposal.id,
            CourseProposal,
            courseProposal
        );
    }

    /**
     * Make the client refuse the instance of the courseProposal passed as parameter.
     * @param courseProposal
     */
    public static refuseCourseProposal(courseProposal: CourseProposal) {
        return ApiService.putInstance<CourseProposal>(
            Route.COURSE_PROPOSAL_BASE +
                "/" +
                courseProposal.id +
                Route.COURSE_PROPOSAL_REFUSE,
            CourseProposal,
            courseProposal
        );
    }

    /**
     * Make the teachr able to precise the lateMinutes attribute.
     * @param courseProposal
     */
    public static lateMinutesCourseProposal(courseProposal: CourseProposal) {
        return ApiService.putInstance<CourseProposal>(
            Route.COURSE_PROPOSAL_BASE +
                "/" +
                courseProposal.id +
                Route.COURSE_PROPOSAL_LATE,
            CourseProposal,
            courseProposal
        );
    }
   

    /**
     * Removes the instance of the courseProposal passed as parameter.
     * @param courseProposal
     */
    public static deleteCourseProposal(courseProposal: CourseProposal) {
        return ApiService.deleteInstance(
            Route.COURSE_PROPOSAL_BASE + "/" + courseProposal.id
        );
    }

    /**
     * Returns number of proposals of the specified course.
     */
    public static getNumberOfCourseProposalsOfPunctualCourse(teachrId:number,punctualCourseId:number) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/punctual_courses/${punctualCourseId}/number_active_proposals`
        );
    }

    /**
     * Returns all course proposals of the specified course.
     */
    public static getCourseProposals(punctual_course_id:number) {
        return ApiService.get(
            `/clients/v2/punctual_courses/${punctual_course_id}/course_proposals`
        ).then(
            (body: any) => plainToClass(
                CourseProposal,
                body.members
            )
        );
    }

    /**
     * Returns all course proposals of the connected user.
     */
    public static getCourseProposalsTeachr(
        page: number = 1,
        filters: Array<string> | null
    ) {
        return ApiService.getCollection<HydraCollection<CourseProposal>>(
            Route.COURSE_PROPOSAL_BASE,
            HydraCollection,
            true,
            page,
            filters
        );
    }

    public static getAllProposalToGivenPunctualCourse(clientId: number, courseId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/punctual_courses/${courseId}/proposals`)
            .then(body => plainToClass(CourseProposal, body));
        ;
    }

    public static getPunctualCourseProposalById(clientId: number, proposalId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/course_proposal/${proposalId}`)
            .then(body => plainToClass(CourseProposal, body));
        ;
    }


}
