import { useEffect, useState } from "react";
import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../Helpers/Functions";
// import hours from "../../../../Assets/Images/hour.png";
import moment from "moment";
import errorIcon from "../../../../Assets/Images/error.png";
// import frLocale from "date-fns/locale/fr";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimePicker } from "antd";
import Index from "./courses/Index";
const format = "HH:mm";
// const localeMap = {
//   fr: frLocale,
// };

const CoursUrgence = (props) => {
  const [user] = useAuthState(auth);
  const end = moment().add(3, "hours");
  const start = moment;
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [endTime, setEndTime] = useState(end);
  // const [error, setError] = useState({
  //   hour: "null",
  // });

  const { currentComponent, handleNext, handleBack } = props;

  const handleChange = (value) => {
    if (moment(value) < endTime) {
      setEndTime(end);
    } else {
      setEndTime(value);
    }
  };

  const validateHour = () => {
    if (moment(endTime).diff(start, "minutes") < rangeNumber * 60) {
      return {
        isValid: false,
        message: "Merci de laisser une heure de fin de cours plus tardive.",
        icon: <img src={errorIcon} alt="" className="w-4 mt-.5 h-4" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  };
  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
    setEndTime(moment().add(newValue + 1.5, "hours"));
  };

  useEffect(() => {
    (async () => {
      const value = await StepperStore.getItem(
        user ? "courseType" + user.uid : "courseType"
      );
      if (value.type === "Urgence") {
        setRangeNumber(value.data.rangeNumber);
      }
    })()
  }, [user]);

  const handleSubmit = (event) => {
    if (validateHour()) {
      StepperStore.setItem(
        user ? "courseType" + user.uid : "courseType",
        {
          type: "Urgence",
          data: {
            rangeNumber: rangeNumber,
            endTime: endTime.toDate().valueOf(),
          },
        },

        function (err) {
          console.warn(err);
        }
      );
      props.handleNext();
    }

    event.preventDefault();
  };

  return (
    <div className="space-y-2 translate-y-28 md:space-y-6 lg:translate-y-10 md:translate-y-20">
      <div className="w-4/5 mx-auto ">
        <h5 className="text-lg font-bold text-blue-800 lg:text-3xl md:text-2xl">
          Cours en urgence :
        </h5>
      </div>
      <div className="w-4/5 mx-auto ">
        <div className="mt-6 md:w-2/3">
          <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="lg:text-lg md:text-base text-sm font-medium"
            >
              {" "}
              Durée du cours
            </label>
            <div className=" h-10 md:mt-0 -mt-2">
              <Functions.PrettoSlider
                defaultValue={1}
                step={0.5}
                min={1}
                max={4}
                value={rangeNumber}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <span className="font-bold text-dark_blue">
            <input
              type="text"
              value={Functions.renderDuration(rangeNumber * 2)}
              onChange={(e) => setRangeNumber(e.target.value)}
            />
          </span>
        </div>
        <div className="grid w-full gap-8 mx-auto mt-4 -space-y-3 md:grid-cols-2 md:space-y-0">
          <div>
            <div
              className={
                "shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md relative flex pl-12 px-4 py-5 focus:outline-none focus:ring-0 "
              }
            >
              <label className="w-full -ml-6 text-base font-medium lg:text-xl">
                Aujourd'hui
              </label>
              <div className=" ">
                <Index
                  currentComponent={currentComponent}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              </div>
            </div>
          </div>
          <div className="translate-y-4 md:translate-y-0">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Fin de disponibilité :
              </label>
              <div className="-ml-3">
                <div
                  className={
                    "flex items-center md:hidden justify-between w-full ml-1 pt-2 rounded-lg "
                  }
                >
                  <TimePicker
                    value={endTime}
                    onChange={(newValue) => {
                      handleChange(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="small"
                    suffixIcon=""
                  />
                </div>
                <div
                  className={
                    "md:flex items-center hidden justify-between w-full rounded-lg "
                  }
                >
                  <TimePicker
                    value={endTime}
                    onChange={(newValue) => {
                      handleChange(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon=""
                  />
                </div>
              </div>
            </div>
            {/* <div className="bg-input-color block border-grey-300 text-grey-900 text-sm rounded-xl pl-4 py-2  md:p-2.5 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
              <label className="block text-base font-medium md:mb-2 lg:text-xl">
                Fin de disponibilité :
              </label>
              <div className="lg:text-lg md:text-base text-sm -mt-2">
              <TimePicker
                     value={endTime}
                     onChange={(newValue) => {
                      handleChange(newValue)
                     }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon={
                      <img src={hours} alt="calendar icon" className="w-8" />
                    }
                  />
              </div>
            </div> */}
            {/* <div className="flex w-full mx-auto space-x-2 translate-y-4 ">
              {error.hour === "null" || error.hour === ""
                ? " "
                : validateHour().icon}
              <p className="text-[#FF724F] h-6 w-full ">
                {error.hour === "null" || error.hour === ""
                  ? null
                  : validateHour().message}
              </p>
            </div> */}
            <div></div>
          </div>
        </div>
        <div className="md:hidden  flex justify-around mx-auto space-x-4 translate-y-28 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
            <div
              onClick={handleBack}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex hidden justify-between mx-auto translate-y-20 md:translate-y-20 md:w-full">
          <div className="flex justify-center space-x-2">
            <button
              type="button"
              onClick={props.handleBack}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center space-x-2 ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className={
                // error === "null"
                  // ? 
                  " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  // : error === ""
                  // ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  // : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
              }
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursUrgence;
