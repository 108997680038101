import React, { useEffect, useState } from 'react';
import SimulatorNumberInput from '../../../../Components/utils/SimulatorNumberInput';
import { useDispatch, useSelector } from 'react-redux';
import Simulator from '../../../../Objects/models/Simulator';
interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: Simulator
  }
const First = (props: any) => {
    const [adult, setAdults] = useState(1)
    const [child, setChilds] = useState(0)
    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    const dispatch = useDispatch()
    useEffect(() => {
        if (adult > 0 && child >= 0 && simulatorData ) {
            dispatch({
                type: 'SET_INFO', payload: {
                    weekHours: simulatorData.weekHours,
                    hourlyPrice: simulatorData.hourlyPrice,
                    adults: adult,
                    childs: child,
                    last_income: simulatorData.last_income,
                    external_income: simulatorData.external_income,
                    education: simulatorData.education,
                    isAcre: simulatorData.isAcre,
                    response: null
                }
            })
            props.callBack(true)
        } else {
            props.callBack(false)
        }
    }, [adult, child])
    const getChild = (value: string) => {
        setChilds(parseInt(value))
    }
    const getAdult = (value: string) => {
        setAdults(parseInt(value))
    }
    useEffect(() => {
        if (simulatorData) {
            setAdults(simulatorData.adults)
            setChilds(simulatorData.childs)
        }
    }, [simulatorData])
    return (
        <div className='flex flex-col gap-y-4'>
            <SimulatorNumberInput callBack={getAdult} placeholder="Ex : 2" label="Nombre d’adultes" />
            <SimulatorNumberInput callBack={getChild} placeholder="Ex : 1" label="Nombre d’enfants dans le foyer fiscal" />
        </div>
    );
};

export default First;