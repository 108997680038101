import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import frLocale from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers';

const localeMap = {
  fr: frLocale,
};

export default function DateTime(props:any) {
  const [locale] = React.useState<keyof typeof localeMap>('fr');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[locale]}
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <div className='relative md:hidden font-nunito'>
       <MobileDatePicker
          className='text-sm border-2 font-nunito lg:text-lg md:text-base'
          showToolbar={false}
          inputFormat="dd/MM/yyyy"
          closeOnSelect
          disablePast={true}
          toolbarTitle="Sélectionner la date"
          value={props.value}
          onChange={(val)=>props.callBack(val)}
          renderInput={(params) => <TextField {...params} 
          placeholder="Ex : 24/04/2022"
          sx={{
            width: "100%",
            paddingTop:'-1rem',
            
            "& .MuiOutlinedInput-root": {fontSize:"14px", fontFamily:'nunito'},
            "& fieldset": {
              border: "none",
              textDecoration: "black",
            },
          }}/>}
        />
       </div>
      <div className='relative hidden md:flex md:h-10 -translate-y-2 //translate-x-4  font-nunito'>
       <MobileDatePicker
          className='text-sm border-2 font-nunito lg:text-lg md:text-base'
          showToolbar={false}
          inputFormat="dd/MM/yyyy"
          closeOnSelect
          disablePast={true}
          toolbarTitle="Sélectionner la date"
          value={props.value}
          onChange={(val)=>props.callBack(val)}
          renderInput={(params) => <TextField {...params} 
          placeholder="Ex : 24/04/2022"
          sx={{
            width: "100%",
        padding:'-2rem',
            
            "& .MuiOutlinedInput-root": {fontSize:"18px", fontFamily:'nunito'},
            "& fieldset": {
              border: "none",
              textDecoration: "black",
            },
          }}/>}
        />
       </div>
    </LocalizationProvider>
  );
}
