import React, { useEffect, useState } from 'react';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';
import First from './First';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';
import Fifth from './Fifth';
import Last from './Last';
import Teachr from '../../../../Objects/models/Teachr';
import { useSelector } from 'react-redux';
import Sixth from './Sixth';
import Finish from './Finish';



const Index = (props: any) => {
    const navigate = useNavigate()
    const [step, setStep] = useState<number>(0)
    const [open, setOpen] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    const generalTeachr = useSelector((state: any) => state.teachr)
    useEffect(() => {
        if (generalTeachr.teachr) {
            setTeachr(generalTeachr.teachr)
            if (generalTeachr.teachr.proofMandat) {
                props.getStep(7)
            }
        }
    }, [generalTeachr, props])
    const nextStep = (value: number) => {
        props.getStep(value)
        setStep(value)
    }
    const renderScreen = () => {
        switch (step) {
            case 0:
                if (teachr) {
                    if (teachr.signSap || teachr.proofIsAE || teachr.isAE || teachr.proofSignSap !== null) {
                        nextStep(4)
                    } else {
                        return <First nextStep={nextStep} />
                    }
                }
                break;
            case 1:
                return <Second nextStep={nextStep} />
            case 2:
                return <Third nextStep={nextStep} />
            case 3:
                if (teachr) {
                    if (teachr.signSap || teachr.proofSignSap) {
                        nextStep(4)
                    } else {
                        return <Fourth nextStep={nextStep} />
                    }
                }
                break;
            case 4:
                if (teachr) {
                    if (teachr.address && teachr.RCS) {
                        nextStep(5)
                    } else {
                        return <Fifth nextStep={nextStep} />
                    }
                }
                break;
            case 5:
                return <Sixth nextStep={nextStep} />
            case 6:
                return <Last nextStep={nextStep} />
            default:
                return <First nextStep={nextStep} />
        }
    }
    if (teachr) {
        return (
            <div className='flex flex-col h-full gap-y-8'>
                <div className='flex flex-col gap-y-4'>
                    <h2 className='text-xl font-extrabold lg:text-3xl text-first'>Bonjour {teachr.firstName} 👋🏻</h2>
                    <h3 className='text-lg font-extrabold lg:text-2xl text-first'>Finalisez vite votre profil ! </h3>
                </div>
                {renderScreen()}
                {
                    step !== 6 ? <div className="left-0 flex justify-end w-full py-4 bg-white  md:sticky md:bottom-0">
                        <p className='text-sm   cursor-pointer border-[#D9D9D9] text-[#D9D9D9] lg:text-lg' onClick={() => setOpen(true)}>Non merci</p>
                    </div> : null
                }
                <Dialog
                    fullWidth
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        "& .MuiPaper-root": {
                            borderRadius: "42px",
                            position: "relative",
                            overflow: "visible",
                            boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)"

                        }
                    }}
                >
                    <div className="relative flex flex-col py-4 md:p-4 gap-y-4">
                        <div className="absolute right-10 top-8">
                            <span onClick={() => setOpen(false)} className='cursor-pointer'>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.4112 0.340365C15.9505 -0.113469 15.2035 -0.11344 14.7428 0.340365L8.48628 6.50342L2.22976 0.340383C1.76906 -0.113452 1.02209 -0.113452 0.561362 0.340383C0.100629 0.794246 0.10065 1.53004 0.561364 1.98388L6.81787 8.14689L0.561382 14.3099C0.100674 14.7637 0.10065 15.4995 0.561382 15.9534C1.02211 16.4072 1.76908 16.4072 2.22979 15.9534L8.48628 9.79035L14.7428 15.9534C15.2035 16.4072 15.9505 16.4073 16.4112 15.9534C16.8719 15.4996 16.8719 14.7638 16.4112 14.3099L10.1547 8.14689L16.4112 1.98384C16.8719 1.53001 16.8719 0.794228 16.4112 0.340365Z" fill="#808080" />
                                </svg>

                            </span>
                        </div>
                        <DialogTitle id="alert-dialog-title" >
                            <h2 className='py-4 font-extrabold text-center uppercase  text-first lg:text-2xl'> ⚠️ Attention</h2>
                        </DialogTitle>
                        <DialogContent className='flex flex-col gap-y-8'>
                            <p className='text-base font-semibold text-center text-first lg:text-xl'>En cochant cette case vous ne pourrez pas utiliser l’avance immédiate pour vos élèves et ne pourrez accéder au crédit d’impôt  </p>
                            <p className='text-base font-semibold text-center text-first lg:text-xl'>De plus, vous n’aurez pas accès au tableau de bord vous pourrez seulement postuler aux cours à domicile des élèves en tant que salarié de la famille</p>
                        </DialogContent>
                        <DialogActions className='flex flex-col gap-y-2'>
                            <Button onClick={() => setOpen(false)} className='px-10 py-2 font-extrabold text-white rounded-full bg-first' >ME RENSEIGNER</Button>
                            <p className='text-base font-semibold text-center underline cursor-pointer text-first lg:texxt-xl' onClick={() => navigate('/teachr/cours')}>Renoncer à l’avance immédiate</p>
                        </DialogActions>
                    </div>
                </Dialog>
            </div>
        )
    }
    return (<div></div>)
};

export default Index;