import React, { useEffect, useState } from "react";
import imageFond from "../../../Assets/Images/Group (2).png";
import StepperStore from "../../../LocalForage/StepperStore";
import { useNavigate } from "react-router-dom";


const LastStep = (props:any) => {
  const navigate = useNavigate();
const date = Date.now()
  const handleP = () => {
    navigate('/user/timer', {
      state: date 
    })
  }
  
  const handleNext = () => {
    handleP()
    StepperStore.clear();
  };
  const [password, setPassword] = useState();

  const getPassword = async () => {
    const value: any = await StepperStore.getItem("setPassword");
    if (value !== null) {
      setPassword(value.password);
    }
  };

  useEffect(() => {
    getPassword();
  }, []);

  return (
    <div className="font-nunito space-y-2 translate-y-28 lg:space-y-6 md:space-y-4 lg:translate-y-10 md:translate-y-20">
      <div className="w-4/5 mx-auto ">
        <h5 className="text-xl font-bold text-dark_blue lg:text-3xl md:text-2xl">
          Félicitations !
        </h5>
      </div>
      <div className="flex justify-between w-4/5 mx-auto">
        <div className=" md:w-3/4  lg:text-lg xl:text-xl md:text-md text-[4d4d4d] text-sm">
          <p className="">
            Votre demande de cours a bien été créée et les professeurs inscrits
            sur Teach'r viennent d'être notifiés. Nous vous avons créé un mot de
            passe temporaire :
            <strong className="text-xl text-dark_blue font-bold">
              {" "}
              {password}
            </strong>
          </p>
          <br />
          <p>
            Pensez à le modifier via le mail de réinitialisation de mot de passe
            que nous venons de vous envoyer 😉
            </p>
        </div>
        <div className="hidden md:flex  md:w-1/2 mx-auto lg:pl-12">
          <div className=" w-full">
            <img src={imageFond} alt="" />
          </div>
        </div>
      </div>
      <div className="md:w-1/2 cursor-pointer mx-auto w-3/5 p-2 lg:text-base  md:translate-y-8 translate-y-44  bg-dark_blue text-center lg:py-2 lg:px-4 rounded-full uppercase text-sm md:text-xs ml-20 //lg:ml-32 ">
        <div
          onClick={handleNext}
          className=" text-white mx-auto cursor-pointer font-extrabold"
        >
          Menu principal
        </div>
      </div>
    </div>
  );
};

export default LastStep;
