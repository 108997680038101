import errorIcon from "../Assets/Images/error.png";

export function validatePhoneNumber(phone) {
  phone = phone.replace(/ +/g, "")
  const character = phone.split("");
  let message = "";
  switch (character[0]) {
    case "+":
      if (character.length !== 12) {
        message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
      } else {
        if (character[2] === '3' && character[1] === "3") {
          if (character[3] === "7" || character[3] === "6" || character[3] === "1") {
            message = "";
          }else{
            message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
          }
        }else{
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
        }
      }
      
      break;
    case "0":
      if (character.length !== 10) {
        message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
      } else {
        if (character[1] === "7" || character[1] === "6" || character[1] === "1") {
          message = "";
        }else{
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        }
       
      }

      break;
    case "7":
      if (character.length !== 9) {
        message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
      } else {
        message = "";
      }

      break;
    case "6":
      if (character.length !== 9) {
        message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
      } else {
        message = "";
      }
      break;

      case "1":
        if (character.length !== 9) {
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        } else {
          message = "";
        }
        break;

    default:
      message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
      break;
  }
  return message;
}
// export function validatePhoneNumber(phone) {
//   phone = phone.replace(/ +/g, "")
//   const character = phone.split("");
//   let message = "";
//   switch (character[0]) {
//     case "+":
//       if (character.length !== 12) {
//         message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div> ;
//       } else {
//         if (character[2] === '3' && character[1] === "3") {
//           if (character[3] !== '7' || character[3] === '6') {
//             message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//           }else if(phone === ""){
//             message = ''
//           }
        
//         }else if(phone === ""){
//           message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//         }
//       }
      
//       break;

//     case "0":
//       if (character.length !== 10) {
//         message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//       } else if(phone === ""){
//         message = "";
//       }

//       break;
//     case "7":
//       if (character.length !== 9) {
//         message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//       } else if(phone === ""){
//         message = "";
//       }

//       break;
//     case "6":
//       if (character.length !== 9) {
//         message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//       } else if(phone === ""){
//         message = "";
//       }
//       break;

//     default:
//       message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
//       break;
//   }
//   return message;
// }


