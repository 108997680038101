import React from 'react';
import First from '../Components/Monabanq/First';
import Second from '../Components/Monabanq/Second';
import Header from '../Components/Monabanq/Header';
import Third from '../Components/Monabanq/Third';
import Offers from '../Components/Monabanq/Offers';
import Cgu from '../Components/Monabanq/Cgu';
import Footer from '../Components/Monabanq/Footer';

const MonaBanq = () => {
    return (
        <main className='font-sans'>
            <Header />
            <First />
            <Second />
            <Third />
            <Offers />
            <Cgu />
            <Footer />
        </main>
    );
};

export default MonaBanq;