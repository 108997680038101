export function validatePhoneNumber(phone) {
  const character = phone.split("");
  let message = "";
  switch (character[0]) {
    case "0":
      if (character.length !== 10) {
        message = "Téléphone invalide";
        
      } else {
        message = "";
      }

      break;
    case "7":
      if (character.length !== 9) {
        message = "Téléphone invalide";
      } else {
        message = "";
      }

      break;
    case "6":
      if (character.length !== 9) {
        message = "Téléphone invalide";
      } else {
        message = "";
      }
      break;

    default:
        message = "Téléphone invalide";
      break;
  }
  return message;
}
