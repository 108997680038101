import { Button } from '@mui/material';
import React from 'react';
import illu from '../../../../Assets/Images/detente.svg'
const Subscribe = () => {
    return (
        <div className='h-full px-4 mx-auto lg:w-[95%] md:px-8'>
            <div>
                <h2 className='font-extrabold text-first lg:text-[34px] uppercase text-lg'>Aucun frais d’inscription, aucune démarches administratives</h2>
            </div>
            <div className="flex flex-col items-center justify-between pt-10 gap-x-8 md:flex-row">
                <div className=' h-[calc(100vh_-_25.5rem)]  bg-center bg-no-repeat bg-contain w-full' style={{ backgroundImage: `url(${illu})` }}>
                </div>
                <div className='pt-8'>
                    <div className='flex flex-col pb-14 gap-y-8'>
                        <p className='font-bold text-first lg:text-[26px]'><span className="font-extrabold">100% gratuit</span><br />Accédez à notre plateforme et à tout ces avantages gratuitement.</p>
                        <p className='font-bold text-first lg:text-[26px]'><span className="font-extrabold">Aucune démarches administratives</span><br />Vous ne souhaitez pas faire les démarches en autonomie ? Contactez notre conseillé. </p>
                        <p className='font-bold text-first lg:text-[26px]'>Inscrivez-vous sur la liste d’attente afin de prendre rendez vous avec notre conseiller et rejoindre notre plateforme.</p>
                    </div>
                    <Button className='w-full py-2 font-extrabold text-white rounded-full bg-first lg:text-2xl'>Inscription liste d’attente </Button>
                </div>
            </div>
        </div>
    );
};

export default Subscribe;