import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import CreditCard from "../../../../../Objects/models/CreditCard";
import ClientRepository from "../../../../../Repositories/ClientRepository";
import CreditCardRepository from "../../../../../Repositories/CreditCardRepository";
import { APIErrorEnum } from "../../../../../Objects/enums/APIErrorEnum";
import config from "../../../../../config";
import { useMemo, useState } from "react";
import master from "../../../../../Assets/Images/mastercard.png";
import visas from "../../../../../Assets/Images/visa.png";
import { CircularProgress } from "@mui/material";

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Nunito, sans-serif",
          fontSize: "18px",
          "::placeholder": {
            color: "#aab7c4",
            fontSize: "18px",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};
const AddCards = (props) => {
  let timerInterval;
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const Swal = require("sweetalert2");
  const [brand, setBrand] = useState();
  const [loading, setLoading] = useState(false);

  const onAjouterPress = async () => {
    setLoading(true);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    const paymentMethodId = payload.paymentMethod.id;
    CreditCardRepository.initiateIntent(paymentMethodId)
      .then(async (result) => {
        setLoading(true);
        if (result.needAuthentification) {
          // need 3D secure
          try {
            setLoading(true);
            await stripe.confirmCardSetup(result.clientSecret)
            createCart(result.setupIntentId);
          } catch (e) {
            setLoading(false);
            Swal.fire({
              icon: "warning",
              title: "Attention !",
              text: "Le paiement n'a pas été autorisé par votre banque.",
              timer: 2000,
              willClose: () => {
                clearInterval(timerInterval);
              },
            });
          }
        } else {
          // do not need 3D secure
          createCart(result.setupIntentId);
        }
      })
      .catch((error) => {
        setLoading(false);
        alert("Carte invalide.");
      });
    setLoading(false);
    
  };

  const createCart = async (token) => {
    setLoading(true);
    const client = await ClientRepository.getCurrentClient();
    let card = new CreditCard(client["@id"], token, true);
    try {
      setLoading(true);
      await CreditCardRepository.createCreditCard(card);
      props.handleLaoding(true);
      Swal.fire({
        icon: "success",
        title: "Félicitations !",
        text: "Votre carte bancaire a bien été ajoutée",
        timer: 4000,
        willClose: () => {
          clearInterval(timerInterval);
        },
      });
    } catch (error) {
      setLoading(false);
      props.handleLaoding(false);
      switch (error.description) {
        case APIErrorEnum.CREDIT_CARD_ALREADY_REGISTERED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà enregistré cette carte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.CREDIT_CARD_ALREADY_USED_BY_ANOTHER:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Cette carte est déjà rattachée à un autre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case "The setupIntent is invalid or is not confirmed.":
          console.warn(error.description);
          // Swal.fire({
          //   icon: "warning",
          //   title: "Attention !",
          //   text: "The setupIntent is invalid or is not confirmed.",
          //   timer: 2000,
          //   willClose: () => {
          //     clearInterval(timerInterval);
          //   },
          // });
          break;
        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text:
              "Hmmm... erreur inconnue. Contactez-nous à l'adresse support@teachr.fr ou au " +
              config.phoneNumber +
              " si le problème persiste !",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
      }
    }
  };

  return (
    <div>
      <div className="mt-2 z-50 mx-auto">
        <div className=" space-y-8">
          <div className="mx-auto ">
            <div className="bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm shadow-inputCard">
              <label htmlFor="" className="md:truncate text-dark_blue">
                Carte de paiement
              </label>
              <div className="flex">
                <div className=" w-3/4">
                  <CardNumberElement
                    options={options}
                    onChange={(e) => setBrand(e.brand)}
                  />
                </div>
                <div className=" mx-auto w-1/4">
                  {brand === "visa" ? (
                    <img src={visas} alt="" className=" -mt-2  mx-auto" />
                  ) : brand === "mastercard" ? (
                    <img src={master} alt="" className="-mt-4  mx-auto" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-4 justify-between w-4/5 ">
            <div className="w-1/2">
              <div className="bg-input-color rounded-md py-2 px-4 w-full lg:text-lg md:text-base text-sm shadow-inputCard">
                <label htmlFor="" className="md:truncate text-dark_blue">
                  Expire
                </label>
                <CardExpiryElement options={options} />
              </div>
            </div>
            <div className="w-1/2">
              <div className="bg-input-color rounded-md py-2 px-4 w-full lg:text-lg md:text-base text-sm shadow-inputCard">
                <label htmlFor="" className="md:truncate text-dark_blue">
                  CVC / CCV
                </label>
                <CardCvcElement options={options} />
              </div>
            </div>
          </div>
        </div>
        <div className="flex -mt-10 cursor-pointer  md:translate-y-72  translate-y-56 z-50   justify-around md:w-full ">
          <div
            className="border-2 z-50 w-full bg-white border-dark_blue rounded-full py-2 uppercase text-dark_blue text-center text-sm lg:text-[17px] font-semibold transition duration-150 ease-in-out"
            onClick={() => onAjouterPress()}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: "blue",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              "valider"
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCards;
