/**
 * Different possible hydra errors
 */
export enum APIErrorEnum {
    // The object is not found
    NOT_FOUND = "Not Found",

    // The Firebase key is invalid
    INVALID_AUTHORIZATION_TOKEN = "The Firebase Auth token provided is invalid.",
    // The Firebase key is not a key... (could be "test" for example)
    INVALID_AUTHORIZATION_HEADER = "Invalid Authorization header.",
    // The user already exists
    USER_ALREADY_EXISTS = "This user is already registered.",

    // There is wrong attribute format
    WRONG_ATTRIBUTE_FORMAT = "Constraint violation",

    // Limit of requests reached (for example too much creation of addresses)
    LIMIT_REACHED = "API rate limit exceeded.",

    // PUNCTUAL_COURSE

    PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS = "The client must own at least one address.",
    PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED = "The client has already too much pending reservations.",
    PUNCTUAL_COURSE_DURATION_LIMIT_MAX = "The duration of the course is limited to 12 hours.",
    PUNCTUAL_COURSE_DURATION_LIMIT_MIN = "The minimum duration of the course is 1 hour.",
    PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING = "The range of hours is not large enough compared to the duration asked.",
    PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR = "This is not a course in the hour.",
    PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR = "This is a course in the hour.",
    PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED = "You're not authorized to ask a direct course with this Teach'r.",
    PUNCTUAL_COURSE_PROMOTIONAL_CODE_NOT_AUTHORIZED = "The client is not authorized to use this promotional code.",
    PUNCTUAL_COURSE_PROMOTIONAL_CODE_NOT_VALID_FOR_THIS_COURSE = "The promotional code cannot be applied to this course.",
    PUNCTUAL_COURSE_IMPORTANT_MODIFICATION_IS_NO_MORE_ALLOWED = "Some teach'rs have applied. You cannot make important modifications.",
    PUNCTUAL_COURSE_EDIT_NO_MORE_ALLOWED = "The course is payed. Editing this course is no more allowed.",
    PUNCTUAL_COURSE_CLIENT_MUST_FILL_URSSAF_INFORMATION = "The client must complete URSSAF required information.",
    PUNCTUAL_COURSE_CLIENT_MUST_SET_A_CREDIT_CARD = "The client has any saved credit card.",
    PUNCTUAL_COURSE_CANNOT_DELETE_ALREADY_OCCURED = "You cannot delete this course as it has started or has already occurred.",

    COURSE_PROPOSAL_TEACHR_ADDRESS = "The teach'r must have defined an address.",
    COURSE_PROPOSAL_ALREADY_APPLIED = "The Teach'r has already made a proposal for this course.",
    COURSE_PROPOSAL_TEACHR_APPLIES_BEFORE_WANTED_BY_CLIENT = "The start datetime proposed by the Teach'r is anterior to the Client desired one.",
    COURSE_PROPOSAL_TEACHR_APPLIES_AFTER_WANTED_BY_CLIENT = "The end datetime proposed by the Teach'r is posterior to the Client desired one.",
    COURSE_PROPOSAL_SEX_NOT_CORRESPONDING = "The sex preference of the course is not corresponding to the Teach'r one.",
    COURSE_PROPOSAL_TEACHR_HAS_ALREADY_A_COURSE_AT_THIS_TIME = "The Teach'r has already an accepted course for this timeslot.",
    COURSE_PROPOSAL_MAX_PROPOSALS_REACHED = "There is already MAX proposals for this course.",
    COURSE_PROPOSAL_TEACHR_MUST_FILL_URSSAF = "The teach'r must fill URSSAF attributes.",
    COURSE_PROPOSAL_LATE_MINUTE_CANNOT_EDIT_HAS_COURSE_IF_OVER = "You cannot edit the late minutes attributes as the course is over.",

    ADRESS_INVALID = "This address is not valid.",

    CHILD_CANNOT_DELETE_HAS_PENDING_COURSES = "You can't delete this child as he has pending courses.",
    CHILD_CANNOT_HAVE_MORE_THAN_TEN = "You cannot have more than 10 children.",

    CREDIT_CARD_ALREADY_REGISTERED = "You already have registered this card.",
    CREDIT_CARD_ALREADY_USED_BY_ANOTHER = "This card is already used by another user.",

    REVIEW_COURSE_NOT_ENDED = "The course is not over. You must wait before making a review.",

    TEACHR_IBAN_INVALID = "The IBAN is not valid.",
    TEACHR_STRIPE_ERROR = "Cannot create the Stripe account.",

    IMAGE_SIZE_TOO_LARGE = "The file size must not exceed 5 mbits.",
    IMAGE_FORMAT_NOT_SUPPORTED = "Non supported image format.",

    UNKNOWN = "unknown",
    // TODO
}

/**
 * Used for wrong attributes format
 */
export enum InvalidAttributesNameEnum {
    PUNCTUAL_COURSE_CHAPTER_LENGTH_MAX = "/assert/chapter/length/max",
    PUNCTUAL_COURSE_STARTDATETIME_FUTURE = "/assert/startDatetime/future",
    PUNCTUAL_COURSE_ENDDATETIME_FUTURE = "/assert/endDatetime/future",
    PUNCTUAL_COURSE_ENDDATETIME_NEXT_MONTH = "/assert/endDatetime/nextmonth",
    PUNCTUAL_COURSE_CHAPTER_EXTRA_INFORMATION_LENGTH_MAX = "/assert/chapterExtraInformation/length/max",
    PUNCTUAL_COURSE_GLOBAL_EXTRA_INFORMATION_LENGTH_MAX = "/assert/globalExtraInformation/length/max",
}
