// import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import validateCustom from "../../../../../../Validations/CustomList";
import "antd/dist/antd.css";
import cours_regulier from "../../../../../../Assets/Images/cours_regulier.png";
// import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../../../Helpers/Functions";
import { styled, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { InputBase } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DatePickers from "../../../../../../Components/utils/DatePickers";
// import { auth } from "../../../../Services/FirebaseService";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { TimePicker } from "antd";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import { auth } from "../../../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

// const format = "HH:mm";
const days = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
};
const TeachrInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 14,
  },
}));
const TeachrInputs = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 17,
  },
}));
const Menu = styled(MenuItem)(({ theme }) => ({
  "&": {
    border: "2px solid #0054A3",
    borderRadius: "5px",
    color: "#0054A3",
    padding: ".5rem",
    paddingLeft: "4rem",
    margin: ".2rem",
    textAlign: "center !important",
  },
  "&.Mui-selected": {
    border: "2px solid #0054A3",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#0054A3",
  },
}));
function getStyles() {
  return {
    fontWeight: "bold",
    fontFamily: "Nunito",
  };
}
const useStyles = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "grid !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3.5rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 15,
    },
  },
});
const useStylesMobile = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "block !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3rem ",
      paddingRight: "2rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 12,
    },
  },
});

const StepSubscription = (props) => {
  const {handleChangeStep} = props
  const [user] = useAuthState(auth)
  const classes = useStyles();
  const classe = useStylesMobile();
  const starts = moment().add(1, "hours");
  let minute = starts.minute(); 
  if (minute % 5 !== 0) { // Si les minutes ne sont pas divisibles par 5
    minute += (5 - (minute % 5)); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  const heureAvecMinute = moment(starts).minute(minute).toDate();
  // const mostLikelyTime = moment();
  // mostLikelyTime.set({ hour: 17, minute: 0 });
  const today = new Date();
  const tomorrow = today.setDate(today.getDate())
  // mostLikelyTime.set({ hour: 17, minute: 0 });
  // const start = moment().set({ hour: 17, minute: 0 });
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [date, setDate] = useState(tomorrow);
  const [startTime, setStartTime] = useState(heureAvecMinute);
  const [error, setError] = useState({
    day: "null",
  });
  const getDate = (value) => {
    setDate(value);
  };

  const theme = useTheme();
  const [day, setDay] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDay(typeof value === "string" ? value.split(",") : value);
  };

  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
  };

  const handleChangeTime = (value) => {
    let startTimes = moment(value);
    let startDate = moment(date);
    let startDateTime = startDate
      .set("hour", startTimes.hours())
      .set("minutes", startTimes.minutes())
    if (startDateTime < startTime) {
      setStartTime(heureAvecMinute);
      // setEndTime(
      //   moment(heureAvecMinute).add(rangeNumber, "hours").toDate()
      // );
    } else {
      setStartTime(value);
      // setEndTime(
      //   moment(value).add(rangeNumber, "hours").toDate()
      // );
    }
  };


  const validate = () => {
    let errday = true;
    if (!validateCustom(day).isValid) {
      setError({ day: validateCustom(day).message });
      errday = true;
    } else {
      setError({ day: validateCustom(day).message });
      errday = false;
    }

    if (errday) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    StepperStore.removeItem("activeStepundefined")
  }, [])

  const handleSubmit = () => {
    if (validate()) {
      StepperStore.setItem("regulier" + user.uid, {
        date: date,
        startTime: startTime,
        day: day,
        rangeNumber: rangeNumber,
      });
      handleChangeStep(6);
    }
  };

  const calculateTotalHours = () => {
    let total = rangeNumber * day.length;
    return total;
  };

  return (
    <div className="overflow-y-auto  md:w-full w-[100%] list-teacher relative md:h-[100%] h-[120%]  bg-white xl:px-14 px-4 md:py-6 py-4 rounded-lg shadow-dashboard-setup-intro-form">
      <div className="space-y-4">
        <div className="//w-1/2 flex space-x-3">
          <div>
            <p className="text-dark_blue font-extrabold lg:text-xl text-lg">
              Cours régulier
            </p>
          </div>
          <div>
            <img
              src={cours_regulier}
              alt=""
              className="rounded-full w-8 //mt-[4px]"
            />
          </div>
        </div>
        <div className=" mx-auto md:-translate-y-0 -translate-y-2">
          <div className="mt-4 md:w-2/3 md:mt-6">
            <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="text-sm font-medium lg:text-lg md:text-base"
              >
                {" "}
                Nombre d'heures par jour
              </label>
              <div className="h-10 -mt-2  md:mt-0">
                <Functions.PrettoSlider
                  defaultValue={1}
                  step={0.5}
                  min={1}
                  max={4}
                  value={rangeNumber}
                  onChange={handleSliderChange}
                />
              </div>
            </div>
            <span className="font-bold translate-y-3 bg-transparent text-dark_blue">
              <input
                type="text"
                className=" bg-transparent"
                value={Functions.renderDuration(rangeNumber * 2) + "/jour"}
                onChange={(e) => setRangeNumber(e.target.value)}
              />
            </span>
          </div>
          <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 md:mt-8 mt-1 px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="text-sm font-medium lg:text-lg md:text-base"
            >
              Choisissez vos jours :
            </label>
            <div className="relative hidden  font-nunito md:block">
              <Select
                sx={{
                  width: "100%",
                  paddingLeft: "",
                  fontFamily: "nunito",
                  background: "#F2F5FB !important",
                }}
                multiple
                MenuProps={{ classes: { paper: classes.select } }}
                label={"Text"}
                value={day}
                onChange={handleChange}
                input={<TeachrInputs />}
                placeholder="Lundi, Mardi, Mercredi..."
                className="text-xs"
              >
                {Object.entries(days).map(([key, day]) => (
                  <Menu
                    key={day}
                    value={key}
                    style={getStyles(day, day, theme)}
                    placeholder="Lundi, Mardi, Mercredi..."
                    className="text-sm text-center border lg:text-lg md:text-base"
                  >
                    {day}
                  </Menu>
                ))}
              </Select>
            </div>
            <div className="relative mt-1  font-nunito md:hidden">
              <Select
                sx={{
                  width: "100%",
                  paddingLeft: "",
                  fontFamily: "nunito",
                  background: "#F2F5FB",
                }}
                multiple
                label={"Text"}
                value={day}
                onChange={handleChange}
                input={<TeachrInput />}
                MenuProps={{ classes: { paper: classe.select } }}
                placeholder="Lundi, Mardi, Mercredi..."
                className="text-xs displayDay"
              >
                {Object.entries(days).map(([key, day]) => (
                  <Menu
                    key={day}
                    value={key}
                    style={getStyles(day, day, theme)}
                    placeholder="Lundi, Mardi, Mercredi..."
                    className="text-sm text-center border lg:text-lg md:text-base "
                  >
                    {day}
                  </Menu>
                ))}
              </Select>
            </div>
          </div>
          <span className="font-bold bg-transparent text-dark_blue">
            <input
              className="bg-transparent"
              type="text"
              value={
                "Total : " +
                Functions.renderDuration(calculateTotalHours() * 2) +
                "/semaine"
              }
              onChange={(e) => setRangeNumber(e.target.value)}
            />
          </span>
          <div className="flex w-full mx-auto space-x-2  md:translate-y-1 md:mb-4">
            {error.day === "null" || error.day === ""
              ? " "
              : validateCustom(day).icon}
            <p className="text-[#FF724F] h-6 w-full lg:text-base  md:text-sm text-xs md:-mt-1  ">
              {error.day === "null" || error.day === ""
                ? null
                : validateCustom(day).message}
            </p>
          </div>

          <div className="block w-full gap-8 mx-auto space-y-6 md:space-y-0 md:flex mt-">
            <div className="md:w-1/2">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
                <label
                  htmlFor=""
                  className="text-sm font-medium lg:text-lg md:text-base"
                >
                  {" "}
                  À partir du :
                </label>
                <div className="-mt-6 translate-y-2 md:-mt-3 md:translate-y-0 ">
                  <div className="relative -ml-4 ">
                    <DatePickers value={date} callBack={getDate} />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
                <label
                  htmlFor=""
                  className="text-sm font-medium lg:text-lg md:text-base"
                >
                  {" "}
                  À partir de :
                </label>
                <div className="-ml-3">
                  <div
                    className={
                      "flex items-center md:hidden justify-between w-full ml-1 pt-2 rounded-lg "
                    }
                  >
                    <DatePicker
                      selected={startTime}
                      value={startTime}
                      onChange={(newValue) => {
                        handleChangeTime(newValue);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      timeCaption=""
                      locale="fr"
                      placeholderText="Sélectionner l'heure"
                      customInput={<input className='//border-2 //text-center mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                      // timeInputLabel="Heure :"
                      renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                        <div className="time-picker-header border-4 border-red-2 flex justify-between items-center h-12 bg-red-400 px-4">
                          <button className="text-red-700 text-2xl" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{'<'}</button>
                          <span className="text-red-800 text-xl font-bold">{format(date, 'MMMM yyyy', { locale: 'fr' })}</span>
                          <button className="text-red-700 text-2xl" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                        </div>
                      )}
                    />
                  </div>
                  <div
                    className={
                      "md:flex items-center hidden justify-between w-full rounded-lg "
                    }
                  >
                    <DatePicker
                      selected={startTime}
                      value={startTime}
                      onChange={(newValue) => {
                        handleChangeTime(newValue);
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      timeCaption=""
                      locale="fr"
                      placeholderText="Sélectionner l'heure"
                      customInput={<input className='//border-2 //text-center mx-auto w-[100%] //border-2 pl-4 py-2 bg-transparent lg:text-lg  md:text-base text-sm' type="text" />}
                      // timeInputLabel="Heure :"
                      renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                        <div className="time-picker-header border-4 border-red-2 flex justify-between items-center h-12 bg-red-400 px-4">
                          <button className="text-red-700 text-2xl" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{'<'}</button>
                          <span className="text-red-800 text-xl font-bold">{format(date, 'MMMM yyyy', { locale: 'fr' })}</span>
                          <button className="text-red-700 text-2xl" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                        </div>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden flex justify-around space-x-4 pb-4 translate-y-6">
        <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
          <div
            onClick={() => {
              handleChangeStep(2);
            }}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={() => {
              handleSubmit();
            }}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
          >
            Suivant
          </div>
        </div>
      </div>

      <div className="hidden mt-8 md:flex justify-between  right-0 px-14">
        <div>
          <button
            onClick={() => {
              handleChangeStep(2);
            }}
            className="py-2 font-extrabold text-first uppercase rounded-full text-sm px-14 bg-white border-2 border-first"
          >
            precedent
          </button>
        </div>
        <div className="">
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="py-2 font-extrabold text-white uppercase rounded-full text-sm px-14 bg-first border-2 border-first"
          >
            SUIVANT
          </button>
        </div>
      </div>
    </div>
  );
};

export default StepSubscription;
