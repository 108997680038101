import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';

const Seventh = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [fixAddress, setFixAddress] = useState<number>()
    const handleChangeAddress = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFixAddress(parseInt((event.target as HTMLInputElement).value));
    };
    const [homeSocity, setHomeSocity] = useState<number>()
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.seventh) {
            if (generalProcuration.second.seventh.fixAddress !== undefined) {
                setFixAddress(generalProcuration.second.seventh.fixAddress)
            }
            if (generalProcuration.second.seventh.homeSocity !== undefined) {
                setHomeSocity(generalProcuration.second.seventh.homeSocity)
            }
            
        }
    }, [generalProcuration])
    const handleChangeHome = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHomeSocity(parseInt((event.target as HTMLInputElement).value));
    };
    const [error, setError] = useState({
        fixAddress: false,
        homeSocity: false
    })
    // useEffect(
        const handleNext = () => {
        // props.callBack({
        //     homeSocity: homeSocity,
        //     fixAddress: fixAddress
        // })
        if (fixAddress !== undefined) {
            if (fixAddress === 0) {
                if (homeSocity !== undefined) {
                    if (generalProcuration.second.seventh) {
                        dispatch({
                            type: 'SET_AE_INFO', payload: {
                                step: 8,
                                first: generalProcuration.first,
                                second: {
                                    first: generalProcuration.second.first,
                                    second: generalProcuration.second.second,
                                    third: generalProcuration.second.third,
                                    fourth: generalProcuration.second.fourth,
                                    fifth: generalProcuration.second.fifth,
                                    sixth: generalProcuration.second.sixth,
                                    seventh:{
                                        fixAddress: fixAddress,
                                        homeSocity: homeSocity
                                    },
                                    eighth: generalProcuration.second.eighth!,
                                    ninth: generalProcuration.second.ninth!,
                                    tenth: generalProcuration.second.tenth!,
                                    eleventh: generalProcuration.second.eleventh!
                                }
                            }
                        })
                        
                    } else {
                        dispatch({
                            type: 'SET_AE_INFO', payload: {
                                step: 8,
                                first: generalProcuration.first,
                                second: {
                                    first: generalProcuration.second.first,
                                    second: generalProcuration.second.second,
                                    third: generalProcuration.second.third,
                                    fourth: generalProcuration.second.fourth,
                                    fifth: generalProcuration.second.fifth,
                                    sixth: generalProcuration.second.sixth,
                                    seventh: {
                                        fixAddress: fixAddress,
                                        homeSocity: homeSocity
                                    }
                                }
                            }
                        })
                    }
                    // props.nextStep(8)
                    props.setCurrentStep(8);
                    setError({
                        fixAddress: false,
                        homeSocity: false
                    })
                } else {
                    setError({
                        fixAddress: false,
                        homeSocity: true
                    })
                }
            } else {
                if (generalProcuration.second.seventh) {
                    dispatch({
                        type: 'SET_AE_INFO', payload: {
                            step: 8,
                            first: generalProcuration.first,
                            second: {
                                first: generalProcuration.second.first,
                                second: generalProcuration.second.second,
                                third: generalProcuration.second.third,
                                fourth: generalProcuration.second.fourth,
                                fifth: generalProcuration.second.fifth,
                                sixth: generalProcuration.second.sixth,
                                seventh: {
                                    fixAddress: fixAddress,
                                    homeSocity: homeSocity
                                },
                                eighth: generalProcuration.second.eighth!,
                                ninth: generalProcuration.second.ninth!,
                                tenth: generalProcuration.second.tenth!,
                                eleventh: generalProcuration.second.eleventh!
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: 'SET_AE_INFO', payload: {
                            step: 8,
                            first: generalProcuration.first,
                            second: {
                                first: generalProcuration.second.first,
                                second: generalProcuration.second.second,
                                third: generalProcuration.second.third,
                                fourth: generalProcuration.second.fourth,
                                fifth: generalProcuration.second.fifth,
                                sixth: generalProcuration.second.sixth,
                                seventh: {
                                    fixAddress: fixAddress,
                                    homeSocity: homeSocity
                                }
                            }
                        }
                    })
                }
                // props.nextStep(8)
                props.setCurrentStep(8)
            }
        } else {
            setError({
                fixAddress: true,
                homeSocity: true
            })
        }
    }
    // , [homeSocity, fixAddress])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-4">
                {error.fixAddress ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Souhaitez-vous fixer l’adresse de votre auto-entreprise à votre domicile ? </label>
                <RadioGroup row
                    value={fixAddress}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.seventh ? generalProcuration.second.seventh.fixAddress : null}
                    name="customized-radios"
                    onChange={handleChangeAddress}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
            {fixAddress === 0 &&
                <div className="flex flex-col gap-y-4">
                    {error.homeSocity ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                    <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Avez-vous recours à une société de domiciliation ?</label>
                    <RadioGroup row
                        value={homeSocity}
                        defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.sevent ? generalProcuration.second.sevent.homeSocity : null}
                        name="customized-radios"
                        onChange={handleChangeHome}
                    >
                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                    </RadioGroup>
                </div>
            }
            {/* <div className='flex flex-col gap-y-4'>
                <label htmlFor="" className='text-xl block font-semibold'>Quelle sera votre adresse de domiciliation ?
                    <span className='text-base'> <br />(renseigner votre adresse personnelle si vous avez répondu oui à la question précédente)</span></label>
                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-3 px-5 bg-white w-full'>
                    <input type="text" placeholder='Ex : 12 rue du temple, 75004' className='w-full' />
                </div>
            </div> */}
            <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Seventh;