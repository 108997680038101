import validator from "validator";

const validateName = (value) => {
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: "Ce champ est requis",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};

export default validateName