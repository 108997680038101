import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';
import DateInput from '../../../../Components/Admin/DateInput';
import { useDispatch, useSelector } from 'react-redux';

const Eighth = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [show, setShow] = useState<number>()
    const [gender, setGender] = useState<number>(0)
    const [national, setNational] = useState<number>(0)
    const [firstName, setFirstName] = useState('')
    const [name, setName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [country, setCountry] = useState('')
    const [complete, setComplete] = useState(false)
    const [error, setError] = useState({
        firstName: false,
        name: false,
        birthDate: false,
        country: false,
        other: false
    })
    const dateInputStyle = {
        backgroundColor: '#fff',
        padding: '2px 8px',
        boxShadow: 'none',
        border: '1px solid rgba(77,77,77,0.29)',
        borderRadius: '9.3px'

    }
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.eighth) {
            if (generalProcuration.second.eighth.otherChief !== undefined) {
                setShow(generalProcuration.second.eighth.otherChief)
                setComplete(generalProcuration.second.eighth.otherChief)
            }
            if (generalProcuration.second.eighth.gender !== undefined) {
                setGender(generalProcuration.second.eighth.gender)
            }
            if (generalProcuration.second.eighth.national !== undefined) {
                setNational(generalProcuration.second.eighth.national)
            }
            if (generalProcuration.second.eighth.firstName) {
                setFirstName(generalProcuration.second.eighth.firstName)
            }
            if (generalProcuration.second.eighth.name) {
                setName(generalProcuration.second.eighth.name)
            }
            if (generalProcuration.second.eighth.birthDate) {
                setBirthDate(generalProcuration.second.eighth.birthDate)
            }
            if (generalProcuration.second.eighth.country) {
                setCountry(generalProcuration.second.eighth.country)
            }
        }
    }, [generalProcuration])
    const getBirthday = (value: string) => {
        setBirthDate(value)
    }
    const handleChangeShow = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt((event.target as HTMLInputElement).value) === 0) {
            setComplete(false)
        }
        setShow(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGender(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeNational = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNational(parseInt((event.target as HTMLInputElement).value));
    };
    const [open, setOpen] = useState(false)
    // useEffect(
        const handleNext = () => {
        if (show !== undefined) {

            // props.callBack({
            //     otherChief: {
            //         gender: gender,
            //         national: national,
            //         firstName: firstName,
            //         name: name,
            //         birthDate: birthDate,
            //         country: country
            //     }
            // })
            if (show === 1) {
                if (complete) {
                    setError({
                        firstName: false,
                        name: false,
                        birthDate: false,
                        country: false,
                        other: false
                    })
                    if (generalProcuration.second.eighth) {
                        dispatch({
                            type: 'SET_AE_INFO', payload: {
                                step: 9,
                                first: generalProcuration.first,
                                second: {
                                    first: generalProcuration.second.first,
                                    second: generalProcuration.second.second,
                                    third: generalProcuration.second.third,
                                    fourth: generalProcuration.second.fourth,
                                    fifth: generalProcuration.second.fifth,
                                    sixth: generalProcuration.second.sixth!,
                                    seventh: generalProcuration.second.seventh!,
                                    eighth: {
                                        otherChief: show,
                                        gender: gender,
                                        national: national,
                                        firstName: firstName,
                                        name: name,
                                        birthDate: birthDate,
                                        country: country
                                    },
                                    ninth: generalProcuration.second.ninth!,
                                    tenth: generalProcuration.second.tenth!,
                                    eleventh: generalProcuration.second.eleventh!
                                }
                            }
                        })

                    } else {
                        dispatch({
                            type: 'SET_AE_INFO', payload: {
                                step: 9,
                                first: generalProcuration.first,
                                second: {
                                    first: generalProcuration.second.first,
                                    second: generalProcuration.second.second,
                                    third: generalProcuration.second.third,
                                    fourth: generalProcuration.second.fourth,
                                    fifth: generalProcuration.second.fifth,
                                    sixth: generalProcuration.second.sixth,
                                    seventh: generalProcuration.second.seventh,
                                    eighth: {
                                        otherChief: show,
                                        gender: gender,
                                        national: national,
                                        firstName: firstName,
                                        name: name,
                                        birthDate: birthDate,
                                        country: country
                                    }
                                }
                            }
                        })
                    }
                    // props.nextStep(9)
                }
            } else {
                setError({
                    firstName: false,
                    name: false,
                    birthDate: false,
                    country: false,
                    other: false
                })
                if (generalProcuration.second.eighth) {
                    dispatch({
                        type: 'SET_AE_INFO', payload: {
                            step: 9,
                            first: generalProcuration.first,
                            second: {
                                first: generalProcuration.second.first,
                                second: generalProcuration.second.second,
                                third: generalProcuration.second.third,
                                fourth: generalProcuration.second.fourth,
                                fifth: generalProcuration.second.fifth,
                                sixth: generalProcuration.second.sixth,
                                seventh: generalProcuration.second.seventh,
                                eighth: {
                                    otherChief: show,
                                    gender: gender,
                                    national: national,
                                    firstName: firstName,
                                    name: name,
                                    birthDate: birthDate,
                                    country: country
                                },
                                ninth: generalProcuration.second.ninth!,
                                tenth: generalProcuration.second.tenth!,
                                eleventh: generalProcuration.second.eleventh!
                            }
                        }
                    })
                } else {
                    dispatch({
                        type: 'SET_AE_INFO', payload: {
                            step: 9,
                            first: generalProcuration.first,
                            second: {
                                first: generalProcuration.second.first,
                                second: generalProcuration.second.second,
                                third: generalProcuration.second.third,
                                fourth: generalProcuration.second.fourth,
                                fifth: generalProcuration.second.fifth,
                                sixth: generalProcuration.second.sixth,
                                seventh: generalProcuration.second.seventh,
                                eighth: {
                                    otherChief: show,
                                    gender: gender,
                                    national: national,
                                    firstName: firstName,
                                    name: name,
                                    birthDate: birthDate,
                                    country: country
                                }
                            }
                        }
                    })
                }
                // props.nextStep(9)
                props.setCurrentStep(9)
            }
        } else {
            setError({
                firstName: false,
                name: false,
                birthDate: false,
                country: false,
                other: true
            })
        }
    }
    // , [show, gender, national, firstName, name, country, birthDate, complete])
    const renderOtherChiefScreen = () => {
        if (complete) {
            return (
                <div>
                    <div className='flex justify-between py-3 px-8 bg-[#E8F5FF] border border-first rounded-[7px] items-center'>

                        <span className='lg:text-lg text-sm'>{`${firstName} ${name}`}</span>
                        <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.27498 0C4.16049 0 0 4.40601 0 9.82232C0 15.2386 4.16049 19.6446 9.27498 19.6446C14.3895 19.6446 18.55 15.2386 18.55 9.82232C18.55 4.40601 14.3895 0 9.27498 0ZM15.6707 7.18152L8.30243 14.9847C8.0785 15.2218 7.76713 15.3579 7.45046 15.3579V15.8869L7.43986 15.3579C7.11921 15.3551 6.80651 15.2134 6.58391 14.9678L2.86464 10.8887C2.40354 10.3822 2.41679 9.57256 2.89512 9.08425C3.12037 8.85272 3.41849 8.72643 3.73119 8.72643C4.06111 8.72643 4.36984 8.86535 4.59906 9.11652L7.46503 12.2625L13.9668 5.37702C14.1947 5.13567 14.4968 5.00377 14.8188 5.00377C15.1407 5.00377 15.4428 5.13708 15.6707 5.37702C16.1398 5.87515 16.1398 6.68479 15.6707 7.18152Z" fill="#0054A3" />
                        </svg>
                    </div>
                    <div>
                        <p onClick={() => setOpen(true)} className='text-right text-normal font-semibold text-sm underline pt-8 cursor-pointer'>+ ajouter un autre représentant légal</p>
                    </div>
                </div>
            )
        }
        if (show === 1) {
            return (
                <div onClick={() => setOpen(true)} className='flex justify-between p-3 bg-white border border-[#808080] rounded-[7px] items-center cursor-pointer'>
                    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.38281 1L8.38281 17" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                        <path d="M15.1094 9L1.65411 9" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                    <span className='text-lg'> Ajouter un représentant légal</span>
                </div>
            )
        } else {
            return
        }

    }
    const validate = () => {
        let error_firstName = true
        let error_birthName = true
        let error_birthDate = true
        let error_country = true
        if (firstName === '') {
            error_firstName = true
        } else {
            error_firstName = false
        }
        if (name === '') {
            error_birthName = true
        } else {
            error_birthName = false
        }
        if (birthDate === '') {
            error_birthDate = true
        } else {
            error_birthDate = false
        }
        if (country === '') {
            error_country = true
        } else {
            error_country = false
        }
        setError({
            firstName: error_firstName,
            name: error_birthName,
            birthDate: error_birthDate,
            country: error_country,
            other: false
        })
        if (!error_firstName && !error_birthName && !error_birthDate && !error_country) return true
        return false
    }
    const handleClickButton = () => {
        if (validate()) {
            setComplete(true)
            setOpen(false)
        }

    }
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-4">
                {error.other ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Souhaitez-vous ajouter d’autres représentants légaux pour votre micro-entreprise ?</label>
                <RadioGroup row
                    value={show}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.eighth ? generalProcuration.second.eighth.otherChief : null}
                    name="customized-radios"
                    onChange={handleChangeShow}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
            {renderOtherChiefScreen()}

            <Dialog
                fullWidth
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "42px",
                        position: "relative",
                        overflow: "visible",
                        boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)"

                    }
                }}
            >
                <div className="flex flex-col p-4 md:p-4 gap-y-4">
                    <DialogTitle id="alert-dialog-title" >
                        <h2 className='mt-10 font-extrabold  uppercase text-first lg:text-2xl'>Ajouter un représentant légal :</h2>
                    </DialogTitle>
                    <DialogContent className='flex flex-col gap-y-8'>
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                                {error.firstName ? <p className='text-third pb-1'>Veuillez renseigner un prénom</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='text-base block' >Prénom</label>
                                    <input type="text" placeholder='Julie' className='w-full' onBlur={(e) => setFirstName(e.target.value)} />
                                </div>
                            </div>
                            <div>
                                {error.name ? <p className='text-third pb-1'>Veuillez renseigner un nom de naissance</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='text-base block'>Nom de naissance</label>
                                    <input type="text" placeholder='Julie' className='w-full' onBlur={(e) => setName(e.target.value)} />
                                </div>
                            </div>
                            <div>
                                {error.birthDate ? <p className='text-third pb-1'>Veuillez renseigner une date de naissance</p> : null}
                                <DateInput field='birthday' style={dateInputStyle} callBack={getBirthday} />
                            </div>
                            <div>
                                {error.birthDate ? <p className='text-third pb-1'>Veuillez renseigner un pays</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='text-base block'>Pays de naissance</label>
                                    <input type="text" placeholder='Julie' onBlur={(e) => setCountry(e.target.value)} className='w-full' />
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <label className='text-normal text-lg font-semibold' htmlFor="">Genre :</label>
                            <RadioGroup row
                                value={gender}
                                name="customized-radios"
                                onChange={handleChangeGender}
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Femme" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Homme" />
                            </RadioGroup>
                        </div>
                        <div className="flex flex-col">
                            <label className='text-normal text-lg font-semibold' htmlFor="">Nationalité :</label>
                            <RadioGroup row
                                value={national}
                                name="customized-radios"
                                onChange={handleChangeNational}
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Française" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Étrangère" />
                            </RadioGroup>
                        </div>
                    </DialogContent>
                    <DialogActions className='flex-col gap-y-3'>
                        <Button onClick={handleClickButton} className='block w-1/3 py-2  mx-auto font-extrabold text-white rounded-full bg-first'>AJOUTER</Button>
                    </DialogActions>
                </div>
            </Dialog>
             <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Eighth;