import NoteForTeachr from "../Objects/models/NoteForTeachr";
import { Route } from "../Objects/enums/RouteEnum";
import ApiService from "../Services/ApiService";
import HydraCollection from "../Objects/models/HydraCollection";
import { plainToClass } from "class-transformer";
export default class NoteForTeachrRepository {
    /**
     * Creates an instance of Teachr into the API.
     * @param noteForTeachr
     */
    public static createNoteForTeachr(noteForTeachr: NoteForTeachr) {
        return ApiService.postInstance<NoteForTeachr>(
            Route.NOTE_FOR_TEACHR,
            NoteForTeachr,
            noteForTeachr
        );
    }
    public static createNoteForTeachrByAdmin(noteForTeachr: NoteForTeachr) {
        return ApiService.postInstance<NoteForTeachr>(
            "/admin/sendmail",
            NoteForTeachr,
            noteForTeachr
        );
    }
    public static fetchNoteForTeachrByKey(key: string) {
        return ApiService.get(`/NoteForTeachr/client/${key}`)
    }
    public static fetchClientByTeachr(teachrId: number) {
        return ApiService.get(`/teachrs/get/client/${teachrId}`)
    }
    
    public static updateNoteForTeachr(id: number, noteForTeachr: NoteForTeachr) {
        return ApiService.putInstance<NoteForTeachr>(
            Route.NOTE_FOR_TEACHR +'/' + id,
            NoteForTeachr,
            noteForTeachr
        );
    }

    public static fetchChildById(id: number) {
        return ApiService.getSingleInstance<NoteForTeachr>(
            Route.NOTE_FOR_TEACHR + "/" + id,
            NoteForTeachr
        );
    }

    public static fetchNoteByTeachr(id: number) {
        return ApiService.getSingleInstance<NoteForTeachr>(
            `/teachrs/${id}/note`,
            NoteForTeachr
        );
    }
    public static fetchInfoByTeachr(id: number) {
        return ApiService.getSingleInstance<NoteForTeachr>(
            `/teachrs/${id}/info`,
            NoteForTeachr
        );
    }

   

}