// import React, { useState } from "react";
// import { useAuthState } from "react-firebase-hooks/auth";
import NavBar from "../../../Components/Navbar/NavBar";
// import { auth } from "../../../Services/FirebaseService";
import { useNavigate } from "react-router-dom";
import  welcome from "../../../Assets/Images/FrameWelcome.png"
// import { Link } from "@mui/material";

function Welcome() {
  const navigate = useNavigate();
  // const [user] = useAuthState(auth)
  return (
    <div className="bg-[#004066]  overflow-hidden  h-screen font-nunito">
      <NavBar theme="dark" />
      {/* <button onClick={()=>navigate("/user/formlayout")} className="text-2xl text-white">PRENDRE COURS</button> */}
      <div className="md:flex  mx-auto mt-8 text-white w-4/5">
        <div className="lg:w-[690px] lg:pt-20  md:w-1/2 p-2 pt-4  ">
          <div className="mb-10 ">
            <p className="lg:text-3xl  md:text-xl text-lg font-extrabold">
              Félicitations, votre compte vient d’être créé !
            </p>
          </div>
          <div>
            <p className="lg:text-xl md:text-lg text-base font-semibold">
              Vous venez de recevoir un mail avec le lien de notre application.
            </p>
            <p className="lg:text-xl md:text-lg text-base font-semibold">
              Vous pouvez desormais vous connectez à votre compte Teach’r
              directement sur l’application et prendre vos premiers cours
              particuliers.
              {/* Vous venez de recevoir un mail avec le lien de notre application. 
              Vous pouvez désormais vous connecter à votre compte Teach’r directement sur l’application et prendre vos premiers cours particuliers.  */}
            </p>
          </div>
          <div className="mt-10">
            <div className="w-full p-2 lg:text-base hover:text-[#219CFF]  //md:translate-y-20 //translate-y-44  hover:border-2 hover:border-[#219CFF]   bg-[#219CFF] text-center lg:py-2 lg:px-4 rounded-full uppercase text-xs md:text-sm ">
              <div
                onClick={()=>navigate("/user/formlayout")}
                className="no-underline  hover:font-bold text-white text-center  font-extrabold"
              >
               prendre un cours
              </div>
            </div>
          </div>
        </div>
        <div className=" translate-x-10 w-1/2  ">
          <img src={welcome} alt="" className="" />
        </div>
      </div>
    </div>
  );
}

export default Welcome;
