import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Send = (props: any) => {
    const navigate = useNavigate()
    return (
        <div>
            <h2 className='text-base font-extrabold text-first lg:text-2xl'>Transmettez votre demande </h2>
            <div className="flex flex-col py-4 pb-20 gap-y-2 md:pb-10">
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>
                Une fois votre demande transmise, Veuillez patienter 1 à 2 semaines. Vous devriez alors avoir un retour positif et notamment <span className="font-bold">l'arrêté préfectoral stipulant que vous êtes enregistré Services à la Personne,</span>. D'ici 1 à 2 semaines, vous devriez avoir un retour positif et notamment <span className="font-bold">l'arrêté préfectoral stipulant que vous êtes enregistré Services à la Personne</span>c'est à dire que vos cours particuliers sont bien éligibles au crédit d'impôt pour vos élèves. Dès que vous serez en possession de ce document, transmettez-le nous en cliquant ci-dessous.
                </p>
            </div>
            <div className="absolute bottom-0 left-0 flex flex-col justify-between w-full px-4 py-4 md:px-8 md:flex-row">
                <Button onClick={() => props.getStep(6)} className='px-8 font-extrabold uppercase rounded-full text-[#B7B7B7] lg:text-xl order-last md:order-first'>PRÉCEDENT</Button>
                <Button onClick={() => navigate('/teachr/profile')} className='px-8 font-extrabold text-white uppercase rounded-full lg:text-xl bg-first'>TRANSMETTRE mon arrêté préfectoral</Button>
            </div>
        </div>
    );
};

export default Send;
 