import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import { Subject } from "./Subject";

export class SubscriptionCourse extends HydraEntity implements DisplayableCourse {

    subjectAsked_id: number;
    subjectAsked?: Subject;

    /**
     * The child associated to the course
     */
    forChild_id?: number;
    /**
     * The client associated to the course
     */
    forClient_id?: number;

    startDate: string;
    /**
     * The end datetime of the course.
     */
    startTime: string;

    /**
     * Address where the course takes place.
     * @id of the address
     */
    address_id: number;

    /**
     * The frequency of a regular course.
     */
    daysOfTheWeek: Array<string>;

    /**
     * Duration of a course, in half hours.
     */

    durationPerDay: number;

    /**
     * payment mean used by client.
     */

    paymentMean: "CASH" | "CARD" | "CESU";


    /**
     * Additional information about the course.
     */
    extraInformation?: string;

    /**
     * True if the course will be in visio, else if at home.
     */
    isRemote: Boolean;
    id?: number;

    acceptedProposal_id?: number;

    creationDatetime?: string;
    resumesAt?: Date;


    constructor(
        subjectAsked_id: number,
        isRemote: Boolean,
        address_id: number,
        daysOfTheWeek: Array<string>,
        startDate: string,
        startTime: string,
        durationPerDay: number,
        paymentMean: "CASH" | "CARD" | "CESU",
        forChild_id?: number,
        forClient_id?: number,
        extraInformation?: string,
    ) {
        super();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.subjectAsked_id = subjectAsked_id,
        this.forChild_id = forChild_id,
        this.forClient_id = forClient_id,
        this.isRemote = isRemote,
        this.address_id = address_id,
        this.extraInformation = extraInformation,
        this.daysOfTheWeek = daysOfTheWeek,
        this.startDate = startDate,
        this.startTime = startTime,
        this.durationPerDay = durationPerDay;
        this.paymentMean = paymentMean;
    }
}
