import { Button, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Teachr from '../../../../Objects/models/Teachr';
import SchoolIcon from '@mui/icons-material/School';
import SimpleSelect from '../../../../Components/utils/SimpleSelect';
import SubjectsSelect from '../../../../Components/Dashboard/Account/SubjectSelect';
import { Subject } from '../../../../Objects/models/Subject';
import SubjectRepository from '../../../../Repositories/SubjectRepository';
import ValidateIban from '../../../../Validation/Iban';
import { auth, RefreshAuthentification, UpdateUserEmail } from '../../../../Services/FirebaseService';
import validateEmail from '../../../../Validation/Email';
import { useAuthState } from 'react-firebase-hooks/auth';
import { UserInfo } from 'firebase/auth';
import FacturationUpdate from './IsAe/FacturationUpdate';
const AccountInformation = (props: any) => {
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [iban, setIban] = useState('')
    const [teachr, setTeachr] = useState<Teachr>()
    const [school, setSchool] = useState<string>()
    const [loading, setLoading] = useState(false)
    const [level, setLevel] = useState<number>()
    const [subjects, setSubjects] = useState<Subject[]>()
    const [subjectsId, setSubjectId] = useState<string[]>([])
    const [userProviders, setUserProviders] = useState<UserInfo[]>()
    const [tagHeight, setTagHeight] = useState(20)
    const [user] = useAuthState(auth)
    useEffect(() => {
        if (user) {
            setUserProviders(user.providerData)
        }
    }, [user])

    const getLevel = (value: number) => {
        setLevel(value)
    }
    const getTagHeight = (value: number) => {
        setTagHeight(value)
    }
    const getSubjects = (value: Subject[]) => {
        let array: string[] = []
        value.map((subject) => (array.push(subject['@id']!)))
        setSubjectId(array)
    };
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response) {
                setTeachr(response)
                setEmail(response.mail!)
                setPhone(response.phone!)
                setIban(response.iban!)
                setSchool(response.whereStudies)
                getSubject(response)
            }
        })
    }, [])

    const update = () => {
        setLoading(true)
        if (teachr) {
            // teachr.yearInStudies = level!
            // teachr.whereStudies = school!
            teachr.subjectToTeach = subjectsId
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            if (teachr && teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat['@id']
            }
            TeachrRepository.updateTeachr(teachr).then((succes) => {
                setLoading(false)
            }).catch((error) => { setLoading(false) })
        } else {
            setLoading(false)
        }

    }
    const updateContact = () => {
        setLoading(true)
        if (teachr) {
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            if (teachr && teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat['@id']
            }
            if (iban) {
                if (ValidateIban(iban).isValid) {
                    teachr.iban = iban.replace(/ /g, "")
                    teachr.subjectToTeach = subjectsId;
                    TeachrRepository.updateTeachr(teachr).then((succes) => {
                      
                        setLoading(false)
                    }).catch((error) => { setLoading(false) })
                } else {
                    setLoading(false)
                }
            }else{
                teachr.subjectToTeach = subjectsId;
                TeachrRepository.updateTeachr(teachr).then((succes) => {
                  
                    setLoading(false)
                }).catch((error) => { setLoading(false) })
            }
        } else {
            setLoading(false)
        }

    }
    const getSubject = (teachr: Teachr) => {
        const regex = /\/subjects\/(.*)/
        const array: Subject[] = []
        if (teachr) {
            teachr.subjectToTeach!.map((value) => {
                const result = value.match(regex)
                if (result) {
                    SubjectRepository.fetchSubjectById(parseInt(result[1])).then((subject) => {
                        array.push(subject)
                    })
                }
            })
            setSubjects(array)
        }
    }
    return (
        <div>
            <div className="flex p-4 flex-col gap-4 /md:p-4 md:flex-row /md:w-1/2 w-full /lg:gap-24 ">
                {teachr ? <div className='px-4 py-4 rounded-lg /lg:p-8 h-max shadow-account text-normal md:w-1/2 w-full'>
                    <h1 className='py-2 font-extrabold uppercase text-first'>Mes informations personnelles</h1>
                    <div className='flex flex-col gap-6 pt-2'>
                        <div>
                            <label className='font-bold uppercase '>Iban</label>
                            <p className='text-third'>{iban === null && teachr.iban !== "" ? "" : ValidateIban(iban).message}</p>
                            <div className="relative flex gap-4 py-3 px-3 rounded-lg border-2 focus:ring-0 focus:border-none focus:outline-none">
                                {iban !== '' && <ClearIcon onClick={() => setIban("")} className=' absolute top-1/3 right-6  p-[1px] text-lg text-white font-bold cursor-pointer rounded-full bg-[#808080]' />}
                                <AccountBalanceIcon />
                                <input placeholder='Votre iban' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setIban(e.target.value)} value={iban} />
                            </div>
                            <p className='font-bold'>Votre IBAN est conservé de manière entièrement sécurisée. Il nous sert uniquement à verser sur votre compte l’argent que vous gagnez en donnant des cours avec Teach’r.
                            </p>
                        </div>
                        <div style={{ height: tagHeight + 100 + "px" }}>
                            <label htmlFor="" className='font-semibold'>Vos matières </label>
                            <SubjectsSelect callBack={getTagHeight} type={1} placeholder="SVT, piano, anglais..." default={subjects} getSubjects={getSubjects} customClass='bg-transparent border-2 py-3' />
                        </div>
                        <Button disabled={loading} onClick={updateContact} className="disabled:opacity-30 font-extrabold md:px-4 mt-5 bg-transparent mx-auto w-max block rounded-full py-1  text-first border-solid border-[1px] border-first no-underline uppercase px-2" >
                            valider les modifications
                        </Button>
                    </div>
                </div> : <Skeleton variant="rounded" width={"100%"} height={500} />}
                <div className='md:w-1/2 w-full'>
                    <FacturationUpdate />
                </div>
            </div>
        </div >
    );
};

export default AccountInformation;