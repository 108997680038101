import validator from 'validator';
const validatePassword = (password, cpassword) => {

    if (validator.isEmpty(password)) {
        return {
            isValid: false,
            message: 'Veuillez remplir ce champ'
        }
    }
    //  if (!validator.isStrongPassword(password ,{ minLength: 6, minLowercase: 0,
    //     minUppercase: 1, 
    //     minNumbers: 1, 
    //     minSymbols: 0, 
    //     returnScore: false })) {
    //         return {
    //             isValid: false,
    //             message: 'Votre mot de passe doit contenir 6 caractères au moins 1 lettre majuscule et 1 chiffre'
    //         }
    // }
    if (validator.isEmpty(cpassword)) {
        return {
            isValid: false,
            message: 'Veuillez confirmer votre mot de passe'
        }
    }
    if (password !== cpassword) {
        return {
            isValid: false,
            message: 'Les mots de passe ne correspondent pas'
        }
    } else
        return {
            isValid: true,
            message: ''
        }
};

export default validatePassword