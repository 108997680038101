import { Type } from "class-transformer";
import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import CourseProposal from "./CourseProposal";
import AvailablePromotionalCode from "./AvailablePromotionalCode";
import { Subject } from "./Subject";
import Child from "./Child";
import Client from "./Client";

declare class ReviewClient {}

export class PunctualCourseDashboardUpdate extends HydraEntity implements DisplayableCourse {
    duration?: number;

   
    // @Type(() => Date)
    startDateTime?: Date;
    /**
     * The end datetime of the course.
     */
    // @Type(() => Date)
    endDateTime?: Date;
    
    
    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    isNewDate?: boolean; // only used to display PunctualCourse in front, doesn't exist in db

    constructor(
        startDateTime: Date,
        endDateTime:Date,
        duration?: number,
    ) {
        super();
        this.startDateTime = startDateTime;
        this.endDateTime = endDateTime;
        this.duration = duration;
    }
}

export class PunctualCoursePassed extends HydraEntity implements DisplayableCourse {
  isPassedCourse: boolean;

  readonly id?: number;
  @Type(() => Date)
  readonly lastModificationDatetime?: Date;
  @Type(() => Date)
  readonly creationDatetime?: Date;
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;

  constructor(isPassedCourse: boolean) {
    super();
    this.isPassedCourse = isPassedCourse;
  }
}
