import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import CustomFees from "../Objects/models/CustomFees";
import HydraCollection from "../Objects/models/HydraCollection";

export default abstract class CustomFeesRepository {
  /**
   * Fetch a Credit Card stored into the API by his id.
   * @param id
   */
  public static fetchCustomFeesById(id: number) {
    return ApiService.getSingleInstance<CustomFees>(
      Route.CUSTOM_FEES_BASE + "/" + id,
      CustomFees
    );
  }

  public static getCustomFeesSingle(clientId: number, teachrId: number) {
    return ApiService.get(`/custom_fees/${teachrId}/find/${clientId}`);
  }
  public static sendMailToTeachr(
    client: number,
    teachr: number,
    customFees: number
  ) {
    return ApiService.get(
      `/teachrs/${teachr}/client/${client}/customfees/${customFees}`
    );
  }

  /**
   * Creates an instance of CustomFees into the API.
   * @param customFees
   */
  public static createCustomFees(customFees: CustomFees) {
    return ApiService.postInstance<CustomFees>(
      Route.CUSTOM_FEES_BASE,
      CustomFees,
      customFees
    );
  }

  /**
   * Creates an instance of CustomFees into the API.
   * @param customFees_id
   */
  public static archives_client_by_teachr(customFees_id: number) {
    return ApiService.put(`/teachrs/clients/${customFees_id}/archives`);
  }
  /**
   * Creates an instance of CustomFees into the API.
   * @param customFees_id
   */
  public static remove_archives_client_by_teachr(customFees_id: number) {
    return ApiService.put(`/teachrs/clients/${customFees_id}/remove_archives`);
  }

  /**
   * Delete the specified customFees
   * @param customFeesId
   */
  public static deleteCustomFees(customFees: CustomFees) {
    return ApiService.deleteInstance(
      Route.CUSTOM_FEES_BASE + "/" + customFees.id
    );
  }

  /**
   * Returns all credit cards of the connected user.
   */
  public static getCustomFeess(page: number = 1) {
    return ApiService.getCollection<HydraCollection<CustomFees>>(
      Route.CUSTOM_FEES_BASE,
      HydraCollection,
      true,
      page
    );
  }
}
