import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import info from "../../../Assets/Icons/info-acre.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));



export default function InfoAcre() {
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open dialog
        </Button> */}
        <img src={info} alt="" onClick={handleClickOpen} className="cursor-pointer border-4 w-6" />
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="text-[24px] font-extrabold text-first text-center "
          >
            Qu’est-ce que l’Acre ?
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography className="text-[#4d4d4d] text-[18px] ">
              <strong className="text-[20px] font-bold text-center">
                ACRE : Aide à la création ou à la reprise d’une entreprise
              </strong>
              <br />
              <br />
              Lorsque vous créez votre auto-entreprise pour la première fois et
              que vous remplissez l’un des critères suivant : <br />
              <br />
              <span onClick={() => setOpen2(!open2)} className="cursor-pointer">
            {open2 ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon/>} Cliquez ici pour dérouler la liste des critières d’eligibilité{" "}
              </span>
              {open2 && (
                <ul className="pl-10 pt-2 space-y-2" >
                  <li>
                    Vous touchez l'allocation d'aide au retour à l'emploi (ARE)
                    ou l'allocation de sécurisation professionnelle (ASP).
                  </li>
                  <li>
                    Vous êtes demandeur d'emploi non indemnisé inscrit sur la
                    liste des demandeurs d'emploi depuis plus de 6 mois ces 18
                    derniers mois.
                  </li>
                  <li>
                    Vous touchez le RSA: RSA : Revenu de solidarité active ou
                    l'allocation de solidarité spécifique (ASS).
                  </li>
                  <li>
                    Vous avez entre 18 et 25 ans (ou 29 ans si vous êtes reconnu
                    handicapé).
                  </li>
                  <li>
                    Vous avez moins de 30 ans et vous ne remplissez pas les
                    conditions d'activité antérieure pour bénéficier de
                    l'indemnisation chômage.
                  </li>
                  <li>
                    Vous êtes salarié ou licencié d'une entreprise en sauvegarde
                    de justice, en redressement judiciaire ou liquidation
                    judiciaire et reprenant une entreprise.
                  </li>
                  <li>
                    Vous êtes sans emploi et avez signé un contrat d'appui au
                    projet d'entreprise - Cape.
                  </li>
                  <li>
                    Vous créez ou reprenez une entreprise dans un quartier
                    prioritaire de la politique de la ville (QPPV).
                  </li>
                  <li>
                    Vous touchez la prestation partagée d'éducation de l'enfant
                    (PreParE).
                  </li>
                </ul>
              )}
              <br />
              <br />
              Vous êtes alors exonéré de 50% du montant des cotisations sociales
              juqu’à la fin du 3ème trimestre civil suite à votre création
              d’entreprise. <br />
              Vous ne paierez plus que 10,6% au lieu des 21,1% habituels. <br />
              Pour plus d’informations, vous pouvez consulter le site officiel
              du gouvernement en cliquant ici.
            </Typography>
          </DialogContent>
          <DialogActions className="relative p-6">
            {/* <Button
              onClick={handleClose}
              className="border-2 bg-first text-white absolute w-1/2 rounded-full "
            >
              Retour
            </Button> */}
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    );
}
