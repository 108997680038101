// import { plainToClass } from "class-transformer";
import { Route } from "../Objects/enums/RouteEnum";
import Client from "../Objects/models/Client";
import HydraCollection from "../Objects/models/HydraCollection";
import Teachr from "../Objects/models/Teachr";
import ApiService from "../Services/ApiService";

export default abstract class ClientRepository {

    /**
     * Returns the current logged client
     */
    public static getCurrentClient() {
        return ApiService.getSingleInstance<Client>(
            Route.CLIENT_BASE + Route.CLIENT_ME,
            Client
        );
    }

    public static getClientByTeachr(page: number = 1 ||  2 || 3 || 4 || 5 ) {
        return ApiService.getCollection<HydraCollection<Client>>(
            Route.CLIENT_BASE,
            HydraCollection,
            true,
            page,
            null
        );
    }

    public static fetchClientById(id: number) {
        return ApiService.getSingleInstance<Client>(
            Route.CLIENT_BASE + "/" + id,
            Client
        );
    }

    
    /**
 * Creates an instance of Client into the API.
 * @param client
 */
    public static createClient(client: Client) {
        return ApiService.postInstance<Client>(
            Route.CLIENT_BASE,
            Client,
            client
        );
    }
    public static fetchClientByIdForTeachr( teachrId: number,id: number) {
        return ApiService.getSingleInstance<Client>(
            `/teachrs/v2/teachrs/${teachrId}/clients/${id}`,
            Client
        );
    }

      /**
     * Updates the instance of the client passed as parameter.
     * @param client
     */
       public static updateClient(client: Client) {
        if (typeof client.profilePicture !== "string") {
            client.profilePicture = undefined;
        }
        client.favoriteTeachrs?.map(
            teachr => (teachr.profilePicture = undefined)
        );
        return ApiService.putInstance<Client>(
            Route.CLIENT_BASE + "/" + client.id,
            Client,
            client
        );
    }

     /**
     * Adds a favorite teachr
     * @param client
     * @param teachr
     */
      public static addFavoriteTeachr(client: Client, teachr: Teachr) {
        return ApiService.postInstance(
            Route.CLIENT_BASE + "/" + client.id + "/favorite/" + teachr.id,
            Client,
            null
        );
    }

    /**
     * Removes a favorite teachr
     * @param client
     * @param teachr
     */
    public static removeFavoriteTeachr(client: Client, teachr: Teachr) {
        return ApiService.deleteInstance(
            Route.CLIENT_BASE + "/" + client.id + "/favorite/" + teachr.id
        );
    }

    /**
     * 
     */
    public static refreshStatusTdp(
        client: number
    ) {
        return ApiService.get(
            `/client/urssaf/tpres/transmission/${client}`
        );
    }

       /**
 * send teachrCode to client by mail.
 * @param mail
 */
       public static sendTeachrCode(mail: string, teachr: number) {
        return ApiService.post(`/teachr/${teachr}/send_code/client_mail/${mail}`);
    }

}