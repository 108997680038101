import React, { useEffect, useState } from 'react';
import First from './First';
import Fourth from './Fourth';
import Second from './Second';
import Third from './Third';
import Last from './Last'
import Stepper from '../../../Components/Admin/Stepper';
import TeachrContactRepository from '../../../Repositories/TeachrContactRepository';
import { useNavigate, useParams } from 'react-router-dom';
import Finish from './Finish';
import ApiService from '../../../Services/ApiService';
import { auth } from '../../../Services/FirebaseService';
import { signOut } from '@firebase/auth';
import Complete from './Complete';
import Loader from '../../../Components/utils/Loader';
import Messages from '../../../Components/utils/Messages';
import TeachrContact from '../../../Objects/models/TeachrContact';
import StepperStore from '../../../LocalForage/StepperStore';

const Index = (props: any) => {
    const params = useParams()
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [address, setAddress] = useState<any>()
    const [teachrContact, setTeachrContact] = useState<TeachrContact>()
    const [finish, setFinish] = useState(false)
    const nextStep = (step: number) => {
        setCurrentStep(step)
    }
    const getIsFinish = (value: boolean) => {
        setFinish(value)
    }
    
    useEffect(() => {
         StepperStore.getItem("addresseFacturation").then((response) => {
            if(response !== null) {
                setAddress(response)
            }
            // setAddress(response)
         })
         
        if (currentStep === 4 && address === null) {
            if (teachrContact) {
                teachrContact.step = 0
                TeachrContactRepository.updateTeachrContact(teachrContact)
                  .then((response: any) => {
                    setCurrentStep(response.step);
                  })
                  .catch((error) => {
                    console.warn(error);
                  });
            }
        }
    }, [currentStep, address])

    useEffect(() => {
        if (params.key) {
            TeachrContactRepository.fetchTeachrContactByKey(params.key)
              .then((response) => {
                if (response) {
                  setFinish(response.status);
                  setAddress(response.address);
                  setTeachrContact(response);
                }
              })
              .catch((error) => {
                console.warn(error);
              });

        } else {
            // navigate('/notfound')
        }
    }, [])
    const renderStepScreen = () => {
        switch (currentStep) {
            case 0:
                return (<First nextStep={nextStep} />)
            case 1:
                return (<Second nextStep={nextStep} />)
            case 2:
                return (<Third nextStep={nextStep} />)
            case 3:
                return (<Fourth nextStep={nextStep} />)
            case 4:
                return (<Last nextStep={nextStep} />)
            case 5:
                if (finish) {
                    return (<Complete callBack={getIsFinish} />)
                } else {
                    return (<Finish callBack={getIsFinish} />)
                }
            default:
                return (<First nextStep={nextStep} />)
        }
    }
    const getCurrentStep = (step: number) => {
        if (step >= 1) {
            step = step - 1
            return step
        } else {
            return step
        }
    }
    useEffect(() => {
        if (params.key) {
            // signOut(auth).then(() => {
            //     ApiService.disableAuth()
            // })
            TeachrContactRepository.fetchTeachrContactByKey(params.key).then((response) => {
                if (response) {
                    setCurrentStep(response.step)
                }
            })

        } else {
            navigate('/notfound')
        }
    }, [])
    return (
        <div>
            <Loader />
            <Messages />
            <div className='bg-[#F4FAFF] h-screen  overflow-auto lg:px-10'>
                <div className='items-center justify-between gap-8 lg:flex'>
                    <div className='relative w-full mx-auto lg:m-0 lg:order-last lg:w-max'>
                        <Stepper nextStep={nextStep} steps={["Vos informations personnelles", "Vos coordonnées bancaires", "Récapitulatif et envoi", "Félicitations !"]} currentStep={getCurrentStep(currentStep)} />
                    </div>
                    {renderStepScreen()}
                </div>
            </div>
        </div>
    );
};

export default Index;
