import { useEffect, useState } from "react";
// import loading from "../../../Assets/images/Eclipse-1s-200px.gif";
// import errorIcon from "../../../Assets/Images/error.png";
// import eye from "../../../Assets/Icons/eye.png";
import validateName from "../../../Validations/Name";
import validateFirstName from "../../../Validations/FirstName";
import validateEmail from "../../../Validations/Email";
// import validatePassword from "../../../Validations/Password";
import { validatePhoneNumber } from "../../../Validations/Phone";
import StepperStore from "../../../LocalForage/StepperStore";
import { logout, sendPasswordReset } from "../../../Services/FirebaseService";
import CircularProgress from "@mui/material/CircularProgress";
import {
  auth,
  FirebaseAuthServiceErrorCode,
  getJWTToken,
  registerWithEmailAndPassword,
} from "../../../Services/FirebaseService";
import Client from "../../../Objects/models/Client";
import ClientRepository from "../../../Repositories/ClientRepository";
import { useAuthState } from "react-firebase-hooks/auth";
import ApiService from "../../../Services/ApiService";
import { CoursePack } from "../../../Objects/models/CoursePack";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
import AddressRepository from "../../../Repositories/AddressRepository";
import moment from "moment";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { Address } from "../../../Objects/models/Address";
import ChildRepository from "../../../Repositories/ChildRepository";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
import Child from "../../../Objects/models/Child";
import { APIErrorEnum } from "../../../Objects/enums/APIErrorEnum";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Functions from "../../../Helpers/Functions";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
// import Loader from "../../utils/Loader";
import LoaderContext from "../../LoadingContext";
import { browserSessionPersistence } from "firebase/auth";
// import Password from 'antd/lib/input/Password'

const FormEnd = (props: any) => {
  const { handleNext, handleBack } = props;
  const [terms, setTerms] = useState(false);
  const [datas, setDatas] = useState<any>({});
  const [client, setClient] = useState<Client>();
  const [address, setAddress] = useState<Address>();
  // const [passwordShown, setPasswordShown] = useState(false);
  // const [passwordShown2, setPasswordShown2] = useState(false);
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  // const [password, setPassword] = useState("");
  // const [cPassword, setCpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  // const [termsTeachr, setTermsTeachr] = useState(false);
  const [remember, setRemember] = useState(false);
  const [user] = useAuthState(auth);
  const Swal = require("sweetalert2");
  let timerInterval: any;
  const [error, setError] = useState({
    name: "null",
    firstName: "null",
    phone: "null",
    email: "null",
    // password: "null",
  });
  useEffect(() => {
    if (user) {
      createClient();
    }
  }, [user]);
  useEffect(() => {
    if (client) {
      createChildren();
      createAddresses();
    }
  }, [client]);
  useEffect(() => {
    StepperStore.getItem("addresse").then((response: any) => {
      if (response) {
        setAddress(response.addresseObject);
      }
    });
  }, []);
  const validate = () => {
    let errname = true;
    let errfirstName = true;
    let erremail = true;
    let errphone = true;
    // let errpassword = true;
    if (!validateName(name).isValid) {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errname = true;
    } else {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errname = false;
    }
    if (!validateFirstName(firstName).isValid) {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = true;
    } else {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = false;
    }
    // if (!validatePassword(password, cPassword).isValid) {
    //   setError({
    //     name: validateName(name).message,
    //     firstName: validateFirstName(firstName).message,
    //     email: validateEmail(email).message,
    //     // password: validatePassword(password, cPassword).message,
    //     phone: validatePhoneNumber(phone),
    //   });
    //   errpassword = true;
    // } else {
    //   setError({
    //     name: validateName(name).message,
    //     firstName: validateFirstName(firstName).message,
    //     email: validateEmail(email).message,
    //     // password: validatePassword(password, cPassword).message,
    //     phone: validatePhoneNumber(phone),
    //   });
    //   errpassword = false;
    // }
    if (!validateEmail(email).isValid) {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      erremail = true;
    } else {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      erremail = false;
    }
    if (validatePhoneNumber(phone)) {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errphone = true;
    } else {
      setError({
        name: validateName(name).message,
        firstName: validateFirstName(firstName).message,
        email: validateEmail(email).message,
        // password: validatePassword(password, cPassword).message,
        phone: validatePhoneNumber(phone),
      });
      errphone = false;
    }

    if (datas.me === true) {
      if (erremail) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        errname ||
        errfirstName ||
        erremail ||
        // errpassword ||
        errphone
      ) {
        return false;
      } else {
        return true;
      }
    }
  };
  const getConfirmationCourse = async () => {
    try {
      const formMatiere: any = await StepperStore.getItem("formMatiere");
      const formLocation: any = await StepperStore.getItem("formLocation");
      const courseType: any = await StepperStore.getItem("courseType");
      const who: any = await StepperStore.getItem("formWho");
      const forWho: any = await StepperStore.getItem("formWhos");
      if (
        formMatiere !== null &&
        formLocation !== null &&
        courseType !== null
      ) {
        setDatas({
          matiere: formMatiere.matiere.name,
          isHome: formLocation.position.isHome,
          isRemote: formLocation.position.isRemote,
          isNoPreference: formLocation.position.isNoPreference,
          addresse:
            formLocation.position.isHome === true
              ? formLocation.add.formattedAddress
              : null,
          type: courseType.type,
          rangeNumber: courseType.data.rangeNumber * 2 + "",
          date: courseType.data.date,
          dates: courseType.data.dates,
          hour: courseType.data.startTime,
          day: courseType.data.day,
          startTime: courseType.data.startTime,
          endTime: courseType.data.endTime,
          firstNames: who.add.firstName,
          me: who.me,
          myChild: who.myChild,
          friend: who.friend,
          meFirstName: forWho.firstName,
          meName: forWho.lastName,
          mePhone: forWho.phone,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };
  useEffect(() => {
    getConfirmationCourse();
  }, [datas]);
  const sendMailUpdatePassword = () => {
    sendPasswordReset(email).catch((err) => console.warn(err)
    )
  }
  const createUser = () => {
    const password = Math.random().toString(36).slice(-8);
    registerWithEmailAndPassword(email, password)
      .then(() => {
        setIsLoading(true);
        StepperStore.setItem(
          "setPassword",
          {
            password: password,
          },
          function () { }
        );
      })
      .catch((error) => {
        // if (user) {
          setIsLoading(false);
          logout();
          user?.delete()
          document.getElementById("teachr-loader")?.classList.add("hidden");
          switch (error.code) {
            case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
              Swal.fire({
                icon: "warning",
                title: "Attention !",
                text: "L'adresse mail est déjà utilisée. Un mail a été renvoyé.",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
  
            default:
              Swal.fire({
                icon: "error",
                title: "OOPS.. !",
                text: "Quelque chose s'est mal passée",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
          }
        // }
      });
  };
  
  const createClient = async () => {
    // const term: any = await StepperStore.getItem("registerTerms");
    if (user) {
      let clientObject = new Client(
        await user.getIdToken(),
        datas.me === true ? datas.meFirstName : firstName,
        datas.me === true ? datas.meName : name,
        datas.me === true
          ? Functions.formatPhoneNumber(datas.mePhone)
          : Functions.formatPhoneNumber(phone),
        terms,
        "firebaseMessagingToken", // TODO registrationToken
        true, // TODO parent/child
        false,
        undefined
      );
      clientObject.isRelationTeachr = true
      if (email) {
        clientObject.mail = email;
      }

      
      ClientRepository.createClient(clientObject)
        .then((response) => {
          getJWTToken(user).then((token) => {
            ApiService.enableAuth(undefined, undefined, "client " + token);
          });
          setClient(response);
          sendMailUpdatePassword()
          setIsLoading(true);
        })
        .catch((error) => {
          console.warn(error);
          // logout();
          user?.delete()
          document.getElementById("teachr-loader")?.classList.add("hidden");
          document.getElementById("teachr-loader")?.classList.add("hidden");
          setIsLoading(false);
        });
    }
  };
  const createChildren = async () => {
    try {
      const clientWithId = await ClientRepository.getCurrentClient();
      const child: any = await StepperStore.getItem("formWhos");
      const firstNameChild = child.firstName;
      const lastName = child.lastName;
      const classe = child.niveau.value;
      const birthday = child.birthday;
      const phone = child.phone;
      const additonalInfo = child.additionalInfo;
      if (clientWithId["@id"]) {
        const child = new Child(
          firstNameChild,
          lastName,
          clientWithId["@id"],
          classe,
          birthday,
          phone === "" ? "" : Functions.formatPhoneNumber(phone),
          additonalInfo
        );
        try {
          await ChildRepository.createChild(child);
          setIsLoading(true);
        } catch (error) {
          console.warn(error);
          setIsLoading(false);
          document.getElementById("teachr-loader")?.classList.add("hidden");
        }
      }
    } catch (error) {
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const createAddresses = async () => {
    try {
      if (user) {
        const courseType: any = await StepperStore.getItem("courseType");
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id && address) {
          AddressRepository.createAddressForClient(
            address,
            clientWithId.id
          ).then((res: Address) => {
            if (res) {
              if (courseType.type === "Urgence") {
                (async () => {
                  if (res["@id"] && datas.isNoPreference === true) {
                    await inCourseInTheHourIsNoPreferenceFalse(res["@id"]);
                    await inCourseInTheHour(res["@id"]);
                  } else if (res["@id"]) {
                    await inCourseInTheHour(res["@id"]);
                  }
                })();
              } else if (courseType.type === "Ponctuel") {
                (async () => {
                  if (res["@id"] && datas.isNoPreference === true) {
                    await createPonctualCourseIsNoPreferenceFalse(res["@id"]);
                    await createPonctualCourse(res["@id"]);
                  } else if (res["@id"]) {
                    await createPonctualCourse(res["@id"]);
                  }
                })();
              } else if (courseType.type === "Stage") {
                (async () => {
                  if (res.id && datas.isNoPreference === true) {
                    await createPackCourseIsNoPreferenceFalse(res.id);
                    await createPackCourse(res.id);
                  } else if (res.id) {
                    await createPackCourse(res.id);
                  }
                })();
              } else if (res.id && courseType.type === "Régulier") {
                (async () => {
                  if (res.id && datas.isNoPreference === true) {
                    await createSubscriptionCourseIsNoPreferenceFalse(res.id);
                    await createSubscriptionCourse(res.id);
                  } else if (res.id) {
                    await createSubscriptionCourse(res.id);
                  }
                })();
              }
            }
          });
        }
      }
    } catch (error) {
      console.warn(error);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const inCourseInTheHourIsNoPreferenceFalse = async (address_id: string) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        const courseType: any = await StepperStore.getItem("courseType");
        const durations = (courseType.data.rangeNumber * 2).toString();

        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          // const isNoPreference = datas.isNoPreference;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const address = address_id;
          const paymentMean = "CARD";
          const duration = (durations);
          const subjectAsked = formMatiere.matiere["@id"];
          const isCourseInTheHour = true;
          const forClient = "/children/" + childWithId;
          const forChild = "/children/" + childWithId;
          const isFiscalReductionEnabled = false;
          const chapter = "Introduction à la programmation";
          const directAskedTeachr = 0;
          // let startTime = moment(datas.startTime).add(50, "seconds");
          let endTime = moment(datas.endTime).add(50, "seconds");
          let startDate = moment(new Date().toDateString());
          // let startDateTime = startDate
          //   .set("hour", startTime.hours())
          //   .set("minutes", startTime.minutes())
          //   .set("seconds", 20)
          //   .toDate();

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .set("seconds", 30)
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = datas.isRemote;
          await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
        }
      }
    } catch (error: any) {
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
      }
    }
  };
  const inCourseInTheHour = async (address_id: string) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        const courseType: any = await StepperStore.getItem("courseType");
        const durations = (courseType.data.rangeNumber * 2).toString();

        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          const isNoPreference = datas.isNoPreference;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const address = address_id;
          const paymentMean = "CARD";
          const duration = (durations);
          const subjectAsked = formMatiere.matiere["@id"];
          const isCourseInTheHour = true;
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "Introduction à la programmation";
          const directAskedTeachr = 0;
          let endTime = moment(datas.endTime).add(50, "seconds");
          let startDate = moment(new Date().toDateString());

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .set("seconds", 30)
            .toDate();

          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = isNoPreference === true ? true : datas.isRemote;
          await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error: any) {
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          logout();
          user?.delete()
          document.getElementById("teachr-loader")?.classList.add("hidden");
          break;
      }
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  };
  const createPonctualCourseIsNoPreferenceFalse = async (
    address_id: string
  ) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        const courseType: any = await StepperStore.getItem("courseType");
        const durations = (courseType.data.rangeNumber * 2).toString();

        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;

          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const address = address_id;
          const paymentMean = "CARD";
          const duration = (durations);
          const subjectAsked = formMatiere.matiere["@id"];
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "Introduction à la programmation";
          const directAskedTeachr = 0;
          let startTime = moment(courseType.data.startTime);
          let endTime = moment(courseType.data.endTime);
          let startDate = moment(courseType.data.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = datas.isRemote;
          const response = await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };
  const createPonctualCourse = async (address_id: string) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        const courseType: any = await StepperStore.getItem("courseType");
        const durations = (courseType.data.rangeNumber * 2).toString();

        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const childWithId = allChildrenCollection[0].id;
          const isNoPreference = datas.isNoPreference;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const address = address_id;
          const paymentMean = "CARD";
          const duration = (durations);
          const subjectAsked = formMatiere.matiere["@id"];
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "Introduction à la programmation";
          const directAskedTeachr = 0;
          let startTime = moment(courseType.data.startTime);
          let endTime = moment(courseType.data.endTime);
          let startDate = moment(courseType.data.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = isNoPreference === true ? true : datas.isRemote;
          const response = await PunctualCourseRepository.createPunctualCourse(course);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };
  const createSubscriptionCourseIsNoPreferenceFalse = async (id: number) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );

          const childWithId = allChildrenCollection[0].id;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const subjectAsked_id = formMatiere.matiere.id;
          const isRemote = datas.isRemote;
          const address_id = id;
          const daysOfTheWeek = datas.day;
          const durationPerDay = (datas.rangeNumber).toString();
          const paymentMean = "CASH";
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const extraInformation = "";
          let startTime = moment(datas.hour).format("HH:mm");
          let startDate = moment(datas.date).format("Y-MM-DD");
          // const isNoPreference = datas.isNoPreference;
          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isRemote,
            address_id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );

          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            clientWithId.id
          );
          setIsLoading(true);
        }
      }
    } catch (error) {
      console.warn(error);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
    }
  };
  const createSubscriptionCourse = async (id: number) => {
    try {
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );

          const childWithId = allChildrenCollection[0].id;

          const formMatiere: any = await StepperStore.getItem("formMatiere");

          const subjectAsked_id = formMatiere.matiere.id;
          const isRemote = datas.isRemote;
          const address_id = id;
          const daysOfTheWeek = datas.day;
          const durationPerDay = Math.round(datas.rangeNumber);
          const paymentMean = "CASH";
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const extraInformation = "";
          let startTime = moment(datas.hour).format("HH:mm");
          let startDate = moment(datas.date).format("Y-MM-DD");
          const isNoPreference = datas.isNoPreference;
          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isNoPreference === true ? true : isRemote,
            address_id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );

          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            clientWithId.id
          );
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error) {
      console.warn(error);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false);
    }
  };
  const createPackCourseIsNoPreferenceFalse = async (id: number) => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection: any = await ChildRepository.getChildren(
            clientWithId.id
          );

          const childWithId = allChildrenCollection[0].id;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const address_id = id;
          // const isNoPreference = datas.isNoPreference;
          const subjectAsked_id = formMatiere.matiere.id;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.dates;
          const durationPerDay = (datas.rangeNumber).toString();
          const isRemote = datas.isRemote;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address_id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);
          setIsLoading(true);
        }
      }
    } catch (error) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      console.warn(error);
    }
  };
  const createPackCourse = async (id: number) => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );

          const childWithId = allChildrenCollection[0].id;
          const formMatiere: any = await StepperStore.getItem("formMatiere");
          const forChild_id = childWithId;
          const forClient_id = childWithId;
          const address_id = id;
          const isNoPreference = datas.isNoPreference;
          const subjectAsked_id = formMatiere.matiere.id;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.dates;
          const durationPerDay =(datas.rangeNumber).toString();
          const isRemote = datas.isRemote;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address_id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isNoPreference === true ? true : isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);
          setIsLoading(true);
          await handleNext();
          // await StepperStore.clear();
        }
      }
    } catch (error) {
      setIsLoading(false);
      // logout();
      user?.delete()
      document.getElementById("teachr-loader")?.classList.add("hidden");
      console.warn(error);
    }
  };
  // const togglePasswordVisiblity = () => {
  //   setPasswordShown(passwordShown ? false : true);
  // };
  // const togglePasswordVisiblity2 = () => {
  //   setPasswordShown2(passwordShown2 ? false : true);
  // };
  const handleSubmit = (event: any) => {

    if (validate()) {
      setIsLoading(true)
      // auth.setPersistence( remember ? indexedDBLocalPersistence : inMemoryPersistence)
      //   .then(() => {
      //     document.getElementById("teachr-loader")?.classList.remove("hidden");
      //     createUser();
      //   });
      if (remember) {
        createUser()
      } else {
        auth.setPersistence(browserSessionPersistence).then(() => {
          createUser()
        })
      }
    } else {
      document.getElementById("teachr-loader")?.classList.add("hidden");
      setIsLoading(false)
    }
    event.preventDefault();
  };


  return (
    <>
      <LoaderContext />

      <div className="h-screen space-y-2 translate-y-24 lg:space-y-6 md:space-y-4 lg:translate-y-8 list-teacher md:translate-y-20">
        <div className="w-4/5 mx-auto ">
          {/* <button onClick={password}>Password</button> */}
          <h5 className="text-lg font-bold lg:text-3xl md:text-2xl text-dark_blue">
            Dernières informations :
          </h5>
          <div className="">
            <div className="flex">
              <p className="lg:text-lg md:text-base  text-sm  text-[#4d4d4d] ">
                Pour que nous puissons confirmer votre demande et vous avertir
                par mail de vos réponses merci de compléter{" "}
                <strong>vos informations personnelles</strong>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div
            className={
              datas.me === true
                ? "mt-6 grid w-4/5 gap-2 mx-auto lg:grid-cols-1"
                : "mt-6 grid w-4/5 gap-2 mx-auto lg:grid-cols-2"
            }
          >
            {datas.me === true ? (
              " "
            ) : (
              <>
                <div>
                  <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] py-2 lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                    <label className="block mb-2 lg:text-lg md:text-base  text-sm font-medium pl-2 text-[#4d4d4d] ">
                      Prénom
                    </label>
                    <input
                      type="text"
                      id="firs_name"
                      className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none focus:ring-0 w-full focus:border-1 pl-2"
                      placeholder="Ex : John"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="flex w-full mx-auto mb-2 -mt-3 space-x-2 translate-y-4 ">
                    {error.firstName === "null" || error.firstName === ""
                      ? " "
                      : validateFirstName(firstName).icon}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.firstName === "null" || error.firstName === ""
                        ? null
                        : validateFirstName(firstName).message}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                    <label className="block mb-2 lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                      Nom
                    </label>
                    <input
                      type="text"
                      id="last_name"
                      className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                      placeholder="Ex : Doe"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="flex w-full mx-auto mb-2 -mt-3 space-x-2 translate-y-4 ">
                    {error.name === "null" || error.name === ""
                      ? " "
                      : validateName(name).icon}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.name === "null" || error.name === ""
                        ? null
                        : validateName(name).message}
                    </p>
                  </div>
                </div>
              </>
            )}
            <div>
              <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                <label className="block pl-2 mb-2 lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                  Adresse email
                </label>
                <input
                  type="text"
                  id="email"
                  className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                  placeholder="Ex : johndoe@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="flex w-full mx-auto mb-2 -mt-3 space-x-2 translate-y-4 ">
                {error.email === "null" || error.email === ""
                  ? " "
                  : validateEmail(email).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.email === "null" || error.email === ""
                    ? null
                    : validateEmail(email).message}
                </p>
              </div>
            </div>
            {datas.me === true ? (
              ""
            ) : (
              <div>
                <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full p-2 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                  <label className="block pl-2 mb-2 lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                    Numéro de téléphone
                  </label>
                  <input
                    type="text"
                    id="phone"
                    className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                    placeholder="Ex : 07 XX XX XX XX"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div className="flex w-full mx-auto mb-4 -mt-3 space-x-2 translate-y-4 ">
                  {error.phone === "null" || error.phone === "" ? " " : ""}
                  <p className="text-[#FF724F] h-6 w-full text-sm ">
                    {error.phone === "null" || error.phone === ""
                      ? null
                      : validatePhoneNumber(phone)}
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="hidden w-4/5 mx-auto -mt-4 //-translate-y-6 md:block gap-x-10">
          <div className="">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "24px",
                // marginLeft: "4rem",
                fontFamily: "Nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "Nunito",
                    },
                  }}
                  onClick={() => setTerms(!terms)}
                />
              }
              label={
                <Typography style={{ fontFamily: "nunito" }}>
                  J'accepte recevoir des offres promotionnelles de la part de
                  Teach'r
                </Typography>
              }
            />
          </div>
          <div className=" font-nunito //-mt-8">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "24px",
                // marginLeft: "4rem",
                fontFamily: "nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "nunito",
                    },
                  }}
                  onClick={() => setRemember(!remember)}
                />
              }
              label={
                <Typography style={{ fontFamily: "nunito" }}>
                  Rester connecté
                </Typography>
              }
            />

            {/* <div className="flex space-x-2 -translate-y-3 //ml-6 lg:mt-4 ">
            {error.termsTeachr === "null" || error.termsTeachr === ""
              ? " "
              : validateTermsTeachr(termsTeachr).icon}
            <p className="text-[#FF724F] h-6 w-full text-sm ">
              {error.termsTeachr === "null" || error.termsTeachr === ""
                ? null
                : validateTermsTeachr(termsTeachr).message}
            </p>
          </div> */}
          </div>
        </div>
        <div className="block w-4/5 pb-4 mx-auto mt-8 space-y-2 //-mt-4 md:hidden">
          <div className="">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "16px",
                // marginLeft: "4rem",
                fontFamily: "Nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "Nunito",
                    },
                  }}
                  onClick={() => setTerms(!terms)}
                />
              }
              label={
                <Typography
                  style={{ fontFamily: "nunito" }}
                  className="text-sm lg:text-lg md:text-base"
                >
                  J'accepte recevoir des offres promotionnelles de la part de
                  Teach'r
                </Typography>
              }
            />

            {/* <div className="flex pt-2 space-x-2 text-xs -translate-y-3 md:text-sm md:ml-6 ">
            {error.terms === "null" || error.terms === ""
              ? " "
              : validateTerms(terms).icon}
            <p className="text-[#FF724F] h-6 w-full ">
              {error.terms === "null" || error.terms === ""
                ? null
                : validateTerms(terms).message}
            </p>
          </div> */}
          </div>
          <div className="-mt-6 font-nunito">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "10px",
                // marginLeft: "4rem",
                fontFamily: "nunito",
              }}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "nunito",
                      fontSize: "10px",
                    },
                  }}
                  onClick={() => setRemember(!remember)}
                />
              }
              label={
                <Typography
                  style={{ fontFamily: "nunito" }}
                  className="text-sm lg:text-lg md:text-base"
                >
                  Rester connecté
                </Typography>
              }
            />
          </div>
        </div>

        <div className="flex justify-around w-4/5 mx-auto space-x-4 -translate-y-4 md:hidden md:translate-y-44 md:w-full">
          <div className="flex justify-center w-1/2 font-extrabold bg-white border-2 rounded-full text-orange border-orange">
            <div
              onClick={handleBack}
              className="w-full pt-2 text-sm text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <button
              disabled={isLoading}
              onClick={(e) => {
                handleSubmit(e);
              }}
              className="w-full px-3 py-2 text-sm font-extrabold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-extrabold border-dark_blue text-dark_blue active:bg-dark_blue active:text-white "
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "gray",
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />
              ) : (
                "Envoyer"
              )}{" "}
            </button>
          </div>
        </div>

        <div
          className={
            datas.me === true
              ? "md:flex hidden //-translate-y-4 pb-4 md:mb-0 md:translate-y-16 justify-around mx-auto md:w-full"
              : "md:flex hidden //-translate-y-4 pb-4 md:mb-0 md:translate-y- justify-around mx-auto md:w-full"
          }
        >
          <div className="flex justify-center space-x-2">
            <button
              type="button"
              onClick={handleBack}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center space-x-2 ">
            <button
              type="button"
              disabled={isLoading}
              onClick={(e) => {
                handleSubmit(e);
              }}
              className={
                // error.name === "null" &&
                // error.firstName === "null" &&
                // error.email === "null" &&
                // error.password === "null" &&
                // error.phone === "null"
                //   ?
                " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                // : error.name === "" &&
                //   error.firstName === "" &&
                //   error.email === "" &&
                //   error.password === "" &&
                //   error.phone === ""
                // ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                // : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
              }
            >
              {isLoading ? (
                <CircularProgress
                  sx={{
                    color: "gray",
                  }}
                  size={20}
                  thickness={4}
                  value={100}
                />
              ) : (
                "Envoyer"
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormEnd;
