import HydraEntity from "./HydraEntity";
import "reflect-metadata";

export default class PromotionalCode extends HydraEntity {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    id?: number;
    startDatetime?: Date;
    endDatetime?: Date;
    minimumPrice?: number;
    percentageReduction?: number;
    fixReduction?: number;
}
