import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import { Subject } from "./Subject";

export class SubscriptionCourseDashboard extends HydraEntity implements DisplayableCourse {

    subjectAsked_id: number;
    subjectAsked?: Subject;

    /**
     * The child associated to the course
     */
    forChild_id: number;
    /**
     * The client associated to the course
     */
    forClient_id?: number;

    startDate: string;
    /**
     * The end datetime of the course.
     */
    startTime: string;

    /**
     * Address where the course takes place.
     * @id of the address
     */
    address_id: number;

    /**
     * The frequency of a regular course.
     */
    daysOfTheWeek: Array<string>;
    /**
     * The frequency of a regular course.
     */
    dataCourse?:any;

    /**
     * Duration of a course, in half hours.
     */

    durationPerDay: number;

    /**
     * payment mean used by client.
     */

    paymentMean: "CASH" | "CARD" | "CESU";


    /**
     * Additional information about the course.
     */
    extraInformation?: string;

    /**
     * True if the course will be in visio, else if at home.
     */
    isRemote: Boolean;
    aiciActivated?: Boolean;
    isDirectCourse?: Boolean;
    teachr_id?: number;
    id?: number;

    acceptedProposal_id?: number;

    creationDatetime?: string;
    resumesAt?: Date;


    constructor(
        forChild_id: number,
        isRemote: Boolean,
        address_id: number,
        subjectAsked_id: number,
        daysOfTheWeek: Array<string>,
        startDate: string,
        startTime: string,
        durationPerDay: number,
        paymentMean: "CASH" | "CARD" | "CESU",
        extraInformation?: string,
        aiciActivated?: Boolean,
        isDirectCourse?: Boolean,
        teachr_id?: number,
        dataCourse?: any,
    ) {
        super();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.forChild_id = forChild_id,
        this.isRemote = isRemote,
        this.address_id = address_id,
        this.subjectAsked_id = subjectAsked_id,
        this.daysOfTheWeek = daysOfTheWeek,
        this.startDate = startDate,
        this.startTime = startTime,
        this.durationPerDay = durationPerDay;
        this.paymentMean = paymentMean;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.aiciActivated = aiciActivated,
        this.extraInformation = extraInformation,
        this.isDirectCourse = isDirectCourse,
        this.teachr_id = teachr_id
        this.dataCourse = dataCourse
    }
}
