
import * as React from 'react';
import info from '../../../../../Assets/Icons/info.svg'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { acceptCourse } from './FunctionsDashbord';
import ValidIban from './ValidIban';
import { useNavigate } from 'react-router-dom';

export default function PopUpInfo(props: any) {
    const navigate = useNavigate();
    const {handleDeclareCourse, handleClose, open, teachr} = props
    const [opens, setOpens] = React.useState(false)
    const handleOpen = () => {
        setOpens(true)
    }
    const handleCloses = () => {
      setOpens(false);
    };
    const closePop = () => {
        if (teachr.iban) {
            handleClose()
            navigate("/teachr/dashboard/declare");
        }else{
            handleClose()
            handleOpen();
        }
    }


    return (
        <>
        <ValidIban handleOpen={handleOpen} handleCloses={handleCloses} opens={opens} teachr={teachr} />
        <React.Fragment>
            <Dialog
                // fullWidth={true}
                // maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    <div className='flex w-full  gap-x-10  relative text-base font-extrabold uppercase text-first'>
                        <div className="pt-1 pl-2 gap-x-3 flex">
                            <div>
                                <p className="text-left lg:text-xl md:text-base text-sm">INFORMATION CLIENT</p>
                            </div>
                            <div>
                                <img onClick={() => handleClose()} className='cursor-pointer w-7' src={info} alt="" />
                            </div>
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className="text-left px-2 ">
                            <div className=" ">
                                <p className='text-lg text-normal '>
                                    Il se peut que certaines banques bloquent les prélèvements de divers émetteurs.   <br />
                                    Afin d’éviter cela, nous vous conseillons de transmettre à vos clients le numéro de créancier et l’émetteur des prélèvements URSSAF afin qu’ils puissent prévenir leurs banques.
                                </p>
                            </div>
                            <div className='flex gap-x-4 mt-6'>
                                <div className=''>
                                    <p>Numéro créancier ICS :</p>
                                    <div className='text-dark_blue bg-[#F2F5FB] p-2 '>
                                        FR55HOM143065
                                    </div>
                                </div>
                                <div className=''>
                                    <p>Émetteur :</p>
                                    <div className='text-dark_blue bg-[#F2F5FB] p-2'>
                                        URSSAF RHONE-ALPES CNCESU
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='w-full px-4 py-3 '>
                        <Button onClick={() =>  closePop()} className='block mx-auto bg-dark_blue font-extrabold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >C’EST COMPRIS</Button>
                        <p onClick={() => handleDeclareCourse()} className='underline text-center pt-1 cursor-pointer'>Ne plus afficher ce message</p>
                    </div>
                </DialogActions>
            </Dialog>
        </React.Fragment >
        </>
    );
}