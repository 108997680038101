import { Type } from "class-transformer";
import "reflect-metadata";
import HydraEntity from "./HydraEntity";

export class SubscriptionCoursePause extends HydraEntity {

  
    endPauseDate?: string;

    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    
    isFromTeachr?: boolean;

    constructor(
        endPauseDate: string,
    ) {
        super();
        this.endPauseDate = endPauseDate;
    }
}
