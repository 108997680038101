import { Button } from '@mui/material'
import React from 'react'
import appStoreIcon from '../../../../../Assets/button/appstore.png'
import playStoreIcon from '../../../../../Assets/button/googleplay.png'
import qrCodeAppleBtn from '../../../../../Assets/Images/qr_code_apple.svg'
import qrCodePlaystoreBtn from '../../../../../Assets/Images/qr_code_androide.svg'
import appleIcon from '../../../../../Assets/Icons/apple-blue.svg'
import playIcon from '../../../../../Assets/Icons/play-blue.svg'
import NavBar from '../../../../../Components/Dashboard/NavBar'

function Confirm() {
    return (
        <div>
            <NavBar />
            <div className="flex px-8 pt-8 lg:px-14">
                <div className='w-full font-bold text-first lg:text-2xl text-md'>
                    <p className=' font-extrabold lg:text-[40px] pb-8 text-xl'> FÉLICITATIONS ! </p>
                    <div className="flex flex-col gap-12 py-8 lg:gap-4">
                        <p>L’élève ou le parent vient de recevoir votre candidature.</p>
                        <p>Vous serez alerté par mail des réponses à votre demande de cours. <br />Pour plus de simplicité, nous vous conseillons de télécharger l’application.</p>
                        <div className="items-end justify-between hidden lg:flex ">
                            <a href='https://apps.apple.com/fr/app/teachr/id1473591330' target="_blank" rel="noreferrer">
                                <img src={qrCodeAppleBtn} alt="" className='mx-auto' />
                                <div className="flex items-center gap-2 py-4">
                                    <p className='text-base font-extrabold text-first '>Télécharger via l’App Store</p>
                                    <img src={appleIcon} className="w-5" alt="" />
                                </div>
                            </a>
                            <a href="https://play.google.com/store/apps/details?id=fr.teachr.app" target="_blank" rel="noreferrer">
                                <img src={qrCodePlaystoreBtn} alt="" className='mx-auto' />
                                <div className="flex items-center gap-2 py-4">
                                    <p className='text-base font-extrabold text-first '>Télécharger via Google play</p>
                                    <img src={playIcon} className="w-5" alt="" />

                                </div>
                            </a>
                        </div>
                        <div className="flex items-center justify-between gap-8 py-8 text-center md:w-2/4 lg:hidden">
                            <div className=''>
                                <img className='' src={appStoreIcon} alt="" />
                            </div>
                            <div className=''>
                                <img className='' src={playStoreIcon} alt="" />
                            </div>
                        </div>
                        <Button href='/teachr/candidatures' className=' bg-transparent rounded-full py-2 my-4  text-first lg:w-2/4 w-full border-solid border-[3px] border-first px-8 uppercase font-extrabold' >mes candidatures</Button>
                    </div>

                </div>
                <div className='bg-center bg-no-repeat bg-contain bg-confirm-illu md:h-creen md:w-full'>

                </div>
            </div>
        </div>
    )
}

export default Confirm