import React, { useEffect, useState } from 'react';
import ProfileImg from '../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../Assets/Icons/clock.svg'
import CalendarIcon from '../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../Assets/Icons/clip.svg'
import LocationIcon from '../../../Assets/Icons/location.svg'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SubjectRepository from '../../../Repositories/SubjectRepository';
import { Subject } from '../../../Objects/models/Subject';
import ChildRepository from '../../../Repositories/ChildRepository';
import Teachr from '../../../Objects/models/Teachr';
import Child from '../../../Objects/models/Child';
import AddressRepository from '../../../Repositories/AddressRepository';
import { Address } from '../../../Objects/models/Address';
import { Button, FormControl, FormControlLabel, RadioGroup, Skeleton } from '@mui/material';
import Functions from '../../../Helpers/Functions';
import TeachrRepository from '../../../Repositories/TeachrRepository';
import Client from '../../../Objects/models/Client';
import ClientRepository from '../../../Repositories/ClientRepository';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import RadioButton from '../../utils/RadioButton';
import closeCircle from '../../../Assets/Icons/close_circle.svg'
import Input from '../../utils/Input';
import AddressComponent from '../../utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import validateZipCode from '../../../Validation/zipCode';
import ValidateNni from '../../../Validation/Nni';
import ValidateIban from '../../../Validation/Iban';


const PunctualCourses = (props: any) => {
    const navigate = useNavigate()
    const abortController = new AbortController()
    const [subject, setSubjects] = useState<Subject>()
    const [child, setChild] = useState<Child | Client>()
    const [address, setAddress] = useState<Address>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [search, setSearch] = useState('')
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response && response.id) {
                setTeachr(response)
                if (props.course.forChild_id) {
                    ChildRepository.fetchChildByIdForTeachr(props.course.forChild_id, response.id).then((childResponse) => {
                        setChild(childResponse)

                    })
                } else if (props.course.forClient_id) {
                    ClientRepository.fetchClientByIdForTeachr(response.id, props.course.forClient_id).then((clientResponse) => {
                        setChild(clientResponse)
                    })
                }
            }
        })
       return (
        abortController.abort()
       )
    }, [props])
    useEffect(() => {
        if (props.course.subjectAsked_id) {
            SubjectRepository.fetchSubjectById(props.course.subjectAsked_id).then((response) => {
                setSubjects(response)
            })
        }
    }, [props])
    useEffect(() => {
        if (props.course.address_id) {
            AddressRepository.fetchAddressById(props.course.address_id).then((response) => {
                setAddress(response)
            })
        }
    }, [props])
    const formatTime = (time: string | undefined) => {
        let format = ''
        if (time) {
            const regex = "(.*):(.*)"
            const match = time.match(regex)
            if (match) {
                format = `${match[1]}h${match[2]}`
            }
        }
        return format
    }
    const isCandidate = () => {
        const maxStartDate = moment(props.course.endDatetime).subtract(props.course.duration / 2, 'hours').toDate()
        const now = moment().toDate()
        if (now < maxStartDate) {
            return true
        }
        return false
    }
    useEffect(() => {
        if (props.subjects.length > 0) {
            let count = 0
            props.subjects.map((subject: Subject) => {
                if (props.course.subjectAsked_id === subject.id) {
                    count++
                }
            })
            if (count > 0) {
                setSearch('')
            } else {
                setSearch('hidden')
            }
        } else {
            setSearch('')
        }
    }, [props])
    const MySwal = withReactContent(Swal)
    const acceptCourse = () => {
        let isBornFrance = false
        let nni = ''
        let secondName = ''
        let postalCode = ''
        let defaultAddress: Address | null = null
        let iban = ''

        const getPlaceId = (placeId: string) => {
            geocodeByPlaceId(placeId).then((response) => {
                const addressObject = new Address(
                    response[0].place_id,
                    response[0].address_components[0].long_name,
                    response[0].address_components[1].long_name,
                    response[0].address_components[2].long_name,
                    response[0].address_components[3].long_name,
                    response[0].address_components[4].long_name,
                    response[0].address_components[5].long_name,
                    response[0].address_components[6].long_name,
                    response[0].geometry.location.lat().toString(),
                    '',
                    response[0].formatted_address,
                    teachr?.['@id']!,
                    true)
                addressObject.sessionToken = ''
                defaultAddress = addressObject
            })
        }
        const getPostal = (value: string) => {
            postalCode = value
        }
        const getNni = (value: string) => {
            nni = value.replace(/\s/g, '')
        }
        const getSecondName = (value: string) => {
            secondName = value
        }
        const validate = () => {
            let errAddress = true
            let errZipCode = true
            let errNni = true
            let errIban = true
            const errorsTag = document.getElementsByClassName('errors')
            if (defaultAddress) {
                errAddress = false
                errorsTag[0].innerHTML = ""
            } else {
                errAddress = true
                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"
            }
            if (validateZipCode(postalCode).isValid) {
                errZipCode = false
                errorsTag[1].innerHTML = validateZipCode(postalCode).message
            } else {
                errZipCode = true
                errorsTag[1].innerHTML = validateZipCode(postalCode).message
            }
            if (ValidateNni(nni).isValid) {
                errNni = false
                errorsTag[2].innerHTML = ValidateNni(nni).message
            } else {
                errNni = true
                errorsTag[2].innerHTML = ValidateNni(nni).message
            }
            if (ValidateIban(iban).isValid) {
                errorsTag[3].innerHTML = ValidateIban(iban).message
                errIban = false
            } else {
                errorsTag[3].innerHTML = ValidateIban(iban).message
                errIban = true
            }
            switch (teachr?.isAE) {
                case false:
                    if (teachr?.address !== null) {
                        if (teachr?.iban == null) {
                            if (errZipCode || errNni || errIban) {
                                return false
                            } else {
                                return true
                            }
                        } else {
                            if (errZipCode || errNni) {
                                return false
                            } else {
                                return true
                            }
                        }

                    } else {
                        if (teachr?.iban == null) {
                            if (errAddress || errZipCode || errNni || errIban) {
                                return false
                            } else {
                                return true
                            }
                        } else {
                            if (errAddress || errZipCode || errNni) {
                                return false
                            } else {
                                return true
                            }
                        }

                    }
                default:
                    if (errAddress) {
                        return false
                    } else {
                        return true
                    }
            }
        }

       
        const createAddress = () => {
            let submitError = true
            AddressRepository.createAddress(defaultAddress!).then(()=>{
                submitError = true
                return true
            })
            .catch((error) => {
                submitError = false
                return false
            })
            return submitError
        }
        const updateTeachr = () => {
            let submitError = true
            const newTeachr = teachr!
            if (teachr && teachr.iban == null) {
                newTeachr.iban = iban.replace(/ /g, "")
            }
            newTeachr.NNI = nni
            newTeachr.bornPlace = postalCode
            newTeachr.secondFirstName = secondName
            newTeachr.isBornFrance = isBornFrance
            if (teachr && teachr.proofIdentity) {
                newTeachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr && teachr.proofIsAE) {
                newTeachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr && teachr.proofSignSap) {
                newTeachr.proofSignSap = teachr.proofSignSap['@id']
            }
            if (teachr && teachr.proofMandat) {
                newTeachr.proofSignSap = teachr.proofSignSap['@id']
            }
            TeachrRepository.updateTeachr(newTeachr!).catch((error) => {
                submitError = false
            })
            return submitError
        }
        switch (teachr?.isAE) {
            case true:
                if (teachr?.address !== null) {
                    if (teachr && teachr.iban === null) {
                        MySwal.fire({
                            showConfirmButton: false,
                            html:
                                <div>
                                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                                        <p>Renseignements supplémentaires</p>
                                        <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                    </div>
                                    {/* <div className='py-8'>
                                        <p className='w-full text-sm font-bold text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                    </div> */}
                                    <div className="py-8 text-left ">
                                        <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                            Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                        </p>
                                        <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                            <div className={teachr.iban ? 'hidden' : ''}>
                                                <p className='text-sm text-red-600 errors'></p>
                                                <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                    <p className='mx-2'>Iban</p>
                                                    <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex justify-end pt-4 md:pt-8'>
                                            <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                        </div>
                                    </div>
                                </div>,
                            preConfirm: () => {
                                const errorsTag = document.getElementsByClassName('errors')
                                if (ValidateIban(iban).isValid) {
                                    errorsTag[0].innerHTML = ValidateIban(iban).message
                                    teachr.iban = iban.replace(/ /g, "")
                                    if (teachr && teachr.proofIdentity) {
                                        teachr.proofIdentity = teachr.proofIdentity['@id']
                                    }
                                    if (teachr && teachr.proofIsAE) {
                                        teachr.proofIsAE = teachr.proofIsAE['@id']
                                    }
                                    if (teachr && teachr.proofSignSap) {
                                        teachr.proofSignSap = teachr.proofSignSap['@id']
                                    }
                                    if (teachr && teachr.proofMandat) {
                                        teachr.proofMandat = teachr.proofMandat['@id']
                                    }
                                    TeachrRepository.updateTeachr(teachr).catch(() => {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    })
                                } else {
                                    errorsTag[0].innerHTML = ValidateIban(iban).message
                                    return false;
                                }
                            }
                        }).then((result) => result.isConfirmed && navigate("/teachr/cours/postuler/", {
                            state: {
                                punctual: {
                                    teachr: teachr,
                                    type: "Ponctuel",
                                    course: props.course,
                                    subject: subject,
                                    child: child,
                                    address: address
                                }, courseType: 'punctual'
                            }
                        }))
                        MySwal.getPopup()?.classList.add('shadow-candidature-close')
                        MySwal.getPopup()?.classList.add('w-[698px]')
                        MySwal.getPopup()?.classList.add('rounded-[20px]')
                    } else {
                        navigate("/teachr/cours/postuler/", {
                            state: {
                                punctual: {
                                    teachr: teachr,
                                    type: "Ponctuel",
                                    course: props.course,
                                    subject: subject,
                                    child: child,
                                    address: address
                                }, courseType: 'punctual'
                            }
                        });
                    }

                } else if (teachr && teachr.iban == null) {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                {/* <div className='py-8'>
                                    <p className='w-full text-sm font-bold text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div> */}
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                        <div className={teachr.iban ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Iban</p>
                                                <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value.replace(/ /g, "")} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            let errAddress = true
                            let errIban = true
                            if (defaultAddress) {
                                errAddress = false
                                errorsTag[0].innerHTML = ""
                            } else {
                                errAddress = true
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"

                            }
                            if (ValidateIban(iban).isValid) {
                                errorsTag[1].innerHTML = ValidateIban(iban).message
                                errIban = false
                            } else {
                                errorsTag[1].innerHTML = ValidateIban(iban).message
                                errIban = true
                            }
                            if (!errAddress && !errIban) {
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                } else {
                                    teachr.iban = iban.replace(/ /g, "")
                                    if (teachr && teachr.proofIdentity) {
                                        teachr.proofIdentity = teachr.proofIdentity['@id']
                                    }
                                    if (teachr && teachr.proofIsAE) {
                                        teachr.proofIsAE = teachr.proofIsAE['@id']
                                    }
                                    if (teachr && teachr.proofSignSap) {
                                        teachr.proofSignSap = teachr.proofSignSap['@id']
                                    }
                                    if (teachr && teachr.proofMandat) {
                                        teachr.proofMandat = teachr.proofMandat['@id']
                                    }
                                    TeachrRepository.updateTeachr(teachr).catch(() => {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    })
                                }
                            } else {
                                return false
                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/teachr/cours/postuler/", {
                        state: {
                            punctual: {
                                teachr: teachr,
                                type: "Ponctuel",
                                course: props.course,
                                subject: subject,
                                child: child,
                                address: address
                            }, courseType: 'punctual'
                        }
                    }))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                } else {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                {/* <div className='py-8'>
                                    <p className='w-full text-sm font-bold text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div> */}
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            if (defaultAddress) {
                                errorsTag[0].innerHTML = ""
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                }
                            } else {
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"
                                return false
                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/teachr/cours/postuler/", {
                        state: {
                            punctual: {
                                teachr: teachr,
                                type: "Ponctuel",
                                course: props.course,
                                subject: subject,
                                child: child,
                                address: address
                            }, courseType: 'punctual'
                        }
                    }))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                }
                break;

            default:
                if (teachr?.isBornFrance === null && teachr?.NNI === null) {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                <div className='py-8'>
                                    <p className='w-full text-sm font-bold text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div>
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>
                                    <p className='py-1 text-sm text-normal'>
                                        PS : si vous avez le statut auto-entrepreneur, cette étape n’est pas nécessaire.
                                        <a href='https://drive.google.com/drive/folders/1ua_E5nxAJQzB8NIE4E-4fI6hkwD_SS0e?usp=sharing' className='ml-1 underline' target="_blank" rel="noreferrer">Cliquez ici</a> pour en savoir plus et vous créer le statut 😉</p>
                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <FormControl>
                                            <div className="flex items-center justify-between">
                                                <label className='text-sm font-extrabold text-first md:text-base'>Êtes-vous né(e) en France ? </label>
                                                <RadioGroup row
                                                    defaultValue="1"
                                                    name="customized-radios"
                                                >
                                                    <FormControlLabel value="1" onClick={() => isBornFrance = true} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                    <FormControlLabel value="0" onClick={() => isBornFrance = false} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                                </RadioGroup>
                                            </div>
                                        </FormControl>
                                        <div className={teachr.address ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <Input callback={getPostal} label="Code postal de naissance" inset="true" placeholder='Ex : 75013' customClass="bg-bg-input-light p-2 text-sm text-normal font-bold" />
                                        </div>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <Input callback={getNni} label="Numéro de sécurité sociale" inset="true" placeholder='Ex : 1 97 12 83 232 428 48' customClass="bg-bg-input-light p-2 text-sm text-normal font-bold" />
                                        </div>
                                        <div className={teachr.iban ? 'hidden' : ''}>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Iban</p>
                                                <input id='iban-input' type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => iban = e.target.value.replace(/ /g, "")} placeholder='FRXX XXXX XXXX XXXX XXXX XXXX XXX' />
                                            </div>
                                        </div>
                                        <div>
                                            <Input callback={getSecondName} label="Deuxième prénom (Facultatif)" inset="true" placeholder='Ex : Hélène' customClass="bg-bg-input-light p-2 text-sm text-normal font-bold" />
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            if (validate()) {
                                if (!teachr.address) {
                                    if (createAddress()) {
                                        if (!updateTeachr()) {
                                            return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                        }
                                    }
                                } else {
                                    if (!updateTeachr()) {
                                        return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                    }
                                }


                            } else {
                                return false
                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/teachr/cours/postuler/", {
                        state: {
                            punctual: {
                                teachr: teachr,
                                type: "Ponctuel",
                                course: props.course,
                                subject: subject,
                                child: child,
                                address: address
                            }, courseType: 'punctual'
                        }
                    }))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                } else if (teachr?.address) {
                    navigate("/teachr/cours/postuler/", {
                        state: {
                            punctual: {
                                teachr: teachr,
                                type: "Ponctuel",
                                course: props.course,
                                subject: subject,
                                child: child,
                                address: address
                            }, courseType: 'punctual'
                        }
                    });
                } else {
                    MySwal.fire({
                        showConfirmButton: false,
                        html:
                            <div>
                                <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                                    <p>Renseignements supplémentaires</p>
                                    <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                                </div>
                                <div className='py-8'>
                                    <p className='w-full text-sm font-bold text-third'>NB : Ces informations servent à ce que vos cours soient déclarés par nos soins. Nous ne sommes PAS en train de vous créer votre statut auto-entrepreneur. </p>
                                </div>
                                <div className="py-8 text-left ">
                                    <p className='text-sm text-normal'>Pour postuler au cours, merci de remplir les informations suivantes afin de pouvoir effectuer les démarches de déclaration auprès de l’URSSAF.
                                        Une fois complétées, vous pourrez postuler à autant de cours que vous souhaitez !
                                    </p>

                                    <div className='flex flex-col w-full gap-4 pt-4 md:w-2/3'>
                                        <div>
                                            <p className='text-sm text-red-600 errors'></p>
                                            <div className='p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal'>
                                                <p className='mx-2'>Adresse postale</p>
                                                <AddressComponent getPlaceId={getPlaceId} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex justify-end pt-4 md:pt-8'>
                                        <Button onClick={() => Swal.clickConfirm()} className='block bg-transparent rounded-full md:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                                    </div>
                                </div>
                            </div>,
                        preConfirm: () => {
                            const errorsTag = document.getElementsByClassName('errors')
                            if (defaultAddress) {
                                errorsTag[0].innerHTML = ""
                                if (!createAddress()) {
                                    return Swal.showValidationMessage("Erreur : Quelque chose s'est mal passé")
                                }
                            } else {
                                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide"
                                return false
                            }
                        }
                    }).then((result) => result.isConfirmed && navigate("/teachr/cours/postuler/", {
                        state: {
                            punctual: {
                                teachr: teachr,
                                type: "Ponctuel",
                                course: props.course,
                                subject: subject,
                                child: child,
                                address: address
                            }, courseType: 'punctual'
                        }
                    }))
                    MySwal.getPopup()?.classList.add('shadow-candidature-close')
                    MySwal.getPopup()?.classList.add('w-[698px]')
                    MySwal.getPopup()?.classList.add('rounded-[20px]')
                }
                break;
        }


    }
    const redirectLastStep = () => {
        // StepperStore.setItem("currentStep" + user?.uid, 4, function (err) { })
        navigate('/teachr/register/next')
    }
    return (
        <div className={`shrink ${search} ${!isCandidate() && 'opacity-60'}`}>
            <div className='shadow-course-card w-[315px]  p-6 rounded-[10px] mx-auto md:mx-0 relative'>
                {!isCandidate() && <p className='absolute font-semibold text-red-600 right-2 top-1'>- DÉLAIS ÉCOULÉ -</p>}
                <div className="flex flex-col gap-5">
                    <div className="flex items-center gap-4">
                        <img src={ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                        <div>
                            {subject ?
                                <p className='m-0 text-xl font-bold text-first text-ellipsis whitespace-nowrap overflow-hidden w-[210px]' title={subject.fr_FR}>{subject.fr_FR}</p>
                                :
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="200px"
                                />
                            }
                            {child ?
                                <p className='text-[#4D4D4D] text-sm'>Pour {child.firstName} / {Functions.getLevel(child.class)}</p>
                                :
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="200px"
                                />
                            }
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <div>
                            <p className='text-[16px] font-bold text-first whitespace-nowrap'>Créneau de disponibilité</p>
                            <p className='text-[#4D4D4D] text-sm'>{formatTime(moment(props.course.startDatetime).format('LT'))} - {formatTime(moment(props.course.endDatetime).format('LT'))}</p>
                        </div>
                    </div>

                    <div className="flex items-center gap-4">
                        <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                        <p className='text-[16px] font-bold text-first whitespace-nowrap'>{moment(props.course.startDatetime).format('dddd DD MMMM YYYY')}</p>
                    </div>

                    {!props.course.isRemote
                        ? <div className="flex items-center gap-4">
                            <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            {address ?
                                <a href={`https://www.google.com/maps/search/?api=1&query=${address?.streetName}%2C+${address?.city}`} target='_blanck' className='underline text-[16px] font-bold  text-first'>{address.streetName + ', ' + address.city}</a>
                                :
                                <Skeleton
                                    animation="wave"
                                    height={20}
                                    width="200px"
                                />
                            }
                        </div> :
                        <div className="flex items-center gap-4">
                            <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                            <p className='text-[16px] font-bold text-first'>Cours à distance</p>
                        </div>}
                </div>
                {teachr && subject && child && address ?
                    <Button onClick={() => !isCandidate() ? null : teachr!.isValidated ? acceptCourse() : redirectLastStep()} disabled={!isCandidate() ? true : false} className='mt-6 bg-transparent rounded-full py-2 text-first  border-solid border-[1px] border-first  uppercase  w-full font-extrabold' >POSTULER</Button>
                    :
                    <Skeleton
                        animation="wave"
                        height={40}
                        width="100%"
                    />
                }

            </div>
        </div>

    );
};

export default PunctualCourses;