import { useEffect, useState } from "react";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import ponct_dash from "../../../../../../../Assets/Images/ponct_dash.svg"
import SubscriptionCourseRepositorys from "../../../../../../../Repositories/SubscriptionCourseRepository";
import ComponentBodyTableSubscriptionPrevu from "./ComponentBodySubscriptionPrevu";
import moment from "moment";
import Skeleton from '@mui/material/Skeleton';
import React from "react";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";


const ComponentSubscriptionPrevu = () => {
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const [urssaf, setUrssaf] = useState<PunctualCourseDashboard[]>();
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubscriptionCourseRepositorys.getActiveChargeForSubscriptionCourseByTeachrForDashboard(teachr.id).then(
                    (course: any) => {
                        setCourse(course);
                    }
                );
                SubscriptionCourseRepositorys.getAllForSubscriptionUrssafStatus(teachr.id).then((res) => {
                   // eslint-disable-next-line array-callback-return
                   res.map((urssafs:any) => {
                       setUrssaf(res);
                    
                   })
                })
            }
        });
    }, []);


    return (
      <>
        {course === undefined ? (
          <div className="overflow-y-auto scroll w-full relative  h-[70%]  mt-4 //px-2 //py-2 //bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        ) : course.length === 0 ? (
          <div className="  md:w-full relative list-teacher h-[95%]  px-2 py-2 //bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            <div className="mx-auto">
              <div className="mx-auto mt-8 md:w-4/5 ">
                <p className="text-[#4d4d4d] text-center lg:text-2xl md:text-lg text-base font-bold mx-auto ">
                  Afin de recevoir un paiement via notre plateforme, veuillez
                  déclarer votre premier cours, ainsi que tout autre cours que
                  vous avez déjà dispensé.
                </p>
              </div>
              <div className="mx-auto mt-16 md:w-1/2">
                <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
              </div>
            </div>
          </div>
        ) : (
          <div className="overflow-y-auto md:scroll w-full relative mt-4 scroll  h-[70%] px-2 //py-2 bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            <div className="">
              <table className="w-full text-sm text-left  text-[#4d4d4d] ">
                <thead className="text-xs md:text-sm lg:text-base font-extrabold text-[#4d4d4d] //uppercase bg-gray-50 border-b-[2px] border-[#D2D2D2]">
                  <tr className="w-full ">
                    <th scope="col " className="py-2 px-1 w-[16%]  ">
                      Date
                    </th>
                    <th scope="col " className="py-2 px-1 w-[13%] ">
                      Élève
                    </th>
                    <th scope="col " className="py-2 px-1 w-[16%] ">
                      Matière
                    </th>
                    <th scope="col " className="py-2 ">
                      <div className="text-center">
                        <p>Paiement </p>
                        <p className="hidden text-sm font-light lowercase md:block text-grey">
                          Par le parent
                        </p>
                        <p className="text-sm font-light lowercase md:hidden text-grey">
                          parent
                        </p>
                      </div>
                    </th>
                    <th scope="col " className="py-2 px-1 w-[10%] ">
                      <div className="w-5 mx-auto -translate-y-2 border-b-2"></div>
                    </th>
                    <th scope="col " className="py-2 ">
                      <div className="text-center">
                        <p>Rémunération</p>
                        <p className="text-sm font-light lowercase text-grey">
                          Pour vous
                        </p>
                      </div>
                    </th>
                    <th scope="col " className="py-2 px-1 text-center w-[12%] ">
                      Durée
                    </th>
                    <th scope="col " className="py-2 px-1 text-center w-[12%] ">
                      Statut
                    </th>
                  </tr>
                </thead>
                {course
                  ?.sort(function (a: any, b: any) {
                    const date1: any = moment(a.startDateOccurrence).toDate();
                    const date2: any = moment(b.startDateOccurrence).toDate();
                    return date1 - date2;
                  })
                  ?.map((course: any, key: any) => (
                    <ComponentBodyTableSubscriptionPrevu
                      course={course}
                      key={key}
                    />
                  ))}
              </table>
            </div>
          </div>
        )}
      </>
    );
};

export default ComponentSubscriptionPrevu;
