import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
// import moment from 'moment';
// import { type } from 'os';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useNavigate, useParams } from 'react-router-dom';
import DateInput from '../../../Components/Admin/DateInput';
import Input from '../../../Components/Admin/Input';
import SelectComponent from '../../../Components/Admin/Select';
import AddressComponent from '../../../Components/utils/Address';
import RadioButton from '../../../Components/utils/RadioButton';
import StepperStore from '../../../LocalForage/StepperStore';
import { Address } from '../../../Objects/models/Address';
import Teachr from '../../../Objects/models/Teachr';
import TeachrContact from '../../../Objects/models/TeachrContact';
// import AddressRepository from '../../../Repositories/AddressRepository';
// import ClientRepository from '../../../Repositories/ClientRepository';
import TeachrContactRepository from '../../../Repositories/TeachrContactRepository';
// import validateDate from '../../../Validation/Date';
// import validateSelect from '../../../Validation/Select';
import validateZipCode from '../../../Validation/zipCode';
// import validateName from '../../../Validations/Name';
import SelectCountry from '../../../Components/Admin/SelectCountry';
import Layout from './Layout';
import { useDispatch } from 'react-redux';
import SelectCity from '../../../Components/Admin/SelectCity';
import validateDateAici from '../../../Validation/DateAici';
import validateBirthName from '../../../Validation/BirthName';
// import SelectTypeVoie from '../../../Components/Admin/SelectTypeVoie';
// import Functions from '../../../Helpers/Functions';

interface data {
    street_number: null,
    street_name_short: null,
    route: null,
    locality: null,
    administrative_area_level_2: null,
    administrative_area_level_1: null,
    country: null,
    postal_code: null
}
const Second = (props: any) => {
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [teachrContact, setTeachrContact] = useState<TeachrContact>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [address, setAddress] = useState<Address>()
    const [gender, setGender] = useState(0)
    const [valueForm, setValueForm] = useState(0)
    const [streetNumber, setStreetNumber] = useState('')
    const [birthDay, setBirthDay] = useState('')
    const [isBornFrance, setIsBornFrance] = useState(false)
    // const [isTypeVoie, setIsTypeVoie] = useState(false)
    const [isStreetNumber, setIsStreetNumber] = useState(false)
    const [country, setCountry] = useState('')
    // const [typeVoie, setTypeVoie] = useState('')
    // const [codeVoie, setCodeVoie] = useState('')
    const [zipCode, setZipcode] = useState<string>("")
    const [isUpdateAddress, setIsUpdateAddress] = useState(false)
    const [city, setCity] = useState('')
    const [birthName, setBirthName] = useState('')
    const [warning, setWarning] = useState(false)
    const [value, setValue] = React.useState(teachrContact?.isBornFrance ? "1" : "0");
    const [errors, setErorrs] = useState({
        gender: false,
        birthDay: false,
        isBornFrance: false,
        country: false,
        address: false,
        zipCode: false,
        city: false,
        birthName: false,
        typeVoie: false,
        streetNumber: false,
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (value === "1") {
            setIsBornFrance(false)
        }else{
            setIsBornFrance(true)
        }
        setValue((event.target as HTMLInputElement).value);
    };
    useEffect(() => {
        if (params.key) {
            TeachrContactRepository.fetchTeachrContactByKey(params.key).then((response) => {
                if (response) {
                    setTeachrContact(response)
                    setTeachr(response.teachr)
                    setIsBornFrance(response.isBornFrance)
                    StepperStore.getItem('addresseFacturation').then((item: any) => {
                        if (item) {
                            setAddress(item)
                            setIsUpdateAddress(true)
                        } else {
                            if (response.address) {
                                setAddress(response.address)
                                setIsUpdateAddress(false)
                            }
                        }
                    })
                }
            })
                .catch(() => navigate('/notfound'))
        } else {
            navigate('/notfound')
        }
        // StepperStore.getItem('wayCode').then((res: any) => {
        //     if (res) {
        //         setIsTypeVoie(false)
        //         // setCodeVoie(res)
        //     }
        // })
    }, [])
    const checkIfSameCity = (city: string | undefined) => {
        if (city && address && isBornFrance) {
            if (address.city.trim().toLowerCase() === city.trim().toLocaleLowerCase()) {
                setWarning(true)
                return true
            }
            return false
        }
        return false

    }
    const handleClick = () => {
        if (validation()) {
            if (!checkIfSameCity(city)) {
                nextStep()
            }
        }
    }
    const nextStep = () => {
        setWarning(false)
        if (teachrContact) {
            if (validation() && teachrContact && address) {
                teachrContact.gender = gender.toString()
                teachrContact.birthName = birthName?.trim()
                teachrContact.birthDay = birthDay
                teachrContact.step = 2
                teachrContact.isBornFrance = isBornFrance
                if (isBornFrance) {
                    teachrContact.country = ""
                    teachrContact.zipCode = (zipCode)
                    teachrContact.city = city
                } else {
                    teachrContact.country = country
                    teachrContact.zipCode = ""
                    teachrContact.city = ""
                }
                if (teachr) {
                    teachrContact.teachr = teachr['@id']!
                }
                if (!address.streetNumber) {
                    address.streetNumber = streetNumber
                }
                if (isUpdateAddress) {
                    StepperStore.setItem('addresseFacturation', address)
                    teachrContact.address = address['@id']
                } else {
                    StepperStore.removeItem('addresseFacturation')
                    teachrContact.address = address['@id']
                }
                TeachrContactRepository.updateTeachrContact(teachrContact).then(() => {
                    props.nextStep(2)
                })
            }
        }


    }
    const prevStep = () => {
        props.nextStep(0)
    }
    const getGender = (value: number) => {
        setGender(value)
    }
    const getBirthName = (value: string) => {
        setBirthName(value)
    }
    const getBirthDay = (value: string) => {
        setBirthDay(value)
    } 
    const getCountry = (value: string) => {
        if(value === "FRANCE"){
            setValue("1")
            setIsBornFrance(true)
        }else{
            setCountry(value)
        }
    }
  
    const getStreetNumber = (value: string) => {
        setStreetNumber(value)
    }
    const getCity = (value: string) => {
        setCity(value)
    }
    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            let updatedAddressInfo: any = {
                street_number: null,
                street_name_short: null,
                route: null,
                locality: null,
                administrative_area_level_2: null,
                administrative_area_level_1: null,
                country: null,
                postal_code: null
            };
            const resultsArray = response[0]
            resultsArray.address_components.forEach(singleResult => {
                if (singleResult.types[0] === 'route') {
                    updatedAddressInfo.street_name_short = singleResult.short_name;
                } else {
                    if (singleResult.types[0] === 'neighborhood') {
                        updatedAddressInfo.street_name_short = singleResult.short_name;
                    }
                }

                Object.keys(updatedAddressInfo).forEach((key) => {
                    if (singleResult.types.includes(key)) {
                        updatedAddressInfo[key] = singleResult.long_name;
                    }
                });
            });

            setStreetNumber(updatedAddressInfo.street_number)
            const addressObj = new Address(
                response[0].place_id,
                updatedAddressInfo.street_number,
                updatedAddressInfo.route,
                updatedAddressInfo.locality,
                updatedAddressInfo.administrative_area_level_2,
                updatedAddressInfo.administrative_area_level_1,
                updatedAddressInfo.country,
                updatedAddressInfo.postal_code,
                response[0].geometry.location.lat().toString(),
                '',
                response[0].formatted_address,
                "",
                true)
            addressObj.sessionToken = ''
            if (!addressObj.streetNameShort) {
                addressObj.streetNameShort = updatedAddressInfo.street_name_short
            }
            if (addressObj.formattedAddress !== address?.formattedAddress) {
                setIsUpdateAddress(true)
                setAddress(addressObj)
            } else {
                setIsUpdateAddress(false)
            }
        })
    }

    const getZipCode = (value: number | null) => {
        if (value) {
            setZipcode(value.toString())
        }
    }
    const validation = (): boolean => {
        let valid = {
            gender: false,
            birthDay: false,
            isBornFrance: false,
            country: false,
            address: false,
            zipCode: false,
            city: false,
            birthName: false,
            typeVoie: false,
            streetNumber: false
        }
        if (!isBornFrance) {
            valid.zipCode = false
            if (country && country !== "") {
                valid.country = false
            } else {
                valid.country = true
            }
        } else {
            valid.country = false
            valid.zipCode = !validateZipCode(zipCode, city).isValid
            if (city && city !== "") {
                valid.city = false
            } else {
                valid.city = true
            }

        }

        if (address) {
            if (!streetNumber) {
                if (address.streetNumber) {
                    valid.streetNumber = false
                    setIsStreetNumber(valid.streetNumber)
                } else {
                    valid.streetNumber = true
                    setIsStreetNumber(valid.streetNumber)
                }

            } else {
                valid.streetNumber = false
                if (!address.streetNumber) {
                    address.streetNumber = streetNumber
                }
                setIsStreetNumber(valid.streetNumber)
            }
            valid.address = false
        } else {
            valid.address = true
        }
        valid.birthDay = !validateDateAici(birthDay).isValid


        if (gender) {
            valid.gender = false
            if (gender.toString() === '2') {
                if (!birthName || birthName === "") {
                    valid.birthName = true;
                } else {
                    valid.birthName = !validateBirthName(birthName).isValid;
                }
            } else {
                valid.birthName = false;
            }
        } else {
            valid.gender = true
        }

        setErorrs(valid)
        if (!valid.gender && !valid.birthDay && !valid.address && !valid.country && !valid.city && !valid.zipCode && !valid.birthName && !valid.typeVoie && !valid.streetNumber) {
            return true
        } else {
            return false
        }
    }
    
    const renderBornFrance = () => {
        if (isBornFrance) {
            return (
                <div className="grid w-full grid-cols-1 md:items-end gap-x-8 md:grid-cols-2 gap-y-4 ">
                    <div className="relative w-full">
                        <p className={`${errors.zipCode ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validateZipCode(zipCode, city).message}</p>
                        <Input defaultValue={teachrContact?.zipCode?.length !== 0 ? teachrContact?.zipCode : null} callBack={getZipCode} label='Code postal de naissance' placeholder='Ex: 75001' />
                    </div>
                    <div className="relative w-full">
                        <p className={`${errors.address ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez entrer une adresse valide"}</p>
                        <div className='relative px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
                            <label className='block lg:text-base'>Adresse de facturation</label>
                            {address ? <AddressComponent default={address.formattedAddress} getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" /> : <AddressComponent getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" />}

                        </div>
                    </div>
                    <div className="relative w-full">
                        <p className={`${errors.city ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez sélectionner une ville"}</p>
                        {teachrContact && teachrContact.city && teachrContact.city !== "" ?
                            <SelectCity value={teachrContact.city} callBack={getCity} zipCode={zipCode} />
                            :
                            <SelectCity callBack={getCity} zipCode={zipCode} />}
                    </div>
                    {(!address?.streetNumber) &&
                        <div>
                            <p className={`${errors.streetNumber ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez entrer le numéro de la voie"}</p>
                            <Input defaultValue={streetNumber} callBack={getStreetNumber} label='Numéro de la voie' placeholder='Ex: 11' />
                        </div>
                    }
                </div>
            )
        }
        return (
            <div className="grid w-full grid-cols-1 md:items-end gap-x-8 md:grid-cols-2 gap-y-4">
                <div className="relative w-full ">
                    <p className={`${errors.country ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez sélectionner un pays"}</p>
                    {teachrContact && teachrContact.country && teachrContact.country !== "" ?
                        <SelectCountry value={teachrContact.country} callBack={getCountry} />
                        :
                        <SelectCountry callBack={getCountry} />}
                </div>
                <div className="relative w-full">
                    <p className={`${errors.address ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez entrer une adresse valide"}</p>
                    <div className='relative px-4 py-1 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
                        <label className='block lg:text-base '>Adresse de facturation</label>
                        {address ? <AddressComponent default={address.formattedAddress} getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" /> : <AddressComponent getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" />}
                    </div>
                </div>
                {!isStreetNumber || (!address?.streetNumber) && <div className={`col-end-3`}>
                    <p className={`${errors.streetNumber ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez entrer le numéro de la voie"}</p>
                    <Input defaultValue={streetNumber} callBack={getStreetNumber} label='Numéro de la voie' placeholder='Ex: 11' />
                </div>}
            </div>

        )
    }
    const renderScreen = () => {

        if (teachrContact) {
            dispatch({
                type: 'HIDE_LOADER'
            })
            return (
                <Layout teachr={teachr?.firstName} prev={<Button onClick={() => prevStep()} className='w-full py-2 font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first md:text-base px-14 md:w-max border-first '>precedent</Button>} next={<Button onClick={() => handleClick()} className='w-full py-2 text-lg font-extrabold text-white uppercase border-2 border-solid rounded-full px-14 bg-first md:w-max border-first'>SUIVANT</Button>}>
                    <div className=''>
                        <h1 className='pb-2 text-2xl font-bold text-first'>Informations personnelles :</h1>
                        <div className='flex flex-col gap-y-4'>
                            <div className="grid items-end w-full grid-cols-1 gap-x-8 md:grid-cols-2 gap-y-4">
                                <div className='relative w-full'>
                                    <p className={`${errors.gender ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez sélectionner une civilité"}</p>
                                    <SelectComponent
                                        defaultValue={teachrContact.gender}
                                        callBack={getGender}
                                        placeholder='Homme / Femme'
                                        label="Civilité"
                                        data={[
                                            { label: "Une femme", value: 2 },
                                            { label: "Un homme", value: 1 },
                                        ]}
                                    />
                                </div>
                                <div className='relative w-full'>
                                    <p className={`${errors.birthDay ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validateDateAici(birthDay).message}</p>
                                    <DateInput callBack={getBirthDay} defaultValue={teachrContact.birthDay} />
                                </div>
                                {gender == 2 &&
                                    <div className="w-full">
                                        <p className={`${errors.birthName ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validateBirthName(birthName).message}</p>
                                        <Input field='birthName' defaultValue={teachrContact.birthName !== '' ? teachrContact.birthName : null} callBack={getBirthName} label='Nom de naissance' placeholder='Ex: DUPONT' />
                                    </div>
                                }
                            </div>
                            <div className='flex flex-col w-full md:items-center gap-x-8 md:flex-row'>
                                <label htmlFor="" className='text-xl font-bold text-first'>Pays de naissance : France*</label>

                                <FormControl>
                                    <RadioGroup row
                                        // defaultValue={teachrContact.isBornFrance ? 1 : 0}
                                        name="customized-radios"
                                        value={value}
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel  sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="1" control={<RadioButton border='2px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                        <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="0" control={<RadioButton border='2px solid #0054A3' bg='#0054A3' />} label="Non" />
                                    </RadioGroup>
                                </FormControl>
                                <div className='absolute bottom-3'><p className='text-grey'>*Information nécessaire demandée par l'URSSAF</p></div>
                            </div>
                            {renderBornFrance()}
                        </div>
                    </div>
                    <Dialog
                        fullWidth
                        open={warning}
                        onClose={() => setWarning(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        sx={{
                            "& .MuiPaper-root": {
                                borderRadius: "42px",
                                position: "relative",
                                overflow: "visible",
                                boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)"

                            }
                        }}
                    >
                        <div className="flex flex-col p-4 md:p-4 gap-y-4">
                            <DialogTitle id="alert-dialog-title" >
                                <h2 className='mt-10 font-extrabold text-center uppercase text-first lg:text-2xl'>Vérification</h2>
                            </DialogTitle>
                            <DialogContent className='flex flex-col py-10 text-center gap-y-8'>
                                <p className='text-lg font-semibold text-normal'>Vous êtes sur le point d'ajouter comme ville de naissance la même que celle où vous résidez actuellement.</p>
                                <p className='text-lg font-semibold text-normal'>Voulez-vous continuer ?</p>
                            </DialogContent>
                            <DialogActions className=''>
                                <Button onClick={() => setWarning(false)} className='block w-1/3 py-2 mx-auto font-extrabold text-white rounded-full bg-third'>Non</Button>
                                <Button onClick={nextStep} className='block w-1/3 py-2 mx-auto font-extrabold text-white rounded-full bg-first'>Oui</Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </Layout>
            )
        }
        dispatch({
            type: 'SHOW_DASHBOARD_AICI_LOADER'
        })
        return
    }
    return (
        <div className='w-full'>{renderScreen()}</div>
    );
};

export default Second;