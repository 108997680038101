import ApiService from "../Services/ApiService"
import { Route } from "../Objects/enums/RouteEnum";
import { Address } from "../Objects/models/Address";
import { plainToClass } from "class-transformer";
import Client from "../Objects/models/Client"

export default abstract class AddressRepository {
    /**
     * Fetch a address stored into the API by his id.
     * @param id
     */
    public static fetchAddressById(id: number) {
        return ApiService.getSingleInstance<Address>(
            Route.ADDRESS_BASE + "/" + id,
            Address
        );
    }

    public static createAddressForClient(address: Address, clientId: number) {
        return ApiService.postInstance<Address>(
            `/clients/v2/clients/${clientId}/addresses`,
            Address,
            address
        );
        // return ApiService.post(`/clients/v2/clients/${clientId}/addresses`, address);
    }

    public static createAddressForTeacher(address: Address, teachrId: number) {
        return ApiService.post(`/teachrs/v2/teachrs/${teachrId}/addresses`, address);
    }

    /**
     * Creates an instance of address into the API.
     * @param address
     */
    public static createAddress(address: Address) {
        return ApiService.postInstance<Address>(
            Route.ADDRESS_BASE,
            Address,
            address
        );
    }

    /**
     * Updates the instance of the address passed as parameter.
     * @param address
     */
    public static updateAddress(address: Address) {
        // if (address.owner instanceof Client) {
        //     // TODO add Teach'r
        //     if (address.owner["@id"] !== undefined) {
        //         address.owner = address.owner["@id"];
        //     }
        // }
        return ApiService.putInstance<Address>(
            Route.ADDRESS_BASE + "/" + address.id,
            address,
            address
        );
    }

    /**
     * Returns the default address of the current user.
     * @returns Address
     */
    // public static async getDefaultAddress(): Promise<Address> {
    //     return ApiService
    //         .get("/addresses/default/")
    //         .then(body => plainToClass(Address, body))
    //     ;
    // }

    /**
     * Updates the instance by of the address passed as parameter.
     * By soft deleting the address (isDeleted = true)
     * @param addressId
     */
    public static deleteAddress(addressId: number) {
        return ApiService.put(
            `/clients/v2/client/addresses/soft_delete/${addressId}`
        );
    };

    /**
     * Returns all addresses of the connected user.
     */
    public static async getAddresses(): Promise<Array<Address>> {
        return ApiService
            .get("/addresses")
            .then(
                (body: any) => plainToClass(
                    Address,
                    body.members
                )
            )
        ;
    }
    public static async getDefaultAddress(): Promise<Address> {
        return ApiService.getSingleInstance(
            "/addresses/default/",
            Address
            )
    }

    

    /**
     * Returns all addresses of the connected user (client).
     * @param clientId
     */
    public static async getClientAddresses(clientId: number) {
        return ApiService
            .get(`/clients/v2/clients/${clientId}/addresses`)
            .then(
                (body: any) => plainToClass(
                    Address,
                    body
                )
            )
        ;
    }
}
