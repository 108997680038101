import React from 'react';
import eytanimage from "../../../Assets/Images/eytan.png"
import { Button } from '@mui/material';
import { useNavigate } from 'react-router';
const First = (props:any) => {
    const navigate = useNavigate()
    return (
        <div>
            <div className='bg-white rounded-[20px] p-4'>
                <div className="flex items-center gap-x-4">
                    <img src={eytanimage} alt="" />
                    <p className='text-lg font-extrabold text-normal lg:text-[22px]'>Eytan</p>
                </div>
                <div className='font-bold text-normal lg:text-lg lg:leading-[30.01px] py-4'>
                    <p>Bienvenue dans l’espace de création de votre procuration pour devenir auto-entrepreneur.
                        <br /> En nous délivrant celle-ci, vous nous permettez de faire toutes les démarches administratives à votre place. </p>
                </div>
                
            </div>
            <div className="flex flex-col gap-y-4">
                <Button onClick={() => props.callBack(1)} className='text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2' style={{ background: 'linear-gradient(180deg, #0054A3 0%, #219CFF 100%)' }}>C’EST PARTI</Button>
                <Button className='text-normal lg:text-xl rounded-full px-10 lg:py-3 mx-auto block  w-full lg:w-max py-2 lg:hidden font-semibold' onClick={() => navigate(-1)}>précédent</Button>
            </div>
        </div>
    );
};

export default First;