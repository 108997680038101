import add from "../../../../../Assets/Icons/add.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
import Teachr from "../../../../../Objects/models/Teachr";
import { DashboardLayout } from "./DashboardLayout";
import TeachrShowInfo from "../../../../../Objects/models/TeachrShowInfo";
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import PopUpInfo from "../UtilsDashboard/PopUpInfo";
import React from "react";
import simulateurBg from "../../../../../Assets/Images/simulateur_bg.svg";
import { Address } from "../../../../../Objects/models/Address";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import validateZipCode from "../../../../../Validation/zipCode";
import ValidateNni from "../../../../../Validation/Nni";
import ValidateIban from "../../../../../Validation/Iban";
import Swal from "sweetalert2";
import AddressRepository from "../../../../../Repositories/AddressRepository";
import withReactContent from "sweetalert2-react-content";
import AddressComponent from "../../../../../Components/utils/Address";
import { Button, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import closeCircle from "../../../../../Assets/Icons/close_circle.svg";
import RadioButton from "../../../../../Components/utils/RadioButton";
import Input from "../../../../../Components/utils/Input";
import { useDisplayAddStudent } from "../../../../../Screens/Teacher/Admin/Dashboard/AddStudentPage"
import { Skeleton } from "@mui/material";
import TeachrContactRepository from "../../../../../Repositories/TeachrContactRepository";
import PunctualCourseRepository from "../../../../../Repositories/PunctualCourseRepository";
import { PunctualCourseDashboard } from "../../../../../Objects/models/PunctualCourseDashboard";
import ValidIban from "../UtilsDashboard/ValidIban";

const Layout = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const handleLoad = () => { };
  const [clientTiersPrest, setClientTiersPrest] = useState<any>();
  const [course, setCourse] = useState<PunctualCourseDashboard[]>();
  const AddStudent = useDisplayAddStudent(clientTiersPrest, handleLoad);
  const [teachr, setTeachr] = useState<Teachr>();
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    if (teachr) {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getCourse = (id:number) => {
    PunctualCourseRepository.getPunctualCourseByTeachr(id, 1).then((courses) => {
      if(courses){
        setCourse(courses.courses)
      }
      }
    );
  }
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachr) => {
      if (teachr.id) {
        TeachrContactRepository.fetchClientByTeachr(teachr.id).then((res) => {
          setClientTiersPrest(res);
        });
        getCourse(teachr.id)
        // PunctualCourseRepository.getPunctualCourseByTeachr(teachr.id).then(
        //   (course) => {
        //     setCourse(course);
        //   }
        // );
      }
    });
  }, []);
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((response) => {
      if (response && response.id) {
        setTeachr(response);
      }
    });
  }, [load]);
  const acceptCourse = () => {
    let isBornFrance = false;
    let nni = "";
    let secondName = "";
    let postalCode = "";
    let defaultAddress: Address | null = null;
    let iban = "";
    let err = false;

    const getPlaceId = (placeId: string) => {
      geocodeByPlaceId(placeId).then((response) => {
        const addressObject = new Address(
          response[0].place_id,
          response[0].address_components[0].long_name,
          response[0].address_components[1].long_name,
          response[0].address_components[2].long_name,
          response[0].address_components[3].long_name,
          response[0].address_components[4].long_name,
          response[0].address_components[5].long_name,
          response[0].address_components[6].long_name,
          response[0].geometry.location.lat().toString(),
          "",
          response[0].formatted_address,
          teachr?.["@id"]!,
          true
        );
        addressObject.sessionToken = "";
        defaultAddress = addressObject;
      });
    };
    const getPostal = (value: string) => {
      postalCode = value;
    };
    const getNni = (value: string) => {
      nni = value.replace(/\s/g, "");
    };
    const getSecondName = (value: string) => {
      secondName = value;
    };
    const validate = () => {
      let errAddress = true;
      let errZipCode = true;
      let errNni = true;
      let errIban = true;
      const errorsTag = document.getElementsByClassName("errors");
      if (defaultAddress) {
        errAddress = false;
        errorsTag[0].innerHTML = "";
      } else {
        errAddress = true;
        errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
      }
      if (validateZipCode(postalCode).isValid) {
        errZipCode = false;
        errorsTag[1].innerHTML = validateZipCode(postalCode).message;
      } else {
        errZipCode = true;
        errorsTag[1].innerHTML = validateZipCode(postalCode).message;
      }
      if (ValidateNni(nni).isValid) {
        errNni = false;
        errorsTag[2].innerHTML = ValidateNni(nni).message;
      } else {
        errNni = true;
        errorsTag[2].innerHTML = ValidateNni(nni).message;
      }
      if (ValidateIban(iban).isValid) {
        errorsTag[3].innerHTML = ValidateIban(iban).message;
        errIban = false;
      } else {
        errorsTag[3].innerHTML = ValidateIban(iban).message;
        errIban = true;
      }
      switch (teachr?.isAE) {
        case false:
          if (teachr?.address !== null) {
            if (teachr?.iban == null) {
              if (errZipCode || errNni || errIban) {
                return false;
              } else {
                return true;
              }
            } else {
              if (errZipCode || errNni) {
                return false;
              } else {
                return true;
              }
            }
          } else {
            if (teachr?.iban == null) {
              if (errAddress || errZipCode || errNni || errIban) {
                return false;
              } else {
                return true;
              }
            } else {
              if (errAddress || errZipCode || errNni) {
                return false;
              } else {
                return true;
              }
            }
          }

        default:
          if (errAddress) {
            return false;
          } else {
            return true;
          }
      }
    };
    const createAddress = () => {
      let submitError = true;
      AddressRepository.createAddress(defaultAddress!)
        .then(() => {
          submitError = true;
          return true;
        })
        .catch((error) => {
          submitError = false;
          return false;
        });
      return submitError;
    };
    const updateTeachr = () => {
      let submitError = true;
      const newTeachr = teachr!;
      if (teachr && teachr.iban == null) {
        newTeachr.iban = iban.replace(/ /g, "");
      }
      newTeachr.NNI = nni;
      newTeachr.bornPlace = postalCode;
      newTeachr.secondFirstName = secondName;
      newTeachr.isBornFrance = isBornFrance;
      if (teachr && teachr.proofIdentity) {
        newTeachr.proofIdentity = teachr.proofIdentity["@id"];
      }
      if (teachr && teachr.proofIsAE) {
        newTeachr.proofIsAE = teachr.proofIsAE["@id"];
      }
      if (teachr && teachr.proofSignSap) {
        newTeachr.proofSignSap = teachr.proofSignSap["@id"];
      }
      if (teachr && teachr.proofMandat) {
        newTeachr.proofMandat = teachr.proofMandat["@id"];
      }
      TeachrRepository.updateTeachr(newTeachr!).catch((error) => {
        submitError = false;
      });
      return submitError;
    };

    let error = false;
    const handleValidate = () => {
      if (iban === "") {
        return (error = true);
      } else {
        return (error = false);
      }
    };

    switch (teachr?.isAE) {
      case true:
        if (teachr?.address !== null) {
          if (teachr && teachr.iban == null) {
            MySwal.fire({
              showConfirmButton: false,
              html: (
                <div>
                  <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                    <p>Renseignements supplémentaires</p>
                    <img
                      onClick={() => Swal.close()}
                      className="cursor-pointer"
                      src={closeCircle}
                      alt=""
                    />
                  </div>
                  <div className="py-8 text-left ">
                    <p className="text-sm text-normal">
                      Pour déclarer vos cours, merci de remplir les informations
                      suivantes afin de pouvoir effectuer les démarches de
                      déclaration auprès de l’URSSAF. Une fois complétées, vous
                      pourrez déclarer à autant de cours que vous souhaitez !
                    </p>
                    <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                      <div className={teachr.iban ? "hidden" : ""}>
                        {error !== false ? (
                          <p className="text-sm text-red-600 errors">
                            Veuillez entrer un iban valide
                          </p>
                        ) : (
                          ""
                        )}
                        {/* <p className='text-sm text-red-600 errors'>{ error !== false ? ValidateIban(iban).message : ""}</p> */}
                        <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                          <p className="mx-2">Iban</p>
                          <input
                            id="iban-input"
                            type="text"
                            className="block w-full bg-transparent border-none focus:outline-none"
                            onChange={(e) => (iban = e.target.value)}
                            placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end pt-4 lg:pt-8">
                      <Button
                        onClick={() => handleValidate()}
                        className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                      >
                        Valider
                      </Button>
                    </div>
                  </div>
                </div>
              ),
              preConfirm: () => {
                const errorsTag = document.getElementsByClassName("errors");
                if (ValidateIban(iban).isValid) {
                  errorsTag[0].innerHTML = ValidateIban(iban).message;
                  teachr.iban = iban.replace(/ /g, "");
                  if (teachr && teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity["@id"];
                  }
                  if (teachr && teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE["@id"];
                  }
                  if (teachr && teachr.proofSignSap) {
                    teachr.proofSignSap = teachr.proofSignSap["@id"];
                  }
                  if (teachr && teachr.proofMandat) {
                    teachr.proofMandat = teachr.proofMandat["@id"];
                  }
                  TeachrRepository.updateTeachr(teachr).catch(() => {
                    return Swal.showValidationMessage(
                      "Erreur : Quelque chose s'est mal passé"
                    );
                  });
                } else {
                  errorsTag[0].innerHTML = ValidateIban(iban).message;
                }
              },
            }).then(
              (result) =>
                result.isConfirmed && navigate("/teachr/dashboard/declare")
            );
            MySwal.getPopup()?.classList.add("shadow-candidature-close");
            MySwal.getPopup()?.classList.add("w-[698px]");
            MySwal.getPopup()?.classList.add("rounded-[20px]");
          } else {
            navigate("/teachr/dashboard/declare");
          }
        } else if (teachr && teachr.iban == null) {
          MySwal.fire({
            showConfirmButton: false,
            html: (
              <div>
                <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                  <p>Renseignements supplémentaires</p>
                  <img
                    onClick={() => Swal.close()}
                    className="cursor-pointer"
                    src={closeCircle}
                    alt=""
                  />
                </div>
                <div className="py-8 text-left ">
                  <p className="text-sm text-normal">
                    Pour déclarer vos cours, merci de remplir les informations
                    suivantes afin de pouvoir effectuer les démarches de
                    déclaration auprès de l’URSSAF. Une fois complétées, vous
                    pourrez déclarer à autant de cours que vous souhaitez !
                  </p>
                  <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                    <div>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Adresse postale</p>
                        <AddressComponent getPlaceId={getPlaceId} />
                      </div>
                    </div>
                    <div className={teachr.iban ? "hidden" : ""}>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Iban</p>
                        <input
                          id="iban-input"
                          type="text"
                          className="block w-full bg-transparent border-none focus:outline-none"
                          onChange={(e) =>
                            (iban = e.target.value.replace(/ /g, ""))
                          }
                          placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-4 lg:pt-8">
                    <Button
                      onClick={() => Swal.clickConfirm()}
                      className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            ),
            preConfirm: () => {
              const errorsTag = document.getElementsByClassName("errors");
              let errAddress = true;
              let errIban = true;
              if (defaultAddress) {
                errAddress = false;
                errorsTag[0].innerHTML = "";
              } else {
                errAddress = true;
                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
              }
              if (ValidateIban(iban).isValid) {
                errorsTag[1].innerHTML = ValidateIban(iban).message;
                errIban = false;
              } else {
                errorsTag[1].innerHTML = ValidateIban(iban).message;
                errIban = true;
              }
              if (!errAddress && !errIban) {
                if (!createAddress()) {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                } else {
                  teachr.iban = iban.replace(/ /g, "");
                  if (teachr && teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity["@id"];
                  }
                  if (teachr && teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE["@id"];
                  }
                  if (teachr && teachr.proofSignSap) {
                    teachr.proofSignSap = teachr.proofSignSap["@id"];
                  }
                  if (teachr && teachr.proofMandat) {
                    teachr.proofMandat = teachr.proofMandat["@id"];
                  }
                  TeachrRepository.updateTeachr(teachr).catch(() => {
                    return Swal.showValidationMessage(
                      "Erreur : Quelque chose s'est mal passé"
                    );
                  });
                }
              } else {
              }
            },
          }).then(
            (result) =>
              result.isConfirmed && navigate("/teachr/dashboard/declare")
          );
          MySwal.getPopup()?.classList.add("shadow-candidature-close");
          MySwal.getPopup()?.classList.add("w-[698px]");
          MySwal.getPopup()?.classList.add("rounded-[20px]");
        } else {
          MySwal.fire({
            showConfirmButton: false,
            html: (
              <div>
                <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                  <p>Renseignements supplémentaires</p>
                  <img
                    onClick={() => Swal.close()}
                    className="cursor-pointer"
                    src={closeCircle}
                    alt=""
                  />
                </div>
                <div className="py-8 text-left ">
                  <p className="text-sm text-normal">
                    Pour déclarer vos cours, merci de remplir les informations
                    suivantes afin de pouvoir effectuer les démarches de
                    déclaration auprès de l’URSSAF. Une fois complétées, vous
                    pourrez déclarer à autant de cours que vous souhaitez !
                  </p>
                  <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                    <div>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Adresse postale</p>
                        <AddressComponent getPlaceId={getPlaceId} />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-4 lg:pt-8">
                    <Button
                      onClick={() => Swal.clickConfirm()}
                      className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            ),
            preConfirm: () => {
              const errorsTag = document.getElementsByClassName("errors");
              if (defaultAddress) {
                errorsTag[0].innerHTML = "";
                if (!createAddress()) {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                }
              } else {
                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
              }
            },
          }).then(
            (result) =>
              result.isConfirmed && navigate("/teachr/dashboard/declare")
          );
          MySwal.getPopup()?.classList.add("shadow-candidature-close");
          MySwal.getPopup()?.classList.add("w-[698px]");
          MySwal.getPopup()?.classList.add("rounded-[20px]");
        }
        break;

      default:
        if (teachr?.isBornFrance === null && teachr?.NNI === null) {
          MySwal.fire({
            showConfirmButton: false,
            html: (
              <div>
                <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                  <p>Renseignements supplémentaires</p>
                  <img
                    onClick={() => Swal.close()}
                    className="cursor-pointer"
                    src={closeCircle}
                    alt=""
                  />
                </div>
                <div className="py-8">
                  <p className="w-full text-sm font-bold text-third">
                    NB : Ces informations servent à ce que vos cours soient
                    déclarés par nos soins. Nous ne sommes PAS en train de vous
                    créer votre statut auto-entrepreneur.{" "}
                  </p>
                </div>
                <div className="py-8 text-left ">
                  <p className="text-sm text-normal">
                    Pour déclarer vos cours, merci de remplir les informations
                    suivantes afin de pouvoir effectuer les démarches de
                    déclaration auprès de l’URSSAF. Une fois complétées, vous
                    pourrez déclarer à autant de cours que vous souhaitez !
                  </p>
                  <p className="py-1 text-sm text-normal">
                    PS : si vous avez le statut auto-entrepreneur, cette étape
                    n’est pas nécessaire.
                    <a
                      href="https://drive.google.com/drive/folders/1ua_E5nxAJQzB8NIE4E-4fI6hkwD_SS0e?usp=sharing"
                      className="ml-1 underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Cliquez ici
                    </a>{" "}
                    pour en savoir plus et vous créer le statut 😉
                  </p>
                  <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                    <FormControl>
                      <div className="flex items-center justify-between">
                        <label className="text-sm font-extrabold text-first lg:text-base">
                          Êtes-vous né(e) en France ?{" "}
                        </label>
                        <RadioGroup
                          row
                          defaultValue="1"
                          name="customized-radios"
                        >
                          <FormControlLabel
                            value="1"
                            onClick={() => (isBornFrance = true)}
                            control={
                              <RadioButton
                                border="1px solid #0054A3"
                                bg="#0054A3"
                              />
                            }
                            label="Oui"
                          />
                          <FormControlLabel
                            value="0"
                            onClick={() => (isBornFrance = false)}
                            control={
                              <RadioButton
                                border="1px solid #0054A3"
                                bg="#0054A3"
                              />
                            }
                            label="Non"
                          />
                        </RadioGroup>
                      </div>
                    </FormControl>
                    <div className={teachr.address ? "hidden" : ""}>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Adresse postale</p>
                        <AddressComponent getPlaceId={getPlaceId} />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-red-600 errors"></p>
                      <Input
                        callback={getPostal}
                        label="Code postal de naissance"
                        inset="true"
                        placeholder="Ex : 75013"
                        customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                      />
                    </div>
                    <div>
                      <p className="text-sm text-red-600 errors"></p>
                      <Input
                        callback={getNni}
                        label="Numéro de sécurité sociale"
                        inset="true"
                        placeholder="Ex : 1 97 12 83 232 428 48"
                        customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                      />
                    </div>
                    <div className={teachr.iban ? "hidden" : ""}>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Iban</p>
                        <input
                          id="iban-input"
                          type="text"
                          className="block w-full bg-transparent border-none focus:outline-none "
                          onChange={(e) =>
                            (iban = e.target.value.replace(/ /g, ""))
                          }
                          placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                        />
                      </div>
                    </div>
                    <div>
                      <Input
                        callback={getSecondName}
                        label="Deuxième prénom (Facultatif)"
                        inset="true"
                        placeholder="Ex : Hélène"
                        customClass="bg-bg-input-light p-2 text-sm text-normal font-bold"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end pt-4 lg:pt-8">
                    <Button
                      onClick={() => Swal.clickConfirm()}
                      className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            ),
            preConfirm: () => {
              if (validate()) {
                if (!teachr.address) {
                  if (createAddress()) {
                    if (!updateTeachr()) {
                      return Swal.showValidationMessage(
                        "Erreur : Quelque chose s'est mal passé"
                      );
                    }
                  }
                } else {
                  if (!updateTeachr()) {
                    return Swal.showValidationMessage(
                      "Erreur : Quelque chose s'est mal passé"
                    );
                  }
                }
              } else {
              }
            },
          }).then(
            (result) =>
              result.isConfirmed && navigate("/teachr/dashboard/declare")
          );
          MySwal.getPopup()?.classList.add("shadow-candidature-close");
          MySwal.getPopup()?.classList.add("w-[698px]");
          MySwal.getPopup()?.classList.add("rounded-[20px]");
        } else if (teachr?.address) {
          navigate("/teachr/dashboard/declare");
        } else {
          MySwal.fire({
            showConfirmButton: false,
            html: (
              <div>
                <div className="flex items-center justify-between text-xl font-extrabold uppercase text-first">
                  <p>Renseignements supplémentaires</p>
                  <img
                    onClick={() => Swal.close()}
                    className="cursor-pointer"
                    src={closeCircle}
                    alt=""
                  />
                </div>
                <div className="py-8">
                  <p className="w-full text-sm font-bold text-third">
                    NB : Ces informations servent à ce que vos cours soient
                    déclarés par nos soins. Nous ne sommes PAS en train de vous
                    créer votre statut auto-entrepreneur.{" "}
                  </p>
                </div>
                <div className="py-8 text-left ">
                  <p className="text-sm text-normal">
                    Pour déclarer vos cours, merci de remplir les informations
                    suivantes afin de pouvoir effectuer les démarches de
                    déclaration auprès de l’URSSAF. Une fois complétées, vous
                    pourrez déclarer à autant de cours que vous souhaitez !
                  </p>

                  <div className="flex flex-col w-full gap-4 pt-4 lg:w-2/3">
                    <div>
                      <p className="text-sm text-red-600 errors"></p>
                      <div className="p-2 text-sm font-bold rounded-lg bg-bg-input-light text-normal">
                        <p className="mx-2">Adresse postale</p>
                        <AddressComponent getPlaceId={getPlaceId} />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end pt-4 lg:pt-8">
                    <Button
                      onClick={() => Swal.clickConfirm()}
                      className="block bg-transparent rounded-full lg:px-14 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max"
                    >
                      Valider
                    </Button>
                  </div>
                </div>
              </div>
            ),
            preConfirm: () => {
              const errorsTag = document.getElementsByClassName("errors");
              if (defaultAddress) {
                errorsTag[0].innerHTML = "";
                if (!createAddress()) {
                  return Swal.showValidationMessage(
                    "Erreur : Quelque chose s'est mal passé"
                  );
                }
              } else {
                errorsTag[0].innerHTML = "Veuillez entrer une adresse valide";
              }
            },
          }).then(
            (result) =>
              result.isConfirmed && navigate("/teachr/dashboard/declare")
          );
          MySwal.getPopup()?.classList.add("shadow-candidature-close");
          MySwal.getPopup()?.classList.add("w-[698px]");
          MySwal.getPopup()?.classList.add("rounded-[20px]");
        }
        break;
    }
  };
  const handleDeclareCourse = () => {
    const showInfo = new TeachrShowInfo(true);
    if (teachr?.id) {
      Teachr_Repository.teachrShowInfo(teachr?.id, showInfo).then((res) => {
        setLoad(!load);
        acceptCourse();
        handleClose();
      });
    }
  };
  const [opens, setOpens] = React.useState(false);
  const handleOpen = () => {
    setOpens(true);
  };
  const handleCloses = () => {
    setOpens(false);
  };
  const handleNavigate = () => {
    if (teachr?.iban) {
      navigate("/teachr/dashboard/declare");
    } else {
      // handleClose();
      handleOpen();
    }
  };
  const displayButtons = () => {
    if (teachr) {
      return (
        <div className="md:flex md:gap-x-8 space-y-2 md:space-y-0">
          <button className="md:py-2 border-b-2 border-first rounded-lg gap-x-2 items-center flex">
            <img
              src={add}
              alt=""
              className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold"
            />
            {AddStudent}
          </button>
          {!teachr?.isShowInfos ? (
            <>
              <button className="border-b-2 border-first rounded-lg flex gap-x-1 items-center xl:text-xl lg:text-lg md:text-base text-sm lg:font-extrabold font-semibold text-first" onClick={() => handleClickOpen()}>
                <img src={add} alt="Déclarer un cours" className="md:w-8 w-5 font-extrabold" />
                <p>Déclarer un cours 📚</p>
              </button>
              <PopUpInfo
                handleDeclareCourse={handleDeclareCourse}
                navigate={navigate}
                teachr={teachr}
                handleClose={handleClose}
                open={open}
              />
            </>
          ) : (
            <button className="border-b-2 border-first rounded-lg flex gap-x-1 items-center xl:text-xl lg:text-lg md:text-base text-sm font-extrabold text-first" onClick={() => handleNavigate()}>
              <img src={add} alt="Déclarer un cours" className="md:w-8 w-5 font-extrabold" />
              <p>Déclarer un cours 📚</p>
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className=" h-[10%] md:flex gap-x-8">
          <Skeleton animation="wave" height={30} width="200px" />
          <Skeleton animation="wave" height={30} width="200px" />
        </div>
      );
    }
  };

  return (
    <div
      className="h-[100%]  py-4 md:px-10 px-4 bg-bottom bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${simulateurBg})` }}
    >
      <ValidIban
        handleOpen={handleOpen}
        handleCloses={handleCloses}
        opens={opens}
        teachr={teachr}
      />
      <div className="py-4">
        {displayButtons()}
      </div>
      <DashboardLayout clientTiersPrest={clientTiersPrest} course={course} />
    </div>
  );
};

export default Layout;
