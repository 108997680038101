import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import monabankLogo from '../../Assets/Images/monabank.png'
import monabankCreditCard from '../../Assets/Images/monabanq-credit-card-2x.png'
import ArrowRightMonabank from '../../Assets/Icons/arrow-right-monabanq.svg'
import Teachr from '../../Objects/models/Teachr';
import TeachrRepository from '../../Repositories/TeachrRepository';
const Header = () => {
    const [teachr, setTeachr] = useState<Teachr>()

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response: Teachr) => {
            setTeachr(response)
        })
    }, [])

    const handleClick = () => {
        const teachrId = teachr?.id; 
        const firstName = teachr?.firstName; 
        const lastName = teachr?.lastName; 

        const baseUrl = "https://clk.tradedoubler.com/click?p=200547&a=3324191&g=23407414";
        const finalUrl = `${baseUrl}&teachrId=${teachrId}&firstName=${firstName}&lastName=${lastName}`;

        window.open(finalUrl, '_blank');
    }
    return (
        <section className='py-8 font-sans lg:py-10'>
            <div className="mx-auto lg:w-[90%] ">
                <div className='mx-auto w-max'>
                    <img className='w-[123px] h-[34px] lg:w-[265px] lg:h-[72px]' src={monabankLogo} alt="" />
                </div>
                <div className='flex flex-col items-end justify-between py-8 lg:flex-row'>
                    <div className='flex flex-col lg:w-1/2 gap-y-8'>
                        <div className='flex lg:block gap-x-2'>
                            <h1 className='lg:text-[32px] font-sans font-bold uppercase text-[20px] px-4 md:px-8 lg:px-0'>Jusqu'à <span className='text-[#BAD606] font-sans lg:text-[45px] text-[20px]'>120€</span> offerts pour l'ouverture d'un premier compte : <span className='capitalize'>Pratiq+, Uniq ou Uniq+</span> <sup className="text-sm">(1)</sup> </h1>
                            <img className='w-[106px] h-[79px] lg:hidden' src={monabankCreditCard} alt="" />
                        </div>
                        <div className='lg:hidden'>
                            <p className='font-sans font-bold lg:text-xl text-[11px] px-4 md:px-8 lg:px-0 text-[#424242] uppercase'>À partir du 1er septembre 2023</p>
                        </div>
                        <div className='flex flex-col gap-y-4 bg-[#F3F3F3] py-4 lg:py-0 lg:bg-transparent'>
                            <div className='flex items-center px-4 font-bold gap-x-8 md:px-8 lg:px-0'>
                                <span className="text-[#BAD606] lg:text-[40px] text-2xl after:content-['+'] after:text-[#424242] after:text-center after:absolute after:top-[25px] lg:after:top-[32px] after:left-1/3 relative font-sans">80€</span>
                                <p className='lg:text-[20px] text-[12px] font-sans'>Jusqu’à 80€ offerts pour toute ouverture de 1er compte (avec ou sans carte visa) (1)</p>
                            </div>
                            <div className='flex items-center px-4 font-bold gap-x-8 md:px-8 lg:px-0'>
                                <span className="text-[#BAD606] lg:text-[40px] text-2xl //after:content-['+'] after:text-[#424242] after:text-center after:absolute after:top-[25px] lg:after:top-[32px] after:left-1/3 relative font-sans">40€</span>
                                <p className='lg:text-[20px] text-[12px] font-sans'>En plus pour toute souscription à un contrat porteur carte Visa Premier ou Platinum et après activation de celle-ci (1)</p>
                            </div>
                            {/* <div className='flex items-center px-4 font-bold gap-x-8 md:px-8 lg:px-0'>
                                <span className="text-[#BAD606] lg:text-[40px] text-2xl font-sans">80€</span>
                                <p className='lg:text-[20px] text-[12px] font-sans'>En plus pour toute utilisation du service de domiciliation bancaire à l’ouverture de votre premier compte Monabanq (1)</p>
                            </div> */}
                        </div>
                        <div className='hidden lg:block'>
                            <p className='font-sans font-bold lg:text-xl text-[11px] px-4 md:px-8 lg:px-0'>À partir du 1er septembre 2023</p>
                        </div>
                    </div>
                    <div className='flex flex-col w-full pt-8 lg:pt-0 lg:w-max gap-y-4'>
                        <div className="hidden lg:block">
                            <img className='w-[416px] h-[330px]' src={monabankCreditCard} alt="" />
                        </div>
                        <a target="_blank" onClick={handleClick} className='rounded-full bg-[#424242] text-white font-bold text-xl px-8 shadow-dashboard-setup-input py-2 font-sans flex items-center gap-x-4 w-max mx-auto lg:mx-0'>J’EN PROFITE <img src={ArrowRightMonabank} alt="" /></a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Header;