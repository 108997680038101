import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'

type AcceptedFileGroup = "image" | "application/pdf";

function UploadButton(props: any) {
    const [fileGroup, setFileGroup] = useState<AcceptedFileGroup>("image");
    const [file, setFile] = useState<File>();
    const getFileType = (file: File): AcceptedFileGroup => {
        if (file.type.indexOf("application/pdf") !== -1) {
            return "application/pdf";
        } else if (file.type.indexOf("image") !== -1) {
            return "image";
        }
        props.getFile(null, true)
        throw new Error("Mauvais format de fichier");
    };

    const prepareFileUpload = (file: File) => {
        setFileGroup(getFileType(file));
        setFile(file);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.currentTarget;
        if (!files || !files[0]) {

            throw new Error("Veuillez sélectionner un fichier");
        }
        prepareFileUpload(files[0]);
    };

    const validateMedia = (fileGroup: AcceptedFileGroup, file: File) => {
        if (props.fileType) {
            if (props.fileType === "pdf") {
                if (fileGroup === "image" || fileGroup === "application/pdf") {
                    props.getFile(file, false)
                }else {
                    props.getFile(null, true)
                }
            }
        } else {
            if (fileGroup === "image") {
                props.getFile(file, false)
            } else {
                props.getFile(null, true)
            }
        }

    };

    useEffect(() => {
        if (file) validateMedia(fileGroup, file);
    }, [file, fileGroup]);

    return (
        <div>
            {props.type === 'button' ?
                <Button
                    variant="contained"
                    component="label"
                    className="w-full px-4 py-2 font-extrabold text-white uppercase rounded-full font-nunito bg-second"
                >
                    CHOISIR UNE PHOTO
                    <input hidden accept="image/*" onChange={onChange} type="file" />
                </Button> :
                <div>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                        <input hidden accept="application/pdf, image/*" type="file" onChange={onChange} />
                        {props.button ?
                            props.button :
                            <AddCircleIcon sx={{ color: props.icon, fontSize: '35px' }} />
                        }
                    </IconButton>
                </div>}
        </div>
    )
}

export default UploadButton