import React, { useEffect, useState } from 'react';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControlLabel, RadioGroup } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RadioButton from '../../../../Components/utils/RadioButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Teachr from '../../../../Objects/models/Teachr';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Functions from '../../../../Helpers/Functions';
const First = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selected, setSelected] = useState<number>()
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [apeCode, setApeCode] = useState<number>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [datas, setDatas] = useState<string[]>()
    const generalTeachr = useSelector((state: any) => state.teachr)
    useEffect(() => {
        const data = []
        data.push('JE NE POSSÈDE PAS DE SOCIÉTÉ')
        Functions.businessTypeList.map((el) => {
            data.push(el.label)
        })
        // data.push('JE NE POSSÈDE PAS DE SOCIÉTÉ')
        setDatas(data)
    }, [])
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setApeCode(parseInt((event.target as HTMLInputElement).value));
    };
    useEffect(() => {
        if (apeCode !== undefined && apeCode === 0) {
            props.nextStep(1)
        }
    }, [apeCode])
    useEffect(() => {
        if (generalTeachr.teachr && datas) {
            if (generalTeachr.teachr.businessType && generalTeachr.teachr.businessType !== "") {
                const index = datas.indexOf(Functions.renderBusinessType(generalTeachr.teachr.businessType!))
                if (index !== -1) {
                    setSelected(index)
                }
            }
            setTeachr(generalTeachr.teachr)
            if (generalTeachr.teachr.signSap) {
                navigate("/teachr/cours")
            } else {
                if (generalTeachr.teachr.proofSignSap && generalTeachr.teachr.proofMandat) {
                    navigate("/teachr/cours")
                }
            }
        }
    }, [generalTeachr, datas])
    const handleSubmit = () => {
        if (selected !== undefined && datas && apeCode !== undefined && apeCode === 1 && selected > 0 && teachr) {
            setLoading(true)
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat['@id']
            }
            teachr.businessType = Functions.businessTypeList[selected-1].value
            
            
            TeachrRepository.updateTeachr(teachr).then((teachrResponse) => {
                dispatch({
                    type: 'SET_TEACHR_INFO',
                    payload: {
                        teachr: teachrResponse
                    }
                })
                props.nextStep(2)
                setLoading(false)
            }).catch(() => setLoading(false))
        }
    }
    const renderApeCodeScreen = () => {
        if (!open && selected !== undefined && datas) {
            if (selected > 0) {
                return (
                    <div className='flex flex-col gap-y-4'>
                        <h4 className='text-base font-bold text-first lg:text-xl'>Disposez-vous du bon code APE ? </h4>
                        <p className='text-sm font-semibold text-normal lg:text-lg'>Afin que profil soit validé, Veuillez vérifier que vous avez le bon code APE : <span className='font-extrabold'>8559B ou 8552Z</span>. Sans celui-ci, les démarches administratives concernant l'obtention du crédit d'impôt instantané pourraient vous être refusées.</p>
                        <RadioGroup row
                            value={apeCode}
                            name="customized-radios"
                            onChange={handleChange}
                        >
                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />

                        </RadioGroup>
                        <div className="flex justify-between flex-col lg:flex-row gap-y-4">
                            <Button disabled={loading} className='text-[#B7B7B7] lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 disabled:opacity-30 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
                            <Button onClick={handleSubmit} disabled={loading ? true : !apeCode} className='px-8 py-2 font-extrabold text-white rounded-full bg-radio disabled:opacity-30 w-full lg:w-max'>SUIVANT </Button>

                        </div>
                    </div>
                )
            } else if (selected === 0) {
                return (
                    <div className='flex flex-col gap-y-4'>
                        <p className='text-base font-semibold text-normal lg:text-lg'>Nous vous invitons à vous créer le statut auto-entrepreneur pour pouvoir bénéficier de tous les avantages de notre plateforme. </p>
                        <p className='text-base font-semibold text-normal lg:text-lg'>Si vous souhaitez faire les démarches par vous même vous pouvez suivre notre <span className='font-bold'>guide</span> en <a href="" className='font-bold underline text-radio'>cliquant ici.</a> <br />
                            Sinon vous pouvez nous <span className='font-extrabold '>envoyer une procuration</span> afin que nous puissions nous en occuper à vote place. </p>
                        <div className="flex justify-between flex-col lg:flex-row gap-y-4">
                            <Button onClick={()=> setSelected(undefined)} className='text-[#B7B7B7]  lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
                            <Button className='px-8 py-2 font-extrabold text-white rounded-full bg-radio  w-full lg:w-max' onClick={() => navigate('/teachr/procuration/ae')}>PROCURATION</Button>
                        </div>
                    </div>
                )
            }


        } else {
            return
        }
    }
    const renderStatus = () => {
        if (datas) {
            return datas.map((el, index) => {
                return <li onClick={() => { setSelected(index); setOpen(false) }} className='w-full py-2 px-4 hover:bg-[rgba(33,156,255,0.07)] rounded-xl rounded-b-none cursor-default'>{el}</li>
            })
        }
        return null
    }
    return (
        <div className='flex flex-col lg:border border-[rgba(146,211,255,0.54)] lg:shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-4'>
            <h4 className='text-base font-bold lg:text-xl text-first'>1. Vous possédez un statut juridique : </h4>
            <div>
                <Accordion expanded={open} className='flex flex-col shadow-none gap-y-8'>
                    <AccordionSummary onClick={() => setOpen(!open)} className={`${selected !== undefined ? 'border-first bg-[rgba(33,156,255,0.07)] text-first' : 'border-[rgba(183,183,183,0.64)]'} border border-solid rounded-xl`}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        sx={{
                            '& .MuiAccordionSummary-content': {
                                margin: 0
                            }
                        }}
                    >
                        <p>{datas && selected !== undefined ? datas[selected] : 'Choisissez parmi la liste déroulante'}</p>
                    </AccordionSummary>
                    <AccordionDetails className='border border-[rgba(183,183,183,0.64)] border-solid rounded-xl p-0'>
                        <ul className='m-0'>
                            {renderStatus()}
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
            {renderApeCodeScreen()}

        </div>
    );
};

export default First;