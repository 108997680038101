import React, { useState } from "react";
import SubscriptionCourseCard from "./CourseDemandCard/SubscriptionCourseCard";
import PonctualCourseCard from "./CourseDemandCard/PonctualCourseCard";
import CoursePackCard from "./CourseDemandCard/CoursePackCard";
import ClientRepository from "../../../Repositories/ClientRepository";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import { useEffect } from "react";
import { CoursePack } from "../../../Objects/models/CoursePack";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import NoLessonRequest from "./Components/NoLessonRequest";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from "../../../Services/FirebaseService";
import gif from "../../../Assets/Icons/load.gif";
import InTheCourseInTheHour from "./CourseDemandCard/InCourseInTheHour";
const ResponseCourse = () => {
  // const [user] = useAuthState(auth);
  const [regularCourse, setRegularCourse] = useState<SubscriptionCourse[]>();
  const [coursePack, setCoursePack] = useState<any>();
  const [ponctualCourse, setPonctualCourse] = useState<PunctualCourse[]>();
  const [loading, setLoading] = useState(false);


  

  useEffect(() => {
    // if (user) {
      (async () => {
        const client = await ClientRepository.getCurrentClient();
        if (client.id) {
          const subscriptionCourse =
            await SubscriptionCourseRepository.getPendingSubscriptionCourse(
              client.id
            );
          setRegularCourse(subscriptionCourse);
          const coursePacks: any =
            await CoursePackRepository.getPendingCoursePacks(client.id);
          setCoursePack(coursePacks.members);
          const ponctual =
            await PunctualCourseRepository.getPendingPunctualCourse(client.id);
          setPonctualCourse(ponctual);
        }
        setLoading(true);
      })();
    // }
  }, []);

  

  return (
    <>
      { 
        ponctualCourse?.length === 0 &&
        regularCourse?.length === 0 &&
        coursePack?.length === 0 ? (
          <NoLessonRequest />
        ) : (
          <div>
            {loading ? (
              <div>
                <div className="block font-nunito">
                  <div className="font-nunito md:mt-4">
                    {ponctualCourse?.sort((a:any, b: any) => {
                     return b.creationDatetime - a.creationDatetime
                    }).map((course: PunctualCourse, key) => (
                      <InTheCourseInTheHour {...course} key={key} />
                    ))
                    }
                    {ponctualCourse?.sort((a:any, b: any) => {
                     return b.creationDatetime - a.creationDatetime
                    }).map((course: PunctualCourse, key) => (
                      <PonctualCourseCard {...course} key={key} />
                    ))
                    }
                    {regularCourse?.sort((a:any, b: any) => {
                     return b.creationDatetime - a.creationDatetime
                    }).map(
                      (course: SubscriptionCourse, key: any) => (
                        <SubscriptionCourseCard {...course} key={key} />
                      )
                    )}
                    {coursePack?.sort((a:any, b: any) => {
                     return b.creationDatetime - a.creationDatetime
                    }).map((course: CoursePack, key: any) => (
                      <CoursePackCard {...course} key={key} />
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div className="mx-auto my-auto mt-40">
                <img src={gif} alt="gif" className="mx-auto " />
              </div>
            )}
          </div>
        )
      }
    </>
  );
};

export default ResponseCourse;
