import validateSelects from "../../../Validations/MultiStep";
import { useEffect, useState } from "react";
import StepperStore from "../../../LocalForage/StepperStore";
import { Subject } from "../../../Objects/models/Subject";
import SubjectsSelects from "../../utils/SelectMatiere";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../Services/FirebaseService";
import question from "../../../Assets/Images/question (3).png";
import fontMatiere from "../../../Assets/Images/formMatiere.svg";

const FormMatiere = (props: { handleNext: any }) => {
  const { handleNext } = props;
  const [user] = useAuthState(auth);
  const [matiere, setMatiere] = useState<Subject[]>();
  const [matieres, setMatieres] = useState<Subject[]>();
  const [error, setError] = useState({
    matiere: "null",
  });

  const navigate = useNavigate();
  const getMatiere = (value: Subject[]) => { 
    setMatiere(value);
  };
  const validate = () => {
    let errMatiere = true;
    if (!validateSelects(matiere).isValid) {
      setError({ matiere: validateSelects(matiere).message });
      errMatiere = true;
    } else {
      setError({ matiere: validateSelects(matiere).message });
      errMatiere = false;
    }
    if (errMatiere) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem(
        user ? "formMatiere" + user?.uid : "formMatiere"
      );
      if (value !== null) {
        getMatiere(value.matiere);
        setMatieres(value.matiere);
      }
    })()
  }, [user]);
  const handleSubmit = (event: any) => {
    if (validate()) {
      StepperStore.setItem(
        user ? "formMatiere" + user?.uid : "formMatiere",
        {
          matiere: matiere,
        },
        function () {}
      );
      handleNext();
    }
    event.preventDefault();
  };
  return (
    <div className=" mb-10 space-y-2 //overflow-hidden  lg:space-y-6 md:space-y-4 lg:translate-y-10 translate-y-32 md:translate-y-20">
      <div className="w-4/5 mx-auto ">
        <h5 className="text-base font-bold lg:text-3xl md:text-2xl text-dark_blue">
          Que souhaitez-vous apprendre ?
        </h5>
      </div>
      <div className=" bg-input-color relative rounded-lg w-4/5 mx-auto md:translate-y-5 translate-y-2 shadow-md shadow-[rgba(0,0,0,25%)]">
        <SubjectsSelects
          callBack={getMatiere}
          validation={validateSelects}
          value={matiere}
          placeholder="Ex : Mathématiques"
          matieres={matieres}
        />
      </div>
      <div className="flex w-4/5 mx-auto space-x-2 text-lg md:translate-y-4 translate-y-2 ">
        {error.matiere === "null" || error.matiere === ""
          ? " "
          : validateSelects(matiere).icon}
        <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
          {error.matiere === "null" || error.matiere === ""
            ? null
            : validateSelects(matiere).message}
        </p>
      </div>
      <div className="w-4/5 mx-auto xl:translate-y-32 md:translate-y-24 translate-y-2 ">
        <div className="flex w-   mx-auto space-x-2  md:space-x-4 ">
          <div>
            <img
              src={question}
              alt=""
              className="mt-[2px] md:mt-0 md:w-8 w-5"
            />
          </div>
          <div className="">
            <p className="text-base font-bold lg:text-3xl md:text-2xl text-dark_blue ">
              Tarifs
            </p>
          </div>
        </div>
        <p className="md:mt-4 text-[14px]  lg:text-lg md:test-base //md:-mt-4">
          Le prix d'une heure de cours varie d'un professeur à l'autre. En
          moyenne, il faut compter 15€ de l'heure après crédit d'impôt pour une
          heure de cours à domicile.
        </p>
      </div>
      <div>
        <div className="md:hidden -translate-y-6    w-[150%]  mx-auto ">
          <img src={fontMatiere} alt="" className="-translate-x-28 h-[150%] " />
        </div>
        <div className="md:hidden //-translate-y-6 flex justify-around mx-auto space-x-4 w-4/5 -translate-y-36 -mt-20 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
            <div
              onClick={() => navigate(-1)}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
      </div>

      <div className="md:flex  hidden justify-around mx-auto //-translate-y-48 xl:translate-y-44 md:translate-y-32  pb-6 md:w-full">
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent
          </button>
        </div>
        <div className="flex justify-center ">
          <button
            type="button"
            onClick={(e) => handleSubmit(e)}
            className={
              // error.matiere === "null"
              //   ?
              " inline-block md:px-4 md:py-2 py-2 px-3 border-2 font-bold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
              // : error.matiere
              // ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-semibold hover:   border-grey text-grey  leading-tight uppercase rounded-full hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
              // : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-semibold  text-white  leading-tight uppercase rounded-full hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
            }
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormMatiere;
