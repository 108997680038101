import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Input from '../../../Components/Admin/Input';
import SelectComponent from '../../../Components/Admin/Select';
import Functions from '../../../Helpers/Functions';
import { Address } from '../../../Objects/models/Address';
import Teachr from '../../../Objects/models/Teachr';
import TeachrContact from '../../../Objects/models/TeachrContact';
import TeachrContactRepository from '../../../Repositories/TeachrContactRepository';
import validateBirthName from '../../../Validation/BirthName';
import { validatePhoneNumber } from '../../../Validation/Phone';
import validateEmail from '../../../Validations/Email';
import validateFirstName from '../../../Validations/FirstName';
import validateName from '../../../Validations/Name';
import Layout from './Layout';
import ClientRepository from '../../../Repositories/ClientRepository';
import Client from '../../../Objects/models/Client';

const First = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()
    const [teachrContact, setTeachrContact] = useState<TeachrContact>()
    const [teachr, setTeachr] = useState<Teachr>()
    const [client, setClient] = useState<Client>()
    const [address, setAddress] = useState<Address>()
    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [studentName, setStudentFirstName] = useState('')
    const [level, setLevel] = useState<number>()
    const [errors, setErorrs] = useState({
        studentFirstName: false,
        // level: false,
        firstName: false,
        lastName: false,
        mail: false,
        telephone: false

    })
    useEffect(() => {
        if (params.key) {
            TeachrContactRepository.fetchTeachrContactByKey(params.key).then((response) => {
                if (response) {
                    setLevel(response.level)
                    setTeachrContact(response)
                    setTeachr(response.teachr)
                    if (response.address) {
                        setAddress(response.address)
                    }
                }
            })
                .catch(() => navigate('/notfound'))
        } else {
            navigate('/notfound')
        }
    }, [])

    useEffect(() => {
        ClientRepository.getCurrentClient().then((client:Client) => {
            setClient(client);
            // client.
        })
    }, [])

    const nextStep = () => {
        if (validation() && teachrContact) {
            teachrContact.firstName = firstName.trim()
            teachrContact.name = lastName.trim()
            teachrContact.mail = email
            teachrContact.phoneNumber = phone
            teachrContact.studentName = studentName.trim()
            teachrContact.level = level
            teachrContact.step = 1
            teachrContact.teachr = teachr ? teachr['@id']! : null
            if (address) {
                teachrContact.address = address['@id']!
            }
            if (teachrContact.level) {
                setLevel(teachrContact.level)
            }
            TeachrContactRepository.updateTeachrContact(teachrContact).then((response) => {
                if (response) {
                    props.nextStep(1)
                }
            })
        }

    }
    const getStudentName = (value: string) => {
        setStudentFirstName(value)
    }
    const getLevel = (value: any) => {
        if (typeof(value) === 'string') {
            setLevel(parseInt(value))
        }else if (typeof(value) === 'number'){
            setLevel(value)
        }
    }
    const getFirstName = (value: string) => {
        setFirstName(value)
    }

    const getLastName = (value: string) => {
        setLastName(value)
    }
    const getMail = (value: string) => {
        setEmail(value)
    }
    const getPhone = (value: string) => {
        setPhone(value)
    }
    const validation = (): boolean => {
        let valid = {
            studentFirstName: false,
            // level: false,
            firstName: false,
            lastName: false,
            mail: false,
            telephone: false
        }
        valid.studentFirstName = !validateFirstName(studentName).isValid
        valid.firstName = !validateBirthName(firstName).isValid
        valid.lastName = !validateBirthName(lastName).isValid
        valid.mail = !validateEmail(email).isValid
        if (validatePhoneNumber(phone) === "") {
        valid.telephone = false
        } else {
        valid.telephone = true
        }
        // if (!Number.isNaN(level)) {
        //     valid.level = false
        // }else{
        //     valid.level = true
        // }
        setErorrs(valid)
        
        if (!valid.firstName && !valid.lastName && !valid.mail && !valid.studentFirstName && !valid.telephone
            //  && !valid.level
             ) {
            return true
        } else {
            return  false
        }
    }

    const renderScreen = () => {
        if (teachrContact) {
            dispatch({
                type: 'HIDE_LOADER'
            })
            return (
                <Layout teachr={teachr?.firstName} next={<Button onClick={() => nextStep()} className='w-full py-2 text-lg font-extrabold text-white uppercase rounded-full px-14 bg-first md:w-max'>SUIVANT</Button>}>
                    
                    <h2 className='pt-4 pb-2 text-2xl font-bold text-first'>Vos informations</h2>
                    <div className="flex flex-col gap-y-4">
                        <div className="flex flex-col w-full md:flex-row gap-y-4 gap-x-8 md:items-end">
                            <div className="relative w-full">
                                <p className={`${errors.firstName ? 'block' : 'hidden'} text-third text-base md:text-base`}>Le prénom ne doit pas comporter de chiffres, ni de caractères spéciaux</p>
                                <Input editMode field='firstName' defaultValue={teachrContact.firstName} callBack={getFirstName} label='Prénom (si vous avez plusieurs prénoms, inscrivez-les)' placeholder='Ex: john' />
                            </div>
                            <div className="relative w-full">
                                <p className={`${errors.lastName ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validateBirthName(lastName).message}</p>
                                <Input editMode field='name' label='Nom de famille' defaultValue={teachrContact.name} callBack={getLastName} placeholder='Ex: Dupont' />
                            </div>
                        </div>
                        <div className="flex flex-col w-full gap-x-8 md:flex-row gap-y-4 md:items-end">
                            <div className="relative w-full">
                                <p className={`${errors.mail ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validateEmail(email).message}</p>
                                <Input editMode field='mail' label='E-mail' defaultValue={teachrContact.mail} callBack={getMail} placeholder='julie.dupont@gmail.com' />
                            </div>
                            <div className="relative w-full">
                            <p className={`${errors.telephone ? 'block' : 'hidden'} text-third text-base md:text-base`}>{validatePhoneNumber(phone)}</p>
                                <Input editMode field="phone" defaultValue={teachrContact.phoneNumber} callBack={getPhone} label='Téléphone portable' placeholder='0612345678' />
                            </div>
                        </div>
                    </div>
                    <h2 className='pt-4 pb-2 text-2xl font-bold text-first'>Informations de l’élève</h2>
                    <div className="flex flex-col w-full gap-x-8 md:flex-row gap-y-4 md:items-end">
                        <div className="relative w-full">
                            <p className={`${errors.studentFirstName ? 'block' : 'hidden'} text-third text-base md:text-base`}>{!validateFirstName(studentName).isValid ? "Entrez le prénom de l'élève" : ''}</p>
                            <Input editMode label='Prénom' field="studentName" callBack={getStudentName} defaultValue={teachrContact.studentName} placeholder='Ex: Juliette' />
                        </div>
                        <div className='relative w-full'>
                        {/* <p className={`${errors.level ? 'block' : 'hidden'} text-third text-base md:text-base`}>{"Veuillez choisr un niveau scolaire"}</p>
                            <SelectComponent
                                defaultValue={teachrContact.level}
                                field='level'
                                placeholder='Ex: CE2'
                                callBack={getLevel}
                                label='Niveau scolaire'
                                data={Functions.level}
                            /> */}
                        </div>
                    </div>
                </Layout>
            )
        }
        dispatch({
            type: 'SHOW_DASHBOARD_AICI_LOADER'
        })
        return 
    }
    return (
        <div className='w-full'>{renderScreen()}</div>
    );
};

export default First;