import React, { useEffect, useState } from 'react';
import First from './First';
import { Button } from '@mui/material';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Index = (props: any) => {
    const [step, setStep] = useState(0)
    // useEffect(() => {
    //     setTimeout(autoPlay, 100000)
        //if (step === 4) {
        //    setStep(0)
      //  }
    //}, [step])
    // const autoPlay = () => {
    //    setStep(step + 1)
    // }
    const handleNext  = () => {
        setStep(step + 1)
    }
    const handlePrev  = () => {
        setStep(step - 1)
    }
    const renderScreen = () => {
        switch (step) {
            case 0:
                return <First />
            case 1:
                return <Second />
            case 2:
                return <Third />
            case 3:
                return <Fourth />

            default:
                return <First />
        }
    }
    return (
        <div className=' flex flex-col h-full gap-y-8'>
            <h2 className='text-xl font-extrabold text-first lg:text-3xl'>Devenir auto-entrepreneur, à quoi ça sert ? </h2>
            <div className='flex items-center gap-x-2'>
                <KeyboardArrowLeftIcon className='text-first font-extralight'onClick={handlePrev}/>
                {renderScreen()}
                <KeyboardArrowRightIcon className='text-first font-extralight' onClick={handleNext}/>
            </div>
            <div className=" md:sticky  md:bottom-0 py-4 bg-[#F2F5FB] w-full flex justify-center gap-x-2 left-0">
                <span onClick={()=> setStep(0)} className={` ${step === 0 ? 'bg-radio' : 'bg-[#D9D9D9]'} w-[14px] h-[14px] rounded-full cursor-pointer hover:opacity-30`}></span>
                <span onClick={()=> setStep(1)} className={` ${step === 1 ? 'bg-radio' : 'bg-[#D9D9D9]'} w-[14px] h-[14px] rounded-full cursor-pointer hover:opacity-30`}></span>
                <span onClick={()=> setStep(2)} className={` ${step === 2 ? 'bg-radio' : 'bg-[#D9D9D9]'} w-[14px] h-[14px] rounded-full cursor-pointer hover:opacity-30`}></span>
                <span onClick={()=> setStep(3)} className={` ${step === 3 ? 'bg-radio' : 'bg-[#D9D9D9]'} w-[14px] h-[14px] rounded-full cursor-pointer hover:opacity-30`}></span>
            </div>
        </div>
    );
};

export default Index;