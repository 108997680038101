import ActionInterface from "../../Objects/interfaces/ActionInterface";
import {
    USER_CLIENT_SET_ACCOUNT,
    USER_CLIENT_UNSET_ACCOUNT,
    USER_FIREBASE_MESSAGING_SET_TOKEN,
    USER_FIREBASE_SET_ACCOUNT,
    USER_FIREBASE_UNSET_ACCOUNT,
    USER_TEACHR_SET_ACCOUNT,
    USER_TEACHR_UNSET_ACCOUNT,
} from "../Actions/UserActions";
import UserStateInterface from "../../Objects/interfaces/UserStateInterface";

export const initialState: UserStateInterface = {
    // firebase auth
    firebaseAuth: {
        userCredential: undefined,
        isAuthenticated: false,
        method: undefined,
    },

    // API entities
    currentUser: {
        isClient: false,
        client: undefined,
        isTeachr: false,
        teachr: undefined,
        isAuthenticated: false,
    },

    // firebase messaging
    firebaseMessaging: {
        token: undefined,
    },
};

export default function userReducer(
    state = initialState,
    action: ActionInterface
): UserStateInterface {
    switch (action.type) {
    case USER_FIREBASE_SET_ACCOUNT:
        return {
            ...state,
            firebaseAuth: {
                ...state.firebaseAuth,
                userCredential: action.value.userCredential,
                method: action.value.authMethod,
                isAuthenticated: true,
            },
        };
    case USER_FIREBASE_UNSET_ACCOUNT:
        return { ...state, firebaseAuth: initialState.firebaseAuth };

    case USER_CLIENT_SET_ACCOUNT:
        return {
            ...state,
            currentUser: {
                ...state.currentUser,
                isClient: true,
                client: action.value,
                isTeachr: false,
                teachr: undefined,
                isAuthenticated: true,
            },
        };
    case USER_CLIENT_UNSET_ACCOUNT:
        return { ...state, currentUser: initialState.currentUser };

    case USER_TEACHR_SET_ACCOUNT:
        return {
            ...state,
            currentUser: {
                ...state.currentUser,
                isTeachr: true,
                teachr: action.value,
                isClient: false,
                client: undefined,
                isAuthenticated: true,
            },
        };
    case USER_TEACHR_UNSET_ACCOUNT:
        return { ...state, currentUser: initialState.currentUser };
    case USER_FIREBASE_MESSAGING_SET_TOKEN:
        return {
            ...state,
            firebaseMessaging: {
                ...state.firebaseMessaging,
                token: action.value,
            },
        };
    default:
        return state;
    }
}
