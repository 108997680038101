import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Third = () => {
    const [open, setOpen] = useState(false)

    return (
        <div>
            <Accordion
                onChange={(event, expaned) => setOpen(expaned)}
                sx={{
                    '&': {
                        borderRadius: "20px !important",
                        border: "1px solid #219CFF"
                    }
                }}
            >
                <AccordionSummary
                    className={open ? 'bg-[#F2F9FF] rounded-[20px] rounded-b-none' : ''}
                    expandIcon={<div>
                        <svg className='hidden lg:block' width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.7803 22.3033C18.0292 22.3033 17.3163 22.0105 16.7817 21.4759L0.830649 5.5124C-0.276883 4.40489 -0.276883 2.62268 0.830649 1.5279C1.93818 0.420387 3.72042 0.420387 4.81522 1.5279L18.7803 15.4928L32.7454 1.5279C33.8529 0.420387 35.6352 0.420387 36.73 1.5279C37.8375 2.63541 37.8375 4.41762 36.73 5.5124L20.779 21.4759C20.2443 22.0105 19.5314 22.3033 18.7803 22.3033Z" fill="#4D4D4D" />
                        </svg>
                        <svg className='lg:hidden' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2344 12.8632C9.85444 12.8632 9.49382 12.6908 9.22336 12.3758L1.15456 2.97301C0.594314 2.32065 0.594314 1.27089 1.15456 0.626043C1.7148 -0.0263078 2.61634 -0.0263078 3.17015 0.626043L10.2344 8.85166L17.2986 0.626043C17.8588 -0.0263078 18.7604 -0.0263078 19.3142 0.626043C19.8744 1.27839 19.8744 2.32815 19.3142 2.97301L11.2454 12.3758C10.9749 12.6908 10.6143 12.8632 10.2344 12.8632Z" fill="#4D4D4D" />
                        </svg>
                    </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {open ?
                        <div>
                            <h3 className='text-normal font-bold lg:text-2xl text-base'>Calcul des impôts sur les revenus Teach’r : déclarés en tant qu’auto-entrepreneur</h3>
                        </div>
                        :
                        <div>
                            <h3 className='text-first font-bold lg:text-2xl text-base'>Calculs des impôts sur le montant des cours particuliers déclarés</h3>
                            <h4 className='text-normal font-bold lg:text-[22px] '>Étape 3 : Volet calcul des impôts sur les revenus Teach’r déclarés en tant qu’auto entrepreneur et TVA</h4>
                        </div>
                    }
                </AccordionSummary>
                <AccordionDetails className='flex flex-col gap-y-8 py-8 px-0'>
                    <div className='px-8'>
                        <h4 className='font-bold lg:text-[22px] text-radio'>Régime d’impôsition classique</h4>
                        <p className='text-normal font-semibold lg:text-xl leading-[27.28px] text-sm'>Les revenus issus des cours particuliers relèvent des Bénéfices Non Commerciaux (BNC). Un abattement de 34% sur le chiffre d’affaires est donc à prendre en compte pour déterminer le bénéfice imposable : <a href="" target='_blank' className='font-bold text-normal'>https://entreprendre.service-public.fr/vosdroits/F32105</a>
                            L’abattement minimum est de 305€.
                            L’auto-entrepreneur sera donc imposé sur 66% de son chiffres d’affaires brut.
                            Ces 66% s’ajoutent donc aux revenus imposés par le foyer fiscal et subissent les mêmes étapes</p>
                    </div>
                    <div className='px-8'>
                        <h4 className='font-bold lg:text-[22px] text-radio text-base'>Régime libératoire</h4>
                        <p className='text-normal font-semibold lg:text-xl leading-[27.28px] text-sm'>Si vous remplissez la condition suivante :
                            Les revenus du foyer fiscal à l’année N-2 ne dépassent pas 25.710€ par part du foyer fiscal présente à l’année N.
                            Alors, les impôts peuvent être payés en même temps que les cotisations sociales, à hauteur de 2,2% de son chiffre d’affaires brut.</p>
                        <h4 className='text-third font-semibold lg:text-xl leading-[27.28px] text-sm'>/!\ Si l’auto-entrepreneur n’est pas seul sur le foyer fiscal, il convient de rectifier le montant imposable en calculant le taux effectif.</h4>

                        <div className='flex items-center gap-x-2'>
                            <p className='text-normal lg:text-xl font-semibold text-sm'>Taux effectif =</p>
                            <div className='flex flex-col items-center w-max gap-y-1'>
                                <p className='text-normal lg:text-xl font-semibold text-sm'>  impôts total (en cas de régime d’imposition classique) <span className='text-radio font-bold px-2'>X</span> revenus imposables hors A.E</p>
                                <hr className='text-radio w-full' />
                                <p className='text-normal lg:text-xl font-semibold text-sm'>Revenus imposables totaux du foyer fiscal</p>
                            </div>
                        </div>


                        <p className='text-normal lg:text-xl font-semibold text-sm'>
                            Ce taux effectif donne ainsi l’impôt à payer pour les revenus du foyer fiscal. Les impôts que le foyer fiscal de l’auto-entrepreneur aura donc payé sont calculés comme suit :
                            <br /> Impôt final du foyer fiscal = taux effectif <span className='text-radio px-2'>+</span> prélèvement libératoire (payé au fur et à mesure de l’année)
                        </p>
                    </div>
                    <Accordion
                        sx={{
                            '&': {
                                boxShadow: 'none'
                            }
                        }}
                    >
                        <AccordionSummary
                            className='bg-[#F2F9FF]'
                            expandIcon={<div>
                                <svg className='hidden lg:block' width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.7803 22.3033C18.0292 22.3033 17.3163 22.0105 16.7817 21.4759L0.830649 5.5124C-0.276883 4.40489 -0.276883 2.62268 0.830649 1.5279C1.93818 0.420387 3.72042 0.420387 4.81522 1.5279L18.7803 15.4928L32.7454 1.5279C33.8529 0.420387 35.6352 0.420387 36.73 1.5279C37.8375 2.63541 37.8375 4.41762 36.73 5.5124L20.779 21.4759C20.2443 22.0105 19.5314 22.3033 18.7803 22.3033Z" fill="#4D4D4D" />
                                </svg>
                                <svg className='lg:hidden' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2344 12.8632C9.85444 12.8632 9.49382 12.6908 9.22336 12.3758L1.15456 2.97301C0.594314 2.32065 0.594314 1.27089 1.15456 0.626043C1.7148 -0.0263078 2.61634 -0.0263078 3.17015 0.626043L10.2344 8.85166L17.2986 0.626043C17.8588 -0.0263078 18.7604 -0.0263078 19.3142 0.626043C19.8744 1.27839 19.8744 2.32815 19.3142 2.97301L11.2454 12.3758C10.9749 12.6908 10.6143 12.8632 10.2344 12.8632Z" fill="#4D4D4D" />
                                </svg>
                            </div>
                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>
                                <h4 className='text-normal font-bold lg:text-[22px]'>Calcul de la TVA</h4>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className='flex flex-col gap-y-4 pt-8 px-8'>
                            <p className='font-semibold lg:text-[22px] text-normal'>Les cours particuliers sont soumis à deux taux de T.V.A différents :</p>
                            <ul>
                                <li className='list-disc font-semibold lg:text-[22px] text-normal list-inside'>10% s’il s’agit de soutien scolaire</li>
                                <li className='list-disc font-semibold lg:text-[22px] text-normal list-inside'>20% s’il s’agit d’enseignement extra-scolaires</li>
                            </ul>
                            <p className='font-semibold lg:text-[22px] text-normal leading-[30px]'>L’auto-entrepreneur doit payer la TVA dès qu’il dépasse 36.500€ de chiffre d’affaires sur le chiffre d’affaires supplémentaire.</p>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default Third;