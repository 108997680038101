import React from 'react'
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import { bg } from 'date-fns/locale';



function RadioButton(props:any) {
  const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 25,
    color:'white',
    height: 25,
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : 'transparent',
    'input ~ &' : {
    border: props.border ? props.border : '1px solid white'
    },
    'input:checked ~ &' : {
      border: 'none',
      },
    'input:hover ~ &': {
      backgroundColor: props.bg ? props.bg : '#219CFF',
      border: 'none',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      border: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: props.bg ? props.bg : '#219CFF',
    '&:before': {
      display: 'block',
      width:  '100%',
      height: '100%',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: props.bg ? props.bg : '#219CFF',
    },
  });
  return (
    <div><Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    checkedIcon={<BpCheckedIcon />}
    icon={<BpIcon />}
    {...props}
  /></div>
  )
}

export default RadioButton