import React, { useEffect, useRef, useState } from 'react';
import SimulatorNumberInput from '../../../Components/utils/SimulatorNumberInput';
import { Button, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../Components/utils/RadioButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import SimulatorObject from '../../../Objects/models/Simulator';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import InfoAcre from './InfoAcre';
import InfoPl from './InfoPl';

interface SimulatorRedux {
    weekHours: number
    hourlyPrice: number
    adults: number,
    childs: number,
    last_income: number,
    external_income: number,
    education: number,
    isAcre: boolean,
    response: SimulatorObject
}
const Simulator2 = (props: any) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [status, setStatus] = useState(2)
    const [isAcre, setIsAcre] = useState(2)
    const [isAcre2, setIsAcre2] = useState(2)
    const [isPl, setIsPl] = useState(2)
    const [weekHours, setWeekHours] = useState(0)
    const [hourlyPrice, setHourlyPrice] = useState(0)
    const [age, setAge] = useState(0)
    const [netPricePerHour, setNetPricePerHour] = useState(0)
    const [netPricePerMonth, setNetPricePerMonth] = useState(0)
    const [netPricePerYear, setNetPricePerYear] = useState(0)
    const [isMobile, setIsMobile] = useState(false)
    const [reset, setReset] = useState(false)
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(true)

    const simulatorData: SimulatorRedux | null = useSelector((state: any) => state.simulateur)
    const swiperRef = useRef<any>(null);
    const goToSlide = (index: number) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt((event.target as HTMLInputElement).value) === 1) {
            goToSlide(2)
        }
        if (parseInt((event.target as HTMLInputElement).value) === 0) {
            goToSlide(3)
        }
        setStatus(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt((event.target as HTMLInputElement).value) === 1 || parseInt((event.target as HTMLInputElement).value) === 0) {
            goToSlide(4)
        }
        setIsAcre(parseInt((event.target as HTMLInputElement).value))
        setIsAcre2(2)
    };
    const handleChange3 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (parseInt((event.target as HTMLInputElement).value) === 1 || parseInt((event.target as HTMLInputElement).value) === 0) {
            goToSlide(4)
        }
        setIsAcre2(parseInt((event.target as HTMLInputElement).value));
        setIsAcre(2)
    };

    const handleBack = () => {
        if (isAcre === 2) {
            goToSlide(3)
        } else {
            goToSlide(2)
        }
     }

    const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#F2F5FB',
            borderRadius: '13px',
            padding: '20px',
            border: '1px solid ##92D3FF'
        },
    }));
    useEffect(() => {
        if (window.screen.width < 1024) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }


    }, [])
    const getweeHours = (value: string) => {
        if (value !== "") {
            const result = parseInt(value)
            if (result >= 0) {
                setWeekHours(parseInt(value))
            } else {
                setWeekHours(0)
            }

        }
    }
    const getHourlyPrice = (value: string) => {
        if (value !== "") {
            const result = parseInt(value)
            if (result >= 0) {
                setHourlyPrice(parseInt(value))
            } else {
                setHourlyPrice(0)
            }
        }
    }
    // const getAge = (value: string) => {
    //     if (value !== "") {
    //         const result = parseInt(value)
    //         if (result >= 0) {
    //             setAge(parseInt(value))
    //         } else {
    //             setAge(0)
    //         }
    //     }
    // }
    const completeSimulate = () => {
        if (!error) {
            dispatch({
                type: 'SET_INFO', payload: {
                    weekHours: weekHours,
                    hourlyPrice: hourlyPrice,
                    adults: simulatorData?.adults,
                    childs: simulatorData?.childs,
                    last_income: simulatorData?.last_income,
                    external_income: simulatorData?.external_income,
                    education: simulatorData?.education,
                    isAcre: status === 0 || status === 2 ? true : isAcre ? true : false,
                    response: null
                }
            })
            navigate('/teachr/simulate')
        }
    }
    useEffect(() => {
        let net_price_per_hour = hourlyPrice
        let net_price_per_Month = 0
        let net_price_per_Year = 0
        let rapport1 = 74.40/100
        let rapport2 = 72.57/100
        let rapport3 = 64.31/100
        let rapport4 = 62.93/100
        
        // let Result = (x*25+y*22)*0.89*4
        if (weekHours > 0 && hourlyPrice > 0 && status >= 0) {
            setError(false)
        }
        if (status === 2) {
            net_price_per_hour = Math.round(net_price_per_hour * rapport2);
            net_price_per_Month = net_price_per_hour * weekHours * 4.33; 
            net_price_per_Year = net_price_per_hour * 2 * 0.9 * weekHours * 4.33;
        }
        else if (status === 0) {
            // net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.1055))
             if (isAcre2 === 0) {
                //cas n'a plus de l'acre
                net_price_per_hour = (net_price_per_hour * rapport2);
                if (isPl === 1) {
                  net_price_per_hour = (hourlyPrice * rapport1);
                }
              } else {
                //cas ou il a l'aid1
                net_price_per_hour = (net_price_per_hour * rapport3);
                // net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.1055))
                if (isPl === 1) {
                  // net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.689)*0.017)
                  net_price_per_hour = (hourlyPrice * rapport1);
                }
            }
            net_price_per_Month = net_price_per_hour * weekHours * 4.33; 
            net_price_per_Year = net_price_per_hour * 2 * 0.9 * weekHours * 4.33;
           
        }
        else {
            // net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.1055))
             if (isAcre === 0) {
                //cas n'a plus de l'acre
                net_price_per_hour = (net_price_per_hour * rapport2);
                if (isPl === 1) {
                  net_price_per_hour = (hourlyPrice * rapport1);
                }
              } else {
                //cas ou il a l'aid1
                net_price_per_hour = (net_price_per_hour * rapport3);
                // net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.1055))
                if (isPl === 1) {
                  // net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.689)*0.017)
                  net_price_per_hour = (hourlyPrice * rapport1);
                }
            }
            net_price_per_Month = net_price_per_hour * weekHours * 4.33; 
            net_price_per_Year = net_price_per_hour * 2 * 0.9 * weekHours * 4.33;
        }
        props.callBack({
            status: status,
            weekHours: weekHours,
            hourlyPrice: hourlyPrice,
            // age: age,
            netPricePerHour: net_price_per_hour,
            netPricePerMonth: net_price_per_Month,
            netPricePerYear: net_price_per_Year
        })
        setNetPricePerHour(net_price_per_hour)
        setNetPricePerMonth(net_price_per_Month)
        setNetPricePerYear(net_price_per_Year)
    }, [hourlyPrice, status, weekHours, isAcre, isPl, isAcre2])

    const calculer = () => {
        let net_price_per_hour = hourlyPrice * 2
        let net_price_per_Month = 0
        let net_price_per_Year = 0
        // let Result = (x*25+y*22)*0.89*4
        if (weekHours > 0 && hourlyPrice > 0 && status >= 0) {
            setError(false)
        }
        if (status === 2) {
            net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.211))
            net_price_per_Month = net_price_per_hour * weekHours * 4.33
            net_price_per_Year = net_price_per_hour * weekHours * 52
        }
        else if (status === 0) {
            // net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.1055))
             if (isAcre2 === 0) {
                //cas n'a plus de l'acre
                net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.211))
                if (isPl === 1) {
                    net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.689)*0.017)
                }
            } else {
                //cas ou il a l'aide
                net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.106))
                if (isPl === 1) {
                     net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.794)*0.017)
                }
            }
            net_price_per_Month = net_price_per_hour * weekHours * 4.33
            net_price_per_Year = net_price_per_hour * weekHours * 52
        }
        else {
            if (isAcre === 0) {
                //cas n'a plus de l'acre
                net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.211))
                if (isPl === 1) {
                    net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.689)*0.017)
                }
            } else {
                //cas ou il a l'aide
                net_price_per_hour = ((net_price_per_hour * 0.9) - (net_price_per_hour * 0.106))
                if (isPl === 1) {
                     net_price_per_hour = net_price_per_hour - ((net_price_per_hour/0.794)*0.017)
                }
            }

            net_price_per_Month = net_price_per_hour * weekHours * 4.33
            net_price_per_Year = net_price_per_hour * weekHours * 52
        }
        props.callBack({
            status: status,
            weekHours: weekHours,
            hourlyPrice: hourlyPrice,
            // age: age,
            netPricePerHour: net_price_per_hour,
            netPricePerMonth: net_price_per_Month,
            netPricePerYear: net_price_per_Year
        })
        setNetPricePerHour(net_price_per_hour)
        setNetPricePerMonth(net_price_per_Month)
        setNetPricePerYear(net_price_per_Year)
        setReset(true)
    }
    const renderSimulator = () => {
        if (isMobile) {
            if (reset) {
                return (
                    <div className=' rounded-[11px] bg-white  border-solid border-[rgba(183,183,183,0.45)] shadow-simulator px-4 md:px-8 py-8'>
                        <div className='  w-full border-b border-[rgba(77,77,77,0.43)] py-4'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap text-sm'>Prix net perçu par mois avec Teach’r : </p>
                            <p className='font-bold lg:text-[22px] text-first text-lg'>{netPricePerYear.toFixed(2)} € 
                            {/* <sup className='text-third'>(2)</sup> */}
                            </p>
                        </div>
                        <div className='border-b border-[rgba(77,77,77,0.43)] w-full py-4'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap text-sm'>Montant mensuel déboursé par vos élèves : </p>
                            <p className='font-bold lg:text-[22px] text-first text-lg'>{netPricePerMonth.toFixed(2)} €
                             {/* <sup className='text-third'>(2)</sup> */}
                             </p>
                        </div>
                        <div className='w-full border-b border-[rgba(77,77,77,0.43)] py-4'>
                            <p className='font-semibold lg:text-base'>Prix net horaire déboursé par votre élève : </p>
                            <p className='font-extrabold lg:text-[26px] text-radio text-[22px]'>{netPricePerHour.toFixed(2)} € <span className='text-base lg:text-base'>NET à l’année <sup className='text-third'>(2)</sup></span></p>
                        </div>
                        <div className="py-4">
                            <p className='flex text-xs font-semibold text-normal'><span className='pr-2 text-third'>(2)</span>Veuillez noter qu’il s’agit du montant qui sera prélevé à votre élève par heure de cours en utilisant l’Avance Immédiate. Le double leur sera facturé. L’État couvre immédiatement la différence entre le montant facturé et le montant payé par votre élève</p>
                        </div>
                        <div className="flex justify-between gap-x-4">
                            <Button onClick={() => setReset(false)} className='rounded-full'>
                                <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 2L2.49519 11.108C2.24232 11.3082 2.24232 11.6918 2.49519 11.892L14 21" stroke="#4D4D4D" strokeWidth="3" strokeLinecap="round" />
                                </svg>

                            </Button>
                            <Button onClick={completeSimulate} className='w-full py-3 text-white rounded-full bg-radio text-md'>Compléter ma simulation</Button>
                        </div>
                    </div>
                )
            }
            return (
                <div className='rounded-[11px] py-4 lg:rounded-[20px] bg-white relative   lg:mx-auto border border-solid border-[rgba(183,183,183,0.45)] shadow-simulator flex flex-col gap-y-6 pt-4 md:pt-8 lg:w-[94vw]' >
                    <h3 className='hidden mx-4 font-extrabold lg:text-lg text-first md:mx-8 lg:block'>SIMULER VOS GAINS DÈS MAINTENANT 😎</h3>
                    <div className="flex flex-col items-center px-4 gap-y-6 lg:gap-4 md:flex-row md:flex-wrap lg:flex-nowrap md:px-8">
                        <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getweeHours} placeholder="10h" label="Nombre d’heures de cours par semaine :" />
                        </div>
                        <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getHourlyPrice} placeholder="Ex. 20€/h" label="Prix horaire payé :" sup='1' />
                        </div>
                        {/* <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getAge} placeholder="Ex. 23 ans" label="Votre âge" />
                        </div> */}
                        {/* <FormControl>
                            <div className='flex items-center gap-x-2'>
                                <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label>
                                <div className="z-50">
                                    <LightTooltip
                                        open={open}
                                        onMouseEnter={() => setOpen(true)}
                                        onMouseLeave={() => setOpen(false)}
                                        onClick={() => setOpen(!open)}
                                        title={
                                            <div className='text-sm text-normal font-nunito '>
                                                L'aide à la création ou à la reprise d'une entreprise (Acre) consiste en une exonération partielle de charges sociales, dite exonération de début d'activité.
                                                <a className='block pt-2 underline text-normal text-radio' href="https://www.service-public.fr/particuliers/vosdroits/F11677#:~:text=Situation%20du%20b%C3%A9n%C3%A9ficiaire&text=Demandeur%20d%27emploi%20non%20indemnis%C3%A9,ans%20et%20%C3%AAtre%20reconnu%20handicap%C3%A9" target='_blank'>Lien vers l’éligibilité à l’ACRE</a>
                                            </div>
                                        }>
                                        <svg className='m-0' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M14.9855 7.74101C14.9855 11.74 11.7429 14.982 7.74273 14.982C3.74257 14.982 0.5 11.74 0.5 7.74101C0.5 3.74201 3.74257 0.5 7.74273 0.5C11.7429 0.5 14.9855 3.74201 14.9855 7.74101Z" stroke="#808080" />
                                            <path d="M9.21545 11.532V6.41812H5.70703V7.27025H6.65822V11.532H5.80539V12.3844H10.0682V11.532L9.21545 11.532ZM7.93684 5.81687C8.64286 5.81687 9.21551 5.24447 9.21551 4.53815C9.21545 3.83216 8.64286 3.25977 7.93684 3.25977C7.23082 3.25977 6.65817 3.83216 6.65817 4.53815C6.65817 5.24441 7.23076 5.81687 7.93684 5.81687Z" fill="#808080" />
                                        </svg>
                                    </LightTooltip>
                                </div>
                            </div>
                            <RadioGroup row
                                value={status}
                                name="customized-radios"
                                onChange={handleChange}
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="2" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Je ne sais pas" />
                            </RadioGroup>
                        </FormControl> */}
                        <div className="md:w-1/3 w-full ">
                            <Swiper
                                ref={swiperRef}
                                noSwiping
                                noSwipingClass='swiper-no-swiping'
                                spaceBetween={100}
                                className='h-full pl-2'
                                slidesPerView={1}
                                loop
                                speed={700}
                                navigation={{
                                    nextEl: ".next-slide"
                                }}
                                modules={[Navigation]}
                                effect="slide">
                                <SwiperSlide className="swiper-no-swiping">
                                    <FormControl>
                                        <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label>
                                            <div className="z-50">
                                                <InfoAcre />
                                            </div>
                                        </div>

                                        <RadioGroup row
                                            value={status}
                                            name="customized-radios"
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="2" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Je ne sais pas" />
                                        </RadioGroup>
                                    </FormControl>
                                </SwiperSlide >
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => goToSlide(1)}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <label className='text-sm font-extrabold text-first'>Bénéficiez-vous actuellement de l’ACRE ? </label>

                                            <RadioGroup row
                                                value={isAcre}
                                                name="customized-radios"
                                                onChange={handleChange2}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => goToSlide(1)}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Êtes-vous éligible ? </label>
                                                {/* <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label> */}
                                                <div className="z-50">
                                                    <InfoAcre />
                                                </div>
                                            </div>
                                            <RadioGroup row
                                                value={isAcre2}
                                                name="customized-radios"
                                                onChange={handleChange3}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => handleBack()}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Opterez-vous pour le prélèvement libératoire ? ( Recommandé )</label>
                                                {/* <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label> */}
                                                <div className="z-50">
                                                    <InfoPl />
                                                </div>
                                            </div>
                                            <RadioGroup row
                                                value={isPl}
                                                name="customized-radios"
                                                onChange={(e, value) => setIsPl(parseInt(value))}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>
                        <p className='flex text-xs font-semibold text-normal'><span className='pr-2 text-third'>(2)</span>Net de cotisations sociales, de frais de service et d’impôts, selon les lois en vigueur</p>
                        <Button onClick={() => setReset(true)} className='px-16 py-3 font-extrabold text-white rounded-full bg-radio btn-simuler disabled:opacity-50' disabled={hourlyPrice > 0 && weekHours > 0 && status >= 0 ? false : true}>Calculer</Button>
                    </div>
                    <div className="flex-col justify-between hidden pt-4 md:flex-row lg:flex">
                        <div className='px-4 my-4 md:px-8 w-max border-r border-[rgba(77,77,77,0.43)]'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap'>Prix net perçu par mois avec Teach’r : </p>
                            <p className='font-bold lg:text-[22px] text-first'>{netPricePerHour.toFixed(2)} € 
                            {/* <sup className='text-third'>(2)</sup> */}
                            </p>
                        </div>
                        <div className='px-4 py-4 md:px-8 w-max'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap'>
                                Montant mensuel déboursé par vos élèves : </p>
                            <p className='font-bold lg:text-[22px] text-first'>{netPricePerMonth.toFixed(2)} € 
                            {/* <sup className='text-third'>(2)</sup> */}
                            </p>
                        </div>
                        <div className='flex items-center gap-8 bg-[rgba(196,196,196,0.17)] px-4 md:px-8 py-4 rounded-[5px] w-full justify-around'>
                            <div>
                                <p className='font-semibold lg:text-base'>Prix net horaire déboursé par votre élève : </p>
                                <p className='font-extrabold lg:text-[26px] text-radio'>{netPricePerYear.toFixed(2)} € <span className='lg:text-base'>NET à l’année <sup className='text-third'>(2)</sup></span></p>
                            </div>

                        </div>

                    </div>
                </div>
            )
        } else {
            return (
                <div className=' lg:rounded-[20px] bg-white relative   lg:mx-auto border border-solid border-[rgba(183,183,183,0.45)] shadow-simulator flex flex-col gap-y-6 pt-4 md:pt-8 lg:w-[94vw]' >
                    <h3 className='hidden mx-4 font-extrabold lg:text-lg text-first md:mx-8 lg:block'>SIMULER VOS GAINS DÈS MAINTENANT 😎</h3>
                    <div className="flex flex-col items-center px-4 gap-y-6 lg:gap-4 md:flex-row md:flex-wrap lg:flex-nowrap md:px-8">
                        <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getweeHours} placeholder="10h" label="Nombre d’heures de cours par semaine :" />
                        </div>
                        <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getHourlyPrice} placeholder="Ex. 20€/h" label="Prix horaire payé :" sup='1' />
                        </div>
                        {/* <div className="w-full lg:w-max">
                            <SimulatorNumberInput callBack={getAge} placeholder="Ex. 23 ans" label="Votre âge" />
                        </div> */}
                        <div className="w-1/3 ">
                            <Swiper
                                ref={swiperRef}
                                noSwiping
                                noSwipingClass='swiper-no-swiping'
                                spaceBetween={100}
                                className='h-full pl-2'
                                slidesPerView={1}
                                loop
                                speed={700}
                                navigation={{
                                    nextEl: ".next-slide"
                                }}
                                modules={[Navigation]}
                                effect="slide">
                                <SwiperSlide className="swiper-no-swiping">
                                    <FormControl>
                                        <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label>
                                            <div className="z-50">
                                                <InfoAcre />
                                            </div>
                                        </div>

                                        <RadioGroup row
                                            value={status}
                                            name="customized-radios"
                                            onChange={handleChange}
                                        >
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="2" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Je ne sais pas" />
                                        </RadioGroup>
                                    </FormControl>
                                </SwiperSlide >
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => goToSlide(1)}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <label className='text-sm font-extrabold text-first'>Bénéficiez-vous actuellement de l’ACRE ? </label>

                                            <RadioGroup row
                                                value={isAcre}
                                                name="customized-radios"
                                                onChange={handleChange2}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => goToSlide(1)}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Êtes-vous éligible ? </label>
                                                {/* <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label> */}
                                                <div className="z-50">
                                                    <InfoAcre />
                                                </div>
                                            </div>
                                            <RadioGroup row
                                                value={isAcre2}
                                                name="customized-radios"
                                                onChange={handleChange3}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className="swiper-no-swiping">
                                    <div className="flex">
                                        <Button onClick={() => handleBack()}>
                                            <svg width="11" height="25" viewBox="0 0 11 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 1L2 12.5L10 24" stroke="#4D4D4D" strokeWidth="2" strokeLinecap="round" />
                                            </svg>
                                        </Button>
                                        <FormControl>
                                            <div className='flex items-center gap-x-2'>
                                            <label className='text-sm font-extrabold text-first'>Opterez-vous pour le prélèvement libératoire ? ( Recommandé )</label>
                                                {/* <label className='text-sm font-extrabold text-first'>Avez-vous déjà bénéficié de l’ACRE dans les 3 dernières années ?</label> */}
                                                <div className="z-50">
                                                    <InfoPl />
                                                </div>
                                            </div>
                                            <RadioGroup row
                                                value={isPl}
                                                name="customized-radios"
                                                onChange={(e, value) => setIsPl(parseInt(value))}
                                            >
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={1} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui" />
                                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value={0} control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </SwiperSlide>

                            </Swiper>
                        </div>

                        {
                            !error && <Button onClick={completeSimulate} className='w-3/4 py-2 mb-4 text-base font-extrabold text-white rounded-full bg-radio md:mb-8 lg:hidden btn-simuler'>Compléter ma simulation</Button>
                        }
                    </div>
                    <div className="flex-col justify-between hidden pt-4 md:flex-row lg:flex">
                        <div className='px-4 my-4 md:px-8 w-max border-r border-[rgba(77,77,77,0.43)]'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap'>Prix net perçu par mois avec Teach’r : </p>
                            <p className='font-bold lg:text-[22px] text-first'>{netPricePerYear.toFixed(2)} € 
                            {/* <sup className='text-third'>(2)</sup> */}
                            </p>
                        </div>
                        <div className='px-4 py-4 md:px-8 w-max'>
                            <p className='text-[rgba(77,77,77,0.74)] font-normal lg:text-base whitespace-nowrap'>
                                Montant mensuel déboursé par vos élèves : </p>
                            <p className='font-bold lg:text-[22px] text-first'>{netPricePerMonth.toFixed(2)} €
                             {/* <sup className='text-third'>(2)</sup> */}
                             </p>
                        </div>
                        <div className='flex items-center gap-8 bg-[rgba(196,196,196,0.17)] px-4 md:px-8 py-4 rounded-[5px] w-full justify-around'>
                            <div>
                                <p className='font-semibold lg:text-base'>Prix net horaire déboursé par votre élève : </p>
                                <p className='font-extrabold lg:text-[26px] text-radio'>{netPricePerHour.toFixed(2)} € <span className='lg:text-base'>NET à l’année <sup className='text-third'>(2)</sup></span></p>
                            </div>
                            {!error && <Button onClick={completeSimulate} className='px-16 py-3 font-extrabold text-white rounded-full bg-radio btn-simuler'>Compléter ma simulation</Button>}
                        </div>
                    </div>
                    <div className="flex px-4 py-8 md:px-8 gap-x-8">
                        <p className='flex text-xs font-semibold text-normal'><span className='pr-2 text-third'>(1)</span>Net de cotisations sociales, de frais de service et d’impôts, selon les lois en vigueur</p>
                        <p className='flex text-xs font-semibold text-normal'><span className='pr-2 text-third'>(2)</span>Veuillez noter qu’il s’agit du montant qui sera prélevé à votre élève par heure de cours en utilisant l’Avance Immédiate. Le double leur sera facturé. L’État couvre immédiatement la différence entre le montant facturé et le montant payé par votre élève.</p>
                    </div>
                </div>
            )
        }
    }

    return (
        <div>
            <h3 className='mx-2 font-extrabold text-[12px] text-first md:mx-8 pb-4 lg:hidden'>SIMULER VOS GAINS DÈS MAINTENANT 😎</h3>
            {renderSimulator()}

        </div>

    );
};

export default Simulator2;