import HydraEntity from "./HydraEntity";
import "reflect-metadata";

export default class NoteForTeachr extends HydraEntity {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;
    readonly id?: number;


    title?: string 
    description?: any 
    teachr?:string

    constructor(
        title: string ,
        description: any,
        teachr: string ,
    ) {
        super();
        this.title = title;
        this.description = description;
        this.teachr = teachr;
    }
}
