import HydraEntity from "./HydraEntity";
import "reflect-metadata";
import Child from "./Child";
import Client from "./Client";

export default class ReviewClient extends HydraEntity {

    comment?: string;
    note?: number;
    owner?: {firstName: string, lastName: string, id: number, averageNote:number, profilePicture:string}
    punctualCourse?: {acceptProposal: string, forChild:Child,forClient:Client,subjectAsked:{fr_FR: string}}
    readonly id?:number
    readonly lastModificationDatetime?:Date

    constructor() {
        super();
    }
}
