import validator from "validator";
import errorIcon from "../Assets/Images/error.png";

const validateName = (value) => {
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: "Entrez votre nom",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  } 
  else if (!value.match(/^[a-z,.'-éèàê]+$/i) ) {
    return {
      isValid: false,
      message: "Nom de famille invalide",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  }
  if (value.match(/^[0-9]+$/)) {
    return {
      isValid: false,
      message: "Nom de famille invalide",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  } else {
    return {
      isValid: true,
      message: "",
    };
  }
};

export default validateName;
