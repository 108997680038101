import * as React from 'react';
// import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';

import CalendarIcon from '../../Assets/Images/Vector (1).png'
// import moment from 'moment';
import { frFR, MobileDatePicker } from '@mui/x-date-pickers';

const localeMap = {
  fr: frLocale,

};

export default function DatePickersBirthday(props: any) {
  const [locale] = React.useState<keyof typeof localeMap>('fr');
  const today = new Date();
  // const tomorrow: any = today.setDate(today.getDate() + 1)

  return (


    <div className='flex relative w-full font-nunito'>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[locale]}

        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
      >
        <div className='lg:text-lg md:text-md text-sm w-full relative md:block hidden'>
          <MobileDatePicker
            className='font-nunito lg:text-lg md:text-md text-sm  -pb-2 '
            inputFormat="dd/MM/yyyy"
            showToolbar={false}
            maxDate={today}
            closeOnSelect
            toolbarTitle="Sélectionner la date"
            value={props.value}
            onChange={(val) => props.callBack(val)}
            renderInput={(params) => <TextField {...params}
              className="lg:text-lg md:text-md text-sm"
              placeholder="Ex : 26/08/2009"
              sx={{
                width: "100%",

                paddingTop: '-3rem',
                "& .MuiOutlinedInput-root": {
                  fontSize: "18px",
                  fontFamily: 'nunito'
                },
                "& fieldset": {
                  border: "none",
                  textDecoration: "black",
                },
              }} />
            }
          />

        </div>
        <div className='w-full relative lg:text-lg md:text-md text-sm  md:hidden' >
          <MobileDatePicker
            className='font-nunito lg:text-lg md:text-md text-sm  -pb-2 '
            inputFormat="dd/MM/yyyy"
            showToolbar={false}
            maxDate={today}
            closeOnSelect
            toolbarTitle="Sélectionner la date"
            value={props.value}
            onChange={(val) => props.callBack(val)}
            renderInput={(params) => <TextField {...params}
              placeholder="Ex : 26/08/2009"
              sx={{
                width: "100%",

                paddingTop: '-3rem',
                "& .MuiOutlinedInput-root": {
                  //fontSize:"18px",
                  fontFamily: 'nunito'
                },
                "& fieldset": {
                  border: "none",
                  textDecoration: "black",
                },
              }} />}
          />

        </div>
        <div className=" md:-ml-4 ml-3 mt-2">
          <img src={CalendarIcon} alt="calendar icon" className="w-6 h-6 " />
        </div>
      </LocalizationProvider>
    </div>
  );
}
