import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import HydraCollection from "../Objects/models/HydraCollection";
import { Subject } from "../Objects/models/Subject";
import { BusinessType } from "../Objects/models/BusinessType";

export default abstract class SubjectRepository {
    /**
     * Fetch a subject stored into the API by his id.
     * @param id
     */
    public static fetchSubjectById(id: number) {
        return ApiService.getSingleInstance<Subject>(
            Route.SUBJECT_BASE + "/" + id,
            Subject
        );
    }

    /**
     * Returns all subject of the connected user.
     */
    public static getSubjects(page: number = 1) {
        return ApiService.getCollection<HydraCollection<Subject>>(
            Route.SUBJECT_BASE,
            HydraCollection,
            true,
            page,
            null
        );
    }

     /**
     * Returns all subject of the connected user.
     */
     public static getBusinessType(page: number = 1) {
        return ApiService.getCollection<HydraCollection<BusinessType>>(
            // Route.SUBJECT_BASE,
            "/teachrs/get_all_business_types",
            HydraCollection,
            true,
            page,
            null
        );
    }
    
}
