import { Address } from "./Address";
import Image from "./Image";

export default class Teachr {
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;

  // whereStudies: string;
  // yearInStudies: number; // superior or equal to 1
  // description: string; // min 60 charac
  // acceptMarketingEmail: boolean;
  // siren?: string;
  // iban: string;

  // isAE: boolean;
  // otherSubjects?: string;
  // NNI?: string;
  // secondFirstName?: string;
  // bornPlace?: string; // null or undefined if isBornFrance is false
  // marriedName?: string;
  // isBornFrance?: boolean;
  // proofIsAE?: string;
  // isValidated?: boolean;
  // geoDistance?: number;
  // geoPoint?: string;

  // averageNote?: number;

  // firebaseAuthToken: string;

  // firstName: string;
  // lastName: string;
  // phone: string;
  // mail: string;
  // firebaseMessagingToken: string;
  // badges?: Array<string>;

  // sex : string; // MAN or WOMAN

  // birthday?: Date | string;

  // profilePictureURL?: string; // image URL for Symfony endpoints

  address?: any;

  birthday: Date | string;
  whereStudies: string;
  yearInStudies: number;
  // defaultFees?: number;
  description: string;
  siren?: number;
  iban?: string;
  subjectToTeach?: string[];
  otherSubjects?: string;
  otherSueperiorSubjects?: string;
  NNI?: string;
  secondFirstName?: string;
  bornPlace?: string;
  proofIsAE?: any;
  marriedName?: string;
  isBornFrance?: boolean;
  RCS?: string;
  firebaseAuthToken: string;
  firstName: string;
  lastName: string;
  phone: string | null;
  mail: string | null;
  profilePicture?: any;
  sex: string;
  firebaseMessagingToken: string;
  geoPoint?: string;
  geoDistance?: number;
  acceptMarketingEmail?: boolean;
  isValidated?: boolean;
  isAE?: boolean;
  businessType?: string;
  signSap?: boolean;
  isShowInfos?: boolean;
  proofSignSap?: any;
  proofIdentity?: any;
  proofIdentity2?: any;
  proofMandat?: any;
  recommandedBy?: string;
  invitationCode?: string;
  defaultFees?: string;
  isFirstCourse?: boolean;
  isOldFacturation?: boolean;
  // isTdae?: boolean;
  // statusTdae?: string;

  readonly id?: number;

  readonly lastModificationDatetime?: Date;

  readonly creationDatetime?: Date;

  constructor(
    firebaseAuthToken: string,
    firstName: string,
    lastName: string,
    mail: string | null,
    phone: string | null,
    firebaseMessagingToken: string,
    sex: string,
    whereStudies: string,
    yearInStudies: number,
    description: string,
    // iban: string,
    birthday: Date | string
  ) {
    this.firebaseAuthToken = firebaseAuthToken;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.mail = mail;
    this.firebaseMessagingToken = firebaseMessagingToken;
    this.whereStudies = whereStudies;
    this.yearInStudies = yearInStudies;
    this.description = description;
    this.sex = sex;
    this.birthday = birthday;
  }
}
