// src/components/ImageModal.tsx
import React, { useState } from 'react';

interface ImageModalProps {
  src: string;
  alt: string;
}

const ImageModal: React.FC<ImageModalProps> = ({ src, alt }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className=" md:w-1/2 w-full mx-auto">
      <img
        src={src}
        alt={alt}
        className="rounded cursor-pointer transition-opacity duration-300 hover:opacity-70 w-full  /max-w-xs"
        onClick={handleImageClick}
      />

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden bg-black bg-opacity-90" onClick={handleCloseModal}>
          <span
            className="absolute top-4 right-8 text-white text-4xl font-bold cursor-pointer transition duration-300 hover:text-gray-400"
            onClick={handleCloseModal}
          >
            &times;
          </span>
          <img
            className="modal-content w-5/6 max-w-3xl mx-auto animate-zoom"
            src={src}
            alt={alt}
          />
        </div>
      )}
    </div>
  );
};

export default ImageModal;
