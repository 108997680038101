import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import schemaFactiration from '../../../../Assets/Images/schema_facturation.png'

const First = () => {
    const [open, setOpen] = useState(false)
 
    return (
        <div>
            <Accordion
                onChange={(event, expaned) => setOpen(expaned)}
                sx={{
                    '&': {
                        borderRadius: "20px !important",
                        border: "1px solid #219CFF"
                    }
                }}
            >
                <AccordionSummary
                    className={open ? 'bg-[#F2F9FF] rounded-[20px] rounded-b-none' : ''}
                    expandIcon={<div>
                        <svg className='hidden lg:block' width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18.7803 22.3033C18.0292 22.3033 17.3163 22.0105 16.7817 21.4759L0.830649 5.5124C-0.276883 4.40489 -0.276883 2.62268 0.830649 1.5279C1.93818 0.420387 3.72042 0.420387 4.81522 1.5279L18.7803 15.4928L32.7454 1.5279C33.8529 0.420387 35.6352 0.420387 36.73 1.5279C37.8375 2.63541 37.8375 4.41762 36.73 5.5124L20.779 21.4759C20.2443 22.0105 19.5314 22.3033 18.7803 22.3033Z" fill="#4D4D4D" />
                        </svg>
                        <svg className='lg:hidden' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2344 12.8632C9.85444 12.8632 9.49382 12.6908 9.22336 12.3758L1.15456 2.97301C0.594314 2.32065 0.594314 1.27089 1.15456 0.626043C1.7148 -0.0263078 2.61634 -0.0263078 3.17015 0.626043L10.2344 8.85166L17.2986 0.626043C17.8588 -0.0263078 18.7604 -0.0263078 19.3142 0.626043C19.8744 1.27839 19.8744 2.32815 19.3142 2.97301L11.2454 12.3758C10.9749 12.6908 10.6143 12.8632 10.2344 12.8632Z" fill="#4D4D4D" />
                        </svg>
                    </div>
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {open ?
                        <div>
                            <h3 className='text-normal font-bold lg:text-2xl text-base'>Calcul des cotisations sociales :</h3>
                        </div>
                        :
                        <div>
                            <h3 className='text-first font-bold lg:text-2xl text-base'>Calcul des cotisations sociales :</h3>
                            <h4 className='text-normal font-bold lg:text-[22px]'>Étape 1. Volet cotisation sociales et facturation Teach’r</h4>
                        </div>
                    }

                </AccordionSummary>
                <AccordionDetails className='px-0 py-8 flex flex-col gap-y-8'>
                    <div className='flex flex-col px-8 gap-y-2'>
                        <p className='lg:text-xl font-semibold text-sm'>Si le professeur est éligible à l’ACRE ( <a className='font-bold' href="https://www.service-public.fr/particuliers/vosdroits/F11677#:~:text=Situation%20du%20b%C3%A9n%C3%A9ficiaire&text=Demandeur%20d%27emploi%20non%20indemnis%C3%A9,ans%20et%20%C3%AAtre%20reconnu%20handicap%C3%A9" target='_blank'>lien vers éligibilité ACRE</a> ), il paye 10,6% de cotisations sociales la 1ère année d’activité, puis 21,3% à partir de la suivante.</p>
                        <p className='lg:text-xl font-semibold text-sm'>Les cotisations sociales sont calculées sur le chiffre d’affaires brut du professeur, dans notre cas, il ne peut donc pas déduire la commission de Teach’r de ses frais.</p>
                    </div>
                    <Accordion
                        sx={{
                            '&': {
                                boxShadow: 'none'
                            }
                        }}
                    >
                        <AccordionSummary
                            className='bg-[#F2F9FF]'
                            expandIcon={<div>
                                <svg className='hidden lg:block' width="38" height="23" viewBox="0 0 38 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M18.7803 22.3033C18.0292 22.3033 17.3163 22.0105 16.7817 21.4759L0.830649 5.5124C-0.276883 4.40489 -0.276883 2.62268 0.830649 1.5279C1.93818 0.420387 3.72042 0.420387 4.81522 1.5279L18.7803 15.4928L32.7454 1.5279C33.8529 0.420387 35.6352 0.420387 36.73 1.5279C37.8375 2.63541 37.8375 4.41762 36.73 5.5124L20.779 21.4759C20.2443 22.0105 19.5314 22.3033 18.7803 22.3033Z" fill="#4D4D4D" />
                                </svg>
                                <svg className='lg:hidden' width="20" height="13" viewBox="0 0 20 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.2344 12.8632C9.85444 12.8632 9.49382 12.6908 9.22336 12.3758L1.15456 2.97301C0.594314 2.32065 0.594314 1.27089 1.15456 0.626043C1.7148 -0.0263078 2.61634 -0.0263078 3.17015 0.626043L10.2344 8.85166L17.2986 0.626043C17.8588 -0.0263078 18.7604 -0.0263078 19.3142 0.626043C19.8744 1.27839 19.8744 2.32815 19.3142 2.97301L11.2454 12.3758C10.9749 12.6908 10.6143 12.8632 10.2344 12.8632Z" fill="#4D4D4D" />
                                </svg>
                            </div>

                            }
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <div>
                                <h4 className='text-normal font-bold lg:text-[22px]'>Rappel du schéma de facturation Teach’r</h4>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails>
                            <img src={schemaFactiration} className='mx-auto' alt="" />
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default First;