import React, { useState } from 'react';

import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../../Components/utils/RadioButton';
import First from './First';
import Second from './Second';



const FiveIndex = (props: any) => {
    const [step, setStep] = useState(1)
    const nextStep = () => {
        if (step < 2) {
            setStep(step + 1)
            props.nextPage(6)
        } else {
            props.callBack(5)
        }
    }
    const renderScreen = () => {
        switch (step) {
            case 1:
                return <First />;
            case 2:
                return <Second />;
            default:
                return <First />;
        }
    }
    return (
        <div className=''>
            {renderScreen()}
            <div className=' flex flex-col h-full gap-10 relative w-full'>
                <div className='flex items-center gap-x-4 '>
                   <span className="hidden lg:inline">
                   <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#0054A3" />
                        <path d="M20.9543 17.764C21.3943 17.764 21.7243 17.8593 21.9443 18.05C22.1643 18.2407 22.2743 18.512 22.2743 18.864C22.2743 19.5973 21.8343 19.964 20.9543 19.964H19.8323V21.702C19.8323 22.1567 19.7003 22.5087 19.4363 22.758C19.187 23.0073 18.857 23.132 18.4463 23.132C18.0356 23.132 17.6983 23.0073 17.4343 22.758C17.185 22.5087 17.0603 22.1567 17.0603 21.702V19.964H11.2963C10.9003 19.964 10.5923 19.854 10.3723 19.634C10.1523 19.3993 10.0423 19.1133 10.0423 18.776C10.0423 18.424 10.1596 18.0867 10.3943 17.764L17.1923 7.996C17.339 7.79067 17.5223 7.63667 17.7423 7.534C17.9623 7.41667 18.1896 7.358 18.4243 7.358C18.8056 7.358 19.1356 7.48267 19.4143 7.732C19.693 7.98133 19.8323 8.33333 19.8323 8.788V17.764H20.9543ZM12.9683 17.764H17.0603V11.846L12.9683 17.764Z" fill="white" />
                    </svg>
                   </span>
                    <h2 className='text-first lg:text-[22px] text-lg font-bold'>Annexe 3 : Fiche d’information NOVA</h2>
                </div>
                <div>
                    <p className='text-base lg:text-xl font-semibold text-normal'>Veuillez prendre en compte les informations suivantes avant la signature de votre document   </p>
                    <a target="_blank" download href={props.file} className='text-base lg:text-xl font-semibold text-radio'>visualiser la procuration</a>
                </div>
                <div className="flex justify-between w-full lg:gap-10 gap-y-4 lg:flex-row flex-col">
                    <Button disabled={props.loading} onClick={nextStep} className='text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30' style={{ background: 'linear-gradient(180deg, #0054A3 0%, #219CFF 100%)' }}>SUIVANT</Button>
                    <Button disabled={props.loading} onClick={() => props.prevStep()} className='text-normal lg:text-xl rounded-full px-10 lg:py-3 mx-auto block  w-full disabled:opacity-30 lg:w-max py-2 lg:hidden font-semibold' >précédent</Button>
                </div>

            </div>

        </div>
    );
};

export default FiveIndex;