import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import closeCircle from '../../../../../../../Assets/Icons/close_circle.svg'


export default function PopUpUpdateCourse(props: any) {
    const { value, handleLoad, openEdit, handleOpenEdit, handleLock} = props

    const handleClose = () => {
        handleOpenEdit(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (openEdit) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [openEdit]);

    const handlSubmit = () => {
        handleLoad()
        handleLock()
        handleClose()
    }

    return (
        <div className=''>
            <Dialog
                open={openEdit}
                onClose={handleClose}
                // scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"

            >
                <DialogTitle id="scroll-dialog-title" >
                    <div className='flex w-full //border gap-x-10 //px-10 //items-center relative //justify-between text-base font-extrabold uppercase text-first'>
                        <div className="//w-full w-3/4 //border pt-1">
                            <p className="text-left">Vous allez modifier toutes les {value} Séances de ce cours régulier</p>
                        </div>
                        <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div className="//py-8 //px-10 text-left ">
                            <div className=" //ml-2 mt-4 w-full">
                                <p className='text-lg -mt-6 mb-4 text-normal font-bold'>Si vous préférez plutôt modifier une ou plusieurs Séances, Veuillez cliquer dans l’onglet “Séances à venir”.
                                    Si vous souhaitez poursuivre la modification de toutes les Séances du cours régulier, cliquez sur “Continuer”.
                                </p>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full px-4 justify-between //gap-x-8 py-3 //md:pt-8'>
                        <Button onClick={() => handleClose()} className='block bg-[#4d4d4d] font-extrabold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Retour</Button>
                        <Button onClick={() => handlSubmit()} className='block bg-dark_blue font-extrabold   rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Suivant</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}