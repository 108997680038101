import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import SimplOptions from '../../AfterVideo/Meet/SelectOptions';
import { useDispatch, useSelector } from 'react-redux';

const Eleventh = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [saveSiren, setSaveSiren] = useState<number>()
    const [savePersonnalInfo, setSavePersonnnalInfo] = useState<number>()
    const [paiementMode, setPaiementMode] = useState<number>()
    const [error, setError] = useState({
        paiementMode: true,
        saveSiren: true,
        savePersonnalInfo: true
    })
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.eleventh) {
            if (generalProcuration.second.eleventh.paiementMode !== undefined) {
                setPaiementMode(generalProcuration.second.eleventh.paiementMode)
            }
            if (generalProcuration.second.eleventh.saveSiren !== undefined) {
                setSaveSiren(generalProcuration.second.eleventh.saveSiren)
            }
            if (generalProcuration.second.eleventh.savePersonnalInfo !== undefined) {
                setSavePersonnnalInfo(generalProcuration.second.eleventh.savePersonnalInfo)
            }
        }
    }, [generalProcuration])
    const handleChangePaiementMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaiementMode(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSavePersonnalInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSavePersonnnalInfo(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSaveSiren = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSaveSiren(parseInt((event.target as HTMLInputElement).value));
    };
    const validate = () => {
        let error_paiment_mode = true
        let error_save_siren = true
        let error_save_personnal_info = true

        if (paiementMode !== undefined && paiementMode >= 0) {
            error_paiment_mode = false
        } else {
            error_paiment_mode = true
        }
        if (savePersonnalInfo !== undefined && savePersonnalInfo >= 0) {
            error_save_personnal_info = false
        } else {
            error_save_personnal_info = true
        }
        if (saveSiren !== undefined && saveSiren >= 0) {
            error_save_siren = false
        } else {
            error_save_siren = true
        }
        setError({
            paiementMode: error_paiment_mode,
            saveSiren: error_save_siren,
            savePersonnalInfo: error_save_personnal_info
        })
        if (!error_paiment_mode && !error_save_siren && !error_save_personnal_info) {
            return true
        }
        return false
    }
    // useEffect(
        const handleNext = () => {
        props.callBack({
            saveSiren: saveSiren,
            savePersonnalInfo: savePersonnalInfo,
            paiementMode: paiementMode
        })
        if (validate()) {
            dispatch({
                type: 'SET_AE_INFO', payload: {
                    step: 12,
                    first: generalProcuration.first,
                    second: {
                        first: generalProcuration.second.first,
                        second: generalProcuration.second.second,
                        third: generalProcuration.second.third,
                        fourth: generalProcuration.second.fourth,
                        fifth: generalProcuration.second.fifth,
                        sixth: generalProcuration.second.sixth,
                        seventh: generalProcuration.second.seventh,
                        eighth: generalProcuration.second.eighth,
                        ninth: generalProcuration.second.ninth,
                        tenth: generalProcuration.second.tenth,
                        eleventh: {
                            saveSiren: saveSiren,
                            savePersonnalInfo: savePersonnalInfo,
                            paiementMode: paiementMode
                        }
                    }
                }
            })
            props.nextStep(12)
            props.setCurrentSep(12)
        }
    }
    // , [paiementMode, savePersonnalInfo, saveSiren])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-2">
                <div>
                    {error.paiementMode ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                    <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Souhaitez-vous opter pour le versement libératoire ?</label>
                    <a className='text-radio text-sm font-semibold underline block py-1' href="https://www.economie.gouv.fr/entreprises/micro-entreprise-auto-entreprise-versement-liberatoire-impot-revenu" target='_blank'>Cliquez-ici pour obtenir plus d’information sur le prélèvement libératoire</a>
                </div>
                <RadioGroup row
                    value={paiementMode}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.eleventh ? generalProcuration.second.eleventh.paiementMode : null}
                    name="customized-radios"
                    onChange={handleChangePaiementMode}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex flex-col">
                {error.saveSiren ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Autorisez-vous que vos informations enregistrés dans le répertoire SIREN soit consultable ou utilisable par des tiers ? </label>
                <RadioGroup row
                    value={saveSiren}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.eleventh ? generalProcuration.second.eleventh.saveSiren : null}
                    name="customized-radios"
                    onChange={handleChangeSaveSiren}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
            <div className="flex flex-col">
                {error.savePersonnalInfo ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Autorisez-vous que vos données personnelles puissent être utilisées à des fin de prospection ? </label>
                <RadioGroup row
                    value={savePersonnalInfo}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.eleventh ? generalProcuration.second.eleventh.savePersonnalInfo : null}
                    name="customized-radios"
                    onChange={handleChangeSavePersonnalInfo}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
             <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Eleventh;