import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import CreditCard from "../Objects/models/CreditCard";
import HydraCollection from "../Objects/models/HydraCollection";
import { Address } from "../Objects/models/Address";

export default abstract class CreditCardRepository {
    /**
     * Fetch a Credit Card stored into the API by his id.
     * @param id
     */
    public static fetchCreditCardById(id: number) {
        return ApiService.getSingleInstance<CreditCard>(
            Route.CREDIT_CARD_BASE + "/" + id,
            CreditCard
        );
    }

    /**
     * Creates an instance of CreditCard into the API.
     * @param creditCard
     */
    public static createCreditCard(creditCard: CreditCard) {
        return ApiService.postInstance<CreditCard>(
            Route.CREDIT_CARD_BASE,
            CreditCard,
            creditCard
        );
    }

    /**
     * Returns the default CreditCard
     */
    public static getDefaultCreditCard() {
        return ApiService.getSingleInstance<CreditCard>(
            Route.CREDIT_CARD_BASE + Route.CREDIT_CARD_DEFAULT,
            CreditCard
        );
    }

    /**
     * Delete the specified creditCard
     * @param creditCard
     * @param clientId
     */
    public static deleteCreditCard(creditCard: CreditCard, clientId: number) {
        return ApiService.put(
            `/credit_cards/v2/credit_cards/delete/${clientId}/${creditCard.id}`
        );
    }

    /**
     * Returns all credit cards of the connected user.
     */
    public static getCreditCards(page: number = 1) {
        return ApiService.getCollection<HydraCollection<CreditCard>>(
            Route.CREDIT_CARD_BASE,
            HydraCollection,
            true,
            page
        );
    }

    /**
     * Converts MethodPayment into SetupIntent.
     */
    public static initiateIntent(paymentMethodId: string) {
        return ApiService.postInstance<{
            setupIntentId: string;
            clientSecret: string;
            needAuthentification: string;
        }>(
            Route.CREDIT_CARD_BASE + Route.CREDIT_CARD_INTENT,
            null,
            {
                paymentMethodId: paymentMethodId,
            },
            false
        );
    }
}
