import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';

const CircularStep = (props: any) => {
    const renderCircular = () => {
        if(props.complete) {
            return (<Box sx={{ position: 'relative', display: 'inline-flex', width: "max-content" }}>
                <CircularProgress className='rounded-full' sx={{
                    'color': "#0054A3",
                    'backgroundColor': "#0054A3"
                }} variant="determinate" value={props.complete ? 100 : 0} />
                <Typography
                    className='absolute text-xl font-bold left-[33%] top-[16%] text-white font-nunito text-center'
                    variant="caption"
                    component="span"
                >{props.value >= 4 ? <svg width="15" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.1054 5.86839L16.1843 1.81581C16.4476 1.47364 16.5001 1.02628 16.2895 0.631633C16.1052 0.236967 15.7106 0 15.2895 0H3.49985C2.86822 0 2.36835 0.500053 2.36835 1.1315V22.737H1.1315C0.499865 22.737 0 23.2371 0 23.8685C0 24.5001 0.500053 25 1.1315 25H5.86839C6.50003 25 6.99989 24.4999 6.99989 23.8685C6.99989 23.2369 6.49984 22.737 5.86839 22.737H4.63154L4.63135 11.7369H15.2894C15.7105 11.7369 16.1051 11.5001 16.2894 11.1052C16.4737 10.7106 16.4473 10.263 16.1842 9.92107L13.1054 5.86839Z" fill="#fff" />
                </svg>
                    : props.value}</Typography>
            </Box>)
        } else {
            if (props.active) {
                return (<Box sx={{ position: 'relative', display: 'inline-flex', width: "max-content" }}>
                    <CircularProgress className='rounded-full' sx={{
                        'color': "#0054A3",
                        'backgroundColor': "#fff"
                    }} variant="determinate" value={100} />
                    <Typography
                        className='absolute text-xl font-bold left-[33%] top-[16%] text-first font-nunito text-center'
                        variant="caption"
                        component="span"
                    >{props.value >= 4 ? <svg width="15" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1054 5.86839L16.1843 1.81581C16.4476 1.47364 16.5001 1.02628 16.2895 0.631633C16.1052 0.236967 15.7106 0 15.2895 0H3.49985C2.86822 0 2.36835 0.500053 2.36835 1.1315V22.737H1.1315C0.499865 22.737 0 23.2371 0 23.8685C0 24.5001 0.500053 25 1.1315 25H5.86839C6.50003 25 6.99989 24.4999 6.99989 23.8685C6.99989 23.2369 6.49984 22.737 5.86839 22.737H4.63154L4.63135 11.7369H15.2894C15.7105 11.7369 16.1051 11.5001 16.2894 11.1052C16.4737 10.7106 16.4473 10.263 16.1842 9.92107L13.1054 5.86839Z" fill="#0054A3" />
                    </svg>
                        : props.value}</Typography>
                </Box>)
            } else {
                return (<Box sx={{ position: 'relative', display: 'inline-flex', width: "max-content" }}>
                    <CircularProgress sx={{
                        'color': "#D2D2D2"
                    }} variant="determinate" value={100} />
                    <Typography
                        className='absolute text-xl font-bold left-[33%] top-[16%] text-input-gray font-nunito text-center'
                        variant="caption"
                        component="span"
                    >{props.value >= 4 ? 
                    <svg width="15" height="25" viewBox="0 0 17 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.1054 5.86839L16.1843 1.81581C16.4476 1.47364 16.5001 1.02628 16.2895 0.631633C16.1052 0.236967 15.7106 0 15.2895 0H3.49985C2.86822 0 2.36835 0.500053 2.36835 1.1315V22.737H1.1315C0.499865 22.737 0 23.2371 0 23.8685C0 24.5001 0.500053 25 1.1315 25H5.86839C6.50003 25 6.99989 24.4999 6.99989 23.8685C6.99989 23.2369 6.49984 22.737 5.86839 22.737H4.63154L4.63135 11.7369H15.2894C15.7105 11.7369 16.1051 11.5001 16.2894 11.1052C16.4737 10.7106 16.4473 10.263 16.1842 9.92107L13.1054 5.86839Z" fill="#D2D2D2" />
                    </svg>
                        : props.value}</Typography>
                </Box>)
            }
        }
    }

    return (
        <div>{renderCircular()}</div>
    );
};

export default CircularStep;