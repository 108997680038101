import { Button } from '@mui/material';
import React from 'react';
import success from "../../../../Assets/Icons/success.svg"
import { useNavigate } from 'react-router-dom';
const Last = (props: any) => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col border border-[rgba(146,211,255,0.54)] shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-4'>
            <div className='flex flex-col mx-auto w-max gap-y-3 ' style={{ background: "radial-gradient(50% 50.00% at 50% 50.00%, #B9D0FF 0%, rgba(242, 245, 251, 0.00) 100%)" }}>
                <img src={success} alt="" className='w-2/3 mx-auto'  />
                <p className='text-first font-extrabold lg:text-[22px] text-lg text-center'>FÉLICITATIONS</p>
            </div>
            <div className="flex flex-col mx-auto gap-y-4 md:w-2/3">
                <div>
                    <p className='text-base font-bold text-center text-first lg:text-lg'>Nous avons reçu les deux documents que vous nous avez envoyé.</p>
                    <p className='text-base font-bold text-center text-first lg:text-lg'>Notre équipe de vérification vous fera un retour d’ici 2 jours ouvrés.</p>
                </div>
                <p className='text-base font-bold text-center text-first lg:text-lg'>Passé ce délai, si vous n’avez pas de retour de notre part, vous pouvez nous contacter au 01.84.80.26.15 ou par mail à l’adresse support@teachr.fr
                </p>
            </div>
            <div className="flex justify-end pt-8">
                <Button onClick={() => navigate('/teachr/cours')} className='px-8 py-2 font-extrabold text-white rounded-full bg-radio w-full lg:w-max'>SUIVANT </Button>

            </div>
        </div>
    );
};

export default Last;