import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import { PunctualCourse } from "../Objects/models/PunctualCourse";
import HydraCollection from "../Objects/models/HydraCollection"
import { plainToClass } from "class-transformer";
import { PunctualCourseDashboard } from "../Objects/models/PunctualCourseDashboard";
import Charges from "../Objects/models/Charges";
import { PunctualCourseDashboardUpdate, PunctualCoursePassed } from "../Objects/models/PunctualCourseDashboardUpdate";
import UsrssafStatusService from "../Objects/models/UrssafStatusService";

interface passeCoursePunctualModel {
  isPassedCourse: boolean
}

export default abstract class PunctualCourseRepository {
  /**
   * Fetch a punctualCourse stored into the API by his id.
   * @param id
   */
  public static fetchPunctualCourseById(id: number) {
    return ApiService.getSingleInstance<PunctualCourse>(
      Route.PUNCTUAL_COURSE_BASE + "/" + id,
      PunctualCourse
    );
  }

  /**
   * Creates an instance of punctualCourse into the API.
   * @param punctualCourse
   */
  public static createPunctualCourse(punctualCourse: PunctualCourse) {
    return ApiService.postInstance<PunctualCourse>(
      Route.PUNCTUAL_COURSE_BASE,
      PunctualCourse,
      punctualCourse
    );
  }

  /**
   * Creates an instance of punctualCourse into the API by teacher.
   * @param punctualCourseDashboard
   */
  // public static createPunctualCourseByTeacher(punctualCourseDashboard: PunctualCourseDashboard) {
  //     return ApiService.postInstance<PunctualCourseDashboard>(
  //         Route.PUNCTUAL_COURSE_BASE_TEACHR,
  //         PunctualCourseDashboard,
  //         punctualCourseDashboard
  //     );
  // }
  // public static createPunctualCourseByTeacher(punctualCourseDashboard: PunctualCourseDashboard):Promise<unknown> {
  //     return ApiService.post(`/teachrs/punctual_courses`, punctualCourseDashboard);
  // }

  /**
   * Updates the instance of the punctualCourse passed as parameter.
   * @param punctualCourse
   */
  public static updatePunctualCourse(punctualCourse: PunctualCourse) {
    return ApiService.putInstance<PunctualCourse>(
      Route.PUNCTUAL_COURSE_BASE + "/" + punctualCourse.id,
      PunctualCourse,
      punctualCourse
    );
  }

  /**
   * Creates an instance of punctualCourse into the API by teacher.
   * @param punctualCourseDashboard
   */
  public static createPunctualCourseByTeacher(
    punctualCourseDashboard: PunctualCourseDashboard
  ) {
    return ApiService.postInstance<PunctualCourseDashboard>(
      Route.PUNCTUAL_COURSE_BASE_TEACHR,
      PunctualCourseDashboard,
      punctualCourseDashboard
    );
  }

  public static async tryAcceptPunctualCourseByTeachr(
    client_id: number,
    course_proposal_id: number
  ) {
    return ApiService.put(
      `/teachrs/v2/teachrs/${client_id}/course_proposals/${course_proposal_id}/try-accept`
    );
  }

  /**
   * Removes the instance of the punctualCourse passed as parameter.
   * @param punctualCourse
   */
  public static deletePunctualCourse(punctualCourse: PunctualCourse) {
    return ApiService.deleteInstance(
      Route.PUNCTUAL_COURSE_BASE + "/" + punctualCourse.id
    );
  }

  /**
   * Returns all punctualCourses of the connected user.
   */
  public static getPunctualCourses(
    page: number = 1,
    filters: Array<string> | null
  ) {
    return ApiService.getCollection<HydraCollection<PunctualCourse>>(
      Route.PUNCTUAL_COURSE_BASE,
      HydraCollection,
      true,
      page,
      filters
    );
  }

  public static getAllAvailablePunctualCourse(teachrId: number) {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/available_punctual_courses`
    );
  }

  public static downloadInvoice(chargeId: number) {
    return ApiService.get(`/teachrs/download/invoice/${chargeId}`);
  }

  public static async tryAcceptPunctualCourse(
    client_id: number,
    course_proposal_id: number
  ) {
    return ApiService.put(
      `/clients/v2/clients/${client_id}/course_proposals/${course_proposal_id}/try-accept`
    );
  }

  public static async firstCourseDashboard(teachr: number) {
    return ApiService.put(`/teachrs/${teachr}/first_course_dashboard`);
  }

  public static async acceptCourseProposalAfter3dSecure(
    client_id: number,
    course_proposal_id: number
  ) {
    return ApiService.put(
      `/clients/v2/clients/${client_id}/course_proposals/${course_proposal_id}/accept-after-3d-secure`
    );
  }

  public static async deletePunctualByTeachrDashboard(course: number) {
    return ApiService.put(`/teachrs/${course}/delete`);
  }

  public static getPunctualCoursesToNoteClient(
    clientId: any
  ): Promise<PunctualCourse | PunctualCourse[]> {
    return ApiService.get(
      `/clients/v2/clients/${clientId}/punctual_courses_to_note`
    ).then((body) => plainToClass(PunctualCourse, body));
  }

  public static getPunctualCoursesToNoteTeachr(
    teachrId: any
  ): Promise<PunctualCourse | PunctualCourse[]> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/punctual_courses_to_note`
    ).then((body) => plainToClass(PunctualCourse, body));
  }

  public static getNumberOfAvailablePunctualCourses(clientId: any) {
    return ApiService.get(
      `/clients/v2/clients/${clientId}/premium/allowed-punctual-courses`
    );
  }

  public static getPendingPunctualCourse(
    clientId: Number
  ): Promise<PunctualCourse[]> {
    return ApiService.get(
      `/clients/v2/clients/${clientId}/clients_get_pending_punctual_courses`
    ).then((body) => plainToClass(PunctualCourse, body));
  }

  public static getActivePunctualCourse(
    clientId: number
  ): Promise<PunctualCourse[]> {
    return ApiService.get(
      `/clients/v2/clients/${clientId}/active_punctual_courses`
    ).then((body) => plainToClass(PunctualCourse, body));
  }

  public static getPastPunctualCourse(
    clientId: number
  ): Promise<PunctualCourse[]> {
    return ApiService.get(
      `/clients/v2/clients/${clientId}/historical_punctual_courses`
    ).then((body) => plainToClass(PunctualCourse, body));
  }

  public static getPunctualCourseByTeachr(
    teachrId: Number,   page: number = 1,
  ): Promise<any> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/teachrs_get_punctual_courses?page=${page}`);
  }
  public static getCourseNextMonth(teachrId: Number) {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/teachr_get_course_previous_month`
    );
  }
  public static teachrGetCourseForDashboard(teachrId: Number) {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/teachr_get_course_for_dashboard`
    );
  }

  // public static getPunctualCoursePastByTeachr(teachrId: Number): Promise<any> {
  //   return ApiService.get(
  //     `/teachrs/v2/teachrs/${teachrId}/teachrs_get_punctual_courses_past`
  //   ).then((body) => plainToClass(PunctualCourseDashboard, body));
  // }

  public static searchPunctualCoursePastByTeachr(
    teachrId: Number,
    terms: string
  ): Promise<any> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/search/${terms}`
    ).then((body) => plainToClass(PunctualCourseDashboard, body));
  }
  public static searchPunctualCoursePastByPeriod(
    teachr: Number,
    startDate: string,
    endDate: string
  ): Promise<any> {
    return ApiService.get(
      `/teachrs/v2/search/${teachr}/periode/${startDate}/${endDate}`
    ).then((body) => plainToClass(PunctualCourseDashboard, body));
  }

  public static searchPunctualCoursePastByTeachrNew(
    page: number = 1,
    teachrId: number,
    name: string,
    subject: string,
    startDate: any,
    endDate: any
  ): Promise<any> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/search?page=${page}&matiere=${subject}&name=${name}&startDate=${startDate}&endDate=${endDate}`
    );
  }
  public static getMontantFacture(
    teachrId: number,
    startDate: any,
    endDate: any
  ): Promise<any> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/amount?startDate=${startDate}&endDate=${endDate}`
    );
    // .then(body => plainToClass(PunctualCourseDashboard, body));
  }

  public static getUrssafStatusIdForPunctualByTeachr(
    teachrId: Number
  ): Promise<UsrssafStatusService[]> {
    return ApiService.get(
      `/teachrs/v2/teachrs/${teachrId}/get_urssaf_status`
    ).then((body) => plainToClass(UsrssafStatusService, body));
  }

  public static getUsrssafStatusServiceIdById(id: number) {
    return ApiService.getSingleInstance<UsrssafStatusService>(
      `/teachrs/${id}/get_one_urssaf_status`,
      UsrssafStatusService
    );
  }

  /**
   * Fetch a punctualCourse stored into the API by his id.
   * @param id
   */
  public static fetchChargePunctualCourseById(id: number) {
    return ApiService.getSingleInstance<Charges>(
      Route.CHARGES_PUNCTUAL_COURSE_BASE + "/" + id,
      Charges
    );
  }

  /**
   * Updates the instance of the punctualCourse passed as parameter.
   * @param punctualCourse
   */
  public static updatePunctualCourseByTeachr(
    courseId: number,
    punctualCourse: PunctualCourseDashboardUpdate
  ) {
    return ApiService.putInstance<PunctualCourseDashboardUpdate>(
      Route.PUNCTUAL_COURSE_BASE_UPDATE_TEACH_R +
        "/" +
        courseId +
        Route.PUNCTUAL_COURSE_BASE_UPDATE_TEACH_R_,
      PunctualCourseDashboardUpdate,
      punctualCourse
    );
  }
  
  /**
   * set course passed 
   */
  public static setCoursePassed( courseId: number, coursePassed: PunctualCoursePassed) {
    return ApiService.putInstance<PunctualCoursePassed>(`/teachrs/v2/teachrs/${courseId}/course_passed`,PunctualCoursePassed, coursePassed);
  }
}
