import loaderImage from "../Assets/Icons/loader.gif";
import React from "react";
export default function LoaderContext() {
  
  return (
    <div className="fixed mx-auto  z-50 hidden w-full h-full text-white bg-[rgba(0,0,0,0.3)]" id="teachr-loader">
      <div className="absolute text-center w-max lg:left-1/2 left-1/3 top-1/2">
        <img src={loaderImage} width= '100' alt="loader" className="mx-auto" />
      </div>
    </div>
  );
}
