
const SHOW_SUCCESS = 'SHOW_SUCCESS';
const SHOW_ERROR = 'SHOW_ERROR';
const SHOW_WARNING = 'SHOW_WARNING';
const SHOW_INFO = 'SHOW_INFO';
const HIDE_MESSAGE = 'HIDE_MESSAGE';

const initialState = {
  message: '',
  type: '',
};

export default  function messageReducer(state = initialState, action:any) {
    switch (action.type) {
      case SHOW_SUCCESS:
        return { message: action.payload, type: 'success' };
      case SHOW_ERROR:
        return { message: action.payload, type: 'error' };
      case SHOW_WARNING:
        return { message: action.payload, type: 'warning' };
      case SHOW_INFO:
        return { message: action.payload, type: 'info' };
      case HIDE_MESSAGE:
        return initialState;
      default:
        return state;
    }
  }