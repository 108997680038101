import { Button } from '@mui/material';
import React from 'react';

const Renseignement = (props:any) => {
    return (
        <div>
            <h2 className='text-base font-extrabold text-first lg:text-2xl'>Renseignements personnels</h2>
            <div className="flex flex-col pt-4 gap-y-8">
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>
                    Indiquez ensuite toutes vos informations liées à votre auto-entreprise.
                    Si vous avez un doute, le numéro <span className="font-bold">NIC </span> correspond au 5 derniers chiffres du numéro de SIRET.
                </p>
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>
                    Vous n’avez pas à remplir la partie “Adhesion”.
                </p>
            </div>
            <div className="absolute left-0 flex justify-between w-full pt-4 md:px-8">
                
            <Button onClick={() => props.getStep(5)} className='px-8 font-extrabold uppercase rounded-full text-[#B7B7B7] lg:text-xl'>PRÉCEDENT</Button>
                <Button onClick={()=> props.getStep(7)} className='px-8 font-extrabold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
            </div>
        </div>
    );
};

export default Renseignement;