import "reflect-metadata";

export default class HydraView {
    readonly "@id": string;
    readonly "@type": string;
    readonly "hydra:first": string;
    readonly "hydra:last": string;
    readonly "hydra:next"?: string;

    /**
     * Returns if there is a next page or not.
     * @return boolean
     */
    public isThereANextPage(): boolean {
        if (this.getCurrentPage() === undefined) {
        // it means there is any page
            return false;
        } else {
            if (this.getCurrentPage() === this.getLastPage()) {
                // it's already the last page
                return false;
            } else {
                // it's not the last page
                return true;
            }
        }
    }

    /**
     * Returns the current page number.
     * If undefined, it means there is any page for this collection.
     */
    public getCurrentPage(): number {
        return HydraView.getPageNumber(this["@id"]);
    }

    /**
     * Returns the first page number
     */
    public getFirstPage(): number {
        return HydraView.getPageNumber(this["hydra:first"]);
    }

    /**
     * Returns the next page number
     */
    public getNextPage(): number | undefined {
        return this["hydra:next"]
            ? HydraView.getPageNumber(this["hydra:next"])
            : undefined;
    }

    /**
     * Returns the last page number
     */
    public getLastPage(): number {
        return HydraView.getPageNumber(this["hydra:last"]);
    }

    /**
     * Returns the GET page parameter in an URL. If undefined (meaning there is any page system, it returns 1).
     * @example /addresses?default=1&param=test&page=159 will return 159
     * @param str
     */
    private static getPageNumber(str: string): number {
        let regexPattern: RegExp = /page\=(\d+)/;
        let match = regexPattern.exec(str);
        return match && match[1] ? Number(match[1]) : 1;
    }
}
