import React, { useEffect } from 'react';
import RadioButton from '../../../../../Components/utils/RadioButtonClient';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Avatar, Radio } from '@mui/material';
import ClientRepository from '../../../../../Repositories/ClientRepository';
import Swal from "sweetalert2";
import { capitalizeFirstLetter, extractDateTimeFromString } from "../UtilsDashboard/FunctionsDashbord"
import StepperStore from '../../../../../LocalForage/StepperStore';
import moment from "moment";
import AlertInfo from '../UtilsDashboard/AlertInfo';
import { toNumber } from 'lodash';
let timerInterval: any;


const ComponentChildDeclareCourse = (props: any) => {
  const { child, handleOk, handleClickOpen, handleLoad, setLoad, load } = props;

  const handleRefresh = () => {
    ClientRepository.refreshStatusTdp(child?.client_id).then((res) => {
      setLoad(true)
      if (res) {
        if (!child?.is_teachr_contact && (!child?.price_per_hour || !child?.fees_per_hour)) {
          handleOk();
          setLoad(false);
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: " Cet ajout est en attente de validation par un administrateur. Veuillez patienter environ deux jours pour la confirmation ou contactez le service client pour plus d'informations.",
            // timer: 5000,
            willClose: () => {
              clearInterval(timerInterval);
              handleClickOpen();
              // window.location.reload();
            },
          });
        } else {
          if (res?.etat === 'OK') {
            setLoad(true);
            const dateTime = extractDateTimeFromString(res?.description);
            const date = moment()
            const dateTimeTdpMoment = moment(dateTime, "DD/MM/YYYY HH:mm:ss")
            const secondTime = toNumber(dateTimeTdpMoment.format("ss"));
            const dateSetSecond = date.set("second", secondTime);
            if (date.format("DD/MM/YYYY") === dateTimeTdpMoment.format("DD/MM/YYYY")) {
              if (date.diff(dateTimeTdpMoment, "hours", true) <= 2) {
                const diffMinutes = dateSetSecond.diff(dateTimeTdpMoment, "minutes");
                const hours = Math.floor(diffMinutes / 60);
                const minutes = diffMinutes % 60;

                let min: number = 0
                let hour = 1 - hours
                if (minutes === 0 && hour > 0) {
                  min = 0;
                } else {
                  min = 60 - minutes;
                }
                handleOk();
                setLoad(false)
                const showTime = () => {
                  let nwMin
                  let nwHour
                  if (min.toString().length === 1) {
                    nwMin = `0${min}`;
                  } else {
                    nwMin = min;
                  }
                  if (hour.toString().length === 1) {
                    nwHour = `0${hour}`;
                  } else {
                    nwHour = hour;
                  }
                
                  return `${nwHour}h${nwMin}`;
                }
              
                Swal.fire({
                  icon: "warning",
                  title: "Attention !",
                  text: `Vous ne pouvez pas déclarer de cours pour ${capitalizeFirstLetter(child?.child_first_name)} ${child?.user_last_name.toUpperCase()} actuellement, car son compte a été validé à ${dateTimeTdpMoment.format("HH")}h${dateTimeTdpMoment.format("mm")}. Veuillez attendre et réessayer de faire votre déclaration dans ${showTime()}.`,
                  //  timer: 5000,
                  willClose: () => {
                    clearInterval(timerInterval);
                    handleClickOpen();
                  },
                });
              } else {
                setLoad(false);
                StepperStore.setItem("description_tdp" + child?.client_id, child?.description_tdp);
              }
            } else {
              setLoad(false);
              StepperStore.setItem("description_tdp" + child?.client_id, child?.description_tdp);
            }
          } else {
            handleOk();
            Swal.fire({
              icon: "error",
              title: "Erreur",
              text: `Le compte de votre client  ${capitalizeFirstLetter(child?.child_first_name)} ${child?.user_last_name.toUpperCase()}  a été bloqué pour le moment. Vous ne pouvez plus déclarer de cours à cet élève pour l'instant. Vous allez prochaiment recevoir un mail de notre part pour vous expliquer la situation. Merci de votre compréhension.`,
              //  timer: 5000,
              willClose: () => {
                clearInterval(timerInterval);
                handleClickOpen();
              },
            });
          }
        }
      }
    }).catch((er) => {
      setLoad(false)
      handleOk()
      console.warn(er);
      if (er.message === "Invalid Authorization") {
        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: er.message,
          timer: 5000,
          willClose: () => {
            clearInterval(timerInterval);
            // window.location.reload();
          },
        });
      } else {

        Swal.fire({
          icon: "error",
          title: "Erreur",
          text: "Une erreur inconnue, veuillez contactez le service client",
          timer: 5000,
          willClose: () => {
            clearInterval(timerInterval);
            // window.location.reload();
          },
        });
      }
    })
  }

  const showLabel = () => {
    return (
      <div className="font-nunito w-full flex items-center gap-x-4 lg:text-lg md:text-base text-[#4d4d4d]">
        <Avatar
          sx={{
            bgcolor:
              child !== undefined
                ? !child?.is_teachr_contact && (!child?.price_per_hour || !child?.fees_per_hour)
                  ? "#ff9647"
                  : "#0054A3"
                : "#0054A3",
            color: "white",
          }}
        >
          {child?.child_first_name.charAt(0).toUpperCase() +
            child?.user_last_name.charAt(0).toUpperCase()}
        </Avatar>
        {/* </div> */}
        {child !== undefined ? (!child?.is_teachr_contact && (!child?.price_per_hour || !child?.fees_per_hour)? (
            <div className="font-bold block">
                <AlertInfo title={`${capitalizeFirstLetter(child?.child_first_name)} ${child?.user_last_name.toUpperCase()}`} msg="Veuillez attendre la validation d'un administrateur, cela prendra en moyenne deux jours" info='warning' />
            </div>
          ) : (
            <div className="font-bold">
              {capitalizeFirstLetter(child?.child_first_name) +
                " " +
                child?.user_last_name.toUpperCase()}
            </div>
          )
        ) : (
          <div className="font-bold">
            {capitalizeFirstLetter(child?.child_first_name) +
              " " +
              child?.user_last_name.toUpperCase()}
          </div>
        )}
      </div>
    );
  }


  return (
    <>
      {child?.etat_tdp === "OK" && !child?.is_archive && (
        <div className='mt-1.5'> 
          <FormControlLabel
            onClick={handleRefresh}
            value={child?.child_id}
            key={child?.child_id}
            label={showLabel()}
            control={!child?.is_teachr_contact && (!child?.price_per_hour || !child?.fees_per_hour) ? <Radio  sx={{color: '#ff9647','&.Mui-checked': {color: '#ff9647',},}}/> : <RadioButton />}
            labelPlacement="end"
          />
        </div>
      )}
    </>
  );
};

export default ComponentChildDeclareCourse;