import { Link } from "@mui/material";
import React, {  useEffect, useState } from "react";
import logo_blanc from "../../Assets/Images/logo_blanc.svg";
import logobleu from "../../Assets/Images/logo_bleu.png"
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Services/FirebaseService";
import ApiService from "../../Services/ApiService";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
function NavbarHomePage(props: any) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const dispatch = useDispatch()
   
  const logout = () => {
    signOut(auth).then(() => {
      ApiService.disableAuth()
      dispatch({
        type: 'DELETE_TEACHR_INFO'
    })
      navigate("/")
    }).catch((error) => {
    });
  }

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Nettoyage de l'écouteur d'événements lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); 

  
  return (
    <div className="">
      {props.theme === "light" ? (
        <div
          className={`transition-['max-height'] ease-in-out duration-500 py-[10px] px-[14px] w-full z-50 fixed top-0 ${
            scrolling ? "bg-first" : "bg-white lg:bg-transparent"
          }`}
          // className="  transition-['max-height'] ease-in-out duration-500 py-[10px] px-[14px] w-full z-50 /fixed /top-0 "
        >
          <div className="flex items-center justify-between p-4 ">
            <div className="hidden lg:block">
              {scrolling ? (
                <img
                  className="cursor-pointer w-[129px]"
                  onClick={() => navigate("/")}
                  src={logo_blanc}
                  alt=""
                />
              ) : (
                <img
                  className="cursor-pointer w-[129px] "
                  onClick={() => navigate("/")}
                  src={logobleu}
                  alt=""
                />
              )}
            </div>
            <div className="lg:hidden">
              {scrolling ? (
                <img
                  className="cursor-pointer w-[84px]"
                  onClick={() => navigate("/")}
                  src={logo_blanc}
                  alt=""
                />
              ) : (
                <img
                  className="cursor-pointer w-[84px]"
                  onClick={() => navigate("/")}
                  src={logobleu}
                  alt=""
                />
              )}
            </div>
            <div
              className={`lg:hidden ${scrolling ? "text-white" : "text-first"}`} >
              <MenuIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></MenuIcon>
            </div>
            <div className="items-center hidden gap-5 lg:flex">
              <Link
                href="/user/formlayout"
                underline="none"
                className={`font-extrabold xl:text-base uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className={`font-extrabold xl:text-base uppercase  ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className=" border-2 rounded-full bg-first py-1 px-4 uppercase text-white font-extrabold xl:text-base"
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className=" border-2 rounded-full bg-first py-1 px-4 uppercase text-white font-extrabold xl:text-base"
                >
                  Connexion
                </Link>
              )}
            </div>
          </div>
          {toggle ? (
            <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
              <Link
                href="/user/formlayout"
                underline="none"
                // className=" text-first font-extrabold"
                className={`font-extrabold uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className={`font-extrabold uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className={`font-extrabold uppercase ${
                    scrolling ? "text-white" : "text-first"
                  }`}
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className={`font-extrabold uppercase ${
                    scrolling ? "text-white" : "text-first"
                  }`}
                >
                  Connexion
                </Link>
              )}
            </div>
          ) : null}
        </div>
      ) : props.theme === "dark" ? (
        <div
          className="lg:bg-transparent  transition-['max-height'] ease-in-out duration-500 py-[10px] px-[14px] w-full z-50 /fixed /top-0 "
        >
          <div className="flex items-center justify-between p-4 ">
            <div className="hidden lg:block">
              <img
                className="cursor-pointer w-[129px]"
                onClick={() => navigate("/")}
                src={logo_blanc}
                alt=""
              />
            </div>
            <div className="lg:hidden">
              <img
                className="cursor-pointer w-[84px]"
                onClick={() => navigate("/")}
                src={logo_blanc}
                alt=""
              />
            </div>
            <div className="lg:hidden text-white">
              <MenuIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></MenuIcon>
            </div>
            <div className="items-center hidden gap-5 lg:flex">
              <Link
                href="/user/formlayout"
                underline="none"
                className=" text-white font-extrabold xl:text-base"
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className=" text-white font-extrabold xl:text-base uppercase"
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className=" border-2 rounded-full bg-white py-1 px-4 uppercase text-first font-extrabold xl:text-base"
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className=" border-2 rounded-full bg-white py-1 px-4 uppercase text-first font-extrabold xl:text-base"
                >
                  Connexion
                </Link>
              )}
            </div>
          </div>
          {toggle ? (
            <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
              <Link
                href="/user/formlayout"
                underline="none"
                className=" text-white font-extrabold"
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className=" text-white font-extrabold uppercase"
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className=" text-white font-extrabold uppercase"
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className=" uppercase text-white font-extrabold"
                >
                  Connexion
                </Link>
              )}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={`transition-['max-height'] ease-in-out duration-500 py-[10px] px-[14px] w-full z-50 fixed top-0 ${
            scrolling
              ? "bg-first text-white"
              : "bg-gradient-to-l from-[#219CFF]"
          }`}
          // className="bg-gradient-to-l from-[#219CFF] /lg:bg-transparent  transition-['max-height'] ease-in-out duration-500 py-[10px] px-[14px] w-full /z-50 /fixed /top-0 "
        >
          <div className="flex items-center justify-between p-4 ">
            <div className="hidden lg:block">
              {scrolling ? (
                <img
                  className="cursor-pointer w-[129px]"
                  onClick={() => navigate("/")}
                  src={logo_blanc}
                  alt=""
                />
              ) : (
                <img
                  className="cursor-pointer w-[129px] "
                  onClick={() => navigate("/")}
                  src={logobleu}
                  alt=""
                />
              )}
            </div>
            <div className="lg:hidden">
              {scrolling ? (
                <img
                  className="cursor-pointer w-[84px]"
                  onClick={() => navigate("/")}
                  src={logo_blanc}
                  alt=""
                />
              ) : (
                <img
                  className="cursor-pointer w-[84px]"
                  onClick={() => navigate("/")}
                  src={logobleu}
                  alt=""
                />
              )}
            </div>
            <div className="lg:hidden text-white">
              <MenuIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></MenuIcon>
            </div>
            <div className="items-center hidden gap-5 lg:flex">
              <Link
                href="/user/formlayout"
                underline="none"
                className={`font-extrabold xl:text-base uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className={`font-extrabold xl:text-base uppercase  ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className=" border-2 rounded-full bg-white py-1 px-4 text-first uppercase text-first font-extrabold xl:text-base"
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className=" border-2 rounded-full bg-white py-1 px-4 uppercase text-first font-extrabold xl:text-base"
                >
                  Connexion
                </Link>
              )}
            </div>
          </div>
          {toggle ? (
            <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
              <Link
                href="/user/formlayout"
                underline="none"
                // className=" text-first font-extrabold"
                className={`font-extrabold uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="/aici/infos"
                underline="none"
                className={`font-extrabold uppercase ${
                  scrolling ? "text-white" : "text-first"
                }`}
              >
                Qu’est-ce que l’avance immédiate ?
              </Link>
              {user ? (
                <Link
                  onClick={() => logout()}
                  underline="none"
                  className={`font-extrabold uppercase ${
                    scrolling ? "text-white" : "text-first"
                  }`}
                >
                  {" "}
                  DéCONNEXION
                </Link>
              ) : (
                <Link
                  href="/teachr/login"
                  underline="none"
                  className={`font-extrabold uppercase ${
                    scrolling ? "text-white" : "text-first"
                  }`}
                >
                  Connexion
                </Link>
              )}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NavbarHomePage;
