import React, { useEffect, useState } from 'react'
import iconGoogle from '../../../Assets/Images/google_icon.svg'
import { Button, Checkbox } from '@mui/material'
import validateEmail from '../../../Validation/Email';
import validatePassword from '../../../Validation/Password'
import { useNavigate } from "react-router-dom"
import { auth, FirebaseAuthServiceErrorCode, getJWTToken, mailSendMailVerification, registerWithEmailAndPassword, signInWithGoogle } from '../../../Services/FirebaseService'
import { useAuthState } from 'react-firebase-hooks/auth'
import ToolTip from '../../../Components/utils/ToolTip'
import { validatePhoneNumber } from '../../../Validation/Phone'
import NavBar from '../../../Components/Navbar/NavBar'
import StepperStore from '../../../LocalForage/StepperStore';
import Loader from '../../../Components/utils/Loader';
import TeachrRepository from '../../../Repositories/TeachrRepository';
import ApiService from '../../../Services/ApiService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Functions from '../../../Helpers/Functions';
import { browserSessionPersistence } from 'firebase/auth';
import Teachr from '../../../Objects/models/Teachr';
import { useDispatch } from 'react-redux';
import NavbarHomePage from '../../../Components/Navbar/NavbarHomePage';

export default function Register() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const [teachr, setTeachr] = useState<Teachr>()
  const [show, setShow] = useState(true)
  //check if user exist //
  useEffect(() => {
    if (user) {
      (async () => {
        const jwtToken = await getJWTToken(user)
        ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
        TeachrRepository.getCurrentTeachr().then((response) => {
          dispatch({
            type: 'SET_TEACHR_INFO', 
            payload: {
              teachr: response,
              file: undefined,
              authorization: ""
            }
          })
          navigate('/teachr/cours')
        }).catch((error) => {
          ApiService.disableAuth()
          let displayName = [""]
          if (user.providerData[0].providerId === "google.com") {
            if (user.providerData[0].displayName) {
              displayName =user.providerData[0].displayName.split(" ")
            }
            const teachrObject = {
              birthday: "",
              description: "",
              firebaseAuthToken: "",
              firebaseMessagingToken: "firebaseMessagingToken",
              firstName: displayName.length >  0 ? displayName[0] : "",
              lastName: displayName.length >=  1 ? displayName[1] : "",
              mail: user.providerData[0].email,
              phone: "",
              sex: "",
              whereStudies: "",
              yearInStudies: 0,
              isTdae : form.isTdae
            }
            dispatch({
              type: 'SET_TEACHR_INFO', 
              payload: {
                teachr: teachrObject,
                file: undefined,
                authorization: ""
              }
            })
          }else{
            dispatch({
              type: 'SET_TEACHR_INFO', 
              payload: {
                teachr: teachr,
                file: undefined,
                authorization: ""
              }
            })
          }
          if (!user.emailVerified) {
            mailSendMailVerification(user!).then(() => navigate('/teachr/register/next'))
          } else {
            navigate('/teachr/register/next')
          }
        })
      })()
      // navigate('/register/next')
    }
  }, [ teachr, user]);

  //end Check if user exist

  // definitions//


  const Swal = require('sweetalert2')
  let timerInterval: any
  const [showPass, setShowPass] = useState(false)
  const [showCPass, setShowCPass] = useState(false)
  const [remember, setRemember] = useState(false)
  const [form, setForm] = useState({
    email: '',
    password: '',
    cpassword: '',
    phone: '',
    terms: false,
    ccg: false,
    isTdae: false
  })
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    phone: '',
    terms: '',
    ccg: ''
  })

  // end Definitions //

  //end get //

  //validation //

  //end Validation //
  const validateTerms = () => {
    if (!form.terms) {
      return {
        isValid: false,
        message: 'Veuillez accepter les conditions d’utilisation et la politique des données'
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }
  const validateCcg = () => {
    if (!form.ccg) {
      return {
        isValid: false,
        message: 'Veuillez accepter les  CGUs et CGVs'
      }
    }
    return {
      isValid: true,
      message: ''
    }
  }
  const validate = () => {
    let erremail = true
    let errpass = true
    let errterms = true
    let errccg = true
    if (!validateEmail(form.email).isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      erremail = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      erremail = false
    }
    if (!validatePassword(form.password, form.cpassword).isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errpass = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errpass = false
    }
    if (!validateTerms().isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errterms = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errterms = false
    }
    if (!validateCcg().isValid) {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errccg = true
    } else {
      setErrors({
        email: validateEmail(form.email).message,
        password: validatePassword(form.password, form.cpassword).message,
        terms: validateTerms().message,
        phone: validatePhoneNumber(form.phone),
        ccg: validateCcg().message
      })
      errccg = false
    }


    if (errpass || erremail || errterms || errccg || validatePhoneNumber(form.phone) !== '')
      return false
    else return true
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (validate()) {
      setShow(false)
      const teachrObject = {
        birthday: "",
        description: "",
        firebaseAuthToken: "",
        firebaseMessagingToken: "firebaseMessagingToken",
        firstName: "",
        lastName: "",
        mail: "",
        phone: "",
        sex: "",
        whereStudies: "",
        yearInStudies: 0,
        // isTdae : false
      }
      teachrObject.mail = form.email
      // teachrObject.isTdae = form.isTdae
      teachrObject.phone = Functions.formatPhoneNumber(form.phone)
      setTeachr(teachrObject)
      document.getElementById('teachr-loader')?.classList.remove('hidden')
      const request = registerWithEmailAndPassword(form.email, form.password)
      if (remember) {
        request.then((response: any) => {
          StepperStore.setItem('register', {
            email: form.email,
            phone: Functions.formatPhoneNumber(form.phone)
          })
        }).catch((error) => {
          setShow(true)
          ApiService.disableAuth()
          document.getElementById('teachr-loader')?.classList.add('hidden')
          switch (error.code) {
            case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: "Cet email est déjà utilisé !",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              })
              break;

            default:
              Swal.fire({
                icon: 'warning',
                title: 'Oops...',
                text: "quelque chose s'est mal passé!",
                timer: 2000,
                willClose: () => {
                  clearInterval(timerInterval)
                }
              })
              break;
          }
        })

      } else {
        auth.setPersistence(browserSessionPersistence).then(() => {
          request.then((response: any) => {
            StepperStore.setItem('register', {
              email: form.email,
              phone: Functions.formatPhoneNumber(form.phone)
            })
          }).catch((error) => {
            ApiService.disableAuth()
            document.getElementById('teachr-loader')?.classList.add('hidden')
            switch (error.code) {
              case FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE:
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: "Cet email est déjà utilisé !",
                  timer: 2000,
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
                break;

              default:
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: "quelque chose s'est mal passé!",
                  timer: 2000,
                  willClose: () => {
                    clearInterval(timerInterval)
                  }
                })
                break;
            }
            setShow(true)
          })
        })
      }


    }



  }
  return (
    <div className='w-screen overflow-x-hidden bg-center bg-no-repeat bg-cover md:h-screen md:overflow-y-auto bg-register'>
      <div className="overflow-x-hidden bg-right-bottom bg-no-repeat bg-30% md:h-screen md:overflow-y-auto lg:bg-bg-home-right">
        <Loader />
        <NavbarHomePage theme='dark' />
        <p className='lg:px-12 px-5 my-2 md:leading-[61px] md:text-[45px] leading-[30px] text-[22px] text-white font-extrabold py-4'>Donner des cours particuliers, c’est pas sorcier !</p>
        <div className="flex justify-between ">
          <div className='w-full px-5 mb-14 md:w-3/4 lg:w-1/2 lg:px-12'>
            <Button onClick={signInWithGoogle} className='flex items-center justify-center px-4 py-2 text-black no-underline bg-white rounded-md font-nunito gap-x-3 w-max'><img src={iconGoogle} alt="" /> S’inscrire avec Google</Button>
            <div className="flex items-center justify-between gap-4 py-8 text-white"><hr className='w-full' /><span className='w-full whitespace-nowrap'>ou alors en renseignant ces informations :</span><hr className='w-full' /></div>
            <div className="flex flex-col gap-4 lg:gap-4 md:gap-8">
              <div className="flex flex-col md:flex-row gap-y-6 gap-x-4">
                <div className="w-full md:w-1/2">
                  <ToolTip text={errors.email}>
                    <div className={errors.email === '' ? 'bg-white rounded-md py-2 px-3 ' : 'bg-white rounded-md py-2 px-3 border-2 border-red-600 '}>
                      <label htmlFor="">Email</label>
                      <input type="text" className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: e.target.value, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: form.ccg , isTdae: form.isTdae })} placeholder='mail@mail.com' />
                    </div>
                  </ToolTip>
                </div>
                <div className="w-full md:w-1/2">
                  <ToolTip text={errors.phone}>
                    <div
                      className={
                        errors.phone === ''
                          ? "bg-white rounded-md py-2 px-3 w-full"
                          : "bg-white rounded-md py-2 px-3 w-full border-2 border-red-600"
                      }
                    >
                      <label htmlFor="">Numéro de téléphone</label>
                      <input
                        type="tel"
                        onChange={(e) => {
                          setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: e.target.value, terms: form.terms, ccg: form.ccg , isTdae: form.isTdae });
                          setErrors({
                            email: errors.email,
                            password: errors.password,
                            terms: errors.terms,
                            phone: validatePhoneNumber(e.target.value),
                            ccg: errors.ccg
                          })
                        }}
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="06 XX XX XX XX"
                      />
                    </div>
                  </ToolTip>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-6 gap-x-4">
                <div className='w-full md:w-1/2'>
                  <ToolTip text={errors.password}>
                    <div className={errors.password === '' ? 'bg-white rounded-md py-2 px-3 w-full relative' : 'bg-white rounded-md relative py-2 px-3 w-full border-2 border-red-600'}>
                      <label htmlFor="">Mot de passe</label>
                      <input type={showPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: form.email, password: e.target.value, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: form.ccg , isTdae: form.isTdae })} placeholder='********' />
                      <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowPass(!showPass)}>{showPass ? <VisibilityIcon className='text-normal' /> : <VisibilityOffIcon className='text-normal' />}</span>

                    </div>
                  </ToolTip>
                </div>
                <div className='relative w-full px-3 py-2 bg-white rounded-md md:w-1/2'>
                  <label htmlFor="">Confirmation de mot de passe</label>
                  <input type={showCPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e) => setForm({ email: form.email, password: form.password, cpassword: e.target.value, phone: form.phone, terms: form.terms, ccg: form.ccg , isTdae: form.isTdae })} placeholder='********' />
                  <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowCPass(!showCPass)}>{showCPass ? <VisibilityIcon className='text-normal' /> : <VisibilityOffIcon className='text-normal' />}</span>
                </div>
              </div>
              <div className='flex flex-col justify-between gap-4 md:flex-row'>
                <ToolTip text={errors.terms}>
                  <div className='flex items-center w-max'>
                    <Checkbox sx={errors.terms === '' ? {
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    } : {
                      color: 'rgb(220 38 38)',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    }} onClick={() => setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: !form.terms, ccg: form.ccg , isTdae: form.isTdae })} />
                    <a target={"_blank"} href='https://www.teachr.fr/policy' className="text-white underline" rel="noreferrer">J’accepte la politique des données. </a><span className='text-2xl text-white'>*</span>
                  </div>
                </ToolTip>
                <ToolTip text={errors.ccg}>
                  <div className='flex items-center w-max'>
                    <Checkbox sx={errors.ccg === '' ? {
                      color: 'white',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    } : {
                      color: 'rgb(220 38 38)',
                      '&.Mui-checked': {
                        color: 'white',
                      },
                      '&.MuiCheckbox-root ': {
                        padding: 0,
                        paddingRight: 1
                      },
                    }} onClick={() => setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: !form.ccg, isTdae: form.isTdae })} />
                    <a target={"_blank"} href='https://www.teachr.fr/cgu_cgv' className="text-white underline" rel="noreferrer">J'accepte les CGUs et CGVs.</a><span className='text-2xl text-white'>*</span>
                  </div>
                </ToolTip>
              </div>
                {/* <div className='flex items-center gap-2 text-white'>
                  <Checkbox onChange={() => setForm({ email: form.email, password: form.password, cpassword: form.cpassword, phone: form.phone, terms: form.terms, ccg: form.ccg, isTdae: !form.isTdae })} className='p-0 text-white' />
                  <a className="text-white" rel="noreferrer">Souhaitez-vous que Teach'r s'occupe de la télédéclaration et le télépaiement de votre chiffre d'affaires auprès de l'URSSAF</a>
                </div> */}
                <div className='flex items-center gap-2 text-white'>
                  <Checkbox onChange={() => setRemember(!remember)} className='p-0 text-white' />
                  <a className="text-white" rel="noreferrer">Se souvenir de moi</a>
                </div>
              <Button type="submit" disabled = {!show}   onClick={(e) => handleSubmit(e)} className='disabled:opacity-30 flex items-center justify-center px-4 py-2 font-extrabold text-white no-underline rounded-full lg:w-1/2 font-nunito bg-second gap-x-3'>JE M’INSCRIS !</Button>
            </div>
          </div>
          <div className='hidden w-1/2 lg:block'>
          </div>
        </div>
      </div>
    </div>
  )
}
