import ApiService from "../Services/ApiService";
import ReviewTeachr from "../Objects/models/ReviewTeachr";
import { Route } from "../Objects/enums/RouteEnum";
import HydraCollection from "../Objects/models/HydraCollection";

export default abstract class ReviewTeachrRepository {
    /**
     * Fetch a ReviewTeachr stored into the API by his id.
     * @param id
     */
    public static fetchReviewTeachrById(id: number) {
        return ApiService.getSingleInstance<ReviewTeachr>(
            Route.REVIEW_TEACHR_BASE + "/" + id,
            ReviewTeachr
        );
    }

    /**
     * Returns all reviewTeachrs of the connected user.
     */
    public static getReviewsTeachrs(
        page: number = 1,
        filters: Array<string> | null
    ) {
        return ApiService.getCollection<HydraCollection<ReviewTeachr>>(
            Route.REVIEW_TEACHR_BASE,
            HydraCollection,
            true,
            page,
            filters
        );
    }

    public static createReviewTeachrPunctualCourse(courseId: number, reviewTeachr : ReviewTeachr):Promise<unknown> {
        return ApiService.post(`/teachrs/v2/punctual_courses/${courseId}/review_teachrs`, reviewTeachr);
    }

    public static createReviewTeachrSubscriptionCourse(courseId: number, reviewTeachr : ReviewTeachr):Promise<unknown> {
        return ApiService.post(`/teachrs/v2/subscriptions_courses/${courseId}/review_teachrs`, reviewTeachr);
    }

}
