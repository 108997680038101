import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import Teachr from "../Objects/models/Teachr";
import TeachrHours from "../Objects/models/TeachrHours";
import { Subject } from "../Objects/models/Subject";
import { plainToClass } from "class-transformer";
import TeachrPutTva from "../Objects/models/TeachrPutTva";
import TeachrShowInfo from "../Objects/models/TeachrShowInfo";
import Client from "../Objects/models/Client";
import TeachrValidateClientAici from "../Objects/models/TeachrValidateClientAici";
import TeachrBusinessType from "../Objects/models/TeachrBusinessType";
import TeachrRCS from "../Objects/models/TeachrUpdateRCS";

export default abstract class Teachr_Repository {
    /**
     * Fetch a Teachr stored into the API by his id, client side
     * @param id
     */
    public static fetchTeachrById(id: number) {
        return ApiService.getSingleInstance<Teachr>(
            Route.TEACHR_BASE + "/" + id,
            Teachr
        );
    }

    /**
     * Fetch a Teachr stored into the API by his id.
     * @param id
     */
    public static fetchTeachrByIdClient(id: number){
        // return ApiService
        //     .get(`/clients/v2/teachrs/${id}`)
        //     .then(body => plainToClass(Teachr, body));
            return ApiService.getSingleInstance<Teachr>(
                `/clients/v2/teachrs/${id}`,
                Teachr
            );
    };


    public static fetchTeachrInfoByIdClient(
        teachrId: number,
        clientId: number
    ) {
        return ApiService.get(
            `/clients/v2/clients/${clientId}/teachrs/${teachrId}/contact_infos`
        );
    }

   

    /**
     * Creates an instance of Teachr into the API.
     * @param teachr
     */
    public static createTeachr(teachr: Teachr) {
        return ApiService.postInstance<Teachr>(
            Route.TEACHR_BASE,
            Teachr,
            teachr
        );
    }

    /**
     * Updates only the FCM token of the user
     */
    public static updateFCMToken(teachr: Teachr) {
        return ApiService.putInstance<Object>(
            `/teachrs/v2/teachrs/${teachr.id}/firebase_messaging_token`,
            Object,
            {
                firebaseMessagingToken: teachr.firebaseMessagingToken,
            }
        );
    }

    /**
     * Updates the instance of the Teachr passed as parameter.
     * @param teachr
     */
    // public static updateTeachr(teachr: Teachr) {
    //     if (typeof teachr.profilePicture !== "string") {
    //         teachr.profilePicture = undefined;
    //     }
    //     if (typeof teachr.proofIsAE !== "string") {
    //         teachr.proofIsAE = undefined;
    //     }
    //     return ApiService.putInstance<Teachr>(
    //         Route.TEACHR_BASE + "/" + teachr.id,
    //         Teachr,
    //         teachr
    //     );
    // }

    /**
     * Returns the current logged teachr
     */
    public static getCurrentTeachr() {
        return ApiService.getSingleInstance<Teachr>(
            Route.TEACHR_BASE + Route.TEACHR_ME,
            Teachr
        );
    }

    /**
     * Generates a PDF file of teachr payslip.
     */
    public static createPaySlip(data:{ month: number, year: number, sendMail: boolean }, teachrId:number) {
        return ApiService.post(`/teachrs/v2/teachr/pay_slip/${teachrId}`, data);
    }

    /*
     * Returns flagged words by teachr
     */
    public static async getFlaggedWords(teachrId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/flagged_words`);
    }

    /**
     * Returns the number of hours and courses given by teachr
     * @param teachr
     */
    public static getTeachrHours(teachrId: number) {
        return ApiService.get(`/clients/v2/teachrs/${teachrId}/hours`)
            .then(body => plainToClass(TeachrHours, body));
        ;
    }

    /**
     * Returns all subjects given by teachr
     * @param teachr
     */
    public static getSubjectsGivenByTeachr(teachrId: number) {
        return ApiService.get(`/clients/v2/teachrs/${teachrId}/subjects`)
            .then(body => plainToClass(Subject, body))
        ;
    }

    public static putTvaByTeachr(teachrId: number, teachr: TeachrPutTva) {
        return ApiService.putInstance<TeachrPutTva>(
            `/teachrs/${teachrId}/put/tva`,
            TeachrPutTva,
            teachr
        );
    }

    public static teachValidateClient(teachrId: number, client : Client, teachr: TeachrValidateClientAici) {
        return ApiService.putInstance<TeachrValidateClientAici>(
            `/teachrs/${teachrId}/teachrs/${client}/client_validate`,
            TeachrValidateClientAici,
            teachr
        );
    }

    public static teachrShowInfo(teachrId: number, teachr:TeachrShowInfo) {
        return ApiService.putInstance<TeachrShowInfo>(
            `/teachrs/${teachrId}/is_show_info`,
            TeachrShowInfo,
            teachr
        );
    }

    public static teachrUpdateBusinessType(teachrId: number, teachr:TeachrBusinessType) {
        return ApiService.putInstance<TeachrBusinessType>(
            `/teachrs/${teachrId}/business_type`,
            TeachrBusinessType,
            teachr
        );
    }
    public static teachrUpdateRCS(teachrId: number, teachr:TeachrRCS) {
        return ApiService.putInstance<TeachrRCS>(
            `/teachrs/${teachrId}/rcs`,
            TeachrRCS,
            teachr
        );
    }

    public static teachrValidateAccountWithCode(teachrId: number, code:string) {
        return ApiService.get(
            `/teachrs/validate_account_with_code/${teachrId}/${code}`
            )
    }

    public static async isOkByTeachr(teachr: number) {
        return ApiService.put(
            `/teachr/note/${teachr}`
        );
    }

    public static removeTeachrFavoriteByTeachr(clientId: number, teachrId: number) {
        return ApiService.deleteInstance(
            `/teachrs/${clientId}/favorite/${teachrId}`
        );
    }

    public static async sendSmsToClient(teachr: number, client:number) {
        return ApiService.put(
            `/teachrs/${teachr}/send_sms_to_client/${client}`
        );
    }
    public static async sendMailToClient(teachr: number, client:number) {
        return ApiService.get(
            `/teachrs/${teachr}/send_mail_to_client/${client}`
        );
    }
}   
