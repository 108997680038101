import { IconButton } from "@mui/material";
import React from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -4,
    top: 10,
    fontSize: "16px",
    backgroundColor: "#FF724F",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "5px",
  },
}));
const DemandeComponentLayout = (props:any) => {
    return (
        <div>
            <div className="lg:text-[24px] text-lg overflow-x-auto  hidden text-center lg:space-x-44 space-x-20 text-dark_blue font-bold md:flex">
          <div className="">
            <p className="border-b-4 border-b-orange lg:w-64 ">
              Demandes en attentes
            </p>
          </div>
          <div className="-mt-3">
            <IconButton aria-label="cart">
              <StyledBadge badgeContent={10} color="secondary">
                <p
                 // onClick={() => handleChangeToResponse()}
                  className="border-b-4 text-lg lg:text-[24px]  text-dark_blue border-b-orange lg:w-40 "
                >
                  Mes réponses
                </p>
              </StyledBadge>
            </IconButton>
          </div>
        </div>
        <div className="text-sm  overflow-x-auto  flex text-center space-x-10 text-dark_blue font-bold md:hidden">
          <div className="">
            <p className="border-b-2 border-b-orange w-20">En attente</p>
          </div>
          <div className="-mt-2">
            <IconButton aria-label="cart">
              <StyledBadge badgeContent={10} color="secondary">
                <p
                  //onClick={() => handleChangeToResponse()}
                  className="border-b-2 border-b-orange w-24 text-sm  text-dark_blue"
                >
                  Mes réponses
                </p>
              </StyledBadge>
            </IconButton>
          </div>
        </div>
        <div>{props.children}</div>   
        </div>
    );
};

export default DemandeComponentLayout;