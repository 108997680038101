import lieu from "../../../../Assets/Images/place.png";
import picture from "../../../../Assets/Images/picture.png";
import profile from "../../../../Assets/Images/profilehomme.png";
import star from "../../../../Assets/Images/groupstar.png";
import starOnly from "../../../../Assets/Images/staronly.png";
import toch from "../../../../Assets/Images/tochh.png";
import money from "../../../../Assets/Images/money.png";
import msg from "../../../../Assets/Images/msg.png";
import { useLocation, useNavigate } from "react-router-dom";
// import ResponseLayout from "../ResponseLayout";
import { useEffect, useState } from "react";
import { CircularProgress, Link, Rating } from "@mui/material";
import Functions from "../../../../Helpers/Functions";
import ReviewClient from "../../../../Objects/models/ReviewClient";
import ReviewClientRepository from "../../../../Objects/models/ReviewClientRepository";
// import ClientRepository from "../../../../Repositories/ClientRepository";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/btn-close-orange.png";
// import ClientRepository from "../../../../Repositories/ClientRepository";
import PackProposalRepository from "../../../../Repositories/PackProposalRepository";
import Tabs from "@mui/material/Tabs";
// import vecteur from "../../../../Assets/Images/vecteur 2.png"
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import NavBarCominsoon from "../../../../Components/Navbar/NavBarCominsoon";
import { Elements } from "@stripe/react-stripe-js";
// import CoursePackPayment from "../../Paiement/ConfirmationCourse/AcceptCourse/CoursePackPayment";
import { loadStripe } from "@stripe/stripe-js";
import CoursePackPayment from "../../Paiement/ConfirmationCourse/AcceptCourse/CoursePackPayment";
import ClientRepository from "../../../../Repositories/ClientRepository";
import { PackProposal } from "../../../../Objects/models/PackProposal";
const stripePromise = loadStripe("pk_test_aTkKFKmFR3oCTGEppE2nxxA7");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF724F",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #FF724F",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0054A3",
  "&.Mui-selected": {
    color: "#FF724F",
  },
  "&.Mui-focusVisible": {},
}));

const ProfileTeacherCoursePack = () => {

  const navigate = useNavigate();
  // const [showProfile, setShowProfile] = useState(false);
  const location = useLocation();
  const [state] = useState<any>(location.state);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [review, setReview] = useState<ReviewClient[]>();
  const [packProposals, setPackProposals] = useState<PackProposal[]>();
  // const proposal = state;
  // if (packProposals) {
  //   const prop = (packProposals[0]);

  // }




  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue(1);
    setIsLoading(true);
  };

  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpens = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloses = () => {
    setOpens(false);
  };

  const onRefuseConfirm = () => {
    setLoading(true);
    handleCloses();
    navigate("/user/response");
  };
  // const loadData = async (page: number) => {
  //   const reviewsCollection = await ReviewClientRepository.getReviewsClient(
  //     page,
  //     ["coursePack.acceptedProposal.teachr.id=" + state.teachr.id]
  //   );
  //   setReview(reviewsCollection.members);
  // };

  // useEffect(() => {
  //   loadData(1);
  // }, []);

  const handleChanges = () => {
    handleNext();
  };



  useEffect(() => {
    (
      async () => {
        const client = await ClientRepository.getCurrentClient();
        if (client.id && state.proposal.coursePack_id) {
          const coursePack = PackProposalRepository.fetchAllCoursePackProposals(
            state.proposal.coursePack_id,
            true
          );
          coursePack.then((res) => {
            setPackProposals(res.members)
          })

        }
      }
    )()
  },)




  // const getCoursePackProposal = 
  // async () => {
  //   const client = await ClientRepository.getCurrentClient();
  //   if (client.id && state.proposal.coursePack_id) {
  //     const coursePack = PackProposalRepository.fetchAllCoursePackProposals(
  //       state.proposal.coursePack_id,
  //       true
  //     );
  //     coursePack.then((res) => {

  //       setPackProposals(res.members)
  //     })

  //   }
  // };


  return (
    <>

      <div className="w-screen h-screen overflow-x-hidden bg-no-repeat bg-cover bg-font_payment //list-teacher font-nunito">
        <div className="fixed z-50 w-full bg-white">
          <NavBarCominsoon />
          <div className="mx-5 -mt-10 md:mx-10 md:mt-0">
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledTabs value={value} onChange={handleChange}>
                <StyledTab
                  label={
                    <p className="text-sm font-bold lg:text-lg md:text-base">
                      Profil de {state.teachr.firstName}
                    </p>
                  }
                />
                {/* {isLoading ? (<div className="pt-5">
                <img src={vecteur} alt="" />
              </div>):} */}
                {isLoading ? (
                  <StyledTab
                    label={
                      <p className="text-sm font-bold lg:text-lg md:text-base">
                        Paiement
                      </p>
                    }
                  />
                ) : (
                  ""
                )}
              </StyledTabs>
            </Box>
          </div>
        </div>
        <div className="//mx-10 md:mt-[120px] mt-[80px]  //mt-20">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ px: 3, pt: 3, background: 'white' }}>
              <TabPanel value={value} index={0}>
                <div className="md:mt-[50px] mt-10 //bg-white md:mx-20 //mb-8">
                  <div className="hidden md:flex lg:gap-x-0 gap-x-4">
                    <div className="w-2/3 lg:w-1/2">
                      <div className="w-full p-4 bg-bg-color-tuile rounded-xl shadow-profile">
                        <div className="flex ">
                          <div className="w-1/4 ">
                            <div className="mx-auto translate-y-1 rounded-full shadow-profile xl:w-2/3 xl:h-full ">
                              <img
                                src={
                                  state.teachr?.profilePictureURL
                                    ? state.teachr?.profilePictureURL
                                    : picture
                                }
                                alt=""
                                className="relative w-full h-full mx-auto rounded-full"
                              />
                            </div>
                          </div>
                          <div className="block w-1/3 my-auto space-y-1">
                            <div className="">
                              <p className="text-sm font-bold text-dark_blue lg:text-xl md:text-lg //translate-y-4">
                                {state.teachr.firstName}
                              </p>
                            </div>
                            <div className="">
                              {state.teachr?.averageNote === 0 ? (
                                ""
                              ) : (
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={state.teachr?.averageNote}
                                  precision={0.1}
                                  readOnly
                                />
                              )}
                            </div>
                            <div className="text-xs lg:text-base md:text-sm">
                              <p>
                                {Functions.renderTeachrHours(
                                  state.teachrHours?.totalHours
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="w-5/12 my-auto space-y-2 ">
                            <div className="flex ">
                              <div className="w-1/4 pl-2 ">
                                <img src={toch} alt="" />
                              </div>
                              <div className="w-3/4 ">
                                <p className="text-sm font-semibold truncate lg:text-lg md:text-base text-dark_blue">
                                  {state.teachr?.whereStudies},{" "}
                                  {state.teachr.yearInStudies.toString() +
                                    (state.teachr.yearInStudies === 1
                                      ? "ère"
                                      : "ème") +
                                    " année"}
                                </p>
                              </div>
                            </div>
                            <div className="flex ">
                              <div className="w-1/4 pl-4 ">
                                <img src={lieu} alt="" />
                              </div>
                              <div className="w-3/4 ">
                                <p className="text-sm font-semibold truncate lg:text-lg md:text-base text-dark_blue">
                                  Boulogne
                                </p>
                              </div>
                            </div>
                            <div
                              className=""
                              onClick={() => navigate("/coming-soon")}
                            >
                              <div className="flex pt-1 text-xs border-2 rounded-full text-dark_blue lg:text-base md:text-sm border-dark_blue">
                                <div className="w-1/4 pl-7">
                                  <img src={msg} alt="" />
                                </div>
                                <div className="w-3/4 ">
                                  <p className="font-bold truncate">
                                    Discuter avec {state.teachr.firstName} !
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center w-1/2 mx-auto mt-2 space-x-2">
                          <div className="pt-1">
                            <img src={money} alt="" />
                          </div>
                          <div className="">
                            {state.course.isRemote ?
                              <div>
                                <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">Prix par heure : {Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour.toFixed(2) + " €"}</p>
                              </div>
                              :
                              <div>
                                <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                                  Prix par heure après crédit d’impôt:{" "}
                                  {(Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour / 2).toFixed(2) + " €"}
                                </p>
                                <p className="text-xs lg:text-base md:text-sm text-grey">

                                  Prix par heure avant crédit d’impôt :{" "}
                                  {Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour.toFixed(2) + " €"}

                                </p>
                              </div>
                            }

                          </div>
                        </div>
                        <div className="w-full mt-4 space-y-2 lg:px-10 ">
                          <div className="w-full ">
                            <p className="text-base font-bold text-dark_blue lg:text-xl md:text-lg ">
                              Description :
                            </p>
                            <div className="">
                              <p className="w-full overflow-hidden text-sm text-grey lg:text-lg md:text-base">
                                {state.teachr.description}
                              </p>
                            </div>
                          </div>
                          <div className="mt-2 ">
                            {state.proposal.description ? (
                              <p className="w-full overflow-hidden text-base font-bold text-dark_blue lg:text-xl md:text-lg">
                                Message de candidature :
                              </p>
                            ) : (
                              ""
                            )}
                            <p className="text-sm text-grey lg:text-lg md:text-base ">
                              {state.proposal.description}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-around w-4/5 mx-auto mt-10 space-x-8 text-sm lg:text-lg md:text-base">
                          <div className="w-1/2 pt-1 border-2 rounded-full cursor-pointer border-orange active:text-white active:bg-orange">
                            <div
                              onClick={handleClickOpens}
                              className="w-full text-xs font-extrabold text-center uppercase lg:text-base md:text-sm text-orange active:text-white "
                            >
                              Non, Merci
                            </div>
                            <Dialog open={opens} onClose={handleCloses}>
                              <div className="p-3 border-dark_blue border-1">
                                <DialogTitle>
                                  <div className="flex justify-between mb-3">
                                    <span className="text-dark_blue font-extrabold uppercase font-nunito lg:text-[22px] md:text-lg text-base">
                                      REFUSER CE TEACH'R
                                    </span>
                                    <img
                                      src={close}
                                      alt=""
                                      className="w-6 h-6 md:w-6 md:h-6 //translate-y-2"
                                      onClick={() => handleCloses()}
                                    />
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <p className="text-[#4d4d4d] font-nunito lg:text-lg md:text-base text-sm">
                                    Une fois ce teach'r refusé, vous ne pourrez
                                    plus revenir en arrière. Voulez-vous vraiment
                                    continuer ?
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <div className="flex w-full mx-auto mb-4 space-x-6 ">
                                    <button
                                      onClick={handleCloses}
                                      className="w-1/2 px-3 py-1 text-xs font-extrabold uppercase border-2 rounded-full lg:text-base md:text-sm text-orange active:text-white active:bg-orange border-orange"
                                    >
                                      RETOUR
                                    </button>
                                    <button
                                      onClick={() => {
                                        onRefuseConfirm();
                                      }}
                                      className="w-1/2 px-3 py-1 text-xs font-extrabold uppercase border-2 rounded-full lg:text-base md:text-sm text-dark_blue border-dark_blue active:bg-dark_blue active:border-dark_blue active:text-white"
                                    >
                                      {loading ? (
                                        <CircularProgress
                                          sx={{
                                            color: "blue",
                                          }}
                                          size={20}
                                          thickness={4}
                                          value={100}
                                        />
                                      ) : (
                                        "continuer"
                                      )}
                                    </button>
                                  </div>
                                </DialogActions>
                              </div>
                            </Dialog>
                          </div>
                          <button
                            disabled={packProposals === undefined}
                            onClick={() => {
                              handleChanges();
                              // handleChangeProfile();
                            }}
                            className="w-1/2 p-1 text-xs font-extrabold text-center uppercase border-2 rounded-full cursor-pointer text-dark_blue lg:text-base md:text-sm border-dark_blue active:bg-dark_blue active:border-dark_blue active:text-white"
                          >
                            {packProposals === undefined ? (
                              <CircularProgress
                                sx={{
                                  color: "blue",
                                }}
                                size={20}
                                thickness={4}
                                value={100}
                              />
                            ) : (
                              "Accepter"
                            )}{" "}

                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="w-1/3 lg:w-1/2">
                      {state.teachr?.averageNote ? <div className="flex justify-around pt-1 mx-auto mt-4 space-x-4 font-bold bg-white border-2 xl:w-2/5 lg:w-1/2 -translate-y-14 shadow-avis rounded-2xl text-dark_blue ">
                        <div className="w-1/4 text-base text-center xl:text-xl md:text-lg">
                          <p>AVIS</p>
                        </div>
                        <div className="w-1/2 text-base text-center xl:text-xl md:text-lg">
                          <Rating
                            name="half-rating-read"
                            defaultValue={state.teachr?.averageNote}
                            precision={0.1}
                            readOnly
                          />
                        </div>
                        <div className="w-1/4 text-base text-center lg:text-xl md:text-lg">
                          <p>({review?.length}) </p>
                        </div>
                      </div> : ""}
                      <div className="h-screen -mt-8 space-y-8 overflow-x-hidden lg:-translate-y-16 lg:mt-8 list-teacher">
                        {review?.map((comment: ReviewClient) => (
                          <div className="">
                            <div className="p-2 mx-auto lg:w-2/3 rounded-xl shadow-avis ">
                              <div className="flex justify-around ">
                                <div className="w-1/4 ">
                                  <div className="w-10 h-10 mx-auto rounded-full xl:w-16 xl:h-16 lg:h-12 lg:w-12">
                                    <img
                                      src={
                                        comment.owner?.profilePicture
                                          ? comment.owner?.profilePicture
                                          : profile
                                      }
                                      // src={profile}
                                      alt=""
                                      className="relative w-full h-full rounded-full"
                                    />
                                  </div>
                                </div>
                                <div className="w-1/2 pl-2 lg:space-y-0">
                                  <div className="">
                                    <p className="text-xs font-bold text-dark_blue lg:text-base md:text-sm">
                                      {comment.owner?.firstName}
                                    </p>
                                  </div>
                                  <div className="w-1/2 //mx-auto">
                                    <Rating
                                      name="half-rating-read"
                                      defaultValue={comment.note}
                                      precision={0.1}
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="mt-2 lg:mx-4">
                                <p className="text-sm font-bold text-dark_blue lg:text-lg md:text-base">
                                  {comment.comment ? "Commentaire :" : ""}
                                </p>
                                <p className="text-xs text-grey lg:text-base md:text-sm">
                                  {comment.comment}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="block md:hidden">
                    <div className="w-full lg:w-1/2">
                      <div className="w-full p-4 bg-bg-color-tuile rounded-xl shadow-profile">
                        <div className="justify-around ">
                          <div className="flex justify-around ">
                            <div className="w-1/3 mx-auto ">
                              <div className="w-20 h-20 mx-auto translate-y-1 rounded-full shadow-profile ">
                                <img
                                  src={
                                    state.teachr?.profilePictureURL
                                      ? state.teachr?.profilePictureURL
                                      : picture
                                  }
                                  alt=""
                                  className="relative w-20 h-20 rounded-full"
                                />
                              </div>
                            </div>

                            <div className="block w-2/3">
                              <p className="text-lg font-bold text-dark_blue ">
                                {state.teachr.firstName}
                              </p>
                              <div className="">
                                <div className="text-lg">
                                  {state.teachr?.averageNote === 0 ? (
                                    ""
                                  ) : (
                                    <Rating
                                      name="half-rating-read"
                                      defaultValue={state.teachr?.averageNote}
                                      precision={0.1}
                                      readOnly
                                    />
                                  )}
                                </div>
                                <p>
                                  {Functions.renderTeachrHours(
                                    state.teachrHours?.totalHours
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3 space-y-1 ">
                            <div className="flex ">
                              <div className="w-1/3 ">
                                <div className="w-1/2 pl-2 mx-auto ">
                                  <img src={toch} alt="" className="w-8" />
                                </div>
                              </div>
                              <div className="w-2/3 ">
                                <p className="text-sm font-semibold text-dark_blue">
                                  {state.teachr?.whereStudies},{" "}
                                  {state.teachr.yearInStudies.toString() +
                                    (state.teachr.yearInStudies === 1
                                      ? "ère"
                                      : "ème") +
                                    " année"}
                                </p>
                              </div>
                            </div>
                            <div className="flex ">
                              <div className="w-1/3 ">
                                <div className="w-1/2 pl-4 mx-auto ">
                                  <img src={lieu} alt="" className="w-4" />
                                </div>
                              </div>
                              <div className="">
                                <p className="text-sm font-semibold translate-y-1 text-dark_blue">
                                  Boulogne
                                </p>
                              </div>
                            </div>
                            <div className="flex w-full ">
                              <div className="w-1/3 pl-8 ">
                                <img src={money} alt="" className="w-8" />
                              </div>
                              <div className="w-2/3 ">
                                {state.course.isRemote ?
                                  <div>
                                    <p className="text-sm font-semibold text-dark_blue">
                                      Prix par heure:{" "}
                                      {Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour.toFixed(2) + " €"}
                                    </p>
                                  </div> :
                                  <div>
                                    <p className="text-xs text-grey">
                                      <span>
                                        Prix par heure avant crédit d’impôt :{" "}
                                        {Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour.toFixed(2) + " €"}
                                      </span>
                                    </p>
                                    <p className="text-sm font-semibold text-dark_blue">
                                      Prix par heure après crédit d’impôt :{" "}

                                      {(Functions.renderPackProposalPrice(parseFloat(state.proposal.teachrPrice), state.course.durationPerDay, state.course.dates.length, state.proposal.fees / 100, state.proposal.URSSAFPrice / 100).hour / 2).toFixed(2) + " €"}
                                    </p>
                                  </div>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-between w-4/5 mx-auto mt-3 gap-x-4 ">
                          <div onClick={() => navigate("/user/coming-soon")} className="w-1/2 pt-1 text-sm rounded-lg text-dark_blue shadow-shadow-button">
                            <p className="w-full text-center">Discuter</p>
                            <div className="pt-1 pl-10">
                              <img src={msg} alt="" className="w-8" />
                            </div>
                          </div>
                          <div className="w-1/2 pt-1 text-sm rounded-lg text-dark_blue shadow-shadow-button">
                            <div className="flex font-nunito md:hidden">
                              <span
                                className="w-full text-dark_blue"
                                onClick={handleClickOpen}
                              >
                                <p className="text-center">Avis</p>
                                <div className="w-8 mx-auto">
                                  <img
                                    src={starOnly}
                                    alt=""
                                    className="w-10 px-auto"
                                  />
                                </div>
                              </span>
                              {state.teachr?.averageNote ? <Dialog open={open} onClose={handleClose}>
                                <div className="w-[295px] overflow-x-hidden">
                                  <DialogTitle>
                                    <div className="h-8 z-40  flex fixed bg-white w-[258px]">
                                      <div className="w-4/5">
                                        <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                          Avis
                                        </span>
                                      </div>
                                      <div className="w-1/5 pt-1 pl-4 ">
                                        <img
                                          src={close}
                                          alt=""
                                          className="w-5"
                                          onClick={handleClose}
                                        />
                                      </div>
                                    </div>
                                  </DialogTitle>
                                  <DialogContent>
                                    <div className="">
                                      <div className="flex justify-around w-full py-2 mx-auto mt-6 font-bold shadow-avis rounded-2xl text-dark_blue">
                                        <div className="text-base translate-y-1 ">
                                          <p>AVIS</p>
                                        </div>
                                        <div className="translate-y-  text-[15px] ">
                                          <Rating
                                            name="half-rating-read"
                                            defaultValue={
                                              state.teachr?.averageNote
                                            }
                                            precision={0.1}
                                            readOnly
                                          />
                                        </div>
                                        <div className="text-base translate-y-1 ">
                                          <p>({review?.length})</p>
                                        </div>
                                      </div>
                                      <div className="mt-4 space-y-4 overflow-x-hidden list-teacher">
                                        {review?.map((comment: ReviewClient) => (
                                          <div className="">
                                            <div className="w-full p-2 mx-auto rounded-xl shadow-avis ">
                                              <div className="flex justify-between ">
                                                <div className="w-1/2 ">
                                                  <div className="w-16 h-16 mx-auto rounded-full ">
                                                    <img
                                                      src={
                                                        comment.owner
                                                          ?.profilePicture
                                                          ? comment.owner
                                                            ?.profilePicture
                                                          : profile
                                                      }
                                                      alt=""
                                                      className="relative w-full h-full rounded-full"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="w-1/2 //mx-auto">
                                                  <div>
                                                    <Rating
                                                      name="half-rating-read"
                                                      defaultValue={
                                                        comment.note
                                                      }
                                                      precision={0.1}
                                                      readOnly
                                                    />
                                                  </div>
                                                  <div className="">
                                                    <p className="text-base font-bold text-dark_blue">
                                                      {comment.owner?.firstName}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="">
                                                <p className="text-base font-bold text-dark_blue">
                                                  {comment.comment
                                                    ? "Commentaire :"
                                                    : ""}{" "}
                                                </p>
                                                <p className="text-sm text-grey">
                                                  {comment.comment}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </DialogContent>
                                </div>
                              </Dialog> : ''}
                            </div>
                          </div>
                        </div>

                        <div className="mt-4">
                          <p className="text-base font-bold text-dark_blue">
                            Description :
                          </p>
                          <p className="w-full text-sm text-grey">
                            {state.teachr.description}
                          </p>
                        </div>
                        {state.proposal.description ? (
                          <div className="mt-2">
                            {state.proposal.description ? (
                              <p className="text-base font-bold text-dark_blue">
                                Message de candidature :
                              </p>
                            ) : (
                              ""
                            )}
                            <p className="w-full text-sm text-grey">
                              {state.proposal.description}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="flex justify-between mx-auto mt-4 space-x-3">
                          <div className="w-1/2 pt-2 border-2 rounded-full cursor-pointer border-orange active:text-white active:bg-orange ">
                            <div
                              onClick={handleClickOpens}
                              className="w-full text-xs font-extrabold text-center uppercase lg:text-base md:text-sm text-orange active:text-white "
                            >
                              Non, Merci
                            </div>
                            <Dialog open={opens} onClose={handleCloses}>
                              <div className="p-3 border-dark_blue border-1">
                                <DialogTitle>
                                  <div className="flex justify-between mb-3">
                                    <span className="text-dark_blue font-extrabold uppercase font-nunito lg:text-[22px] md:text-lg text-base">
                                      REFUSER CE TEACH'R
                                    </span>
                                    <img
                                      src={close}
                                      alt=""
                                      className="w-6 h-6 md:w-6 md:h-6 //translate-y-2"
                                      onClick={() => handleCloses()}
                                    />
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <p className="text-[#4d4d4d] font-nunito lg:text-lg md:text-base text-sm">
                                    Une fois ce teach'r refusé, vous ne pourrez
                                    plus revenir en arrière. Voulez-vous vraiment
                                    continuer ?
                                  </p>
                                </DialogContent>
                                <DialogActions>
                                  <div className="flex w-full mx-auto mb-4 space-x-6 ">
                                    <button
                                      onClick={handleCloses}
                                      className="w-1/2 px-3 py-1 text-xs font-extrabold uppercase border-2 rounded-full lg:text-base md:text-sm text-orange active:text-white active:bg-orange border-orange"
                                    >
                                      RETOUR
                                    </button>
                                    <button

                                      onClick={() => {
                                        onRefuseConfirm();
                                      }}
                                      className="w-1/2 px-3 py-1 text-xs font-extrabold uppercase border-2 rounded-full lg:text-base md:text-sm text-dark_blue border-dark_blue active:bg-dark_blue active:border-dark_blue active:text-white"
                                    >
                                      {packProposals === undefined ? (
                                        <CircularProgress
                                          sx={{
                                            color: "blue",
                                          }}
                                          size={20}
                                          thickness={4}
                                          value={100}
                                        />
                                      ) : (
                                        "continuer"
                                      )}
                                    </button>
                                  </div>
                                </DialogActions>
                              </div>
                            </Dialog>
                          </div>
                          <button
                            disabled={packProposals === undefined}
                            onClick={() => {
                              handleChanges();
                              // handleChangeProfile();
                            }}
                            className="w-1/2 p-2 px-3 text-xs font-extrabold uppercase border-2 rounded-full cursor-pointer lg:text-base md:text-sm text-dark_blue border-dark_blue active:bg-dark_blue active:border-dark_blue active:text-white"
                          >
                            {loading ? (
                              <CircularProgress
                                sx={{
                                  color: "blue",
                                }}
                                size={20}
                                thickness={4}
                                value={100}
                              />
                            ) : (
                              "Accepter"
                            )}{" "}

                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Box>
            <TabPanel value={value} index={1}>
              {packProposals?.map((packProposals, key) => (
                <Box sx={{ pt: 3 }}>
                  <Elements stripe={stripePromise}>
                    <CoursePackPayment state={state} packProposals={packProposals} />
                  </Elements>
                </Box>
              ))
              }
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ProfileTeacherCoursePack;
