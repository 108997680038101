import Simulator from "../../Objects/models/Simulator";

interface SimulatorRedux {
  weekHours: number
  hourlyPrice: number
  adults: number,
  childs: number,
  last_income: number,
  external_income: number,
  education: number,
  isAcre: boolean,
  response: Simulator
}
const SET_INFO = 'SET_INFO';

const initialState: SimulatorRedux | null = null

export default function simulateurReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_INFO:
      return action.payload;
    default:
      return state
  }
}