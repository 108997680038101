// import React from "react";
import NavBarCominsoon from "../../../Components/Navbar/NavBarCominsoon";
// import { useNavigate } from "react-router-dom";
// import Badge, { BadgeProps } from "@mui/material/Badge";
// import { styled } from "@mui/material/styles";
// import IconButton from "@mui/material/IconButton";
// import ShoppingCartIcon from "@mui/icons-material";
import DemandeComponentLayout from './DemandeComponentLayout';
import DemandeSubscription from "./DemandSubscription";
import CoursePrevu from "./CoursePrevu";
import CoursePrevuSubscription from "./CoursePresvuSubscription";
import Historique from "./Historique";
import HistoriqueComingsoon from "../Courses/Components/HistoriqueComingsoon";

// const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
//   "& .MuiBadge-badge": {
//     right: -4,
//     top: 10,
//     fontSize: "16px",
//     backgroundColor: "#FF724F",
//     border: `2px solid ${theme.palette.background.paper}`,
//     padding: "5px",
//   },
// }));

function DemandeCourse(props: any) {
  // const navigate = useNavigate();
  // const [showProfile, setShowProfile] = useState(false);
  // const handleChangeToResponse = () => {
  //   navigate("/response");
  //   //setShowProfile(!showProfile);
  // };

  return (
    <div className="h-screen overflow-x-hidden list-teacher font-nunito">
      <div className="fixed bg-white z-40 w-full">
        <NavBarCominsoon />
      </div>
      <div className="lg:ml-[79px] ml-[25px] mt-[132px] ">
             <DemandeComponentLayout>
                <DemandeSubscription/>
             </DemandeComponentLayout>
             <CoursePrevu>
                <CoursePrevuSubscription/>
                <CoursePrevuSubscription/>
                <CoursePrevuSubscription/>
             </CoursePrevu>
             <Historique>
                <HistoriqueComingsoon/>
             </Historique>
      </div>
    </div>
  );
}

export default DemandeCourse;
