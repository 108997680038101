import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import hours from "../../../../Assets/Icons/hour.svg";
import moment from "moment";
import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../Helpers/Functions";
import DatePickers from "../../../utils/DatePickers";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../Services/FirebaseService";
import { TimePicker } from "antd";
// import Slider from "@mui/material/Slider";
const format = "HH:mm";

const CoursPonctuel = (props) => {
  moment.locale("fr");
  const [user] = useAuthState(auth);
  const today = new Date();
  const tomorrow = today.setDate(today.getDate() + 1);
  const start = moment().set({ hour: 17, minute: 0 });
  const end = moment().set({ hour: 17, minute: 0 }).add(1.5, "hours");
  const mostLikelyTime = moment();
  mostLikelyTime.set({ hour: 17, minute: 0 });
  const [date, setDate] = useState(tomorrow);
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);
  const getDate = (value) => {
    setDate(value);
  };
  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
    setEndTime(moment(startTime).add(newValue, "hours"));
  };
  useEffect(() => {
    (async () => {
      const value = await StepperStore.getItem(
        user ? "courseType" + user.uid : "courseType"
      );
      if (value.type === "ponctuel") {
        setDate(value.data.date);
        setRangeNumber(value.data.rangeNumber);
        setStartTime(value.data.startTime);
        setEndTime(value.data.endTime);
      }
    })();
    
  }, [user]);

  const [error , setError] = useState({
    endTime: ""
  })



  const validateDateTime = (value) => {
    const hour = endTime.hours() - startTime.hours();
    const minute =( endTime.minutes() - startTime.minutes()) / 60;
    const duration = hour + minute
    if (duration < value ) {
      return {
        isValid: false,
        message: "Attention, le créneau horaire de votre cours ne peut être inférieur à la durée de votre cours !",
      }
    } else {
      return {
        isValid: true,
        message: "",
      }
    }
  };

  const validate = () => {
    let errEnd = true;
    if (!validateDateTime(rangeNumber ).isValid) {
      setError({ endTime: validateDateTime(rangeNumber).message });
      errEnd = true;
    } else {
      setError({ endTime: validateDateTime(rangeNumber).message });
      errEnd = false;
    }
    if (errEnd) {
      return false;
    } else {
      return true;
    }
  };

  const setEndTimes = (newValue) => {
    const hour = endTime.hours() - startTime.hours();
    const minute =( endTime.minutes() - startTime.minutes()) / 60;
    const duration = hour + minute
    if (duration < rangeNumber) {
      setEndTime(newValue);
      setStartTime(moment(newValue).add(-rangeNumber, "hours"));
    } else {
      setEndTime(newValue);
    }
  };

  const handleSubmit = (event) => {
   if(validate()){ 
    StepperStore.setItem(
      user ? "courseType" + user.uid : "courseType",
      {
        type: "Ponctuel",
        data: {
          date: date,
          rangeNumber: rangeNumber,
          startTime: startTime.toDate().valueOf(),
          endTime: endTime.toDate().valueOf(),
        },
      },
      function (err) {
        console.warn(err);
      }
    );
    props.handleNext();
  }
    event.preventDefault();
  };

  
  return (
    <div className="space-y-2 translate-y-28 md:space-y-6 lg:translate-y-10 md:translate-y-20">
      <div className="w-4/5 mx-auto ">
        <h5 className="text-lg font-bold text-dark_blue lg:text-3xl md:text-2xl">
          Cours ponctuel :
        </h5>
      </div>
      <div className="w-4/5 mx-auto ">
        <div className="mt-6 md:w-2/3">
          <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="lg:text-lg md:text-base text-sm font-medium"
            >
              {" "}
              Durée du cours
            </label>
            <div className=" h-10 md:mt-0 -mt-2">
              <Functions.PrettoSlider
                defaultValue={1}
                step={0.5}
                min={1}
                max={4}
                marks
                value={rangeNumber}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <span className="font-bold text-dark_blue">
            <input
              type="text"
              value={Functions.renderDuration(rangeNumber * 2)}
              onChange={(e) => setRangeNumber(e.target.value)}
            />
          </span>
        </div>
        <div className="w-full md:mt-8 mt-4 gap-8 md:space-y-0 space-y-6  md:flex block mt- mx-auto">
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Date du cours :
              </label>
              <div className="md:-mt-3 -mt-6 md:translate-y-0 translate-y-2  ">
                <div className="relative  -ml-4 ">
                  <DatePickers value={date} callBack={getDate} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="lg:text-lg md:text-base text-sm font-medium"
              >
                {" "}
                Créneau de disponibilité
              </label>
              <div className="md:flex hidden mt-2">
                <div className="w-full relative">
                  <div className="md:w-full //w-2/3  //xl:w-3/2  flex">
                    <div className=" ">
                      <TimePicker
                        value={startTime}
                        onChange={(newValue) => {
                          setStartTime(newValue);
                          setEndTime(
                            moment(newValue).add(rangeNumber, "hours")
                          );
                        }}
                        minuteStep={5}
                        bordered={false}
                        format={format}
                        showNow={false}
                        clearIcon=""
                        size="large"
                        suffixIcon=""
                      />
                    </div>
                    <div className="md:translate-x-0 xl:-translate-x-4 lg:-translate-x-2 translate-x-4 md:mt-1 mt-2">
                      <span className="lg:text-lg text-base">à</span>
                    </div>
                    <div className="text-base w-1/2 //mt-2.5 ml-5">
                      <TimePicker
                        value={endTime}
                        onChange={(newValue) => {
                          setEndTimes(newValue)
                        }}
                        minuteStep={5}
                        bordered={false}
                        format={format}
                        showNow={false}
                        clearIcon=""
                        size="large"
                        suffixIcon=""
                        inputReadOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <img
                    src={hours}
                    alt="calendar icon"
                    className="md:w-8 xl:w-10 md:-translate-x-3 -translate-x-6 xl:-mt-2 md:-mt-0  -mt-1"
                  />
                </div>
              </div>
              <div className="-ml-2 md:hidden flex mt-2 ">
                <div className="w-full relative">
                  <div className="md:w-full //w-2/3 xl:w-3/2   flex">
                    <div className="">
                      <TimePicker
                        value={startTime}
                        onChange={(newValue) => {
                          setStartTime(newValue);
                          setEndTime(
                            moment(newValue).add(rangeNumber, "hours")
                          );
                        }}
                        minuteStep={5}
                        bordered={false}
                        format={format}
                        showNow={false}
                        clearIcon=""
                        size="small"
                        suffixIcon=""
                      />
                    </div>
                    <div className="-mt-[1px] -translate-x-2">
                      <span className="lg:text-lg text-base">à</span>
                    </div>
                    <div className="//mt-[2px] //-mt-3 ml-8">
                    <TimePicker
                        value={endTime}
                        onChange={(newValue) => {
                          setEndTimes(newValue)
                        }}
                        minuteStep={5}
                        bordered={false}
                        format={format}
                        showNow={false}
                        clearIcon=""
                        size="small"
                        suffixIcon=""
                        inputReadOnly={true}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <img
                    src={hours}
                    alt="calendar icon"
                    className="w-7 -mt-3 -ml-"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-4/5 mx-auto space-x-2 text-lg md:translate-y-4 translate-y-2 ">
        {/* {error.endTime === "null" || error.endTime === ""
          ? " "
          : validateDateTime().icon} */}
        <p className="text-[#FF724F] h-6 w-full text-sm md:text-base ">
          {error.endTime === "null" || error.endTime === ""
            ? null
            : validateDateTime(rangeNumber).message}
        </p>
      </div>
      <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-28 md:translate-y-44 md:w-full">
        <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
          <div
            onClick={props.handleBack}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={(e) => handleSubmit(e)}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="md:flex hidden justify-around mx-auto translate-y-12 md:translate-y-20 md:w-full">
        <div className="flex justify-center space-x-2">
          <button
            type="button"
            onClick={props.handleBack}
            className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent
          </button>
        </div>
        <div className="flex justify-center space-x-2 ">
          <button
            type="button"
            onClick={(e) => handleSubmit(e)}
            className={
              // error.date === "null"
              //   ?
              " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
              // : error.date
              // ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
              // : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
            }
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoursPonctuel;
