import ApiService from "../../Services/ApiService";
import { Route } from "../../Objects/enums/RouteEnum";
import HydraCollection from "../../Objects/models/HydraCollection";
import ReviewClient from "../../Objects/models/ReviewClient";

export default abstract class ReviewClientRepository {
    /**
     * Fetch a ReviewClient stored into the API by his id.
     * @param id
     */
    public static fetchReviewClientById(id: number) {
        return ApiService.getSingleInstance<ReviewClient>(
            Route.REVIEW_CLIENT_BASE + "/" + id,
            ReviewClient
        );
    }

    // /**
    //  * Creates an instance of ReviewClient into the API.
    //  * @param reviewClient
    //  */
    // public static createReviewClient(reviewClient: ReviewClient) {
    //     return ApiService.postInstance<ReviewClient>(
    //         Route.REVIEW_CLIENT_BASE,
    //         ReviewClient,
    //         reviewClient
    //     );
    // }

    /**
     * Returns all reviewClients of the connected user.
     */
    public static getReviewsClient(
        page: number = 1,
        filters: Array<string> | null
    ) {
        return ApiService.getCollection<HydraCollection<ReviewClient>>(
            Route.REVIEW_CLIENT_BASE,
            HydraCollection,
            true,
            page,
            filters
        );
    }

    public static createReviewClientPunctualCourse(courseId: number, reviewClient : ReviewClient):Promise<unknown> {
        return ApiService.post(`/clients/v2/punctual_courses/${courseId}/review_clients`, reviewClient);
    }

    public static createReviewClientSubscriptionCourse(courseId: number, reviewClient : ReviewClient):Promise<unknown> {
        return ApiService.post(`/clients/v2/subscriptions_courses/${courseId}/review_clients`, reviewClient);
    }


}
