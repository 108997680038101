import React from 'react'
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import pause from "../../../../../Assets/Icons/pause2.svg";
import EditIcon from '@mui/icons-material/Edit';

// const BpIcon = styled('span')(({ theme }) => ({
//   borderRadius: '50%',
//   width: 25,
//   color:'#0054A3',
//   height: 25,
//   backgroundColor: theme.palette.mode === 'dark' ? '#0054A3' : 'transparent',
//   'input ~ &' : {
//   border: '1px solid #0054A3'
//   },
//   'input:checked ~ &' : {
//     border: 'none',
//     },
//   'input:hover ~ &': {
//     backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#0054A3',
//     border: 'none',
//   },
//   'input:disabled ~ &': {
//     boxShadow: 'none',
//     border: 'none',
//     background:
//       theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
//   },
// }));
// const BpCheckedIcon = styled(BpIcon)({
//   backgroundColor: '#0054A3',
//   '&:before': {
//     display: 'block',
//     width:  '100%',
//     height: '100%',
//     content: '""',
//   },
//   'input:hover ~ &': {
//     backgroundColor: '#0054A3',
//   },
// });

function RadioButtonDashboard(props:any) {
  return (
    <div className='' ><Radio
    sx={{
      '&:hover': {
        bgcolor: 'transparent',
      },
    }}
    disableRipple
    color="default"
    // checkedIcon={<img className='' src={props.image} alt="" /> }
    checkedIcon={props.image }
    icon={props.image}
    {...props}
  /></div>
  )
}

export default RadioButtonDashboard