import ApiService from "../Services/ApiService";
import { CoursePack } from "../Objects/models/CoursePack";
import { plainToClass } from "class-transformer";

export default abstract class CoursePackRepository {
    /**
     * Fetch a Course Pack stored into the API by his id.
     * @param id
     */
    public static fetchCoursePackById(id: number) {
        return ApiService.get(`/teachrs/v2/course_packs/${id}`);
    }

    public static getCoursePacks(clientId: number) {
        return ApiService.get(
            `/clients/v2/clients/${clientId}/active_course_packs`
        )
            .then(body => plainToClass(CoursePack, body));
    }

    public static getPendingCoursePacks(clientId: number) : Promise< CoursePack[]> {
        return ApiService.get(
            `/clients/v2/clients/${clientId}/pending_course_packs`
        )
            .then(body => plainToClass(CoursePack, body));
    }

    /**
     * Creates an instance of coursePack into the API.
     * @param coursePack
     */
    public static createCoursePack(coursePack: CoursePack) {
        return ApiService.post("/clients/v2/course_packs", coursePack);
    }

    /**
     * Removes the instance of the coursePack passed as parameter.
     * @param coursePack
     */
    public static deleteCoursePack(coursePackId: number) {
        return ApiService.put(
            `/clients/v2/course_packs/${coursePackId}/cancelled`
        );
    }

    /**
     * Returns all active coursePacks
     */
    public static getActiveCoursePacks(teachrId: number) {
            return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/active_course_packs`)
        ;
    }

    /**
     * Returns all past coursePacks for teachr
     */
    public static getPastCoursePacks(teachrId: number) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/historical_course_packs`
        );
    }

    /**
     * Returns all past coursePacks for client
     */
    public static getPastCoursePacksClient(clientId: number) {
        return ApiService.get(
            `/clients/v2/clients/${clientId}/historical_course_packs`
        );
    }

    /**
     * Returns available coursePacks for teachr
     */
    public static getAvailableCoursePacks(id: number) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${id}/available_course_packs`
        );
    }

    /**
     * Returns price estimation of a coursePacks for the Teach'rs
     */
    public static getPriceEstimationCoursePacks(
        teachrId: number,
        coursePackId: number
    ) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${teachrId}/course_packs/${coursePackId}/price_estimation`
        );
    }
}
