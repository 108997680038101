import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import mandataireSign from '../../../../Assets/Images/sign.png'
import { useNavigate } from 'react-router';
import Teachr from '../../../../Objects/models/Teachr';
import NavBar from '../../../../Components/Dashboard/NavBar';
import First from './First';
import Second from './Second';
import Fourth from './Fourth';
import Third from './Third';
import Last from './Last';
import ImageRepository from '../../../../Repositories/ImageRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Fifth from './Fifth';
import { Address } from '../../../../Objects/models/Address';
import AddressRepository from '../../../../Repositories/AddressRepository';
import withReactContent from "sweetalert2-react-content";
// import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import closeCircle from "../../../../Assets/Icons/close_circle.svg";
import { Button } from '@mui/material';
import Teachr_Repository from '../../../../Repositories/Teachr_Repository';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Finish = () => {
    const MySwal = withReactContent(Swal);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [file, setFile] = useState<any>();
    const [proofIentity, setProofIdentity] = useState<File>();
    const [pdfFile, setPdfFile] = useState<any>(process.env.PUBLIC_URL + '/Conditions_de_services_VDEF.pdf')
    const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null)
    const [numPages, setNumPages] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const generalTeachr = useSelector((state: any) => state.teachr)
    const [downloadLink, setDownloadLink] = useState<string>()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth - 35);
    const [showDoc, setShowDoc] = useState(false)
    const [address, setAddress] = useState<Address>()
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth - 35);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const validateWithCode = (code: string) => {
        return Teachr_Repository.teachrValidateAccountWithCode(teachr!.id!, code!)
    }
    const showValidateWidget = () => {
        if (teachr) {
            if (teachr.proofMandat && teachr.address) {
                MySwal.fire({
                    showCancelButton: false,
                    showConfirmButton: false,
                    html:
                        <div className='h-full'>
                            <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                                <p className=''>Valider mon compte</p>
                                <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <span className=''>Merci de bien vouloir renseigner le code de validation de votre compte qui vous a été transmis par l’un de nos administrateurs.</span>
                                <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                                    <label className='block lg:text-base'>Mon code</label>
                                    <input id="swal-input-code" className={` w-full lg:text-base bg-transparent placeholder:text-grey`} type="text" placeholder='...' />
                                </div>
                                <div className="flex py-8">
                                    <Button onClick={() => Swal.clickConfirm()}
                                        className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>
                                        Valider
                                    </Button>
                                </div>
                            </div>
                        </div>,
                    preConfirm: () => {
                        const code = (MySwal!.getPopup()!.querySelector('#swal-input-code')! as HTMLInputElement).value;
                        if (!code) {
                            Swal.showValidationMessage(`Erreur : code invalide.`);
                            return;
                        }
                        return validateWithCode(code).then(response => {
                            if (response[0] === "BAD_CODE") {
                                Swal.showValidationMessage(`Erreur : code invalide.`)
                            } else {
                                return { response: response[0] }
                            }
                        })
                    }
                    , allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        MySwal.fire({
                            showCancelButton: false,
                            showConfirmButton: false,
                            html:
                                <div className='h-full'>
                                    <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                                        <p className=''>Valider mon compte</p>
                                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                                    </div>
                                    <div className='flex flex-col gap-4'>
                                        <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                                            <span>Félicitations, votre compte est désormais validé 🥳</span>
                                            <span>Vous pouvez dès maintenant accéder à votre Tableau de bord et profiter de l'avance immédiate. </span>
                                        </div>
                                        <div className="flex py-8">
                                            <Button onClick={() => { navigate('/teachr/dashboard'); MySwal.close() }} className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>Tableau de bord</Button>
                                        </div>
                                    </div>
                                </div>,
                        })
                    }
                })
            } else if (!teachr.proofMandat) {
                navigate('/teachr/profile/complete')
            } else {
                setOpenDialog(true);
            }
        }

        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('h-half')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')

    }

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
        setLoading(false)
    }
    useEffect(() => {
        (async () => {
            const response = await fetch(pdfFile);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDocument = await PDFDocument.load(arrayBuffer);
            if (pdfDocument) {
                const form = pdfDocument.getForm();
                const nameField = form.getTextField('name');
                const siretField = form.getTextField('siret');
                const addressField = form.getTextField('address');
                const cityField = form.getTextField('city');
                const dateField = form.getTextField('date');
                dateField.setText(moment().format("L"))
                if (teachr) {
                    nameField.setText(teachr.firstName + ' ' + teachr.lastName);
                    siretField.setText(teachr.RCS);
                }
                if (address) {
                    addressField.setText(address.formattedAddress);
                    cityField.setText(address.city)
                }
                const pdfBytes = await pdfDocument.save();
                const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
                setPdfFile(pdfBlob)
            }
        })();
    }, [address, teachr])
    useEffect(() => {
        if (generalTeachr) {
            if (generalTeachr.teachr) {
                if (generalTeachr.teachr.proofMandat) {
                    setDownloadLink(generalTeachr.teachr.proofMandat.contentUrl)
                    setCurrentStep(6)
                    dispatch({
                        type: 'SET_SAP_STEP', payload: 6
                    })
                }
                if (generalTeachr.teachr.address) {
                    const addressId = parseInt(generalTeachr.teachr.address.match(/\d+/)[0])
                    AddressRepository.fetchAddressById(addressId).then((response) => {
                        setAddress(response)
                    })
                }
                setTeachr(generalTeachr.teachr)
            } else {
                navigate('/teachr/login')
            }

        } else {
            navigate('/teachr/login')
        }
    }, [generalTeachr])

    return (
        <div className=' overflow-y-auto bg-[#F5FAFF]'>
            <header className='lg:bg-white bg-[#F5FAFF]'>
                <NavBar />
            </header>
            <div className='lg:flex  lg:bg-white lg:w-4/5 mx-auto h-max overflow-y-hidden'>
                <div className='w-full  bg-[#F5FAFF] px-4 md:px-8 lg:px-10 pt-10 overflow-x-hidden'>
                    <div className='lg:flex justify-between hidden '>
                        <div className="flex items-center cursor-pointer gap-x-4" onClick={() => currentStep > 0 && setCurrentStep(currentStep - 1)}>
                            <span>
                                <svg width="39" height="34" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="39" height="34" rx="8" fill="#219CFF" fill-opacity="0.14" />
                                    <path d="M31 17.85C31.4694 17.85 31.85 17.4694 31.85 17C31.85 16.5306 31.4694 16.15 31 16.15L31 17.85ZM10.399 16.399C10.067 16.7309 10.067 17.2691 10.399 17.601L15.8083 23.0104C16.1403 23.3424 16.6785 23.3424 17.0104 23.0104C17.3424 22.6785 17.3424 22.1403 17.0104 21.8083L12.2021 17L17.0104 12.1917C17.3424 11.8597 17.3424 11.3215 17.0104 10.9896C16.6785 10.6576 16.1403 10.6576 15.8083 10.9896L10.399 16.399ZM31 16.15L11 16.15L11 17.85L31 17.85L31 16.15Z" fill="#0054A3" />
                                </svg>
                            </span>
                            <span className='text-first font-bold lg:text-[22px]'>Précédent</span>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className='lg:py-10'>
                        <div className='flex justify-between lg:gap-10 gap-y-4 flex-col lg:flex-row'>
                            {currentStep !== 0 &&
                                <div className='w-max hidden lg:block'>
                                    <Document
                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}


                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            }
                            <div className={currentStep !== 0 ? 'lg:w-1/2' : 'w-full'}>
                                <div className='flex h-full gap-10'>

                                    <div className='relative w-full'>
                                        <div className='flex items-center pb-10 gap-x-4'>
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="16" cy="16" r="16" fill="#0054A3" />
                                                <path d="M16.9503 13.056C17.8743 13.056 18.6956 13.2687 19.4143 13.694C20.133 14.1047 20.6903 14.6913 21.0863 15.454C21.497 16.202 21.7023 17.06 21.7023 18.028C21.7023 19.0253 21.475 19.92 21.0203 20.712C20.5803 21.4893 19.9643 22.098 19.1723 22.538C18.395 22.978 17.5076 23.198 16.5103 23.198C14.545 23.198 13.027 22.538 11.9563 21.218C10.9003 19.8833 10.3723 17.984 10.3723 15.52C10.3723 13.8333 10.6216 12.374 11.1203 11.142C11.619 9.89533 12.3303 8.942 13.2543 8.282C14.193 7.622 15.293 7.292 16.5543 7.292C17.3903 7.292 18.2263 7.446 19.0623 7.754C19.913 8.04733 20.6316 8.45067 21.2183 8.964C21.409 9.12533 21.541 9.28667 21.6143 9.448C21.7023 9.59467 21.7463 9.778 21.7463 9.998C21.7463 10.3207 21.651 10.5993 21.4603 10.834C21.2843 11.054 21.0716 11.164 20.8223 11.164C20.6756 11.164 20.5363 11.142 20.4043 11.098C20.2723 11.0393 20.111 10.9513 19.9203 10.834C18.703 9.98333 17.6176 9.558 16.6643 9.558C15.5936 9.558 14.7503 10.0567 14.1343 11.054C13.5183 12.0367 13.1956 13.4227 13.1663 15.212C13.5036 14.552 14.0096 14.0313 14.6843 13.65C15.359 13.254 16.1143 13.056 16.9503 13.056ZM16.3563 20.954C17.163 20.954 17.8083 20.6973 18.2923 20.184C18.7763 19.656 19.0183 18.9667 19.0183 18.116C19.0183 17.2653 18.7763 16.5833 18.2923 16.07C17.8083 15.5567 17.163 15.3 16.3563 15.3C15.535 15.3 14.8676 15.564 14.3543 16.092C13.8556 16.6053 13.6063 17.28 13.6063 18.116C13.6063 18.9667 13.8556 19.656 14.3543 20.184C14.8676 20.6973 15.535 20.954 16.3563 20.954Z" fill="white" />
                                            </svg>
                                            <h2 className='text-first lg:text-[22px]'>Terminé ! 🎉</h2>
                                        </div>
                                        {teachr && teachr.isAE && teachr.signSap ? null :
                                            <>
                                                <div className="flex flex-col text-xl font-semibold gap-y-8 text-normal">
                                                    <p>Tous vos documents sont maintenant finalisés.</p>

                                                    <p>Vous allez recevoir très prochainement un mail vous permettant d’accéder à l’avance immédiate et au Dashboard professeur.</p>

                                                </div>
                                                <div className="flex justify-center py-10">
                                                    <a target="_blank" download href={downloadLink} className='px-10 py-3 text-xl font-extrabold text-white rounded-full bg-first'>Télécharger le document</a>
                                                </div>
                                            </>
                                        }
                                        {/* <div className='text-base font-semibold text-center text-normal'>
                                            En attendant vous pouvez des à présent télécharger
                                            la plaquette de renseignement pour vos élèves
                                            <a href="" className='ml-2 font-bold underline text-first'> cliquant ici</a>
                                        </div> */}
                                        {
                                            teachr && teachr.isAE && teachr.signSap ?
                                                <div className="flex flex-col text-xl font-semibold gap-y-8 text-normal">
                                                    <p>Accédez à votre tableau de bord, ajoutez votre premier élève et effectuez votre première déclaration de cours.</p>
                                                    <Button onClick={() => { navigate('/teachr/dashboard') }} className='w-full py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>Dashboard</Button>
                                                </div>
                                                :
                                                <div className="flex flex-col text-xl font-semibold gap-y-8 text-normal">
                                                    <p>Veuillez valider votre compte en utilisant le code de validation qu'un administrateur vous a donné.</p>
                                                    {/* <Button onClick={() => { navigate('/teachr/dashboard') }} className='w-full py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>Dashboard</Button> */}
                                                    {/* <Button onClick={showValidateWidget} className="w-full mx-auto uppercase bg-first rounded-full text-white text-sm font-extrabold py-4"
                                                        style={{ background: "", boxSizing: "border-box", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", }}

                                                    >
                                                        <span className='uppercase'>Valider mon compte</span>
                                                    </Button> */}
                                                    <div className="flex justify-center">
                                                        <button onClick={showValidateWidget} className='p-2 lg:text-lg md:text-base text-sm font-extrabold text-center text-first uppercase transition duration-150 ease-in-out border-b-2 border-first'>Valider mon compte</button>
                                                    </div>
                                                </div>
                                        }
                                    </div>

                                </div>
                            </div>
                            {currentStep !== 0 ?
                                <div className='lg:hidden text-center'>
                                    <p onClick={() => setShowDoc(!showDoc)} className='underline border-radio text-radio font-bold text-lg  py-2'>{showDoc ? 'Fermer le document' : 'Voir les modifications sur le document'}</p>
                                    <div className={`${loading ? 'block' : 'hidden'} flex items-center gap-x-2 justify-center`}>
                                        <span className="animate-spin">
                                            <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.67991 14.238C2.13795 14.7791 2.13795 15.6569 2.67991 16.1984C3.22235 16.7398 4.10123 16.7398 4.6432 16.1984C5.18516 15.6569 5.18516 14.779 4.6432 14.238C4.10123 13.696 3.22238 13.696 2.67991 14.238Z" fill="#808080" />
                                                <path d="M3.27912 10.5341C3.27912 9.67331 2.57994 8.97412 1.71769 8.97412C0.855438 8.97412 0.15625 9.67331 0.15625 10.5341C0.15625 11.3959 0.855438 12.0941 1.71769 12.0941C2.57994 12.0941 3.27912 11.396 3.27912 10.5341Z" fill="#808080" />
                                                <path d="M3.65666 4.11816C2.69869 4.11816 1.92188 4.89404 1.92188 5.85151C1.92188 6.80851 2.69869 7.58435 3.65666 7.58435C4.61509 7.58435 5.39144 6.80851 5.39144 5.85151C5.39141 4.89401 4.61509 4.11816 3.65666 4.11816Z" fill="#808080" />
                                                <path d="M8.34566 2C7.292 2 6.4375 2.85397 6.4375 3.90669C6.4375 4.95937 7.29194 5.81334 8.34566 5.81334C9.40028 5.81334 10.2542 4.95937 10.2542 3.90669C10.2547 2.85397 9.40028 2 8.34566 2Z" fill="#808080" />
                                                <path d="M8.34662 15.5728C7.67625 15.5728 7.13281 16.1157 7.13281 16.7866C7.13281 17.456 7.67628 17.9995 8.34662 17.9995C9.0175 17.9995 9.56141 17.456 9.56141 16.7866C9.56141 16.1157 9.0175 15.5728 8.34662 15.5728Z" fill="#808080" />
                                                <path d="M14.9765 9.66748C14.4976 9.66748 14.1094 10.0551 14.1094 10.5341C14.1094 11.0131 14.4976 11.4008 14.9765 11.4008C15.456 11.4008 15.8446 11.0131 15.8446 10.5341C15.8446 10.0551 15.456 9.66748 14.9765 9.66748Z" fill="#808080" />
                                                <path d="M12.2969 14.4824C11.8906 14.8881 11.8906 15.5468 12.2969 15.9531C12.7036 16.3593 13.3622 16.3593 13.7694 15.9531C14.1757 15.5468 14.1757 14.8882 13.7694 14.4824C13.3622 14.0762 12.7036 14.0762 12.2969 14.4824Z" fill="#808080" />
                                                <path d="M13.1001 6.47799C13.4838 6.47799 13.7944 6.16792 13.7944 5.78464C13.7944 5.40183 13.4838 5.09127 13.1001 5.0918C12.7173 5.0918 12.4058 5.40183 12.4063 5.78464C12.4063 6.16792 12.7173 6.47799 13.1001 6.47799Z" fill="#808080" />
                                            </svg>
                                        </span>

                                        <span className='text-[#808080] font-medium text-sm'>Enregistrement automatique</span>
                                    </div>
                                </div> : null}
                            {currentStep !== 0 ?
                                <div className={`${showDoc ? 'block' : 'hidden'} w-full lg:hidden`}>
                                    <Document

                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}


                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={windowWidth}
                                        />
                                    </Document>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='relative h-full px-4 lg:hidden pb-14 md:px-8'>
                <div className="absolute top-1/3">
                    <h1 className='text-lg font-extrabold text-center text-first'>Cette Version n'est pas disponible sur des petits écrans</h1>
                </div>
            </div> */}

        </div >
    );
};

export default Finish;