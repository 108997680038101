import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));



export default function InfoArrayHomeAici() {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
      <React.Fragment>
        <button onClick={handleClickOpen} className="text-first font-bold">
          guide ici
        </button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className="text-[24px] font-extrabold text-first text-center "
          >
            {/* Qu’est-ce que l’Acre ? */}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Typography className="text-[#4d4d4d] text-[18px] ">
              Tout le processus administratif et la facturation sont pris en
              charge par Teach'r. Notre équipe s'occupera de toutes les
              démarches administratives à votre place (création du statut
              d'auto-entrepreneur et déclaration "Service à la Personne"). Il
              vous suffit de suivre la procédure mise en place par Teach’r et en
              quelques minutes, toutes vos procurations seront faites et votre
              inscription finalisée. Vous donnant la possibilité de vous
              focaliser entièrement sur votre activité pédagogique. Notre
              objectif est de faciliter votre quotidien et de vous permettre de
              vous concentrer sur ce qui compte vraiment : l'enseignement.
            </Typography>
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    );
}
