import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import { PunctualCourse } from "../Objects/models/PunctualCourse";
import HydraCollection from "../Objects/models/HydraCollection";
import { plainToClass } from "class-transformer";

export default abstract class PunctualCourseRepository {
    public static getPunctualCourses(
        page: number = 1,
        filters: Array<string> | null
    ) {
        return ApiService.getCollection<HydraCollection<PunctualCourse>>(
            Route.PUNCTUAL_COURSE_BASE,
            HydraCollection,
            true,
            page,
            filters
        );
    }
    public static getToken() {
        return ApiService.getValidJWTToken()
    }
    public static getAllAvailablePunctualCourse(teachrId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/available_punctual_courses`);
    }
    public static fetchPunctualCourseById(id: number) {
        return ApiService.getSingleInstance<PunctualCourse>(
            Route.PUNCTUAL_COURSE_BASE + "/" + id,
            PunctualCourse
        );
    }
    

}
