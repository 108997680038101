import React, { useEffect, useState } from "react";
import Functions from '../../../../../Helpers/Functions';
import { Avatar, Button, Dialog, DialogContent, DialogTitle, Tooltip } from '@mui/material';
import CopyToClipboard from "react-copy-to-clipboard";
import TeachrContactRepository from "../../../../../Repositories/TeachrContactRepository";
import Swal from "sweetalert2";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import Teachr_Repository from "../../../../../Repositories/Teachr_Repository";
import { validatePhoneNumber } from "../../../../../Validations/ValidationPhone";
import config from "../../../../../config";
import loader from "../../../../../Assets/Icons/loader-bleu.gif";
import SendTwoToneIcon from "@mui/icons-material/SendTwoTone";
import DeleteTwoToneIcon from "@mui/icons-material/DeleteTwoTone";
import { capitalizeFirstLetter, stepper } from "../UtilsDashboard/FunctionsDashbord";
import { statusTpdEnum } from "../../../../../Enum/Enum";
import ReplyAllTwoToneIcon from "@mui/icons-material/ReplyAllTwoTone";
let timerInterval: any;

interface statusInterface {
  valid: boolean;
  status: boolean;
}


const ComponentChildStudent = (props: any) => {
  const { handleLoadDelete, child, handleDeleteTrue, isLoad, setIsLoad, relance, handleRelance } = props
   const mode = config.debugMode;
  const statusTDP : statusInterface = {
    valid: child?.etatTdp === statusTpdEnum.VALIDE.etat && child?.codeTdp === statusTpdEnum.VALIDE.status,
    status:child?.status
  }
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [send, setSend] = useState(0)
  const [isCopy, setIsCopy] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const [load, setLoad] = useState(false);
  const [phone, setPhone] = useState("")
  const [error, setError] = useState({
    phone: "null",
  });
  useEffect(() => {
    setTimeout(() => {
      if (isCopy) {
        setIsCopy(false)
      }
    }, 300)
  }, [isCopy])
  const handleOpen = () => {
    setOpen(true)
  }
  const handleCloseDelete = () => {
    setOpenDelete(false)
    handleDeleteTrue(false)
  }
  const handleClose2 = () => {
    setSend(0)
    setOpen(false)
  }
  const validatePhone = () => {
    let errphone = true;
    if (validatePhoneNumber(phone)) {
      setError({
        phone: validatePhoneNumber(phone),
      });
      errphone = true;
    } else {
      setError({
        phone: validatePhoneNumber(phone),
      });
      errphone = false;
    }
    if (errphone) {
      return false;
    } else {
      return true;
    }
  };
  const handleSavePhone = () => {
    setIsLoad(true)
    if (validatePhone()) {
      setIsLoad(true)
      TeachrContactRepository.putPhoneNumber(child?.id, Functions.formatPhoneNumber(phone)).then((res) => {
        setIsLoad(true)
        Teachr_Repository.getCurrentTeachr().then((res: any) => {
          setIsLoad(true)
          Teachr_Repository.sendSmsToClient(res.id, child?.id).then((response: any) => {
            setIsLoad(true)
            handleClose2()
            setShowDialog(false)
            Swal.fire({
              icon: "success",
              title: "",
              text: "Vous venez d'envoyer un sms de relance à votre client.",
              timer: 5000,
              willClose: () => {
                setSend(0)
                clearInterval(timerInterval);
                handleRelance(false)
                setPhone("")
                setIsLoad(false)
              },
            })
          })
        })
      }).catch((err) => {
        console.warn(err);
        setIsLoad(false)
      })
    }
  }
  // const handleRelanceChild = () => {
  //   switch (send) {
  //     case 0:
  //       handleClose2()
  //       Swal.fire({
  //         icon: "warning",
  //         title: "",
  //         text: "Veuillez choisir une option",
  //         timer: 2000,
  //         willClose: () => {
  //           setOpen(true)
  //           clearInterval(timerInterval);
  //         },
  //       })
  //       break;
  //     case 1:
  //       Teachr_Repository.getCurrentTeachr().then((res: any) => {
  //         if (res?.id) {
  //           Teachr_Repository.sendSmsToClient(res.id, child?.id).then((response: any) => {
  //             if (response === "phone number not found") {
  //               setShowDialog(true)
  //             } else {
  //               if (res?.smsCount === 20) {
  //                 handleClose2()
  //                 Swal.fire({
  //                   icon: "warning",
  //                   title: "",
  //                   text: response,
  //                   timer: 5000,
  //                   willClose: () => {
  //                     clearInterval(timerInterval);
  //                     setSend(2)
  //                     setOpen(true)
  //                   },
  //                 })
  //               } else {
  //                 handleClose2()

  //                 Swal.fire({
  //                   icon: "success",
  //                   title: "",
  //                   text: "Vous venez d'envoyer un sms de relance à votre client.",
  //                   timer: 5000,
  //                   willClose: () => {
  //                     setSend(0)
  //                     clearInterval(timerInterval);
  //                     handleRelance(false)
  //                   },
  //                 })
  //               }
  //             }
  //           })
  //         }
  //       })
  //       break;
  //     case 2:
  //       Teachr_Repository.getCurrentTeachr().then((res) => {
  //         if (res?.id) {
  //           Teachr_Repository.sendMailToClient(res.id, child?.id).then((response) => {
  //             handleClose2()
  //             handleRelance(false)
  //             Swal.fire({
  //               icon: "success",
  //               title: "",
  //               text: "Vous venez d'envoyer un mail de relance à votre client.",
  //               timer: 5000,
  //               willClose: () => {
  //                 setSend(0)
  //                 clearInterval(timerInterval);
  //               },
  //             })
  //           })
  //         }
  //       })
  //       break;
  //     default:
  //       Swal.fire({
  //         icon: "warning",
  //         title: "Attention !",
  //         text: "Hmmm... erreur inconnue. Contactez-nous à l'adresse support@teachr.fr ou au " + config.phoneNumber + " si le problème persiste !",
  //         timer: 2000,
  //         willClose: () => {
  //           clearInterval(timerInterval);
  //         },
  //       });
  //       break;
  //   }
  // }

  const relanceClient = () => {
    Swal.fire({
      title: `<u>Relancer votre élève</u>`,
      html: `Vous avez la possibilité de relancer votre élève par e-mail ou par SMS.
          <br/>Veuillez choisir votre option de relance<br/>`,
      icon: "info",
      showCloseButton: true,
      showCancelButton: false,
      focusConfirm: false,
      showDenyButton: true,
      confirmButtonColor: "#0054A3",
      denyButtonColor: "#0054A3",
      confirmButtonText: "Par e-mail",
      denyButtonText: `Par SMS`,
      customClass: {
        actions: "my-actions",
        cancelButton: "order-1 right-gap",
        confirmButton: "order-2",
        denyButton: "order-2",
        closeButton: "red",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        //mail
        Teachr_Repository.getCurrentTeachr().then((res) => {
          if (res?.id) {
            Teachr_Repository.sendMailToClient(res.id, child?.id).then(
              (response) => {
                handleClose2();
                handleRelance(false);
                Swal.fire({
                  icon: "success",
                  title: "",
                  text: "Vous venez d'envoyer un mail de relance à votre client.",
                  timer: 5000,
                  willClose: () => {
                    setSend(0);
                    clearInterval(timerInterval);
                  },
                });
              }
            );
          }
        });
      } else if (result.isDenied) {
        //sms
        Teachr_Repository.getCurrentTeachr().then((res: any) => {
          if (res?.id) {
            Teachr_Repository.sendSmsToClient(res.id, child?.id).then(
              (response: any) => {
                if (response === "phone number not found") {
                  setShowDialog(true);
                } else {
                  if (res?.smsCount === 20) {
                    handleClose2();
                    Swal.fire({
                      icon: "warning",
                      title: "",
                      text: response,
                      timer: 5000,
                      willClose: () => {
                        clearInterval(timerInterval);
                        setSend(2);
                        setOpen(true);
                      },
                    });
                  } else {
                    handleClose2();

                    Swal.fire({
                      icon: "success",
                      title: "",
                      text: "Vous venez d'envoyer un sms de relance à votre client.",
                      timer: 5000,
                      willClose: () => {
                        setSend(0);
                        clearInterval(timerInterval);
                        handleRelance(false);
                      },
                    });
                  }
                }
              }
            );
          }
        });
      }
    });
  }

  // const relanceButton = () => {
  //   return (
  //     <>
  //       <ReplyAllTwoToneIcon
  //         className="text-dark_blue cursor-pointer"
  //         onClick={handleOpen}
  //       />
  //       <Dialog
  //         open={open}
  //         onClose={handleClose2}
  //         aria-labelledby="scroll-dialog-title"
  //         aria-describedby="scroll-dialog-description"
  //       >
  //         <div className="text-left p-3">
  //           <DialogTitle id="alert-dialog-title">
  //             <h2 className="mt-2 font-extrabold text-center uppercase text-first lg:text-xl">
  //               Choisir une option pour relancer l'élève
  //             </h2>
  //           </DialogTitle>
  //           <div className="w-full">
  //             <div className="flex justify-around mt-4">
  //               <div
  //                 className={
  //                   send === 1
  //                     ? "block bg-first text-white text-center space-y-2 cursor-pointer p-2 px-2 rounded-xl shadow-lg"
  //                     : "block text-[#4d4d4d] bg-white text-center space-y-2 cursor-pointer p-2 px-2 rounded-xl shadow-lg"
  //                 }
  //                 onClick={() => setSend(1)}
  //               >
  //                 {send === 1 ? (
  //                   <SmsIcon
  //                     sx={{
  //                       color: "white",
  //                       width: "40px",
  //                       height: "40px",
  //                     }}
  //                   />
  //                 ) : (
  //                   <SmsIcon
  //                     sx={{
  //                       color: "#0054A3",
  //                       width: "40px",
  //                       height: "40px",
  //                     }}
  //                   />
  //                 )}
  //                 <p className="font-bold  text-lg">Par SMS</p>
  //               </div>
  //               <div
  //                 className={
  //                   send === 2
  //                     ? "block bg-first text-white text-center space-y-2 cursor-pointer p-2 px-2 rounded-xl shadow-lg"
  //                     : "block text-[#4d4d4d] bg-white text-center space-y-2 cursor-pointer p-2 px-2 rounded-xl shadow-lg"
  //                 }
  //                 onClick={() => setSend(2)}
  //               >
  //                 {send === 2 ? (
  //                   <EmailIcon
  //                     sx={{
  //                       color: "white",
  //                       width: "40px",
  //                       height: "40px",
  //                     }}
  //                   />
  //                 ) : (
  //                   <EmailIcon
  //                     sx={{
  //                       color: "#0054A3",
  //                       width: "40px",
  //                       height: "40px",
  //                     }}
  //                   />
  //                 )}
  //                 <p className="font-bold  text-lg">Par Mail</p>
  //               </div>
  //             </div>
  //             <div className="flex w-full justify-around py-3 pt-6">
  //               <Button
  //                 onClick={() => handleClose2()}
  //                 className="block bg-[#4d4d4d] font-extrabold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max"
  //               >
  //                 Annuler
  //               </Button>
  //               <Button
  //                 onClick={() => handleRelanceChild()}
  //                 className="block bg-dark_blue font-extrabold   rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max"
  //               >
  //                 {isLoad
  //                   ? "Chargement..."
  //                   : send === 1
  //                   ? "Envoyer SMS"
  //                   : send === 2
  //                   ? "Envoyer Mail"
  //                   : "Choisir"}
  //               </Button>
  //             </div>
  //           </div>
  //         </div>
  //       </Dialog>
  //     </>
  //   );
  // }
  const compoonentDelete = () => {
    return (
      <div className="">
        <Tooltip title='Supprimer' >
          <DeleteTwoToneIcon
            className="text-dark_blue cursor-pointer"
            onClick={displayeDelete}
          />
        </Tooltip>
        <Dialog open={load} sx={{ backgroundColor: "transparent" }}>
          <div className="flex items-center justify-center /h-screen bg-transparent">
            <div className="w-1/3 lg:w-max bg-transparent">
              <img src={loader} alt="chargement bg-transparent" />
              <p className="py-8 text-center bg-transparent text-first">
                Chargement ...
              </p>
            </div>
          </div>
        </Dialog>
      </div>
    );
  };
  const displayeDelete = () => {
    return (
      <>
        {Swal.fire({
          title: "Êtes-vous sûr ?",
          text: 'En appuyant sur le bouton "Oui, supprimer!" , vous confirmez la suppression de l\'élève.',
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#4d4d4d",
          confirmButtonText: "Oui, supprimer !",
          cancelButtonText: "Annuler",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setLoad(true);
            handleLoadDelete();
            if (child?.id) {
              setLoad(true);
              TeachrContactRepository.deleteTeachrContact(child?.id)
                .then((res) => {
                  handleLoadDelete();
                  handleCloseDelete();
                  setLoad(false);
                  Swal.fire({
                    icon: "success",
                    title: "",
                    text: "Vous venez de supprimer cet élève de votre compte.",
                    timer: 5000,
                    willClose: () => {
                      clearInterval(timerInterval);
                    },
                  });
                })
                .catch((err) => {
                  setIsLoad(false);
                  setLoad(true);
                });
            }
          }
        })}
      </>
    );
  };
 
  return (
    <>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // borderRadius: "42px",
            position: "relative",
            overflow: "visible",
            boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)",
          },
        }}
      >
        <div className="flex flex-col p-4 md:p-4 gap-y-2">
          <DialogTitle id="alert-dialog-title">
            <h2 className="mt-2 font-extrabold /text-center uppercase text-first lg:text-xl">
              Ajouter un numéro de téléphone
            </h2>
          </DialogTitle>
          <DialogContent className="flex flex-col /py-5 /gap-y-8 relative">
            <p className="font-semibold text-[#4d4d4d] lg:text-xl">
              Cet élève à déjà n'a pas de numéro de téléphone. <br />
              Afin de pouvoir l'envoyer un sms de relance, veuillez resnseigner
              son numéro de téléphone.
            </p>
            <div className="mt-4">
              <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] text-[#4d4d4d] lg:text-lg md:text-base  text-sm rounded-lg w-full py-1 px-2 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                <label className="block pl-2 mb- lg:text-lg md:text-base  text-sm font-medium text-[#4d4d4d]">
                  Numéro de téléphone
                </label>
                <input
                  type="text"
                  id="phone"
                  className="bg-input-color text-[#4d4d4d] lg:text-lg md:text-base  text-sm focus:outline-none pl-2 focus:ring-0 w-full focus:border-1"
                  placeholder="Ex : 07 XX XX XX XX"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                />
              </div>
              <div className="flex  w-full mx-auto mb-4 -mt-3 space-x-2  translate-y-4 ">
                {error.phone === "null" || error.phone === "" ? " " : ""}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.phone === "null" || error.phone === ""
                    ? null
                    : validatePhoneNumber(phone)}
                </p>
              </div>
            </div>

            <Button
              onClick={() => handleSavePhone()}
              className="bg-dark_blue font-extrabold mx-auto rounded-full md:px-20 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max"
            >
              {isLoad ? "Chargement..." : "Valider"}
            </Button>
          </DialogContent>
        </div>
      </Dialog>
      <tr className="flex w-full group/item hover:bg-slate-100 hover:rounded-xl hover:py-2">
        <td className="py-2 md:w-2/5 w-2/4 flex items-center truncate">
          {statusTDP.valid ? (
            ""
          ) : (
            <CopyToClipboard
              text={
                `https://app.teachr.fr/client/complete-infos/${child?.key}`
                  ? `https://${
                      mode ? "staging" : "app"
                    }.teachr.fr/client/complete-infos/${child?.key}`
                  : ""
              }
              onCopy={() => setIsCopy(true)}
            >
              <Tooltip
                title={
                  isCopy
                    ? "Copié"
                    : `Cliquez sur ce lien pour copier et envoyer ce formulaire à votre élève : https://${
                        mode ? "staging" : "app"
                      }.teachr.fr/client/complete-infos/${child?.key}`
                }
              >
                <ContentCopyIcon sx={{ color: "#4d4d4d" }} />
              </Tooltip>
            </CopyToClipboard>
          )}
          <p className="font-nunito md:text-base text-gray-900 font-semibold /w-[100%] leading-normal md:p-2 ">
            {!child.studentName
              ? child.mail
              : capitalizeFirstLetter(
                  child.studentName +
                    " " +
                    (child?.name ? child?.name?.toUpperCase() : "")
                )}
          </p>
        </td>
        <td className="p-2 md:w-2/5 w-1/4 ">
          <div className="md:block hidden">{stepper(statusTDP)}</div>
          <div className="md:hidden">
            {child?.status
              ? "Complété"
              : statusTDP.valid
              ? "Validé"
              : "En cours"}
          </div>
        </td>
        <td className="py-2 md:w-1/5 w-1/4 flex justify-center ">
          {/* {relanceButton()} */}
          <Tooltip title='Relancer votre élève soit par e-mail ou par sms' >
            <ReplyAllTwoToneIcon
              className="text-dark_blue cursor-pointer"
              onClick={relanceClient}
            />
          </Tooltip>
          {/* {relanceButton()} */}
          {compoonentDelete()}
        </td>
      </tr>
    </>
  );
};

export default ComponentChildStudent;