import Image from "../../Assets/Images/logo_bleu.png";
//import  from "../../Assets/Images/Frame.png";
import FormMatiere from "./Forms/FormMatiere";
import { useEffect, useState } from "react";
import FormLocation from "./Forms/FormLocation";
import FormWhos from "./Forms/FormWhos";
import FormEnd from "./Forms/FormEnd";
import { Step, StepLabel, Stepper } from "@mui/material";

import ChoiceCourse from "./Forms/ChoiceCourse";
import CourseConfirmation from "./Forms/CourseConfirmation";
import LastStep from "./Forms/LastStep";
import icon1 from "../../Assets/Images/icone1.png";
import icon2 from "../../Assets/Images/Frame 21.png";
import icon3 from "../../Assets/Images/Frame 20.png";
import icon4 from "../../Assets/Images/Frame 19.png";
import icon5 from "../../Assets/Images/Frame 18.png";
// import load from "../../Assets/Images/hourglass.gif";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
// import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../Assets/Images/logo_bleu.png";
import frameLayout from "../../Assets/Images/Frame.png";
// import Index from "./Forms/ChoiceCourse/courses/Index";
import StepperStore from "../../LocalForage/StepperStore";
import { auth } from "../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import TimerClient from "./TimerClient";

const FormLayout = (props: any) => {
  // const date = Date.now()
  const navigate = useNavigate();
  useEffect(() => {
    document.body.classList.remove("bg-bg-home");
  }, []);
  const [user] = useAuthState(auth);
  const stepsTab = ["", "", "", "", "", ""];
  const [activeStep, setActiveStep] = useState<any>(0);

  const currentComponent = (current: number, component: any) => {
    setActiveStep(current);
    setBreak(component);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  };
  const [BreakComponent, setBreak] = useState(
    <ChoiceCourse
      currentComponent={currentComponent}
      handleNext={handleNext}
      handleBack={handleBack}
    />
  );

  useEffect(() => {
    StepperStore.setItem(
      user ? "currentStep" + user?.uid : "currentStep",
      activeStep,
      function () { }
    );
  }, [activeStep, user]);
  const getCurrent = async () => {
    try {
      const value = await StepperStore.getItem(
        user ? "currentStep" + user?.uid : "currentStep"
      );
      switch (value) {
        case null:
          setActiveStep(0);
          break;
        // case 3:
        //   setActiveStep(2)
        //   break;
        // case 4:
        //   setActiveStep(2)
        //   break;
        // case 6:
        //   setActiveStep(6)
        //   break
        case 7:
          setActiveStep(0);
          break;
        default:
          setActiveStep(value);
          break;
      }
    } catch (err) {
      setActiveStep(0);
    }
  };
  useEffect(() => {
    getCurrent();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function _conditionalComponent(step: number) {
    switch (step) {
      case 0:
        return <FormMatiere handleNext={handleNext} />;
      case 1:
        return <FormLocation handleNext={handleNext} handleBack={handleBack} />;
      case 2:
        return (
          <ChoiceCourse
            currentComponent={currentComponent}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );
      case 3:
        return BreakComponent;
      case 4:
        return <FormWhos handleNext={handleNext} handleBack={handleBack} />;
      case 5:
        return (
          <CourseConfirmation handleNext={handleNext} handleBack={handleBack} />
        );
      case 6:
        return <FormEnd handleNext={handleNext} handleBack={handleBack} />;
    }
  }

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 5,
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
    },
  }));

  const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "blue",
      height: 22,
      ...(ownerState.active && {
        color: "blue",
      }),
      "& .QontoStepIcon-completedIcon": {
        marginLeft: 80,
        marginTop: -28,
        color: "white",
        zIndEx: 1,
        fontSize: "24",
        backgroundColor: "#8CC63F",
        borderRadius: "50%",
        width: 30,
        height: 30,
      },
      "& .MuiStepLabel-root": {
        color: "gray !important",
      },
      "& .QontoStepIcon-circle": {
        marginLeft: 80,
        marginTop: -31,
        width: 30,
        height: 30,
        borderRadius: "50%",
      },
    })
  );

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
      1: (
        <div className="xl:w-7 lg:w-6 ">
          <img src={icon1} alt='icon'/>
        </div>
      ),
      2: (
        <div className="xl:w-7 lg:w-6">
          <img src={icon2} alt='icon'/>
        </div>
      ),
      3: (
        <div className="xl:w-7 lg:w-6">
          <img src={icon3} alt='icon' />
        </div>
      ),
      4: (
        <div className="xl:w-7 lg:w-6">
          <img src={icon4} alt='icon'/>
        </div>
      ),
      5: (
        <div className="xl:w-7 lg:w-6">
          <img src={icon5} alt='icon'/>
        </div>
      ),
    };

    const steps: { [index: string]: React.ReactElement } = {
      1: (
        <p className=" font-nunito   xl:text-[28px] lg:text-[20px] -mt-7">
          Quelle matière ?
        </p>
      ),
      2: (
        <p className=" font-nunito  xl:text-[28px] lg:text-[20px]  -mt-7">
          Domicile ou à distance ?
        </p>
      ),
      3: (
        <p className=" font-nunito  xl:text-[28px] lg:text-[20px]  -mt-7">
          Quel type de cours ?
        </p>
      ),
      4: (
        <p className=" font-nunito  xl:text-[28px] lg:text-[20px]  -mt-7">
          À qui s'adresse le cours ?
        </p>
      ),
      5: (
        <p className=" font-nunito  xl:text-[28px] lg:text-[20px]  -mt-7">
          Presque fini !
        </p>
      ),
    };
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (

          <div className="flex space-x-4 ">
            <Check className="QontoStepIcon-completedIcon lg:w-7 border-grey-800" />
            <div className="MuiStepLabel-root ">{steps[String(props.icon)]}</div>
          </div>
        ) : (
          <div className="flex space-x-4">
            <div className="QontoStepIcon-circle ">
              {icons[String(props.icon)]}
            </div>
            <div className="text-dark_blue">{steps[String(props.icon)]}</div>
          </div>
        )}
      </QontoStepIconRoot>
    );
  }

  const handleHome = () => {
    if (user) {
      navigate("/user/response");
    } else {
      window.location.href = "https://teachr.fr";
    }
  };

  return (
    <div className="box-border list-teacher  h-screen //overflow-hidden  lg:flex md:h-screen font-nunito">
      <div className="box-border  fixed z-40 items-center justify-between w-full p-4 font-semibold bg-white  lg:hidden">
        <div
          className="hidden lg:block mb-4 border"
          onClick={() => handleHome()}
        >
          <img className="cursor-pointer w-[189px]" src={logo} alt="" />
        </div>
        <div className="lg:hidden  mb-4 " onClick={() => handleHome()}>
          <img className="cursor-pointer w-[84px]" src={logo} alt="" />
        </div>
        <div className="mx-auto font-nunito">
          <Stepper
            activeStep={
              activeStep >= 6
                ? activeStep - 2
                : activeStep === 3 || activeStep === 4
                  ? activeStep - 1
                  : activeStep
            }
          >
            {stepsTab.map((step, index) => (
              <Step key={index}>
                {index === 5 ? null : <StepLabel></StepLabel>}
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div className="box-border shadow-xl  hidden h-screen overflow-hidden lg:w-1/3 bg-input-color lg:block text-md xl:text-xl">
        <div className="mt-4 cursor-pointer ml-14" onClick={() => handleHome()}>
          <img src={Image} alt="logo" className="w-32 " />
        </div>
        <div className=" lg:-ml-12 xl:-ml-8">
          <Stepper
            sx={{ gap: "0", marginTop: "5rem" }}
            activeStep={activeStep >= 6 ? activeStep - 2 : activeStep === 3 || activeStep === 4 ? activeStep - 1 : activeStep
            }
            orientation="vertical"
            connector={<QontoConnector />}
          >
            {stepsTab.map((step, index) => (
              <Step key={index}>
                <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div>
          <img src={frameLayout} alt="" className="mx-auto w-72" />
        </div>
      </div>
      <div className="h-screen overflow-y-scroll overflow-x-hidden md:list-teacher lg:w-2/3  md:mt-8 lg:mt-0">
        {activeStep === stepsTab.length + 1 ? (
          <LastStep handleNext={handleNext} />
        ) : activeStep === stepsTab.length + 2 ? (
          <TimerClient />
        ) : (
          <div className=" w-full">{_conditionalComponent(activeStep)}</div>
        )}
      </div>
    </div>
  );
};

export default FormLayout;
