import Child from "./Child";
import Teachr from "./Teachr";
declare class PunctualCourse {}

declare class Address {}

export default class Client {
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;

  firebaseAuthToken: string;
  firstName: string;
  lastName: string;
  phone: string;
  acceptMarketing: boolean;
  isNotify?: boolean;
  mail?: string;
  firebaseMessagingToken: string;
  isPremium: boolean;
  isParent: boolean;
  sex?: string; // MAN or WOMAN
  class?: number; // 12 (inferior to cP) 11 (CP) 10 9 8 7 6 5 4 3 2 1 0 (Terminal) -1 -2 -3 -4 -5 (Master2) -6 (more). !! Can't be null if isParent = false
  birthday?: Date;
  favoriteTeachrs?: Teachr[];
  otherInformation?: string;
  profilePicture?: string; // @id for Image class
  marriedName?: string;

  URSSAFPseudoSIRET?: string;
  URSSAFNumber?: string;

  promoCode?: String;
  isRegisteredByTeachr?: boolean;
  isRelationTeachr?: boolean;
  
  // readonly values
  readonly addresses: Address[];

  readonly punctualCourses: PunctualCourse[];

  readonly id?: number;
  readonly lastModificationDatetime?: Date;
  readonly creationDatetime?: Date;
  readonly idClientTiersPrestation?: string;
  //readonly children: Child[];

  // TODO see how to handle profilePicture

  constructor(
    firebaseAuthToken: string,
    firstName: string,
    lastName: string,
    phone: string,
    acceptMarketing: boolean,
    firebaseMessagingToken: string,
    isParent: boolean,
    isPremium: boolean,
    class_: number | undefined = undefined
  ) {
    this.firebaseAuthToken = firebaseAuthToken;
    this.firstName = firstName;
    this.lastName = lastName;
    this.phone = phone;
    this.acceptMarketing = acceptMarketing;
    this.firebaseMessagingToken = firebaseMessagingToken;
    this.isParent = isParent;
    this.isPremium = isPremium;
    this.class = class_;

    this.addresses = [];
    this.punctualCourses = [];
    this.favoriteTeachrs = [];
  }
}
