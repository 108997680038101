import React from 'react';

const Cgu = () => {
    return (
        <section className='py-8 bg-[#424242] font-sans'>
            <div className='mx-auto lg:w-[90%] text-white lg:text-sm text-[7px] flex flex-col gap-y-4 md:px-8 px-4'>
                <h2 className='font-sans text-white font-blod'>(1) Offre de bienvenue valable à partir du 21/08/2023 au 30/08/2023 pour toute première demande d'ouverture d'un compte courant (hors offres jeunes, PratiqPro, droit au compte et offre alternative) :</h2>
                <ul className='flex flex-col gap-y-4'>
                    <li className="before:content-['-'] font-sans"> Jusqu’à 80 euros offerts pour toute première ouverture d'un compte courant avec ou sans carte Visa. Cette prime est fractionnée en 8 versements mensuels de 10 euros, et est conditionnée au constat d’un minimum de 10 opérations débitrices (paiements CB et/ou prélèvements et/ou paiement chèques) par mois durant les 8 premiers mois suivant l’ouverture du compte. <br />Cette prime mensuelle de 10 euros sera versée pour les 8 premiers mois suivant l’ouverture au plus tard le 16 du mois suivant le constat de réalisation des conditions, sous réserve que le compte courant soit toujours ouvert à la date du versement.</li>
                    <li className="before:content-['-'] font-sans"> Une prime de 80 euros pour toute souscription à un contrat porteur carte Visa Premier ou Platinum et après activation de la carteCette prime sera versée le 16 du mois suivant l’activation de votre carte Visa Premier ou Platinum et au plus tard le 30 août 2023.</li>
                </ul>

                <p className='font-blod'> Profitez de l’offre épargne du moment avec l’ouverture de votre compte courant avec une carte Visa Classic, Premier ou Platinum avec souscription à l’option « Carte qui épargne », et ouvrez un livret d’épargne MonabanqTaux nominal annuel brut (hors prélèvements sociaux et fiscaux) de 3% applicable pendant 6 mois suivant l’ouverture de votre Livret d’épargne Monabanq dans la limite de 100 000 euros de dépôts, pour toute première ouverture entre le 29 mars 2023 et le 05 avril 2023.Au-delà de 100 000 euros d’épargne, les dépôts sont rémunérés au taux nominal brut annuel (hors prélèvements sociaux et fiscaux) de 1% susceptible de variation selon les conditions générales de banque. <br />Le premier versement sur votre Livret doit être effectué depuis votre compte bancaire Monabanq avant le 30 avril 2023. <br />Offre limitée à une seule ouverture de Livret d’épargne Monabanq par client. <br />Ce livret entre dans le champ de la garantie des dépôts. <br />Monabanq se réserve la possibilité à tout moment de modifier, suspendre ou mettre fin à cette offre sous réserve de la diffusion de l'information sur le site www.monabanq.com.</p>

                <ul className='flex flex-col gap-y-1'>
                    <li className="before:content-['•'] font-sans"> Offre applicable une seule fois par compte.</li>
                    <li className="before:content-['•'] font-sans"> Offre exclusivement réservée aux personnes majeures ne détenant aucun autre compte courant au préalable chez Monabanq.</li>
                    <li className="before:content-['•'] font-sans"> L'offre ne s'appliquera pas si le demandeur est déjà titulaire d'un compte PratiqPro.</li>
                    <li className="before:content-['•'] font-sans"> Dans le cadre d'une demande d'ouverture d'un compte joint, l'offre ne s'appliquera pas si l'un des co-titulaires est déjà client Monabanq.</li>
                    <li className="before:content-['•'] font-sans"> Offre valable sous réserve de l'acceptation du dossier par Monabanq et de l'ensemble des pièces justificatives demandées.</li>
                </ul>
                <p className='font-sans font-blod'> L'ouverture définitive du compte et l'octroi de moyens de paiement sont sous réserve d'acceptation par Monabanq.Vous disposez d'un délai légal de rétractation de 14 jours.</p>
                <p className='font-sans font-blod'> (2) Voir conditions sur le site monabanq.com
                </p>
            </div>

        </section>
    );
};

export default Cgu;