import React, { useEffect } from "react";
import { useState } from "react";
import CourseProposalRepository from "../../../../../../../../Repositories/CourseProposalRepository";
import moment from "moment";
import PunctualCourseRepository from "../../../../../../../../Repositories/PunctualCourseRepository";
import Functions from "../../../../../../../../Helpers/Functions";
import DateTime from "../../../../UtilsDashboard/DateTime";
import MultipleSelect from "../../../../../../../../Components/utils/MultiSelectClient";
import { PunctualCourseDashboardUpdate } from "../../../../../../../../Objects/models/PunctualCourseDashboardUpdate";
import DatePicker from "react-datepicker";
import CourseProposalDashboard from "../../../../../../../../Objects/models/CourseProposalDashbordUpdate";
import StepperStore from "../../../../../../../../LocalForage/StepperStore";
import TeachrRepository from "../../../../../../../../Repositories/TeachrRepository";
import ChildRepository from "../../../../../../../../Repositories/ChildRepository";
import CustomFeesRepository from "../../../../../../../../Repositories/CustomFeesRepository";
import { Skeleton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { validatePrice, extractDateTimeFromString, convertDateTimeFormat } from "../../../../UtilsDashboard/FunctionsDashbord";
import { validateDateTime, validateEndDateTime } from "../../../../../../../../Validations/ValidationBirthday";
import { DatePickerValue, EndDatePickerValue } from "../../../../UtilsDashboard/DemoDate";

const dureeListe: Array<any> = [
  { label: "15min", value: 0.5 },
  { label: "30min", value: 1 },
  { label: "45min", value: 1.5 },
  { label: "1h", value: 2 },
  { label: "1h15", value: 2.5 },
  { label: "1h30", value: 3 },
  { label: "1h45", value: 3.5 },
  { label: "2h", value: 4 },
  { label: "2h15", value: 4.5 },
  { label: "2h30", value: 5 },
  { label: "2h45", value: 5.5 },
  { label: "3h", value: 6 },
  { label: "3h15", value: 6.5 },
  { label: "3h30", value: 7 },
  { label: "3h45", value: 7.5 },
  { label: "4h", value: 8 },
];

const EditChoicePunctual = (props: any) => {
  // Exemple d'utilisation
  const { option } = props;
  const currentMonth = new Date().getMonth();
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const start = moment().add(0.2, "hours");
  const end = moment().add(1.7, "hours");
  let minute = start.minute(); // Obtenez les minutes
  let minuteEnd = end.minute(); // Obtenez les minutes
  if (minute % 5 !== 0) {
    // Si les minutes ne sont pas divisibles par 5
    minute += 5 - (minute % 5); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  if (minuteEnd % 5 !== 0) {
    // Si les minutes ne sont pas divisibles par 5
    minuteEnd += 5 - (minuteEnd % 5); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  const heureAvecMinute = moment(start).minute(minute); // Créez une nouvelle instance Moment avec les minutes mises à jour
  const heureAvecMinuteEnd = moment(end).minute(minuteEnd); // Créez une nouvelle instance Moment avec les minutes mises à jour
  const formattedTime = moment(option.startDatetime).format("HH:mm");
  const formattedEndTime = moment(option.endDatetime).format("HH:mm");
  const modifiedTime = moment(formattedTime, "HH:mm");
  const modifiedEndTime = moment(formattedEndTime, "HH:mm");
  const [load, setLoad] = useState(false);
  const [date, setDate] = useState(option.startDatetime);
  const [duration, setDuration] = useState<any>(option.duration);
  const [durationMonth, setDurationMonth] = useState<number>(option.duration / 2);
  const [startTime, setStartTime] = useState<any>(modifiedTime.toDate());
  const [endTime, setEndTime] = useState<any>(modifiedEndTime.toDate());
  const price = option?.teachrPrice.toString();
  const [teachrPrice, setTeachrPrice] = useState<any>();
  const [teachr, setTeachr] = useState<any>();
  const priceValid = teachrPrice === undefined ? (price / (1 - teachr?.defaultFees) / 2).toFixed(2) : teachrPrice;
  const [customFees, setCustomFees] = useState<any>();
  const [endDate, setEndDate] = useState<any>(option.endDatetime);
  const [startDate, setStartDate] = useState<any>(option.startDatetime);
  const [day, setDay] = useState(0);
  const [givenMonth, setGivenMonth] = useState();
  const [error, setError] = useState({
    price: "null",
    endDate: "null",
    startDate: "null",
  });
  const [error2, setError2] = useState({
    price: "null",
  });

  useEffect(() => {
    const dateTime = extractDateTimeFromString(option?.descriotion);
    // const givenDateString = "29/01/2024 09:41:49";
    const givenDateString = dateTime;
    const givenDate: any = convertDateTimeFormat(givenDateString);
    const givenMonths = givenDate?.getMonth();
    setGivenMonth(givenMonths);

    let today = firstDay.getTime();
    let dateGiven = givenDate.getTime();
    let msDay = 24 * 60 * 60 * 1000;
    let days = Math.floor((dateGiven - today) / msDay);
    setDay(days + 2);
  }, []);
  const handleAddDuration = () => {
    if (durationMonth <= 49.75) {
      setDurationMonth((currentValue: number) => currentValue + 0.25);
    }
  };
  const handleSubtractDuration = () => {
    if (durationMonth >= 0.5) {
      setDurationMonth((currentValue: number) => currentValue - 0.25);
    }
  };
  const validate = () => {
    let errprice = true;
    let errStartDate = true;
    let errEndDate = true;
    if (!validatePrice(priceValid, durationMonth / 2).isValid) {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errprice = true;
    } else {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errprice = false;
    }

    if (!validateDateTime(startDate).isValid) {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errStartDate = true;
    } else {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errStartDate = false;
    }
    if (!validateEndDateTime(startDate, endDate).isValid) {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errEndDate = true;
    } else {
      setError({
        price: validatePrice(priceValid, durationMonth / 2).message,
        endDate: validateDateTime(startDate).message,
        startDate: validateEndDateTime(startDate, endDate).message,
      });
      errEndDate = false;
    }
    if (errprice || errStartDate || errEndDate) {
      return false;
    } else {
      return true;
    }
  };
  const validatePunctual = () => {
    let errprice = true;
    if (!validatePrice(priceValid, duration).isValid) {
      setError2({
        price: validatePrice(priceValid, duration).message,
      });
      errprice = true;
    } else {
      setError2({
        price: validatePrice(priceValid, duration).message,
      });
      errprice = false;
    }

    if (errprice) {
      return false;
    } else {
      return true;
    }
  };
  const getDuration = (value: any) => {
    setDuration(value);
    setEndTime(
      moment(startTime).add(
        value.value === option.duration ? option.duration / 2 : value.value / 2,
        "hours"
      )
    );
  };
  const getDate = (value: any) => {
    setDate(value);
  };
  const getStartDate = (value: any) => {
    setStartDate(value);
  };
  const getEndDate = (value: any) => {
    setEndDate(value);
  };
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachrs: any) => {
      setTeachr(teachrs);
      ChildRepository.fetchChildById(option.forChild_id).then((res: any) => {
        const idClient = parseInt(res?.parent["@id"].substr(9));
        if (idClient) {
          CustomFeesRepository.getCustomFeesSingle(idClient, teachrs.id).then(
            (response) => {
              if (response.length === 0) {
                setCustomFees("nothing");
              } else {
                setCustomFees(response[0]);
              }
            }
          );
        }
      });
    });
  }, [option.forChild_id]);

  const updatePunctualCourse = () => {
    if (validatePunctual()) {
      setLoad(true);
      let startDate = moment(date);
      let startDateTime = startDate
        .set("hour", moment(startTime).hours())
        .set("minutes", moment(startTime).minutes())
        .toDate();
      // .subtract(1, 'hours').format("YYYY-MM-DD HH:mm");
      let endDateTime = startDate
        .set("hour", moment(endTime).hours())
        .set("minutes", moment(endTime).minutes())
        .toDate();
      // .subtract(1, 'hours').format("YYYY-MM-DD HH:mm");
      StepperStore.setItem("course_ponctual_update", startDateTime);
      const course = new PunctualCourseDashboardUpdate(
        startDateTime,
        endDateTime,
        duration === option.duration ? (option.duration).toString() : (duration.value).toString()
      );
      PunctualCourseRepository.updatePunctualCourseByTeachr(option.id, course)
        .then((res) => {
          setLoad(true);
          const proposals = new CourseProposalDashboard(
            teachrPrice === undefined
              ? price
              : (teachrPrice * 2 * (1 - teachr?.defaultFees)).toString(),
            startDateTime,
            endDateTime
          );
          if (option?.acceptedProposal_id) {
            CourseProposalRepository.updateCourseProposalCourseByTeachr(
              option?.acceptedProposal_id,
              proposals
            )
              .then((res) => {
                props.backStep(3);
              })
              .catch((err) => {
                setLoad(false);
              });
          }
        })
        .catch((err) => {
          setLoad(false);
        });
    }
    setLoad(false);
  };
  const updatePunctualCourseMonth = () => {
    if (validate()) {
      setLoad(true);
      const today = new Date();
      const min = today.getMinutes();
      const valeur = 60 - min;
      const newMin = valeur + min;
      let startDateTime = moment(startDate)
        .set("hour", today.getHours() + 1)
        .set("minutes", newMin)
        .toDate();
      let endDateTime = moment(endDate)
        .set("hour", today.getHours() + 1)
        .set("minutes", newMin)
        .toDate();
      StepperStore.setItem("course_ponctual_update", startDateTime);
      const course = new PunctualCourseDashboardUpdate(
        startDateTime,
        endDateTime,
        durationMonth === option.duration ? (option.duration).toString() : (durationMonth * 2).toString()
      );
      PunctualCourseRepository.updatePunctualCourseByTeachr(option.id, course)
        .then((res) => {
          setLoad(true);
          const proposals = new CourseProposalDashboard(
            teachrPrice === undefined
              ? price
              : (teachrPrice * 2 * (1 - teachr?.defaultFees)).toString(),
            startDateTime,
            endDateTime
          );
          if (option?.acceptedProposal_id) {
            CourseProposalRepository.updateCourseProposalCourseByTeachr(
              option?.acceptedProposal_id,
              proposals
            )
              .then(() => {
                props.backStep(3);
                setLoad(false);
              })
              .catch((err) => {
                console.warn(err);
                setLoad(false);
              });
          }
        })
        .catch((err) => {
          console.warn(err);
          setLoad(false);
        });
    }
    // setLoad(false);
  };
  const handleChange = (value: any) => {
    const tomorrow = today.setDate(today.getDate());
    let startTimes = moment(value);
    let startDates = moment(date);
    let nows = new Date();
    let startDateTimeValid = startDates
      .set("hour", startTimes.hours())
      .set("minutes", startTimes.minutes()).toDate();

    if (startDateTimeValid < nows) {
      setStartTime(heureAvecMinute.toDate());
      setEndTime(
        moment(heureAvecMinute)
          .add(
            duration === option.duration ? option.duration / 2 : duration / 2,
            "hours"
          )
          .toDate()
      );
    }
    else {
      setStartTime(value);
      setEndTime(
        moment(value)
          .add(
            duration === option.duration ? option.duration / 2 : duration / 2,
            "hours"
          )
          .toDate()
      );
    }
  };
  const showInputPunctual = () => {
    return (
      <div className="mt-4 lg:mt-8 ">
        {/* <h1 className="xl:text-xl lg:text-lg text-base font-bold text-dark_blue">
          Champs modifiables :{" "}
        </h1> */}
        <div className="mt-2 lg:grid lg:grid-cols-2 gap-4 lg:text-lg text-base bold">
          <div className=" w-full ">
            <label className="text-[#4d4d4d] font-bold">Durée :</label>
            <MultipleSelect
              data={dureeListe}
              callBack={getDuration}
              placeholder="Ex : 2h"
              value={
                duration === option.duration
                  ? Functions.renderDuration(option.duration)
                  : Functions.renderDuration(duration.value)
              }
              customClass="text-base border border-[#4d4d4d] text-center w-full "
            />
          </div>
          <div className="w-full ">
            <label className="text-[#4d4d4d] font-bold">
              Heure de cours :{" "}
            </label>
            <div className="border border-[#4d4d4d] w-full rounded-lg text-center py-4 pl-4">
              <div className="md:w-[90%] items-center flex">
                <div className=" w-1/4 ">
                  <DatePicker
                    selected={startTime}
                    value={startTime}
                    onChange={(newValue) => {
                      handleChange(newValue);
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    timeFormat="HH:mm"
                    timeCaption=""
                    locale="fr"
                    placeholderText="Sélectionner l'heure"
                    customInput={
                      <input
                        className="//border-2 //text-center mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-base"
                        type="text"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="text-base rounded-md w-full font-nunito lg:text-lg">
            <label className="text-[#4d4d4d] font-bold">Jour de cours : </label>
            <div className="border border-[#4d4d4d] rounded-lg lg:py-2">
              <DateTime value={date} callBack={getDate} />
            </div>
          </div>
          {customFees === undefined ? (
            <div className="block lg:w-full">
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          ) : customFees === "nothing" ? (
            <div className="block lg:w-full">
              <label className="text-[#4d4d4d] font-bold">
                Prix horaire payé après crédit d'impôt :
              </label>
              <div className="border border-[#4d4d4d]  rounded-lg w-full text-center py-4">
                <input
                  type="number"
                  value={
                    teachrPrice === undefined
                      ? (price / (1 - teachr?.defaultFees) / 2).toFixed(2)
                      : teachrPrice
                  }
                  className="//text-center pl-4  w-full"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              </div>
              <div className="flex w-full mx-auto space-x-2 text-base /translate-y-4 lg:text-lg lg:text-md ">
                <p className="text-[#FF724F] h-6 w-full lg:text-lg lg:text-md text-base //mb-4 lg:-mt-[2px]">
                  {error2.price === "null" || error2.price === ""
                    ? null
                    : validatePrice(priceValid, duration).message}
                </p>
              </div>
            </div>
          ) : customFees?.is_teachr_contact ? (
            <div className="block lg:w-full">
              <label className="text-[#4d4d4d] font-bold">
                Prix horaire payé après crédit d'impôt :
              </label>
              <div className="border border-[#4d4d4d]  rounded-lg w-full text-center py-4">
                <input
                  type="number"
                  value={
                    teachrPrice === undefined
                      ? (price / (1 - teachr?.defaultFees) / 2).toFixed(2)
                      : teachrPrice
                  }
                  className="//text-center pl-4  w-full"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              </div>
              <div className="flex w-full mx-auto space-x-2 text-base /translate-y-4 lg:text-lg lg:text-md ">
                <p className="text-[#FF724F] h-6 w-full lg:text-lg lg:text-md text-base //mb-4 lg:-mt-[2px]">
                  {error.price === "null" || error.price === ""
                    ? null
                    : validatePrice(priceValid, duration).message}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  const showInputPunctualMonth = () => {
    return (
      <div className="mt-4 lg:mt-8 ">
        {/* <h1 className="xl:text-xl lg:text-lg text-base font-bold text-dark_blue">
          Champs modifiables :{" "}
        </h1> */}
        <div className="mt-2 lg:grid lg:grid-cols-2 gap-4 lg:text-lg text-base bold">
          <div className="text-base rounded-md w-full font-nunito lg:text-lg">
            <label className="text-[#4d4d4d] font-bold">Date de debut : </label>
            <div className="border border-[#4d4d4d] rounded-lg">
              <DatePickerValue
                value={startDate}
                callBack={getStartDate}
                size="18"
                minDate={
                  givenMonth === currentMonth
                    ? new Date().setDate(day)
                    : firstDay
                }
              />
            </div>
            <div className="flex w-full mx-auto space-x-2">
              <p className="text-[#FF724F] w-full lg:text-base text-sm ">
                {error.startDate === "null" ? null : validateDateTime(startDate).message}
              </p>
            </div>
          </div>
          <div className="text-base rounded-md w-full font-nunito lg:text-lg">
            <label className="text-[#4d4d4d] font-bold">Date de fin : </label>
            <div className="border border-[#4d4d4d] rounded-lg flex">
              <EndDatePickerValue
                value={endDate}
                size="18"
                callBack={getEndDate}
                minDate={moment(startDate).toDate()}
              />
            </div>
            <div className="flex w-full mx-auto space-x-2">
              <p className="text-[#FF724F] w-full lg:text-base text-sm ">
                {error.endDate === "null"
                  ? null
                  : validateEndDateTime(startDate, endDate).message}
              </p>
            </div>
          </div>
          {customFees === undefined ? (
            <div className="block lg:w-full">
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          ) : customFees === "nothing" ? (
            <div className="block lg:w-full">
              <label className="text-[#4d4d4d] font-bold">
                Prix horaire payé après crédit d'impôt :
              </label>
              <div className="border border-[#4d4d4d]  rounded-lg w-full text-center py-4">
                <input
                  type="number"
                  value={
                    teachrPrice === undefined
                      ? (price / (1 - teachr?.defaultFees) / 2).toFixed(2)
                      : teachrPrice
                  }
                  className="//text-center pl-4  w-full"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              </div>
            </div>
          ) : customFees?.is_teachr_contact ? (
            <div className="block lg:w-full">
              <label className="text-[#4d4d4d] font-bold">
                Prix horaire payé après crédit d'impôt :
              </label>
              <div className="border border-[#4d4d4d]  rounded-lg w-full text-center py-4">
                <input
                  type="number"
                  value={
                    teachrPrice === undefined ? (price / (1 - teachr?.defaultFees) / 2).toFixed(2): teachrPrice
                  }
                  className="//text-center pl-4  w-full"
                  onChange={(e) => setTeachrPrice(e.target.value)}
                />
              </div>
            </div>
          ) : (
            ""
          )}
          <div className=" w-full ">
            <label className="text-[#4d4d4d] font-bold">
              Nombre d’heures de cours :
            </label>
            <div className="border border-[#4d4d4d] rounded-lg py-3 px-4">
              <div className="w-full flex">
                <input
                  disabled
                  type="text"
                  id="firs_firstName"
                  className=" w-3/4 bg-transparent focus:outline-none focus:ring-0 "
                  placeholder="Ex : 1h30"
                  value={Functions.renderDuration(durationMonth * 2)}
                />
                <div className=" /p-2 gap-x-2 flex w-1/4">
                  <button
                    onClick={handleSubtractDuration}
                    className="border-[4px] border-white bg-red-400 font-extrabold text-white rounded-lg w-1/2"
                  >
                    -
                  </button>
                  <button
                    onClick={handleAddDuration}
                    className="border-[4px] border-white bg-first font-extrabold text-white rounded-lg w-1/2"
                  >
                    <AddIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full mx-auto space-x-2">
          <p className="text-[#FF724F] w-full lg:text-base text-sm ">
            {error.price === "null" || error.price === "" ? null : validatePrice(priceValid, durationMonth / 2).message}
          </p>
        </div>
      </div>
    );
  };
  const showFooter = () => {
    return (
      <>
        <div className="lg:hidden flex justify-around space-x-4 pb-4 translate-y-6">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
            <div
              onClick={() => {
                props.backStep(1);
              }}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            {customFees === undefined ? (
              <div className="lg:px-4 lg:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold /bg-gray-600 text-white uppercase rounded-full ">
                chargement...
              </div>
            ) : (
              <>
                {
                  option?.isPunctualMonth ?
                    <div
                      onClick={() => {
                        updatePunctualCourseMonth();
                      }}
                      className="lg:px-4 lg:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-ba border-first text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
                    >
                      {load ? "chargement..." : "Enregistrer"}
                    </div> :
                    <div
                      onClick={() => {
                        updatePunctualCourse();
                      }}
                      className="lg:px-4 lg:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-ba border-first text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
                    >
                      {load ? "chargement..." : "Enregistrer"}
                    </div>
                }
              </>
            )}
          </div>
        </div>
        <div className=" hidden translate-y-10 p-2 -mt-1 lg:flex justify-between  right-0 px-14">
          <div>
            <button
              onClick={() => {
                props.backStep(1);
              }}
              className="py-2 font-extrabold text-first uppercase rounded-full text-base px-14 bg-white border-2 border-first"
            >
              precedent
            </button>
          </div>
          {customFees === undefined ? (
            <div className="">
              <button className="py-2 font-extrabold text-white uppercase rounded-full text-base px-14 bg-gray-300">
                En chargement...
              </button>
            </div>
          ) : (
            <div className="">
              {option?.isPunctualMonth ?
                <button
                  onClick={() => {
                    updatePunctualCourseMonth();
                  }}
                  disabled={load}
                  className={
                    load
                      ? "py-2 font-extrabold text-white uppercase rounded-full text-base px-14 bg-gray-300"
                      : "py-2 font-extrabold text-white uppercase rounded-full text-base px-14 bg-first"
                  }
                >
                  {load ? "chargement..." : "ENREGISTRER"}
                </button> :
                <button
                  onClick={() => {
                    updatePunctualCourse();
                  }}
                  disabled={load}
                  className={
                    load
                      ? "py-2 font-extrabold text-white uppercase rounded-full text-base px-14 bg-gray-300"
                      : "py-2 font-extrabold text-white uppercase rounded-full text-base px-14 bg-first"
                  }
                >
                  {load ? "chargement..." : "ENREGISTRER"}
                </button>}
            </div>
          )}
        </div>
      </>
    );
  };
  if (customFees === undefined) {
    return (<div className="block h-screen lg:w-full">
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </div>)
  } else {
    return (
      <>
        {option?.isPunctualMonth ? showInputPunctualMonth() : showInputPunctual()}
        {showFooter()}
      </>
    );

  }

};

export default EditChoicePunctual;
