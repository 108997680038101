import ApiService from "../Services/ApiService";
import { SubscriptionCourseProposal } from "../Objects/models/SubscriptionCourseProposal";
import { plainToClass } from "class-transformer";
import { SubscriptionCourseProposalDashboardUpdate } from "../Objects/models/SubscriptionCourseProposalDahboardUpdate";

export default abstract class SubscriptionCourseProposalRepository {

    /**
     * Creates an instance of SubscriptionPack into the API.
     * @param coursePack
     */
    public static createSubscriptionCourseProposal(subCourseProposal: SubscriptionCourseProposal, teachrId?: number, courseId?: number) {
        return ApiService.post(`/teachrs/v2/teachrs/${teachrId}/subscription_courses/${courseId}/proposals`, subCourseProposal);
    }
    public static createSubscriptionCourseProposalByTeachr(subCourseProposal: SubscriptionCourseProposal, teachrId?: number, courseId?: number) {
        return ApiService.post(`/teachrs/v2/teachrs/${teachrId}/subscription_courses_by_dashboard/${courseId}/proposals`, subCourseProposal);
    }
    public static getAllPendingSubscriptionCourseProposals(teachrId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/pending_subscription_course_proposals`)
            .then(body => plainToClass(SubscriptionCourseProposal, body));
    }
    public static getAllProposalToGivenSubCourse(clientId: number, courseId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_courses/${courseId}/proposals`)
            .then(body => plainToClass(SubscriptionCourseProposal, body));
        ;
    }

    public static fetchSubscriptionCourseProposalByIdClient(clientId: number, proposalId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_course_proposals/${proposalId}`);
    }

    public static fetchSubscriptionCourseProposalByIdTeachr(teachrId: number, proposalId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/subscription_course_proposals/${proposalId}`);
    }

    /**
     * refuse a subCourseProposal.
     */
    public static refuseSubCourseProposal(clientId: number, subCourseProposalId: number) {
        return ApiService.put(`/clients/v2/clients/${clientId}/subscription_courses_proposals/${subCourseProposalId}/refused`);
    }

    /**
     * Try to accept a subCourseProposal by teachr (payment process).
     */
    public static async tryAcceptSubCourseProposalByTeachr(teachrId: number, subCourseProposalId: number) {
        return ApiService.put(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses_proposals/${subCourseProposalId}/try-accept`
        );
    }

    /**
     * Try to accept a subCourseProposal (payment process).
     */
    public static async tryAcceptSubCourseProposal(clientId: number, subCourseProposalId: number) {
        return ApiService.put(
            `/clients/v2/clients/${clientId}/subscription_courses_proposals/${subCourseProposalId}/try-accept`
        );
    }

    /**
     * update the subscriptionCourse price by teachr to dashboard
     */
    // public static async updateSubscriptionCourseProposalCourseByTeachr(subCourseProposalId: number) {
    //     return ApiService.put(
    //         `/teachrs/subscription_course_proposal/${subCourseProposalId}/update`
    //     );
    // }

    /**
*  update the subscriptionCourse price by teachr to dashboard
* @param subscriptionCourseProposalDashboardUpdate
*/
    public static updateSubscriptionCourseProposalCourseByTeachr(subCourseProposalId: number, subscriptionCourseProposalDashboardUpdate: SubscriptionCourseProposalDashboardUpdate) {
        return ApiService.putInstance<SubscriptionCourseProposalDashboardUpdate>(
            `/teachrs/subscription_course_proposal/${subCourseProposalId}/update`,
            SubscriptionCourseProposalDashboardUpdate,
            subscriptionCourseProposalDashboardUpdate
        );
    }


    public static async acceptSubCourseProposalAfter3dSecure(clientId: number, subCourseProposalId: number) {
        return ApiService.put(`/clients/v2/clients/${clientId}/subscription_course_proposals/${subCourseProposalId}/accept-after-3d-secure`);
    }

    public static deleteSubscriptionCourseProposal(teachrId: number, subCourseProposalId: number) {
        return ApiService.deleteInstance(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses_proposals/${subCourseProposalId}`
        );
    }


}
