import localforage from "localforage";

let StepperStore = localforage.createInstance({
  driver: localforage.INDEXEDDB, // Force WebSQL; same as using setDriver()
  name: "Teach-r",
  version: 1.0,
  size: 4980736, // Size of database, in bytes. WebSQL-only for now.
  storeName: "stepper", // Should be alphanumeric, with underscores.
});
 export default StepperStore
