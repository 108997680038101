import React, { useEffect, useState } from 'react';
import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import RadioButton from '../../../Components/utils/RadioButton';
import Functions from '../../../Helpers/Functions';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ["PDF", "PNG", "JPEG", "JPG"];

const Third = (props: any) => {
    const [isFrance, setIsFrance] = useState(1)
    const [file, setFile] = useState<File | null>();
    const [file2, setFile2] = useState<File | null>();
    const [error, setError] = useState(false)
    const [error2, setError2] = useState(false)

    const loadFile = (value: any) => {
        if (value) {
            setError(false)
            if (value.type.search("image") === 0) {
                const fileType = value.type.split('/')
                Functions.resizeFile(value, true, fileType[1]).then((response: any) => {
                    setFile(response);
                }).catch((error) => {
                    console.warn(error)
                })
            } else {
                setFile(value)
            }
        }
    };
    const loadFile2 = (value: any) => {
        if (value) {
            // setError(false)
            if (value.type.search("image") === 0) {
                const fileType = value.type.split('/')
                Functions.resizeFile(value, true, fileType[1]).then((response: any) => {
                    setFile2(response);
                }).catch((error) => {
                    console.warn(error)
                })
            } else {
                setFile2(value)
            }
        }
    };
    useEffect(() => {
        if (file) {
            props.getProofIdentity(file)
        }
        if (file2) {
            props.getProofIdentity2(file2)
        }

    }, [file, file2])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsFrance(parseInt((event.target as HTMLInputElement).value));
    };
    const nextPage = () => {
        if (file) {
            props.callBack(3)
        }
    }
    return (
        <div className='flex h-full gap-10'>
            <div className='w-full relative'>
                <div className='flex items-center gap-x-4 pb-10'>
                    <span className="hidden lg:block">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#0054A3" />
                            <path d="M20.3496 20.712C21.2296 20.712 21.6696 21.086 21.6696 21.834C21.6696 22.6113 21.2296 23 20.3496 23H11.9896C11.6082 23 11.3076 22.89 11.0876 22.67C10.8676 22.4353 10.7576 22.1493 10.7576 21.812C10.7576 21.372 10.9262 20.976 11.2636 20.624L16.2796 15.234C16.8956 14.5593 17.3429 13.9433 17.6216 13.386C17.9149 12.814 18.0616 12.2493 18.0616 11.692C18.0616 11.0027 17.8636 10.4747 17.4676 10.108C17.0716 9.74133 16.5069 9.558 15.7736 9.558C15.1722 9.558 14.5856 9.67533 14.0136 9.91C13.4562 10.13 12.8916 10.438 12.3196 10.834C12.1729 10.9367 12.0262 11.0247 11.8796 11.098C11.7329 11.1567 11.5862 11.186 11.4396 11.186C11.1902 11.186 10.9702 11.0687 10.7796 10.834C10.6036 10.5993 10.5156 10.3207 10.5156 9.998C10.5156 9.778 10.5522 9.58733 10.6256 9.426C10.7136 9.26467 10.8456 9.11067 11.0216 8.964C11.6669 8.45067 12.4222 8.04733 13.2876 7.754C14.1529 7.446 15.0182 7.292 15.8836 7.292C17.3942 7.292 18.5969 7.67333 19.4916 8.436C20.4009 9.19867 20.8556 10.2253 20.8556 11.516C20.8556 12.4107 20.6649 13.254 20.2836 14.046C19.9022 14.8233 19.2569 15.696 18.3476 16.664L14.5416 20.712H20.3496Z" fill="white" />
                        </svg>
                    </span>
                    <h2 className='text-first lg:text-[22px] text-lg font-bold'>Annexe 1 : Document à joindre</h2>
                </div>
                <div className="flex flex-col gap-y-8">
                    <div className="flex lg:items-center flex-col lg:flex-row gap-x-10">
                        <label className='text-normal lg:text-xl font-semibold text-base' htmlFor="">Nationalité :</label>
                        <RadioGroup row
                            className='flex flex-row'
                            value={isFrance}
                            name="customized-radios"
                            onChange={handleChange}
                        >
                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Française" />
                            <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Étrangère" />
                        </RadioGroup>
                    </div>
                    {
                        isFrance ?
                            <div className='flex flex-col gap-y-8'>
                                {!file &&
                                    <>
                                        <p className='text-normal font-semibold lg:text-base'><span className='font-bold'>Carte d’identité ou passeport</span> toujours en cours de validité, numérisée avec la mention manuscrite suivante : </p>
                                        <p className='text-normal font-semibold lg:text-base'>“ j’atteste sur l’honneur que ce document d’identité est conforme à l’original”</p>
                                    </>}
                                {error ?
                                    <FileUploader
                                        classes='focus-within:bg-first  '
                                        children={
                                            <div className='flex items-center flex-col rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-y-8'>
                                                <p className='text-third'>Veuillez insérer un fichier au format : ”PDF, PNG ou JPEG” ❌</p>
                                                <p className='text-sm font-semibold text-center text-normal'>
                                                    Glisser votre carte d’identité ou passeport ou <u className='cursor-pointer'>choisissez un fichier</u>
                                                </p>
                                            </div>
                                        }
                                        onTypeError={(value: any) => setError(true)}
                                        handleChange={loadFile}
                                        name="file"
                                        types={fileTypes}
                                    />
                                    :
                                    file ?
                                        <div className='lg:order-first space-y-4'>
                                            <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 order-last' >
                                                <div className="flex items-center gap-x-4">
                                                    <p>“{file.name}” </p>
                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                                    </svg>
                                                </div>
                                                <Button onClick={() => setFile(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                                            </div>
                                            {
                                                file2 ?
                                                    <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 order-last' >
                                                        <div className="flex items-center gap-x-4">
                                                            <p>“{file2.name}” </p>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                                            </svg>
                                                        </div>
                                                        <Button onClick={() => setFile2(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                                                    </div> :
                                                    <div className=''>
                                                        <p className='text-normal font-semibold lg:text-base pb-4'><span className='font-bold'> Voulez-vous ajouter un fichier supplémentaire ? </span> </p>
                                                        <FileUploader
                                                            classes='focus-within:bg-first'
                                                            children={
                                                                <div className='flex items-center  rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-x-8'>
                                                                    <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.081 14.3031C12.9587 14.1766 12.8365 14.05 12.7143 14.05C12.4698 13.9234 12.1031 13.9234 11.7364 14.05C11.6142 14.1766 11.4919 14.1766 11.3697 14.3031L7.70264 18.1002C7.2137 18.6065 7.2137 19.3659 7.70264 19.8722C8.19158 20.3785 8.92499 20.3785 9.41393 19.8722L11.003 18.2268V27.8461C11.003 28.6055 11.4919 29.1118 12.2253 29.1118C12.9587 29.1118 13.4477 28.6055 13.4477 27.8461V18.2268L15.0367 19.8722C15.2812 20.1253 15.6479 20.2519 15.8924 20.2519C16.1369 20.2519 16.5036 20.1253 16.748 19.8722C17.237 19.3659 17.237 18.6065 16.748 18.1002L13.081 14.3031Z" fill="#808080" />
                                                                        <path d="M24.3248 9.61926C24.2025 9.49269 24.2025 9.36612 24.0803 9.23956L15.5238 0.379708C15.4016 0.253139 15.2794 0.126569 15.1571 0.126569C15.0349 0 14.7904 0 14.6682 0H3.66705C1.58905 0 0 1.6454 0 3.79708V31.6423C0 33.794 1.58905 35.4394 3.66705 35.4394H20.7799C22.8579 35.4394 24.447 33.794 24.447 31.6423V10.1255C24.447 9.99897 24.447 9.74583 24.3248 9.61926ZM15.8905 4.30335L20.291 8.85985H17.1129C16.3795 8.85985 15.8905 8.35357 15.8905 7.59416V4.30335ZM22.0023 31.6423C22.0023 32.4017 21.5134 32.908 20.7799 32.908H3.66705C2.93364 32.908 2.4447 32.4017 2.4447 31.6423V3.79708C2.4447 3.03766 2.93364 2.53139 3.66705 2.53139H13.4458V7.59416C13.4458 9.74583 15.0349 11.3912 17.1129 11.3912H22.0023V31.6423Z" fill="#808080" />
                                                                    </svg>

                                                                    <p className='text-sm font-semibold text-center text-normal cursor-pointer underline'>
                                                                        Choisissez un fichier
                                                                    </p>
                                                                </div>
                                                            }
                                                            onTypeError={(value: any) => setError2(true)}
                                                            handleChange={loadFile2}
                                                            name="file"
                                                            types={fileTypes}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                        :
                                        <FileUploader
                                            classes='focus-within:bg-first'
                                            children={
                                                <div className='flex items-center  rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-x-8'>
                                                    <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.081 14.3031C12.9587 14.1766 12.8365 14.05 12.7143 14.05C12.4698 13.9234 12.1031 13.9234 11.7364 14.05C11.6142 14.1766 11.4919 14.1766 11.3697 14.3031L7.70264 18.1002C7.2137 18.6065 7.2137 19.3659 7.70264 19.8722C8.19158 20.3785 8.92499 20.3785 9.41393 19.8722L11.003 18.2268V27.8461C11.003 28.6055 11.4919 29.1118 12.2253 29.1118C12.9587 29.1118 13.4477 28.6055 13.4477 27.8461V18.2268L15.0367 19.8722C15.2812 20.1253 15.6479 20.2519 15.8924 20.2519C16.1369 20.2519 16.5036 20.1253 16.748 19.8722C17.237 19.3659 17.237 18.6065 16.748 18.1002L13.081 14.3031Z" fill="#808080" />
                                                        <path d="M24.3248 9.61926C24.2025 9.49269 24.2025 9.36612 24.0803 9.23956L15.5238 0.379708C15.4016 0.253139 15.2794 0.126569 15.1571 0.126569C15.0349 0 14.7904 0 14.6682 0H3.66705C1.58905 0 0 1.6454 0 3.79708V31.6423C0 33.794 1.58905 35.4394 3.66705 35.4394H20.7799C22.8579 35.4394 24.447 33.794 24.447 31.6423V10.1255C24.447 9.99897 24.447 9.74583 24.3248 9.61926ZM15.8905 4.30335L20.291 8.85985H17.1129C16.3795 8.85985 15.8905 8.35357 15.8905 7.59416V4.30335ZM22.0023 31.6423C22.0023 32.4017 21.5134 32.908 20.7799 32.908H3.66705C2.93364 32.908 2.4447 32.4017 2.4447 31.6423V3.79708C2.4447 3.03766 2.93364 2.53139 3.66705 2.53139H13.4458V7.59416C13.4458 9.74583 15.0349 11.3912 17.1129 11.3912H22.0023V31.6423Z" fill="#808080" />
                                                    </svg>

                                                    <p className='text-sm font-semibold text-center text-normal'>
                                                        Glisser votre carte d’identité ou passeport ou <u className='cursor-pointer'>choisissez un fichier</u>
                                                    </p>
                                                </div>
                                            }
                                            onTypeError={(value: any) => setError(true)}
                                            handleChange={loadFile}
                                            name="file"
                                            types={fileTypes}
                                        />
                                }
                            </div>
                            :
                            <div className='flex flex-col gap-y-8'>
                                {!file &&
                                    <>

                                        <p className='text-normal font-semibold lg:text-base'><span className='font-bold'>Titre de séjour ou carte de résident</span> en cours de validité avec mention manuscrite : </p>
                                        <p className='text-normal font-semibold lg:text-base'>“ j’atteste sur l’honneur que ce document d’identité est conforme à l’original”</p>
                                    </>}
                                {error ?
                                    <FileUploader
                                        classes='focus-within:bg-first  '
                                        children={
                                            <div className='flex items-center flex-col rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-y-8'>
                                                <p className='text-third'>Veuillez insérer un fichier au format : ”PDF, PNG ou JPEG” ❌</p>
                                                <p className='text-sm font-semibold text-center text-normal'>
                                                    Glisser votre Titre de séjour ou carte de résident ou <u className='cursor-pointer'>choisissez un fichier</u>
                                                </p>
                                            </div>
                                        }
                                        onTypeError={(value: any) => setError(true)}
                                        handleChange={loadFile}
                                        name="file"
                                        types={fileTypes}
                                    />

                                    :
                                    file ?
                                        <div className='lg:order-first space-y-4'>
                                            <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 order-last'>
                                                <div className="flex items-center gap-x-4">
                                                    <p>“{file.name}” </p>
                                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                                    </svg>
                                                </div>
                                                <Button onClick={() => setFile(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                                            </div>
                                            {
                                                file2 ?
                                                    <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 order-last' >
                                                        <div className="flex items-center gap-x-4">
                                                            <p>“{file2.name}” </p>
                                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                                            </svg>
                                                        </div>
                                                        <Button onClick={() => setFile2(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                                                    </div> :
                                                    <div className=''>
                                                        <p className='text-normal font-semibold lg:text-base pb-4'><span className='font-bold'> Voulez-vous ajouter un fichier supplémentaire ? </span> </p>
                                                        <FileUploader
                                                            classes='focus-within:bg-first'
                                                            children={
                                                                <div className='flex items-center  rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-x-8'>
                                                                    <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.081 14.3031C12.9587 14.1766 12.8365 14.05 12.7143 14.05C12.4698 13.9234 12.1031 13.9234 11.7364 14.05C11.6142 14.1766 11.4919 14.1766 11.3697 14.3031L7.70264 18.1002C7.2137 18.6065 7.2137 19.3659 7.70264 19.8722C8.19158 20.3785 8.92499 20.3785 9.41393 19.8722L11.003 18.2268V27.8461C11.003 28.6055 11.4919 29.1118 12.2253 29.1118C12.9587 29.1118 13.4477 28.6055 13.4477 27.8461V18.2268L15.0367 19.8722C15.2812 20.1253 15.6479 20.2519 15.8924 20.2519C16.1369 20.2519 16.5036 20.1253 16.748 19.8722C17.237 19.3659 17.237 18.6065 16.748 18.1002L13.081 14.3031Z" fill="#808080" />
                                                                        <path d="M24.3248 9.61926C24.2025 9.49269 24.2025 9.36612 24.0803 9.23956L15.5238 0.379708C15.4016 0.253139 15.2794 0.126569 15.1571 0.126569C15.0349 0 14.7904 0 14.6682 0H3.66705C1.58905 0 0 1.6454 0 3.79708V31.6423C0 33.794 1.58905 35.4394 3.66705 35.4394H20.7799C22.8579 35.4394 24.447 33.794 24.447 31.6423V10.1255C24.447 9.99897 24.447 9.74583 24.3248 9.61926ZM15.8905 4.30335L20.291 8.85985H17.1129C16.3795 8.85985 15.8905 8.35357 15.8905 7.59416V4.30335ZM22.0023 31.6423C22.0023 32.4017 21.5134 32.908 20.7799 32.908H3.66705C2.93364 32.908 2.4447 32.4017 2.4447 31.6423V3.79708C2.4447 3.03766 2.93364 2.53139 3.66705 2.53139H13.4458V7.59416C13.4458 9.74583 15.0349 11.3912 17.1129 11.3912H22.0023V31.6423Z" fill="#808080" />
                                                                    </svg>

                                                                     <p className='text-sm font-semibold text-center text-normal cursor-pointer underline'>
                                                                        Choisissez un fichier
                                                                    </p>
                                                                </div>
                                                            }
                                                            onTypeError={(value: any) => setError2(true)}
                                                            handleChange={loadFile2}
                                                            name="file"
                                                            types={fileTypes}
                                                        />
                                                    </div>
                                            }
                                        </div> :
                                        <FileUploader
                                            classes='focus-within:bg-first'
                                            children={
                                                <div className='flex items-center  rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-x-8'>
                                                    <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.081 14.3031C12.9587 14.1766 12.8365 14.05 12.7143 14.05C12.4698 13.9234 12.1031 13.9234 11.7364 14.05C11.6142 14.1766 11.4919 14.1766 11.3697 14.3031L7.70264 18.1002C7.2137 18.6065 7.2137 19.3659 7.70264 19.8722C8.19158 20.3785 8.92499 20.3785 9.41393 19.8722L11.003 18.2268V27.8461C11.003 28.6055 11.4919 29.1118 12.2253 29.1118C12.9587 29.1118 13.4477 28.6055 13.4477 27.8461V18.2268L15.0367 19.8722C15.2812 20.1253 15.6479 20.2519 15.8924 20.2519C16.1369 20.2519 16.5036 20.1253 16.748 19.8722C17.237 19.3659 17.237 18.6065 16.748 18.1002L13.081 14.3031Z" fill="#808080" />
                                                        <path d="M24.3248 9.61926C24.2025 9.49269 24.2025 9.36612 24.0803 9.23956L15.5238 0.379708C15.4016 0.253139 15.2794 0.126569 15.1571 0.126569C15.0349 0 14.7904 0 14.6682 0H3.66705C1.58905 0 0 1.6454 0 3.79708V31.6423C0 33.794 1.58905 35.4394 3.66705 35.4394H20.7799C22.8579 35.4394 24.447 33.794 24.447 31.6423V10.1255C24.447 9.99897 24.447 9.74583 24.3248 9.61926ZM15.8905 4.30335L20.291 8.85985H17.1129C16.3795 8.85985 15.8905 8.35357 15.8905 7.59416V4.30335ZM22.0023 31.6423C22.0023 32.4017 21.5134 32.908 20.7799 32.908H3.66705C2.93364 32.908 2.4447 32.4017 2.4447 31.6423V3.79708C2.4447 3.03766 2.93364 2.53139 3.66705 2.53139H13.4458V7.59416C13.4458 9.74583 15.0349 11.3912 17.1129 11.3912H22.0023V31.6423Z" fill="#808080" />
                                                    </svg>

                                                    <p className='text-sm font-semibold text-center text-normal'>
                                                        Glisser votre Titre de séjour ou carte de résident ou  <u className='cursor-pointer'>choisissez un fichier</u>
                                                    </p>
                                                </div>
                                            }
                                            onTypeError={(value: any) => setError(true)}
                                            handleChange={loadFile}
                                            name="file"
                                            types={fileTypes}
                                        />

                                }
                            </div>
                    }

                </div>
                <div className="flex justify-between w-full lg:gap-10 gap-y-4 lg:flex-row flex-col">
                    <Button disabled={props.loading} onClick={nextPage} className='text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30' style={{ background: 'linear-gradient(180deg, #0054A3 0%, #219CFF 100%)' }}>SUIVANT</Button>
                    <Button disabled={props.loading} onClick={() => props.prevStep()} className='text-normal lg:text-xl rounded-full px-10 lg:py-3 mx-auto block  w-full disabled:opacity-30 lg:w-max py-2 lg:hidden font-semibold' >précédent</Button>
                </div>
                {/* <div className="flex justify-end lg:py-10 ">
                    <div className="flex w-full justify-between gap-10">
                        <Button disabled={props.loading} onClick={() => props.prevStep()} className='flex rounded-full disabled:opacity-30 py-3 w-1/3 gap-x-4 font-semibold text-normal text-xl'>
                            <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" strokeWidth="1.5" strokeLinecap="round" />
                            </svg>

                            PRÉCÉDENT
                        </Button>
                        <Button disabled={props.loading} onClick={nextPage} className='bg-first text-white rounded-full  py-3 w-1/3 font-extrabold text-xl disabled:opacity-30'>SUIVANT</Button>
                    </div>
                </div> */}
            </div>

        </div>
    );
};

export default Third;