import * as React from "react";
// import Button from "@mui/material/Button";
// import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../Assets/Images/btn-close-orange.png";

export default function Delete(props:any) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
const {handleDelete} = props
  const handleClose = () => {
    setOpen(false);
  }
  const handleDeleteFunc = () => {
    handleDelete()
    props.onDelete()
    handleClose()

  }


  return (
    <div className="font-nunito">
      <span className="text-dark_blue " onClick={handleClickOpen}>
      <div className="border-[1px] text-center md:p-2 p-1 md:text-[16px] text-xs md:px-3 text-orange  border-orange uppercase active:bg-orange active:border-orange active:text-white font-semibold  rounded-full">
                  Supprimer
                </div>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <div className=" md:p-3 ">
          <DialogTitle>
            <div className="flex justify-between mb-3">
              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
              SUPPRIMER MA DEMANDE
              </span>
              <img
                src={close}
                alt=""
                className="md:h-6 h-3 md:w-6 w-3 translate-y-1  "
                onClick={handleClose}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
            Une fois votre demande supprimée, vous n’aurez plus accès aux
              réponses des Teach’rs et vous devrez créer une nouvelle demande.
              Êtes-vous sûr de vouloir supprimer votre demande ?
            </p>
          </DialogContent>
          <DialogActions>
            <div className="flex w-full justify-around gap-x-8">
            
                <button onClick={()=> handleClose()} className="border-[1px] w-1/2 md:p-2  p-1 md:text-xl text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full">
                  Retour
                </button>
            
              <button onClick={()=>handleDeleteFunc()} className="border-[1px] w-1/2 md:p-2  p-1 md:text-xl text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
                supprimer
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}


