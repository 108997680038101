import React, { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getJWTToken, logInWithToken } from '../../../Services/FirebaseService';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import TeachrRepository from '../../../Repositories/TeachrRepository';
import { User } from 'firebase/auth';
import ApiService from '../../../Services/ApiService';
import PunctualCourseRepository from '../../../Repositories/PunctualCourseRepository';
import Loader from '../../../Components/utils/Loader';

const ExternalLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const [user, userLoading] = useAuthState(auth)
    useEffect(() => {
        dispatch({
            type: 'SHOW_DASHBOARD_AICI_LOADER', payload: ""
        })
        if (!userLoading) {
            if (user) {
                login(user)
            } else {
                if (params.token) {
                    logInWithToken(params.token).then((response) => {
                        login(response.user)
                    })
                        .catch(() => navigate('/teachr/login'))
                } else {
                    navigate('/teachr/login')
                }
            }
        }
    }, [user, userLoading, params])
    // useEffect(() => {
    //     dispatch({
    //         type: 'SHOW_DASHBOARD_AICI_LOADER', payload: ""
    //     })
    //     TeachrRepository.getTeachrToken("uid").then((response) => {
    //         logInWithToken(response.token).then((response) => {
    //             login(response.user)

    //         })
    //     })
    // }, [])

    const login = async (currenUser: User) => {
        const jwtToken = await getJWTToken(currenUser)
        ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
        TeachrRepository.getCurrentTeachr().then((response) => {
            dispatch({
                type: 'SET_TEACHR_INFO',
                payload: {
                    teachr: response,
                    authorization: "teachr " + jwtToken
                }
            })
            PunctualCourseRepository.getPunctualCourseByTeachr(response.id!).then((courses) => {
                if (courses.length > 0) {
                    navigate("/teachr/dashboard/coursetype/")

                } else {
                    navigate("/teachr/dashboard")
                }
            })
        })
    }
    return (
        <div>
            <Loader />
        </div>
    );
};

export default ExternalLogin;