import { useState } from 'react';
import Navbar from '../../../../Components/Admin/Navbar';
import PunctualCourse from './CourseType/CoursePunctual/Index';
import SubscriptionCourse from './CourseType/CourseSubscription/SubscriptionCourse';
import React from 'react';

const Courses = () => {
    const [current, setCurrent] = useState(2)

    const stepCurrent = (value:number) => {
        switch (value) {
            case 1:
                return <SubscriptionCourse />
            case 2:
                return <PunctualCourse />
            default:
                return <PunctualCourse />
        }
    }

    return (
      <div className="bg-[#F4FAFF] h-screen">
        <div className="fixed w-full z-40">
          <Navbar index={1} />
        </div>
        <div className=" lg:px-10 md:px-8 px-2 bg-[#F4FAFF] md:pt-24 pt-20 pb-4">
          {stepCurrent(current)}
        </div>
      </div>
    );
};

export default Courses;