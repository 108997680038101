import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import picture from "../../../../../Assets/Images/Validation_1.gif"
import StepperStore from "../../../../../LocalForage/StepperStore";
import { auth } from "../../../../../Services/FirebaseService";
import React from "react";
// import Course from "../Course";

const StepEnd = (props: any) => {
    // const {handleBack} = props
    const [user] = useAuthState(auth)
    const [type, setType] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const value: any = await StepperStore.getItem("type" + user?.uid);
            if (value !== null) {
                setType(value.cours.cours);
            }
        })();
    }, []);
    const handleNext = async () => {
        // props.nextStep()
        navigate("/teachr/dashboard/coursetype")
        await StepperStore.clear()
        await StepperStore.setItem("level", 1)
    }

    return (
        <div className='overflow-y-auto p-3  list-teacher relative h-[99%] bg-white xl:px-14 px-4 md:py-6 py-4 rounded-lg shadow-dashboard-setup-intro-form'>
            <div className="mt-10">
                <h1 className="lg:text-2xl xl:pl-8 md:text-xl text-lg text-dark_blue font-extrabold ">Félicitations, déclaration de cours{type === "punctualMonth" ? ' du mois' : ' ponctuel'} validée ! 🥳</h1>
            </div>
            <div className="flex mt-6  //space-x-4 ">
                <div className=' hidden  md:block w-1/2 '>
                    <img src={picture} alt="" />
                </div>
                <div className=" md:w-1/2  py-10 //xl:pt-10 font-semibold  //xl:px-8 lg:text-xl md:text-lg text-base">
                    <div className="pr-6">
                        <p>
                            Votre cours a bien été validé. Il apparait déjà dans votre tableau de bord !
                        </p>
                    </div>
                    <div className="md:hidden  w-full flex justify-around space-x-4 pb-4 translate-y-6">
                        <div className="flex justify-center md:w-1/2 w-full mt-20 mb-4 ">
                            <div onClick={() => { handleNext() }}
                                className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-xs border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
                            >
                                Tableau de bord
                            </div>
                        </div>
                    </div>

                    <div className=" hidden //border w-4/5 mx-auto  //translate-y-16 mt-28 md:flex justify-between //absolute  //right-0 //px-14">
                        <div className="pl-2">
                            <button
                                onClick={() => {
                                    handleNext()
                                }}
                                className="py-2 font-extrabold text-white w-full uppercase rounded-full text-sm px-16 bg-first"
                            >
                                Tableau de bord
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepEnd;