import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import AddCards from "../CreditCards/AddCards";
import { useNavigate } from "react-router-dom";
import ClientRepository from "../../../../../Repositories/ClientRepository";
import PackProposalRepository from "../../../../../Repositories/PackProposalRepository";
import { PackProposal } from "../../../../../Objects/models/PackProposal";
import CreditCardRepository from "../../../../../Repositories/CreditCardRepository";
import DisplayCards from "../CreditCards/DisplayCards";
import CreditCard from "../../../../../Objects/models/CreditCard";
import secure from "../../../../../Assets/Images/securepayment.png";
import gif from "../../../../../Assets/Icons/load.gif";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AvailablePromotionalCodeRepository from "../../../../../Repositories/AvailablePromotionalCodeRepository";
import AvailablePromotionalCode from "../../../../../Objects/models/AvailablePromotionalCode";
import moment from "moment";
import PromoComponent from "../../../../../Components/PromoComponent";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BusinessError } from "../../../../../Objects/exceptions";
import { Typography } from "@mui/material";
import URSSAFParent from "../../../../../Objects/models/URSSAFParent";
import URSSAFParentRepository from "../../../../../Repositories/URSSAFParentRepository";
import DateInput from "../../../../../Components/utils/DateInput";
import { Checkbox } from "@mui/material";
import MultiSelect from "../../../../../Components/utils/MultiSelectClient";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import config from "../../../../../config";
import StepperStore from "../../../../../LocalForage/StepperStore";
import Functions from "../../../../../Helpers/Functions";

const stripePromise = loadStripe(config.stripePublicKey);

const validateDate = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Veuillez sélectionner une date",
    };
  }
  if (parseInt(moment().subtract(value.year(), "year").format("YY")) < 16) {
    return {
      isValid: false,
      message: "Vous devez avoir au moins 16 ans",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};
const validateName = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Entrez votre nom",
    };
  } else {
    return {
      isValid: true,
      message: "",
    };
  }
};
const validateTerms = (value: any) => {
  if (!value) {
    return {
      isValid: false,
      message: "Veuillez accepter les conditions d'utilisation",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};

const validateSelect = (value: any) => {
  if (value === undefined) {
    return {
      isValid: false,
      message: "Veuillez sélectionner une valeur",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};

const CoursePackPayment = (props: any) => {
  // const duration = 2*(props.state.proposal.totalWeeklyPrice / props.state.proposal.totalDailyPrice)

  const [cards, setCards] = useState<CreditCard[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [cguChecked, setCguChecked] = useState(false);
  const navigate = useNavigate();
  const stripe = useStripe();
  // const MySwal = withReactContent(Swal);
  let timerInterval: any;
  const [birth, setBirth] = useState<any>();
  const [sex, setSex] = useState<any>();
  const [name, setName] = useState<any>();
  const [errors, setError] = useState({
    name: "",
    sex: "",
    birth: "",
    terms: "",
  });
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getBirthDay = (value: any) => {
    if (value !== "") {
      setBirth(value);
    }
  };
  const getGender = (value: any) => {
    switch (value) {
      case "Femme":
        setSex("WOMAN");
        break;
      default:
        setSex("MAN");
        break;
    }
  };
  const validate = () => {
    let errlastName = true;
    let errbirthday = true;
    let errgender = true;
    let errterm = true;

    if (!validateName(name).isValid) {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errlastName = true;
    } else {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errlastName = false;
    }
    if (!validateDate(birth).isValid) {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errbirthday = true;
    } else {
      setError({
        name: validateName(name).message,
        sex: validateSelect(sex).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
      });
      errbirthday = false;
    }
    if (!validateSelect(sex).isValid) {
      setError({
        name: validateName(name).message,

        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errgender = true;
    } else {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errgender = false;
    }
    if (!validateTerms(sex).isValid) {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errterm = true;
    } else {
      setError({
        name: validateName(name).message,
        birth: validateDate(birth).message,
        terms: validateTerms(cguChecked).message,
        sex: validateSelect(sex).message,
      });
      errterm = false;
    }

    if (errlastName || errgender || errbirthday || errterm) {
      return false;
    } else {
      return true;
    }
  };
  const check = () => {
    setCguChecked(!cguChecked);
  };
  const sendUpdate = async () => {
    const clientWithId = await ClientRepository.getCurrentClient();
    clientWithId!.birthday = birth;
    clientWithId!.marriedName = name;
    clientWithId!.sex = sex;
    clientWithId!.profilePicture = undefined;

    if (clientWithId?.isParent) {
      try {
        await ClientRepository.updateClient(clientWithId!);
        Swal.close();
      } catch (error) {
        console.warn("Erreur", "Veuillez réessayer");
      }
    } else {
      const sexe: any = sex;
      const urssaf = new URSSAFParent(
        clientWithId!["@id"]!,
        clientWithId.firstName,
        clientWithId.lastName,
        sexe,
        birth,
        name
      );
      // setUrssaf(urssaf)
      try {
        if (clientWithId.URSSAFNumber) {
          await URSSAFParentRepository.updateURSSAFParent(urssaf);
        } else {
          await URSSAFParentRepository.createURSSAFParent(urssaf);
        }
      } catch (error) {
        console.warn("Erreur", "Veuillez réessayer");
      }
    }
  };
  const handleSubmit = () => {
    if (validate()) {
      sendUpdate();
      handleClose();
    }
  };
  const HandleLaod = (bool: boolean) => {
    setIsLoading(bool);
  };
  const handleLaoding = (bool: boolean) => {
    setLoading(bool);
  };
  const getCards = () => {
    CreditCardRepository.getCreditCards().then((getCard) => {
      setCards(getCard.members);
    });
  };
  useEffect(() => {
    getCards();
  }, [loading]);
  const [payLoad, setPayLoad] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any>();
  useEffect(() => {
    (async () => {
      const payMean: any = await StepperStore.getItem("payMean");
      if (payMean !== null) {
        setPaymentMethod(payMean);
      }
    })();
  }, []);

  

  const handlePayerPress = async () => {
    setPayLoad(true);
    const client = await ClientRepository.getCurrentClient();
    let PackProposal: PackProposal = props.packProposals;
    
    let acceptResults: any;
    if (client.id) {
      try {
        setPayLoad(true);
        acceptResults = await PackProposalRepository.tryAcceptPackProposal(
          PackProposal
        );
      } catch (error) {
        setPayLoad(false);
        if (error instanceof BusinessError) {
          switch (error.data.message) {
            case "must_complete_urssaf_info":
              handleClickOpen()
              break;
            case "no_presaved_credit_card":
              // error_text = "Vous devez enregistrer au moins une carte bancaire.";
              // this.props.navigation.navigate("AjouterMoyenPaiementClient");
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Vous devez enregistrer au moins une carte bancaire.",
                timer: 4000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              break;
            default:
              Swal.fire({
                icon: "warning",
                title: "Oops...",
                text: "Erreur inconnue. Veuillez nous contacter.",
                timer: 4000,
                willClose: () => {
                  clearInterval(timerInterval);
                },
              });
              // error_text = "Erreur inconnue. Veuillez nous contacter.";
              break;
          }
          // alert(error_text);
        } 
      }

      if (acceptResults === undefined) {
        setPayLoad(true);
        try {
          setPayLoad(true);
          if (stripe) {
            const card =
              paymentMethod?.error?.payment_intent?.last_payment_error
                ?.payment_method.id;
            const clientSecret =
              paymentMethod?.error?.payment_intent?.client_secret;
              // setPayLoad(true);
            stripe
              .confirmCardPayment(clientSecret, { payment_method: card })
              .then((res) => {
                setPayLoad(true);
                if (res.paymentIntent?.status === "requires_capture") {
                  if (client.id) {
                    PackProposalRepository.acceptPackProposalAfter3dSecure(
                      PackProposal
                    )
                      .then(() => {
                        setPayLoad(true);
                        Swal.fire({
                          icon: "success",
                          title: "Reussi",
                          text: "Paiement effectué avec succès !",
                          timer: 4000,
                          willClose: () => {
                            clearInterval(timerInterval);
                          },
                        });
                        navigate("/user/confirmpayment");
                      })
                      .catch((error) => {
                        setPayLoad(false);
                        console.warn(error);
                      });
                  }
                }
              }).catch((e)=>{
                setPayLoad(false);
                console.warn(e);
                
              });
          }else{
            setPayLoad(false);
          }
        } catch (error) {
          setPayLoad(false);
          console.warn(error);
        }
        setPayLoad(false);
      } else {

        // the payment was directly succesfull, nothing more to do
       ;
        if (acceptResults.paymentIntentNeedAuthentification === false) {
          setPayLoad(true);
          navigate("/user/confirmpayment");
        }
        // the payment requires 3d secure, we call stripe to load customer's bank page
        try {
          setPayLoad(true);
          if (stripe) {
            stripe
              .confirmCardPayment(acceptResults.paymentIntentClientSecretId)
              .then((result) => {
                setPaymentMethod(result);
                StepperStore.setItem("payMean", result);
                if (result.paymentIntent?.status === "requires_capture") {
                  // end of 3d secure, we check if we can accept the proposal
                  // if there was an error with stripe during the 3d secure step
                  // we will know it here
                  if (client.id) {
                    PackProposalRepository.acceptPackProposalAfter3dSecure(
                      PackProposal
                    )
                      .then(() => {
                        navigate("/user/confirmpayment");
                      })
                      .catch((error) => {
                        setPayLoad(false);
                        console.warn(error);
                      });
                  }
                }
              });
          }
          setPayLoad(false);
        } catch (e) {
          setPayLoad(false);
          console.warn(e);
        }
      }
    }
  };

  const [promotionalCodes, setPromotionalCodes] =
    useState<Array<AvailablePromotionalCode>>();

  const getPromotionalCodes = async () => {
    const codes =
      await AvailablePromotionalCodeRepository.getAvailablePromotionalCodes();
    let actualCodesList: AvailablePromotionalCode[] = [];
    if (codes.members[0]) {
      codes.members.map((availablePromoCodes: AvailablePromotionalCode) => {
        if (
          moment(availablePromoCodes.promotionalCode!.endDatetime).isAfter(
            moment()
          )
        ) {
          actualCodesList.push(availablePromoCodes);
        }
      });
      setPromotionalCodes(actualCodesList);
    }
  };

  useEffect(() => {
    getPromotionalCodes();
  }, [isLoading]);

  const onPromotionalCodeAdded = (
    availablePromotionalCode: AvailablePromotionalCode
  ) => {
    if (
      moment(availablePromotionalCode.promotionalCode!.endDatetime).isAfter(
        moment()
      )
    ) {
      setPromotionalCodes([availablePromotionalCode]);
    }
  };

  return (
    <div className="w-screen h-screen overflow-hidden list-teacher font-nunito md:mx-7 md:pl-10">
      <div className="">
        <div className="list-teacher">
          <div className="md:flex //mt-6 ">
            <div className="pb-8 md:w-1/2">
              <div className="mt-3 md:w-2/3 md:mx-0 mx-7 md:mt-0 ">
                <div className="flex mx-auto gap-x-2 text-dark_blue">
                  {/* <strike>aze</strike> */}

                  {promotionalCodes !== undefined && promotionalCodes[0] ? (
                    <div className="flex text-base font-bold xl:text-3xl lg:text-xl">
                      <p className="">
                        Payer :
                        <strong className="line-through text-[#D2D2D2] ml-2">
                          {(Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total).toFixed(2) +
                            "€"}
                        </strong>
                        <strong className="ml-2 ">
                          {promotionalCodes !== undefined &&
                            (Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total).toFixed(2) +
                            "€"}
                        </strong>
                      </p>
                    </div>
                  ) : (
                    <div className="flex text-base font-bold xl:text-3xl lg:text-xl">
                      <p className="">
                        Payer :
                        <strong className="ml-2 ">
                          {(Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total).toFixed(2) +
                            "€"}
                        </strong>
                      </p>
                    </div>
                  )}
                  {promotionalCodes !== undefined && promotionalCodes[0] ? (
                    <div className="hidden md:block text-[#5DA360] bg-[#91E79578] shadow-md px-1 rounded-md">
                      <p className=" font-bold pt-2.5 text-sm">
                        -{" "}
                        {promotionalCodes !== undefined &&
                          promotionalCodes[0].promotionalCode?.fixReduction}
                        €
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {props.state.course.isRemote ? null :  promotionalCodes !== undefined && promotionalCodes[0] ? (
                  <div className="flex text-sm lg:text-lg md:text-base text-grey text-bold gap-x-2">
                    <p className="">Soit après crédit d’impôt : </p>
                    <p className="line-through text-[#D2D2D2] //ml-2">
                      {(Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total / 2).toFixed(2) +
                            "€"}
                    </p>
                    <p>
                      {promotionalCodes &&
                        (Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total / 2).toFixed(2) +
                        "€"}
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="text-sm lg:text-lg md:text-base text-grey text-bold">
                      Soit après crédit d’impôt :{" "}
                      {(Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total / 2).toFixed(2) +
                            "€"}
                    </p>
                  </div>
                )}
                {cards === undefined ? (
                  <div className="mx-auto my-auto //mt-40">
                    <img src={gif} alt="gif" className="mx-auto " />
                  </div>
                ) : cards?.length! > 0 ? (
                  <div className="w-4/5 mt-2 //mx-auto">
                    {/* <AddCard HandleLaodCard={HandleLaodCard} /> */}
                    {cards?.map((card: CreditCard, key) => (
                      <DisplayCards {...card} key={key} />
                    ))}
                  </div>
                ) : (
                  <Elements stripe={stripePromise}>
                    <AddCards handleLaoding={handleLaoding} />
                  </Elements>
                )}
                <div className="mt-4">
                  <div className="">
                    <div className="flex mx-auto //w-4/5 gap-x-3">
                      <div className="w-6">
                        <img src={secure} alt="" />
                      </div>
                      <div className="text-sm font-bold text-dark_blue lg:text-lg md:text-base">
                        <p>PAIEMENT SÉCURISÉ</p>
                      </div>
                    </div>
                    <div className="mx-auto text-xs lg:text-base md:text-sm text-grey">
                      <p>
                        Teach’r utilise une technologie de protection perfomante
                        : le procédé de chiffrement SSL qui protège toutes les
                        données liées aux moyens de paiement, afin de garantir
                        une transaction entièrement sécurisée.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mx-auto mt-4 //w-4/5">
                  <PromoComponent
                    callBack={(promotionalCode: AvailablePromotionalCode) =>
                      onPromotionalCodeAdded(promotionalCode)
                    }
                    HandleLaod={HandleLaod}
                  />
                </div>
                {cards?.length! > 0 ? (
                  <div className="z-50 flex justify-around mt-8 md:w-full ">
                    {promotionalCodes !== undefined && promotionalCodes[0] ? (
                      <div
                        className="border-2 w-full cursor-pointer z-50  bg-white border-dark_blue rounded-full py-2 uppercase text-dark_blue text-center text-sm lg:text-[17px] font-extrabold transition duration-150 ease-in-out"
                        onClick={() => handlePayerPress()}
                      >
                        {payLoad ? (
                          <CircularProgress
                            sx={{
                              color: "blue",
                            }}
                            size={20}
                            thickness={4}
                            value={100}
                          />
                        ) : (
                          "Payer " + promotionalCodes !== undefined &&
                          (Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total).toFixed(2) +
                            "€"
                        )}
                        {/* {promotionalCodes !== undefined &&
                            (
                              JSON.parse(
                                props.state.proposal.price!
                              )  -
                              promotionalCodes[0].promotionalCode!.fixReduction!
                            ).toFixed(2)}{" "}
                          € */}
                      </div>
                    ) : (
                      <div
                        className="border-2 w-full  cursor-pointer z-50  bg-white border-dark_blue rounded-full py-2 uppercase text-dark_blue text-center text-sm lg:text-[17px] font-extrabold transition duration-150 ease-in-out"
                        onClick={() => handlePayerPress()}
                      >
                        {payLoad ? (
                          <CircularProgress
                            sx={{
                              color: "blue",
                            }}
                            size={20}
                            thickness={4}
                            value={100}
                          />
                        ) : (
                          "Payer " +
                          (Functions.renderPackProposalPrice(parseFloat(props.state.proposal.teachrPrice), props.state.course.durationPerDay, props.state.course.dates.length, props.state.proposal.fees / 100, props.state.proposal.URSSAFPrice / 100).total).toFixed(2) +
                            "€"
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="">
            <Dialog
              open={open}
              // onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div className="flex justify-between text-sm font-extrabold uppercase lg:text-lg md:text-base text-first">
                  <p>dernière étape : Crédit d'impot</p>
                  <ClearIcon
                    onClick={() => handleClose()}
                    className="font-extrabold cursor-pointer"
                  />
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <div className="pl-2 pr-1 pb-2 h-[100%]">
                    <div className="//mt-4 text-left text-[#4d4d4d]">
                      <div>
                        <p>
                          Afin d'effectuer les démarches auprès de l'URSSAF,
                          Nous avons besoin de quelques renseignements.{" "}
                          <strong>
                            Ces informations sont obligatoires pour pouvoir
                            bénéficier du crédit d'impot.
                          </strong>
                        </p>
                      </div>
                      <div className="mt-4 space-y-4">
                        <div className="w-full  shadow-md shadow-[rgba(0,0,0,25%)] rounded-md">
                          <DateInput
                            callback={getBirthDay}
                            customClass="bg-input-color"
                          />
                        </div>
                        <div className="flex w-full mx-auto text-lg -translate-y-3 //space-x-2 //md:translate-y-4 ">
                          <p className="text-[#FF724F] h-3   w-full text-sm md:text-base ">
                            {errors.birth === ""
                              ? null
                              : validateDate(birth).message}
                          </p>
                        </div>
                        <div className="flex //flex-col -mt-10 -translate-y-2 md:flex-row gap-x-4 text-[#4d4d4d]">
                          <div className="w-full">
                            <MultiSelect
                              data={["Femme", "Homme"]}
                              callBack={getGender}
                              placeholder="Ex : Homme"
                              customClass="bg-input-color py-[6px] text-[#4d4d4d] shadow-md shadow-[rgba(0,0,0,25%)] lg:text-lg md:text-base text-sm"
                            />
                            <div className="flex w-full mx-auto text-lg //space-x-2 //md:translate-y-4 //translate-y-1 ">
                              <p className="text-[#FF724F] h-3 w-ull text-sm md:text-base ">
                                {errors.sex === ""
                                  ? null
                                  : validateSelect(sex).message}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="bg-input-color mt- rounded-md py-1.5 px-3 w-full lg:text-lg md:text-base text-sm text-[#4d4d4d] shadow-md shadow-[rgba(0,0,0,25%)]">
                            <label
                              htmlFor=""
                              className="lg:text-lg md:text-base text-sm text-[#4d4d4d] font-medium"
                            >
                              Nom
                            </label>
                            <input
                              type="text"
                              id="last_firstName"
                              className="w-full lg:text-lg md:text-md text-sm text-[#4d4d4d] bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                              placeholder="Ex : Doe"
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div className="flex w-full mx-auto text-lg //space-x-2 //md:translate-y-4 //translate-y-2 ">
                            <p className="text-[#FF724F] h-3 w-full text-sm md:text-base ">
                              {errors.name === ""
                                ? null
                                : validateName(name).message}
                            </p>
                          </div>
                          <div className="text-sm h- //-translate-y-2 md:text-base text-third"></div>
                        </div>
                        <div className=" font-nunito //-mt-6">
                          <FormControlLabel
                            sx={{
                              color: "#4d4d4d",
                              fontSize: "24px",
                              //marginLeft: "4rem",
                              fontFamily: "nunito",
                            }}
                            control={
                              <Checkbox
                                sx={{
                                  color: "#4d4d4d",
                                  "&.Mui-checked": {
                                    color: "#4d4d4d",
                                    fontFamily: "nunito",
                                  },
                                }}
                                onClick={() => check()}
                              />
                            }
                            label={
                              <Typography style={{ fontFamily: "nunito" }}>
                                Je donne l'autorisation à Teach'r de déclarer
                                mes cours particuliers en mon nom et pour mon
                                compte
                              </Typography>
                            }
                          />
                        </div>
                        <div className="flex w-full mx-auto text-lg -translate-y-3 //space-x-2 //md:translate-y-4 ">
                          <p className="text-[#FF724F] h-3 w-full text-sm md:text-base ">
                            {errors.terms === ""
                              ? null
                              : validateTerms(cguChecked).message}
                          </p>
                        </div>
                      </div>
                      <div
                        className="z-50 w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue"
                        onClick={() => handleSubmit()}
                      >
                        Valider
                      </div>
                    </div>
                  </div>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePackPayment;
