import React from 'react';
import Billing from './Billing';
import Dashboard from './Dashboard';
import illustration from '../../../../../Assets/Images/profile-is-ae.svg'
import FacturationUpdate from './FacturationUpdate';
const Index = (props:any) => {
    return (
        <div className='relative md:h-[calc(100vh_-_13.5rem)]'>
            <div className='flex flex-col max-w-[800px] gap-y-10 bg-white px-4 md:px-8'>
                {/* <FacturationUpdate /> */}
                <Billing />
                <Dashboard teachr={props.teachr}/>
            </div>
            <div className='absolute bottom-0 hidden -right-14 md:block -z-10'>
                <img src={illustration} alt="" />
            </div>
        </div>
    );
};

export default Index;