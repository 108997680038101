import React, { useEffect, useState } from "react";
import { Avatar, Dialog } from "@mui/material";
import {
  buttonDelete2,
  buttonUnArchivage,
  capitalizeFirstLetter,
  profileStudent,
  showStatusTDP,
} from "../UtilsDashboard/FunctionsDashbord";
import { useNavigate } from "react-router-dom";
import AddressRepository from "../../../../../Repositories/AddressRepository";

interface dataProfileModel {
  handleOpenView: () => void;
  handleClose: () => void;
  fullName: string;
  initFullName: string;
  child: any;
  isLoad2: boolean;
  firstCondition: boolean;
  isDelete2: boolean;
  openView: boolean;
  address: any;
  isTrue: boolean;
  handleRefresh: () => void;
}
interface dataDeleteModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  setIsLoad2: React.Dispatch<React.SetStateAction<boolean>>;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: any;
}
interface dataArchiveModel {
  child: any;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteTrue2: (val: boolean) => void;
  handleLoadDelete2: () => void;
  navigate: any;
  onValueChange2: React.Dispatch<React.SetStateAction<boolean>>;
}
interface btnArchiveModel {
  data: dataArchiveModel;
  load: boolean;
  firstCondition: boolean;
}
interface btnDeleteModel {
  data: dataDeleteModel;
  load: boolean;
  firstCondition: boolean;
}

interface dataStatusModel {
  child: any;
  firstCondition: boolean;
  handleRefresh: () => void;
  isLoad2: boolean;
}

const ComponentArchive = (props: any) => {
  const navigate = useNavigate();
  const {
    isDelete2,
    child,
    handleLoadDelete2,
    setIsLoad2,
    isLoad2,
    handleDeleteTrue2,
    onValueChange2,
  } = props;
  const [isCopy, setIsCopy] = useState(false);
  const [load, setLoad] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [address, setAddress] = useState<any>();
  const isTrue = true;
  const isTeachrContact = child?.is_teachr_contact;
  const pricePerHour = child?.price_per_hour;
  const feesPerHour = child?.fees_per_hour;
  const fullName = `${capitalizeFirstLetter(
    child?.child_first_name
  )} ${child?.user_last_name.toUpperCase()}`;
  const initFullName = `${child?.child_first_name
    .charAt(0)
    .toUpperCase()}${child?.user_last_name.charAt(0).toUpperCase()}`;
  const firstCondition = !isTeachrContact && (!pricePerHour || !feesPerHour);
  const handleOpenView = () => {
    setOpenView(true);
  };
  const handleClose = () => {
    setOpenView(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isCopy) {
        setIsCopy(false);
      }
    }, 300);
  }, [isCopy]);
  useEffect(() => {
    AddressRepository.getClientAddresses(child?.client_id).then((res) => {
      setAddress(res[0]);
    });
  }, []);
  const handleRefresh = () => {
    setIsLoad2(true);
    // ClientRepository.refreshStatusTdp(child?.client_id)
    //   .then((res) => {
    //     handleLoadDelete2();
    //   })
    //   .catch((error) => {
    //     setIsLoad2(false);
    //     dispatch({
    //       type: "SHOW_ERROR",
    //       payload: JSON.parse(error.message).translate,
    //     });
    //     console.warn(JSON.parse(error.message));
    //   });
    setIsLoad2(false);
  };

  const dataProfile: dataProfileModel = {
    handleOpenView,
    handleClose,
    fullName,
    initFullName,
    child,
    isLoad2,
    firstCondition,
    isDelete2,
    openView,
    address,
    isTrue,
    handleRefresh
  };
  const dataDelete: btnDeleteModel = {
    data: {
      child,
      setLoad,
      handleDeleteTrue2,
      handleLoadDelete2,
      setIsLoad2,
      navigate,
      onValueChange2,
    },
    load,
    firstCondition
  };
  const dataArchive: btnArchiveModel = {
    data: {
      child,
      setLoad,
      handleDeleteTrue2,
      handleLoadDelete2,
      navigate,
      onValueChange2,
    },
    load,
    firstCondition

  };

    const dataStatus: dataStatusModel = {
      child,
      firstCondition,
      handleRefresh,
      isLoad2,
    };
 
  return (
    <>
      <tr className="flex w-full group/item hover:bg-slate-100 hover:rounded-xl hover:py-2">
        <td className="py-2 md:w-3/5 w-2/4 md:px-3 text-[#4d4d4d] font-semibold  md:flex truncate">
          <div className="flex items-center w-full text-gray-900 whitespace-nowrap gap-x-1 font-nunito">
            <Avatar
              sx={{
                bgcolor: firstCondition ? "#ffe5d1" : "#c7e4ff",
                color: firstCondition ? "#ff9647" : "#0054A3",
                border: firstCondition
                  ? "2px solid #ff9647"
                  : "2px solid #0054A3",
              }}
              className="hidden md:flex w-10 h-10 font-nunito font-bold"
            >
              {initFullName}
            </Avatar>
            <div className="ps-3">
              <div
                className={`${
                  firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
                } text-base font-semibold`}
              >
                {fullName}
              </div>
              <p
                className={`${
                  firstCondition ? "text-[#ff9647]" : "text-[#0054A3]"
                } font-normal text-xs cursor-text text-gray-500 `}
              >
                {child?.mail}
              </p>
            </div>
          </div>
        </td>
        <td className="py-2 md:w-1/5 w-1/4 md:text-left text-center">
          {showStatusTDP(dataStatus)}
        </td>
        <td className="py-2 md:w-1/5 w-1/4 flex justify-center">
          {buttonUnArchivage(dataArchive)} {buttonDelete2(dataDelete)}{" "}
          {profileStudent(dataProfile)}
        </td>
      </tr>
    </>
  );
};

export default ComponentArchive;
