import checkCircle from '../../../../../Assets/Icons/check-circle.svg'
import ProfileImg from '../../../../../Assets/Images/profile__image.png'
import ClockIcon from '../../../../..//Assets/Icons/clock.svg'
import CalendarIcon from '../../../../../Assets/Icons/calendar.svg'
import ClipIcon from '../../../../../Assets/Icons/clip.svg'
import LocationIcon from '../../../../../Assets/Icons/location.svg'
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'

import { Subject } from '../../../../../Objects/models/Subject';
import Child from '../../../../../Objects/models/Child';
import Client from '../../../../../Objects/models/Client';
import Functions from '../../../../../Helpers/Functions';
import moment from 'moment';
import { Address } from '../../../../../Objects/models/Address';
import MessageCourse from '../../../../../Components/Dashboard/MessageCourse';
import { CoursePack } from '../../../../../Objects/models/CoursePack';
import { Button } from '@mui/material';
import { PackProposal } from '../../../../../Objects/models/PackProposal';
import Teachr from '../../../../../Objects/models/Teachr';
import PackProposalRepository from '../../../../../Repositories/PackProposalRepository';
import { useNavigate } from 'react-router-dom';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import CoursePrice from '../../../../../Components/utils/CoursePrice';
import { useState } from 'react'

interface CoursePackDate {
    month: string;
    days: string[]
}
const PackDetails = (
    pack: {
        teachr: Teachr,
        course: CoursePack,
        subject: Subject,
        child: Child | Client,
        address: Address
    }
) => {
    const navigate = useNavigate()
    const [description, setDrescription] = useState('')
    const getDescrition = (value: string) => {
        setDrescription(value)
    }
    const [price, setPrice] = useState('')
    const getPrice = (value: string) => {
        setPrice(value)
    }
    const displayCoursePackDates = (dates: Date[] | undefined) => {

        if (dates) {
            let groupDate: CoursePackDate[] = []
            let lastMont: string = ''
            for (let i = 0; i < dates.length; i++) {
                const date = dates[i]
                let sameDays: string[] = []
                let lastIndex: number = 0
                if (lastMont !== moment(date).format('MMMM')) {
                    lastMont = moment(date).format('MMMM')
                    for (let j = 0; j < dates.length; j++) {
                        if (moment(date).format('MMMM') === moment(dates[j]).format('MMMM')) {
                            sameDays.push(moment(dates[j]).format('dddd DD'))
                        }
                    }
                    groupDate.push({
                        month: lastMont,
                        days: sameDays
                    })
                    lastIndex = i
                } else {
                    for (let j = 0; j < dates.length; j++) {
                        if (moment(date).format('MM') === moment(dates[j]).format('MMMM')) {
                            groupDate[lastIndex].days.push(moment(dates[j]).format('dddd DD'))
                        }
                    }

                }
            }
            return groupDate
        }
        return []

    }
    const MySwal = withReactContent(Swal)
    const showConfirmSubscriptionWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-third'>
                        <p className=''>Envoyer sans personnalisation ?</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p>Êtes-vous sûr de ne pas vouloir ajouter une description à votre candidature ?
                            Nous avons remarqué qu'avoir une description personnalisée augmente de 42% vos chances d'être sélectionné !
                        </p>
                        <div className="flex justify-between py-4">
                            <Button onClick={() => Swal.clickCancel()} className='font-bold bg-transparent rounded-full py-2 text-first lg:w-1/4 w-full border-solid border-[1px] border-first px-8 uppercase ' >Annuler</Button>
                            <Button onClick={() => { Swal.clickConfirm(); createdProposal() }} className='font-bold bg-transparent rounded-full py-2 text-third lg:w-1/4 w-full border-solid border-[1px] border-third px-8 uppercase ' >postuler</Button>
                        </div>
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    const createdProposal = () => {
        document.getElementById('teachr-loader')?.classList.remove('hidden')
        const courseProposal = new PackProposal(
            pack.course.id!,
            pack.teachr.id!,
        )
        courseProposal.description = description;
        courseProposal.isFromTeachr=true;
        courseProposal.teachrPrice = price
        PackProposalRepository.createPackProposal(courseProposal, pack.course.id!)
        .then(() => {
                navigate('/teachr/cours/postuler/confirm')
                document.getElementById('teachr-loader')?.classList.add('hidden')
            }).catch((error) => {
                console.warn(error);

            })
    }
    return (
        <div className='mx-auto lg:w-2/3'>
            <div className="flex flex-col justify-center gap-8 py-8 lg:flex-row">
                <div className='shadow-course-select  mx-auto  p-6 rounded-[20px] flex'>
                    <div className="mx-auto w-max">
                        <div className='flex items-center  gap-4 text-[22px] font-black uppercase text-first'>
                            <p>RéCAPITULATIF </p>
                            <img className='h-[25px]' src={checkCircle} alt="" />
                        </div>
                        <div className="flex flex-col gap-5 py-8 ">
                            <div className="flex gap-x-4">
                                <img src={ProfileImg} alt="teacher" className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' />
                                <div>
                                    <p className='pb-2 overflow-hidden text-xl font-bold text-first'>Stage / {pack.subject?.fr_FR}</p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px]'>pour {pack.child.firstName!} / {Functions.getLevel(pack.child?.class!)}</p>
                                </div>
                            </div>

                            <div className="flex items-center gap-4">
                                <img src={ClockIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div>
                                    <p className='text-xl font-bold text-first'>À partir de {moment(pack.course?.dates[0]).format("h") + "h" + moment(pack.course?.dates[0]).format("mm")}</p>
                                    <p className='text-[#4D4D4D] text-sm lg:text-[16px]'>{Functions.renderDuration(pack.course?.durationPerDay!)} / jour</p>
                                </div>
                            </div>
                            <div className="flex items-center gap-4">
                                <img src={CalendarIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                <div>
                                    {displayCoursePackDates(pack.course?.dates).map((date: CoursePackDate, key) => (
                                        <ul key={key}>
                                            <li className='items-center text-[16px]  font-bold text-first'>
                                                {date.month} :
                                            </li>
                                            <li className='text-[#4D4D4D] text-sm lg:text-sm flex flex-wrap'>
                                                {date.days.map((day: string, key) => (<span key={key} >{day}<span className={`${key === date.days.length - 1 && 'hidden'}`}>,&nbsp;</span></span>))}
                                            </li>
                                        </ul>
                                    ))}
                                </div>
                            </div>
                            {pack.course?.isRemote
                                ? <div className="flex items-center gap-4">
                                    <img src={ClipIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <p className='text-xl font-bold text-first'>Cours à distance</p>
                                </div>
                                : <div className="flex items-center gap-4">
                                    <img src={LocationIcon} className='lg:w-[40px] w-[32px] h-[32px] lg:h-[40px]' alt="" />
                                    <p className='text-xl font-bold text-first'>{`${pack.address?.streetName}, ${pack.address?.city}`}</p>
                                </div>}

                        </div>
                    </div>
                </div>
                <CoursePrice teachr = {pack.teachr} courseType = 'pack' duration={pack.course.durationPerDay} days={pack.course.dates.length} getPrice = {getPrice} />
                <MessageCourse child={pack.child} getDescrition={getDescrition} />
            </div>
            <div className="flex flex-row items-center justify-between w-full gap-8 py-4">
                <Button href='/teachr/cours' className='w-full px-8 py-2 font-bold uppercase bg-transparent border-2 border-solid rounded-full text-third lg:w-1/4 border-third' >précédent</Button>
                <Button onClick={() => { description === '' ? showConfirmSubscriptionWidget() : createdProposal() }} className='w-full px-8 py-2 font-bold uppercase bg-transparent border-2 border-solid rounded-full text-first lg:w-1/4 border-first' >postuler</Button>
            </div>

        </div>
    );
};

export default PackDetails;