import * as React from "react";
import { useEffect, useState } from "react";
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import ComponentBodyTablePunctualCourse from "./ComponentBodyTablePunctualCoursePast";
import ponct_dash from "../../../../../../../Assets/Images/ponct_dash.svg";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatePicker from "react-multi-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import AdsClickIcon from "@mui/icons-material/Mouse";
import { Dialog, DialogContent } from "@mui/material";
// import PaginationComponent from "../ActivesPunctuals/Pagination";
import { months, weekDays } from "../../../UtilsDashboard/FunctionsDashbord";
import Switch from "@mui/material/Switch";
import Teachr from "../../../../../../../Objects/models/Teachr";
import search from '../../../../../../../Assets/Icons/search-icon.svg'
import PaginationComponent2 from "../ActivesPunctuals/Pagination2";

const CoursePonctualPast = () => {
  const [nameSearch, setNameSearch] = useState<string>("");
  const [course, setCourse] = useState<PunctualCourseDashboard[]>();
  const [teachr, setTeachr] = useState<Teachr>()
  const [date, setDate] = useState(null);
  const [dateEnd, setDateEnd] = useState(null);
  const [date1, setDate1] = useState(null);
  const [dateEnd1, setDateEnd1] = useState(null);
  const [date13, setDate13] = useState(null);
  const [dateEnd13, setDateEnd13] = useState(null);
  const [subject, setSubject] = useState<string>("");
  const [values, setValues] = useState([]);
  const [trimestre, setTrimistre] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showDialog2, setShowDialog2] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [checked, setChecked] = React.useState(true);
  const result: any = [];
  const result2: any = [];
  const result3: any = [];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setChecked(event.target.checked);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const getCourse = (id: number, name: string, sujet: string, dateS: string, dateE: string) => {
    PunctualCourseRepository.searchPunctualCoursePastByTeachrNew(currentPage, id, name, sujet, dateS, dateE).then((courses) => {
      if (courses) {
        setCourse(courses.courses)
        setNextPage(courses.nextPage)
        setPrevPage(courses.previousPage)
        setTotalPage(courses.totalPages)
        setCurrentPage(courses.page)
        paginate(currentPage)
        setLoading(false)
      }
    })
      .catch((err) => {
        console.warn(err);
        setLoading(false)
      });
  }
  useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachr) => {
      setTeachr(teachr);
    }).catch((err) => {
      console.warn(err);
    });
  },
    [])
  useEffect(() => {
    if (teachr?.id) {
      const date1 = !date ? "" : `${date} 00:00:00`;
      const date2 = !dateEnd ? "" : `${dateEnd} 23:59:59`;

      if (date && dateEnd) {
        getCourse(teachr.id, nameSearch, subject, date1, date2);
      } else {
        getCourse(teachr.id, nameSearch, subject, date1, date2);
      }
    }
  }, [date, dateEnd, nameSearch, subject, teachr, nextPage, prevPage, currentPage, totalPage, course]);
  useEffect(() => {
    values.map((date: any) => {
      result.push(moment(date?.toDate?.().toString()).format("YYYY-MM-DD"));
    });
    setDate(result[0]);
    setDateEnd(result[1]);
  }, [result, values]);
  useEffect(() => {
    trimestre.map((date: any) => {
      result2.push(moment(date?.toDate?.().toString()).format("YYYY-MM-DD"));
      result3.push(moment(date?.toDate?.().toString()).format("DD MMMM YYYY"));
    });
    setDate1(result2[0]);
    setDateEnd1(result2[1]);
    setDate13(result3[0]);
    setDateEnd13(result3[1]);
  }, [result2, result3, trimestre]);
  useEffect(() => {
    // TeachrRepository.getCurrentTeachr().then((teachr) => {
    if (teachr?.id) {
      if (date1 && dateEnd1) {
        const date11 = !date1 ? "" : `${date1} 00:00:00`;
        const date21 = !dateEnd1 ? "" : `${dateEnd1} 23:59:59`;
        PunctualCourseRepository.getMontantFacture(teachr.id, date11, date21)
          .then((res) => {
            setPrice(res?.amount);
          })
          .catch((err) => {
            console.warn(err);
          });
      }
    }
    // });
  }, [date1, dateEnd1, teachr]);

  const showSearchInputAndButton = () => {
    return (
      <div className="grid grid-cols-4 lg:gap-x-10 gap-x-2 mt-4">
        <div className="bg-[#FFFFFF] lg:grid lg:grid-cols-10 p-1 justify-around rounded-lg /pl-4 shadow-md  /border-[#80808050] /border-[1px]  border-[2px] border-first  col-span-3">
          <input
            className="col-span-3 focus:border-[2px] focus:border-blue-100 focus:rounded-lg focus:shadow-lg w-full hidden lg:block h-full bg-transparent text-[#808080] border-[2px] rounded-lg border-[#80808050] pl-2 lg:text-lg text-base font-normal py-3"
            type="text"
            placeholder="Rechercher par élève"
            onChange={(e) => setNameSearch(e.target.value)}
            value={nameSearch}
          />
          <input
            className="col-span-3 focus:border-[2px] focus:border-blue-100 focus:rounded-lg focus:shadow-lg w-full hidden lg:block h-full bg-transparent text-[#808080] border-[2px] rounded-lg border-[#80808050] pl-2 lg:text-lg text-base font-normal py-3"
            type="text"
            placeholder="Rechercher par matière"
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
          <div className="col-span-3 w-full h-full bg-transparent text-[#808080] /border-[2px] rounded-lg border-[#80808050] md:flex hidden lg:text-lg text-base font-normal /py-3">
            <DatePicker
              style={{
                backgroundColor: "transparent !important",
                // backgroundColor: "aliceblue",
                height: "60px",
                border: "none !important",
                fontSize: "18px",
                padding: "10",
                width: "100%",
                paddingLeft: "4px",
              }}
              className="custom-date-picker"
              placeholder="Rechercher par période"
              containerStyle={{
                width: "100%",
                border: "none",
              }}
              months={months}
              weekDays={weekDays}
              format={"DD/MM/YYYY"}
              value={values}
              onChange={(e: any) => setValues(e)}
              range
              rangeHover
            // customInput={<input className='//border-2 //text-center mx-auto w-[100%] //border-2 pl-4 py-2 bg-red-800 lg:text-lg  md:text-base text-sm' type="text" />}
            />
          </div>
          <div className="md:hidden col-span-3 focus:bg-[#F4FAFF] focus:scale-y-125 focus:scale-x-110 focus:border  focus:border-grey focus:rounded-lg focus:shadow-lg w-full h-full bg-transparent text-[#808080] /border-r-[1px] border-[#80808050] flex /pt-1 /pl-2 lg:text-lg text-sm font-normal /py-3">
            <DatePicker
              style={{
                backgroundColor: "transparent !important",
                height: "22px",
                border: "none",
                fontSize: "16px",
                // paddingTop: "10",
                width: "100%",
                marginTop: "3px",
              }}
              placeholder="Rechercher par période"
              containerStyle={{
                width: "100%",
              }}
              months={months}
              weekDays={weekDays}
              format={"DD/MM/YYYY"}
              value={values}
              onChange={(e: any) => setValues(e)}
              range
              rangeHover
            />
          </div>
          <div className="col-span-1 pl-2 flex items-center">
            {(date || dateEnd) && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setDate(null);
                  setDateEnd(null);
                  setNameSearch("");
                  setSubject("");
                  setValues([]);
                }}
              >
                <HighlightOffIcon sx={{ marginTop: 0.5, marginRight: 2 }} />
              </div>
            )}
            <img src={search} alt="Rechercher" className="mx-auto" />
          </div>
        </div>
        <div className="lg:col-span-1 relative lg:block hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className={
              isOpen
                ? "bg-first right-0 absolute h-full w-full border-[2px] border-first rounded-lg text-white lg:text-lg text-base font-extrabold shadow-md px-6 py-2"
                : "bg-white right-0 absolute h-full w-full border-[2px] border-first rounded-lg text-first lg:text-lg text-base font-extrabold shadow-md px-6 py-2"
            }
          >
            <AdsClickIcon sx={{ marginTop: "-2px" }} /> Total facturé{" "}
          </button>
        </div>
        <div className="lg:col-span-1 relative lg:hidden">
          <button
            onClick={() => setShowDialog2(!showDialog2)}
            className={
              showDialog2
                ? "bg-first right-0 absolute  border-[2px] border-first rounded-lg text-white lg:text-lg text-base font-extrabold shadow-md px-2 py-2 /mt-1"
                : "bg-white right-0 absolute  border-[2px] border-first rounded-lg text-first lg:text-lg text-base font-extrabold shadow-md px-2 py-2 /mt-1"
            }
          >
            <AdsClickIcon sx={{ marginTop: "-2px" }} />
          </button>
        </div>
      </div>
    );
  };
  const showHistoricalCourse = () => {
    return (
      <div className={isOpen ? "grid lg:grid-cols-4 xl:gap-x-10 lg:gap-x-4 h-[60%] mt-4" : "h-[60%] mt-4 "}>
        <div
          className={
            isOpen
              ? "xl:overflow-hidden overflow-x-auto w-[100%] h-[100%] lg:shadow-simulator-dashboard rounded-lg  /lg:rounded-[30px] border-[2px] border-first bg-white lg:col-span-3"
              : "xl:overflow-hidden overflow-x-auto w-[100%] h-[100%] lg:shadow-simulator-dashboard rounded-lg  /lg:rounded-[30px] border-[2px] border-first bg-white"
          }
        >
          {!course ? (
            <div className="overflow-y-auto w-full relative  h-[450px] /md:mt-6 /px-2">
              <Skeleton variant="rectangular" width="100%" height="100%" />
            </div>
          ) : course.length === 0 ? (
            <div className="  md:w-full relative list-teacher h-[450px] mt-6 px-2 py-2 bg-white">
              <div className="mx-auto">
                <div className="md:w-4/5 md:mt-8  mx-auto ">
                  <p className="text-[#4d4d4d] text-center lg:text-2xl md:text-lg text-base font-bold mx-auto">
                    Afin de recevoir un paiement via notre plateforme, veuillez
                    déclarer votre premier cours, ainsi que tout autre cours que
                    vous avez déjà dispensé.
                  </p>
                </div>
                <div className="mx-auto md:mt-12 mt-2 md:w-1/2">
                  <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
                </div>
              </div>
            </div>
          ) : (
            <div className="relative  align-middle /lg:h-[85%]  h-full inline-block min-w-full shadow :overflow-hidden   overflow-y-auto bg-white shadow-dashboard /md:px-8 /px-4 pt-3 rounded-bl-lg rounded-br-lg">
              <div className={isOpen ? "" : "xl:h-[80%]"}>
                <table className="w-full">
                  <thead>
                    <tr className="border-b-2 border-first">
                      <th className="w-2/12 xl:px-2 px-2 py-3 /w-1/5  text-left leading-4 text-first tracking-wider">Période de cours</th>
                      <th className="w-1/12 xl:px-2 px-2 py-3 /w-1/7 text-left text-sm leading-4 text-first tracking-wider">Durée</th>
                      <th className="w-2/12 xl:px-2 px-2 py-3 /w-1/6 text-left text-sm leading-4 text-first tracking-wider">Élève</th>
                      <th className="w-2/12 xl:px-2 px-2 py-3 /w-1/6 text-left text-sm leading-4 text-first tracking-wider">Matière</th>
                      <th className={`${isOpen ? 'hidden' : ''} w-1/12 xl:px-2 /w-1/6 px-2 py-6  text-left text-sm leading-4 text-first tracking-wider `}>Type de cours</th>
                      <th className=" w-1/12 xl:px-2 px-2 py-3 /w-1/6 text-left text-sm leading-4 text-first tracking-wider">
                        <div className="flex items-center">
                          <span >{checked ? 'Montant Facturé' : 'Rémunération'} </span>
                          <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                        </div>
                      </th>
                      <th className="w-1/12 xl:px-6 px-3 py-3 text-left text-sm leading-4 text-first tracking-wider">Statut</th>
                      {isOpen ? "" : <th className="w-2/12 xl:px-4 px-2 py-3 text-left text-sm leading-4 text-first tracking-wider">Facturation</th>}
                    </tr>
                  </thead>
                  <tbody className="bg-white ">
                    {course?.map((punctual: PunctualCourseDashboard, key) => (
                      <ComponentBodyTablePunctualCourse
                        punctual={punctual}
                        checked={checked}
                        key={key}
                        isOpen={isOpen}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              <PaginationComponent2 loading={loading} setLoading={setLoading} pages={totalPage} currentPage={currentPage} onPageChange={paginate} nextPage={nextPage} prevPage={prevPage} />
            </div>
          )}
        </div>
        <div className={isOpen ? "lg:col-span-1 rounded-lg bg-white  h-[100%] overflow-y-auto shadow-dashboard-setup-intro-form p-3 xl:space-y-6 lg:block hidden border-[2px] border-first" : "hidden"}>
          {showBlockAmount()}
        </div>
        <div className="md:hidden">{showBlockAmountMobile()}</div>
      </div>
    );
  };
  const showBlockAmount = () => {
    return (
      <div className="xl:space-y-6 space-y-3 h-[100%]">
        <div
          className={
            "underline underline-offset-8 cursor-pointer font-extrabold text-dark_blue"
          }
        >
          <h2 className="font-extrabold text-dark_blue lg:text-lg text-base">
            Montant Facturé
          </h2>
        </div>
        <div className="border rounded-lg relative flex py-1">
          <DatePicker
            style={{
              backgroundColor: "transparent !important",
              height: "30px",
              border: "none",
              fontSize: "18px",
              padding: "10",
              width: "100%",
              zIndex: 50
              // marginTop: "6px",
              // border: "1px solid gray"
            }}
            placeholder="Rechercher par période"
            containerStyle={{
              width: "100%",
            }}
            months={months}
            weekDays={weekDays}
            format={"DD/MM/YYYY"}
            value={trimestre}
            onChange={(e: any) => setTrimistre(e)}
            range
            rangeHover
          />
          {
            <div
              className="cursor-pointer -mt-2 -right-3 absolute"
              onClick={() => {
                setTrimistre([]);
              }}
            >
              <HighlightOffIcon sx={{ marginTop: 1.5, marginRight: 2 }} />
            </div>
          }
        </div>
        <p className="xl:text-lg fontbold md:text-base text-sm">
          Pour déclarer le montant facturé à l'URSSAF, veuillez choisir la
          période pour laquelle vous souhaitez obtenir le montant de votre
          facturation.
        </p>
        {!date13 || !dateEnd13 ? (
          ""
        ) : (
          <p className="lg:text-lg fontbold text-base">
            Le montant facturé entre le {date13} et le {dateEnd13} est de :{" "}
            <strong className="text-first font-extrabold">
              {price}€
            </strong>{" "}
          </p>
        )}
      </div>
    );
  };
  const showBlockAmountMobile = () => {
    return (
      <Dialog
        fullWidth
        open={showDialog2}
        onClose={() => {
          setShowDialog2(!showDialog2);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // borderRadius: "42px",
            position: "relative",
            overflow: "visible",
            boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)",
            height: "90%",
          },
        }}
      >
        <div className="flex flex-col p-1 h-[100%]">
          <DialogContent className="flex flex-col">
            {showBlockAmount()}
          </DialogContent>
        </div>
      </Dialog>
    );
  };

  return (
    <div className="  h-[100%] -mt-6 md:-mt-0  ">
      {showSearchInputAndButton()}
      {showHistoricalCourse()}
    </div>
  );
};

export default CoursePonctualPast;
