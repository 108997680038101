import {  useState } from 'react'
import NavBar from '../../../Components/Dashboard/NavBar'
import SubjectsSelect from '../../../Components/SubjectsSelect';
import CourseIndex from './Course/Index';
import { Subject } from '../../../Objects/models/Subject';
import Loader from '../../../Components/utils/Loader';
function Index() {
   
    const [matieres, setMatieres] = useState<Subject[]>()
    const callBack = (value: Subject[]) => {
        setMatieres(value)
    }
 
    return (

        <div className='w-screen h-screen mx-auto overflow-y-auto pb-14'>
            {/* <CourseFinish /> */}
            <Loader />
            <NavBar  active = {2} />
            <div className="flex flex-col gap-14">
                <div className='px-2 md:pl-8 lg:w-1/2 md:w-3/4'>
                    <SubjectsSelect placeholder="SVT, piano, anglais..." callBack={callBack} customClass="bg-[#F2F5FB] p-3" />
                </div>
                <CourseIndex search = {matieres}/>
            </div>

        </div>
    )
}

export default Index