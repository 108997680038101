import  { useEffect, useState } from 'react';
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Button } from '@mui/material';
import { SubscriptionCourse } from '../../../../../Objects/models/SubscriptionCourse';
import SubscriptionCourseRepository from '../../../../../Repositories/SubscriptionCourseRepository';
import { SubscriptionCourseProposal } from '../../../../../Objects/models/SubscriptionCourseProposal';
import SubscriptionCourseProposalRepository from '../../../../../Repositories/SubscriptionCourseProposalRepository';
import RegularCandidacyComponent from '../../../../../Components/Dashboard/Course/candidacy/Regular';

const RegularCandidacy = (proposal: SubscriptionCourseProposal) => {
    const MySwal = withReactContent(Swal)
    const cancelProposal = () => {
        Swal.clickConfirm()
        SubscriptionCourseProposalRepository.deleteSubscriptionCourseProposal(proposal.teachr_id, proposal.id!).then(() => window.location.reload())
    }
    const [course, setCourse] = useState<SubscriptionCourse>()
    useEffect(()=> {
        SubscriptionCourseRepository.fetchSubCourseByIdForTeachr(proposal.teachr_id, proposal.subscriptionCourse_id).then((response)=> {
            setCourse(response)
        }) 
    },[])
    const showDialogue = () => {
        let timerInterval: any
        MySwal.fire({
            showConfirmButton: false,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>RETIRER MA CANDIDATURE</p>
                        <img onClick={() => Swal.close()} className='cursor-pointer' src={closeCircle} alt="" />
                    </div>
                    <div className="py-8 text-left">
                        <div>
                            <p className='text-[20px] font-semibold text-[#4D4D4D]'>Êtes-vous sûr de vouloir retirer votre candidature ?</p>
                        </div>
                        <div className="flex justify-between gap-8 pt-14">
                            <Button onClick={() => Swal.clickCancel()} className='w-full font-extrabold uppercase bg-transparent border-2 border-solid rounded-full text-third border-third' >Annuler</Button>
                            <Button onClick={() => cancelProposal()} className='w-full font-extrabold uppercase bg-transparent border-2 border-solid rounded-full text-first border-first' >Confirmer</Button>
                        </div>
                    </div>
                </div>
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: 'Supprimée !',
                    icon: 'success',
                    text: "Votre candidature viens d'être retirée.",
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-candidature-close')
        MySwal.getPopup()?.classList.add('w-max')
        MySwal.getPopup()?.classList.add('rounded-[20px]')
    }
    const getWeeklyHours = () => {
        if (course) {
            const duration = course.durationPerDay / 2
            const days = course.daysOfTheWeek.length
            return  duration * days
        }
        return 0
    }
    return (
        <div className='w-max'>
            <RegularCandidacyComponent {...proposal} />
            <div className='shadow-candidancy-sub p-6 rounded-[10px] mx-auto md:mx-0 my-4 pb-2 lg:pb-6'>
                <p className='flex items-center text-xl font-bold text-first'>Rémunération par semaine </p>
                <p className='pl-2 font-bold text-[28px] text-first'>{proposal.teachrPrice && (parseFloat(proposal.teachrPrice) * getWeeklyHours()).toFixed(2)} €</p>
                <button onClick={() => showDialogue()} className='text-[16px] underline text-normal mx-auto w-full  lg:hidden'> Retirer la candidature </button>
            </div>
            <button onClick={() => showDialogue()} className='text-[16px] underline text-normal mx-auto w-full hidden lg:block'> Retirer la candidature </button>
        </div>
    );
};

export default RegularCandidacy;