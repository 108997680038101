import React, { useEffect, useRef } from 'react';
// import Chart from "chart.js/auto";
import ReactApexChart from 'react-apexcharts';
import Functions from '../../../../../../../Helpers/Functions';

const ComponentMonthChart = (props) => {
    const { durationByMonth } = props
    const [chartData, setChartData] = React.useState({ series: [], options: {} });
    React.useEffect(() => {
    const dataEnCours = [
        durationByMonth["janvier"] !== undefined && durationByMonth["janvier"],
        durationByMonth["février"] !== undefined && durationByMonth["février"],
        durationByMonth["mars"] !== undefined && durationByMonth["mars"],
        durationByMonth["avril"] !== undefined && durationByMonth["avril"],
        durationByMonth["mai"] !== undefined && durationByMonth["mai"],
        durationByMonth["juin"] !== undefined && durationByMonth["juin"],
        durationByMonth["juillet"] !== undefined && durationByMonth["juillet"],
        durationByMonth["août"] !== undefined && durationByMonth["août"],
        durationByMonth["septembre"] !== undefined && durationByMonth["septembre"],
        durationByMonth["octobre"] !== undefined && durationByMonth["octobre"],
        durationByMonth["novembre"] !== undefined && durationByMonth["novembre"],
        durationByMonth["décembre"] !== undefined && durationByMonth["décembre"]
    ]
    const chartOptions = {
        series: [
            {
                name: 'Durée',
                data: dataEnCours
            },
        ],
        chart: {
            height: 350,
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false, // Définir à true si vous souhaitez afficher la barre d'outils, false pour la masquer
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            type: 'string',
            categories: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Jui", "Aôut", "Sept", "Oct", "Nov", "Dec"],
            labels: {
                show: true,
                style: {
                    colors: "#808080",
                    fontSize: '12px',
                    fontFamily: 'nunito',
                    fontWeight: 400,
                },
            },
        },
        yaxis: {
            labels: {
                show: true,
                formatter: function (value) {
                    return Functions.renderDuration(value.toFixed(0)); // Formatage avec des séparateurs de milliers
                },
                style: {
                    colors: "#808080",
                    fontSize: '12px',
                    fontFamily: 'nunito',
                    fontWeight: 400,
                },
            },
        },
        legend: {
            position: 'top',
        },
        fill: {
            opacity: 1,
            colors: "#0054A3"
        },
        dataLabels: {
            formatter: (val) => {
                return Functions.renderDuration(val)
            }
        },
    };
        setChartData({ ...chartData, series: chartOptions.series, options: chartOptions });
    }, [durationByMonth])

    return (
        <div id="chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
        </div>
    );
};

export default ComponentMonthChart;