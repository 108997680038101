export default class TeachrPutTva {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;


    tauxTva: number
    tvaNumber: string

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    readonly creationDatetime?: Date;

    constructor(
        tauxTva: number,
        tvaNumber: string,
    ) {
        this.tauxTva = tauxTva;
        this.tvaNumber = tvaNumber;
    }

}
