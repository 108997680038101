import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

export default function MultipleSelect(props) {
  return (
    <>
    <div className= {"md:flex hidden items-center justify-between  //lg:text-lg //md:text-md t//ext-sm text-[#4d4d4d] -pt-2 rounded-lg " + props.customClass}>
      <Autocomplete
      fullWidth
        limitTags={2}
        id="multiple-limit-tags"
        options={props.data.map((option) => option)}
        value={props.value}
        onChange= {(value,getTagProps) => props.callBack(getTagProps)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <span key={index}>{option + " ,"} </span>)
        }
        renderInput={(params) => (
          <TextField className="lg:text-xl md:text-md text-sm" {...params} placeholder={props.placeholder} />
        )}
        // sx={{
        //   width: "100%",
        //   "& .MuiOutlinedInput-root": { fontFamily:'nunito'},
        //   "& fieldset": {
        //     border: "none",
        //     textDecoration: "black",
        //   },
        // }}
        sx={{
          width: "100%",
          paddingTop:'-3rem',
          "& .MuiOutlinedInput-root": {
            fontSize:"18px",
             fontFamily:'nunito' },
          "& fieldset": {
            border: "none",
            textDecoration: "black",
          },
        }}
      />
  
    </div>
    <div className= {"flex md:hidden items-center justify-between lg:text-lg md:text-md text-sm -pt-2 rounded-lg " + props.customClass}>
      <Autocomplete
      fullWidth
        limitTags={2}
        id="multiple-limit-tags"
        options={props.data.map((option) => option)}
        value={props.value}
        onChange= {(value,getTagProps) => props.callBack(getTagProps)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => <span key={index}>{option + " ,"} </span>)
        }
        renderInput={(params) => (
          <TextField className="lg:text-xl md:text-md text-sm" {...params} placeholder={props.placeholder} />
        )}
        sx={{
          width: "100%",
          "& .MuiOutlinedInput-root": { fontFamily:'nunito'},
          "& fieldset": {
            border: "none",
            textDecoration: "black",
          },
        }}
      />
  
    </div>
    </>
  );
}