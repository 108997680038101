import React from 'react';
import teachrLogo from '../../Assets/Images/logo_bleu.png'
import monabanqLogo from "../../Assets/Images/monabank.png"
const Footer = () => {
    return (
        <footer className=''>
            <div className='flex items-center justify-center py-10 lg:py-14 lg:gap-x-8 gap-x-4 md:px-8'>
                <div>
                    <img className='lg:w-[135.11px] w-[99.14px] lg:h-[46px]' src={teachrLogo} alt="" />
                </div>
                <div className='hidden lg:block'>
                    <svg width="1" height="64" viewBox="0 0 1 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="2.18557e-08" x2="0.499997" y2="64" stroke="black" />
                    </svg>
                </div>
                <div className='lg:hidden'>
                    <svg width="1" height="38" viewBox="0 0 1 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <line x1="0.5" y1="-2.18557e-08" x2="0.500002" y2="38" stroke="black" />
                    </svg>

                </div>
                <div>
                    <img className='lg:w-[230.13px] w-[123px] lg:h-[64px] h-[34px]' src={monabanqLogo} alt="" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;