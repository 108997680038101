import React, { useEffect, useState } from 'react';


import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from '../../../Components/Admin/DateInput';
import AddressComponent from '../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import moment from 'moment';


const dateInputStyle = {
    backgroundColor: '#fff',
    padding: '2px 8px',
    boxShadow: 'none',
    border: '1px solid rgba(77,77,77,0.29)',
    borderRadius: '9.3px'

}
const Second = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [firstName, setFirstName] = useState('')
    const [birthName, setBirthName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [signCity, setSignCity] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [error, setError] = useState({
        firstName: false,
        birthName: false,
        birthDate: false,
        signCity: false,
        city: false,
        address: false
    })
    useEffect(() => {
        
        if (generalProcuration) {
            if (generalProcuration.first.firstName) {
                setFirstName(generalProcuration.first.firstName)
            }
            if (generalProcuration.first.lastName) {
                setBirthName(generalProcuration.first.lastName)
            }
            if (generalProcuration.first.birthDate) {
                setBirthDate(generalProcuration.first.birthDate)
            }
            if (generalProcuration.first.birthCity) {
                setCity(generalProcuration.first.birthCity)
            }
            if (generalProcuration.first.signCity) {
                setSignCity(generalProcuration.first.signCity)
            }
            if (generalProcuration.first.address) {
                setAddress(generalProcuration.first.address)
            }
        }
    }, [generalProcuration])
    const validate = () => {
        let error_firstName = true
        let error_birthName = true
        let error_birthDate = true
        let error_signCity = true
        let error_city = true
        let error_address = true
        if (firstName === '') {
            error_firstName = true
        } else {
            error_firstName = false
        }
        if (birthName === '') {
            error_birthName = true
        } else {
            error_birthName = false
        }
        if (birthDate === '') {
            error_birthDate = true
        } else {
            error_birthDate = false
        }
        if (city === '') {
            error_city = true
        } else {
            error_city = false
        }
        if (address === '') {
            error_address = true
        } else {
            error_address = false
        }
        if (signCity === '') {
            error_signCity = true
        } else {
            error_signCity = false
        }
        setError({
            firstName: error_firstName,
            birthName: error_birthName,
            birthDate: error_birthDate,
            signCity: error_signCity,
            city: error_city,
            address: error_address
        })
        if (!error_firstName && !error_birthName && !error_birthDate && !error_signCity && !error_city && !error_address) return true
        return false
    }
    const getBirthday = (value: string) => {
        setBirthDate(value)
    }
    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            setAddress(response[0].formatted_address)
            setSignCity(response[0].address_components[2].long_name)
        })
    }
    const nextStep = () => {
        if (validate()) {
            if (generalProcuration) {
                if (generalProcuration.second) {
                    dispatch({
                        type: 'SET_AE_INFO', payload: {
                            first: {
                                firstName: firstName,
                                lastName: birthName,
                                birthDate: birthDate,
                                birthCity: city,
                                address: address,
                                signCity: signCity,
                            },
                            second: generalProcuration.second
                        }
                    })
                }
            } else {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        first: {
                            firstName: firstName,
                            lastName: birthName,
                            birthDate: birthDate,
                            birthCity: city,
                            address: address,
                            signCity: signCity,
                        }
                    }
                })
            }
            props.callBack(2)
        }
    }

    return (
        <div className='flex h-full gap-10'>

            <div className='relative w-full'>
                <div className='flex items-center pb-10 gap-x-4'>
                    <span className='hidden lg:block'>
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="16" cy="16" r="16" fill="#0054A3" />
                            <path d="M20.4263 20.712C21.3063 20.712 21.7463 21.086 21.7463 21.834C21.7463 22.6113 21.3063 23 20.4263 23H12.9903C12.0956 23 11.6483 22.6113 11.6483 21.834C11.6483 21.086 12.0956 20.712 12.9903 20.712H15.2783V10.68L13.1663 11.978C12.961 12.0953 12.7776 12.154 12.6163 12.154C12.3083 12.154 12.0443 12.022 11.8243 11.758C11.619 11.494 11.5163 11.2007 11.5163 10.878C11.5163 10.4527 11.707 10.1227 12.0883 9.888L15.4983 7.776C15.9383 7.512 16.3563 7.38 16.7523 7.38C17.163 7.38 17.493 7.50467 17.7423 7.754C17.9916 8.00333 18.1163 8.348 18.1163 8.788V20.712H20.4263Z" fill="white" />
                        </svg>
                    </span>
                    <h2 className='text-first lg:text-[22px] text-lg font-bold'>Votre procuration :</h2>
                </div>
                <div className="grid justify-between w-full lg:grid-cols-2 gap-4 items-end grid-cols-1">
                    <div>
                        {error.firstName ? <p className='text-third pb-1'>Veuillez renseigner un prénom</p> : null}
                        <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                            <label htmlFor="" className='block text-base'>Prénom</label>
                            <input type="text" defaultValue={firstName} onChange={(e) => setFirstName(e.target.value)} className='placeholder:text-[#808080] text-base bg-transparent w-full block' placeholder='Julie' />
                        </div>
                    </div>
                    <div>
                        {error.birthName ? <p className='text-third pb-1'>Veuillez renseigner un nom de naissance</p> : null}
                        <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                            <label htmlFor="" className='block text-base'>Nom de naissance</label>
                            <input type="text" defaultValue={birthName} onChange={(e) => setBirthName(e.target.value)} placeholder='Dupont' className='placeholder:text-[#808080] text-base bg-transparent' />
                        </div>
                    </div>
                    <div>
                        {error.birthDate ? <p className='text-third pb-1'>Veuillez renseigner une date de naissance</p> : null}
                        {generalProcuration && generalProcuration.first && generalProcuration.first.birthDate ? <DateInput defaultValue={moment(generalProcuration.first.birthDate, 'L').format('YYYY-MM-DD')} field='birthday' style={dateInputStyle} callBack={getBirthday} /> : <DateInput field='birthday' style={dateInputStyle} callBack={getBirthday} />}
                    </div>
                    <div>
                        {error.city ? <p className='text-third pb-1'>Veuillez renseigner une ville de naissance</p> : null}
                        <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                            <label htmlFor="" className='block text-base'>Ville de naissance</label>
                            <input defaultValue={city} type="text" onChange={(e) => setCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-base bg-transparent' />
                        </div>
                    </div>

                </div>
                <div className='w-full'>
                    <hr className='text-[rgba(128,128,128,0.80)] border-dashed my-8' />
                    <div className="flex gap-4 items-end lg:flex-row flex-col">
                        <div className='w-full'>
                            {error.address ? <p className='text-third pb-1'>Veuillez renseigner une adresse</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-1 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Adresse</label>
                                <AddressComponent default={address} getPlaceId={getAddress} placeholder="Ex: 2 rue de la Seine, 94004" />
                            </div>
                        </div>
                        <div className='w-full'>
                            {error.signCity ? <p className='text-third pb-1'>Veuillez renseigner un lieu</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block text-base'>Procuration faite à</label>
                                <input defaultValue={signCity} type="text" onChange={(e) => setSignCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-base bg-transparent' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-end lg:py-10">
                    <div className="flex justify-between w-full lg:gap-10 gap-y-4 lg:flex-row flex-col">
                        <Button onClick={nextStep} className='text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2' style={{ background: 'linear-gradient(180deg, #0054A3 0%, #219CFF 100%)' }}>SUIVANT</Button>
                        <Button className='text-normal lg:text-xl rounded-full px-10 lg:py-3 mx-auto block  w-full lg:w-max py-2 lg:hidden font-semibold' onClick={() => props.prevStep()}>précédent</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Second;