import errorIcon from "../Assets/Images/error.png";
const validateCustom = (value) => {
    if (value.length === 0) {
        return {
            isValid: false,
            message: "Veuillez remplir ce champ",
            icon: < img src = { errorIcon }
            alt = ""
            className = "w-4 h-4" / >
        };
    } else
        return {
            isValid: true,
            message: "",
        };
};

export default validateCustom;