import { useNavigate } from "react-router-dom";
// import NavBarCominsoon from "../../../../Components/NavBarCominsoon";

function NoLessonRequest() {
  const navigate = useNavigate();
  return (
    <div className="h-screen overflow-hidden font-nunito">
      <div className=" md:mt-[74px] mt-6 gap-x-12 lg:mx-[79px]  md:flex  mx-[32px]">
        <div className=" md:w-1/2  space-y-4 text-dark_blue xl:text-3xl lg:text-2xl md:text-xl text-base  ">
          <div>
            <p className="font-bold">Aucune demande de cours effectuée 🙁</p>
            <p className="font-semibold xl:text-2xl   lg:text-xl md:text-lg text-sm ">
              Demandez vite votre premier cours, nos Teach’rs vous attendent
              avec impatience ! Rendez-vous dans l’onglet “prendre un cours” et
              effectuez votre demande.{" "}
            </p>
          </div>
          <div className="translate-y-20">
            <div className="md:w-1/2 mx-auto p-2 lg:text-base hover:text-[#219CFF]  //md:translate-y-20 //translate-y-44  hover:border-2 hover:border-[#219CFF]   bg-[#219CFF] text-center lg:py-2 lg:px-4 rounded-full uppercase text-xs md:text-sm ">
              <div
                onClick={() => navigate("/user/formlayout")}
                className="no-underline  hover:font-bold text-white text-center  font-extrabold"
              >
                prendre un cours
              </div>
            </div>
          </div>
        </div>
        <div className="bg-fontwaiting bg-no-repeat  w-1/2 h-[350px] pl-12 md:flex hidden"></div>
      </div>
    </div>
  );
}

export default NoLessonRequest;
