import React from "react";
import { AppState } from "../Redux/store";
import { connect } from "react-redux";
import AppPropsInterface from "../Objects/interfaces/AppPropsInterface";
import {
    generalDismissError,
    generalSetError,
} from "../Redux/Actions/GeneralActions";
import { Toast } from "react-bootstrap";

interface ScreenState {
    showError: boolean;
}

/**
 * Shows the error banner
 */
class RootOverlay extends React.Component<AppPropsInterface, ScreenState> {

    public readonly state: ScreenState = {
        showError: false,
    }

    /**
     * When the props of redux are updated, this function is executed to show the error banner.
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate(
        prevProps: Readonly<AppPropsInterface>,
        prevState: Readonly<ScreenState>,
        snapshot?: any
    ): void {
        if (this.props.general.error !== undefined) {
            setTimeout(() => {
                this.props.dispatch(generalDismissError());
            }, 5000)
            // we remove the error from the state
        }
    }

    onClose=()=>{
        this.props.dispatch(generalDismissError());
    }

    render() {
        return (
            <>{this.props.general.error &&
                <Toast className="d-inline-block m-1" show={!!this.props.general.error} bg={this.props.general.error?.type} autohide={true} delay={3000} onClose={this.onClose}>
                    <Toast.Header>
                        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
                        <strong className="me-auto">{this.props.general.error?.title}</strong>
                    </Toast.Header>
                    <Toast.Body >
                        {this.props.general.error?.message}
                    </Toast.Body>
                </Toast>
            }
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return { general: state.general };
};

export default connect(mapStateToProps)(RootOverlay);
