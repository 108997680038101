import React from 'react';
import { CoursePack } from '../../../../../Objects/models/CoursePack';

import CourseProposal from '../../../../../Objects/models/CourseProposal';
import { SubscriptionCourse } from '../../../../../Objects/models/SubscriptionCourse';
import Teachr from '../../../../../Objects/models/Teachr';
import PackCourseAccepted from './Pack';
import PunctualCourseAccepted from './Punctual';
import RegularCourseAccepted from './Regular';
const Accepted = (state: { regular: SubscriptionCourse[] | undefined, punctual: CourseProposal[] | undefined, pack: CoursePack[] | undefined , teachr: Teachr}) => {
 
   return (
        <div>
            <div className='flex flex-wrap justify-center gap-8 md:justify-start xl:gap-x-[1.3%]'>
                {state.punctual && state.punctual.map((proposal) =>  
                <PunctualCourseAccepted {... proposal}/>
                )}
                {state.regular && state.regular.map((course) =>  
                <RegularCourseAccepted teachr={state.teachr} course={course} />
                )}
                {state.pack && state.pack.map((course) =>  
                <PackCourseAccepted course={course} />
                )}
            </div>
        </div>
    );
};

export default Accepted;