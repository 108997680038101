const SET_INFO = 'SET_SAP_INFO';

const initialState = {
  firstName: '',
  lastName: '',
  birthDate: '',
  birthCity: '',
  siret: '',
  address: '',
  signCity: '',

}

export default  function procurationSapReducers(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      default:
        return state
    }
  }