// import Loader from "../../../../Components/Utilitis/Loader";
import gif from "../../../../Assets/Icons/load_general.gif";

const WaitingResponse = () => {
    return (
        <div className="mt-20 ml-48">
        <div>
          <p className="xl:text-[24px] lg:text-lg md:text-base text-sm  text-dark_blue font-semibold">
          Veuillez patienter, nous cherchons des réponses pour votre demande. 
          </p>
        </div>
        <div className=" mx-auto mt-4 h-1/2 w-1/2">
          <img src={gif} alt="gif" className="h-28 w-28 mx-auto"/>
        </div>
      </div> 
    );
};

export default WaitingResponse;