import HydraEntity from "./HydraEntity";
import Client from "./Client";
import { Type } from "class-transformer";
import "reflect-metadata";

export default class UsrssafStatusService extends HydraEntity {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    id?: number;
    idClient: string ;
    status: string;
    done: boolean;
    statusDescription: string;
    error: any;

    constructor(
        idClient: string ,
        status: string,
        done: boolean,
        statusDescription: string,
        error: string
    ) {
        super();
        this.idClient = idClient;
        this.status = status;
        this.done = done;
        this.statusDescription = statusDescription;
        this.error = error;
    }
}
