import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";

export class SubscriptionCourseDashboardUpdate extends HydraEntity implements DisplayableCourse {
    startDate: string;
    /**
     * The end datetime of the course.
     */
    startTime: string;


    /**
     * The frequency of a regular course.
     */
    daysOfTheWeek: Array<string>;

    /**
     * Duration of a course, in half hours.
     */

    durationPerDay: number;

    dataCourse?:any;

   


    constructor(
        daysOfTheWeek: Array<string>,
        startDate: string,
        startTime: string,
        durationPerDay: number,
        dataCourse?: any,
    ) {
        super();
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-sequences
        this.daysOfTheWeek = daysOfTheWeek,
        this.startDate = startDate,
        this.startTime = startTime,
        this.durationPerDay = durationPerDay;
        this.dataCourse = dataCourse;
    }
}
