import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Functions from '../../../../Helpers/Functions';
import { FileUploader } from 'react-drag-drop-files';
import { useNavigate } from 'react-router-dom';

const fileTypes = ["PDF", "PNG", "JPEG", "JPG"];

const Fourth = (props: any) => {
    const navigate = useNavigate()
    // const [isFrance, setIsFrance] = useState(1)
    const [file, setFile] = useState<File | null>();
    const [error, setError] = useState(false)

    const loadFile = (value: any) => {
        if (value) {
            setError(false)
            if (value.type.search("image") === 0) {
                Functions.resizeFile(value, true).then((response: any) => {
                    setFile(response);
                })
            } else {
                setFile(value)
            }
        }
    };
    useEffect(() => {
        if (file) {
            props.getProofIdentity(file)
        }
    }, [file])

    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setIsFrance(parseInt((event.target as HTMLInputElement).value));
    // };
    const nextPage = () => {
        if (file) {
            props.callBack(props.index)
        }
    }

    return (
        <div className='relative w-full flex flex-col gap-y-8'>
            <div className='flex items-center gap-x-4 '>
            <span className='hidden lg:inline'>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill="#0054A3" />
                    <path d="M18.3803 15.036C19.3043 15.2707 20.0083 15.718 20.4923 16.378C20.991 17.0233 21.2403 17.83 21.2403 18.798C21.2403 20.1327 20.749 21.196 19.7663 21.988C18.7836 22.78 17.4563 23.176 15.7843 23.176C14.831 23.176 13.9143 23.0293 13.0343 22.736C12.1543 22.4427 11.4063 22.032 10.7903 21.504C10.453 21.2107 10.2843 20.866 10.2843 20.47C10.2843 20.1473 10.3723 19.876 10.5483 19.656C10.739 19.4213 10.959 19.304 11.2083 19.304C11.355 19.304 11.487 19.3333 11.6043 19.392C11.7216 19.436 11.883 19.5167 12.0883 19.634C12.7043 20.0447 13.291 20.36 13.8483 20.58C14.4056 20.8 15.0143 20.91 15.6743 20.91C16.6423 20.91 17.361 20.7193 17.8303 20.338C18.2996 19.942 18.5343 19.348 18.5343 18.556C18.5343 17.7787 18.285 17.2067 17.7863 16.84C17.3023 16.4587 16.547 16.268 15.5203 16.268H14.2663C13.929 16.268 13.665 16.158 13.4743 15.938C13.2983 15.7033 13.2103 15.4393 13.2103 15.146C13.2103 14.838 13.2983 14.574 13.4743 14.354C13.665 14.134 13.929 14.024 14.2663 14.024H15.1463C17.1556 14.024 18.1603 13.2687 18.1603 11.758C18.1603 11.054 17.9476 10.5113 17.5223 10.13C17.097 9.74867 16.5103 9.558 15.7623 9.558C14.6916 9.558 13.5476 9.98333 12.3303 10.834C12.125 10.9513 11.9636 11.0393 11.8463 11.098C11.729 11.142 11.597 11.164 11.4503 11.164C11.201 11.164 10.981 11.054 10.7903 10.834C10.6143 10.5993 10.5263 10.3207 10.5263 9.998C10.5263 9.778 10.563 9.59467 10.6363 9.448C10.7243 9.28667 10.8563 9.12533 11.0323 8.964C11.6483 8.45067 12.3816 8.04733 13.2323 7.754C14.0976 7.446 14.9776 7.292 15.8723 7.292C17.3976 7.292 18.6076 7.666 19.5023 8.414C20.4116 9.162 20.8663 10.1593 20.8663 11.406C20.8516 12.2567 20.6243 13.0047 20.1843 13.65C19.759 14.2807 19.1576 14.7427 18.3803 15.036Z" fill="white" />
                </svg>
                </span>
                <h2 className='text-first lg:text-[22px] text-lg font-bold'>Annexe 2 : Fiche d’information NOVA</h2>
            </div>
            <div className='flex flex-col gap-y-2'>
                <p className='lg:text-xl font-semibold text-normal mt-4 text-base'>Veuillez prendre en compte les informations suivantes avant la signature du document.</p>
                <a  target= "_blank" download href = {props.file}  className='lg:text-xl font-semibold text-radio text-bae'>visualiser la procuration</a>
                <div className='flex flex-col gap-y-8'>
                <p className='lg:text-xl font-semibold text-normal text-base'>Merci de bien vouloir joindre votre extrait KBIS ou votre avis de situation SIREN. 
                <br /> Si vous n'avez plus vous pouvez le retélécharger depuis<br />         
                <a className='underline text-blue-400 text-base lg:text-xl' href="https://avis-situation-sirene.insee.fr/" target="_blank" rel="noopener noreferrer">https://avis-situation-sirene.insee.fr/</a>
                </p>
                    {/* <p className='text-normal font-semibold text-base'><span className='font-bold'>Titre de séjour ou carte de résident</span> en cours de validité avec mention manuscrite : </p> */}
                    {/* <p className='text-normal font-semibold text-base '>“ j’atteste sur l’honneur que ce document d’identité est conforme à l’original”</p> */}
                    {error ?
                        <FileUploader
                            classes='focus-within:bg-first  '
                            children={
                                <div className='flex items-center flex-col rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-y-8'>
                                    <p className='text-third'>Veuillez insérer un fichier au format : ”PDF, PNG ou JPEG” ❌</p>
                                    <p className='text-sm font-semibold text-center text-normal'>
                                        Glissez / déposez votre avis de situation SIREN ou <u className='cursor-pointer'>extrait KBIS</u>
                                    </p>
                                </div>
                            }
                            onTypeError={(value: any) => setError(true)}
                            handleChange={loadFile}
                            name="file"
                            types={fileTypes}
                        />

                        :
                        file ?
                            <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 order-last lg:order-first'>
                                <div className="flex items-center gap-x-4">
                                    <p>“{file.name}” </p>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                    </svg>
                                </div>
                                <Button onClick={() => setFile(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                            </div> :
                            <FileUploader
                                classes='focus-within:bg-first'
                                children={
                                    <div className='flex items-center  rounded-[10px] border-dashed  border-2 border-[#B7B7B7] bg-transparent p-4  gap-x-8'>
                                        <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.081 14.3031C12.9587 14.1766 12.8365 14.05 12.7143 14.05C12.4698 13.9234 12.1031 13.9234 11.7364 14.05C11.6142 14.1766 11.4919 14.1766 11.3697 14.3031L7.70264 18.1002C7.2137 18.6065 7.2137 19.3659 7.70264 19.8722C8.19158 20.3785 8.92499 20.3785 9.41393 19.8722L11.003 18.2268V27.8461C11.003 28.6055 11.4919 29.1118 12.2253 29.1118C12.9587 29.1118 13.4477 28.6055 13.4477 27.8461V18.2268L15.0367 19.8722C15.2812 20.1253 15.6479 20.2519 15.8924 20.2519C16.1369 20.2519 16.5036 20.1253 16.748 19.8722C17.237 19.3659 17.237 18.6065 16.748 18.1002L13.081 14.3031Z" fill="#808080" />
                                            <path d="M24.3248 9.61926C24.2025 9.49269 24.2025 9.36612 24.0803 9.23956L15.5238 0.379708C15.4016 0.253139 15.2794 0.126569 15.1571 0.126569C15.0349 0 14.7904 0 14.6682 0H3.66705C1.58905 0 0 1.6454 0 3.79708V31.6423C0 33.794 1.58905 35.4394 3.66705 35.4394H20.7799C22.8579 35.4394 24.447 33.794 24.447 31.6423V10.1255C24.447 9.99897 24.447 9.74583 24.3248 9.61926ZM15.8905 4.30335L20.291 8.85985H17.1129C16.3795 8.85985 15.8905 8.35357 15.8905 7.59416V4.30335ZM22.0023 31.6423C22.0023 32.4017 21.5134 32.908 20.7799 32.908H3.66705C2.93364 32.908 2.4447 32.4017 2.4447 31.6423V3.79708C2.4447 3.03766 2.93364 2.53139 3.66705 2.53139H13.4458V7.59416C13.4458 9.74583 15.0349 11.3912 17.1129 11.3912H22.0023V31.6423Z" fill="#808080" />
                                        </svg>

                                        <p className='text-sm font-semibold text-center text-normal'>
                                        Glissez / déposez votre avis de situation SIREN ou <u className='cursor-pointer'>extrait KBIS</u>
                                        </p>
                                    </div>
                                }
                                onTypeError={(value: any) => setError(true)}
                                handleChange={loadFile}
                                name="file"
                                types={fileTypes}
                            />

                    }
                </div>
            </div>
            <div className="flex justify-end lg:py-10 pt-8">
                <div className="flex justify-between w-full lg:gap-10 gap-y-4 flex-col lg:flex-row">
                    <Button onClick={() => props.callBack(2)} className='flex lg:w-1/3 lg:py-3 py-2 lg:text-xl text-base font-semibold rounded-full gap-x-4 text-normal w-full order-last lg:order-first'>
                        <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" strokeWidth="1.5" strokeLinecap="round" />
                        </svg>
                        PRÉCÉDENT
                    </Button>
                    <Button onClick={() => nextPage()} className='lg:w-1/3 lg:py-3 py-2 lg:text-xl text-base font-extrabold text-white rounded-full bg-first w-full '>SUIVANT</Button>
                </div>
            </div>
        </div>
    );
};

export default Fourth;