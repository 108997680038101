import validator from "validator";
import errorIcon from "../Assets/Images/error.png";

const validateChild = (value) => {
    if (value === "") {
        return {
            isValid: false,
            message: "Veuillez entrer les informations",
            icon: < img src = { errorIcon }
            alt = ""
            className = "w-4 h-4 mt-1" / >
        };
    } else
        return {
            isValid: true,
            message: "",
        };
};

export default validateChild;