import React, { useEffect, useState } from 'react'
import StepperAe from '../../../../../Components/Dashboard/Account/Stepper'
import backgroundImage from '../../../../../Assets/Images/bg-ae.svg'
import Account from './Account'
import NovaIndex from './Nova/Index'
import Teachr from '../../../../../Objects/models/Teachr'
import TeachrRepository from '../../../../../Repositories/TeachrRepository'
import IsAeComponent from '../IsAe/Index'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
const Index = () => {
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const generalTeachr = useSelector((state: any) => state.teachr)
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((res) => {
            if (res) {
                setTeachr(res);
            }else{
                navigate("/teachr/login");
            }
        }).catch((err) => {
            console.warn(err);
            navigate("/teachr/login");
        })
        // if (generalTeachr) {
        //     if (generalTeachr.teachr) {
        //         setTeachr(generalTeachr.teachr)
        //     }else{
        //         navigate('/teachr/login')
        //     }
        // } else {
        //     navigate('/teachr/login')
        // }
    }, [])

    useEffect(() => {
        if (teachr) {
            
            
            if (!teachr.signSap) {
                if (!teachr.proofSignSap || !teachr.proofMandat) {
                    navigate("/teachr/profile/complete")
                }
            }
        }
    }, [teachr])
    if (teachr) {
        if (teachr.signSap) {
            return <IsAeComponent teachr={teachr} />
            
        }else{
            if (teachr.proofSignSap && teachr.proofMandat) {
                return <IsAeComponent teachr={teachr} />
            }else{
                return <div></div>
            }
        }
    }else{
        return <div></div>
    }
};

export default Index;