import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import { useDispatch, useSelector } from 'react-redux';

const Fifth = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [isAe, setIsAe] = useState<number>()
    const [error, setError] = useState(false)

    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.fifth !== undefined) {
            setIsAe(generalProcuration.second.fifth)
        }
    }, [generalProcuration])
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsAe(parseInt((event.target as HTMLInputElement).value));
    };
    // useEffect(
    const handleNext = () => {
        if (isAe !== undefined && isAe >= 0) {
            if (generalProcuration.second.fifth !== undefined) {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        step: 6,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: generalProcuration.second.third,
                            fourth: generalProcuration.second.fourth,
                            fifth: isAe,
                            sixth: generalProcuration.second.sixth!,
                            seventh: generalProcuration.second.seventh!,
                            eighth: generalProcuration.second.eighth!,
                            ninth: generalProcuration.second.ninth!,
                            tenth: generalProcuration.second.tenth!,
                            eleventh: generalProcuration.second.eleventh!
                        }
                    }
                })
            } else {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        step: 6,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: generalProcuration.second.third,
                            fourth: generalProcuration.second.fourth,
                            fifth: isAe
                        }
                    }
                })
            }
            // props.nextStep(6)
            props.setCurrentStep(6)
            setError(false)
        } else {
            setError(true)
        }
    }
    // , [isAe])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
                {error ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Avez-vous déjà eu le statut auto-entrepreneur par le passé ? </label>
                <RadioGroup row
                    value={isAe}
                    defaultValue={generalProcuration && generalProcuration.second ? generalProcuration.second.fifth : null}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
            <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Fifth;