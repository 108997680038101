import { useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
function CoursePrice(props: any) {
    const teachr = props.teachr


    const PRICE_DETAILS = [
        {
            min: 11.50,
            recommanded: 15.50,
            max: 20,

        },
        {
            min: 11.50,
            recommanded: 25,
            max: 250,

        }
    ]
    const getPriceDetail = (price: number, entrepreneur: boolean) => {
        if (entrepreneur) {
            if (price < PRICE_DETAILS[1].recommanded) {
                setPriceDetail({
                    color: 'text-green-600',
                    title: 'prix concurrentiel'
                })
            }
            if (price === PRICE_DETAILS[1].recommanded) {
                setPriceDetail({
                    color: 'text-radio',
                    title: 'prix conseillé'
                })
            }
            if (price > PRICE_DETAILS[1].recommanded) {
                setPriceDetail({
                    color: 'text-third',
                    title: 'prix élevé'
                })
            }
            if (price < PRICE_DETAILS[1].min) {
                setPriceDetail({
                    color: 'text-red-600',
                    title: `vous ne pouvez pas postuler à moins de ${PRICE_DETAILS[1].min}`
                })
            }
            if (price > PRICE_DETAILS[1].max) {
                setPriceDetail({
                    color: 'text-red-600',
                    title: `vous ne pouvez pas postuler à plus de ${PRICE_DETAILS[1].max}`
                })
            }

        } else {
            if (price < PRICE_DETAILS[0].recommanded) {
                setPriceDetail({
                    color: 'text-green-600',
                    title: 'prix concurrentiel'
                })
            }
            if (price === PRICE_DETAILS[0].recommanded) {
                setPriceDetail({
                    color: 'text-radio',
                    title: 'prix conseillé'
                })
            }
            if (price > PRICE_DETAILS[0].recommanded) {
                setPriceDetail({
                    color: 'text-third',
                    title: 'prix élevé'
                })
            }
            if (price < PRICE_DETAILS[0].min) {
                setPriceDetail({
                    color: 'text-red-600',
                    title: `vous ne pouvez pas postuler à moins de ${PRICE_DETAILS[0].min}`
                })
            }
            if (price > PRICE_DETAILS[0].max) {
                setPriceDetail({
                    color: 'text-red-600',
                    title: `vous ne pouvez pas postuler à plus de ${PRICE_DETAILS[0].max} €.`
                })
                if (price <= PRICE_DETAILS[1].max) {
                    setisError(true)
                } else {
                    setisError(false)
                }
            }

        }
    }
    const [priceDetail, setPriceDetail] = useState({
        color: 'text-radio',
        title: 'prix conseillé'
    })
    const [isError, setisError] = useState(false)
    const [price, setPrice] = useState<string>('')
    useEffect(() => {
        if (teachr) {
            if (teachr.isAE) {
                setPrice(PRICE_DETAILS[1].recommanded.toString())
            } else {
                setPrice(PRICE_DETAILS[0].recommanded.toString())
            }
        } else {
            setPrice('0.00')
        }
    }, [teachr])
    useEffect(() => {
        if (teachr) {
            if (parseFloat(price!) >= 0) {
                getPriceDetail(parseFloat(price!), teachr.isAE!)
                props.getPrice(price)
            }
        }
    }, [price])
    const validate = () => {
        if (teachr?.isAE) {
            if (parseFloat(price!) < PRICE_DETAILS[1].min) {
                setPrice(PRICE_DETAILS[1].min.toString())
            }
            if (parseFloat(price!) > PRICE_DETAILS[1].max) {
                setPrice(PRICE_DETAILS[1].max.toString())
            }
        } else {
            if (parseFloat(price!) < PRICE_DETAILS[0].min) {
                setPrice(PRICE_DETAILS[0].min.toString())
            }
            if (parseFloat(price!) > PRICE_DETAILS[0].max) {
                setPrice(PRICE_DETAILS[0].max.toString())
            }
        }
    }

    const weekPrice = () => {
        if (props.duration !== undefined && price && props.days) {
            return parseFloat(price) * parseInt(props.duration!) / 2 * parseInt(props.days)
        }
        return 0
    }
    return (
        <div>
            <div className='shadow-course-select lg:w-[383px] p-6 rounded-[20px] h-full'>
                <div className="flex flex-col gap-6">
                    <p className='flex items-center  gap-4 text-[22px] font-black uppercase text-first'>TARIF <CheckIcon className='text-white bg-radio rounded-full p-[1px]' /></p>
                    <p className='text-[#4D4D4D] lg:text-[16px] text-sm font-semibold leading-[22px]'>Attention : si l’élève valide votre candidature, le prix ne sera plus modifiable pour ce cours. </p>
                    <div>
                        <div className='border-[rgba(128,128,128,0.5)] border-2 rounded-lg p-3 flex justify-between gap-2 text-2xl font-bold'>
                            <input type="number" onMouseMove={() => validate()} step={1 / 2} min={teachr?.isAE ? PRICE_DETAILS[1].min : PRICE_DETAILS[0].min} max={teachr?.isAE ? PRICE_DETAILS[1].max : PRICE_DETAILS[0].max} value={price} onChange={(e) => setPrice(e.target.value)} className={`w-full ${priceDetail.color}`} />
                            <span>€/h</span>
                        </div>
                        {price !== '' ?
                            <p className={`py-4 font-bold ${priceDetail.color}`}>{`Tarif : ${price} € par heure - ${priceDetail.title}`}</p>
                            : <p className={`py-4 font-bold ${priceDetail.color}`}>{`Tarif : ${teachr?.isAE ? PRICE_DETAILS[1].recommanded : PRICE_DETAILS[0].recommanded} € par heure - ${priceDetail.title}`}</p>
                        }
                        {isError && <p className='text-red-600'>Tarif: {price} €  par heure - uniquement pour les auto-entrepreneurs. Pour bénéficier de ce tarif <span className='underline'>cliquez ici.</span></p>}

                    </div>
                    {props.courseType === 'regular' &&
                        <div>
                            <p className='font-bold lg:text-[26px] text-first text-2xl whitespace-nowrap'>Total par semaine : </p>
                            <p className='font-bold lg:text-[20px] text-first text-2xl whitespace-nowrap'>{` ${price} x ${props.days} x ${(props.duration / 2).toString().replace('.', ',')} = ${weekPrice()} €`}</p>
                        </div>
                    }
                    {props.courseType === 'pack' &&
                        <div>
                            <p className='pb-2 overflow-hidden text-xl font-bold text-first'>Rémunération totale :{` ${price}  x  ${(props.duration / 2).toString().replace('.', ',')} x ${props.days} = ${weekPrice()} €`}</p>
                        </div>
                    }
                    {props.courseType !== 'pack' && props.courseType !== 'regular' && <div>
                        <p className='font-bold lg:text-[26px] text-first text-2xl whitespace-nowrap'>Total du cours : </p><p className='font-bold lg:text-[20px] text-first text-2xl whitespace-nowrap'>{` ${price} x  ${(props.duration / 2).toString().replace('.', ',')}  = ${weekPrice()} €`}</p></div>}

                </div>
            </div>
        </div>
    )
}

export default CoursePrice