import React, { useEffect, useState } from 'react';
import NavbarHomePage from '../Navbar/NavbarHomePage';
import EastIcon from "@mui/icons-material/East";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import franceInfo from "../../Assets/Images/franceInfo.svg";
import france2 from "../../Assets/Images/france2.svg";
import europe1 from "../../Assets/Images/europe1.svg";
import bfm from "../../Assets/Images/bfm.svg";
import image from "../../Assets/Images/video1.png";
import { FormControlLabel, Typography } from '@mui/material';
import RadioButton from '../utils/RadioButtonClient';
import Simulator from '../../Screens/Teacher/AfterVideo/Simulator';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
import PhoneInTalkSharpIcon from "@mui/icons-material/PhoneInTalkSharp";
import EmailSharpIcon from "@mui/icons-material/EmailSharp";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import mobileShow from '../../Assets/Images/mobile-show.svg';
import Simulator2 from '../../Screens/Teacher/AfterVideo/Simulator2';
import SimpleSelect from '../utils/SimpleSelect';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, getJWTToken } from '../../Services/FirebaseService';
import ApiService from '../../Services/ApiService';
import TeachrRepository from '../../Repositories/TeachrRepository';
import ClientRepository from '../../Repositories/ClientRepository';
import PunctualCourseRepository from '../../Repositories/PunctualCourseRepository';
import InfoArrayHome1 from '../utils/InfoArrayHome1';
import InfoArrayHome2 from '../utils/InfoArrayHome2';
import InfoArrayHome3 from '../utils/InfoArrayHome3';

interface SimulatorResult {
  status: number;
  weekHours: number;
  hourlyPrice: number;
  age: number;
  netPricePerHour: number;
  netPricePerMonth: number;
  netPricePerYear: number;
}

const TeachrHomePage = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState<SimulatorResult>();
  const [user] = useAuthState(auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      (async () => {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken);
        TeachrRepository.getCurrentTeachr()
          .then((response) => {
            PunctualCourseRepository.getPunctualCourseByTeachr(
              response.id!
            ).then((courses) => {
              if (courses.length > 0) {
                navigate("/teachr/dashboard/");
              } else if (response.isFirstCourse) {
                navigate("/teachr/dashboard/");
              } else {
                navigate("/teachr/cours");
              }
            });
          })
          .catch(() => {
            ApiService.disableAuth();
            ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
            ClientRepository.getCurrentClient()
              .then((response) => {
                navigate("/user/response");
                dispatch({
                  type: "HIDE_LOADER",
                  payload: "",
                });
              })
              .catch(() => {
                ApiService.disableAuth();
                navigate("/teachr/register/next");
              });
          });
      })();
    }
  }, [user]);
  const [position, setPosition] = useState({
    isHome: true,
    isRemote: false,
  });
  const handleIsHome = () => {
    setPosition({
      isRemote: false,
      isHome: true,

    });
  };
  const handleIsRemote = () => {
    setPosition({
      isRemote: true,
      isHome: false,

    });
  };

  useEffect(() => {
    dispatch({
      type: "RESET_INFO",
    });
    const el = document.querySelector("body");
    if (el) {
      el.classList.add("bg-[rgba(239,246,255,1)]");
    }
    return () => {
      if (el) {
        el.classList.remove("bg-[rgba(239,246,255,1)]");
      }
    };
  }, []);
  const getResult = (value: SimulatorResult) => {
    if (value) {
      dispatch({
        type: 'SET_INFO',
        payload: value
      })
      setResult(value)
    }
  }
  const displayMobile = () => {
    return (
      <div className="md:hidden mt-24">
        <img src={mobileShow} alt="" />
        <div className='px-8'>

        <button
          className="w-full  border text-white bg-first text-base uppercase rounded-full py-2 font-extrabold shadow-md xl:mt-20 mt-10"
          onClick={() => navigate("/teachr/register")}
        >
          JE M’INSCRIS !
        </button>
        </div>
      </div>
    );
  }

  const [system, setSystem] = useState("Avec le CESU");
  const [value, setValue] = useState(1);
  const getSystem = (value: any) => {
    switch (value) {
      case 1:
        setSystem("Avec le CESU");
        setValue(1);
        break;
      case 2:
        setSystem("Avec le CESU+");
        setValue(2);
        break;
      case 3:
        setSystem("Auto-entrepreneur sans crédit d'impôt");
        setValue(3);
        break;
      case 4:
        setSystem("Auto-entrepreneur avec crédit d’impôt");
        setValue(4);
        break;
      case 5:
        setSystem("Non déclarés");
        setValue(5);
        break;

      default:
        setSystem("Autres");
        break;
    }
  };

  const dataList = [
    {
      label: "Vos cours sont déclarés",
      system: value === 1 ? 1 : value === 2 ? 1 : value === 3 ? 1 : value === 4 ? 1 : value === 5 ? 0 : 1,
      aici: 1,
    },
    {
      label: "Montant de cotisations sociales sur vos cours",
      system: value === 1 ? 2 : value === 2 ? 2 : value === 3 ? 3 : value === 4 ? 3 : value === 5 ? 0 : 3,
      aici: 2,
    },
    {
     
      label: "Remboursement de 50% instantané",
      img : <InfoArrayHome1/>,
      system: value === 1 ? 0 : value === 2 ? 1 : value === 3 ? 0 : value === 4 ? 0 : value === 5 ? 0 : 1,
      aici: 1,
    },
    // {
    //   label: "Cotisations sociales",
    //   system: value === 1 ? 1 : value === 2 ? 1 : value === 3 ? 1 : value === 4 ? 1 : value === 5 ? 0 : 1,
    //   aici: 1,
    // },
    {
      label: "Avoir la main sur vos paiements",
      img : <InfoArrayHome2/>,
      system: value === 1 ? 0 : value === 2 ? 0 : value === 3 ? 0 : value === 4 ? 0 : value === 5 ? 0 : 0,
      aici: 1,
    },
    {
      label: "Vous ne gérez ni l'administratif ni la facturation",
      img : <InfoArrayHome3/>,
      system: value === 1 ? 1 : value === 2 ? 1 : value === 3 ? 0 : value === 4 ? 0 : value === 5 ? 1 : 1,
      aici: 1,
    },
  ];

  

  return (
    <div className="bg-white">
      <NavbarHomePage />
      {displayMobile()}
      <div className="bg-home-page  bg-no-repeat bg-cover /-mt-3 lg:h-screen px-10 md:block hidden w-full border border-2 mt-24">
        <div className="w-1/2 pt-16">
          <h1 className="text-first font-extrabold xl:text-[40px] text-[30px]">
            Professeur particulier ?{" "}
          </h1>
          <h2 className="text-first font-extrabold  xl:text-[40px] text-[30px]">
            Augmentez votre chiffre d’affaires <br />
            en co-finançant vos cours
          </h2>
          <p className="text-first  xl:text-[20px] text-[17px] font-bold mt-6">
            L’État prend en charge <b>instantanément</b> la moitié
            <br /> du prix de vos cours.
            <br /> Avec une telle offre, vous allez crouler sous la
            <br /> demande.
          </p>
          <div className="flex gap-x-2 text-[#219CFF] xl:text-lg text-base mt-10">
            <EastIcon className="xl:mt-1" />
            <p
              className="underline cursor-pointer"
              onClick={() => navigate("/teachr/avance-immediate")}
            >
              Réaliser une simulation
            </p>
          </div>
          <button
            className="md:w-4/5 w-full mx-auto  border text-white bg-first text-base uppercase rounded-full py-2 font-extrabold shadow-md xl:mt-20 mt-10"
            onClick={() => navigate("/teachr/register")}
          >
            JE M’INSCRIS !
          </button>
        </div>
      </div>
      <div className="w-full z-50 md:px-10 px-4 xl:-mt-6 lg:mt-6 md:mt-4 mt-6 space-y-4 pb-10">
        <h2 className="font-extrabold xl:text-[36px] text-[26px] text-[#4d4d4d]">
          Pourquoi l’Avance Immédiate attire-t-elle autant de professeurs ?
        </h2>
        <p className="text-[#808080] xl:text-[24px] lg:text-[20px] text-[18px] ">
          De nombreux professeurs ont réalisé que leur méthode de paiement pour
          leurs cours particuliers n'était pas optimisée. Pourquoi pas vous ?
        </p>
        <h3 className="text-[#4d4d4d] xl:text-[24px] lg:text-[20px]  text-[18px]  font-bold">
          Comment déclarez vous vos cours ?{" "}
        </h3>
        <div className="md:flex block md:gap-x-8 mt-2 pb-10 md:space-y-0 space-y-4">
          <SimpleSelect
            callback={getSystem}
            defaultValue={1}
            data={[
              "Avec le CESU",
              "Avec le CESU+",
              "Auto-entrepreneur sans crédit d'impôt",
              "Auto-entrepreneur avec crédit d’impôt",
              "Non déclarés",
            ]}
            placeholder="Ex: CESU +"
            customClass="bg-[#F2F5FB] px-4 py-3 rounded-lg md:w-3/4 w-full md:text-lg text-base font-bold font-nunito "
          />
          {/* <button className="uppercase bg-[#219CFF] md:py-0 py-1 px-2 text-lg text-white font-bold md:w-1/4 w-full rounded-full">
            Valider
          </button> */}
        </div>
        <div className="pb-20 text-[#4d4d4d] md:text-lg">
          <div className="md:px-10 px-3">
            <div className="w-full mx-auto border-2 rounded-t-3xl shadow-md">
              <table className="w-full">
                <thead>
                  <tr className="font-bold">
                    <th className="border-b-2 py-4 md:px-4 px-1 text-left w-1/3 ">
                      {/* Quel moyen de paiement pour vos cours ?{" "} */}
                    </th>
                    <th className="border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1 w-1/3">
                      {system === "" ? "Avec le CESU +" : system}
                    </th>
                    <th className="border-b-2 py-4 md:px-4 px-1 w-1/3 bg-[#EBF7FF] rounded-tr-3xl">
                      Avec notre service, l’avance immédiate pour les
                      professeurs indépendants
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataList.map((item, index) => (
                    <tr key={index}>
                      <td className="border-b-2 flex gap-x-2 py-4 md:px-4 px-1 ">
                        {item?.label} {item.img}
                      </td>
                      <td
                        className={
                          item.system === 1
                            ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#0074E4] text-center"
                            : item.system === 0
                            ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#FF724F] text-center"
                            : "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#4d4d4d] font-bold text-center"
                        }
                      >
                        {item.system === 1 ? (
                          <CheckCircleIcon />
                        ) : item.system === 0 ? (
                          <CancelSharpIcon />
                        ) : item.system === 2 ? (
                          "45%"
                        ) : (
                          "21,1% ou 10,55% avec l'ACRE"
                        )}
                      </td>
                      <td
                        className={
                          item.aici === 1
                            ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#0074E4] text-center"
                            : item.aici === 2
                            ? "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1 font-bold text-[#4d4d4d] text-center"
                            : "border-b-2 border-l-2 border-r-2 py-4 md:px-4 px-1  text-[#FF724F] text-center"
                        }
                      >
                        {item.aici === 1 ? (
                          <CheckCircleIcon />
                        ) : item.aici === 2 ? (
                          "21,1% ou 10,55% avec l'ACRE"
                        ) : (
                          <CancelSharpIcon />
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex border-2 md:px-10 rounded-3xl -mt-3 z-50 bg-gradient-to-l from-[#EBF7FF] to-white shadow-lg font-bold">
            <div className="py-8 md:px-4 px-1 w-1/3 bg-white">
              Montant net perçu pour un cours payé 25€/h par votre élève, avec /
              sans crédit d’impôt
            </div>
            <div className="border-l-2 border-r-2 py-8 md:px-4 px-1 w-1/3 bg-white">
              {system === "Avec le CESU"
                ? "50€ payés, remboursé 25€ l’année d’après / Rémunéré "
                : system === "Avec le CESU+"
                ? "25€ payés par votre élève après crédit d'impôt instantané / Rémunéré "
                : system === "Auto-entrepreneur sans crédit d'impôt"
                ? "25€ payés par votre élève, 19,7€ net (21,1%) ou 22,35€ net (10,55%)"
                : system === "Auto-entrepreneur avec crédit d’impôt"
                ? "50€ payés, remboursé 25€ l'année d'après / Rémunéré 39.6€ net (21,1%) ou 44,7€"
                : "25€ payés / Rémunéré 25€"}
              {(system === "Avec le CESU" || system === "Avec le CESU+") && (
                <strong className="text-[#FF724F]">28€ net</strong>
              )}
            </div>
            <div className="py-8 md:px-4 px-1 w-1/3 bg-[#EBF7FF] rounded-tr-3xl">
              Coût élève : 25€ après crédit d'impôt instantané.
              <strong className="text-first">
                {" "}
                Rémunération 34,51€ net (21,1%) ou 39,13€ (10,55%), déduit des
                frais de Teach'r de 12,5%
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center py-10 hidden">
        <h1 className="xl:text-[36px] text-[26px] font-extrabold">
          Nos professeurs témoignent{" "}
        </h1>
        <p className="xl:text-[24px] text-[18px]">
          Haec igitur lex in amicitia sanciatur, ut neque rogemus res turpes nec
          faciamus.
        </p>
        <div className="flex w-[100%] py-4 gap-x-8 px-10 mt-7 text-[#4d4d4d] list-teacher overflow-x-scroll scroll-smooth overflow-y-hidden md:w-full md:flex md:space-y-0 space-y-4">
          <div className="md:w-1/3 w-4/5 bg-[#EBF7FF] rounded-3xl shrink-0">
            <div className="w-full  bg-white translate-y-4 -translate-x-4 rounded-3xl">
              <img src={image} alt="" className="w-full" />
              <div className="border-2 rounded-b-3xl">
                <div className="flex relative gap-x-2 mt-4 py-3 px-6">
                  <img src={image} alt="" className="rounded-full w-10 h-10" />
                  <p>Louis Legrand</p>
                  <p className="right-4 absolute">12/05/2023</p>
                </div>
                <div className="px-6 xl:text-[24px] text-[18px] py-4">
                  <p>“Ajouter le verbatim le plus important du témoignage”</p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-4/5 bg-[#EBF7FF] rounded-3xl shrink-0">
            <div className="w-full  bg-white translate-y-4 -translate-x-4 rounded-3xl">
              <img src={image} alt="" className="w-full" />
              <div className="border-2 rounded-b-3xl">
                <div className="flex relative gap-x-2 mt-4 py-3 px-6">
                  <img src={image} alt="" className="rounded-full w-10 h-10" />
                  <p>Louis Legrand</p>
                  <p className="right-4 absolute">12/05/2023</p>
                </div>
                <div className="px-6 xl:text-[24px] text-[18px] py-4">
                  <p>“Ajouter le verbatim le plus important du témoignage”</p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/3 w-4/5 bg-[#EBF7FF] rounded-3xl shrink-0">
            <div className="w-full  bg-white translate-y-4 -translate-x-4 rounded-3xl">
              <img src={image} alt="" className="w-full" />
              <div className="border-2 rounded-b-3xl">
                <div className="flex relative gap-x-2 mt-4 py-3 px-6">
                  <img src={image} alt="" className="rounded-full w-10 h-10" />
                  <p>Louis Legrand</p>
                  <p className="right-4 absolute">12/05/2023</p>
                </div>
                <div className="px-6 xl:text-[24px] text-[18px] py-4">
                  <p>“Ajouter le verbatim le plus important du témoignage”</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#EBF7FF] text-[#4d4d4d] w-full /text-center space-y-8 py-10 md:px-10 px-4">
        <h1 className="xl:text-[36px] text-[26px] font-extrabold">
          Découvrez vos nouveaux revenus avec Teach’r :
        </h1>
        <div>
          <div>
            <FormControlLabel
              checked={position.isHome === true}
              onChange={() => handleIsHome()}
              sx={{ color: "black" }}
              value={position.isHome === true}
              control={<RadioButton border="1px solid #fff" bg="#219CFF" />}
              label={
                <Typography
                  style={{ fontFamily: "Nunito" }}
                  className="md:text-base lg:text-lg text-sm"
                >
                  En fonction du prix payé par vos élèves après Avance Immédiate
                </Typography>
              }
            />
          </div>
          <div>
            <FormControlLabel
              checked={position.isRemote === true}
              onChange={() => handleIsRemote()}
              sx={{ color: "black" }}
              value={position.isRemote === true}
              control={<RadioButton border="1px solid #fff" bg="#219CFF" />}
              label={
                <Typography
                  style={{ fontFamily: "Nunito" }}
                  className="md:text-base lg:text-lg text-sm"
                >
                  En fonction du prix horaire net que vous voulez percevoir
                </Typography>
              }
            />
          </div>
        </div>
        <div className="mt-4">
          {position.isHome === true ? (
            <Simulator callBack={getResult} />
          ) : (
            <Simulator2 callBack={getResult} />
          )}
        </div>
      </div>
      <div className="text-[#4d4d4d] w-full /text-center space-y-8 py-10 md:px-10 px-4 pb-4 hidden">
        <h1 className="xl:text-[36px] text-[26px] font-extrabold">
          Foire aux questions :{" "}
        </h1>
        <div className="text-lg">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Accordion 1
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              Accordion 2
            </AccordionSummary>
            <AccordionDetails>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <div className="bg-[#EBF7FF] text-[#4d4d4d] w-full text-center space-y-8 py-6">
        <h1 className="xl:text-[36px] text-[26px] font-extrabold">
          Teach’r dans les médias
        </h1>
        <p className="xl:text-[24px] text-[18px]">
          Cliquez sur les logos pour en découvrir davantage
        </p>
        <div className="md:flex md:justify-around  w-4/3 mx-auto">
          <a
            className="mx-auto cursor-pointer"
            href="https://www.youtube.com/watch?v=vXz2Yk3d4Bs"
          >
            <img src={franceInfo} alt="franceInfo" />
          </a>
          <a
            className="mx-auto cursor-pointer"
            href="https://www.bfmtv.com/economie/replay-emissions/tech-and-co/start-up-co-la-plateforme-teach-r-met-en-relation-parents-et-professeurs-pour-des-cours-particuliers-29-03_VN-202103290476.html"
          >
            <img src={bfm} alt="bfm" className="mx-auto cursor-pointer" />
          </a>
          <a
            className="mx-auto cursor-pointer"
            href="https://www.youtube.com/watch?v=yw-8-kVPFpU"
          >
            <img
              src={france2}
              alt="france2"
              className="mx-auto cursor-pointer"
            />
          </a>
          <a
            className="mx-auto cursor-pointer"
            href="https://www.europe1.fr/emissions/La-libre-antenne/eytan-et-eliott-ont-cofonde-teachr-une-plateforme-de-mise-en-relation-entre-parents-et-professeurs-pour-des-cours-particuliers-4024409"
          >
            <img
              src={europe1}
              alt="europe1"
              className="mx-auto cursor-pointer"
            />
          </a>
        </div>
        <p className="xl:text-[24px] text-[18px]">
          Vous ne trouvez pas la réponse à vos questions ?
        </p>
        <div>
          <p className="font-bold md:text-lg text-base">Contactez nous :</p>
          <p className=" md:text-lg text-base text-center space-x-4 ">
            {" "}
            <EmailSharpIcon /> contact@teachr.fr{" "}
          </p>
        </div>
      </div>
      {/* <div className="bg-[#EBF7FF] text-[#4d4d4d] px-4 w-full /text-center space-y-8 py-6 text-center">
        <h1 className="xl:text-[36px] text-[26px] font-extrabold">
          Vous ne trouvez pas la réponse à vos questions ?{" "}
        </h1>
        <p className="font-bold md:text-lg text-base">Contactez nous :</p>
        <p className=" md:text-lg text-base text-center space-x-4 hidden">
          <PhoneInTalkSharpIcon /> 01.23.72.09.09
        </p>
        <p className=" md:text-lg text-base text-center space-x-4 ">
          <EmailSharpIcon /> contact@teachr.fr
        </p>
      </div> */}
    </div>
  );
};

export default TeachrHomePage;