import { Type } from "class-transformer";
import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import CourseProposal from "./CourseProposal";
import { PackProposal } from "./PackProposal";
import { Subject } from "./Subject";

export class CoursePack extends HydraEntity implements DisplayableCourse {
    subjectAsked_id?: number;
    subjectAsked?: Subject;

    /**
     * The child associated to the pack
     */
    forChild_id?: number;

    /**
     * The client associated to the pack
     */
    forClient_id?: number;

    /**
     * The parent associated to the pack
     */
    parent_id?: number;

    /**
     * Extra information concerning the pack.
     */
    extraInformation?: string;

    /**
     * Address where the course takes place.
     * @id of the address
     */
    address_id?: number;

    /**
     * If the course is remote or not.
     */
    isRemote: boolean;

    isOver: boolean;

    isCancelled: boolean;

    price: number;

    durationPerDay: number;

    dates: Array<Date>;

    paymentMean: "CASH" | "CARD" | "CESU";

    proposals?: Array<PackProposal>;

    @Type(() => CourseProposal)
    acceptedProposal_id?: number; // | string;

    // Concerning the Stripe payment
    paymentIntentId?: string;
    // paymentIntentClientSecretId?: string;
    // paymentIntentNeedAuthentification?: string;

    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    creationDatetime?: Date;

    constructor(
        forChild_id: number | undefined,
        forClient_id: number | undefined,
        address_id: number | undefined,
        subjectAsked_id: number,
        extraInformation: string,
        dates: Array<Date>,
        paymentMean: "CASH" | "CARD" | "CESU",
        durationPerDay: number,
        isRemote: boolean,
        price: number
    ) {
        super();
        this.forChild_id = forChild_id;
        this.forClient_id = forClient_id;
        this.address_id = address_id;
        this.subjectAsked_id = subjectAsked_id;
        this.paymentMean = paymentMean;
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        (this.extraInformation = extraInformation),
        (this.dates = dates),
        (this.durationPerDay = durationPerDay),
        (this.isRemote = isRemote),
        (this.price = price),
        (this.isOver = false),
        (this.isCancelled = false);
    }
}
