import React, { useEffect, useState } from 'react';
import Navbar from '../../../../Components/Admin/Navbar';
import Stepper from '../../../../Components/Admin/Stepper';
import StepperStore from '../../../../LocalForage/StepperStore';
import Finish from './Finish';
import First from './First';
import Intro from './Intro';
import Last from './Last';
import Second from './Second';
import Third from './Third';

const Index = () => {
    
    const [currentStep, setCurrentStep] = useState<number>(0)
    const [link, setLink] = useState('')
    useEffect(() => {
        StepperStore.getItem('dashboard_setup').then((response: any) => {
            if (response) {
                setCurrentStep(response.currentStep)
            }
        })

    }, [])
    const nextStep = (step: number) => {
        setCurrentStep(step)
    }
    const getLink = (value:string)=>{
        setLink(value)
    }
    const renderStepScreen = () => {

        switch (currentStep) {
            case 1:
                return (<First nextStep={nextStep} />)
            case 2:
                return (<Second nextStep={nextStep} />)
            case 3:
                return (<Third nextStep={nextStep} />)
            case 4:
                return (<Last getLink = {getLink} nextStep={nextStep} />)
            case 5:
                return (<Finish link = {link} nextStep={nextStep} />)
            default:
                return (<Intro nextStep={nextStep} />)
        }
    }
    const getCurrentStep = (step: number) => {
        if (step >= 2) {
            step = step - 1
            return step
        } else {
            return step
        }
    }

    return (
        <div className="">
            <Navbar index={2} />
            <div className='bg-[#F4FAFF] lg:h-[calc(100vh_-_5rem)] md:h-[calc(100vh_-_3.5rem)]  h-[calc(100vh_-_3.5rem)]   overflow-y-auto lg:py-10 lg:px-14 px-4 py-8'>
                <div className='mx-auto'>
                    <div className="flex flex-col gap-4">
                        {currentStep < 4 && <div>
                            <h1 className='lg:text-[2vw] font-[900] text-first text-2xl'>Avant tout, commencez par ajouter vos élèves ! </h1>
                            <p className='text-[#4D4D4D] font-bold lg:text-[1.3vw] text-lg pt-2'>Veuillez indiquer les informations personnelles de votre élève et de son parent</p>
                        </div>}

                    </div>
                    <div className='items-center justify-between gap-8 lg:flex'>
                        <div className='relative order-last w-full mx-auto lg:w-max'>
                            <Stepper nextStep={nextStep} steps={["Vous aurez besoin de ...", "Informations client", "Coordonnées bancaires", "Récapitulatif et envoi "]} currentStep={getCurrentStep(currentStep)} />
                        </div>
                        {renderStepScreen()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;