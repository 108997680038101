import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import mandataireSign from '../../../../Assets/Images/sign.png'
import { useNavigate } from 'react-router';
import Teachr from '../../../../Objects/models/Teachr';
import NavBar from '../../../../Components/Dashboard/NavBar';
import Stepper from './Stepper';
import First from './First';
import Second from './Second';
import Fourth from './Fourth';
import Third from './Third';
import Last from './Last';
import ImageRepository from '../../../../Repositories/ImageRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Fifth from './Fifth';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const Index = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [file, setFile] = useState<any>();
    const [proofIentity, setProofIdentity] = useState<File>();
    const [pdfFile, setPdfFile] = useState<any>(process.env.PUBLIC_URL + '/Pouvoir_creation_SAP_VDEF_2607.pdf')
    const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null)
    const [numPages, setNumPages] = useState<any>(null);
    const [currentStep, setCurrentStep] = useState(0)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(true)
    const generalTeachr = useSelector((state: any) => state.teachr)
    const generalStepperSap = useSelector((state: any) => state.stepperSap)
    const generalProcuration = useSelector((state: any) => state.procurationSap)
    const [signUrl, setSignUrl] = useState<string>()
    const [signText, setSignText] = useState<string>()
    const [isSign, setIsSign] = useState(false)
    const [isSignText, setIsSignText] = useState(false)
    const [downloadLink, setDownloadLink] = useState<string>()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth - 35);
    const [showDoc, setShowDoc] = useState(false)

    const getProofIdentity = (value: any) => {
        setProofIdentity(value)
    };
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth - 35);

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
        setNumPages(numPages);
        setLoading(false)
    }
    useEffect(() => {
        if (generalStepperSap) {
            if (generalStepperSap >= 5) {
                setPage(1)
            } else {
                setPage(generalStepperSap)
            }
            setCurrentStep(generalStepperSap)
        }
    }, [generalStepperSap])
    useEffect(() => {
        (async () => {
            const response = await fetch(pdfFile);
            const arrayBuffer = await response.arrayBuffer();
            const pdfDocument = await PDFDocument.load(arrayBuffer);
            setPdfDoc(pdfDocument);
            if (generalProcuration && pdfDocument) {
                const fullNameField = pdfDocument.getForm().getTextField('text_1rgez');
                const birthDateField = pdfDocument.getForm().getTextField('text_2azmj');
                const cityBirthField = pdfDocument.getForm().getTextField('text_3bvew');
                const siretField = pdfDocument.getForm().getTextField('text_4kpir');
                const addressField = pdfDocument.getForm().getTextField('text_16unmp');
                const signCityField = pdfDocument.getForm().getTextField('text_5nlom');
                const codeApeField = pdfDocument.getForm().getTextField('text_7cfjc');
                const createdAtField = pdfDocument.getForm().getTextField('text_8fxmo');
                const enterprisePhoneField = pdfDocument.getForm().getTextField('text_9psux');
                const EnterpriseEmailField = pdfDocument.getForm().getTextField('text_10xfe');
                const ChiefFirstNameField = pdfDocument.getForm().getTextField('text_12nvnu');
                const ChiefLastNameField = pdfDocument.getForm().getTextField('text_11jqek');
                const ChiefPhoneField = pdfDocument.getForm().getTextField('text_13jxjc');
                const genderField = pdfDocument.getForm().getRadioGroup('radio_group_1ghva');
                if (generalProcuration.first) {
                    if (generalProcuration.first.firstName && generalProcuration.first.lastName) {
                        fullNameField.setText(generalProcuration.first.firstName + ' ' + generalProcuration.first.lastName);
                    }
                    if (generalProcuration.first.siret) {
                        siretField.setText(generalProcuration.first.siret);
                    }
                    if (generalProcuration.first.birthDate) {
                        birthDateField.setText(generalProcuration.first.birthDate);
                    }
                    if (generalProcuration.first.birthCity) {
                        cityBirthField.setText(generalProcuration.first.birthCity);
                    }
                    if (generalProcuration.first.address) {
                        addressField.setText(generalProcuration.first.address);
                    }
                    if (generalProcuration.first.signCity) {
                        signCityField.setText(generalProcuration.first.signCity);
                    }
                }

                if (generalProcuration.second) {
                    if (generalProcuration.second.apeCode && generalProcuration.second.apeCode !== '') {
                        codeApeField.setText(generalProcuration.second.apeCode);
                    }
                    if (generalProcuration.second.createdAt && generalProcuration.second.createdAt !== '') {
                        createdAtField.setText(generalProcuration.second.createdAt);
                    }
                    if (generalProcuration.second.enterprisePhone && generalProcuration.second.enterprisePhone !== '') {
                        enterprisePhoneField.setText(generalProcuration.second.enterprisePhone);
                    }
                    if (generalProcuration.second.enterpriseEmail && generalProcuration.second.enterpriseEmail !== '') {
                        EnterpriseEmailField.setText(generalProcuration.second.enterpriseEmail);
                    }
                    if (generalProcuration.second.chiefFirstName && generalProcuration.second.chiefFirstName !== '') {
                        ChiefFirstNameField.setText(generalProcuration.second.chiefFirstName);
                    }
                    if (generalProcuration.second.chiefLastName && generalProcuration.second.chiefLastName !== '') {
                        ChiefLastNameField.setText(generalProcuration.second.chiefLastName);
                    }
                    if (generalProcuration.second.chiefPhone && generalProcuration.second.chiefPhone !== '') {
                        ChiefPhoneField.setText(generalProcuration.second.chiefPhone);
                    }
                    if (generalProcuration.second.gender !== undefined) {

                        switch (generalProcuration.second.gender) {
                            case 0:
                                genderField.select('Value_njst')
                                break;
                            case 1:
                                genderField.select('Value_ymsg')
                                break;
                            default:
                                genderField.select('Value_njst')
                                break;
                        }
                    }
                }
            }
        })();
    }, [generalProcuration])
    useEffect(() => {
        if (generalTeachr) {
            if (generalTeachr.teachr) {
                if (generalTeachr.teachr.proofSignSap) {
                    setDownloadLink(generalTeachr.teachr.proofSignSap.contentUrl)
                    setCurrentStep(6)
                    dispatch({
                        type: 'SET_SAP_STEP', payload: 6
                    })
                }
                setTeachr(generalTeachr.teachr)
            } else {
                navigate('/teachr/login')
            }

        } else {
            navigate('/teachr/login')
        }
    }, [generalTeachr])
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pdfBytes = await pdfDoc.save();
                const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
                setFile(pdfBytes);
                const pdfUrl = URL.createObjectURL(pdfBlob);
                setPdfFile(pdfUrl);
            }
        })();
    }, [pdfDoc, isSignText])
    const getInfos = (value: any) => {

    }
    const nextStep = (value: number) => {
        if (value < 4) {
            dispatch({
                type: 'SET_SAP_STEP', payload: value
            })
            setCurrentStep(value)
        } else {
            
            if (value === 4) {
                if (proofIentity && teachr && generalStepperSap) {
                    setLoading(true);
                  ImageRepository.createImage(new File([proofIentity], 'proof-is-sign-sap-1', { type: 'application/pdf' })).then((response) => {
                      teachr.proofIdentity = response['@id']
                      if (teachr.proofSignSap) {
                          teachr.proofSignSap = teachr.proofSignSap['@id']
                      }
                      if (teachr.proofIdentity2) {
                        teachr.proofIdentity2 = teachr.proofIdentity2["@id"];
                      }
                      if (teachr.proofIsAE) {
                          teachr.proofIsAE = teachr.proofIsAE['@id']
                      }
                      if (teachr && teachr.proofMandat) {
                          teachr.proofMandat = teachr.proofMandat['@id']
                      }
                      TeachrRepository.updateTeachr(teachr).then((teachrResponse) => {
                          dispatch({
                              type: 'SET_TEACHR_INFO',
                              payload: {
                                  teachr: teachrResponse
                              }
                          })
                          setCurrentStep(value)
                          dispatch({
                              type: 'SET_SAP_STEP', payload: value
                          })
                          setPage(value)
                          setLoading(false)
                      })
                  }).catch((error) => {
                    console.warn(error)
                      setLoading(false)
                  })
                }
            }
            if (value === 5) {
                setPage(1)
                if (file && teachr && isSign && isSignText) {
                    setLoading(true)
                    ImageRepository.createImage(new File([file], 'proof-is-sign-sap', { type: 'application/pdf' })).then((response) => {

                        if (teachr.proofIdentity) {
                            teachr.proofIdentity = teachr.proofIdentity['@id']
                        }
                        if (teachr.proofIdentity2) {
                            teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                        }
                        if (teachr.proofIsAE) {
                            teachr.proofIsAE = teachr.proofIsAE['@id']
                        }
                        if (teachr && teachr.proofMandat) {
                            teachr.proofMandat = teachr.proofMandat['@id']
                        }
                        teachr.proofSignSap = response['@id']
                        TeachrRepository.updateTeachr(teachr).then((responseTeachr) => {
                            dispatch({
                                type: 'SET_TEACHR_INFO',
                                payload: {
                                    teachr: responseTeachr
                                }
                            })
                            dispatch({
                                type: 'SET_SAP_STEP', payload: 6
                            })
                            setCurrentStep(6)
                            setLoading(false)
                        })
                    }).catch((error) => {
                        console.warn(error);
                        setLoading(false)
                    })
                } else {
                    dispatch({
                        type: 'SET_SAP_STEP', payload: value
                    })
                    setCurrentStep(value)
                }
            }
        }

    }
    const getSignature = (value: any) => {
        setSignUrl(value)
    }
    const getSignatureText = (value: any) => {
        setSignText(value)
    }
    useEffect(() => {
        (async () => {
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                const { width, height } = currentPage.getSize();
                if (!isSign) {
                    if (signUrl) {
                        const dateField = pdfDoc.getForm().getTextField('text_6zzwq');
                        dateField.setText(moment().format('L'))
                        const imageBytes1 = await fetch(signUrl).then((res) => res.arrayBuffer());
                        const image1 = await pdfDoc.embedPng(imageBytes1);
                        const imageBytes2 = await fetch(mandataireSign).then((res) => res.arrayBuffer());
                        const image2 = await pdfDoc.embedPng(imageBytes2);
                        let imageWidth = 100;
                        let imageHeight = 30;
                        currentPage.drawImage(image1, {
                            x: 80,
                            y: imageWidth - imageHeight - 10,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        imageWidth = 230;
                        imageHeight = 40;
                        currentPage.drawImage(image2, {
                            x: width - imageWidth - 10,
                            y: 70,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setIsSign(true)
                    }

                }

            }
        }
        )()
    }, [signUrl])
    useEffect(() => {
        (async () => {
            setLoading(true)
            if (pdfDoc) {
                const pages = pdfDoc.getPages();
                const currentPage = pages[0];
                if (!isSignText) {
                    if (signText) {
                        const imageBytes = await fetch(signText).then((res) => res.arrayBuffer());
                        const image = await pdfDoc.embedPng(imageBytes);
                        const imageWidth = 100;
                        const imageHeight = 20;
                        currentPage.drawImage(image, {
                            x: 80,
                            y: imageWidth - 10,
                            width: imageWidth,
                            height: imageHeight,
                        });
                        setPdfDoc(pdfDoc)
                        setIsSignText(true)
                        setLoading(false)
                    }
                }

            }
        }
        )()
    }, [signText, pdfFile])
    const renderScreen = () => {
        if (teachr && teachr.proofSignSap) {
            return <Last file={downloadLink} callBack={nextStep} />
        } else {
            switch (currentStep) {
                case 0:
                    return <First callBack={nextStep} />
                case 1:
                    return <Second sendData={getInfos} callBack={nextStep} />
                case 2:
                    return <Third sendData={getInfos} callBack={nextStep} />
                case 3:
                    if (pdfFile) {
                        return <Fourth file={pdfFile} index={4} callBack={nextStep} getProofIdentity={getProofIdentity} />
                    }
                    break
                case 4:
                    if (pdfFile) {
                        return <Fourth file={pdfFile} index={5} callBack={nextStep} getProofIdentity={getProofIdentity} />
                    }
                    break
                case 5:
                    return <Fifth loading={loading} getSignatureText={getSignatureText} getSignature={getSignature} callBack={nextStep} />
                case 6:
                    return <Fourth index={4} callBack={nextStep} getProofIdentity={getProofIdentity} />
                default:
                    return <First callBack={nextStep} />
            }
        }

    }

    return (
        <div className='h-screen lg:overflow-hidden overflow-y-auto bg-[#F5FAFF] lg:bg-white'>
            <header className='lg:bg-white bg-[#F5FAFF]'>
                <NavBar active={3} />
            </header>
            <div className=' lg:h-full pb-14 lg:flex bg-[#F5FAFF] lg:bg-white'>
                <div className='lg:h-full px-4 lg:pt-10 lg:pb-40 lg:w-1/4 md:px-8 lg:px-10'>
                    <h1 className='text-first font-extrabold lg:text-[22px] mb-8 text-lg'>ESPACE PROCURATION 📄</h1>
                    <Stepper current={currentStep} />
                </div>
                <div className='w-full overflow-y-scroll bg-[#F5FAFF] px-4 md:px-8 lg:px-10 pt-10 overflow-x-hidden'>
                    <div className='lg:flex justify-between hidden '>
                        <div className="flex items-center cursor-pointer gap-x-4" onClick={() => currentStep > 0 && setCurrentStep(currentStep - 1)}>
                            <span>
                                <svg width="39" height="34" viewBox="0 0 39 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="39" height="34" rx="8" fill="#219CFF" fill-opacity="0.14" />
                                    <path d="M31 17.85C31.4694 17.85 31.85 17.4694 31.85 17C31.85 16.5306 31.4694 16.15 31 16.15L31 17.85ZM10.399 16.399C10.067 16.7309 10.067 17.2691 10.399 17.601L15.8083 23.0104C16.1403 23.3424 16.6785 23.3424 17.0104 23.0104C17.3424 22.6785 17.3424 22.1403 17.0104 21.8083L12.2021 17L17.0104 12.1917C17.3424 11.8597 17.3424 11.3215 17.0104 10.9896C16.6785 10.6576 16.1403 10.6576 15.8083 10.9896L10.399 16.399ZM31 16.15L11 16.15L11 17.85L31 17.85L31 16.15Z" fill="#0054A3" />
                                </svg>
                            </span>
                            <span className='text-first font-bold lg:text-[22px]'>Précédent</span>
                            <span>
                            </span>
                        </div>
                    </div>
                    <div className='lg:py-10'>
                        <div className='flex justify-between lg:gap-10 gap-y-4 flex-col lg:flex-row'>
                            {currentStep !== 0 &&
                                <div className='w-max hidden lg:block'>
                                    <Document
                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}


                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                        />
                                    </Document>
                                </div>
                            }
                            <div className={currentStep !== 0 ? 'lg:w-1/2' : 'w-full'}>
                                {renderScreen()}
                            </div>
                           {currentStep !== 0 ? 
                            <div className='lg:hidden text-center'>
                            <p onClick={() => setShowDoc(!showDoc)} className='underline border-radio text-radio font-bold text-lg  py-2'>{showDoc ? 'Fermer le document' : 'Voir les modifications sur le document'}</p>
                            <div className={`${loading ? 'block' : 'hidden'} flex items-center gap-x-2 justify-center`}>
                                <span className="animate-spin">
                                    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2.67991 14.238C2.13795 14.7791 2.13795 15.6569 2.67991 16.1984C3.22235 16.7398 4.10123 16.7398 4.6432 16.1984C5.18516 15.6569 5.18516 14.779 4.6432 14.238C4.10123 13.696 3.22238 13.696 2.67991 14.238Z" fill="#808080" />
                                        <path d="M3.27912 10.5341C3.27912 9.67331 2.57994 8.97412 1.71769 8.97412C0.855438 8.97412 0.15625 9.67331 0.15625 10.5341C0.15625 11.3959 0.855438 12.0941 1.71769 12.0941C2.57994 12.0941 3.27912 11.396 3.27912 10.5341Z" fill="#808080" />
                                        <path d="M3.65666 4.11816C2.69869 4.11816 1.92188 4.89404 1.92188 5.85151C1.92188 6.80851 2.69869 7.58435 3.65666 7.58435C4.61509 7.58435 5.39144 6.80851 5.39144 5.85151C5.39141 4.89401 4.61509 4.11816 3.65666 4.11816Z" fill="#808080" />
                                        <path d="M8.34566 2C7.292 2 6.4375 2.85397 6.4375 3.90669C6.4375 4.95937 7.29194 5.81334 8.34566 5.81334C9.40028 5.81334 10.2542 4.95937 10.2542 3.90669C10.2547 2.85397 9.40028 2 8.34566 2Z" fill="#808080" />
                                        <path d="M8.34662 15.5728C7.67625 15.5728 7.13281 16.1157 7.13281 16.7866C7.13281 17.456 7.67628 17.9995 8.34662 17.9995C9.0175 17.9995 9.56141 17.456 9.56141 16.7866C9.56141 16.1157 9.0175 15.5728 8.34662 15.5728Z" fill="#808080" />
                                        <path d="M14.9765 9.66748C14.4976 9.66748 14.1094 10.0551 14.1094 10.5341C14.1094 11.0131 14.4976 11.4008 14.9765 11.4008C15.456 11.4008 15.8446 11.0131 15.8446 10.5341C15.8446 10.0551 15.456 9.66748 14.9765 9.66748Z" fill="#808080" />
                                        <path d="M12.2969 14.4824C11.8906 14.8881 11.8906 15.5468 12.2969 15.9531C12.7036 16.3593 13.3622 16.3593 13.7694 15.9531C14.1757 15.5468 14.1757 14.8882 13.7694 14.4824C13.3622 14.0762 12.7036 14.0762 12.2969 14.4824Z" fill="#808080" />
                                        <path d="M13.1001 6.47799C13.4838 6.47799 13.7944 6.16792 13.7944 5.78464C13.7944 5.40183 13.4838 5.09127 13.1001 5.0918C12.7173 5.0918 12.4058 5.40183 12.4063 5.78464C12.4063 6.16792 12.7173 6.47799 13.1001 6.47799Z" fill="#808080" />
                                    </svg>
                                </span>

                                <span className='text-[#808080] font-medium text-sm'>Enregistrement automatique</span>
                            </div>
                        </div> : null}
                            {currentStep !== 0 ?
                                <div className={`${showDoc ? 'block' : 'hidden'} w-full lg:hidden`}>
                                    <Document

                                        error='Impossible de charger le document.'
                                        // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                                        file={pdfFile}


                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page
                                            // renderMode='none'
                                            pageNumber={page}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            width={windowWidth}
                                        />
                                    </Document>
                                </div> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className='relative h-full px-4 lg:hidden pb-14 md:px-8'>
                <div className="absolute top-1/3">
                    <h1 className='text-lg font-extrabold text-center text-first'>Cette Version n'est pas disponible sur des petits écrans</h1>
                </div>
            </div> */}

        </div >
    );
};

export default Index;