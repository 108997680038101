import { Type } from "class-transformer";
import "reflect-metadata";
import HydraEntity from "./HydraEntity";
import { CoursePack } from "./CoursePack";

export class PackProposal extends HydraEntity {

    /**
     * The pack associated to the proposal
     */
    @Type(() => CoursePack)
    coursePack_id: number;

    teachr_id: number;

    isAccepted: boolean;

    isRefused: boolean;

    isDeleted: boolean;

    teachrPrice?: string;

    description?: string;

    number?: number; // used to blur cards is non premium account in AnswerCardSubscriptionCourse

    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    totalDailyPrice: string | undefined;
    forChild_id: any;
    isFromTeachr?: boolean;
    fees?: number
    URSSAFPrice?: number

    constructor(coursePack_id: number, teachr_id: number) {
        super();
        this.coursePack_id = coursePack_id;
        this.teachr_id = teachr_id;
        (this.isAccepted = false);
        (this.isRefused = false);
        (this.isDeleted = false);
    }
}
