// import { useState, useEffect } from "react";
import CreditCard from "../../../../../Objects/models/CreditCard";
import CreditCardRepository from "../../../../../Repositories/CreditCardRepository";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import master from "../../../../../Assets/Images/mastercard.png";
import visa from "../../../../../Assets/Images/visa.png";
import deletes from "../../../../../Assets/Images/delete.svg";
// import Input from "../../../../../Components/Utilitis/Input";
// import AddCard from "./AddCard";
import ClientRepository from "../../../../../Repositories/ClientRepository";
import Client from "../../../../../Objects/models/Client";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { truncate } from "lodash";
const DisplayCards = (card: CreditCard) => {
  const confirmDeleteCard = () => {
    ClientRepository.getCurrentClient().then((client: Client) => {
      if (client?.id) {
        CreditCardRepository.deleteCreditCard(card, client?.id)
          .then((response) => {
            window.location.reload()
          })
          .catch((error) => {
            console.warn("Error ", error);
          });
      }
    });
  };

  

  return (
    <div>
      <div className=" //translate-y-3 md:flex //md:ml-8">
        {/* <div className="md:w-1/2 "></div> */}
        <div 
        onClick={()=>confirmDeleteCard()}
        className=" flex space-x-2 cursor-pointer translate-x-2 //md:w-1/2 //ml-52"
        >
          <div className=" my-auto ">
            <img src={deletes} alt="" />{" "}
          </div>
          <div className="">
            <p className="lg:text-lg md:text-base text-sm font-bold text-orange //text-right">
              Supprimer la carte
            </p>
          </div>
        </div>
      </div>
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          sx={{ width: "100%" }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <div className=" //space-y-4 mt-4 w-full">
            <div className="border-[3px] p-2 border-dark_blue rounded-xl flex justify-around  w-full">
              <div className=" pl-4  w-3/4 //w-2/3">
                <FormControlLabel
                  sx={{ width: "100%" }}
                  value="female"
                  control={<Radio />}
                  label={
                    <span className="md:text-base font-nunito text-sm font-bold">
                      ****{card.last4Digits}
                    </span>
                  }
                />
                <div className="  //-mt-4 ml-8 font-semibold">
                  <p className="text-grey lg:text-base text-sm uppercase //translate-y-2">
                    {card.brand}
                  </p>
                </div>
              </div>
              <div className=" w-1/4 pl-8 pt-2">
                {card.brand === "visa" ? (
                  <img src={visa} alt="" className="mt-4" />
                ) : card.brand === "mastercard" ? (
                  <img src={master} alt="" className="mt-4" />
                ) : (
                  <img src={visa} alt="" className="mt-4" />
                )}
              </div>
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default DisplayCards;
