import axios from 'axios';
import moment from "moment";
import Resizer from "react-image-file-resizer";
import { styled } from "@mui/material/styles";
import Slider from "@mui/material/Slider";
import { CoursePack } from "../Objects/models/CoursePack";
import cities from '../Assets/file/code_ville.json'
import { Console } from 'console';
import { typeVoie } from '../Screens/Teacher/Admin/Dashboard/UtilsDashboard/FunctionsDashbord';

interface level {
  label: string;
  value: number
}

interface City {
  label: string,
  value: string
}


const niveauListe = [
  { label: "Maternelle", value: 12 },
  { label: "CP", value: 11 },
  { label: "CE1", value: 10 },
  { label: "CE2", value: 9 },
  { label: "CM1", value: 8 },
  { label: "CM2", value: 7 },
  { label: "6ème", value: 6 },
  { label: "5ème", value: 5 },
  { label: "4ème", value: 4 },
  { label: "3ème", value: 3 },
  { label: "2nde", value: 2 },
  { label: "1ère", value: 1 },
  { label: "Terminale", value: 0 },
  { label: "Licence 1", value: -1 },
  { label: "Licence 2", value: -2 },
  { label: "Licence 3", value: -3 },
  { label: "Master 1", value: -4 },
  { label: "Master 2", value: -5 },
  { label: "Vie active", value: -6 },
  { label: "Retraité", value: -7 },
];


export default abstract class Functions {


  /**
   * Retrieves the key of an Enum by its value.
   * @param theEnum
   * @param enumValue
   */
  public static getEnumKeyByEnumValue<V>(theEnum: any, enumValue: V) {
    let keys = Object.keys(theEnum).filter(x => theEnum[x] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  public static renderPaymentMean(paymentMean: "CASH" | "CARD" | "CESU", isClient = true): string {
    switch (paymentMean) {
      case "CASH": return "Éspèces";
      case "CARD": return isClient ? "Carte bancaire" : "Virement via Teach'r";
      case "CESU": return "Paiement hors Teach'r (ex.: CESU, ...)";
      default: return "Carte bancaire";
    }
  }
  public static addEspacesIBAN = (iban: string) => {
    // Supprime les espaces existants pour éviter les problèmes de formatage
    let ibanSansEspaces = iban.replace(/\s/g, '');
    // Insère un espace après chaque groupe de 4 caractères
    let ibanFormatte = ibanSansEspaces.replace(/(.{4})/g, '$1 ');
    // Enlève l'espace éventuellement ajouté à la fin
    return ibanFormatte.trim();
  }
  public static removeEspacesIBAN = (iban: string) => {
    return iban.replace(/\s/g, '');
  }
  public static getLevel = (value: number | undefined) => {
    const filter: level[] = niveauListe.filter(level =>
      level.value === value)
    if (value) {
      return filter[0].label
    }
    return ''
  }
  public static formatPhoneNumber = (phone: string) => {
    phone = phone.replace(/ +/g, "")
    const character = phone.split("")
    switch (character[0]) {
      case '+':
        return phone
      case '0':
        return `+33${phone.slice(1, phone.length)}`
      default:
        return `+33${phone}`
    }
  }

  public static priceTotal = (price: number, df: number) => {
    // let fix:any = (price * 0.1)
    let priceT: number = 2 * price * (1 - df);
    return (priceT)
  }
  public static priceTotalTTC = (price: number) => {
    // let fix:any = (price * 0.1)
    let priceT: number = 2 * price;
    return (priceT)
  }

  public static renderDaysForPack = (pack: CoursePack) => {
    let months: Array<String> = [];
    let result = "";
    let datesArray = pack.dates;
    datesArray.map(element => {
      if (!months.includes(moment(element).format("MMMM"))) {
        months.push(moment(element).format("MMMM"));
      }
    });
    months.map(month => {
      result += month.charAt(0).toUpperCase() + month.slice(1) + " :\n";
      datesArray.map(date => {
        if (moment(date).format("MMMM") == month) {
          result += Functions.renderDate(date) + ", ";
        }
      });
      result += "\n";
    });
    result = result.substring(0, result.length - 3);
    return result;
  };



  public static renderDurationPerDay = (duration: number): string => {
    duration = duration / 2;
    let result = "";
    if (duration - Math.trunc(duration) === 0) {
      result = Math.trunc(duration) + "h00";
    } else {
      result = Math.trunc(duration) + "h" + (duration - Math.trunc(duration)) * 60;
    }
    return result + "/jour";
  }

  public static cutStreetWayType = (name: string) : string => {
    let street = ""
    typeVoie.forEach(voie => {
      let streetName = name?.toUpperCase();
      let typeVoie = voie?.name?.toUpperCase();
     
      if (streetName?.includes(typeVoie)) {
        street = name.slice(voie?.name?.length+1).trim()
      }
    })
    return street
  }

  public static renderStreetWayType = (name: string): string => {
    let code = "";
    typeVoie.forEach(voie => {
      let streetName = name?.toUpperCase();
      let typeVoie = voie?.name?.toUpperCase();
      let codes = voie?.code?.toUpperCase()
      const streetNameSplit = streetName?.split(" ");
      const voieName = typeVoie?.split(" ");
      
      if (streetName?.includes(typeVoie)) {
        switch (voieName?.length) {
          case 1:
            if (streetNameSplit[0] === voieName[0]) {
              code = voie?.code;
            }
            break;
          case 2:
            if (streetNameSplit[0] === voieName[0] && streetNameSplit[1] === voieName[1]) {
              code = voie?.code;
            }
            break;
          case 3:
            if (streetNameSplit[0] === voieName[0] && streetNameSplit[1] === voieName[1] && streetNameSplit[2] === voieName[2]) {
              code = voie?.code;
            }
            break;
          default:
            break;
        }
      } else if (streetName?.includes(codes)) {
        if (streetNameSplit[0] === `${codes}.`) {
          code = voie?.code;
        } else if (streetNameSplit[0] === codes) {
          code = voie?.code;
        }
      }
    });
    return code;
  }

  public static formatTwoDigits = (value: number) => {
    var myNumber = value;
    var dec = myNumber - Math.floor(myNumber);
    myNumber = myNumber - dec;
    var formattedNumber = ("0" + myNumber).slice(-2) + dec.toString().substr(1);
    return formattedNumber;
  };

  public static weekDays = [
    ["Lundi", "L"], //[["name","shortName"], ... ]
    ["Mardi", "M"],
    ["Mercredi", "M"],
    ["Jeudi", "J"],
    ["Vendredi", "V"],
    ["Samedi", "S"],
    ["Dimanche", "D"]
  ]
  public static recommandedByDatas = [
    'Directement contacté par nous',
    'Recommandation par un tiers',
    'Réseaux sociaux',
    'Article sur internet',
    'Recherche google',
    'Recherche sur l’App Store ou Google Play',
    'Publicité en ligne',
    'Autre'

  ]
  public static level = [
    { label: "Maternelle", value: 12 },
    { label: "CP", value: 11 },
    { label: "CE1", value: 10 },
    { label: "CE2", value: 9 },
    { label: "CM1", value: 8 },
    { label: "CM2", value: 7 },
    { label: "6ème", value: 6 },
    { label: "5ème", value: 5 },
    { label: "4ème", value: 4 },
    { label: "3ème", value: 3 },
    { label: "2nde", value: 2 },
    { label: "1ère", value: 1 },
    { label: "Terminale", value: 0 },
    { label: "Licence 1", value: -1 },
    { label: "Licence 2", value: -2 },
    { label: "Licence 3", value: -3 },
    { label: "Master 1", value: -4 },
    { label: "Master 2", value: -5 },
    { label: "Vie active", value: -6 },
    { label: "Retraité", value: -7 },
  ]
  public static businessTypeList = [
    { label: "Auto-entrepreneur", value: "AE" },
    { label: "Entreprise individuelle", value: "EI" },
    { label: "Entreprise unipersonnelle à responsabilité limitée", value: "EURL" },
    { label: "Société à responsabilité limitée", value: "SARL" },
    { label: "Société anonyme", value: "SA" },
    { label: "Société par actions simplifiée", value: "SAS" },
    { label: "Société par actions simplifiée unipersonnelle", value: "SASU" },
    { label: "Société en nom collectif", value: "SNC" },
    { label: "Société coopérative de production", value: "SCOP" },
    { label: "Société en commandite simple", value: "SCS" },
    { label: "Société en commandite, par action", value: "SCA" },
    { label: "Association", value: "A" },
  ]
  public static months = [
    ["Janvier", "j"], //[["name","shortName"], ... ]
    ["Février", "f"],
    ["Mars", "m"],
    ["Avril", "a"],
    ["Mai", "m"],
    ["Juin", "j"],
    ["Juillet", "j"],
    ["Aout", "a"],
    ["Septembre", "s"],
    ["Octobre", "o"],
    ["Novembre", "n"],
    ["Décembre", "d"],
  ]
  public static CodePays = [
    {
      "value": 125,
      "label": "ALBANIE"
    },
    {
      "value": 109,
      "label": "ALLEMAGNE"
    },
    {
      "value": 130,
      "label": "ANDORRE"
    },
    {
      "value": 135,
      "label": "PAYS-BAS"
    },
    {
      "value": 110,
      "label": "AUTRICHE"
    },
    {
      "value": 131,
      "label": "BELGIQUE"
    },
    {
      "value": 148,
      "label": "BIELORUSSIE"
    },
    {
      "value": 118,
      "label": "BOSNIE-HERZEGOVINE"
    },
    {
      "value": 103,
      "label": "SVALBARD et ILE JAN MAYEN"
    },
    {
      "value": 111,
      "label": "BULGARIE"
    },
    {
      "value": 119,
      "label": "CROATIE"
    },
    {
      "value": 101,
      "label": "FEROE (ILES)"
    },
    {
      "value": 134,
      "label": "ESPAGNE"
    },
    {
      "value": 106,
      "label": "ESTONIE"
    },
    {
      "value": 156,
      "label": "MACEDOINE DU NORD"
    },
    {
      "value": 105,
      "label": "FINLANDE"
    },
    {
      "value": 133,
      "label": "GIBRALTAR"
    },
    {
      "value": 126,
      "label": "GRECE"
    },
    {
      "value": 132,
      "label": "ROYAUME-UNI"
    },
    {
      "value": 112,
      "label": "HONGRIE"
    },
    {
      "value": 136,
      "label": "IRLANDE; ou EIRE"
    },
    {
      "value": 102,
      "label": "ISLANDE"
    },
    {
      "value": 127,
      "label": "ITALIE"
    },
    {
      "value": 157,
      "label": "KOSOVO"
    },
    {
      "value": 107,
      "label": "LETTONIE"
    },
    {
      "value": 113,
      "label": "LIECHTENSTEIN"
    },
    {
      "value": 108,
      "label": "LITUANIE"
    },
    {
      "value": 137,
      "label": "LUXEMBOURG"
    },
    {
      "value": 144,
      "label": "MALTE"
    },
    {
      "value": 151,
      "label": "MOLDAVIE"
    },
    {
      "value": 138,
      "label": "MONACO"
    },
    {
      "value": 120,
      "label": "MONTENEGRO"
    },
    {
      "value": 122,
      "label": "POLOGNE"
    },
    {
      "value": 139,
      "label": "PORTUGAL"
    },
    {
      "value": 141,
      "label": "REPUBLIQUE DEMOCRATIQUE ALLEMANDE"
    },
    {
      "value": 142,
      "label": "REPUBLIQUE FEDERALE D'ALLEMAGNE"
    },
    {
      "value": 114,
      "label": "ROUMANIE"
    },
    {
      "value": 123,
      "label": "RUSSIE"
    },
    {
      "value": 128,
      "label": "SAINT-MARIN"
    },
    {
      "value": 121,
      "label": "SERBIE"
    },
    {
      "value": 117,
      "label": "SLOVAQUIE"
    },
    {
      "value": 145,
      "label": "SLOVENIE"
    },
    {
      "value": 104,
      "label": "SUEDE"
    },
    {
      "value": 140,
      "label": "SUISSE"
    },
    {
      "value": 115,
      "label": "TCHECOSLOVAQUIE"
    },
    {
      "value": 116,
      "label": "TCHEQUIE"
    },
    {
      "value": 208,
      "label": "TURQUIE D'EUROPE"
    },
    {
      "value": 155,
      "label": "UKRAINE"
    },
    {
      "value": 129,
      "label": "VATICAN; ou SAINT-SIEGE"
    },
    {
      "value": 212,
      "label": "AFGHANISTAN"
    },
    {
      "value": 201,
      "label": "ARABIE SAOUDITE"
    },
    {
      "value": 252,
      "label": "ARMENIE"
    },
    {
      "value": 253,
      "label": "AZERBAIDJAN"
    },
    {
      "value": 249,
      "label": "BAHREIN"
    },
    {
      "value": 246,
      "label": "BANGLADESH"
    },
    {
      "value": 214,
      "label": "BHOUTAN"
    },
    {
      "value": 224,
      "label": "BIRMANIE"
    },
    {
      "value": 225,
      "label": "BRUNEI"
    },
    {
      "value": 234,
      "label": "CAMBODGE"
    },
    {
      "value": 216,
      "label": "CHINE"
    },
    {
      "value": 254,
      "label": "CHYPRE"
    },
    {
      "value": 237,
      "label": "COREE"
    },
    {
      "value": 239,
      "label": "COREE (REPUBLIQUE DE)"
    },
    {
      "value": 238,
      "label": "COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)"
    },
    {
      "value": 247,
      "label": "EMIRATS ARABES UNIS"
    },
    {
      "value": 228,
      "label": "ETATS MALAIS NON FEDERES"
    },
    {
      "value": 255,
      "label": "GEORGIE"
    },
    {
      "value": 223,
      "label": "INDE"
    },
    {
      "value": 230,
      "label": "HONG-KONG"
    },
    {
      "value": 231,
      "label": "INDONESIE"
    },
    {
      "value": 204,
      "label": "IRAN"
    },
    {
      "value": 203,
      "label": "IRAQ"
    },
    {
      "value": 207,
      "label": "ISRAEL"
    },
    {
      "value": 217,
      "label": "JAPON"
    },
    {
      "value": 222,
      "label": "JORDANIE"
    },
    {
      "value": 211,
      "label": "KAMTCHATKA"
    },
    {
      "value": 256,
      "label": "KAZAKHSTAN"
    },
    {
      "value": 257,
      "label": "KIRGHIZISTAN"
    },
    {
      "value": 240,
      "label": "KOWEIT"
    },
    {
      "value": 241,
      "label": "LAOS"
    },
    {
      "value": 205,
      "label": "LIBAN"
    },
    {
      "value": 232,
      "label": "MACAO"
    },
    {
      "value": 227,
      "label": "MALAISIE"
    },
    {
      "value": 229,
      "label": "MALDIVES"
    },
    {
      "value": 218,
      "label": "MANDCHOURIE"
    },
    {
      "value": 242,
      "label": "MONGOLIE"
    },
    {
      "value": 215,
      "label": "NEPAL"
    },
    {
      "value": 250,
      "label": "OMAN"
    },
    {
      "value": 258,
      "label": "OUZBEKISTAN"
    },
    {
      "value": 213,
      "label": "PAKISTAN"
    },
    {
      "value": 261,
      "label": "PALESTINE (Etat de)"
    },
    {
      "value": 220,
      "label": "PHILIPPINES"
    },
    {
      "value": 221,
      "label": "POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT"
    },
    {
      "value": 248,
      "label": "QATAR"
    },
    {
      "value": 209,
      "label": "SIBERIE"
    },
    {
      "value": 226,
      "label": "SINGAPOUR"
    },
    {
      "value": 235,
      "label": "SRI LANKA"
    },
    {
      "value": 206,
      "label": "SYRIE"
    },
    {
      "value": 259,
      "label": "TADJIKISTAN"
    },
    {
      "value": 236,
      "label": "TAIWAN"
    },
    {
      "value": 219,
      "label": "THAILANDE"
    },
    {
      "value": 262,
      "label": "TIMOR ORIENTAL"
    },
    {
      "value": 210,
      "label": "TURKESTAN RUSSE"
    },
    {
      "value": 260,
      "label": "TURKMENISTAN"
    },
    {
      "value": 208,
      "label": "TURQUIE"
    },
    {
      "value": 243,
      "label": "VIET NAM"
    },
    {
      "value": 244,
      "label": "VIET NAM DU NORD"
    },
    {
      "value": 245,
      "label": "VIET NAM DU SUD"
    },
    {
      "value": 251,
      "label": "YEMEN"
    },
    {
      "value": 233,
      "label": "YEMEN DEMOCRATIQUE"
    },
    {
      "value": 202,
      "label": "YEMEN (REPUBLIQUE ARABE DU)"
    },
    {
      "value": 319,
      "label": "ACORES; MADERE"
    },
    {
      "value": 303,
      "label": "AFRIQUE DU SUD"
    },
    {
      "value": 352,
      "label": "ALGERIE"
    },
    {
      "value": 395,
      "label": "ANGOLA"
    },
    {
      "value": 327,
      "label": "BENIN"
    },
    {
      "value": 347,
      "label": "BOTSWANA"
    },
    {
      "value": 331,
      "label": "BURKINA"
    },
    {
      "value": 321,
      "label": "BURUNDI"
    },
    {
      "value": 322,
      "label": "CAMEROUN"
    },
    {
      "value": 305,
      "label": "CAMEROUN ET TOGO"
    },
    {
      "value": 313,
      "label": "PROVINCES ESPAGNOLES D'AFRIQUE"
    },
    {
      "value": 396,
      "label": "CAP-VERT"
    },
    {
      "value": 323,
      "label": "CENTRAFRICAINE (REPUBLIQUE)"
    },
    {
      "value": 397,
      "label": "COMORES"
    },
    {
      "value": 324,
      "label": "CONGO"
    },
    {
      "value": 312,
      "label": "CONGO (REPUBLIQUE DEMOCRATIQUE)"
    },
    {
      "value": 326,
      "label": "COTE D'IVOIRE"
    },
    {
      "value": 399,
      "label": "DJIBOUTI"
    },
    {
      "value": 301,
      "label": "EGYPTE"
    },
    {
      "value": 317,
      "label": "ERYTHREE"
    },
    {
      "value": 391,
      "label": "ESWATINI"
    },
    {
      "value": 315,
      "label": "ETHIOPIE"
    },
    {
      "value": 328,
      "label": "GABON"
    },
    {
      "value": 304,
      "label": "GAMBIE"
    },
    {
      "value": 329,
      "label": "GHANA"
    },
    {
      "value": 330,
      "label": "GUINEE"
    },
    {
      "value": 314,
      "label": "GUINEE EQUATORIALE"
    },
    {
      "value": 392,
      "label": "GUINEE-BISSAU"
    },
    {
      "value": 320,
      "label": "ILES PORTUGAISES DE L'OCEAN INDIEN"
    },
    {
      "value": 332,
      "label": "KENYA"
    },
    {
      "value": 348,
      "label": "LESOTHO"
    },
    {
      "value": 302,
      "label": "LIBERIA"
    },
    {
      "value": 316,
      "label": "LIBYE"
    },
    {
      "value": 333,
      "label": "MADAGASCAR"
    },
    {
      "value": 334,
      "label": "MALAWI"
    },
    {
      "value": 335,
      "label": "MALI"
    },
    {
      "value": 350,
      "label": "MAROC"
    },
    {
      "value": 390,
      "label": "MAURICE"
    },
    {
      "value": 336,
      "label": "MAURITANIE"
    },
    {
      "value": 393,
      "label": "MOZAMBIQUE"
    },
    {
      "value": 311,
      "label": "NAMIBIE"
    },
    {
      "value": 337,
      "label": "NIGER"
    },
    {
      "value": 338,
      "label": "NIGERIA"
    },
    {
      "value": 308,
      "label": "ZANZIBAR"
    },
    {
      "value": 339,
      "label": "OUGANDA"
    },
    {
      "value": 340,
      "label": "RWANDA"
    },
    {
      "value": 389,
      "label": "SAHARA OCCIDENTAL"
    },
    {
      "value": 306,
      "label": "SAINTE HELENE; ASCENSION ET TRISTAN DA CUNHA"
    },
    {
      "value": 394,
      "label": "SAO TOME-ET-PRINCIPE"
    },
    {
      "value": 341,
      "label": "SENEGAL"
    },
    {
      "value": 398,
      "label": "SEYCHELLES"
    },
    {
      "value": 342,
      "label": "SIERRA LEONE"
    },
    {
      "value": 318,
      "label": "SOMALIE"
    },
    {
      "value": 343,
      "label": "SOUDAN"
    },
    {
      "value": 307,
      "label": "SOUDAN ANGLO-EGYPTIEN; KENYA; OUGANDA"
    },
    {
      "value": 349,
      "label": "SOUDAN DU SUD"
    },
    {
      "value": 325,
      "label": "TANGER"
    },
    {
      "value": 309,
      "label": "TANZANIE"
    },
    {
      "value": 344,
      "label": "TCHAD"
    },
    {
      "value": 345,
      "label": "TOGO"
    },
    {
      "value": 351,
      "label": "TUNISIE"
    },
    {
      "value": 346,
      "label": "ZAMBIE"
    },
    {
      "value": 310,
      "label": "ZIMBABWE"
    },
    {
      "value": 404,
      "label": "ETATS-UNIS"
    },
    {
      "value": 425,
      "label": "VIERGES BRITANNIQUES (ILES)"
    },
    {
      "value": 441,
      "label": "ANTIGUA-ET-BARBUDA"
    },
    {
      "value": 431,
      "label": "ANTILLES NEERLANDAISES"
    },
    {
      "value": 415,
      "label": "ARGENTINE"
    },
    {
      "value": 436,
      "label": "BAHAMAS"
    },
    {
      "value": 434,
      "label": "BARBADE"
    },
    {
      "value": 429,
      "label": "BELIZE"
    },
    {
      "value": 418,
      "label": "BOLIVIE"
    },
    {
      "value": 443,
      "label": "BONAIRE; SAINT EUSTACHE ET SABA"
    },
    {
      "value": 416,
      "label": "BRESIL"
    },
    {
      "value": 401,
      "label": "CANADA"
    },
    {
      "value": 417,
      "label": "CHILI"
    },
    {
      "value": 419,
      "label": "COLOMBIE"
    },
    {
      "value": 406,
      "label": "COSTA RICA"
    },
    {
      "value": 407,
      "label": "CUBA"
    },
    {
      "value": 444,
      "label": "CURA\\u00c3\\u2021AO"
    },
    {
      "value": 408,
      "label": "DOMINICAINE (REPUBLIQUE)"
    },
    {
      "value": 438,
      "label": "DOMINIQUE"
    },
    {
      "value": 414,
      "label": "EL SALVADOR"
    },
    {
      "value": 420,
      "label": "EQUATEUR"
    },
    {
      "value": 427,
      "label": "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE SUD"
    },
    {
      "value": 435,
      "label": "GRENADE"
    },
    {
      "value": 430,
      "label": "GROENLAND"
    },
    {
      "value": 409,
      "label": "GUATEMALA"
    },
    {
      "value": 428,
      "label": "GUYANA"
    },
    {
      "value": 410,
      "label": "HAITI"
    },
    {
      "value": 411,
      "label": "HONDURAS"
    },
    {
      "value": 426,
      "label": "JAMAIQUE"
    },
    {
      "value": 403,
      "label": "LABRADOR"
    },
    {
      "value": 405,
      "label": "MEXIQUE"
    },
    {
      "value": 412,
      "label": "NICARAGUA"
    },
    {
      "value": 413,
      "label": "PANAMA"
    },
    {
      "value": 421,
      "label": "PARAGUAY"
    },
    {
      "value": 422,
      "label": "PEROU"
    },
    {
      "value": 432,
      "label": "VIERGES DES ETATS-UNIS (ILES)"
    },
    {
      "value": 442,
      "label": "SAINT-CHRISTOPHE-ET-NIEVES"
    },
    {
      "value": 439,
      "label": "SAINTE-LUCIE"
    },
    {
      "value": 445,
      "label": "SAINT-MARTIN (PARTIE NEERLANDAISE)"
    },
    {
      "value": 440,
      "label": "SAINT-VINCENT-ET-LES GRENADINES"
    },
    {
      "value": 437,
      "label": "SURINAME"
    },
    {
      "value": 402,
      "label": "TERRE-NEUVE"
    },
    {
      "value": 433,
      "label": "TRINITE-ET-TOBAGO"
    },
    {
      "value": 423,
      "label": "URUGUAY"
    },
    {
      "value": 424,
      "label": "VENEZUELA"
    },
    {
      "value": 501,
      "label": "NORFOLK (ILE)"
    },
    {
      "value": 502,
      "label": "TOKELAU"
    },
    {
      "value": 508,
      "label": "FIDJI"
    },
    {
      "value": 505,
      "label": "TERR. DES ETATS-UNIS D'AMERIQUE EN OCEANIE"
    },
    {
      "value": 504,
      "label": "HAWAII (ILES)"
    },
    {
      "value": 513,
      "label": "KIRIBATI"
    },
    {
      "value": 515,
      "label": "MARSHALL (ILES)"
    },
    {
      "value": 516,
      "label": "MICRONESIE (ETATS FEDERES DE)"
    },
    {
      "value": 507,
      "label": "NAURU"
    },
    {
      "value": 517,
      "label": "PALAOS (ILES)"
    },
    {
      "value": 510,
      "label": "PAPOUASIE-NOUVELLE-GUINEE"
    },
    {
      "value": 503,
      "label": "PITCAIRN (ILE)"
    },
    {
      "value": 512,
      "label": "SALOMON (ILES)"
    },
    {
      "value": 506,
      "label": "SAMOA OCCIDENTALES"
    },
    {
      "value": 509,
      "label": "TONGA"
    },
    {
      "value": 511,
      "label": "TUVALU"
    },
    {
      "value": 514,
      "label": "VANUATU"
    },
    {
      "value": 100,
      "label": "FRANCE"
    }
  ]

  public static days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];




  public static formatHour = (value: number) => {
    const hours = Math.trunc(value);
    const min = value - hours;
    return (
      Functions.formatTwoDigits(hours) + " h " + Functions.formatTwoDigits(min * 60) + " / jour"
    );
  };
  public static formatHourConfirmation = (value: number) => {
    const hours = Math.trunc(value);
    const min = value - hours;
    return (
      Functions.formatTwoDigits(hours) + " h " + Functions.formatTwoDigits(min * 60)
    );
  };

  public static renderTotalDuration = (pack: any) => {
    let duration =
      (pack.durationPerDay / 2) * pack.dates.length;
    if (duration - Math.trunc(duration) === 0) {
      return Math.trunc(duration) + "h";
    }
    return (
      Math.trunc(duration) + "h" + (duration - Math.trunc(duration)) * 60
    );
  };

  public static dateFormater = (date: string | number | Date) => {
    let newDate = new Date(date).toLocaleDateString('FR-fr', {
      year: "numeric",
      month: "long",
      day: "numeric",
    })
    return newDate
  }

  public static renderTime(date: any) {
    moment.locale("fr");
    var resultTime = moment(date).format("HH:mm");
    return resultTime;
  }
  public static renderTimeUrgence(date: any) {
    moment.locale("fr");
    var resultTime = moment(date).add(3, "hours").format("HH:mm");
    return resultTime;
  }

  public static renderTeachrHours = (hours: any) => {
    let duration = Math.trunc(parseInt(hours));
    if (duration === 0) {
      return "Nouveau Teach'r";
    } else {
      return duration + "h données sur Teach'r";
    }
  }

  public static pronom = (word: any): any => {
    if (
      "aeiouyh".includes(word[0].toLowerCase())
    ) {
      return "d'";
    } else {
      return "de ";
    }
  };


  public static renderDuration = (duration: any) => {
    duration = duration / 2;
    let result = "";
    if (duration) {
      if (duration - Math.trunc(duration) === 0) {
        result = Math.trunc(duration) + "h";
      } else {
        result = Math.trunc(duration) + "h" + (duration - Math.trunc(duration)) * 60;
      }
    }
    return result;
  }

  public static renderClass = (level: number): string => {
    if (level !== undefined) {
      let result = niveauListe.filter(
        classe => classe.value === level
      )[0].label;
      return result;
    } else {
      return " ";
    }
  }
  public static getCodePays(pays: string): number {
    let result = 0
    this.CodePays.map((codePays) => {
      if (codePays.label.toLowerCase() === pays.toLowerCase()) {
        result = codePays.value
      }
    })
    return result
  }

  public static getCodeTypeVoie(type: string): string {
    let result = ""
    typeVoie.map((codeTypeVoie) => {
      if (codeTypeVoie.name.toLowerCase() === type.toLowerCase()) {
        result = codeTypeVoie.code
      }
    })
    return result
  }

  public static getcity(value: string): City | null {
    const result = cities.filter((city) => {
      const search = city.label.search(value)
      return search >= 0
    })
    if (result.length > 0) {
      return result[0]
    }
    return null
  }
  public static getCities(code: string): City[] {
    return cities.filter((city) => {
      const search = city.value.search(code)
      return search >= 0
    })
  }
  public static renderDate(date: Date | string, displayMonth?: boolean): string {
    moment.locale("fr");
    let resultDate: string;
    if (displayMonth) {
      resultDate = moment(date).format("dddd Do MMMM");
    } else {
      resultDate = moment(date).format("dddd Do");
    }
    return resultDate;
  }
  public static renderPackProposalPrice(teachrPrice: number, duration: number, days: number, fees: number, ursaffPrice: number): {
    hour: number,
    total: number
  } {
    const somme = teachrPrice + fees + ursaffPrice



    return {
      hour: parseFloat(somme.toString()),
      total: (somme * duration * days) / 2
    }
  }
  public static getClientIP = () => {
    return axios.get<{ ip: string }>('https://api.ipify.org?format=json')
  }


  public static PrettoSlider = styled(Slider)({
    color: "#219CFF",
    height: 10,
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .css-14pt78w-MuiSlider-rail": {
      backgroundColor: "#808080",
    },
    "& .MuiSlider-thumb": {
      height: 25,
      width: 25,
      backgroundColor: "#fff",
      border: "5px solid currentColor",
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
  });
  public static resizeFile = (file: Blob, doc: boolean, type: string = "PNG") =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        doc ? 1000 : 200,
        doc ? 1000 : 200,
        type,
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file",
        doc ? 1000 : 200,
        doc ? 1000 : 200,
      );
    });

  public static renderBusinessType = (businessType: any) => {
    switch (businessType) {
      case "AE": return "Auto-entrepreneur";
      case "EI": return "Entreprise Individuelle";
      case "EURL": return "Entreprise Unipersonnelle à Responsabilité Limitée";
      case "SARL": return "Société à Responsabilité Limitée";
      case "SA": return "Société Anonyme";
      case "SAS": return "Société par Actions Simplifiée";
      case "SASU": return "Société par Actions Simplifiée Unipersonnelle";
      case "SNC": return "Société en Nom Collectif";
      case "SCOP": return "Société Coopérative de Production";
      case "SCS": return "Société en Commandite Simple";
      case "SCA": return "Société en Commandite, par Action ";
      case "A": return "Association";
      default: return "Auto-entrepreneur"
    }
  }

}



