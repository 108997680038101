import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import info from "../../../Assets/Icons/info-acre.svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function InfoPl() {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
          Open dialog
        </Button> */}
      <img
        src={info}
        alt=""
        onClick={handleClickOpen}
        className="cursor-pointer"
      />
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
          className="text-[24px] font-extrabold text-first text-center"
        >
          Qu’est-ce que le prélèvement libératoire ?
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Typography className="text-[#4d4d4d] text-[18px] ">
            <strong className="text-[20px] font-bold text-center">
              Payer ses impôts en même temps que ses cotisations sociales
            </strong>
            <br />
            <br />
            En tant qu’auto-entrepreneur, vous avez la possibilité de vous
            acquiter en même temps du paiement de vos cotisations sociales et de
            vos impôts. Dans ce cas, en optant pour le prélèvement libératoire,
            vous paierez un total de 22,8% de cotisations sociales et impôts,
            sans compter une éventuelle éligibilité à l’ACRE. Si vous êtes
            éligible à l’ACRE, ce total est de 12,2%. Pour plus d’informations
            et pour vérifier les conditions d’éligibilité, vous pouvez consulter
            le site officiel du gouvernement en cliquant ici.
          </Typography>
        </DialogContent>
        <DialogActions className="relative p-6">
          {/* <Button
              onClick={handleClose}
              className="border-2 bg-first text-white absolute w-1/2 rounded-full "
            >
              Retour
            </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
