import React, { useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

const ComponentWeekPriceChart = (props) => {
  const { durationByDay, durationByDay2, durationByDay3, priceWeek2, priceWeek, priceWeek3 } = props
  const [chartData, setChartData] = React.useState({ series: [], options: {} });
  React.useEffect(() => {
  const dataRegle = [
    durationByDay["lu"],
    durationByDay["ma"],
    durationByDay["me"],
    durationByDay["je"],
    durationByDay["ve"],
    durationByDay["sa"],
    durationByDay["di"]
  ]
  const dataEnCours = [
    durationByDay2["lu"],
    durationByDay2["ma"],
    durationByDay2["me"],
    durationByDay2["je"],
    durationByDay2["ve"],
    durationByDay2["sa"],
    durationByDay2["di"],
  ]
  const dataError = [
    durationByDay3["lu"],
    durationByDay3["ma"],
    durationByDay3["me"],
    durationByDay3["je"],
    durationByDay3["ve"],
    durationByDay3["sa"],
    durationByDay3["di"],
  ]
  const chartOptions = {
    series: [
      {
        name: 'En cours',
        data: dataEnCours
      },
      {
        name: 'Réglés',
        data: dataRegle
      },
      {
        name: 'Réfusés',
        data: dataError
      },

    ],
    chart: {
      height: 350,
      type: 'area',
      toolbar: {
        show: false, // Définir à true si vous souhaitez afficher la barre d'outils, false pour la masquer
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'string',
      categories: ["L", "M", "M", "J", "V", "S", "D"]
    },
    yaxis: {
      title: {
        // text: "Rémunération horaire NET d'impôts",
        style: {
          color: "#4D4D4D",
          fontFamily: "nunito",
          fontSize: "14px",
          fontWeight: 600
        },
        tickAmount: 9,
        // offsetX: -11,
      },
      min: 0,
      // max: yaxisMax,
      tickAmount: 10,
      labels: {
        show: true,
        formatter: function (value) {
          return value.toFixed(2) + '€'; // Formatage avec des séparateurs de milliers
        },
        style: {
          colors: "#808080",
          fontSize: '12px',
          fontFamily: 'nunito',
          fontWeight: 400,
        },
      },
    },
    grid: {
      position: 'back',
      borderColor: 'rgba(0,0,0,0.22)',
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true,
        }
      },
      yaxis: {
        lines: {
          show: true,
        }
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
    legend: {
      position: 'top',
      formatter: function (seriesName, value) {
        if (seriesName === 'En cours') {
          return seriesName + ' (' + priceWeek2.toFixed(1) + '€)';
        } else if (seriesName === 'Réglés') {
          return seriesName + ' (' + priceWeek .toFixed(1)+ '€)';
        } 
         else if (seriesName === 'Réfusés') {
          return seriesName + ' (' + priceWeek3.toFixed(1) + '€)';
        }
      }
    }
  };
    setChartData({ ...chartData, series: chartOptions.series, options: chartOptions });
  }, [durationByDay, durationByDay2, durationByDay3, priceWeek, priceWeek2, priceWeek3])

  return (
       <div className='h-full w-full ' id="chart">
      <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} className="w-full md:border-none rounded-lg border-first border-2 md:shadow-none md:mt-0 shadow-lg mt-4" />
    </div>
   
  );
};

export default ComponentWeekPriceChart;
