import lieu from "../../../../Assets/Images/place.png";
import picture from "../../../../Assets/Images/picture.png";
import profile from "../../../../Assets/Images/profilehomme.png";
// import star from "../../../../Assets/Images/groupstar.png";
import starOnly from "../../../../Assets/Images/staronly.png";
import toch from "../../../../Assets/Images/tochh.png";
// import loader from "../../../../Assets/Icons/load_wait.gif";
import money from "../../../../Assets/Images/money.png";
import msg from "../../../../Assets/Images/msg.png";
import { useLocation, useNavigate } from "react-router-dom";
// import ResponseLayout from "../ResponseLayout";
import { useEffect, useState } from "react";
import { Rating } from "@mui/material";
import Functions from "../../../../Helpers/Functions";
import ReviewClient from "../../../../Objects/models/ReviewClient";
import ReviewClientRepository from "../../../../Objects/models/ReviewClientRepository";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/btn-close-orange.png";
import SubscriptionCourseProposalRepository from "../../../../Repositories/SubscriptionCourseProposalRepositorys";
// import Client from "../../../../Objects/models/Client";
import ClientRepository from "../../../../Repositories/ClientRepository";
import Tabs from "@mui/material/Tabs";
// import vecteur from "../../../../Assets/Images/vecteur 2.png"
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import NavBarCominsoon from "../../../../Components/Navbar/NavBarCominsoon";
// import Paiement from "../../Paiement/Payment";
// import ConfirmPayment from "../../Paiement/ConfirmationCourse/ConfirmPayment";
import { Elements } from "@stripe/react-stripe-js";
import SubscriptionCoursePayment from "../../Paiement/ConfirmationCourse/AcceptCourse/SubscriptionCoursePayment";
import { loadStripe } from "@stripe/stripe-js";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";
import favorite from "../../../../Assets/Icons/favorite.svg";
import phone from "../../../../Assets/Icons/phone.svg";
import nofavorite from "../../../../Assets/Icons/nofavorite.svg";
const stripePromise = loadStripe("pk_test_aTkKFKmFR3oCTGEppE2nxxA7");

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF724F",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #FF724F",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0054A3",
  "&.Mui-selected": {
    color: "#FF724F",
  },
  "&.Mui-focusVisible": {},
}));

const ProfileTeachrSubscriptionCourseActive = () => {
  const navigate = useNavigate();
  // const [showProfile, setShowProfile] = useState(false);
  // const [expanded, setExpanded] = useState(false);
  const [review, setReview] = useState<ReviewClient[]>();
  // const handleChangeProfile = () => {
  //   setShowProfile(!showProfile);
  // };
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleNext = () => {
    setValue(1);
    setIsLoading(true);
  };
  const handleBack = () => {
    navigate(-1);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpens = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloses = () => {
    setOpens(false);
  };

  const location = useLocation();
  const [state] = useState<any>(location.state);

  // const loadData = async (page: number) => {
  //   const reviewsCollection = await ReviewClientRepository.getReviewsClient(
  //     page,
  //     ["subscriptionCourse.acceptedProposal.teachr.id=" + state.teachr.id]
  //   );
  //   setReview(reviewsCollection.members);
  // };
  // useEffect(() => {
  //   loadData(1);
  // }, []);

  
  

  const handleChanges = () => {
    // navigate("/user/coming-soon");
    handleNext()
  };

  const [teachrInfos, setTeachrInfos] = useState<any>();
  const [isFavorite, setIsFavorite] = useState(false);

  const onFavoritePress = async (param: "add" | "remove") => {
    // this.setState({ loadingFavorite: true });
    const client = await ClientRepository.getCurrentClient();
    setIsFavorite(!isFavorite)
    if (param === "add") {
      await ClientRepository.addFavoriteTeachr(client!, state.teachr!);
  

      // this.props.dispatch(generalSetError(new Error("Teach'r ajouté","Le Teach'r a bien été ajouté à vos favoris","success")));
    } else {
    await ClientRepository.removeFavoriteTeachr(client!, state.teachr!);
     
      // this.props.dispatch(generalSetError(new Error("Teach'r supprimé","Le Teach'r a bien été supprimé de vos favoris","success")));
    }

  }

  useEffect(() => {
    (async () => {
      const client = await ClientRepository.getCurrentClient()
      const teachrInfos = await Teachr_Repository.fetchTeachrInfoByIdClient(state.teachr?.id!, client!.id!);
      setTeachrInfos(teachrInfos)

    })()
  }, [state.teachr?.id])
  return (
    <div className="h-screen  w-screen overflow-x-hidden list-teacher font-nunito">
      <div className="fixed bg-white z-50 w-full">
        <NavBarCominsoon />
        <div className="md:mx-10">
          <Box>
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="lg:text-lg md:text-base text-sm font-bold">
                    Profil de {state.teachr.firstName}
                  </p>
                }
              />
              {/* {isLoading ? (<div className="pt-5">
             <img src={vecteur} alt="" />
           </div>):} */}
              <StyledTab
                label={
                  <div
                    className="translate-y-2"
                    onClick={() => navigate("/coming-soon")}
                  >
                    <div className="flex text-dark_blue lg:text-base md:text-sm text-xs  pt-1 px-3 space-x-2 rounded-full border-2 border-dark_blue">
                      <div className=" //w-1/4">
                        <img src={msg} alt="" />
                      </div>
                      <div className=" //w-3/4">
                        <p className="font-bold //truncate">
                          Discuter avec {state.teachr.firstName} !
                        </p>
                      </div>
                    </div>
                  </div>
                }
              />
            </StyledTabs>
          </Box>
        </div>
      </div>
      <div className="//mx-10 mt-[120px] ">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ p: 3 }}>
            <TabPanel value={value} index={0}>
              <div className="md:mt-[50px] mt-16 mb-8">
                <div className="md:flex lg:gap-x-0 gap-x-4 hidden">
                  <div className=" lg:w-1/2 w-2/3">
                    <div className=" bg-bg-color-tuile rounded-xl shadow-profile p-4 w-full">
                      <div className="  flex ">
                        <div className=" w-1/4 ">
                          <div className="rounded-full mx-auto  shadow-profile xl:w-2/3 xl:h-full translate-y-1 ">
                            <img
                              src={
                                state.teachr?.profilePictureURL
                                  ? state.teachr?.profilePictureURL
                                  : picture
                              }
                              alt=""
                              className="relative w-full h-full mx-auto rounded-full"
                            />
                          </div>
                        </div>
                        <div className="my-auto space-y-1  w-1/3  block">
                          <div className="">
                            <p className="text-dark_blue lg:text-xl md:text-lg text-sm font-bold //translate-y-4">
                              {state.teachr.firstName}
                            </p>
                          </div>
                          <div className="">
                            <Rating
                              name="half-rating-read"
                              defaultValue={state.teachr?.averageNote}
                              precision={0.1}
                              readOnly
                            />
                          </div>
                          <div className="lg:text-base md:text-sm text-xs">
                            <p>
                              {Functions.renderTeachrHours(
                                state.teachrHours?.totalHours
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="  my-auto space-y-2 w-5/12">
                          <div className="flex ">
                            <div className=" pl-2 w-1/4">
                              <img src={toch} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg md:text-base truncate text-sm text-dark_blue font-semibold">
                                {state.teachr?.whereStudies},{" "}
                                {state.teachr.yearInStudies.toString() +
                                  (state.teachr.yearInStudies === 1 ? "ère" : "ème") +
                                  " année"}
                              </p>
                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/4 pl-4 ">
                              <img src={lieu} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.city}
                              </p>
                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/4 pl-4 ">
                              <img src={phone} alt="" />
                            </div>
                            <div className=" w-3/4">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-2 w-1/2 mx-auto space-x-2">
                        <div className="pt-1">
                          <img src={money} alt="" />
                        </div>
                        <div className="">
                          <p className="lg:text-lg md:text-base text-sm  text-dark_blue font-semibold">
                            Prix par heure :{" "}
                            {/* {state.proposal.teachrPrice!.toFixed(2) + "€"} */}
                            {JSON.parse(state.proposal.totalDailyPrice!).toFixed(2) +
                              "€"}
                          </p>
                          <div className="lg:text-base md:text-sm text-xs  text-grey">
                            <span>
                              Soit après crédit d’impôt :{" "}
                              {(state.proposal.totalDailyPrice! / 2).toFixed(2) + "€"}
                              {/* {(JSON.parse(state.proposal.teachrPrice!) / 2).toFixed(
                      2
                    ) + "€"} */}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="lg:px-10 mt-4 space-y-2">
                        <div className="  ">
                          <p className="text-dark_blue font-bold lg:text-xl md:text-lg text-base ">
                            Description :
                          </p>
                          <p className="text-grey lg:text-lg md:text-base text-sm ">
                            {state.teachr.description}
                          </p>
                        </div>
                        <div className=" mt-2 ">
                          {state.proposal.description ? (
                            <p className="text-dark_blue font-bold lg:text-xl md:text-lg text-base ">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="text-grey lg:text-lg md:text-base text-sm ">
                            {state.proposal.description}
                          </p>
                        </div>
                      </div>
                      <div onClick={() => onFavoritePress(isFavorite ? 'remove' : 'add')} className="lg:px-10 mt-4 flex space-x-3 cursor-pointer">
                          <div>
                            <img src={isFavorite ? favorite : nofavorite} alt="" />
                          </div>
                          <div>
                            <p className="lg:text-lg md:text-base text-sm text-[4d4d4d]" >{isFavorite ? "Retirer ce Teach’r aux favoris" : 'Ajouter ce Teach’r aux favoris'}</p>
                          </div>
                        </div>
                      <div className="flex  space-x-8 w-4/5 mt-10 lg:text-lg md:text-base text-sm mx-auto justify-around">
                        <div className="w-1/2 border-2 border-orange pt-1 rounded-full active:text-white active:bg-orange">
                          <div
                            onClick={() => handleBack()}
                            className="lg:text-base md:text-sm text-xs text-center w-full font-extrabold text-orange active:text-white  uppercase   "
                          >
                            Retour
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" lg:w-1/2  w-1/3">
                    <div className=" flex xl:w-2/5 lg:w-1/2 -translate-y-14 mt-4   shadow-avis rounded-2xl mx-auto border-2 pt-1  text-dark_blue font-bold justify-around ">
                      <div className="   w-1/4 text-center xl:text-xl  md:text-lg text-base ">
                        <p>AVIS</p>
                      </div>
                      <div className="   w-1/2 text-center xl:text-xl md:text-lg text-base ">
                        <Rating
                          name="half-rating-read"
                          defaultValue={state.teachr?.averageNote}
                          precision={0.1}
                          readOnly
                        />
                      </div>
                      <div className="   w-1/4 text-center lg:text-xl md:text-lg text-base ">
                        <p>({review?.length}) </p>
                      </div>
                    </div>
                    <div className="  lg:-translate-y-16 lg:mt-8 -mt-8 overflow-x-hidden list-teacher h-screen space-y-8">
                      {review?.map((comment: ReviewClient) => (
                        <div className="">
                          <div className="lg:w-1/2 p-2 rounded-xl shadow-avis mx-auto ">
                            <div className="flex  justify-around ">
                              <div className=" w-1/4">
                                <div className=" mx-auto xl:w-16 xl:h-16 lg:h-12 lg:w-12 h-10 w-10 rounded-full ">
                                  <img
                                    src={
                                      comment.owner?.profilePicture
                                        ? comment.owner?.profilePicture
                                        : profile
                                    }
                                    alt=""
                                    className="w-full h-full  relative rounded-full"
                                  />
                                </div>
                              </div>
                              <div className=" w-1/2 pl-2 lg:space-y-0">
                                <div className="">
                                  <p className="text-dark_blue font-bold lg:text-base md:text-sm text-xs">
                                    {comment.owner?.firstName}
                                  </p>
                                </div>
                                <div className="  w-1/2 //mx-auto">
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={comment.owner?.averageNote}
                                    precision={0.1}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="lg:mx-4 mt-2">
                              <p className="text-dark_blue  lg:text-lg md:text-base text-sm font-bold">
                                {comment.comment ? "Commentaire :" : ""}
                              </p>
                              <p className="text-grey lg:text-base md:text-sm text-xs">
                                {comment.comment}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="block md:hidden">
                  <div className=" lg:w-1/2 w-full">
                    <div className=" bg-bg-color-tuile rounded-xl shadow-profile p-4 w-full">
                      <div className=" justify-around">
                        <div className="flex justify-around ">
                          <div className=" mx-auto w-1/3">
                            <div className="rounded-full  mx-auto shadow-profile translate-y-1 w-20 h-20 ">
                              <img
                                src={
                                  state.teachr?.profilePictureURL
                                    ? state.teachr?.profilePictureURL
                                    : picture
                                }
                                alt=""
                                className="h-20 w-20 relative rounded-full"
                              />
                            </div>
                          </div>

                          <div className="block  w-2/3">
                            <p className="text-dark_blue text-lg font-bold ">
                              {state.teachr.firstName}
                            </p>
                            <div className="">
                              <div className="text-lg">
                                <Rating
                                  name="half-rating-read"
                                  defaultValue={state.teachr?.averageNote}
                                  precision={0.1}
                                  readOnly
                                />
                              </div>
                              <p>
                                {Functions.renderTeachrHours(
                                  state.teachrHours?.totalHours
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="space-y-1 mt-3  ">
                        <div onClick={() => onFavoritePress(isFavorite ? 'remove' : 'add')} className="lg:px-10 mt-4 flex md:space-x-3 space-x-2 cursor-pointer">
                              <div className="w-1/3 pl-8">
                                <img src={isFavorite ? favorite : nofavorite} alt="" />
                              </div>
                              <div>
                                <p className="lg:text-lg md:text-base text-sm text-[4d4d4d]" >{isFavorite ? "Retirer ce Teach’r aux favoris" : 'Ajouter ce Teach’r aux favoris'}</p>
                              </div>
                            </div>
                          <div className="flex ">
                            <div className=" w-1/3  ">
                              <div className=" mx-auto pl-2 w-1/2">
                                <img src={toch} alt="" className="w-8" />
                              </div>
                            </div>
                            <div className=" w-2/3 ">
                              <p className="text-sm text-dark_blue font-semibold">
                                {state.teachr?.whereStudies},{" "}
                                {state.teachr.yearInStudies.toString() +
                                  (state.teachr.yearInStudies === 1 ? "ère" : "ème") +
                                  " année"}
                              </p>
                            </div>
                          </div>
                          <div className="flex ">
                            <div className=" w-1/3  ">
                              <div className=" pl-4 mx-auto w-1/2">
                                <img src={lieu} alt="" className="w-4" />
                              </div>
                            </div>
                            <div className="">
                              <p className="text-sm translate-y-1 text-dark_blue font-semibold">
                                {teachrInfos?.city}
                              </p>
                            </div>
                          </div>
                          <div className="flex  ">
                            <div className="w-1/3 pl-9 ">
                              <img src={phone} alt="" />
                            </div>
                            <div className="">
                              <p className="lg:text-lg truncate md:text-base text-sm text-dark_blue font-semibold">
                                {teachrInfos?.phone}
                              </p>
                            </div>
                          </div>
                          <div className="flex w-full ">
                            <div className="w-1/3 pl-8 ">
                              <img src={money} alt="" className="w-8" />
                            </div>
                            <div className=" w-2/3">
                              <p className="text-sm  text-dark_blue font-semibold">
                                Prix par heure :{" "}
                                {JSON.parse(state.proposal.totalDailyPrice!).toFixed(
                                  2
                                ) + "€"}
                                {/* {state.proposal.teachrPrice!.toFixed(2) + "€"} */}
                              </p>
                              <div className="text-xs text-grey">
                                <span>
                                  Soit après crédit d’impôt :{" "}
                                  {(state.proposal.totalDailyPrice! / 2).toFixed(2) +
                                    "€"}
                                  {/* {(JSON.parse(state.proposal.totalPrice!) / 2).toFixed( */}
                                  2{/* ) + "€"} */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex  mx-auto w-4/5 mt-3 justify-between gap-x-4 ">
                        <div className=" text-dark_blue text-sm  pt-1 w-1/2 shadow-shadow-button rounded-lg  ">
                          <p className="text-center w-full">Discuter</p>
                          <div className="pl-10 pt-1">
                            <img src={msg} alt="" className="w-8" />
                          </div>
                        </div>
                        <div className=" text-dark_blue text-sm  pt-1 w-1/2   shadow-shadow-button rounded-lg ">
                          <div className="font-nunito md:hidden flex">
                            <span
                              className="text-dark_blue w-full"
                              onClick={handleClickOpen}
                            >
                              <p className="text-center">Avis</p>
                              <div className="mx-auto w-8">
                                <img src={starOnly} alt="" className="w-10 px-auto" />
                              </div>
                            </span>
                            <Dialog open={open} onClose={handleClose}>
                              <div className="w-[295px] overflow-x-hidden">
                                <DialogTitle>
                                  <div className="h-8 z-40  flex fixed bg-white w-[258px]">
                                    <div className="w-4/5">
                                      <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                        Avis
                                      </span>
                                    </div>
                                    <div className=" pl-4 pt-1 w-1/5">
                                      <img
                                        src={close}
                                        alt=""
                                        className="w-5"
                                        onClick={handleClose}
                                      />
                                    </div>
                                  </div>
                                </DialogTitle>
                                <DialogContent>
                                  <div className="">
                                    <div className=" flex mt-6 w-full shadow-avis rounded-2xl mx-auto py-2  text-dark_blue font-bold justify-around ">
                                      <div className="translate-y-1 text-base ">
                                        <p>AVIS</p>
                                      </div>
                                      <div className="translate-y-  text-[15px] ">
                                        <Rating
                                          name="half-rating-read"
                                          defaultValue={state.teachr?.averageNote}
                                          precision={0.1}
                                          readOnly
                                        />
                                      </div>
                                      <div className="translate-y-1 text-base ">
                                        <p>({review?.length})</p>
                                      </div>
                                    </div>
                                    <div className=" mt-4 overflow-x-hidden list-teacher space-y-4">
                                      {review?.map((comment: ReviewClient) => (
                                        <div className="">
                                          <div className="w-full p-2 rounded-xl shadow-avis mx-auto ">
                                            <div className="flex justify-between ">
                                              <div className=" w-1/2">
                                                <div className=" w-16 h-16  mx-auto rounded-full ">
                                                  <img
                                                    src={
                                                      comment.owner?.profilePicture
                                                        ? comment.owner?.profilePicture
                                                        : profile
                                                    }
                                                    alt=""
                                                    className="w-full h-full relative rounded-full"
                                                  />
                                                </div>
                                              </div>
                                              <div className="  w-1/2 //mx-auto">
                                                <div>
                                                  <Rating
                                                    name="half-rating-read"
                                                    defaultValue={
                                                      comment.owner?.averageNote
                                                    }
                                                    precision={0.1}
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="">
                                                  <p className="text-dark_blue font-bold text-base">
                                                    {comment.owner?.firstName}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="">
                                              <p className="text-dark_blue  text-base font-bold">
                                                {comment.comment ? "Commentaire :" : ""}{" "}
                                              </p>
                                              <p className="text-grey  text-sm">
                                                {comment.comment}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </DialogContent>
                              </div>
                            </Dialog>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <p className="text-dark_blue font-bold text-base">
                          Description :
                        </p>
                        <p className="text-grey text-sm w-full">
                          {state.teachr.description}
                        </p>
                      </div>
                      {state.proposal.description ? (
                        <div className="mt-2">
                          {state.proposal.description ? (
                            <p className="text-dark_blue font-bold text-base">
                              Message de candidature :
                            </p>
                          ) : (
                            ""
                          )}
                          <p className="text-grey text-sm w-full">
                            {state.proposal.description}
                          </p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="flex space-x-3 mt-4 mx-auto justify-between">
                        <div className="w-1/2 border-2 border-orange pt-2 rounded-full active:text-white active:bg-orange ">
                          <div
                            onClick={handleClickOpens}
                            className="lg:text-base md:text-sm text-xs text-center w-full font-extrabold text-orange  active:text-white  uppercase   "
                          >
                            Non, Merci
                          </div>
                          <Dialog open={opens} onClose={handleCloses}>
                            <div className="border-dark_blue border-1 p-3">
                              <DialogTitle>
                                <div className="flex justify-between mb-3">
                                  <span className="text-dark_blue font-extrabold uppercase font-nunito lg:text-[22px] md:text-lg text-base">
                                    REFUSER CE TEACH'R
                                  </span>
                                  <img
                                    src={close}
                                    alt=""
                                    className="md:w-6 w-6 md:h-6 h-6 //translate-y-2"
                                    onClick={() => handleCloses()}
                                  />
                                </div>
                              </DialogTitle>
                              <DialogContent>
                                <p className="text-[#4d4d4d] font-nunito lg:text-lg md:text-base text-sm">
                                  Une fois ce teach'r refusé, vous ne pourrez plus
                                  revenir en arrière. Voulez-vous vraiment continuer ?
                                </p>
                              </DialogContent>
                              <DialogActions>
                                {/* <div className=" w-full space-x-6 flex mb-4 mx-auto  ">
                          <button
                            onClick={handleCloses}
                            className="border-2 w-1/2 px-3 py-1 lg:text-base md:text-sm text-xs text-orange active:text-white active:bg-orange border-orange uppercase font-extrabold rounded-full"
                          >
                            RETOUR
                          </button>
                          <button
                            onClick={() => {
                              onRefuseConfirm();
                            }}
                            className="border-2 w-1/2 py-1  lg:text-base md:text-sm text-xs text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-extrabold rounded-full"
                          >
                            {loading ? (
                              <CircularProgress
                                sx={{
                                  color: "blue",
                                }}
                                size={20}
                                thickness={4}
                                value={100}
                              />
                            ) : (
                              "continuer"
                            )}
                          </button>
                        </div> */}
                                <div className="w-full border-2 border-orange pt-1 rounded-full active:text-white active:bg-orange">
                                  <div
                                    onClick={() => handleBack()}
                                    className="lg:text-base md:text-sm text-xs text-center w-full font-extrabold text-orange active:text-white  uppercase   "
                                  >
                                    Retour
                                  </div>
                                </div>
                              </DialogActions>
                            </div>
                          </Dialog>
                        </div>
                        <button
                          onClick={() => {
                            handleChanges();
                          }}
                          className="border-2 w-1/2 p-2  lg:text-base md:text-sm text-xs text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-extrabold rounded-full"
                        >
                          Accepter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Box>
          <TabPanel value={value} index={1}>
            {/* <ConfirmPayment {...proposal}/> */}
            <Elements stripe={stripePromise}>
              <SubscriptionCoursePayment state={state} />
            </Elements>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default ProfileTeachrSubscriptionCourseActive;



