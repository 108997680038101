import Teachr from "../../Objects/models/Teachr";

const SET_INFO = 'SET_TEACHR_INFO';
const DELETE_TEACHR_INFO = 'DELETE_TEACHR_INFO';

const initialState = null;

export default  function teachrReducer(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      case DELETE_TEACHR_INFO:
        return null;
      default:
        return state
    }
  }