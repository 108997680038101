const statusTpdEnum = {
  BLOQUE: {
    etat: "KO",
    status: "PARTICULIER_BLOQUE",
    value:
      "Transmission de demande de paiement impossible, compte du particulier bloqué dû à un défaut de paiement. Le compte est bloqué pendant 29 jours à compter du refus de prélèvement.",
    response: "Bloqué",
  },
  EN_COURS: {
    etat: "KO",
    status: "APPAREILLAGE_EC",
    value:
      "Transmission de déclaration de paiement impossible, appareillage en cours de traitement",
    response: "En cours",
  },
  EN_ATTENTE: {
    etat: "KO",
    status: "APPAREILLAGE_NF",
    value:
      "Transmission de déclaration de paiement impossible, appareillage non finalisé (Non prise de connaissance des CGU ou appareillage en attente de validation)\n Note : Dans ce cas, une action du particulier est attendue pour finaliser l’appareillage. Votre client doit valider le lien reçu par mail de la part de l'URSSAF. Il faut vérifier dans les SPAM s'il ce mail ne s'y trouve pas. S'il ne parvient pas à récupérer le lien, il peut en faire une nouvelle demande depuis le site https://particulier.urssaf.fr",
    response: "Non finalisé",
  },
  VALIDE: {
    etat: "OK",
    status: "APPAREILLAGE_VALIDE",
    value: "Votre élève a activé son compte URSSAF. Vous pouvez déclarer vos cours avec lui sur Teach’r.",
    response: "Validé",
  },
  ERROR: {
    etat: "ERROR",
    status: "",
    value: "",
    response: "Erreur",
  },
};

const status = {
  STATUS_NULL: {
    status: null,
    value: null,
    message: "Votre cours est prévu",
    response: "Cours prévu",
    response2: "En attente",
  },
  STATUS_ERROR: {
    status: null,
    value: null,
    message: "Erreur",
    response: "Erreur",
  },
  STATUS_INTEGRATED: {
    status: "INTEGRATED",
    value: "10",
    message:
      "La demande de paiement a été reçue et a passé les différents contrôle de cohérence",
    response: "En cours",
  },
  STATUS_WAITING_FOR_VALIDATION: {
    status: "WAITING_FOR_VALIDATION",
    value: "20",
    message:
      "Le particulier a été prévenu que la demande de paiement est à valider/rejeter par lui",
    response: "En cours",
  },
  STATUS_VALIDATED: {
    status: "VALIDATED",
    value: "30",
    message:
      "La demande de paiement a été validée par le particulier et le circuit de prélèvement est lancé",
    response: "Validé",
  },
  STATUS_REFUSED: {
    status: "REFUSED",
    value: "40",
    message:
      "La demande de paiement est refusée par le particulier : il a renseigné son rejet et un code motif associé",
    response: "Refusé",
  },
  STATUS_COLLECTED: {
    status: "COLLECTED",
    value: "50",
    message:
      "La demande de paiement a été validée par le particulier et le circuit de prélèvement est lancé",
    response: "Collecté",
  },
  STATUS_REFUSAL_OF_DIRECT_DEBIT: {
    status: "REFUSAL_OF_DIRECT_DEBIT",
    value: "60",
    message:
      "Le prélèvement associé à la demande de paiement est revenu en erreur . La demande de paiement est en impayée.",
    response: "Impayé",
  },
  STATUS_PAID: {
    status: "PAID",
    value: "70",
    message:
      "Le virement de paiement de la demande de paiement au prestataire est lancé",
    response: "Prélevée",
  },
  STATUS_CANCELED: {
    status: "CANCELED",
    value: "110",
    message: "La demande de paiement a été annulée par le particulier.",
    response: "Annulé",
  },
  STATUS_CANCELED_111: {
    status: "CANCELED 111",
    value: "111",
    message:
      "La demande de paiement a été annulée après impayé du particulier.",
    response: "Annulé",
  },
  STATUS_CANCELED_112: {
    status: "CANCELED 112",
    value: "112",
    message:
      "La demande de paiement a été annulée après recouvrement par le prestataire.",
    response: "Annulé",
  },
  STATUS_COVERED: {
    status: "COVERED",
    value: "120",
    message:
      "Impayée ou régularisée, contactez le SAV Teach'r pour en savoir plus",
    response: "Recouvrée",
  },
};

export {statusTpdEnum, status}