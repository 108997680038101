import moment from 'moment';
import { Dayjs } from 'dayjs';
const validateDate = (value: Dayjs) => {

  if (!value) {
    return {
      isValid: false,
      message: "Veuillez entrez une date valide",
    };
  } else {
    if (!value.isValid()) {
      return {
        isValid: false,
        message: "Veuillez entrez une date valide",
      };
    }
  }

  if (parseInt(moment().subtract(value.year(), 'year').format('YY')) < 16) {

    return {
      isValid: false,
      message: "Vous devez avoir au moins 16 ans",
    };
  }
  else
    return {
      isValid: true,
      message: "",
    };
};

export default validateDate;
