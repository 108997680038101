import React, { useEffect, useState } from 'react'
import logo_degrade from "../../Assets/Images/logo_bleu.png";
import MenuIcon from "@mui/icons-material/Menu";
import ProfileButton from './ProfileButton';
import { Link, useNavigate } from 'react-router-dom';
import { List, ListItem, } from '@mui/material';
import Messages from '../utils/Messages';
import { useSelector } from 'react-redux';
import Teachr from '../../Objects/models/Teachr';
import PunctualCourseRepository from '../../Repositories/PunctualCourseRepository';
function NavBar(props: any) {
  const [toggle, setToggle] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(props.active);
  const [teachr, setTeachr] = useState<Teachr>(props.active);
  const [dashboardLink, setDashboardLink] = useState('')
  const navigate = useNavigate()
  const handleListItemButtonClick = (
    index: number,
  ) => {
    setSelectedIndex(index);
    navigate('/coming-soon')
  };
  
  const generalTeachr = useSelector((state: any) => state.teachr)
  useEffect(() => {
    if (generalTeachr && generalTeachr.teachr && generalTeachr.teachr.id) {
      setTeachr(generalTeachr.teachr)
      PunctualCourseRepository.getPunctualCourseByTeachr(generalTeachr.teachr.id).then((courses) => {
        if (courses.length > 0) {
          setDashboardLink("/teachr/dashboard/coursetype/")

        } else {
          setDashboardLink("/teachr/dashboard/")
        }
      }).catch(() => { setDashboardLink("/teachr/dashboard/") })
    }
  }, [generalTeachr])
  
  return (
    <div>
      <div className=" lg:bg-transparent lg:pb-0 pb-8">
        <div className="flex items-center justify-between px-8 py-6 font-extrabold">
          <div className="">
            <img
              className="cursor-pointer lg:w-[129px] w-[84px]"
              onClick={() => window.location.replace("/")}
              src={logo_degrade}
              alt=""
            />
          </div>
          <div className="lg:hidden">
            <MenuIcon
              onClick={() => {
                setToggle(!toggle);
              }}
            ></MenuIcon>
          </div>
          <div className="hidden lg:block">
            <List
              sx={{
                display: "flex",
                gap: "0 20px",
                "& .Mui-selected": {
                  boxShadow: "4px 4px 6px 1px rgba(0, 0, 0, 0.25)",
                  color: "#FF724F !important",
                  backgroundColor: "#fff !important",
                },
                "& .MuiListItem-root": {
                  padding: "0",
                  borderRadius: " 9999px",
                  fontFamily: "Nunito",
                },
              }}
            >
              <ListItem
                selected={selectedIndex === 0}
                onClick={(event) => {
                  handleListItemButtonClick(0);
                  navigate("/coming-soon");
                }}
                className="px-4 py-2 uppercase cursor-pointer whitespace-nowrap hover:text-third text-button"
              >
                Historique
              </ListItem>
              <ListItem
                selected={selectedIndex === 1}
                onClick={(event) => {
                  handleListItemButtonClick(1);
                  navigate("/teachr/candidatures");
                }}
                className="px-4 py-2 uppercase cursor-pointer whitespace-nowrap hover:text-third text-button"
              >
                MES CANDIDATURES
              </ListItem>
              <ListItem
                selected={selectedIndex === 2}
                onClick={(event) => {
                  handleListItemButtonClick(2);
                  navigate("/teachr/cours");
                }}
                className="px-4 py-2 uppercase cursor-pointer whitespace-nowrap hover:text-third text-button"
              >
                {" "}
                COURS DISPONIBLES
              </ListItem>
              {teachr?.isAE && teachr?.signSap && (
                <ListItem
                  selected={selectedIndex === 3}
                  onClick={(event) => {
                    handleListItemButtonClick(3);
                    navigate(dashboardLink);
                  }}
                  className="px-4 py-2 uppercase cursor-pointer whitespace-nowrap hover:text-third text-button"
                >
                  {" "}
                  déclarer mes cours
                </ListItem>
              )}
              <ListItem className="uppercase w-max whitespace-nowrap hover:text-third text-button">
                <ProfileButton />
              </ListItem>
            </List>
          </div>
        </div>
        {toggle ? (
          <div className="flex flex-col items-center gap-2 lg:hidden lg:flex-row">
            <Link
              to="/coming-soon"
              className="font-extrabold uppercase text-button"
            >
              Historique
            </Link>
            <Link
              to="/teachr/candidatures"
              className="font-extrabold uppercase text-button"
            >
              mes candidatures
            </Link>
            <Link to="/teachr/cours" className="px-4 uppercase text-third">
              COURS DISPONIBLES
            </Link>
            {teachr?.isAE && teachr?.signSap && (
              <Link
                to={dashboardLink}
                className="font-extrabold uppercase text-button z-50"
              >
                déclarer mes cours
              </Link>
            )}
            <div className="w-[50px]">
              <ProfileButton />
            </div>
          </div>
        ) : null}
      </div>
      <Messages />
    </div>
  );
}

export default NavBar