import { Button } from '@mui/material';
import React from 'react';
import illu from '../../../../Assets/videos/parrainage.gif'
const Sponsor = () => {
    return (
        <div className='h-full px-4 mx-auto lg:w-[95%] md:px-8'>
            <div>
                <h2 className='font-extrabold text-first lg:text-[34px] uppercase text-lg lg:leading-10'>Voyez encore plus loin, parrainer vos proches et cumuler les avantages.</h2>
                <p className='font-extrabold lg:text-[26px] text-first md:py-10 text-base lg:py-8 lg:leading-[40.92px]'>Après votre inscription, parrainez un ou plusieurs de vos proches, ceux-ci deviennent alors vos filleuls. À chaques cours éffectués par vos filleuls vous obtenez un pourcentage de la comission Teach’r.</p>
            </div>
            <div className="flex flex-col items-center justify-between lg:gap-x-14 gap-x-8 md:flex-row">
                <div className='w-full h-[calc(100vh_-_21.5rem)]  bg-center bg-no-repeat bg-contain' style={{ backgroundImage: `url(${illu})` }}>
                </div>
                <div className='lg:pt-8 text-first md:pt-0'>
                    <h3 className='font-extrabold lg:text-2xl text-first'>Les commissions de parrainage sur 5 niveaux :</h3>
                    <div className='font-bold lg:text-[22px] pt-8 pb-14 flex flex-col md:gap-y-10 gap-y-4'>
                        <p>Avec Teach’r vous avez la possibilité de développer votre propre organisation commerciale grâce au parrainage. </p>
                        <p>En tant que parrain ou marraine, vous accompagnez vos filleuls à decouvrir leurs Dasboard et à déclarer leurs cours particuliers avec Teach’r.</p>
                        <p>Ainsi, lorsqu’un cours de vos filleuls est déclarer, vous percevrez un pourcentage des de la commission Teach’r de cette dernière.</p>
                    </div>
                    <Button className='w-full py-2 font-extrabold text-white rounded-full bg-first lg:text-2xl'>En savoir plus</Button>
                </div>
            </div>
        </div>
    );
};

export default Sponsor;