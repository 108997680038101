import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import Teachr from "../Objects/models/Teachr";
import { plainToClass } from "class-transformer";
import TeachrHours from "../Objects/models/TeachrHours";
import Client from "../Objects/models/Client";
import CustomToken from "../Objects/interfaces/CustomToken";


export default abstract class TeachrRepository {


    public static getTeachrToken(uid : string) {
        return ApiService.getSingleInstance<CustomToken>(
            `/teachrs/v2/teachrs/create_token/${uid}`,
            String
        );
    }
    public static getCurrentTeachr() {
        return ApiService.getSingleInstance<Teachr>(
            Route.TEACHR_BASE + Route.TEACHR_ME,
            Teachr
        );
    }

    /**
     * Fetch a Teachr stored into the API by his id, client side
     * @param id
     */
    public static fetchTeachrById(id: number) {
        return ApiService.getSingleInstance<Teachr>(
            Route.TEACHR_BASE + "/" + id,
            Teachr
        );
    }
    /**
     * Fetch a Teachr stored into the API by his id, client side
     * @param code
     */
    public static fetchTeachrByCode(code: string) {
        return ApiService.getSingleInstance<Teachr>(
            "/teachr/code/" + code,
            Teachr
        );
    }


    /**
     * Creates an instance of Teachr into the API.
     * @param teachr
     */
    public static createTeachr(teachr: Teachr) {
        return ApiService.postInstance<Teachr>(
            Route.TEACHR_BASE,
            Teachr,
            teachr
        );
    }
    public static getToken() {
        return ApiService.getValidJWTToken()
    }

    public static get_client_by_teacher_favorite(
        teachrId: number,
    ) {
        return ApiService.get(
            `/teachrs/clients/${teachrId}`
        );
    }

    /**
     * Updates the instance of the Teachr passed as parameter.
     * @param teachr
     */
    public static updateTeachr(teachr: Teachr) {
        if (typeof teachr.profilePicture !== "string") {
            teachr.profilePicture = undefined;
        }
        if (typeof teachr.proofIsAE !== "string") {
            teachr.proofIsAE = undefined;
        }
        return ApiService.putInstance<Teachr>(
            Route.TEACHR_BASE + "/" + teachr.id,
            Teachr,
            teachr
        );
    }

    /**
     * Returns the number of hours and courses given by teachr
     * @param teachr
     */
    public static getTeachrHours(teachrId: number) {
        return ApiService.get(`/clients/v2/teachrs/${teachrId}/hours`)
            .then(body => plainToClass(TeachrHours, body));
        ;
    }

    // public static searchClient(teachr: number, term: any) {
    //     return ApiService.get(`/teachrs/${teachr}/search/${term}`);
    // }
    // /teachrs/{teachr}/favorite/{mail}/add/{phone}
    /**
     * Adds a favorite teachr by even teachr
     * @param client
     * @param teachr
     */
    public static addFavoriteTeachr(teachr: number, mail:string, phone:string) {
        return ApiService.postInstance(
            Route.TEACHR_BASE + "/" + teachr + "/favorite/" +  mail + "/add/" + phone,
            Client,
            null
        );
    }

    
}
