export class Subject {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    readonly id: number;
    name: string;
    fr_FR: string;
    isSuperior: boolean;

    constructor(id: number, name: string, fr_FR: string, isSuperior: boolean) {
        this.id = id;
        this.name = name;
        this.fr_FR = fr_FR;
        this.isSuperior = isSuperior;
    }
}
