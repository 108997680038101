import { Button } from '@mui/material';
import React from 'react';

const Second = (props: any) => {
    return (
        <div className='flex flex-col lg:border border-[rgba(146,211,255,0.54)] lg:shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-4'>
            <h4 className='text-lg font-extrabold lg:text-[22px] text-first'>Déclaration NOVA : </h4>
            <div>
                <h5 className='text-first lg:text-xl font-extrabold text-base'>1. Poursuivre avec un mauvais code APE</h5>
                <p className='lg:text-lg text-base font-semibold text-normal'>Vous pouvez choisir de continuer votre inscription avec le mauvais code APE, cependant vous risquez d’attendre 2 semaines de plus en cas de refus</p>
                <p onClick={() => props.nextStep(2)} className='text-radio lg:text-lg text-base underline font-bold cursor-pointer'>Cliquez ici pour poursuivre avec le mauvais code </p>
            </div>
            <div>
                <h5 className='text-first lg:text-xl font-extrabold text-base'>2. Changer de code APE</h5>
                <p className='text-normal lg:text-lg text-sm font-semibold'>Vous pouvez aussi changer facilement votre code APE depuis le site officiel de l'URSSAF en choisissant "Activité de soutien soutien scolaire à domicile" <a className='text-radio font-bold lg:text-lg text-sm underline cursor-pointer' href="https://www.autoentrepreneur.urssaf.fr/portail/accueil/gerer-mon-auto-entreprise.html" target='_blank'>En cliquant ici.</a></p>
            </div>
            <div className="flex justify-between flex-col lg:flex-row gap-y-4">
                <Button onClick={() => props.nextStep(0)} className='text-[#B7B7B7] lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
                <Button onClick={() => props.nextStep(2)} className='text-white bg-radio font-extrabold rounded-full py-2 px-8 w-full lg:w-max'>J'AI LE BON CODE</Button>

            </div>
        </div>
    );
};

export default Second;