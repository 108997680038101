import moment from 'moment';
import Functions from '../../../../../../Helpers/Functions';
import { capitalizeFirstLetter } from "../../UtilsDashboard/FunctionsDashbord";

const CompPonctual = (props: any) => {

    const { punctual } = props
    const formattedTime = moment(punctual.startDatetime).format('HH:mm')
    // const modifiedTime = moment(formattedTime, 'HH:mm');
    return (
      <>
        <tr className="group/item hover:bg-slate-100 hover:rounded-xl hover:py-2">
          <td className="/px-6 md:py-2 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5 md:flex hidden ">
            Du {moment(punctual.startDatetime).format("DD MMM YYYY")} au{" "}
            {moment(punctual.endDatetime).format("DD MMM YYYY")}
          </td>
          <td className="/px-6 py-2 whitespace-no-wrap  border-gray-500 md:hidden">
            <div className="text-sm leading-5 text-first">
              {moment(punctual.startDatetime).format("DD/MM/YYYY")} au{" "}
              {moment(punctual.endDatetime).format("DD/MM/YYYY")}
            </div>
          </td>
          <td className="/px-6 md:py-2 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5">
            {capitalizeFirstLetter(punctual?.forChildFirstName)}
          </td>
          <td className="/px-6 md:py-2 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
            {punctual?.subjectAsked}
          </td>
          <td className="/px-6 md:py-2 whitespace-no-wrap  border-gray-500 text-first text-sm leading-5">
            {Functions.renderDuration(punctual.duration)}
          </td>
          <td className="/px-6 md:py-2 whitespace-no-wrap  text-first border-gray-500 text-sm leading-5">
            <span className="relative inline-block px-3 py-1 font-semibold text-[#4d4d4d] leading-tight">
              <span className="absolute inset-0 bg-[#F4F4F8] opacity-50 rounded-full" />
              <span className="relative text-xs">Cours prévu</span>
            </span>
          </td>
        </tr>
      </>
    );
};

export default CompPonctual;