import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputBase from "@mui/material/InputBase";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const TeachrInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: 'transparent',
    border: "none",
    padding: "0",
    fontFamily: "Nunito, 'sans-serif'"
  },
}));

function SimplOptions(props:any) {
  const handleChange = (event:any) => {
    props.callback(event.target.value)
  };
  return (
    <div className={
      "flex justify-between rounded-md py-2 px-3  border border-solid items-center" +
      props.customClass 
    }>
      {/* <label htmlFor="" className="text-black" >{props.label}</label> */}
      <Select
        IconComponent = {KeyboardArrowDownIcon}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        placeholder="dqdqdqsd"
        onChange={handleChange}
        displayEmpty
        renderValue={(selected : any) => {
          if (!selected) {
            return <em>{props.label}</em>;
          }

          return selected
        }}
        input={<TeachrInput placeholder="qsdqsd" />}
      >
        {props.data.map((el:string, key:number) => (
          <MenuItem sx={{
            '&.MuiMenuItem-root':{
              fontFamily: "nunito"
            }
          }} key={key} value={el}>{el}</MenuItem>
        ))}
      </Select>
    </div>
  );
}
export default SimplOptions;
