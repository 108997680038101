interface SimulatorResult {
    status: number
    weekHours: number
    hourlyPrice: number
    age: number
    withTeachrPrice: number
    withoutTeachrPrice: number
    differencePrice: number
}

const SET_INFO = 'SET_INFO';

const initialState : SimulatorResult | null = null

export default  function simulatorReducer(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      default:
        return state
    }
  }