import * as React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import frLocale from 'date-fns/locale/fr';

import CalendarIcon from '../../Assets/Images/Vector (1).png'
import moment from 'moment';
import { frFR } from '@mui/x-date-pickers';

const localeMap = {
  fr: frLocale,

};

export default function DatePickers(props:any) {
  const [locale] = React.useState<keyof typeof localeMap>('fr');
  const today = new Date(); 
 const tomorrow: any = today.setDate(today.getDate())

 

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[locale]}
      localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}
    >
      <div className='relative flex mt-1 md:h-10 md:hidden font-nunito'>
       <MobileDatePicker
          className='text-lg border-2 font-nunito lg:text-lg md:text-base'
          showToolbar={false}
          inputFormat="dd/MM/yyyy"
          minDate={tomorrow}
          maxDate={moment().add(2, "years").toDate()}
          closeOnSelect
          disablePast={true}
          toolbarTitle="Sélectionner la date"
          //toolbarPlaceholder='JJ/MM/AAAA'
          value={props.value}
          onChange={(val)=>props.callBack(val)}
          renderInput={(params) => <TextField {...params} 
          placeholder="Ex : 24/04/2022"
          sx={{
            width: "100%",
            paddingTop:'-3rem',
            
            "& .MuiOutlinedInput-root": {fontSize:"15px", fontFamily:'nunito'},
            "& fieldset": {
              border: "none",
              textDecoration: "black",
            },
          }}/>}
        />
          <div className="mt-2 -ml-">
            <img src={CalendarIcon} alt="calendar icon"  className="w-6 h-6 "/>
          </div>
      </div>
      <div className='relative hidden -mt-1 md:flex md:h-10 font-nunito'>
       <MobileDatePicker
          className='text-lg border-2 font-nunito lg:text-lg md:text-base'
          showToolbar={false}
          inputFormat="dd/MM/yyyy"
          minDate={tomorrow}
          maxDate={moment().add(2, "years").toDate()}
          closeOnSelect
          disablePast={true}
          toolbarTitle="Sélectionner la date"
          //toolbarPlaceholder='JJ/MM/AAAA'
          value={props.value}
          onChange={(val)=>props.callBack(val)}
          renderInput={(params) => <TextField {...params} 
          placeholder="Ex : 24/04/2022"
          sx={{
            width: "100%",
            paddingTop:'-3rem',
            
            "& .MuiOutlinedInput-root": {fontSize:"17px", fontFamily:'nunito'},
            "& fieldset": {
              border: "none",
              textDecoration: "black",
            },
          }}/>}
        />
          <div className="mt-2 -ml-14">
            <img src={CalendarIcon} alt="calendar icon"  className="w-7 h-7 "/>
          </div>
      </div>
    </LocalizationProvider>
  );
}
