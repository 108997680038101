import errorIcon from "../Assets/Images/error.png";

const validateClasse = (value) => {
  if (value.length < 1) {
    return {
      isValid: false,
      message: "Sélectionnez le niveau",
      icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />
    };
  } else
    return {
      isValid: true,
      message: "", 
      icon:""
    };
};

export default validateClasse;