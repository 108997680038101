import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import listIcon from '../../../../../Assets/Icons/list.svg'
import PackCandidacyComponent from '../../../../../Components/Dashboard/Course/candidacy/Pack';
import { CoursePack } from '../../../../../Objects/models/CoursePack';
import { PackProposal } from '../../../../../Objects/models/PackProposal';
import PackProposalRepository from '../../../../../Repositories/PackProposalRepository';

const PackCourseAccepted = (state: {course: CoursePack}) => {
    const navigate = useNavigate()
    const [proposal, setProposal] = useState<PackProposal>()
    useEffect(()=>{
       PackProposalRepository.getPackProposals(state.course?.acceptedProposal_id!).then((response) => {
        setProposal(response)
       })
    },[])
    const getWeeklyHours = () => {
        if (state.course) {
            const duration = state.course.durationPerDay / 2
            const days = state.course.dates.length
            return  duration * days
        }
        return 0
    }

   
    return (
        <div className='w-max'>
            {proposal && <PackCandidacyComponent {... proposal} />}
            <div className='shadow-candidancy-sub px-3 py-4 flex flex-col gap-2 rounded-[10px] mx-auto md:mx-0 my-4 whitespace-nowrap'>
                <p className='text-xl font-bold text-first'>Rémunération horaire :  <span className='text-2xl'>{proposal?.teachrPrice} €</span></p>
                <p className='text-xl font-bold text-first'>Rémunération totale :  <span className='text-2xl'>{proposal?.teachrPrice && (parseFloat(proposal?.teachrPrice) * getWeeklyHours()).toFixed(2)} €</span></p>
            </div>
            <div className='shadow-high-record p-4  gap-2 rounded-[10px]  w-full text-xl text-radio '>
                <button onClick={()=> navigate('/teachr/candidatures/eleve', {
                state: {
                   course: proposal,
                   teachrId:proposal?.teachr_id
                }
            })}  className='flex gap-3 mx-auto underline w-max ' > <img src={listIcon} alt="" /> Voir la fiche élève </button>
            </div>
        </div>
    );
};

export default PackCourseAccepted;