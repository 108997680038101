import PunctualCandidacyComponent from '../../../../../Components/Dashboard/Course/candidacy/Punctual';
import listIcon from '../../../../../Assets/Icons/list.svg'
import { useNavigate } from 'react-router-dom';

const PunctualCourse = (proposal: any) => {
    const navigate = useNavigate()
    return (
        <div className='w-max'>
            <PunctualCandidacyComponent {... proposal} />
            <div className='shadow-candidancy-sub px-3 py-4 flex flex-col gap-2 rounded-[10px] mx-auto md:mx-0 my-4 whitespace-nowrap'>
                <p className='text-xl font-bold text-first'>Rémunération horaire :  <span className='text-2xl'>{proposal.teachrPrice} €</span></p>
                <p className='text-xl font-bold text-first'>Rémunération totale :  <span className='text-2xl'>{(proposal.teachrPrice * parseInt(proposal.punctualCourse.duration) /2).toFixed(2) } €</span></p>
            </div>
            <div className='shadow-high-record p-4  gap-2 rounded-[10px]  w-full text-xl text-radio '>
                <button onClick={()=> navigate('/teachr/candidatures/eleve', {
                state: {
                   course: proposal.punctualCourse,
                   teachrId: proposal.teachr.id
                }
            })}  className='flex gap-3 mx-auto underline w-max ' > <img src={listIcon} alt="" /> Voir la fiche élève </button>
            </div>
            
            
        </div>
    );
};

export default PunctualCourse;