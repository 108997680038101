import React, { useEffect, useState } from 'react';
import NavbarHomePage from '../Navbar/NavbarHomePage';
import bgaici from '../../Assets/Images/bg-aici.svg'
import iconi from '../../Assets/Icons/icon-int.svg'
import bgaici2 from '../../Assets/Images/bg-aici-2.svg'
import bgaici3 from '../../Assets/Images/bg-aici-3.svg'
import detente from '../../Assets/Images/detente2.svg'
import dem1 from "../../Assets/Icons/dem1.svg";
import arrow1 from "../../Assets/Icons/arrow1.svg";
import dem2 from "../../Assets/Icons/dem2.svg";
import dem3 from "../../Assets/Icons/dem3.svg";
import arrow2 from "../../Assets/Icons/arrow2.svg";
import { useNavigate } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import Input from '../Admin/Input';
import { validatePhoneNumber } from '../../Validation/Phone';
import { signOut } from 'firebase/auth';
import { auth } from '../../Services/FirebaseService';
import ApiService from '../../Services/ApiService';
import InterestedTeachr from '../../Objects/models/InterestedTeachr';
import Functions from '../../Helpers/Functions';
import InterestedTeachrRepository from '../../Repositories/InterestedTeachrRepository';
import InfoArrayHomeAici from '../utils/InfoArrayHomeAici';

 const AiciPage = () => {
  const navigate = useNavigate()
  const [openRdvDial, setOpenRdvDial] = useState(false);
  const [errorPhone, setErrorPhone] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [preferenceForACall, setPreferenceForACall] = useState("");
  const [ipAddress, setIpAddress] = useState<string>();
  const [interestedTeachr, setInterestedTeachr] = useState<InterestedTeachr>();
  // const [result, setResult] = useState<SimulatorResult>();


 useEffect(() => {
   Functions.getClientIP().then((response) => {
     setIpAddress(response.data.ip);
   });
 }, []);
  const getPhoneNumber = (value: string) => {
    if (value) {
      setPhoneNumber(value);
    }
  };
   const getPreferenceForACall = (value: string) => {
     if (value) {
       setPreferenceForACall(value);
     }
   };
    const logout = () => {
      signOut(auth)
        .then(() => {
          ApiService.disableAuth();
        })
        .catch((error) => {
          // An error happened.
        });
    };
    const createRdv = (phone: string) => {
      logout();
      const interestedTeachr = new InterestedTeachr(phone);
      interestedTeachr.preferenceForACall = preferenceForACall;
      if (ipAddress) {
        interestedTeachr.ipAddress = ipAddress;
      }
      // if (result) {
      //   interestedTeachr.age = result.age;
      //   interestedTeachr.averageNumberHoursPerWeek = result.weekHours;
      //   interestedTeachr.hourlyPrice = result.hourlyPrice;
      //   interestedTeachr.netPricePerHour = result.netPricePerHour;
      //   interestedTeachr.netPricePerMonth = result.netPricePerMonth;
      //   interestedTeachr.netPricePerYear = result.netPricePerYear;
      //   switch (result.status) {
      //     case 1:
      //       interestedTeachr.isAcre = true;
      //       break;
      //     default:
      //       interestedTeachr.isAcre = false;
      //       break;
      //   }
      // }
      InterestedTeachrRepository.createInterestedTeachr(interestedTeachr).then(
        (response) => {
          setInterestedTeachr(response);
          // setOpen(true)
        }
      );
    };
    const validateRdv = () => {
      if (phoneNumber) {
        if (validatePhoneNumber(phoneNumber) === "") {
          setOpenRdvDial(false);
          createRdv(phoneNumber);
        } else {
          setErrorPhone(validatePhoneNumber(phoneNumber));
        }
      } else {
        setErrorPhone(validatePhoneNumber(""));
      }
    };

  return (
    <div>
      <div className="">
        <Dialog
          fullWidth
          open={openRdvDial}
          onClose={() => setOpenRdvDial(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          <div className="flex flex-col p-4 pt-0 bg-white md:p-8 md:pt-0 gap-y-4 lg:rounded-[42px] shadow-simulator-popup rounded-[10px]">
            <DialogTitle
              id="alert-dialog-title"
              className="lg:mt-10 mt-4 text-base font-extrabold uppercase text-first lg:text-2xl leading-[21.82px] px-0"
            >
              PRENDRE RENDEZ VOUS AVEC UN CONSEILLer
            </DialogTitle>
            <DialogContent
              className="flex flex-col gap-y-8"
              sx={{
                "&": {
                  padding: 0,
                },
              }}
            >
              <DialogContentText
                id="alert-dialog-description"
                className="px-0 text-xs font-bold text-normal lg:text-lg"
              >
                Pour pouvoir accéder à l’aide financière de l’état et à notre
                plateforme de déclaration de cours, Veuillez renseigner votre
                numéro de téléphone.
              </DialogContentText>
              <div className="flex flex-col gap-y-4">
                <div>
                  <p
                    className={`${ errorPhone !== "" ? "block" : "hidden" } text-third text-xs md:text-sm pb-1`}
                  >
                    {errorPhone}
                  </p>
                  <Input
                    callBack={getPhoneNumber}
                    field="telephone"
                    label="Téléphone portable"
                    placeholder="Ex: 06 XX XX XX XX"
                    require
                  />
                </div>
                <div className="flex flex-row items-center gap-x-2 lg:flex-col lg:items-start">
                  <h3 className="text-xs font-bold text-normal lg:text-lg">
                    Indiquez vos disponibilités :
                  </h3>
                  <p className="text-xs font-medium lg:text-sm">(Optionnel)</p>
                </div>
                <div className="w-full">
                  <Input
                    callBack={getPreferenceForACall}
                    field="disponibilities"
                    label="Jours et heures de préférence pour un rappel :"
                    placeholder="“Les lundis de 17h à 19h et les mercredis de 14h à 16h”"
                  />
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <div className="flex flex-col gap-y-4">
                <p className="font-medium lg:text-sm text-normal text-[10px] text-center lg:text-left">
                  En soumettant ce formulaire, j’accepte d’être recontacté selon
                  les disponibilités des conseillers.
                </p>
                <Button
                  className="block w-3/4 py-2 mx-auto font-extrabold text-white rounded-full lg:py-3 lg:w-3/5 bg-first"
                  onClick={validateRdv}
                >
                  VALIDER
                </Button>
              </div>
            </DialogActions>
          </div>
        </Dialog>
      </div>
      <NavbarHomePage theme="light" />
      <div className="md:flex md:px-10 px-4 mt-24 ">
        <div className="md:w-3/5 text-[#4d4d4d] md:px-[50px] space-y-8">
          <h1 className="font-extrabold xl:text-[40px] text-[30px]">
            L’avance immédiate du crédit d’impôt :
          </h1>
          <p className="xl:text-[32px] text-[24px] font-bold md:block hidden">
            Un dispositif mis en place par l’État et <br />
            re-distribué par Teach’r
          </p>
          <p className="text-[24px] md:hidden font-bold">
            Un dispositif mis en place par l’État et re-distribué par Teach’r
          </p>
          <div className="border-2 border-[#92D3FF] rounded-3xl bg-[#EBF7FF] md:p-8 p-4">
            <h2 className="font-extrabold text-[24px] text-first">
              Qu’est-ce que ça change pour vous professeur ?{" "}
            </h2>
            <p className="xl:text-[22px] text-[20px] font-bold pt-5">
              Concrètement vous allez pouvoir fixer le prix de cours que vous
              souhaitez tout en sachant que le parent de votre élève ne
              déboursera que la moitié. Par exemple si vous décidez de proposer
              un cours à 40€/h c’est seulement 20€ que le parent devra payer.
            </p>
          </div>
        </div>
        <div className="w-2/5 pt-20 md:flex hidden">
          <img src={bgaici} alt="" />
        </div>
      </div>
      <div className="bg-bg1 pb-32 mt-14 text-[#4d4d4d] font-extrabold md:px-[70px] px-4">
        <div className="md:flex md:gap-x-4">
          <div className="w-2/6 translate-y-36 hidden md:block">
            <img src={bgaici2} alt="" />
          </div>
          <div className="md:w-4/6 md:mt-10 mt-5 relative">
            <h2 className="right-0 xl:text-[36px] text-[26px] md:absolute font-extrabold">
              L’avance immédiate du crédit d’impôt :
            </h2>
            <div className="border-2 bg-white rounded-xl md:translate-y-20 space-y-4 md:p-8 p-4 translate-y-8 ">
              <h3 className="text-first text-[24px] /text-[22px] font-extrabold">
                Le crédit d’impôt classique :
              </h3>
              <p className="xl:text-[22px] text-[20px] text-[#4d4d4d] font-bold">
                Il s’applique une fois par an au moment de la déclaration
                d’imposition annuelle, le parent peut alors bénéficier d’une
                remise de 50% sur le montant de ses cours particuliers à ce
                moment là.
              </p>
              <h3 className="text-first text-[24px] font-extrabold">
                Le crédit d’impôt instantané (depuis juin 2022), <br />{" "}
                <a
                  className="mx-auto cursor-pointer underline text-[#4d4d4d] font-semibold text-[16px] -mt-4"
                  href="https://www.urssaf.fr/portail/files/live/sites/urssaf/files/particulier/avance-immediate/avance-immediate.html"
                >
                  cliquez ici pour en savoir plus
                </a>
              </h3>
              <p className="xl:text-[22px] text-[20px] text-[#4d4d4d] font-bold">
                En juin 2022, l’État a mis en place le crédit d’impôt
                instantané. L’élève a la possibilité de seulement payer
                immédiatement 50% du prix facturé pour ses cours particuliers.
                Plus besoin d’attendre l’année suivante. C’est notamment grâce à
                ce système que vous pouvez faire payer vos élèves 2 fois moins
                cher.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="/h-[500px] text-[#4d4d4d] bg-white md:flex md:px-[76px] px-4 py-10">
        <div className="md:w-1/2 space-y-6">
          <h2 className=" xl:text-[36px] text-[26px] font-extrabold">
            Le tableau de bord un dispositif spécialement conçu pour les professeurs :
          </h2>
          <div className="border-2 border-[#92D3FF44]  shadow-lg  rounded-xl py-6">
            <div className="md:px-6 px-3 space-y-4 pb-4">
              <h3 className="xl:text-[26px] text-[20px] font-extrabold">
                Pour en bénéficier :{" "}
              </h3>
              <div className="xl:text-[20px] text-[18px] space-y-2">
                <ul>
                  <li className="">
                    {/* <div className='w-1 h-1 mt-2 /border-2 bg-black rounded-full'></div> */}
                    <strong>
                      un statut juridique (auto-entrepreneur, SAS, SARL ...)
                    </strong>
                    obligatoire pour déclarer vos cours.{" "}
                  </li>
                  <li>
                    <strong>
                      Un arrêté préfectoral “Déclaration Services à la Personne
                      (SAP)”
                    </strong>
                    pour faire profiter de l’avance immédiate du crédit d’impôt
                    à vos élèves
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-[#92D3FF44] xl:text-[18px] text-[16px] flex gap-x-4 p-2">
              <img src={iconi} alt="icon?" className="mt-2" />
              <p>
                Vous ne souhaitez pas faire les démarches administratives ? Nous
                pouvons nous en occuper gratuitement. Vous souhaitez vous
                renseigner sur le statut auto-entreprise ? Vous pouvez suivre
                notre <InfoArrayHomeAici/>
              </p>
            </div>
          </div>
        </div>
        <div className="md:w-1/2 pt-10">
          <img src={bgaici3} alt="" />
        </div>
      </div>
      <div className="bg-[#EBF7FF] py-14 md:px-[60px] px-4 text-[#4d4d4d]">
        <h2 className="xl:text-[36px] text-[24px] font-extrabold">
          Quelle est la marche à suivre ?
        </h2>
        <div className="md:grid md:grid-cols-4 md:gap-x-2 md:space-y-0 space-y-8 pt-8">
          <div className="">
            <div className="">
              <img src={dem1} alt="" className="border-2 mx-auto" />
              <img
                src={arrow1}
                alt=""
                className="translate-x-64 -translate-y-20 xl:block hidden"
              />
            </div>
            <div className="text-center">
              <h4 className="text-[19px] font-extrabold">
                Créez votre compte professeur
              </h4>
              <p className="text-[16px] mt-2">
                Renseignez les informations nécessaires à votre inscription en
                tant que nouveau professeur
              </p>
            </div>
          </div>
          <div className="">
            <div className="">
              <img src={dem1} alt="" className="border-2 mx-auto" />
              <img
                src={arrow2}
                alt=""
                className="translate-x-64 -translate-y-16 xl:block hidden"
              />
            </div>
            <div className="text-center">
              <h4 className="text-[19px] font-extrabold">
                Remplissez la procuration
              </h4>
              <p className="text-[16px] mt-2">
                Notre équipe se chargera de toutes les démarches administratives
                à votre place (création du statut auto-entrepreneur et
                déclaration “Service à la Personne”).
              </p>
            </div>
          </div>
          <div className="">
            <div className="">
              <img src={dem2} alt="" className="border-2 mx-auto" />
              <img
                src={arrow1}
                alt=""
                className="translate-x-64 -translate-y-20 xl:block hidden"
              />
            </div>
            <div className="text-center">
              <h4 className="text-[19px] font-extrabold">
                Un conseiller disponible
              </h4>
              <p className="text-[16px] mt-2">
                Vous pourrez être guidé pour l’ajout du premier élève dans votre
                tableau de bord. Pensez à le prévenir pour qu’il puisse valider
                l’opération de son côté.
              </p>
            </div>
          </div>
          <div className="">
            <div className="">
              <img src={dem3} alt="" className="border-2 mx-auto" />
              <img src="" alt="" className="" />
            </div>
            <div className="text-center mt-7">
              <h4 className="text-[19px] font-extrabold">
                Recevez votre paiement
              </h4>
              <p className="text-[16px] mt-2">
                Une fois vos cours déclarés, recevez votre paiement sur votre
                compte bancaire et accédez à toutes les fonctionnalités du
                tableau de bord.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="md:px-[60px] px-4 text-[#4d4d4d] py-14">
        <h2 className="xl:text-[36px] text-[26px] font-extrabold md:block hidden">
          Aucuns frais d’inscription, aucunes <br /> démarches administratives
        </h2>
        <h2 className="xl:text-[36px] text-[26px] font-extrabold md:hidden block">
          Aucuns frais d’inscription, aucunes démarches administratives
        </h2>
        <div className="md:grid md:grid-cols-2 md:gap-x-4">
          <div className="space-y-8 md:p-8 p-y-4 md:px-6">
            <div className="space-y-8">
              <p className="xl:text-[24px] text-[20px] font-semibold">
                Vous ne souhaitez pas faire les démarches vous-même ? Nous les
                réalisons à votre place !
              </p>
              <button
                className="md:w-4/5 w-full mx-auto  border text-white bg-[#219CFF] text-base uppercase rounded-full py-2 font-extrabold shadow-md"
                onClick={() => navigate("/teachr/register")}
              >
                S’INSCRIRE
              </button>
            </div>
            <div className="space-y-6">
              <h4 className="xl:text-[26px] text-[22px] font-extrabold">
                Un conseiller à votre écoute{" "}
              </h4>
              <p className="xl:text-[24px] text-[20px] font-semibold">
                Vous avez des questions, vous souhaitez prendre un rendez-vous
                pour une démo ?
              </p>
              <button
                onClick={() => setOpenRdvDial(true)}
                className="md:w-4/5 w-full mx-auto  border text-white bg-[#219CFF] text-base uppercase rounded-full py-2 font-extrabold mt-4 shadow-md"
              >
                RENDEZ-VOUS AVEC UN CONSEILLER
              </button>
            </div>
          </div>
          <div className="/bg-detente bg-no-repeat md:block hidden">
            <img src={detente} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiciPage;