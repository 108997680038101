import { Button } from '@mui/material';
import React from 'react';

const Reglementation = (props: any) => {
    return (
        <div>
            <h2 className='text-base font-extrabold text-first lg:text-2xl'>Principe de la réglementation SAP</h2>
            <div className="flex flex-col pt-4 gap-y-2">
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>
                    Acceptez ensuite les principes de la réglementation SAP en cochant l’icône. Vous pouvez également ajouter des documents (cette étape est optionnelle)
                </p>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-between w-full pb-4 md:px-8">
                <Button onClick={() => props.getStep(3)} className='px-8 font-extrabold uppercase rounded-full text-[#B7B7B7] lg:text-xl'>PRÉCEDENT</Button>
                <Button onClick={() => props.getStep(5)} className='px-8 font-extrabold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
            </div>
        </div>
    );
};

export default Reglementation;