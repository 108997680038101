
const SHOW_DASHBOARD_AICI_LOADER = 'SHOW_DASHBOARD_AICI_LOADER';
const HIDE_LOADER = 'HIDE_LOADER';

const initialState = {
  type: '',
};

export default  function loaderReducer(state = initialState, action:any) {
    switch (action.type) {
      case SHOW_DASHBOARD_AICI_LOADER:
        return { type: 'dashboard-aici' };
      case HIDE_LOADER:
        return initialState;
      default:
        return state;
    }
  }