import React from 'react';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const PaginationComponent2 = (props:any) => {
    const { currentPage, onPageChange, pages, nextPage, prevPage, loading, setLoading } = props;
    // Calcule les pages à afficher
    const visiblePages = () => {
        const pagesToShow = [];
        if (currentPage === 1) {
            // Si nous sommes sur la première page
            for (let i = 1; i <= Math.min(2, pages); i++) {
                pagesToShow.push(i);
            }
        } else if (currentPage === pages) {
            // Si nous sommes sur la dernière page
            for (let i = pages - 1; i <= pages; i++) {
                pagesToShow.push(i);
            }
        } else {
            // Pour toutes les autres pages
            pagesToShow.push(currentPage - 1, currentPage);
            if (currentPage + 1 <= pages) {
                pagesToShow.push(currentPage + 1);
            }
        }
        return pagesToShow;
    };

    // Gestion du clic pour aller à la page précédente
    const handlePrevious = () => {
        if (currentPage > 1) {
            setLoading(true)
            onPageChange(prevPage);
        }
    };

    // Gestion du clic pour aller à la page suivante
    const handleNext = () => {
        if (currentPage < pages) {
            setLoading(true)
            onPageChange(nextPage);
        }
    };


    return (
        <div className=" xl:px-6 px-3 py-2 flex items-center justify-between /lg:mt-2 /mt-10 work-sans /md:absolute border-t-2 border-first /pb-2 xl:bottom-0 /w-[95%]">
            <div className=''>
                <p className="text-sm leading-5 text-first">
                    Affichage <span className="font-medium">{currentPage}</span> {" "} sur <span className="font-medium">{!pages ? 1 : pages}</span>
                </p>
            </div>
            <div className="relative  /pt-4 z-0 inline-flex shadow-sm">
                {/* <Pagination pages={Math.ceil(course?.length / dataPerPage)} currentPage={currentPage} onPageChange={paginate} /> */}
                <div className='flex gap-x-2 /items-center'>
                    {/* Bouton Précédent */}
                    <button className='text-[#4d4d4d] border-2 rounded-md px-2 py-2 font-bold' onClick={handlePrevious} disabled={currentPage === 1}>
                        <KeyboardArrowLeftIcon />
                    </button>

                    {/* Numéros de pages */}
                    {/* <div className="flex justify-center space-x-2 "> */}
                        {visiblePages().map((page) => (
                            <button
                                key={page}
                                className={`px-4 py-2 text-sm ${currentPage === page ? 'bg-first border-first text-white font-bold border-2 rounded-md' : 'text-[#4d4d4d] border-2 rounded-md'}`}
                                onClick={() => onPageChange(page)}
                            >
                                {loading ? "..." : page}
                            </button>
                        ))}
                    {/* </div> */}

                    {/* Bouton Suivant */}
                    <button className='text-[#4d4d4d] border-2 rounded-md px-2 py-2 font-bold' onClick={handleNext} disabled={currentPage === pages}>
                        <KeyboardArrowRightIcon sx={{width: '/17px'}} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PaginationComponent2;
