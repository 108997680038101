import StepperStore from '../../../../LocalForage/StepperStore'
import  { useEffect, useState } from 'react'
import NavBar from '../../../../Components/Navbar/NavBar'
import Stepper from '../../../../Components/utils/Stepper'
import FirstRegister from './First'
// import SecondRegister from './Second'
// import ThirdRegister from './Third'
import { useNavigate } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../../../../Services/FirebaseService'
import Loader from '../../../../Components/utils/Loader'
import Last from './Last'
import NavbarHomePage from '../../../../Components/Navbar/NavbarHomePage'

function Index() {
  const navigate = useNavigate()
  const [user,loading] = useAuthState(auth)
  useEffect(() => {
    if (!loading) {
      if (user) {
        StepperStore.getItem("currentStep"+ user.uid).then((response:any)=>{
          if (response) {
            setCurrentStep(response)
          }else{
            setCurrentStep(1)
          }
          })
      }else{
        navigate('/teachr/register')
      }
    }
  }, [user]);
  const [currentStep, setCurrentStep] = useState(1);

  
  const nextStep = (step: number) => {
    setCurrentStep(step)
    StepperStore.setItem("currentStep" + user?.uid, step, function (err) { })
  };
  const prevStep = (step: number) => {
    setCurrentStep(step);
  };
  return (
    <div className="w-screen pb-10 overflow-x-hidden bg-center bg-no-repeat bg-cover 2xl:overflow-y-hidden md:h-screen md:overflow-y-auto md:pb-0 bg-register">
      <Loader />
      {/* <NavBar theme='dark' /> */}
      <NavbarHomePage theme="dark" />
      <Stepper step={2} currentStep={currentStep}>
        <FirstRegister nextStep={nextStep} />
        {/* <SecondRegister nextStep={nextStep} prevStep={prevStep} />
        <ThirdRegister nextStep={nextStep} prevStep={prevStep} /> */}
        <Last />
      </Stepper>
    </div>
  );
}

export default Index