import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import transition from "react-element-popper/animations/transition";
// import "antd/dist/antd.min.css";
// import { message, TimePicker } from "antd";
import StepperStore from "../../../../LocalForage/StepperStore";
import errorIcon from "../../../../Assets/Images/error.png";
import Functions from "../../../../Helpers/Functions";
// import TextField from "@mui/material/TextField";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import Stack from "@mui/material/Stack";
// import frLocale from "date-fns/locale/fr";
import { Badge } from "@mui/material";
// import { frFR, MobileTimePicker } from "@mui/x-date-pickers";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
// import InputIcon from "react-multi-date-picker/components/input_icon";
// import Input from "../../../Utilitis/Input";
import { TimePicker } from "antd";
// const localeMap = {
//   fr: frLocale,
// };
const format = "HH:mm";
// const months = [
//   ["jan", "j"], //[["name","shortName"], ... ]
//   ["fev", "f"],
//   ["mar", "m"],
//   ["avr", "a"],
//   ["mai", "m"],
//   ["juin", "j"],
//   ["juil", "j"],
//   ["août", "a"],
//   ["sept", "s"],
//   ["oct", "o"],
//   ["nov", "n"],
//   ["déc", "d"],
// ];
const weekDays = [
  ["Lundi", "L"],
  ["Mardi", "M"],
  ["Mercredi", "M"],
  ["Jeudi", "J"],
  ["Vendredi", "V"],
  ["Samedi", "S"],
  ["Dimanche", "D"], //[["name","shortName"], ... ]
];

const StageRevision = (props) => {
  const [user] = useAuthState(auth);
  // const [locale, setLocale] = React.useState("fr");
  const end = moment().set({ hour: 17, minute: 0 }).add(1.5, "hours");
  const start = moment().set({ hour: 17, minute: 0 });
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [dates, setDates] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [startTime, setStartTime] = useState(start);
  const [endTime, setEndTime] = useState(end);
  const [error, setError] = useState({
    val: "null",
    val2: "null",
  });

  const minDate = new Date();
  const yestarday = minDate.setDate(minDate.getDate() - 1);
  const pickDates = () => {
    const result = [];
    // eslint-disable-next-line array-callback-return
    dates.map((date) => {
      result.push(
        moment(date?.toDate?.().toString()).add(1, "d").format("DD/MM/YYYY")
      );
    });
    return result.join(", ");
  };
  const val = dates.length * rangeNumber;
  const handleTaggle = () => {
    setToggle(!toggle);
  };
  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
    setEndTime(moment(startTime).add(newValue, "hours"));
  };

  const calculateTotalHours = () => {
    let total = rangeNumber * dates.length;
    return total;
  };

  const validationHours = (value) => {
    if (value < 5) {
      return {
        isValid: false,
        message: "Il vous faut reserver 5 heures au minimum",
        icon: <img src={errorIcon} alt="" className="w-5 h-5 mt-1" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };

  const validate = () => {
    let errRange = true;
    if (!validationHours(val).isValid) {
      setError({
        val: validationHours(val).message,
        val2: validationHours(val).icon,
      });
      errRange = true;
    } else {
      setError({
        val: validationHours(val).message,
        val2: validationHours(val).icon,
      });
      errRange = false;
    }
    if (errRange) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (event) => {
    if (validate()) {
      StepperStore.setItem(
        user ? "courseType" + user.uid : "courseType",
        {
          type: "Stage",
          data: {
            rangeNumber: rangeNumber,
            dates: dates.map((date) =>
              moment(date?.toDate?.().toString())
                .add(1, "d")
                .format("YYYY/MM/DD")
            ),
            startTime: startTime.toDate().valueOf(),
            endTime: endTime.toDate().valueOf(),
          },
        },

        function (err) {
          console.warn(err);
        }
      );

      props.handleNext();
    }

    event.preventDefault();
  };
  return (
    <div className="space-y-2 lg:space-y-6 h-96 md:space-y-4 lg:translate-y-10 translate-y-28 md:translate-y-20">
      <div className=" w-4/5 mx-auto">
        <h5 className="lg:text-3xl md:text-2xl text-lg text-dark_blue font-bold">
          Stage de révisions :
        </h5>
      </div>
      <div className="w-4/5 mt-4 bordr-4 mx-auto">
        <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
          <label
            htmlFor=""
            className="lg:text-lg md:text-base text-sm font-medium"
          >
            {" "}
            Dates du stage
          </label>
          <div className="mt-1">
            <Badge
              color="primary"
              className="w-full hidden md:flex"
              badgeContent={dates.length > 6 ? "+ " + (dates.length - 6) : null}
            >
              <DatePicker
                formattingIgnoreList={["week", "day", "name", "month", "of"]}
                months={Functions.months}
                weekDays={weekDays}
                minDate={new Date()}
                mapDays={({ date }) => {
                  let props = moment(date?.toDate?.().toString()).add(1, "d");
                  let prop = {};
                  if ([5, 10, 15, 20, 25].includes(props.day)) {
                    prop.hidden = true;
                  }
                  return {
                    children: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          //padding: "0 10px",
                          fontSize: "12px",
                        }}
                      >
                        <div style={{ textAlign: "" }}>{props.format("D")}</div>
                      </div>
                    ),
                  };
                }}
                format={"DD/MM/YYYY"}
                style={{
                  backgroundColor: "#F2F5FB",
                  height: "30px",
                  border: "none",
                  fontSize: "18px",
                  padding: "10",
                  width: "100%",
                }}
                containerStyle={{
                  width: "100%",
                }}
                animations={[transition({ duration: 800, from: 35 })]}
                value={dates}
                onChange={(e) => {
                  setDates(e);
                  validate();
                }}
                multiple
                numberOfMonths={2}
                render={(val, openCalendar) => {
                  return (
                    <input
                      onClick={openCalendar}
                      className="w-full bg-transparent text-[18px] truncate"
                      value={pickDates()}
                      readOnly
                      placeholder="Ex : 23/10/2022, 24/10/2022,..."
                    />
                  );
                }}
              />
            </Badge>
            <Badge
              color="primary"
              className="w-full md:hidden flex"
              badgeContent={dates.length > 4 ? "+ " + (dates.length - 4) : null}
            >
              <DatePicker
                formattingIgnoreList={["week", "day", "name", "month", "of"]}
                months={Functions.months}
                weekDays={weekDays}
                minDate={yestarday}
                mapDays={({ date }) => {
                  let props = moment(date?.toDate?.().toString()).add(1, "d");
                  let prop = {};
                  if ([5, 10, 15, 20, 25].includes(props.day)) {
                    prop.hidden = true;
                  }
                  return {
                    children: (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          //padding: "0 10px",
                          fontSize: "11px",
                          textAlign: "center",
                        }}
                      >
                        <div style={{ textAlign: "center" }}>
                          {props.format("D")}
                        </div>
                      </div>
                    ),
                  };
                }}
                format={"DD/MM/YYYY"}
                style={{
                  backgroundColor: "#F2F5FB",
                  height: "30px",
                  border: "none",
                  fontSize: "18px",
                  padding: "10",
                  width: "100%",
                  textAlign: "center",
                }}
                containerStyle={{
                  width: "100%",
                }}
                animations={[transition({ duration: 800, from: 35 })]}
                value={dates}
                onChange={(e) => {
                  setDates(e);
                  validate();
                }}
                multiple
                numberOfMonths={2}
                render={(val, openCalendar) => {
                  return (
                    <input
                      onClick={openCalendar}
                      className="w-full bg-transparent"
                      readOnly
                      value={pickDates()}
                      placeholder="Ex : 23/10/2022, 24/10/2022,..."
                    />
                  );
                }}
              />
            </Badge>
          </div>
        </div>
      </div>
      <div className="w-4/5   mx-auto translate-y-4  ">
        <div className="md:w-2/3 ">
          <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="lg:text-lg md:text-base text-sm font-medium"
            >
              {" "}
              Nombre d'heure par jour
            </label>
            <div className=" h-10 md:mt-0 -mt-2">
              <Functions.PrettoSlider
                defaultValue={1}
                step={0.5}
                min={1}
                max={4}
                value={rangeNumber}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <span className="font-bold text-dark_blue">
            <input
              type="text"
              value={Functions.renderDuration(rangeNumber * 2) + "/jour "}
              onChange={(e) => setRangeNumber(e.target.value)}
            />
          </span>
        </div>
      </div>
      <div className="w-4/5  translate-y-8 mx-auto">
        <div
          onClick={handleTaggle}
          className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color border-grey-300 text-grey-900 text-sm rounded-md p-1 md:px-2 px-3 focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1"
        >
          <label className="block mb-2 text-base font-medium lg:text-xl">
            Créneaux de disponibilité par jour :
          </label>
          <Badge
            color="primary"
            className="w-full hidden md:flex"
            badgeContent={dates.length > 3 ? "+ " + (dates.length - 3) : null}
          >
            <input
              name="day"
              id="day"
              placeholder="Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi et Dimanche"
              className="bg-blue-50  w-full  lg:text-xl text-base focus:outline-none focus:ring-0 focus:border-1 truncate"
              value={dates
                .map((date) =>
                  moment(date?.toDate?.().toString())
                    .add(1, "d")
                    .format(
                      "dddd " +
                        Functions.renderTime(startTime) +
                        " - " +
                        Functions.renderTime(endTime)
                    )
                )
                .join(", ")}
            />
          </Badge>
          <Badge
            color="primary"
            className="w-full md:hidden flex"
            badgeContent={dates.length > 2 ? "+ " + (dates.length - 2) : null}
          >
            <input
              name="day"
              id="day"
              placeholder="Lundi, Mardi, Mercredi, Jeudi, Vendredi, Samedi et Dimanche"
              className="bg-blue-50  w-full  lg:text-xl text-base focus:outline-none focus:ring-0 focus:border-1 "
              value={dates
                .map((date) =>
                  moment(date?.toDate?.().toString()).format(
                    "dddd " +
                      Functions.renderTime(startTime) +
                      " - " +
                      Functions.renderTime(endTime)
                  )
                )
                .join(", ")}
            />
          </Badge>
        </div>
        {toggle && (
          <div className="grid gap-  -space-y-2 -p-2 md:grid-cols-2 md:space-y-0 lg:p- md:p-4 md:mt-3 mt-2 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color max-h-20  md:max-h-32 list-teacher overflow-y-scroll">
            {dates.map((day, index) => (
              <div
                key={index}
                className="flex pt-1 md:pt-0 justify-between space-x-1"
              >
                <span className="mt-2 lg:text-md w-1/2 hidden md:flex ">
                  {moment(day?.toDate?.().toString())
                    .add(1, "d")
                    .format("dddd DD MMMM")}
                </span>
                <span className="mt-2 lg:text-md w-1/2 md:hidden flex  ">
                  {moment(day?.toDate?.().toString())
                    .add(1, "d")
                    .format("ddd DD MMM")}
                </span>
                <div className="flex justify-between w-3/4">
                  <div
                    className="p-1 font-semibold text-center rounded-lg border-1 md:p-2 border-dark_blue"
                    value={day}
                  >
                    <TimePicker
                      value={startTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                        setEndTime(moment(newValue).add(rangeNumber, "hours"));
                      }}
                      minuteStep={5}
                      format={format}
                      showNow={false}
                      clearIcon=""
                      size="large"
                      suffixIcon=""
                    />
                  </div>
                  <span className="mt-4 px-2"> à </span>
                  <div className="md:-mt-2 md:p-4 p-1 text-center font-semibold border-dark_blue rounded-lg">
                    <TimePicker
                      value={endTime}
                      onChange={(newValue) => {
                        setStartTime(newValue);
                      }}
                      minuteStep={5}
                      format={format}
                      showNow={false}
                      clearIcon=""
                      size="large"
                      suffixIcon=""
                      inputReadOnly={true}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <span className="font-bold text-dark_blue">
          <input
            type="text"
            value={
              "Total : " + Functions.renderDuration(calculateTotalHours() * 2)
            }
            onChange={(e) => {
              setRangeNumber(e.target.value);
            }}
          />
        </span>
      </div>
      <div className=" translate-y-4 flex space-x-2 md:text-lg  w-4/5 mx-auto">
        {error.val === "null" || error.val === ""
          ? " "
          : validationHours(val).icon}
        <p className="text-[#FF724F] h-6 w-full ">
          {error.val === "null" || error.val === ""
            ? null
            : validationHours(val).message}
        </p>
      </div>
      <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-20 md:transl md:w-full">
        <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
          <div
            onClick={props.handleBack}
            className="text-xs pt-2 w-full text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <div
            onClick={(e) => handleSubmit(e)}
            className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
          >
            Suivant
          </div>
        </div>
      </div>
      <div className="md:flex hidden justify-around mx-auto translate-y-10 md:w-full">
        <div className="flex space-x-2 justify-center">
          <button
            type="button"
            onClick={props.handleBack}
            className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent
          </button>
        </div>
        <div className="flex justify-center space-x-2 ">
          <button
            type="button"
            onClick={(e) => handleSubmit(e)}
            className={
              // error.val === "null"
                // ? 
                " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
                // : error.val
                // ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
                // : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
            }
          >
            Suivant
          </button>
        </div>
      </div>
    </div>
  );
};

export default StageRevision;
