import validator from "validator";
import errorIcon from "../Assets/Images/error.png";

const validateFirstName = (value) => {
if (!value) {
  return {
    isValid: false,
    message: "Prénom invalide" ,
    icon:<img src={errorIcon} alt="" className="w-4 h-4" />
  };
}
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: "Entrez votre prénom",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  }
  // else if (!value.match(/^[a-zA-z,.'-éèàê]+$/i)) {
  //   return {
  //     isValid: false,
  //     message: "Prénom invalide",
  //     icon:<img src={errorIcon} alt="" className="w-4 h-4" />
  //   };
  // } 
  else if (value.match(/^[0-9]+$/i)) {
    return {
      isValid: false,
      message: "Prénom invalide" ,
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  }
   else
    return {
      isValid: true,
      message: "",
    };
    
};

export default validateFirstName 