import validator from 'validator' 
import errorIcon from "../Assets/Images/error.png";

const validatePhoneNumbers = (value) => {
  if (validator.isEmpty(value)) {
    return {
      isValid: false,
      message: "Remplir ce champ",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  }
  if (!value.match(/^(\+33)(6|7|1|9)[0-9]{8}$/)) {
    return {
      isValid: false,
      message: "Numéro de téléphone invalide",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    }}
   else {
    return {
      isValid: true,
      message: "",
    }
  }
};

export default validatePhoneNumbers 