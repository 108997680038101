import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import { auth } from "../../../../../../Services/FirebaseService";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import Functions from "../../../../../../Helpers/Functions";
import DatePicker, { DateObject } from "react-multi-date-picker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import CustomFeesRepository from "../../../../../../Repositories/CustomFeesRepository";
import { extractDateTimeFromString, convertDateTimeFormat, weekDays } from "../../UtilsDashboard/FunctionsDashbord";
import AlertInfo from "../../UtilsDashboard/AlertInfo";

const validateDate = (date) => {
  let array = [];
  let endDay = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format("YYYY-MM-DD")
  const nows = moment().format("YYYY-MM-DD");
  if (date.length === 0) {
    return {
      error: true,
      message: "Veuillez renseigner une date",
    };
  } else if (date.length === 1) {
    return {
      error: true,
      message: "Veuillez renseigner une date de fin",
    };
  } else if (date.length === 2) {
    date.map((day) => {
      array.push(moment(day?.toDate?.().toString()).format("YYYY-MM-DD"));
    });
    if (array[1] < nows) {
      return {
        error: true,
        message:
          "La fin de la période des cours doit être aujourd'hui ou plus tard dans le mois.",
      };
    } else if (array[1] === array[0]) {
      if (array[1] === endDay) {
        return {
          error: false,
          message: "",
        };
      } else {
        return {
          error: true,
          message:
            "Veuillez modifier la date de fin. Si vous souhaitez avoir un cours le même jour, veuillez déclarer un cours ponctuel.",
        };
      }
    }else {
      return {
        error: false,
        message: "",
      };
    }
  } else {
    return {
      error: false,
      message: "",
    };
  }
};

const StepPunctualMonth = (props) => {
  const { handleChangeStep } = props;
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const todayHour20 = moment(today).format("YYYY-MM-DD HH:mm:ss");
  const lastDayHour20 = moment(lastDayOfMonth).format("YYYY-MM-DD 20:00:00");
  const currentMonth = new Date().getMonth();
  const result = [];

  const [user] = useAuthState(auth);
  const [values, setValues] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [duration, setDuration] = useState(3);
  const [customFees, setCustomFees] = useState();
  const [error, setError] = useState({ date: "null" });
  const [day, setDay] = useState(0);
  const [givenMonth, setGivenMonth] = useState();
  const [notes, setNotes] = useState("");

  useEffect(() => {
    StepperStore.removeItem("activeStepundefined")
    StepperStore.getItem("cours" + user.uid).then((client) => {
      if (client !== null) {
        StepperStore.getItem("description_tdp" + client?.client).then((res) => {
          if (res !== null) {
            const dateTime = extractDateTimeFromString(res);
            const date = new Date();
            const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            const givenDateString = dateTime;
            const givenDate = convertDateTimeFormat(givenDateString);
            const givenMonths = givenDate.getMonth();
            setGivenMonth(givenMonths);
            let today = firstDay.getTime()
            let dateGiven = givenDate.getTime();
            let msDay = 24 * 60 * 60 * 1000;
            let days = Math.floor((dateGiven - today) / msDay);
            setDay(days + 2);
          }
        })
      }
    });
  }, [])

  const handleAddDuration = () => {
    if (duration <= 49.75) {
      setDuration((currentValue) => currentValue + 0.25);
    }
  };
  const handleSubtractDuration = () => {
    if (duration >= 0.5) {
      setDuration((currentValue) => currentValue - 0.25);
    }
  };
  const validate = () => {
    let error = true;

    if (validateDate(values).error) {
      setError({ date: validateDate(values).message });
      error = true;
    } else {
      setError({ date: validateDate(values).message });
      error = false;
    }

    if (!error) {
      return true;
    } else {
      return false;
    }
  };
  const dispalayInfo = () => {
    if (
      moment(today).format("YYYY-MM-DD") ===
      moment(lastDayOfMonth).format("YYYY-MM-DD")
    ) {
      return (
        <p className="lg:text-lg md:text-base text-sm text-[#4d4d4d]">
          <strong className="text-first lg:text-xl /uppercase">
            Important :
          </strong>{" "}
          Aujourd'hui, dernier jour du mois, est la date limite pour déclarer
          vos prestations du mois en cours. Les déclarations tardives ne seront
          pas prise en compte pour le paiement de ce mois-ci. Cette procédure
          assure le traitement en temps et en heure de vos paiements. Merci pour
          votre comprehension.
        </p>
      );
    }
  };
  const displayWarning = () => {
    if (todayHour20 >= lastDayHour20) {
      return (
        <p className="lg:text-lg md:text-base text-sm text-[#4d4d4d] mt-2 ">
          <strong className="text-red-400">Attention /!\</strong> Nous sommes le
          dernier jour du mois et il est 20h passé, il n'est plus possible de
          déclarer vos cours pour le mois en cours. Veuillez attendre jusqu'au
          1er du mois prochain pour déclarer vos cours.
        </p>
      );
    }
  };
  const handleNext = () => {
    if (validate()) {
      if (endDate !== null || endDate !== undefined) {
        const endLastDay = moment(lastDayOfMonth).format("YYYY-MM-DD 20:00:00");
        const endDateTimeOfLastDay = moment(endDate).set("hour", 23).set("minutes", 50).format("YYYY-MM-DD HH:MM:ss")
        const min = today.getMinutes();
        const valeur = 60 - min;
        const newMin = valeur + min;

        const startDateTime = moment(startDate)
          .set("hour", today.getHours() + 1)
          .set("minutes", newMin)
          .toDate();

        const endDateTime = endDateTimeOfLastDay > endLastDay ? moment(endDate)
          .set("hour", 19)
          .set("minutes", 59)
          .toDate() : moment(endDate)
            .set("hour", today.getHours() + 1)
          .set("minutes", newMin).toDate() 


        StepperStore.setItem("punctualMonth" + user?.uid, {
          startDateTime: startDateTime,
          endDateTime: endDateTime,
          duration: duration,
          customFees: customFees,
          values: values,
          notes: notes
        });
        if (customFees !== undefined) {
          if (customFees?.is_teachr_contact === true) {
            handleChangeStep(6);
          } else if (customFees?.is_teachr_contact === false) {
            handleChangeStep(7);
          }
        } else {
          handleChangeStep(6);
        }
      }
    }
  };
  const dispalayButtonNext = () => {
    if (todayHour20 >= lastDayHour20) {
      return (
        <button
          type="button"
          className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] text-white bg-[#8d8d8d] leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 transition duration-150 ease-in-out md:w-36 w-24 "
        >
          Suivant
        </button>
      );
    } else {
      return (
        <button
          type="button"
          onClick={(e) => handleNext()}
          className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
        >
          Suivant
        </button>
      );
    }
  };
  const dispalayButtonNextMobile = () => {
    if (todayHour20 >= lastDayHour20) {
      return (
        <div
          onClick={(e) => handleNext()}
          className="md:px-4 md:py-2 py-2 px-3 border-2 w-full text-center font-extrabold hover:font-extrabold text-sm border-[#8d8d8d] bg-[#8d8d8d] uppercase rounded-full text-white  "
        >
          Suivant
        </div>
      );
    } else {
      return (
        <div
          onClick={(e) => handleNext()}
          className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
        >
          Suivant
        </div>
      );
    }
  };
  const displayButton = () => {
    return (
      <>
        <div className="absolute md:hidden flex justify-around mx-auto space-x-4 md:translate-y-44 w-full px-4">
          <div className="flex w-1/2 rounded-full bg-white text-orange  border-2 border-orange font-extrabold justify-center">
            <div
              onClick={() => handleChangeStep(2)}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            {dispalayButtonNextMobile()}
          </div>
        </div>
        <div className="md:flex px-10 bottom-0 absolute hidden justify-between mx-auto pb-10 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleChangeStep(2)}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center ">{dispalayButtonNext()}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    values.map((date) => {
      result.push(moment(date?.toDate?.().toString()).format("YYYY-MM-DD"));
    });
    setStartDate(result[0]);
    setEndDate(moment(result[1]).add(1, "hours").format("YYYY-MM-DD"));
  }, [result, values]);
  useEffect(() => {
    if (user) {
      StepperStore.getItem("cours" + user.uid).then((res) => {
        // const idClient = parseInt(res?.client["@id"].substr(9));
        const idClient = res?.client;
        TeachrRepository.getCurrentTeachr().then((teachr) => {
          if (teachr?.id) {
            CustomFeesRepository.getCustomFeesSingle(idClient, teachr?.id).then(
              (res) => {
                setCustomFees(res[0]);
              }
            );
          }
        });
      });
    }
  });

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%]  bg-white rounded-lg shadow-dashboard-setup-intro-form">
      <div className="relative h-[100%]">
        <div className="space-y-4 h-[90%] /h-[100%] md:p-10 p-6 ">
          <h2 className="font-extrabold /lg:text-3xl md:text-2xl text-xl text-dark_blue">
            Plusieurs cours
          </h2>
          {dispalayInfo()}
          <div className="mx-auto w-full translate-y-6 md:space-y-12 space-y-6">
            {/* <div className="w-full /md:mt-8 mt-4 gap-8 md:space-y-0 space-y-6  md:flex block mt- mx-auto">
              <div className="md:w-2/3">
                <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-base">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-base font-medium"
                  >
                    {" "}
                    La période des cours :
                  </label>
                  <div className="md:-mt-3 -mt-6 md:translate-y-0 translate-y-2  ">
                    <div className="relative flex z-50  gap-2 -ml-1 ">
                      <DatePicker
                        portal={true}
                        style={{
                          backgroundColor: "#F2F5FB !important",
                          background: "#F2F5FB",
                          height: "30px",
                          border: "none",
                          fontSize: "18px",
                          width: "100%",
                          zIndex: "50",
                        }}
                        placeholder="Rechercher par période"
                        containerStyle={{
                          width: "100%",
                          marginTop: "10px",
                          backgroundColor: "#F2F5FB",
                          border: "none",
                          zIndex: "50",
                        }}
                        className=""
                        months={Functions.months}
                        weekDays={Functions.weekDays}
                        format={"DD/MM/YYYY"}
                        value={values}
                        minDate={givenMonth === currentMonth ? new Date().setDate(day) : new DateObject().toFirstOfMonth()}
                        maxDate={new DateObject().toLastOfMonth()}
                        onChange={(e) => setValues(e)}
                        range
                        rangeHover
                      />
                      {(startDate !== undefined || endDate !== undefined) && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                            setValues([]);
                          }}
                        >
                          <HighlightOffIcon
                            sx={{ marginTop: 1.5, marginRight: 2 }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="w-full grid md:grid-cols-1 gap-4 md:gap-8">
              <div className="grid md:grid-cols-2 gap-4">
                <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-base">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-base font-medium"
                  >
                    {" "}
                    La période des cours :
                  </label>
                  <div className="md:-mt-3 -mt-6 md:translate-y-0 translate-y-2  ">
                    <div className="relative flex z-50  gap-2 -ml-1 ">
                      <DatePicker
                        portal={true}
                        style={{
                          backgroundColor: "#F2F5FB !important",
                          background: "#F2F5FB",
                          height: "30px",
                          border: "none",
                          fontSize: "18px",
                          width: "100%",
                          zIndex: "50",
                        }}
                        placeholder="Rechercher par période"
                        containerStyle={{
                          width: "100%",
                          marginTop: "10px",
                          backgroundColor: "#F2F5FB",
                          border: "none",
                          zIndex: "50",
                        }}
                        className=""
                        months={Functions.months}
                        weekDays={weekDays}
                        format={"DD/MM/YYYY"}
                        value={values}
                        minDate={givenMonth === currentMonth ? new Date().setDate(day) : new DateObject().toFirstOfMonth()}
                        maxDate={new DateObject().toLastOfMonth()}
                        onChange={(e) => setValues(e)}
                        range
                        rangeHover
                      />
                      {(startDate !== undefined || endDate !== undefined) && (
                        <div
                          className="cursor-pointer"
                          onClick={() => {
                            setStartDate(null);
                            setEndDate(null);
                            setValues([]);
                          }}
                        >
                          <HighlightOffIcon
                            sx={{ marginTop: 1.5, marginRight: 2 }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-sm font-medium"
                  >
                    Nombre d’heures de cours
                  </label>
                  <div className="md:h-9 h-11 md:-translate-y-1 md:mt-0 /-mt-2">
                    <div className="w-full flex">
                      <input
                        disabled
                        type="text"
                        id="firs_firstName"
                        className=" w-3/4 bg-transparent focus:outline-none focus:ring-0 "
                        placeholder="Ex : 1h30"
                        value={Functions.renderDuration(duration * 2)}
                      />
                      <div className=" /p-2 gap-x-2 flex w-1/4">
                        <button
                          onClick={(e) => handleSubtractDuration(e)}
                          className="border-[4px] border-white bg-red-400 font-extrabold text-white rounded-lg w-1/2"
                        >
                          -
                        </button>
                        <button
                          onClick={(e) => handleAddDuration(e)}
                          className="border-[4px] border-white bg-first font-extrabold text-white rounded-lg w-1/2"
                        >
                          <AddIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid md:grid-cols-3 gap-4">
                <div className="col-span-3 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-sm font-medium"
                  >
                    Note optionnelle à destination de votre élève
                  </label>
                  <div className="/md:h-9 /h-11 md:-translate-y-1 mt-1 /-mt-2">
                    <div className="w-full flex">
                      <textarea
                        cols="" rows="3"
                        // type="text"
                        // id="firs_firstName"
                        className=" w-full /border-2 bg-transparent focus:outline-none focus:ring-0 "
                        placeholder="Vous pouvez renseigner ici toute information utile que souhaiez partager concernant la facturation des cours du mois"
                        value={notes}
                        onChange={(e)=> setNotes(e.target.value)}
                        // value={Functions.renderDuration(duration * 2)}
                      />
                      {/* <textarea cols="30" rows="10"></textarea> */}
                    </div>
                  </div>
                </div>
                {/* <AlertInfo info="info" msg="Vous pouvez renseigner ici toute information utile que souhaiez partager concernant la facturation des cours du mois" /> */}
              </div>
            </div>
            <div className="flex space-x-2  text-base /md:translate-y-4 /translate-y-2 ">
              <p className="text-[#FF724F] h-6 w-full text-base md:text-base ">
                {error.date === "null" || error.date === ""
                  ? null
                  : validateDate(values).message}
              </p>
            </div>
            <div>{displayWarning()}</div>
          </div>
        </div>
        {displayButton()}
      </div>
    </div>
  );
};

export default StepPunctualMonth;
