import React from 'react';
import { Subject } from '../../../../Objects/models/Subject';

const Search = (props : any) => {
    return (
        <div className='px-4 bg-bottom bg-no-repeat bg-contain  lg:bg-[length:50%_100%] md:bg-[length:70%_100%] lg:px-8  md:bg-right-top bg-nocourse lg:h-[calc(100vh_-_18rem)] h-screen'>
            <ul className='flex flex-col h-full gap-8 lg:w-1/3 md:w-1/2'>
                <li className='lg:text-[32px] text-xl text-first font-bold leading-[43px]'>
                    Aucun cours n’est disponible en {props.subjects.map((subject:Subject,index : number) => <span key={index} className={index !== 0 ? "before:content-[',']" : ''}>{subject.fr_FR}&nbsp;</span>)} pour le moment.
                    Veuillez réessayer plus tard.
                </li>
                <li className='lg:text-[32px] text-xl text-first font-bold leading-[43px]'>
                    Vous souhaitez obtenir plus de cours en {props.subjects.map((subject:Subject,index : number) => <span key={index} className={index !== 0 ? "before:content-[',']" : ''}>{subject.fr_FR}&nbsp;</span>)} ?
                </li>
                <li className='p-2 text-xl font-extrabold text-center text-white rounded-full cursor-pointer bg-first'>Trouver un cours </li>
            </ul>
        </div>
    );
};

export default Search;