import HydraEntity from "./HydraEntity";
import "reflect-metadata";
import { Type } from "class-transformer";

export default class Image extends HydraEntity {
    readonly contentUrl?: string; // url of the file

    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;
    readonly id?: number;
}
