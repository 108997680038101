// import ListChild from "./ForChild.tsx/ListChild";
import React, { useEffect, useState } from "react";
import { auth, getJWTToken } from "../../../Services/FirebaseService";
import ClientRepository from "../../../Repositories/ClientRepository";
import ApiService from "../../../Services/ApiService";
import { useAuthState } from "react-firebase-hooks/auth";
import ChildRepository from "../../../Repositories/ChildRepository";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// import Stack from "@mui/material/Stack";
import AddChild from "../../../Components/NewCourse/Forms/ForChild.tsx/AddChild";
import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
// import Avatar from "@mui/material/Avatar";
import StepperStore from "../../../LocalForage/StepperStore";
import validateChild from "../../../Validations/ValidateChild";

// import FolderIcon from "@mui/icons-material/Folder";
import {
  // Box,
  // Checkbox,
  // Divider,
  FormControl,
  FormControlLabel,
  // ListItemIcon,
  RadioGroup,
  Typography,
} from "@mui/material";
import RadioButton from "../../utils/RadioButtonClient";
import AddMe from "./ForChild.tsx/AddMe";
import AddFriend from "./ForChild.tsx/AddFriend";

// import InboxIcon from "@mui/icons-material/Inbox";
// import DraftsIcon from "@mui/icons-material/Drafts";
import Client from "../../../Objects/models/Client";
// import LoaderContext from "../../LoadingContext";
// import Loader from "../../utils/Loader";
// import CircularProgress from "@mui/material/CircularProgress";

// const BootstrapButton = styled(Button)({
//   boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.25)",
//   textTransform: "none",
//   fontSize: "24px",
//   fontWeight: "bold",
//   color: "#0054A3",
//   padding: "6px 12px",
//   lineHeight: 1.5,
//   backgroundColor: "#F2F5FB",
//   borderRadius: "15px",
//   fontFamily: ["nunito"].join(","),
//   "&:hover": {
//     backgroundColor: "#0054A3",
//     color: "white",
//     boxShadow: "none",
//   },
//   "&:active": {
//     boxShadow: "none",
//     backgroundColor: "#F2F5FB",
//     color: "#0054A3",
//   },
//   "&:focus": {
//     boxShadow: "0 0 0 1px .2rem rgba(0,23,55,.5)",
//   },
// });

const FormWhos = (props: { handleNext: any; handleBack: any }) => {
  const [forWho, setForWho] = useState({
    me: true,
    myChild: false,
    friend: false,
  });
  const { handleNext, handleBack } = props;
  // const [opens, setOpens] = useState(false);
  const [client, setClient] = useState<Client>();
  const [loadings, setLoading] = useState(false);
  const [user, loading] = useAuthState(auth);
  const [child, setChild] = useState<any>([]);
  const [dataChild, setDataChild] = useState("");
  const [selected, setSelected] = useState("");
  const [add, setAdd] = useState<any>([]);
  useEffect(() => {
    if (loading) {
      document.getElementById("teachr-loader")?.classList.remove("hidden");
    } else {
      document.getElementById("teachr-loader")?.classList.add("hidden");
    }
  }, [loading, user]);

  const handleSelect = (value: any) => {
    setSelected(value);
  };
  // const handleClickOpen = () => {
  //   setOpens(true);
  // // };
  // const handleClose = () => {
  //   setOpens(false);
  // };
  const handleChange = (value: any) => {
    setAdd(value);
  };
  const reload = () => {
    setLoading(!loadings);
  };
  const handleMe = () => {
    setForWho({
      me: true,
      myChild: false,
      friend: false,
    });
  };
  const handleMyChild = () => {
    setForWho({
      me: false,
      myChild: true,
      friend: false,
    });
  };
  const handleFriend = () => {
    setForWho({
      me: false,
      myChild: false,
      friend: true,
    });
  };
  // const [load, setLoad] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        if (user) {
          const jwtToken = await getJWTToken(user);
          ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
          const clientWithId = await ClientRepository.getCurrentClient();
          setClient(clientWithId);

          if (clientWithId.id) {
            const allChildrenCollection = await ChildRepository.getChildren(
              clientWithId.id
            );
            const allChildren = allChildrenCollection;
            const firstChild = allChildren[0].firstName;
            const selectChild = allChildren[0];
            if (allChildren) {
              setChild(allChildren);
              setSelected(firstChild);
              setAdd(selectChild);
              // handleClose();
              // setLoad(false);
            }
          }
        }
      } catch (error) {}
    })();
  }, [loadings, user]);

  // const getFormLocation = async () => {
  //   const value: any = await StepperStore.getItem(
  //     user ? "formWho" + user.uid : "formWho"
  //   );
  //   if (value !== null) {
  //     setAdd(value);
  //   } else {
  //     setAdd([]);
  //   }
  // };
  useEffect(() => {
    (async () => {
      const child: any = await StepperStore.getItem(
        user ? "formWhos" + user?.uid : "formWhos"
      );
      if (child !== null) {
        setDataChild(child.firstName);
      }
    })();
  }, [loadings, user]);

  useEffect(() => {
    ( async () => {
      const value: any = await StepperStore.getItem(
        user ? "formWho" + user.uid : "formWho"
      );
      if (value !== null) {
        setAdd(value);
      } else {
        setAdd([]);
      }
    })()
  }, [user]);

  const [error, setError] = useState({
    child: "null",
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ errors, setErrors] = useState({
    add: "null",
  });
  const validate = () => {
    let errMatiere = true;
    if (!validateChild(dataChild).isValid) {
      setError({ child: validateChild(dataChild).message });
      errMatiere = true;
    } else {
      setError({ child: validateChild(dataChild).message });
      errMatiere = false;
    }
    if (errMatiere) {
      return false;
    } else {
      return true;
    }
  };

  const validateConnect = () => {
    let errMatiere = true;
    if (!validateChild(selected).isValid) {
      setErrors({ add: validateChild(selected).message });
      errMatiere = true;
    } else {
      setErrors({ add: validateChild(selected).message });
      errMatiere = false;
    }
    if (errMatiere) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmitDisConnect = () => {
    if (validate()) {
      StepperStore.setItem(
        user ? "formWho" + user?.uid : "formWho",
        {
          add: add,
          me: forWho.me,
          myChild: forWho.myChild,
          friend: forWho.friend,
        },
        function () {}
      );
      handleNext();
    }
  };

  const handleSubmitConnect = () => {
    if (validateConnect()) {
      StepperStore.setItem(
        user ? "formWho" + user?.uid : "formWho",
        {
          add: add,
          me: forWho.me,
          myChild: forWho.myChild,
          friend: forWho.friend,
        },
        function () {}
      );
      handleNext();
    }
  };
  const handleSubmit = (event: any) => {
    if (user) {
      handleSubmitConnect();
    } else {
      handleSubmitDisConnect();
    }
  };

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index : number
  ) => {
    setSelectedIndex(index);
  };

  return (
    <>
      {/* <Loader /> */}
      <div className="space-y-2 lg:space-y-6 md:space-y-4 lg:translate-y-10 translate-y-28 md:translate-y-20">
        <div className=" w-4/5 mx-auto mb-24">
          <h5 className="lg:text-3xl md:text-2xl text-lg text-dark_blue font-bold">
            À qui s'adresse le cours ?
          </h5>
        </div>
        <div className="-mt-12 md:-mt-0">
          <div className="md:-translate-y-8 -translate-y-20   md:w-4/5 w-1/2 ml-7  font-nunito md:mx-auto">
            {!user ? (
              <div className="ml-12 md:ml-0">
                <FormControl>
                  <RadioGroup row name="customized-radios">
                    <div className="font-nunito ">
                      <FormControlLabel
                        checked={forWho.me === true}
                        onChange={() => handleMe()}
                        sx={{ color: "#4d4d4d", fontFamily: "Nunito-Regular" }}
                        value={forWho.me === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-sm md:text-lg"
                          >
                            À moi{" "}
                          </Typography>
                        }
                      />
                    </div>
                    <div className=" xl:ml-20 lg:ml-4 ">
                      <FormControlLabel
                        checked={forWho.myChild === true}
                        onChange={() => handleMyChild()}
                        sx={{ color: "#4d4d4d" }}
                        value={forWho.myChild === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-sm md:text-lg"
                          >
                            À un enfant
                          </Typography>
                        }
                      />
                    </div>
                    <div className=" xl:ml-20 lg:ml-4">
                      <FormControlLabel
                        checked={forWho.friend === true}
                        onChange={() => handleFriend()}
                        sx={{ color: "#4d4d4d" }}
                        value={forWho.friend === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-sm md:text-lg"
                          >
                            À un proche
                          </Typography>
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            ) : user && child.length === 0 ? (
              <div className="ml-12 md:ml-0">
                <FormControl>
                  <RadioGroup row name="customized-radios">
                    <div className="font-nunito ">
                      <FormControlLabel
                        checked={forWho.me === true}
                        onChange={() => handleMe()}
                        sx={{ color: "#4d4d4d", fontFamily: "Nunito-Regular" }}
                        value={forWho.me === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-base md:text-lg"
                          >
                            À moi{" "}
                          </Typography>
                        }
                      />
                    </div>
                    <div className=" xl:ml-20 lg:ml-4 ">
                      <FormControlLabel
                        checked={forWho.myChild === true}
                        onChange={() => handleMyChild()}
                        sx={{ color: "#4d4d4d" }}
                        value={forWho.myChild === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-base md:text-lg"
                          >
                            À un enfant
                          </Typography>
                        }
                      />
                    </div>
                    <div className=" xl:ml-20 lg:ml-4">
                      <FormControlLabel
                        checked={forWho.friend === true}
                        onChange={() => handleFriend()}
                        sx={{ color: "#4d4d4d" }}
                        value={forWho.friend === true}
                        control={
                          <RadioButton border="1px solid #fff" bg="#219CFF" />
                        }
                        label={
                          <Typography
                            style={{ fontFamily: "Nunito" }}
                            className="text-base md:text-lg"
                          >
                            À un proche
                          </Typography>
                        }
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            ) : (
              <div className="lg:text-lg md:text-base text-sm text-grey md:w- w-[170%]  md:pl-0 pl-4 mx-auto font-nunito ">
                <p>Une demande de cours est faite pour une seule personne.</p>
              </div>
            )}
          </div>

          {!user ? (
            <div className="w-full -mt-12 md:-mt-0 ">
              {forWho.me === true && forWho.friend === false ? (
                <AddMe reload={reload} />
              ) : forWho.me === false && forWho.friend === true ? (
                <AddFriend reload={reload} />
              ) : (
                <AddChild reload={reload} />
              )}
              <div className="  translate-y-16 flex space-x-2 text-lg   w-2/3 mx-auto">
                {error.child === "null" || error.child === ""
                  ? " "
                  : validateChild(dataChild).icon}
                <p className="text-[#FF724F] h-6 w-full md:text-lg  text-sm ">
                  {error.child === "null" || error.child === ""
                    ? null
                    : validateChild(dataChild).message}
                </p>
              </div>
            </div>
          ) : user && child.length === 0 ? (
            <div className="-mt-12 md:-mt-0 ">
              {forWho.me === true ? (
                <AddMe reload={reload} />
              ) : forWho.friend === true ? (
                <AddFriend reload={reload} />
              ) : (
                <AddFriend reload={reload} />
              )}
              <div className="  translate-y-8 flex space-x-2  w-2/3 mx-auto">
                {error.child === "null" || error.child === ""
                  ? " "
                  : validateChild(dataChild).icon}
                <p className="text-[#FF724F] h-6 w-full md:text-lg  text-sm ">
                  {error.child === "null" || error.child === ""
                    ? null
                    : validateChild(dataChild).message}
                </p>
              </div>
            </div>
          ) : (
            <div className="font-nunito ">
              <div className="rounded-lg shadow-lg  //-mt-16 md:w-1/2 w-4/5 space-y-2 mx-auto">
                <div className="md:max-h-80  max-h-52 //bg-input-color overflow-y-scroll list-teacher rounded-lg">
                  <List component="nav" aria-label="main mailbox folders">
                    {child.map((val: any, index : any) => (
                      <div>
                        <ListItemButton
                          key={index}
                          selected={selectedIndex === val.id}
                          onClick={(event) => {
                            handleListItemClick(event, val.id);
                            handleSelect(val.firstName);
                            handleChange(val);
                          }}
                        >
                          <ListItemAvatar>
                            <div
                              className={
                                val.firstName === client?.firstName
                                  ? "rounded-full text-white bg-green-400 md:h-10 md:w-10 h-7 w-7 lg:text-lg md:text-base text-sm"
                                  : "rounded-full text-white bg-slate-300 md:h-10 md:w-10 h-7 w-7 lg:text-lg md:text-base text-sm"
                              }
                            >
                              <p
                                className={
                                  " text-center md:translate-y-2 font-nunito translate-y-1"
                                }
                              >
                                {val.firstName[0]}
                              </p>
                            </div>
                          </ListItemAvatar>

                          <ListItemText
                            className="lg:text-lg md:text-base text-sm font-nunito"
                            primary={
                              <p className="lg:text-lg md:text-base text-sm font-nunito md:translate-y-2 translate-y-1">
                                {val.firstName}
                              </p>
                            }
                          />
                        </ListItemButton>
                        {/* <div className="w-4/5 mx-auto border-b-2 border-b-slate-300 "></div> */}
                      </div>
                    ))}
                  </List>

                  {/* </Box> */}
                </div>
                <div>
                  <AddChild reload={reload} />
                </div>
              </div>

              <div className=" translate-y-8 flex space-x-2 text-lg   w-2/3 mx-auto">
                {error.child === "null" || error.child === ""
                  ? " "
                  : validateChild(dataChild).icon}
                <p className="text-[#FF724F] h-6 w-full md:text-lg  text-sm  ">
                  {error.child === "null" || error.child === ""
                    ? null
                    : validateChild(dataChild).message}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-16 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
            <div
              onClick={handleBack}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div
          className=
              "md:flex hidden xl:translate-y-28 lg:translate-y-12 md:translate-y-36   justify-around md:w-full mx-auto"  
        >
          <div className="flex space-x-2 justify-center">
            <button
              type="button"
              onClick={handleBack}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex space-x-2 justify-center ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className={
                error.child === "null"
                  ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  : error.child === ""
                  ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
              }
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormWhos;
