// import NavBarCominsoon from "../../../../Components/NavBarCominsoon";
import image from "../../../../Assets/Images/confpayment.png";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import NavBarCominsoon from "../../../../Components/Navbar/NavBarCominsoon";
// import DemandeComponentLayout from "../DemandeComponentLayout";
import { styled } from "@mui/material/styles";
// import NavBarCominsoon from "../../../Components/NavBarCominsoon";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import Paiement from "../Payment";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: any;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    // className='tab'
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#FF724F",
    heigth: "4px",
    borderRadius: "10px",
    border: " solid #FF724F",
  },
});

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: "none",
  marginRight: theme.spacing(1),
  fontFamily: "Nunito",
  color: "#0054A3",
  "&.Mui-selected": {
    color: "#FF724F",
  },
}));

const ConfirmationStep = () => {
  const [value, setValue] = useState(0);
  // const [loading, setLoading] = useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  const handleNext = () => {
    navigate("/user/response");
  };

  return (
    <div className="h-screen overflow-x-hidden list-teacher font-nunito">
      <div className="fixed bg-white z-40 w-full">
        <NavBarCominsoon />
        <div className="md:mx-10 mx-4">
          <Box
          // sx={{ borderBottom: 1, borderColor: "" }}
          >
            <StyledTabs value={value} onChange={handleChange}>
              <StyledTab
                label={
                  <p className="lg:text-lg md:text-base text-sm font-bold">
                    Paiement confirmé
                  </p>
                }
              />
            </StyledTabs>
          </Box>
        </div>
      </div>
      <div className="md:mx-10  mt-[100px] ">
        <Box sx={{ width: "100%" }}>
          <TabPanel value={value} index={0}>
            <div className=" mx-auto mt-20 gap-x-16 md:flex">
              <div className="  md:w-1/2">
                <div className="">
                  <p className="xl:text-4xl lg:text-3xl md:text-xl text-lg text-dark_blue font-extrabold ">
                    MERCI POUR VOTRE CONFIANCE !
                  </p>
                </div>
                <div className="md:mt-10 mt-5 ">
                  <p className="xl:text-2xl lg:text-xl md:text-lg text-base  text-dark_blue font-bold leading-32 ">
                    Votre cours a bien été réservé. <br />
                    Un e-mail de confirmation vous a été envoyé. <br />
                    Bon cours !
                  </p>
                </div>
                <div className='mt-10 translate-y-20 md:translate-y-0 mx-auto  '>
                  <button
                    onClick={() => handleNext()}
                    type="button"
                    className="border-2 md:w-1/2 mx-auto  px-4 //ml-64 border-dark_blue rounded-full py-2 uppercase text-dark_blue text-center text-sm lg:text-[17px] font-extrabold transition duration-150 ease-in-out"
                  >
                    MES DEMANDES DE COURS
                  </button>
                </div>
              </div>
              <div className="md:block hidden bg-no-repeat w-1/3">
                <img src={image} alt="" />
              </div>
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default ConfirmationStep;
