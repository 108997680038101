var config = {
    // API config
    uriPath: "https://teachr-api-prod.herokuapp.com",
    apiVersion: "1_23_0",

    firebase: {
        apiKey: "AIzaSyBfRczPSrIuWkGmHdP-tu2AK1c-xf5TN94",
        authDomain: "teach-r.firebaseapp.com",
        databaseURL: "https://teach-r.firebaseio.com",
        projectId: "teach-r",
        storageBucket: "teach-r.appspot.com",
        messagingSenderId: "17864051027"
    },

    firestoreMessagesCollection: "messages",
    adminId: 2070,
    premiumDisplay: 3,
    appVersion: "3.1.0",

    stripePublicKey: "pk_live_pvjuL4fM1mDatFqpDDZeGlHe",

    // DEBUG MODE
    debugMode: process.env.REACT_APP_BUILD_ENV === 'prod' ? false : true,
    
    
    phoneNumber: "0184802615",
    webHookSlackPromoCode: "https://hooks.slack.com/services/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",
    url_AE: "https://drive.google.com/drive/folders/16QJNmJUsPyqhqM53IpxJqfpOVRNJYGEV?usp=sharing",
    webHookFlaggedMessages: "https://hooks.slack.com/services/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",

};

if (config.debugMode) {
    let newConfig = config;
    newConfig.apiVersion = "1_23_0";
    newConfig.firestoreMessagesCollection = "messages_dev";
    newConfig.adminId = 5;
    newConfig.uriPath = "https://teachr-api-staging.herokuapp.com";
    newConfig.stripePublicKey = "pk_test_aTkKFKmFR3oCTGEppE2nxxA7";
    config = newConfig;
}



export default config;
