
import validator from "validator";
import cities from '../Assets/file/code_ville.json'
const validateZipCode = (value: string | null, city = "") => {
  const isCity = cities.filter((object) => {
    const searchCity = object.label.search(city)
    const searchZipCode = object.value.search(value!)

      return searchCity >= 0 && searchZipCode >=0
  })
  if (value) {
    if (!validator.isPostalCode(value, 'FR')) {
      return {
        isValid: false,
        message: "Veuillez entrer un code postal valide",
      };
    } else if (isCity.length <= 0) {
      return {
        isValid: false,
        message: "La ville et le code postal ne correspondent pas",
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  } else {
    return {
      isValid: false,
      message: "Veuillez entrer un code postal",
    };
  }
};

export default validateZipCode