import React, { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import load from "../../Assets/Images/Hourglass-3.svg";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Services/FirebaseService";
import logo from "../../Assets/Images/logo_bleu.png";
// import imageFond from "../../Assets/Images/Group (2).png";
import Image from "../../Assets/Images/logo_bleu.png";
// import Check from "@mui/icons-material/Check";

const TimerClient = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);
  const [user] = useAuthState(auth);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const location = useLocation();
  const [state] = useState(location.state);
  const handleHome = () => {
    if (user) {
      navigate("/user/response");
    } else {
      navigate("/");
    }
  };
  const renderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return navigate("/user/response");
    } else {
      // Render a countdown
      return (
        <div className="w-full mx-auto text-xl font-extrabold text-center lg:text-6xl md:text-3xl //text-center text-dark_blue">
          {zeroPad(minutes)} : {zeroPad(seconds)}
        </div>
      );
    }
  };
  return (
    <>
      <div className="box-border h-screen list-teacher //overflow-hidden lg:flex md:h-screen font-nunito">
        <div className="box-border fixed z-40 items-center justify-between w-full p-4 font-semibold bg-white lg:hidden">
          <div
            className="hidden mb-4 lg:block "
            onClick={() => handleHome()}
          >
            <img className="cursor-pointer w-[189px]" src={logo} alt="" />
          </div>
          <div className="mb-4 lg:hidden " onClick={() => handleHome()}>
            <img className="cursor-pointer w-[84px]" src={logo} alt="" />
          </div>
        </div>
        <div className="box-border hidden h-screen overflow-hidden bg-right-top bg-no-repeat shadow-xl bg-timerfond lg:w-1/3 bg-input-color lg:block text-md xl:text-xl">
          <div
            className="mt-4 cursor-pointer ml-14"
            onClick={() => handleHome()}
          >
            <img src={Image} alt="logo" className="w-32 " />
          </div>
          <div className="mt-20">
            <img src={load} alt="" className="mx-auto w-72" />
            <Countdown date={state + 300000} renderer={renderer} />
          </div>
        </div>
        <div className="h-screen overflow-x-hidden overflow-y-scroll md:list-teacher lg:w-2/3 md:mt-8 lg:mt-0">
          <div className="w-4/5 mx-auto mb-6 space-y-4 translate-y-20 md:translate-y-2">
            <div className="mt-8 ">
              <h5 className="lg:text-3xl md:text-2xl text-lg  text-dark_blue -//ml-8 //text-center //text-[#4d4d4d] font-bold">
                Veuillez patienter quelques instants
              </h5>
            </div>
            <div className="w-4/5 mx-auto md:hidden">
              <img src={load} alt="" className="w-24 mx-auto " />
              <Countdown date={state + 300000} renderer={renderer} />
            </div>
            <div className="//mt-4">
              <p className="lg:text-xl //text-center md:text-lg text-base text-[#4d4d4d] font-bold">
                Nous envoyons votre demande à nos 4000 professeurs, cela peut
                prendre un certain temps, mais vous êtes toujours notre priorité.{" "}
              </p>
            </div>
            <div className="">
              <div className="mx-auto ">
                <p className="lg:text-xl md:text-lg text-base text-dark_blue //text-center //text-[#4d4d4d] font-bold">
                  Quelques réponses à vos questions
                </p>
              </div>
              <div className="mx-auto mt-4 space-y-4 text-sm lg:text-lg md:text-base">
                <Accordion
                  expanded={expanded === "panel1"}
                  onChange={handleChange("panel1")}
                  sx={{backgroundColor:'white'}}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Quel est le prix d'un cours ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Lorsqu'un professeur répond à votre demande de cours, il{" "}
                      <strong>fixe son prix</strong> par heure de cours. Ce prix
                      est fixé selon le temps de préparation du cours, le niveau
                      de l'élève, et bien d'autres critères. Si vous avez
                      plusieurs réponses de professeurs, vous aurez alors{" "}
                      <strong>plusieurs prix proposés</strong>. Tous les prix
                      indiqués liés aux cours à domicile permettent d'obtenir le crédit d'impôt !
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Comment sont sélectionnés les professeurs qui répondent
                        à ma demande ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Une fois inscrits, les Teach'rs doivent passer un{" "}
                      <strong>entretien</strong> afin que leurs{" "}
                      <strong>aptitudes pédagogiques</strong> soient évaluées.
                      S'ils sont jugés suffisamment pédagogues pour faire
                      progresser un élève, leur compte est validé sur la
                      plateforme. À la fin du cours, vous pouvez{" "}
                      <strong>laisser un avis</strong> qui apparaîtra sur le
                      profil de l'enseignant. Grâce à Teach'r, les mauvaises
                      surprises, c'est enfin fini !
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel3"}
                  onChange={handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Comment confirmer un cours ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      À la fin du minuteur, vous aurez normalement des réponses
                      de professeurs intéressés. Choisissez celui qui vous
                      intéresse le plus, et confirmez le cours à l'aide de votre{" "}
                      <strong>carte bancaire.</strong>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Qu'est-ce que le crédit d'impôt ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Afin d'inciter les familles à <strong>déclarer</strong>{" "}
                      leurs heures de <strong>cours particuliers</strong>,
                      l'État a mis en place le
                      <strong>
                        {" "}
                        crédit d'impôt : un remboursement de 50%
                      </strong>{" "}
                      sur le montant total payé pour vos cours à domicile (dans
                      la limite de 12 000€ de dépenses annuelles).{"\n"}Vous
                      pouvez donc <strong>récupérer jusqu'à 6 000€ </strong>
                      lors de la déclaration annuelle de vos impôts.{"\n"}Ces
                      dépenses sont{" "}
                      <strong>
                        cumulables avec les autres services à la personne
                      </strong>{" "}
                      dont vous pourrez avoir besoin : ménage, baby-sitting,
                      petits travaux de bricolage, etc.
                      {"\n"}En plus, à partir de 2022, cette aide sera{" "}
                      <strong>mensualisée</strong> !
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Comment en bénéficier ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Pour profiter du crédit d'impôt, vous n'avez absolument
                      rien à faire,{" "}
                      <strong>nous nous occupons de tout ! </strong>
                      De l'envoie des factures aux déclarations auprès de
                      l'URSSAF, Teach'r prend tout à sa charge afin de vous
                      laisser le temps de vous concentrer sur l'essentiel :
                      <strong>
                        {" "}
                        la progression de votre enfant ou votre progression.
                      </strong>
                      {"\n"}Nous vous enverrons avant le 31 mars de l'année
                      suivante une déclaration vous indiquant le montant du
                      crédit d'impôt auquel vous avez droit.
                      {"\n"}Vous n’aurez plus qu’à inscrire ce montant dans
                      votre déclaration d’impôts en suivant les indications que
                      nous vous communiquerons lors de l’envoi de votre
                      attestation fiscale.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel6"}
                  onChange={handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Mon foyer n'est pas imposable, y ai-je tout de même
                        droit ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Le crédit d’impôt est une aide s’appliquant{" "}
                      <strong>
                        pour tous, même pour les familles qui ne payent pas
                        d’impôts
                      </strong>
                      . Dans ce cas, elle prend la forme d’un remboursement de
                      la part du trésor public pour 50% des sommes déboursées.
                      Exemple : vous avez payé 3 000€ de cours à domicile
                      pendant l’année 2020. En 2021,{" "}
                      <strong>l’État vous remboursera la moitié</strong>, soit 1
                      500€. C’est aussi simple que ça !
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === "panel7"}
                  onChange={handleChange("panel7")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                     sx={{backgroundColor:'#F2F5FB', color:'#0054A3'}}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography>
                      <span className="mx-auto mt-4 text-sm font-bold lg:text-lg md:text-base">
                        Comment suivre la progression d'un élève ?
                      </span>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Vous souhaitez suivre{" "}
                      <strong>
                        {" "}
                        la progression de votre enfant sur le long terme ou
                        votre progression tout au long des Séances :
                      </strong>{" "}
                      ? Consultez la <strong>fiche élève</strong>, document de
                      suivi rempli par chaque Teach’r après le cours.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TimerClient;
