import { IconButton } from "@mui/material";
import React from "react";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -4,
    top: 10,
    fontSize: "16px",
    backgroundColor: "#FF724F",
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "5px",
  },
}));

const Historique = (props: any) => {
  return (
    <div className="">
        <div className="lg:text-[24px] text-lg overflow-x-auto  hidden text-center lg:space-x-44 space-x-20 text-dark_blue font-bold md:flex">
          <div className="-mt-3">
            <IconButton aria-label="cart">
              <StyledBadge badgeContent={10} color="secondary">
                <p
                  //onClick={() => handleChangeToResponse()}
                  className="border-b-4 text-lg lg:text-[24px]  text-dark_blue border-b-orange lg:w-40 "
                >
                  Historique
                </p>
              </StyledBadge>
            </IconButton>
          </div>
        </div>
        <div className=" list-teacher overflow-x-scroll overflow-y-hidden w-full flex gap-x-10">
        {props.children}
        </div>
      </div>
  );
};

export default Historique;
