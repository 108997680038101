import validator from 'validator';
import errorIcon from "../Assets/Images/error.png";

const validatePassword = (password, cpassword) => {
    
    if (validator.isEmpty(password)) {
        return {
            isValid: false,
            message: 'Remplir ce champ',
            icon:<img src={errorIcon} alt="" className="w-4 h-4" />
        }
    }
    //  if (!validator.isStrongPassword(password ,{ minLength: 6, minLowercase: 0,
    //     minUppercase: 1, 
    //     minNumbers: 1, 
    //     minSymbols: 0, 
    //     returnScore: false })) {
    //         return {
    //             isValid: false,
    //             message: 'Votre mot de passe doit contenir au moins 6 caractères,1 lettre majuscule et 1 chiffre',
    //             icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    //         }
    // }
    if (validator.isEmpty(cpassword)) {
        return {
            isValid: false,
            message: 'Confirmez votre mot de passe',
            icon:<img src={errorIcon} alt="" className="w-4 h-4" />
        }
    }
    if (password !== cpassword) {
        return {
            isValid: false,
            message: 'Les mots de passe ne correspondent pas',
            icon:<img src={errorIcon} alt="" className="w-4 h-4" />
        }
    } else
        return {
            isValid: true,
            message: ''
        }
};

export default validatePassword