import Child from "./Child";
import Teachr from "./Teachr";
declare class PunctualCourse { }

declare class Address { }

export default class Charges {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    punctualCourse: string;
    amount: string
    client: string
    subscriptionCourse?: string;

    readonly id?: number;
    // readonly lastModificationDatetime?: Date;
    // readonly creationDatetime?: Date;
    //readonly children: Child[];

    // TODO see how to handle profilePicture

    constructor(
        punctualCourse: string,
        amount: string,
        client: string,
        subscriptionCourse?: string
    ) {
        this.punctualCourse = punctualCourse;
        this.amount = amount;
        this.client = client;
        this.subscriptionCourse = subscriptionCourse;
    }
}
