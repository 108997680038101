const SET_INFO = 'SET_AE_INFO';

const initialState = null

export default  function procurationAeReducers(state = initialState, action:any) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      default:
        return state
    }
  }