export default class TeachrShowInfo {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    isShowInfos: boolean

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    readonly creationDatetime?: Date;

    constructor(
        isShowInfos: boolean,
    ) {
        this.isShowInfos = isShowInfos;
    }

}
