import React, { useState } from 'react';

const SimulatorNumberInput = (props: any) => {
    const [value, setValue] = useState('')
    const handleChange = (valueInput: string) => {
        const regex = /\d+(\.\d+)?/g;
        const extrait = valueInput.match(regex)
        if (extrait) {
            setValue(extrait.join('')) ;
            props.callBack(extrait.join(''))
        }else{
            setValue('')
        }
    }
  
    return (
        <div className='border border-solid border-[rgba(128,128,128,0.33)] rounded-[10px] shadow-simulator-input px-4 py-2'>
            <label className='lg:text-lg whitespace-nowrap' htmlFor="">{props.label}{props.sup && <sup className='pl-2 text-third'>({props.sup})</sup>}</label>
            <input value={value} min={0} onChange={(e) => handleChange(e.target.value)} className='lg:text-[22px] text-[#808080] block w-full' type="number" placeholder={props.placeholder} />
        </div>
    );
};

export default SimulatorNumberInput;