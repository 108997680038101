import hour from "../../../Assets/Images/hour.png";
import calendar from "../../../Assets/Images/calender.png";
import lieu from "../../../Assets/Images/lieu.png";
// import Update from "../../../Screens/Client/Courses/Components/Update"
// import Delete from "../../../Screens/Client/Courses/Components/Delete";
import {  useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../Assets/Images/btn-close-orange.png";
// import WaitingResponse from "../Courses/Components/WaitingResponse";
import Response from "./Response";


const DemandeSubscription = () => {
    const [open, setOpen] = useState(false);
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
    const handleClose = () => {
      setOpen(false);
    };

  return (
    <div className="md:flex">
      <div className="md:mt-[74px] mt-10 pr-6 ">
        <div className= "rounded-lg md:w-[340px] w-full md:ml-0 ml-3 border-grey p-4 mb-20 shadow-res">
          <div className="">
            <p className="md:text-[20px]  text-dark_blue font-semibold">
            Cours régulier de Français
            </p>
            <div className="text-[16px] md:-translate-y-7 -translate-y-3 text-grey">
              <span>Pour Louna</span>
            </div>
          </div>
          <div className="space-y-4 ">
            <div className="flex space-x-4">
              <div>
                <img src={hour} alt="" />
              </div>
              <div className="translate-y-3">
                <p className="text-[16px]  text-dark_blue font-semibold">
                17h00
                </p>
              </div>
            </div>
            <div className="flex space-x-4">
              <div>
                <img src={calendar} alt="" />
              </div>
              <div className="translate-y-3">
                Samedi 22 juillet 2021
              </div>
            </div>
            <div className="flex space-x-4">
              <div className="">
                  <img src={lieu} alt="" />
              </div>
              <div className="">
                <p className="text-[16px] pl-4 translate-y-2 text-dark_blue font-semibold">
                 Grand chariot, Vauréal
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-around md:py-4 md:translate-y-0 translate-y-6 ">
            <div>
              {/* <Update  /> */}
            </div>
            <div>
              {/* <Delete  /> */}
            </div>
          </div>
          <div>
            <div className="font-nunito md:hidden flex">
              <span className="text-dark_blue w-full" >
                <div className=" translate-y-12 shadow-shadow-button text-center bg-white border-[1px] p-2 text-[16px] text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
                  Réponses
                </div>
              </span>
              <Dialog open={open} onClose={handleClose}>
                <div className="w-[295px] overflow-x-hidden">
                  <DialogTitle>
                    <div className=" flex fixed  mb-3">
                      <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                        Mes réponses
                      </span>
                      <img
                        src={close}
                        alt=""
                        className="md:h-6 h-3 md:w-6 w-3 translate-y-1 translate-x-32  "
                        onClick={handleClose}
                      />
                    </div>
                  </DialogTitle>
                  <DialogContent>
                   Reponse
                  </DialogContent>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
        <div className="list-teacher overflow-x-scroll overflow-y-hidden w-full mt-[30px] p-10 md:flex gap-10 hidden">
          <Response/>
          <Response/>
          <Response/>
          <Response/>
          <Response/>
          <Response/>
          <Response/>
          <Response/>
        </div>
    </div>
  );
};

export default DemandeSubscription;
