import HydraEntity from "./HydraEntity";
import "reflect-metadata";

export default class ReviewTeachr extends HydraEntity {

    studentRemarks?: string;
    note?: number;
    owner?:{
        firstName: string, 
        lastName: string, 
        id: number,
        averageNote: string
    };
    id?: number;
}
