import React, { useEffect, useState } from "react";

export default function Input(props) {
  const [value, setValue] = useState('')
  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue)
    }
  }, [props.defaultValue])
  return (
    <div>
      {props.inset ? null : (
        <label htmlFor="" className="block mb-3 text-[20px] text-white">
          {props.label}
        </label>
      )}
      <div className={
        " text-[#00000] relative rounded-lg px-4 " +
        props.customClass
      }>
        <div className="flex items-center justify-between">
          <div className="w-full">
            {props.inset ? <label className="" htmlFor="">{props.label}</label> : null}
            <input onChange={(e) => props.callback(e.target.value)} type={props.type} defaultValue={value} className={' border-none focus:outline-none block bg-transparent w-full font-normal'} placeholder={props.placeholder}
            />
          </div>
          {props.icon}
        </div>
      </div>
    </div>
  );
}
