import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import iconTime from "../../../../../Assets/Images/checkurgence.png";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../../Assets/Images/checkclose.png";
// import { useNavigate } from "react-router-dom";
import CoursPonctuel from "../CoursPonctuel";
// import useState from 'react';

export default function Index(props:any) {
  const [open, setOpen] = React.useState(false);
// const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {handleNext, handleBack} = props

  return (
    <div className="font-nunito">
      <span className="text-dark_blue " onClick={handleClickOpen}>
      <img src={iconTime} alt="calendar icon" className="" />
      </span>
      <Dialog open={open} onClose={handleClose}>
        <div className="border-dark_blue border-1 p-3">
        <DialogTitle>
          <div className="flex justify-between mb-3">
            <span className="text-dark_blue font-extrabold uppercase font-nunito lg:text-[22px] md:text-lg text-base">
            Qu'est-ce qu'un cours dans l'heure ? 
            </span>
            <img
              src={close}
              alt=""
              className="md:w-6 w-6 md:h-6 h-6 //translate-y-2"
              onClick={handleClose}
            />
          </div>
        </DialogTitle>
        <DialogContent>
          <p className="text-[#4d4d4d] font-nunito lg:text-lg md:text-base text-sm">
          Vous avez demandé un cours en urgence : vous ne pouvez pas changer la date du cours.
Cependant, si vous préférez un cours pour un autre jour, demandez plutôt un cours ponctuel.
          </p>
        </DialogContent>
        <DialogActions>
          <div className="md:w-1/2 w-full mb-4 mx-auto  " onClick={()=>{props.currentComponent(3, <CoursPonctuel handleNext={handleNext} handleBack={handleBack}/>)}}>
            <button className=" bg-white rounded-full py-2 text-dark_blue border-[2px] font-extrabold  shadow-shadow-button uppercase px-4 w-full hover:bg-dark_blue hover:text-white hover:border-white active:bg-dark_blue active:text-white active:border-white">
            Je préfère un autre jour
            </button>
          </div>
        </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
