import React from "react";
import AddStudent from "../AddStudent/AddStudent";


const StepDeclare = (props: any) => {
  const {handleChangeStep} = props

  return (
    <div className="overflow-y-auto list-teacher relative  h-[100%] bg-white xl:px-14 px-6 md:py-6 py-6 rounded-lg shadow-dashboard-setup-intro-form">
      <div className="space-y-8">
        <div className="xl:w-1/2 lg:w-2/3">
          <p className="underline xl:underline-offset-[16px] underline-offset-[8px] text-dark_blue font-extrabold lg:text-2xl  text-xl">
            Déclaration d’un cours ✏️
          </p>
        </div>
        <div className= "mt-6">
          <p className="text-[#4D4D4D] lg:text-lg md:text-base text-base font-bold md:w-2/3 ">
            Dans cette section, vous pouvez déclarer tous les cours dispensés{" "}
            aux élèves pour le mois actuel, ou bien déclarer un cours
            spécifique qui a eu lieu de manière ponctuelle.
          </p>
        </div>
        <div className="xl:mt-4 mt-2">
          <p className="text-dark_blue lg:text-lg md:text-base text-base font-bold">
            Pour quel élève souhaitez-vous déclarer un cours ?{" "}
          </p>
        </div>
        <AddStudent handleChangeStep={handleChangeStep} />
      </div>
    </div>
  );
};

export default StepDeclare;
