import { Link } from "@mui/material";
import React, {  useState } from "react";
import Button from "../utils/button";
import logo_blanc from "../../Assets/Images/logo_blanc.svg";
import logobleu from "../../Assets/Images/logo_bleu.png"
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../Services/FirebaseService";
import ApiService from "../../Services/ApiService";
import { signOut } from "firebase/auth";
import { useDispatch } from "react-redux";
function NavBar(props: any) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate()
  const [user] = useAuthState(auth)
  const dispatch = useDispatch()
   
  const logout = () => {
    signOut(auth).then(() => {
      ApiService.disableAuth()
      dispatch({
        type: 'DELETE_TEACHR_INFO'
    })
      navigate("/")
    }).catch((error) => {
    });
  }
 


  return (
    <div>
      {props.theme === "dark" ? (
        <div className="bg-[#fff] lg:bg-transparent p-4 transition-['max-height'] ease-in-out duration-500">
          <div className="flex items-center justify-between font-semibold md:p-4">
            <div className="hidden lg:block">
              <img className="cursor-pointer w-[129px]" onClick={() => navigate('/')} src={logo_blanc} alt="" />
            </div>
            <div className="lg:hidden">
              <img className="cursor-pointer w-[84px]" onClick={() => navigate('/')} src={logobleu} alt="" />
            </div>
            <div className="lg:hidden">
              <MenuIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></MenuIcon>
            </div>
            <div className="items-center hidden gap-5 lg:flex">
              <Link
                href="/"
                underline="none"
                className="font-semibold text-white"
              >
                {" "}
                ACCUEIL
              </Link>
              <Link
                href="/teachr/cours"
                underline="none"
                className="block font-semibold text-white"
              >
                {" "}
                DONNER DES COURS
              </Link>
              <Link
                href="/user/formlayout"
                underline="none"
                className="font-semibold text-white"
              >
                {" "}
                PRENDRE DES COURS
              </Link>
              <Link
                href="https://www.teachr.fr/"
                underline="none"
                className="font-semibold text-white"
              >
                {" "}
                NOS SERVICES
              </Link>
              {user && <Link
                onClick={() => logout()}
                underline="none"
                className="font-semibold text-white uppercase"
              >
                {" "}
                DéCONNEXION
              </Link>}
              <Button custom_class="rounded-full bg-white font-semibold text-first">
                Télécharger l'app
              </Button>
            </div>
          </div>
          {toggle ? (
            <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
              <Link
                href="/"
                underline="none"
                className="font-semibold text-first"
              >
                {" "}
                ACCUEIL
              </Link>
              <Link
                href="/teachr/cours"
                underline="none"
                className="font-semibold text-first"
              >
                {" "}
                DONNER DES COURS
              </Link>
              <Link
                href="/user/formlayout"
                underline="none"
                className="font-semibold text-first"
              >
                {" "}
                PRENDRE DES COURS
              </Link>
              <Link
                href="https://www.teachr.fr/"
                underline="none"
                className="font-semibold text-first"
              >
                {" "}
                NOS SERVICES
              </Link>
              {user && <Link
                onClick={() => logout()}
                underline="none"
                className="font-semibold uppercase text-first"
              >
                {" "}
                DéCONNEXION
              </Link>}
              <Button custom_class="rounded-full  bg-first font-semibold text-white">
                Télécharger l'app
              </Button>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="bg-[#fff] lg:bg-transparent  transition-['max-height'] ease-in-out duration-500">
          <div className="flex items-center justify-between p-4 font-semibold">
            <div className="hidden lg:block">
              <img className="cursor-pointer w-[129px]" onClick={() => navigate('/')} src={logobleu} alt="" />
            </div>
            <div className="lg:hidden">
              <img className="cursor-pointer w-[84px]" onClick={() => navigate('/')} src={logobleu} alt="" />
            </div>
            <div className="lg:hidden">
              <MenuIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              ></MenuIcon>
            </div>
            <div className="items-center hidden gap-5 lg:flex">
              <Link
                href="/"
                underline="none"
                className="font-semibold text-first"
              >
                ACCUEIL
              </Link>
              <Link
                href="/teachr/cours"
                underline="none"
                className="block font-semibold text-first"
              >
                DONNER DES COURS
              </Link>
              <Link
                href="/user/formlayout"
                underline="none"
                className="font-semibold text-first"
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="https://www.teachr.fr/"
                underline="none"
                className="font-semibold text-first"
              >
                NOS SERVICES
              </Link>
              {user && <Link
                onClick={() => logout()}
                underline="none"
                className="font-semibold uppercase text-first"
              >
                DéCONNEXION
              </Link>}
              <Button custom_class="rounded-full bg-first font-semibold text-white">
                Télécharger l'app
              </Button>
            </div>
          </div>
          {toggle ? (
            <div className="flex flex-col items-center gap-3 py-4 lg:hidden lg:flex-row">
              <Link
                href="/"
                underline="none"
                className="font-semibold text-first"
              >
                ACCUEIL
              </Link>
              <Link
                href="/teachr/cours"
                underline="none"
                className="font-semibold text-first"
              >
                DONNER DES COURS
              </Link>
              <Link
                href="/user/formlayout"
                underline="none"
                className="font-semibold text-first"
              >
                PRENDRE DES COURS
              </Link>
              <Link
                href="https://www.teachr.fr/"
                underline="none"
                className="font-semibold text-first"
              >
                NOS SERVICES
              </Link>
              {user && <Link
                onClick={() => logout()}
                underline="none"
                className="font-semibold uppercase text-first"
              >
                DéCONNEXION
              </Link>}

              <Button custom_class="rounded-full bg-first font-semibold text-white">
                Télécharger l'app
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}

export default NavBar;
