import { Type } from "class-transformer";
import "reflect-metadata";
import HydraEntity from "./HydraEntity";

export class SubscriptionCourseProposal extends HydraEntity {

    subscriptionCourse_id: number;

    teachr_id: number;

    isRefused?: boolean;

    isDeleted?: boolean;

    teachrPrice?: string;

    totalWeeklyPrice?: string;

    totalDailyPrice?: string;

    description?: string;

    acceptedProposal?: number;

    forChild?: number;

    forClient?: number;

    directAskedTeachr?: number;

    number?: number; // used to blur cards is non premium account in AnswerCardSubscriptionCourse

    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    
    isFromTeachr?: boolean;

    constructor(
        subscriptionCourse_id: number,
        teachr_id: number,
        teachrPrice: string,
        description?: string,
    ) {
        super();
        this.subscriptionCourse_id = subscriptionCourse_id;
        this.teachr_id = teachr_id;
        this.description = description;
        this.teachrPrice = teachrPrice;
    }
}
