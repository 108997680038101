import React, { useEffect, useState } from "react";
import StepperStore from "../../../LocalForage/StepperStore";
import Functions from "../../../Helpers/Functions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../Services/FirebaseService";
import ClientRepository from "../../../Repositories/ClientRepository";
import PunctualCourseRepository from "../../../Repositories/PunctualCourseRepository";
import { PunctualCourse } from "../../../Objects/models/PunctualCourse";
import moment from "moment";
import { SubscriptionCourse } from "../../../Objects/models/SubscriptionCourse";
import SubscriptionCourseRepository from "../../../Repositories/SubscriptionCourseRepository";
import { CoursePack } from "../../../Objects/models/CoursePack";
import CoursePackRepository from "../../../Repositories/CoursePackRepository";
// import ChildRepository from "..:/../../Repositories/ChildRepository";
import { useNavigate } from "react-router-dom";
import { APIErrorEnum } from "../../../Objects/enums/APIErrorEnum";
// import ApiService from "../../../Services/ApiService";
import errorIcon from "../../../Assets/Images/error.png";
import { Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";

const CourseConfirmation = (props: any) => {
  const date = Date.now()
  const { handleNext, handleBack } = props;
  const position = ["domicile", "distance"];
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  let timerInterval: any;
  const [datas, setDatas] = useState<any>({});
  const [datasUserDisconnect, setDatasUserDisconnect] = useState<any>({});
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [terms, setTerms] = useState(false);
  const [error, setError] = useState({
    terms: "null",
    termsTeachr: "null",
  });



  const getConfirmationUserDisconnect = async () => {
    try {
      //const formMatiere: any = await StepperStore.getItem(user ? "formMatiere"+user.uid : "formMatiere")
      const child: any = await StepperStore.getItem(
        user ? "formWhos" + user.uid : "formWhos"
      );
      const formMatiere: any = await StepperStore.getItem(
        user ? "formMatiere" + user.uid : "formMatiere"
      );
      const formLocation: any = await StepperStore.getItem(
        user ? "formLocation" + user?.uid : "formLocation"
      );
      const courseType: any = await StepperStore.getItem(
        user ? "courseType" + user.uid : "courseType"
      );
      const who: any = await StepperStore.getItem(
        user ? "formWho" + user.uid : "formWho"
      );
      const addresse: any = await StepperStore.getItem("addresse");

      if (
        addresse !== null &&
        child !== null &&
        formMatiere !== null &&
        formLocation !== null &&
        courseType !== null &&
        who !== null
      ) {
        setDatasUserDisconnect({
          matiere: formMatiere.matiere.fr_FR,
          adresse:
            addresse.addresseObject.streetName +
            ", " +
            addresse.addresseObject.city,
          adresses: addresse.addresseObject.formattedAddress,
          firstName: child.firstName,
          isHome: formLocation.position.isHome,
          isRemote: formLocation.position.isRemote,
          isNoPreference: formLocation.position.isNoPreference,
          type: courseType.type,
          rangeNumber: courseType.data.rangeNumber * 2 + "",
          date: courseType.data.date,
          dates: courseType.data.dates,
          hour: courseType.data.startTime,
          day: courseType.data.day,
          startTime: courseType.data.startTime,
          endTime: courseType.data.endTime,
          firstNames: who.add.firstName,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const getConfirmationCourse = async () => {
    try {
      const formMatiere: any = await StepperStore.getItem(
        user ? "formMatiere" + user.uid : "formMatiere"
      );
      const formLocation: any = await StepperStore.getItem(
        user ? "formLocation" + user?.uid : "formLocation"
      );
      const courseType: any = await StepperStore.getItem(
        user ? "courseType" + user.uid : "courseType"
      );
      const who: any = await StepperStore.getItem(
        user ? "formWho" + user.uid : "formWho"
      );

      if (
        formMatiere !== null &&
        formLocation !== null &&
        courseType !== null
      ) {
        setDatas({
          matiere: formMatiere.matiere.fr_FR,
          isHome: formLocation.position.isHome,
          isRemote: formLocation.position.isRemote,
          isNoPreference: formLocation.position.isNoPreference,
          addresse: formLocation.add.streetName + ", " + formLocation.add.city,
          addresses: formLocation.add.formattedAddress,
          type: courseType.type,
          rangeNumber: courseType.data.rangeNumber * 2 + "",
          date: courseType.data.date,
          dates: courseType.data.dates,
          hour: courseType.data.startTime,
          day: courseType.data.day,
          startTime: courseType.data.startTime,
          endTime: courseType.data.endTime,
          firstNames: who.add.firstName,
          childWithId: who.add.id,
        });
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const validateTerms = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez accepter la politique des données",
        icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
      };
    } else
      return {
        isValid: true,
        message: "",
        icon: "",
      };
  };
  const validateTermsTeachr = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez accepter les GCUs et CGVs",
        icon: <img src={errorIcon} alt="" className="w-4 h-4" />,
      };
    } else
      return {
        isValid: true,
        message: "",
        icon: "",
      };
  };

  const createPackCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        // const jwtToken = await getJWTToken(user);
        // ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          // const getChild = await ChildRepository.getChildren(clientWithId.id);
          // const childWithId = getChild[0].id;
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const forChild_id = datas.childWithId
          const forClient_id = datas.childWithId
          const address_id = formLocation.add.id;
          const subjectAsked_id = formMatiere.matiere.id;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.dates;
          const durationPerDay = (datas.rangeNumber).toString();
          const isRemote = datas.isRemote;
          const isNoPreference = datas.isNoPreference;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address_id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isNoPreference === true ? true : isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);


          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const createPackCourseIsNoPrefenceFalse = async () => {
    try {
      setLoad(true)
      if (user) {
        // const jwtToken = await getJWTToken(user);
        // ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          // const getChild = await ChildRepository.getChildren(clientWithId.id);
          // const childWithId = getChild[0].id;
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const forChild_id = datas.childWithId
          const forClient_id = datas.childWithId
          const address_id = formLocation.add.id;
          const subjectAsked_id = formMatiere.matiere.id;
          const paymentMean = "CASH";
          const extraInformation = "";
          const dates = datas.dates;
          const durationPerDay = (datas.rangeNumber).toString();
          const isRemote = datas.isRemote;
          // const isNoPreference = datas.isNoPreference;
          const price = 200;

          const packCourse = new CoursePack(
            forChild_id,
            forClient_id,
            address_id,
            subjectAsked_id,
            extraInformation,
            dates,
            paymentMean,
            durationPerDay,
            isRemote,
            price
          );
          await CoursePackRepository.createCoursePack(packCourse);
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const [load, setLoad] = useState(false)

  const createSubscriptionCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          // const allChildrenCollection = await ChildRepository.getChildren(
          //   clientWithId.id
          // );
          // const childWithId = allChildrenCollection[0].id;
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const subjectAsked_id = formMatiere.matiere.id;
          const isRemote = datas.isRemote;
          const address_id = formLocation.add.id;
          const daysOfTheWeek = datas.day;
          const durationPerDay = (datas.rangeNumber).toString()
          const paymentMean = "CASH";
          const forChild_id = datas.childWithId;
          const forClient_id = datas.childWithId;
          const extraInformation = "";
          let startTime = moment(datas.hour).format("HH:mm");
          let startDate = moment(datas.date).format("Y-MM-DD");
          const isNoPreference = datas.isNoPreference;
          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isNoPreference === true ? true : isRemote,
            address_id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );
            
            
          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            clientWithId.id
          );

          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const createSubscriptionCourseIsNoPrefenceFalse = async () => {
    try {
      setLoad(true)
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const subjectAsked_id = formMatiere.matiere.id;
          const isRemote = datas.isRemote;
          const address_id = formLocation.add.id;
          const daysOfTheWeek = datas.day;
          const durationPerDay = (datas.rangeNumber).toString();
          const paymentMean = "CARD";
          const forChild_id = datas.childWithId;
          const forClient_id = datas.childWithId
          const extraInformation = "";
          let startTime = moment(datas.hour).format("HH:mm");
          let startDate = moment(datas.date).format("Y-MM-DD");

          const subscriptionCourse = new SubscriptionCourse(
            subjectAsked_id,
            isRemote,
            address_id,
            daysOfTheWeek,
            startDate,
            startTime,
            durationPerDay,
            paymentMean,
            forChild_id,
            forClient_id,
            extraInformation
          );
          await SubscriptionCourseRepository.createSubscriptionCourse(
            subscriptionCourse,
            clientWithId.id
          );
        }
      }
    } catch (error) {
      setLoad(false)
      console.warn(error);
    }
  };
  const inCourseInTheHour = async () => {
    try {
      setLoad(true)
      if (user) {
        // const jwtToken = await getJWTToken(user);
        // ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          // const getChild = await ChildRepository.getChildren(clientWithId.id);

          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const paymentMean = "CARD";
          const address = formLocation.add["@id"];
          const duration = (datas.rangeNumber).toString();
          const subjectAsked = formMatiere.matiere["@id"];
          const isNoPreference = datas.isNoPreference;
          const isCourseInTheHour = true;
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + datas.childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + datas.childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          // let startTime = moment(datas.startTime);
          let endTime = moment(datas.endTime);
          let startDate = moment(new Date().toDateString());
          // let startDateTime = startDate
          //   .set("hour", startTime.hours())
          //   .set("minutes", startTime.minutes())
          //   .toDate();

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = isNoPreference === true ? true : datas.isRemote;

          await PunctualCourseRepository.createPunctualCourse(course);


          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setLoad(false)
      // await setIsLoading(false);
      // await ApiService.disableAuth();
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
      }
    }
  };
  const inCourseInTheHourIsNoPrefenceFalse = async () => {
    try {
      setLoad(true)
      if (user) {
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const paymentMean = "CARD";
          const address = formLocation.add["@id"];
          const duration = (datas.rangeNumber).toString();
          const subjectAsked = formMatiere.matiere["@id"]
          // const isNoPreference = datas.isNoPreference;
          const isCourseInTheHour = true;
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + datas.childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + datas.childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          // let startTime = moment(datas.startTime);
          let endTime = moment(datas.endTime);
          let startDate = moment(new Date().toDateString());
          // let startDateTime = startDate
          //   .set("hour", startTime.hours())
          //   .set("minutes", startTime.minutes())
          //   .toDate();

          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,

            isFiscalReductionEnabled,
            isCourseInTheHour,
            forClient,
            false,
            chapter,
            moment().add(50, "seconds").toDate(),
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = datas.isRemote;

          await PunctualCourseRepository.createPunctualCourse(course);
        }
      }
    } catch (error: any) {
      setLoad(false)
      // await setIsLoading(false);
      // await ApiService.disableAuth();
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Quelque chose s'est mal passée lors de la demande, Veuillez revoir votre demande.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
      }
    }
  };
  const createPonctualCourse = async () => {
    try {
      setLoad(true)
      if (user) {
        // const jwtToken = await getJWTToken(user);
        // ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();

        if (clientWithId.id) {
          // const getChild = await ChildRepository.getChildren(clientWithId.id);
          // const childWithId = getChild[0].id;
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const address = formLocation.add["@id"];
          const paymentMean = "CARD";
          const duration = (datas.rangeNumber).toString();
          const isNoPreference = datas.isNoPreference;
          const subjectAsked = formMatiere.matiere["@id"];
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + datas.childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + datas.childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          let startTime = moment(datas.startTime);
          let endTime = moment(datas.endTime);
          let startDate = moment(datas.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = isNoPreference === true ? true : datas.isRemote;
          await PunctualCourseRepository.createPunctualCourse(course);


          navigate('/user/timer', {
            state: date
          })
          await StepperStore.clear();
        }
      }
    } catch (error: any) {
      setLoad(false)
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };
  const createPonctualCourseIsNoPrefenceFalse = async () => {
    try {
      setLoad(true)
      if (user) {
        // const jwtToken = await getJWTToken(user);
        // ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();

        if (clientWithId.id) {
          // const getChild = await ChildRepository.getChildren(clientWithId.id);
          // const childWithId = getChild[0].id;
          const formMatiere: any = await StepperStore.getItem(
            user ? "formMatiere" + user.uid : "formMatiere"
          );
          const formLocation: any = await StepperStore.getItem(
            user ? "formLocation" + user?.uid : "formLocations"
          );
          const address = formLocation.add["@id"];
          const paymentMean = "CARD";
          const duration = (datas.rangeNumber).toString();
          // const isNoPreference = datas.isNoPreference;
          const subjectAsked = formMatiere.matiere["@id"];
          const forClient = clientWithId.isParent
            ? undefined
            : "/children/" + datas.childWithId;
          const forChild = clientWithId.isParent
            ? "/children/" + datas.childWithId
            : undefined;
          const isFiscalReductionEnabled = false;
          const chapter = "";
          const directAskedTeachr = 2;
          let startTime = moment(datas.startTime);
          let endTime = moment(datas.endTime);
          let startDate = moment(datas.date);
          let startDateTime = startDate
            .set("hour", startTime.hours())
            .set("minutes", startTime.minutes())
            .toDate();
          let endDateTime = startDate
            .set("hour", endTime.hours())
            .set("minutes", endTime.minutes())
            .toDate();
          const course = new PunctualCourse(
            forChild,
            address,
            duration,
            endDateTime,
            false,
            false,
            forClient,
            isFiscalReductionEnabled,
            chapter,
            startDateTime,
            subjectAsked,
            paymentMean,
            directAskedTeachr
          );
          course.isRemote = datas.isRemote;
          await PunctualCourseRepository.createPunctualCourse(course);
        }
      }
    } catch (error: any) {
      setLoad(false)
      switch (error.description) {
        case APIErrorEnum.PUNCTUAL_COURSE_RANGE_NOT_CORRESPONDING:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours est supérieure à la plage horaire définie.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_CLIENT_MUST_HAVE_AT_LEAST_ONE_ADDRESS:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous devez définir au moins une adresse dans votre compte.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_PENDING_RESERVATIONS_LIMIT_REACHED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous avez déjà trop de demandes de cours en attente.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MAX:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas dépasser les 12 heures.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DURATION_LIMIT_MIN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "La durée du cours ne peut pas être inférieure à 1 heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_NOT_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il ne s'agit pas d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_IS_COURSE_IN_THE_HOUR:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Il s'agit d'un cours dans l'heure.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;
        case APIErrorEnum.PUNCTUAL_COURSE_DIRECT_COURSE_NOT_AUTHORIZED:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous n'êtes pas autorisé à demander un cours directement à ce Teach'r.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };

  const validate = () => {
    let errterms = true;
    let errtermsTeachr = true;
    if (!validateTerms(terms).isValid) {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errterms = true;
    } else {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errterms = false;
    }
    if (!validateTermsTeachr(termsTeachr).isValid) {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errtermsTeachr = true;
    } else {
      setError({
        terms: validateTerms(terms).message,
        termsTeachr: validateTermsTeachr(termsTeachr).message,
      });
      errtermsTeachr = false;
    }

    if (errterms || errtermsTeachr) {
      return false;
    } else {
      return true;
    }
  };
  useEffect(() => {
    getConfirmationCourse();
    getConfirmationUserDisconnect();
  }, [datas]);
  const handleSubmit = () => {
    if (user) {
      if (datas.type === "Régulier") {
        if (datas.isNoPreference === true) {
          (async () => {
            await createSubscriptionCourseIsNoPrefenceFalse();
            await createSubscriptionCourse();
          })();
        } else {
          createSubscriptionCourse();
        }
      } else if (datas.type === "Ponctuel") {
        if (datas.isNoPreference === true) {
          (async () => {
            await createPonctualCourseIsNoPrefenceFalse();
            await createPonctualCourse();
          })();
        } else {
          createPonctualCourse();
        }
      } else if (datas.type === "Urgence") {
        if (datas.isNoPreference === true) {
          (async () => {
            await inCourseInTheHourIsNoPrefenceFalse();
            await inCourseInTheHour();
          })();
        } else {
          inCourseInTheHour();
        }
      } else if (datas.type === "Stage") {
        if (datas.isNoPreference === true) {
          (async () => {
            await createPackCourseIsNoPrefenceFalse();
            await createPackCourse();
          })();
        } else {
          createPackCourse();
        }
      }
    } else if (validate()) {
      StepperStore.setItem(
        "registerTerms",
        {
          terms: terms,
          termsTeachr: termsTeachr,
        },
        function () { }
      );
      handleNext();
    }
  };

  return (
    <div className="-mt-4 space-y-2 translate-y-32 lg:space-y-6 md:space-y-4 lg:translate-y-10 md:translate-y-20">
      <div className="w-2/3 mx-auto ">
        <h5 className="text-lg font-bold text-dark_blue lg:text-3xl md:text-2xl">
          Confirmation :
        </h5>
        <div className="-translate-y-2 text-[#4d4d4d] md:text-[20px]">
          <span>Veuillez vérifier vos informations</span>
        </div>
      </div>
      <div className="w-2/3 mx-auto space-y-4">
        {user ? (
          <div className="flex space-x-2">
            <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Matière :</span>
            <span className="text-sm truncate lg:text-lg md:text-base text-dark_blue">
              {datas.matiere}
            </span>
          </div>
        ) : (
          <div className="flex space-x-2">
            <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Matière :</span>
            <span className="text-sm truncate lg:text-lg md:text-base text-dark_blue">
              {datasUserDisconnect.matiere}
            </span>
          </div>
        )}

        {user &&
          (datas.type === "Régulier" ||
            datas.type === "Ponctuel" ||
            datas.type === "Urgence" ? (
            <div className="flex space-x-2">
              <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Durée :</span>
              <span className="text-sm lg:text-lg md:text-base text-dark_blue">
                {Functions.renderDuration(datas.rangeNumber)}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.type === "Régulier" ||
          datasUserDisconnect.type === "Ponctuel" ||
          datasUserDisconnect.type === "Urgence" ? (
          <div className="flex space-x-2">
            <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Durée :</span>
            <span className="text-sm lg:text-lg md:text-base text-dark_blue">
              {Functions.renderDuration(datasUserDisconnect.rangeNumber)}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="flex space-x-2">
          <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Cours :</span>
          <span className="text-sm lg:text-lg md:text-base text-dark_blue">
            {datas.isNoPreference || datasUserDisconnect.isNoPreference
              ? "Pas de préférence"
              : datas.isHome || datasUserDisconnect.isHome
                ? "À " + position[0]
                : "À " + position[1]}
          </span>
        </div>

        <div className="flex space-x-2">
          <span className="font-bold lg:text-lg md:text-base text-sm text-[#4d4d4d]">Type de cours :</span>
          <span className="text-sm lg:text-lg md:text-base text-dark_blue">
            {user ? datas.type : datasUserDisconnect.type}
          </span>
        </div>

        {user &&
          (datas.type === "Ponctuel" ||
            datas.type === "Ponctuel" ? (
            <div className="flex space-x-2">
              <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Date/Heure :</span>
              <span className="text-sm lg:text-lg md:text-base text-dark_blue">
                {moment(datas.date).format("dddd DD MMMM YYYY")} à {" "}
                {Functions.renderTime(datas.startTime)}
              </span>
            </div>
          ) : (
            ""
          ))}

        {datasUserDisconnect.type === "Ponctuel" ? (
          <div className="flex space-x-2">
            <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">Date/Heure :</span>
            <span className="hidden text-sm lg:text-lg md:text-base md:flex text-dark_blue">
              {moment(datasUserDisconnect.date).format("dddd DD MMMM YYYY")} à{" "}
              {Functions.renderTime(datasUserDisconnect.startTime)}
            </span>
            <span className="text-sm lg:text-lg md:text-base md:hidden text-dark_blue">
              {moment(datasUserDisconnect.date).format("dddd DD/MM")} à{" "}
              {Functions.renderTime(datasUserDisconnect.startTime)}
            </span>
          </div>
        ) : (
          ""
        )}
        {user &&
          (datas.type === "Régulier" ? (
            <div className="flex space-x-2">
              <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
              <span className="hidden text-sm lg:text-lg md:text-base md:flex text-dark_blue">
                {moment(datas.date).format("dddd DD MMMM YYYY")} à{" "}
                {Functions.renderTime(datas.hour)}
              </span>
              <span className="text-sm lg:text-lg md:text-base md:hidden text-dark_blue">
                {moment(datas.date).format("dddd DD/MM")} à{" "}
                {Functions.renderTime(datas.hour)}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.type === "Régulier" ? (
          <div className="flex space-x-2">
            <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
            <span className="hidden text-sm lg:text-lg md:text-base md:flex text-dark_blue">
              {moment(datas.date).format("dddd DD MMMM YYYY")} à{" "}
              {Functions.renderTime(datas.hour)}
            </span>
            <span className="text-sm lg:text-lg md:text-base md:hidden text-dark_blue">
              {moment(datas.date).format("dddd DD/MM")} à{" "}
              {Functions.renderTime(datas.hour)}
            </span>
          </div>
        ) : (
          ""
        )}

        {user &&
          (datas.type === "Stage" ? (
            <div className="flex space-x-2">
              <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
              <span className="hidden text-sm lg:text-lg md:text-base md:flex text-dark_blue">
                {moment(datas.date).format("dddd DD MMMM YYYY")}
              </span>
              <span className="text-sm lg:text-lg md:text-base md:hidden text-dark_blue">
                {moment(datas.date).format("dddd DD/MM")}
              </span>
            </div>
          ) : (
            ""
          ))}
        {datasUserDisconnect.type === "Stage" ? (
          <div className="flex space-x-2">
            <span className="lg:text-lg md:text-base text-sm  font-bold text-[#4d4d4d]">À partir du :</span>
            <span className="hidden text-sm lg:text-lg md:text-base md:flex text-dark_blue">
              {moment(datasUserDisconnect.date).format("dddd DD MMMM YYYY")}
            </span>
            <span className="text-sm lg:text-lg md:text-base md:hidden text-dark_blue">
              {moment(datasUserDisconnect.date).format("dddd DD/MM")}
            </span>
          </div>
        ) : (
          ""
        )}

        <div className="flex space-x-2">
          <p className="lg:text-lg md:text-base text-sm truncate font-bold text-[#4d4d4d]">
            Pour :{" "}
            <span className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
              {user ? datas.firstNames : datasUserDisconnect.firstName}
            </span>{" "}
          </p>
        </div>

        <div className="hidden space-x-2  md:flex">
          <p className="lg:text-lg md:text-base text-sm truncate font-bold text-[#4d4d4d]">
            Adresse :{" "}
            <span className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
              {user ? datas.addresses : datasUserDisconnect.adresse}
            </span>{" "}
          </p>
        </div>
        <div className="flex space-x-2 md:hidden">
          <p className="lg:text-lg md:text-base text-sm truncate  font-bold text-[#4d4d4d]">
            Adresse :{" "}
            <span className="text-sm font-normal lg:text-lg md:text-base text-dark_blue ">
              {user ? datas.addresse : datasUserDisconnect.adresse}
            </span>{" "}
          </p>
        </div>
      </div>

      {user ? (
        ""
      ) : (
        <div>
          <div className="hidden w-2/3 mx-auto mt-10  //-translate-y-6 md:flex gap-x-10">
            <div className="">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "24px",
                  // marginLeft: "4rem",
                  fontFamily: "Nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "Nunito",
                      },
                    }}
                    onClick={() => setTerms(!terms)}
                  />
                }
                label={
                  <Typography style={{ fontFamily: "nunito" }}>
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/policy"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J’accepte la politique des données.
                    </a>{" "}
                  </Typography>
                }
              />

              <div className="flex space-x-2 -translate-y-3 //ml-6 lg:mt-4 ">
                {error.terms === "null" || error.terms === ""
                  ? " "
                  : validateTerms(terms).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.terms === "null" || error.terms === ""
                    ? null
                    : validateTerms(terms).message}
                </p>
              </div>
            </div>
            <div className=" font-nunito //-mt-8">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "24px",
                  // marginLeft: "4rem",
                  fontFamily: "nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "nunito",
                      },
                    }}
                    onClick={() => setTermsTeachr(!termsTeachr)}
                  />
                }
                label={
                  <Typography style={{ fontFamily: "nunito" }}>
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/cgu_cgv"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J'accepte les CGUs et CGVs.
                    </a>
                  </Typography>
                }
              />

              <div className="flex space-x-2 -translate-y-3 //ml-6 lg:mt-4 ">
                {error.termsTeachr === "null" || error.termsTeachr === ""
                  ? " "
                  : validateTermsTeachr(termsTeachr).icon}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.termsTeachr === "null" || error.termsTeachr === ""
                    ? null
                    : validateTermsTeachr(termsTeachr).message}
                </p>
              </div>
            </div>
          </div>
          <div className="block w-2/3 mx-auto  md:hidden">
            <div className="">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "16px",
                  // marginLeft: "4rem",
                  fontFamily: "Nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "Nunito",
                      },
                    }}
                    onClick={() => setTerms(!terms)}
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: "nunito" }}
                    className="text-sm lg:text-lg md:text-base"
                  >
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/policy"
                      className="underline text-[#4d4d4d] "
                      rel="noreferrer"
                    >
                      J’accepte la politique des données.
                    </a>
                  </Typography>
                }
              />

              <div className="flex pt-2 space-x-2 text-xs -translate-y-3 md:text-sm md:ml-6 ">
                {error.terms === "null" || error.terms === ""
                  ? " "
                  : validateTerms(terms).icon}
                <p className="text-[#FF724F] h-6 w-full ">
                  {error.terms === "null" || error.terms === ""
                    ? null
                    : validateTerms(terms).message}
                </p>
              </div>
            </div>
            <div className="-mt-6  font-nunito">
              <FormControlLabel
                sx={{
                  color: "#4d4d4d",
                  fontSize: "10px",
                  // marginLeft: "4rem",
                  fontFamily: "nunito",
                }}
                control={
                  <Checkbox
                    sx={{
                      color: "#4d4d4d",
                      "&.Mui-checked": {
                        color: "#4d4d4d",
                        fontFamily: "nunito",
                        fontSize: "10px",
                      },
                    }}
                    onClick={() => setTermsTeachr(!termsTeachr)}
                  />
                }
                label={
                  <Typography
                    style={{ fontFamily: "nunito" }}
                    className="text-sm lg:text-lg md:text-base"
                  >
                    <a
                      target={"_blank"}
                      href="https://www.teachr.fr/cgu_cgv"
                      className="text-[#4d4d4d] underline"
                      rel="noreferrer"
                    >
                      J'accepte les CGUs et CGVs.
                    </a>{" "}
                  </Typography>
                }
              />

              <div className="flex pt-2 space-x-2 text-xs -translate-y-3 md:text-sm md:ml-6 ">
                {error.termsTeachr === "null" || error.termsTeachr === ""
                  ? " "
                  : validateTermsTeachr(termsTeachr).icon}
                <p className="text-[#FF724F] h-6 w-full ">
                  {error.termsTeachr === "null" || error.termsTeachr === ""
                    ? null
                    : validateTermsTeachr(termsTeachr).message}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={user ? "md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-10 md:w-full" : "md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-0 md:w-full"}>
        <div className="flex justify-center w-1/2 font-extrabold bg-white border-2 rounded-full text-orange border-orange">
          <div
            onClick={handleBack}
            className="w-full pt-2 text-sm text-center uppercase"
          >
            Précédent
          </div>
        </div>
        <div className="flex justify-center w-1/2 ">
          <button
            onClick={() => handleSubmit()}
            disabled={load}
            className="w-full px-3 py-2 text-sm font-extrabold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-extrabold border-dark_blue text-dark_blue active:bg-dark_blue active:text-white "
          >
            {load ? (
              <CircularProgress
                sx={{
                  color: "#4d4d4d",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              user ? "Envoyer" : "Suivant"
            )}{" "}
          </button>
        </div>
      </div>

      <div className={user ? "md:flex hidden translate-y-10 justify-around md:w-full mx-auto" : "md:flex hidden translate-y-0 justify-around md:w-full mx-auto"}>
        <div className="flex justify-center space-x-2">
          <button
            type="button"
            onClick={handleBack}
            className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24"
          >
            Précédent
          </button>
        </div>
        <div className="flex justify-center space-x-2 ">
          <button
            type="button"
            disabled={load}
            onClick={() => handleSubmit()}
            className={
              " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
            }
          >
            {load ? (
              <CircularProgress
                sx={{
                  color: "#4d4d4d",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              user ? "Envoyer" : "Suivant"
            )}{" "}

          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseConfirmation;
