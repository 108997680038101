import { Button, Checkbox, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Input from '../../../../Components/Admin/Input';
import StepperStore from '../../../../LocalForage/StepperStore';
import AiciSetupInterface from '../../../../Objects/interfaces/AiciSetup';
import ValidateBIC from '../../../../Validation/Bic';
import ValidateIbanAici from '../../../../Validation/IbanAici';
import Layout from './Layout';
import Functions from '../../../../Helpers/Functions';

const Third = (props: any) => {
    const [save, setSave] = useState<AiciSetupInterface>()
    const [form, setForm] = useState({
        iban: '',
        bic: '',
    })
    const [errors, setErrors] = useState({
        iban: false,
        bic: false
    })
    useEffect(() => {


        if (form.iban === '') {
            setErrors({
                iban: false,
                bic: errors.bic
            })
        } else {
            setErrors({
                iban: !ValidateIbanAici(form.iban).isValid,
                bic: errors.bic
            })
        }

    }, [form.iban])
    useEffect(() => {
        if (form.bic === '') {
            setErrors({
                iban: errors.iban,
                bic: false
            })
        } else {
            setErrors({
                iban: errors.iban,
                bic: !ValidateBIC(form.bic).isValid
            })
        }

    }, [form.bic])
    const getIban = (value: string) => {
        if (value) {
            setForm({
                iban: value.replace(/\s/g, ''),
                bic: form.bic,

            })
        }
    }
    const getBic = (value: string) => {
        if (value) {
            setForm({
                iban: form.iban,
                bic: value.replace(/\s/g, ''),

            })
        }

    }
    useEffect(() => {
        StepperStore.getItem('dashboard_setup').then((response: any) => {
            let newForm = {
                iban: form.iban,
                bic: form.bic
            }
            if (response) {
                setSave(response)
                if (response.paiment.iban !== null || response.paiment.bic !== null) {
                    newForm.iban = response.paiment.iban
                }
                if (response.paiment.iban !== null || response.paiment.bic !== null) {
                    newForm.bic = response.paiment.bic
                }
                setForm(newForm)
            }
        })
    }, [])
    const nextStep = () => {
        let screenState: AiciSetupInterface = {
            currentStep: 4,
            key: '',
            paiment: {
                bic: form.bic,
                iban: form.iban
            },
            Parent: {
                first: {
                    firstName: null,
                    name: null,
                    mail: '',
                    telephone: null,
                },
                second: {
                    address: null,
                    birthDay: null,
                    country: null,
                    gender: null,
                    isBornFrance: null,
                    zipCode: null,
                    city: null,
                    birthName: null
                }
            },
            student: {
                firstName: null,
                level: null
            },
        }
        if (save && screenState) {
            if (save.Parent.first) {
                screenState.Parent.first = save.Parent.first
            }
            if (save.student) {
                screenState.student = save.student
            }
            if (save.Parent.second) {
                screenState.Parent.second = save.Parent.second
            }
            if (!errors.bic && !errors.iban) {
                StepperStore.setItem('dashboard_setup', screenState).then(() => props.nextStep(4))

            }
        }

    }
    const prevStep = () => {
        if (save) {
            props.nextStep(2)
        }

    };

    return (
        <div className='w-full'>
            <Layout
                next={<Button onClick={() => nextStep()} className='w-full py-2 font-extrabold text-white uppercase rounded-full px-14 bg-first md:w-max md:text-base border-solid border-2 border-first' >SUIVANT</Button>}
                prev={<Button onClick={() => prevStep()} className='w-full py-2 font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first md:text-base px-14 md:w-max border-first' >précédent</Button>}
            >
                <div className="flex flex-col gap-8 md:flex-row">
                    <div className='pb-6 md:w-3/4'>
                        <h1 className='pb-8 text-lg font-bold 2xl text- md:text-xl text-first'>Ses informations bancaires :</h1>
                        <div className={`${errors.iban || errors.bic ? 'pt-5' : 'pt-0'} flex flex-col gap-8 `}>
                            <div className='relative w-full'>
                                <p className={`${errors.iban ? 'absolute' : 'hidden'} text-third text-base md:text-base  -top-7`}>{ValidateIbanAici(form.iban).message}</p>
                                <Input callBack={getIban} defaultValue={form.iban !== '' ? Functions.addEspacesIBAN(form.iban) : null} field='iban' label='IBAN' placeholder='FR00 0000 0000 0000 0000 0000 000' />
                            </div>
                            <div className='relative w-full'>
                                <p className={`${errors.bic ? 'absolute' : 'hidden'} text-third text-base md:text-base  -top-7`}>{ValidateBIC(form.bic).message}</p>
                                <Input callBack={getBic} defaultValue={form.bic !== '' ? form.bic : null} field='bic' label='BIC' placeholder='CBBFRBZJAJHG' />
                            </div>
                        </div>
                    </div>
                    <div className='w-full'>
                        <div className="flex ">
                            <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_5724_22853)">
                                    <path d="M32.1215 15.2077L27.8515 0.676309C27.7038 0.1732 27.1772 -0.11429 26.6828 0.0359889L0.664567 7.95179C0.170143 8.10207 -0.112385 8.63785 0.0353002 9.14095L4.30532 23.6723C4.45301 24.1754 4.97954 24.4629 5.47396 24.3126L31.4922 16.3968C31.9866 16.2466 32.2692 15.7108 32.1215 15.2077Z" fill="#219CFF" />
                                    <path d="M28.3009 2.1961L0.484375 10.6602L1.43547 13.8966L29.2519 5.43258L28.3009 2.1961Z" fill="#3E5D9E" />
                                    <path d="M32.1215 15.2077L27.8515 0.676309C27.7038 0.1732 27.1772 -0.11429 26.6828 0.0359889L0.664567 7.95179C0.170143 8.10207 -0.112385 8.63785 0.0353002 9.14095L4.30532 23.6723C4.45301 24.1754 4.97954 24.4629 5.47396 24.3126L31.4922 16.3968C31.9866 16.2466 32.2692 15.7108 32.1215 15.2077Z" fill="#219CFF" />
                                    <path d="M28.3009 2.1961L0.484375 10.6602L1.43547 13.8966L29.2519 5.43258L28.3009 2.1961Z" fill="#3E5D9E" />
                                    <path d="M27.4767 31.0019C27.3515 31.0019 27.2295 30.9692 27.1203 30.9104C20.9785 27.6957 17.0713 21.3056 16.9493 14.2817C16.9461 13.233 17.0392 12.1875 17.2286 11.1585C17.3057 10.7436 17.7006 10.4724 18.1083 10.5508C18.1244 10.5541 18.1404 10.5573 18.1565 10.5606C18.9206 10.7599 19.7072 10.8579 20.4969 10.8546C22.8984 10.8677 25.21 9.93989 26.9566 8.26395C27.2487 7.97973 27.711 7.97973 28.0032 8.26395C29.7497 9.93989 32.0613 10.8677 34.4628 10.8546C35.2526 10.8579 36.0392 10.7599 36.8033 10.5606C37.2046 10.4561 37.6156 10.7011 37.7183 11.1095C37.7215 11.1258 37.7247 11.1421 37.7279 11.1585C37.9174 12.1875 38.0105 13.233 38.0073 14.2817C37.8885 21.3088 33.978 27.699 27.8363 30.9104C27.7239 30.9725 27.6019 31.0019 27.4767 31.0019Z" fill="#8CC63F" />
                                    <path d="M26.64 24.313L33.4496 16.2306C33.8413 15.734 33.7642 15.0055 33.2762 14.6069C32.8075 14.2247 32.13 14.2769 31.7255 14.7278L25.8374 21.719L23.2818 18.2528C22.9062 17.7432 22.1934 17.6386 21.6926 18.0208C21.1917 18.4031 21.089 19.1283 21.4646 19.638L24.8678 24.2574C25.0733 24.5384 25.3943 24.7083 25.7379 24.7181C25.7507 24.7181 25.7635 24.7181 25.7764 24.7181C26.1103 24.7181 26.4249 24.5711 26.64 24.313Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_5724_22853">
                                        <rect width="38" height="31" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                            <h2 className='text-lg font-semibold text-first'>Prélévement sécurisé et opéré par l’URSSAF. </h2>
                        </div>
                        <p className='text-sm text-[rgba(128,128,128,1)] font-semibold py-8'>Les informations bancaires demandées sont strictement confidentielles et utilisées uniquement par l’URSSAF</p>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Third;