import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AvatarF from '../../Assets/Images/profile__image.png'
import AvatarH from '../../Assets/Images/avatar-h.svg'
import { signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../Services/ApiService';
import TeachrRepository from '../../Repositories/TeachrRepository';
import logoutIcon from '../../Assets/Icons/logout.svg';
import Teachr from '../../Objects/models/Teachr';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../Services/FirebaseService';
import dashBoardIcon from '../../Assets/Icons/data.svg'

export default function ProfileButton() {
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [teachr, setTeachr] = useState<Teachr>()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    signOut(auth).then(() => {
      ApiService.disableAuth()
      navigate("/teachr/login")
    }).catch((error) => {
      // An error happened.
    });

  }
  const getProfileImage = () => {
    if (teachr) {
      if (!teachr.profilePicture) {
        if (teachr.sex === 'MAN') {
          return (<img className='w-full h-full' src={AvatarH} alt="profile" />)
        }
        return (<img className='w-full h-full' src={AvatarF} alt="profile" />)
      } else {
        return (<img className='w-full' src={teachr.profilePicture.contentUrl!} alt="profile" />)
      }
    }
  }
  useEffect(() => {
    document.body.classList.remove('bg-bg-home');
    document.body.classList.add('w-screen');
    document.body.classList.add('overflow-x-hidden');
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.backgroundSize = 'cover';
    document.body.style.height = '100%';
  }, []);
  useEffect(() => {
    if (loading) {
      document.getElementById('teachr-loader')?.classList.remove('hidden')
    } else {
      if (!user) {
        logout()
      } else {
        TeachrRepository.getCurrentTeachr().then((response) => {
          if (response) {
            setTeachr(response)
            document.getElementById('teachr-loader')?.classList.add('hidden')
          }
        }).catch(() => {
          logout()
        })
      }
    }
  }, [loading, user]);
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Paramètres du compte">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar>{getProfileImage()}</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndEx: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{ fontFamily: 'Nunito' }} onClick={() => navigate('/teachr/profile')}>
          <Avatar>{getProfileImage()}</Avatar>Profil
        </MenuItem>
        {(!teachr?.proofSignSap || !teachr?.proofIsAE) && <MenuItem sx={{ fontFamily: 'Nunito' }} onClick={() => navigate('/teachr/procuration/ae')}>
          Procuration auto-entrepreneur
        </MenuItem>}
        {(!teachr?.proofSignSap || !teachr?.proofIsAE) && <MenuItem sx={{ fontFamily: 'Nunito' }} onClick={() => navigate('/teachr/procuration/sap')}>
          Être déclaré SAP <span className='text-sm pl-2'>(service à la personne)</span>
        </MenuItem>}
        {(teachr?.signSap === false || teachr?.signSap === null )&& 
        <MenuItem sx={{ fontFamily: 'Nunito' }} onClick={() => navigate('/teachr/profile/complete')}>
            Signer les conditions de services
          </MenuItem>
          }
        <Divider />
        {/* 
        <MenuItem sx={{fontFamily: 'Nunito'}} onClick={() => navigate('/teachr/dashboard')}> 
          <ListItemIcon>
          <img src={dashBoardIcon} width="16px" alt="" />
          </ListItemIcon>
          Tableau de board
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Préférences
        </MenuItem> */}
        <MenuItem sx={{ fontFamily: 'Nunito' }} onClick={() => logout()}>
          <ListItemIcon>
            <img src={logoutIcon} width="16px" alt="" />
          </ListItemIcon>
          Se déconnecter
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
