import React from 'react';
import Navbar from '../../../../Components/Admin/Navbar';
import Layout from './AccueilDashboard/Layout';

const IndexLayoutCourse = () => {
    return (
      <div className="h-screen overflow-hidden bg-[#F4FAFF]">
        <Navbar />
        <div className=" h-[90%]">
          <Layout />
        </div>
      </div>
    );
};

export default IndexLayoutCourse;