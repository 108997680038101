export enum Route {
  // client
  CLIENT_BASE = "/clients",
  CLIENT_V2 = "/clients/v2",
  CLIENT_ME = "/me/",
  CLIENT_GET_CHILDREN = "/clients/children",
  CLIENT_SIMPLE = "/client",

  // punctual courses
  PUNCTUAL_COURSE_BASE = "/punctual_courses",
  CHARGES_PUNCTUAL_COURSE_BASE = "/charges",
  PUNCTUAL_COURSE_BASE_UPDATE_TEACH_R = "/teachrs/v2/teachrs/punctual_course",
  PROPOSAL_COURSE_BASE_UPDATE_TEACH_R = "/teachrs/v2/teachrs/course_proposal",
  PUNCTUAL_COURSE_BASE_UPDATE_TEACH_R_ = "/update",
  PUNCTUAL_COURSE_BASE_TEACHR = "/teachrs/punctual_courses",
  PUNCTUAL_COURSE_VALIDATE = "/validate",

  // punctual courses
  REGULAR_COURSE_BASE = "/regular_courses",
  REGULAR_COURSE_VALIDATE = "/validate",

  // addresses
  ADDRESS_BASE = "/addresses",
  ADDRESS_DEFAULT = "/default/",

  // credit cards
  CREDIT_CARD_BASE = "/credit_cards",
  CREDIT_CARD_DEFAULT = "/default/",
  CREDIT_CARD_INTENT = "/setup_intent",

  // credit cards
  CUSTOM_FEES_BASE = "/custom_fees",

  // children
  CHILDREN_BASE = "/children",

  // course proposal
  COURSE_PROPOSAL_BASE = "/course_proposals",
  COURSE_PROPOSAL_REFUSE = "/refuse",
  COURSE_PROPOSAL_LATE = "/late",

  // helpers
  HELPER_BASE = "/helpers",
  HELPER_PRICE_ESTIMATION = "/price_estimation",
  HELPER_PRICE_RANGE = "/price_range",
  HELPER_ALLOWED_POSTAL_CODES = "/allowed_postal_codes",

  // images
  IMAGE_BASE = "/images",

  // api version
  API_VERSION_BASE = "/api_versions",

  // subject
  SUBJECT_BASE = "/subjects",

  // teachr
  TEACHR_BASE = "/teachrs",
  TEACHR_ME = "/me/",
  TEACHR_SIMPLE = "/teachr",

  // user
  USER_BASE = "/users",

  // hidden punctual courses
  HIDDEN_PUNCTUAL_COURSE_BASE = "/hidden_punctual_courses",

  // firebase
  FIREBASE_BASE = "/firebase",

  // promotional code
  PROMOTIONAL_CODE_BASE = "/promotional_codes",

  // review client
  REVIEW_CLIENT_BASE = "/review_clients",

  // review teachr
  REVIEW_TEACHR_BASE = "/review_teachrs",

  // available promotional codes
  AVAILABLE_PROMOTIONAL_CODES_BASE = "/available_promotional_codes",

  // refused client
  REFUSED_CLIENT_BASE = "/refused_clients",

  // mvp migration
  MVP_MIGRATION = "/mvp/migration",

  // edition request
  EDITION_REQUEST_BASE = "/edition_requests",

  // URSSAF Parent
  URSSAF_PARENT_BASE = "/u_r_s_s_a_f_parents",

  // teachrContacts
  TEACHRCONTACT_BASE = "/teachr_contacts",
  TEACHRCONTACT_GET_CONTACTS = "/teachr/",

  // interestedTeachrs
  INTERESTEDTEACHR_BASE = "/interested_teachrs",

  //Note for teachr

  NOTE_FOR_TEACHR = "/note_fo_teachrs",

}

