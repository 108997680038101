export default class TeachrBusinessType {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    businessType: string

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    readonly creationDatetime?: Date;

    constructor(
        businessType: string,
    ) {
        this.businessType = businessType;
    }

}
