import React from 'react';
import updateponc from '../../../../../../../Assets/Images/modifier_cours.gif'
import StepperStore from '../../../../../../../LocalForage/StepperStore';

const EditConfirme = (props:any) => {
    
    const handleNext = () => {
        StepperStore.clear()
        props.backStep(1)
    }
    return (
        <div className=' p-6 shadow-dashboard-setup-intro-form h-[80%] mt-4 //h-[0%] px-4 py-4 bg-white rounded-xl '>
            <div className='flex'>
                <div className='lg:w-2/3 lg:p-10  //border-2 lg:pr-56 '>
                    <div className="mt-3">
                        <h1 className="lg:text-2xl pl-10 //text-center /lg:text-lg text-base font-extrabold text-dark_blue">
                            Modification du cours ponctuel réussi !
                        </h1>
                    </div>
                    <div className='mt-8 pl-10'>
                        <p className='lg:text-xl text-base font-bold text-dark_blue'>
                            Votre cours ponctuel a bien été modifié. Vous pourrez retrouver toutes les modifications de celui-ci dans votre tableau.
                        </p>
                    </div>
                </div>
                <div className='w-1/2 hidden lg:block -translate-x-16  px-4 pt-4'>
                    <img src={updateponc} alt="" />
                </div>
            </div>
            <div className='lg:pl-20 lg:-translate-y-16 lg:mt-0 mt-10'>
                <div className="bg-dark_blue p-1 rounded-3xl lg:w-1/4 cursor-pointer" onClick={()=> { handleNext() }}>
                    <p className="text-white text-base text-center font-extrabold">Mes cours</p>
                </div>
            </div>
        </div>
    );
};

export default EditConfirme;