import ActionInterface from "../../Objects/interfaces/ActionInterface";
import GeneralStateInterface from "../../Objects/interfaces/GeneralStateInterface";
import {
    GENERAL_ALREADY_LAUNCHED_CLIENT,
    GENERAL_ALREADY_LAUNCHED_TEACHR,
    GENERAL_ALREADY_LAUNCHED_TEACHR_COURSE_PACK,
    GENERAL_ALREADY_LAUNCHED_CLIENT_REGULAR_COURSES,
    GENERAL_DISMISS_ERROR,
    GENERAL_SET_ERROR,
    GENERAL_SET_HAS_TO_RELOAD_REVIEWS,
    GENERAL_SET_NOTIFICATION_AUTHORIZATION,
    GENERAL_SET_PAGE_TO_OPEN,
    GENERAL_SET_UNREAD_MESSAGES,
    GENERAL_ALREADY_AGREED_CONVERSATION,
} from "../Actions/GeneralActions";

export const initialState: GeneralStateInterface = {
    // authorizations
    authorizations: {
        notification: false,
    },
    // already launched
    alreadyLaunched: {
        introductionTeachr: false,
        introductionClient: false,
    },
    // should the "new course" drawer be opened by default

    alreadyAgreedConversation: false,
    // if the app must fetch reviews to note
    hasToReloadReviews: true,
    // if the app must fetch reviews to note
    unreadMessages: false,
};

export default function generalReducer(
    state = initialState,
    action: ActionInterface
): GeneralStateInterface {
    switch (action.type) {
    case GENERAL_SET_NOTIFICATION_AUTHORIZATION:
        return { ...state, authorizations: { notification: action.value } };
    case GENERAL_SET_ERROR:
        return { ...state, error: action.value };
    case GENERAL_DISMISS_ERROR:
        return { ...state, error: undefined };
    case GENERAL_ALREADY_LAUNCHED_TEACHR:
        return {
            ...state,
            alreadyLaunched: {
                ...state.alreadyLaunched,
                introductionTeachr: true,
            },
        };
    case GENERAL_ALREADY_LAUNCHED_CLIENT_REGULAR_COURSES:
        return {
            ...state,
            alreadyLaunched: {
                ...state.alreadyLaunched,
            },
        };
    case GENERAL_ALREADY_LAUNCHED_TEACHR_COURSE_PACK:
        return {
            ...state,
            alreadyLaunched: {
                ...state.alreadyLaunched,
            },
        };
    case GENERAL_ALREADY_LAUNCHED_CLIENT:
        return {
            ...state,
            alreadyLaunched: {
                ...state.alreadyLaunched,
                introductionClient: true,
            },
        };
    case GENERAL_ALREADY_AGREED_CONVERSATION:
        return { ...state, alreadyAgreedConversation: true };
    case GENERAL_SET_HAS_TO_RELOAD_REVIEWS:
        return { ...state, hasToReloadReviews: action.value };
    case GENERAL_SET_UNREAD_MESSAGES:
        return { ...state, unreadMessages: action.value };
    default:
        return state;
    }
}
