import React, { useEffect, useState } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import RadioButton from "../../utils/RadioButtonClient";
import errorIcon from "../../../Assets/Images/error.png";
import addresse from "../../../Assets/Images/addresse.png";
import StepperStore from "../../../LocalForage/StepperStore";
import { Address } from "../../../Objects/models/Address";
import { auth } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import ClientRepository from "../../../Repositories/ClientRepository";
import AddressRepository from "../../../Repositories/AddressRepository";
import CreateAddress from "./Address/CreateAddresse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {  Radio, Typography } from "@mui/material";

const BootstrapButton = styled(Button)({
  textTransform: "none",
  width: "100%",
  fontSize: "24px",
  fontWeight: "bold",
  color: "#0054A3",
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#F2F5FB",
  borderRadius: "15px",
  fontFamily: ["nunito"].join(","),
  "&:hover": {
    backgroundColor: "#0054A3",
    color: "white",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F2F5FB",
    color: "#0054A3",
  },
  "&:focus": {
    boxShadow: "0 0 0 1px .2rem rgba(0,23,55,.5)",
  },
});

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 25,
  color: "transparent",
  height: 25,
  backgroundColor:
    theme.palette.mode === "dark" ? "transparent" : "transparent",
  "input ~ &": {
    border: "none",
  },
  "input:checked ~ &": {
    border: "none",
  },
  "input:hover ~ &": {
    backgroundColor:
      theme.palette.mode === "dark" ? "transparent" : "transparent",
    border: "none",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    border: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));
const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  "&:before": {
    display: "block",
    width: "100%",
    height: "100%",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "transparent",
  },
});

const FormLocation = (props: { handleNext: any; handleBack: any }) => {
  const { handleNext, handleBack } = props;
  const [position, setPosition] = useState({
    isHome: false,
    isRemote: true,
    isNoPreference: false,
  });
  const [opens, setOpens] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user] = useAuthState(auth);
  const [options, setOptions] = useState<Address[]>([]);
  const [selected, setSelected] = useState("");
  const [add, setAdd] = useState<any>([]);
  const [dataAddresse, setDataAddresse] = useState<Address>();
  const [error, setError] = useState({
    add: "null",
  });

  const [errors, setErrors] = useState({
    addDisconnect: "null",
  });
  const reLoad = () => {
    setLoading(!loading);
  };
  const handleIsHome = () => {
    setPosition({
      isRemote: false,
      isHome: true,
      isNoPreference: false,
    });
  };
  const handleIsRemote = () => {
    setPosition({
      isRemote: true,
      isHome: false,
      isNoPreference: false,
    });
  };
  const handleNoPreference = () => {
    setPosition({
      isHome: false,
      isRemote: false,
      isNoPreference: true,
    });
  };
  const handleSelect = (value: any) => {
    setSelected(value);
  };
  const handleChange = (value: any) => {
    setAdd(value);
  };
  const handleClickOpen = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpens(false);
  };

  useEffect(() => {
    (async () => {
      try {
        if (user) {
          const clientWithId = await ClientRepository.getCurrentClient();
          if (clientWithId.id) {
            const res = await AddressRepository.getClientAddresses(
              clientWithId.id
            );
            const addresse = res;
            const firstAddresse = addresse[0].formattedAddress;
            const selectAddress = addresse[0];
            setOptions(res);
            setSelected(firstAddresse);
            setAdd(selectAddress);
            handleClose();
          }
        }
      } catch (error) {
        console.warn(error);
        // document.getElementById('teachr-loader')?.classList.add('hidden')
      }
    })();
  }, [loading, user]);
  useEffect(() => {
    (async () => {
      const addresse: any = await StepperStore.getItem("addresse");
      if (addresse !== null) {
        setDataAddresse(addresse.addresseObject);
      }
    })();
  }, [loading]);
  useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem(
        user ? "formLocation" + user?.uid : "formLocation"
      );
      if (value !== null) {
        setAdd(value.addresse);
        setPosition(value.position);
      } else {
        setAdd([]);
        setPosition({ isHome: false, isRemote: true, isNoPreference: false });
      }
    })()
    // getFormLocation();
  }, [user]);
  const validateAdresse = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Vous n'avez pas inséré d'adresse",
      };
    }
    if (
      (value.region !== "Île-de-France" && position.isHome === true) ||
      (value.region !== "Île-de-France" && position.isNoPreference === true)
    ) {
      return {
        isValid: false,
        message:
          "Les cours à domicile ne sont pas encore disponibles dans votre région.",
        message1: "Veuillez sélectionner l'option : ",
        message2: " À distance.",
        icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  };
  const validateAdresseConnect = (value: any) => {
    if (value.length === 0) {
      return {
        isValid: false,
        message: "Vous n'avez pas inséré d'adresse",
      };
    }
    if (
      (value.region !== "Île-de-France" && position.isHome === true) ||
      (value.region !== "Île-de-France" && position.isNoPreference === true)
    ) {
      return {
        isValid: false,
        message:
          "Les cours à domicile ne sont pas encore disponibles dans votre région.",
        message1: "Veuillez sélectionner l'option : ",
        message2: " À distance.",
        icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
      };
    } else {
      return {
        isValid: true,
        message: "",
        icon: "",
      };
    }
  };
  const validate = () => {
    let erraddress = true;
    if (!validateAdresse(dataAddresse).isValid) {
      setErrors({
        addDisconnect: validateAdresse(dataAddresse).message,
      });
      erraddress = true;
    } else {
      setErrors({
        addDisconnect: validateAdresse(dataAddresse).message,
      });
      erraddress = false;
      handleClose();
    }
    if (erraddress) {
      return false;
    } else {
      return true;
    }
  };
  const validateConnect = () => {
    let erraddress = true;
    if (!validateAdresseConnect(add).isValid) {
      setError({
        add: validateAdresseConnect(add).message,
      });
      erraddress = true;
    } else {
      setError({
        add: validateAdresseConnect(add).message,
      });
      erraddress = false;
      handleClose();
    }
    if (erraddress) {
      return false;
    } else {
      return true;
    }
  };
  const handleSubmitUserConnect = () => {
    if (validateConnect()) {
      StepperStore.setItem(
        user ? "formLocation" + user?.uid : "formLocation",
        {
          add: add,
          // terms: terms,
          position: position,
        },
        function () {}
      );
      handleNext();
    }
  };

  const handleSubmitUserDisConnect = () => {
    if (validate()) {
      StepperStore.setItem(
        "formLocation",
        {
          add: dataAddresse,
          // terms: terms,
          position: position,
        },
        function () {}
      );
      handleNext();
    }
  };
  const handleSubmit = (event: any) => {
    if (user) {
      handleSubmitUserConnect();
    } else {
      handleSubmitUserDisConnect();
    }
    event.preventDefault();
  };

  return (
    <>
      <div className="space-y-2 lg:space-y-6   md:space-y-4 lg:translate-y-10 translate-y-28 md:translate-y-20">
        <div className=" w-4/5 mx-auto">
          <h5 className="xl:text-3xl md:text-2xl text-lg text- text-dark_blue font-bold">
            Comment souhaitez-vous effectuer votre cours ?
          </h5>
        </div>
        <div className="translate-y-2  md:w-4/5 w-1/2 ml-16  font-nunito md:mx-auto">
          <FormControl>
            <RadioGroup row name="customized-radios">
              <div className="font-nunito ">
                <FormControlLabel
                  checked={position.isRemote === true}
                  onChange={() => handleIsRemote()}
                  sx={{ color: "black", fontFamily: "Nunito-Regular" }}
                  value={position.isRemote === true}
                  control={<RadioButton border="1px solid #fff" bg="#219CFF" />}
                  label={
                    <Typography
                      style={{ fontFamily: "Nunito" }}
                      className="md:text-base lg:text-lg text-sm"
                    >
                      À distance{" "}
                    </Typography>
                  }
                />
                <div className="text-[#009CFF] lg:text-base text-xs font-bold ml-6 -translate-y-3">
                  Non éligible au crédit d’impôt
                </div>
              </div>
              <div className=" xl:ml-10 lg:ml-0 md:ml-4 ">
                <FormControlLabel
                  checked={position.isHome === true}
                  onChange={() => handleIsHome()}
                  sx={{ color: "black" }}
                  value={position.isHome === true}
                  control={<RadioButton border="1px solid #fff" bg="#219CFF" />}
                  label={
                    <Typography
                      style={{ fontFamily: "Nunito" }}
                      className="md:text-base lg:text-lg text-sm"
                    >
                      À domicile
                    </Typography>
                  }
                />
              </div>
              <div className="text-transparent md:hidden lg:text-base text-xs font-bold xl:ml-6 -translate-y-3">
                Non éligible au crédit d’impôt
              </div>
              <div className=" xl:ml-10 lg:ml-0 md:ml-4">
                <FormControlLabel
                  checked={position.isNoPreference === true}
                  onChange={() => handleNoPreference()}
                  sx={{ color: "black" }}
                  value={position.isNoPreference === true}
                  control={<RadioButton border="1px solid #fff" bg="#219CFF" />}
                  label={
                    <Typography
                      style={{ fontFamily: "Nunito" }}
                      className="md:text-base lg:text-lg text-sm"
                    >
                      Pas de préférence
                    </Typography>
                  }
                />
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        <div className="space-y-4 translate-y-6  font-nunito md:space-y-8 md:translate-y-10">
          <div className="w-4/5 mx-auto">
            <h5 className="xl:text-3xl lg:text-2xl md:text-2xl text-lg text-dark_blue font-bold">
              Quelle est votre adresse de facturation ?
            </h5>
          </div>

          {!user ? (
            <div>
              <CreateAddress reLoad={reLoad} />
              {(position.isHome === true &&
                dataAddresse?.region === "Île-de-France") ||
              (position.isNoPreference === true &&
                dataAddresse?.region === "Île-de-France") ? (
                <div className="w-4/5  md:-mt-[88px] text-grey md:translate-y-36 translate-y-4 mx-auto">
                  <div>
                    <Typography
                      style={{ fontFamily: "nunito" }}
                      className="text-sm md:text-lg "
                    >
                      L'adresse de facturation est identique à l'adresse du
                      cours
                    </Typography>
                  </div>
                </div>
              ) : null}
              <div
                className={
                  "mt-4  md:mt-20 md:mb-36  mb-0  w-4/5  flex space-x-2 md:text-lg mx-auto"
                }
              >
                {errors.addDisconnect === "null" || errors.addDisconnect === ""
                  ? " "
                  : validateAdresse(dataAddresse).icon}
                <div className="block  -space-y-6 md:-space-y-0">
                  <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
                    <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
                      {errors.addDisconnect === "null" ||
                      errors.addDisconnect === ""
                        ? null
                        : validateAdresse(dataAddresse).message1}
                    </p>
                    <div className="text-dark_blue md:translate-x-0 lg:text-base md:text-sm text-xs translate-x-44 -translate-y-6 md:-translate-y-0 h-6 w-full lg:-ml-14 ">
                      {errors.addDisconnect === "null" ||
                      errors.addDisconnect === "" ? null : (
                        <FormControl>
                          <RadioGroup row name="customized-radios">
                            <div className="font-nunito -mt-2 -ml-10">
                              <FormControlLabel
                                checked={position.isRemote === true}
                                onChange={() => handleIsRemote()}
                                sx={{ color: "black", fontFamily: "Nunito" }}
                                value={position.isRemote}
                                control={
                                  <Radio
                                    sx={{ color: "white" }}
                                    icon={<BpIcon />}
                                    checkedIcon={<BpCheckedIcon />}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{ fontFamily: "Nunito" }}
                                    className="lg:text-base md:text-sm text-xs text-dark_blue"
                                  >
                                    {validateAdresse(dataAddresse).message2}{" "}
                                  </Typography>
                                }
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <p className="text-[#FF724F] h-6 w-full lg:text-base md:text-sm text-xs ">
                    {errors.addDisconnect === "null" ||
                    errors.addDisconnect === ""
                      ? null
                      : validateAdresse(dataAddresse).message}
                  </p>
                </div>
              </div>
            </div>
          ) : user && options.length === 0 ? (
            <div>
              <CreateAddress reLoad={reLoad} />
              {(position.isHome === true &&
                dataAddresse?.region === "Île-de-France") ||
              (position.isNoPreference === true &&
                dataAddresse?.region === "Île-de-France") ? (
                <div className="w-4/5  md:-mt-[88px] text-grey md:translate-y-36 translate-y-4 mx-auto">
                  <div>
                    <Typography
                      style={{ fontFamily: "nunito" }}
                      className="text-sm md:text-lg "
                    >
                      L'adresse de facturation est identique à l'adresse du
                      cours
                    </Typography>
                  </div>
                </div>
              ) : null}
              <div
                className={
                  "  md:mt-20 md:mb-36  mb-0 mt-4 w-4/5  flex space-x-2 md:text-lg mx-auto"
                }
              >
                {errors.addDisconnect === "null" || errors.addDisconnect === ""
                  ? " "
                  : validateAdresse(dataAddresse).icon}
                <div className="block -space-y-6 md:-space-y-0">
                  <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
                    <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
                      {errors.addDisconnect === "null" ||
                      errors.addDisconnect === ""
                        ? null
                        : validateAdresse(dataAddresse).message1}
                    </p>
                    <div className="text-dark_blue md:translate-x-0 lg:text-base md:text-sm text-xs translate-x-44 -translate-y-6 md:-translate-y-0 h-6 w-full lg:-ml-14 ">
                      {errors.addDisconnect === "null" ||
                      errors.addDisconnect === "" ? null : (
                        <FormControl>
                          <RadioGroup row name="customized-radios">
                            <div className="font-nunito -mt-2 -ml-10">
                              <FormControlLabel
                                checked={position.isRemote === true}
                                onChange={() => handleIsRemote()}
                                sx={{ color: "black", fontFamily: "Nunito" }}
                                value={position.isRemote}
                                control={
                                  <Radio
                                    sx={{ color: "white" }}
                                    icon={<BpIcon />}
                                    checkedIcon={<BpCheckedIcon />}
                                  />
                                }
                                label={
                                  <Typography
                                    style={{ fontFamily: "Nunito" }}
                                    className="lg:text-base md:text-sm text-xs text-dark_blue"
                                  >
                                    {validateAdresse(dataAddresse).message2}{" "}
                                  </Typography>
                                }
                              />
                            </div>
                          </RadioGroup>
                        </FormControl>
                      )}
                    </div>
                  </div>
                  <p className="text-[#FF724F] h-6 w-full lg:text-base md:text-sm text-xs ">
                    {errors.addDisconnect === "null" ||
                    errors.addDisconnect === ""
                      ? null
                      : validateAdresse(dataAddresse).message}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="pt-2 relative md:translate-y-14 mb-20  mx-auto text-grey w-4/5 xl:w-4/5 ">
              <div className="font-nunito">
                <Stack spacing={2} direction="row">
                  <BootstrapButton
                    className="w-full  z-50 mx-auto truncate"
                    variant="contained"
                    onClick={handleClickOpen}
                  >
                    {user ? (
                      <input
                        className="text-base md:text-lg lg:2xl w-full"
                        value={selected ? selected : "Ajouter une adresse"}
                        type="button"
                      />
                    ) : (
                      ""
                    )}
                  </BootstrapButton>
                </Stack>
                {(position.isHome === true && add.region === "Île-de-France") ||
                (position.isNoPreference === true &&
                  add.region === "Île-de-France") ? (
                  <div className="w-full z-50  md:-mt-[88px]  text-grey md:translate-y-24 translate-y-4 mx-auto">
                    <div>
                      <Typography
                        style={{ fontFamily: "nunito" }}
                        className="text-sm md:text-lg "
                      >
                        L'adresse de facturation est identique à l'adresse du
                        cours
                      </Typography>
                    </div>
                  </div>
                ) : null}
                <div
                  className={
                    "  md:mt-20 md:mb-36  mb-0 mt-4 w-4/5  flex space-x-2 md:text-lg mx-auto"
                  }
                >
                  {error.add === "null" || error.add === ""
                    ? " "
                    : validateAdresse(add).icon}
                  <div className="block -space-y-6 md:-space-y-0">
                    <div className="md:flex justify-around  md:gap-x-4 lg:text-base md:text-sm text-xs">
                      <p className="text-[#FF724F] h-6 w-full  md:-translate-y-0 translate-y-1 ">
                        {error.add === "null" || error.add === ""
                          ? null
                          : validateAdresse(add).message1}
                      </p>
                      <div className="text-dark_blue md:translate-x-0 lg:text-base md:text-sm text-xs translate-x-44 -translate-y-6 md:-translate-y-0 h-6 w-full lg:-ml-14 ">
                        {error.add === "null" || error.add === "" ? null : (
                          <FormControl>
                            <RadioGroup row name="customized-radios">
                              <div className="font-nunito -mt-2 -ml-10">
                                <FormControlLabel
                                  checked={position.isRemote === true}
                                  onChange={() => handleIsRemote()}
                                  sx={{ color: "black", fontFamily: "Nunito" }}
                                  value={position.isRemote}
                                  control={
                                    <Radio
                                      sx={{ color: "white" }}
                                      icon={<BpIcon />}
                                      checkedIcon={<BpCheckedIcon />}
                                    />
                                  }
                                  label={
                                    <Typography
                                      style={{ fontFamily: "Nunito" }}
                                      className="lg:text-base md:text-sm text-xs text-dark_blue"
                                    >
                                      {validateAdresse(add).message2}{" "}
                                    </Typography>
                                  }
                                />
                              </div>
                            </RadioGroup>
                          </FormControl>
                        )}
                      </div>
                    </div>
                    <p className="text-[#FF724F] h-6 w-full lg:text-base md:text-sm text-xs ">
                      {error.add === "null" || error.add === ""
                        ? null
                        : validateAdresse(add).message}
                    </p>
                  </div>
                </div>

                <Dialog open={opens} onClose={handleClose}>
                  {
                    <div className="  ">
                      <DialogTitle></DialogTitle>
                      <div className="md:hidden block">
                        <DialogContent>
                          <List
                            dense
                            sx={{
                              width: 260,
                              maxWidth: 400,
                              maxHeight: 300,
                              bgcolor: "background.paper",
                            }}
                            subheader={<li />}
                          >
                            {options.map((val: any, index: any) => (
                              <ListItem key={index} disablePadding>
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <img
                                      alt={`Avatar n°${val + 1}`}
                                      src={addresse}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    className="font-nunito "
                                    onClick={() => {
                                      handleSelect(val.formattedAddress);
                                      handleClose();
                                      handleChange(val);
                                    }}
                                    primary={val.formattedAddress}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </DialogContent>
                      </div>
                      <div className="hidden md:block">
                        <DialogContent>
                          <List
                            dense
                            sx={{
                              width: 390,
                              maxWidth: 400,
                              maxHeight: 300,
                              bgcolor: "background.paper",
                            }}
                            subheader={<li />}
                          >
                            {options.map((val: any, index: any) => (
                              <ListItem key={index} disablePadding>
                                <ListItemButton>
                                  <ListItemAvatar>
                                    <img
                                      alt={`Avatar n°${val + 1}`}
                                      src={addresse}
                                    />
                                  </ListItemAvatar>
                                  <ListItemText
                                    className=" "
                                    onClick={() => {
                                      handleSelect(val.formattedAddress);
                                      handleClose();
                                      handleChange(val);
                                    }}
                                    primary={val.formattedAddress}
                                  />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </DialogContent>
                      </div>

                      <DialogActions>
                        <div className="md:w-1/2 w-full mb-4 mx-auto ">
                          <div>
                            <CreateAddress reLoad={reLoad} />
                          </div>
                        </div>
                      </DialogActions>
                    </div>
                  }
                </Dialog>
              </div>
            </div>
          )}
        </div>
        <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-12 md:translate-y-44 md:w-full">
          <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
            <div
              onClick={handleBack}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex hidden md:full xl:translate-y-8 lg:-translate-y-12  md:translate-y-8  //translate-y-20  justify-around">
          <div className="flex  space-x-2 justify-center">
            <button
              type="button"
              onClick={handleBack}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center space-x-2 ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className={
                error.add === "null"
                  ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  : error.add
                  ? " inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold    border-grey text-grey  leading-tight uppercase rounded-full hover:text-grey focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
                  : " inline-block md:px-4 md:py-2 py-2 px-3 border-2 bg-dark_blue text-sm lg:text-[17px] font-bold hover:font-extrabold   text-white  leading-tight uppercase rounded-full hover:text-white  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
              }
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormLocation;
