import { Autocomplete, Box, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Functions from '../../Helpers/Functions';
import cities from '../../Assets/file/code_ville.json'

interface City {
    label: string,
    value: string
}

const SelectCity = (props: any) => {
    const [value, setValue] = useState<City | null>()
    const [options, setOptions] = useState<City[]>(cities)
    useEffect(() => {
        if (props.zipCode && props.zipCode !== '') {
            setOptions(Functions.getCities(props.zipCode))
        }else{
            setValue(null)
        }
    }, [props.zipCode])
    useEffect(() => {
        if (props.value) {
            setValue(Functions.getcity(props.value))
            props.callBack(props.value)
        }
    }, [])
    return (
        <div className='px-4 rounded-lg bg-input-color shadow-dashboard-setup-input h-max'>
            <label htmlFor="" className='block text-sm lg:text-lg md:text-base'>Ville de naissance</label>
            <Autocomplete
            disabled ={props.zipCode ? false : true}
                value={value}
                defaultValue={props.value ? Functions.getcity(props.value) : null}
                onChange={(event, newValue) => {
                    if (newValue) {
                        setValue(newValue);
                        props.callBack(newValue.label)
                    } else {
                        props.callBack("")
                    }
                }}
                id="city-select"
                options={options}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        sx={{
                            '&': {
                                fontFamily: "nunito",
                                fontSize: "1rem",
                                padding: 0
                            },
                            '& fieldset': {
                                border: "none",
                                fontFamily: 'nunito',
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot ': {
                                padding: 0,
                                fontSize: "1rem",
                            },
                            '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                                padding: "2px 0",
                                fontSize: "1rem",
                            },
                        }}
                        {...params}
                        placeholder="Sélectionnez votre ville"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />
        </div>
    );
};

export default SelectCity;