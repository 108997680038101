import { useEffect, useRef, useState } from "react";
// import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
// import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
// import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
// import ComponentBodyTableSubscription from "./ComponentBodyTableSubscription";
import ponct_dash from "../../../../../../../Assets/Images/ponct_dash.svg"
import SubscriptionCourseRepositorys from "../../../../../../../Repositories/SubscriptionCourseRepository";
import moment from "moment";
// import loader from '../../../../../../../Assets/Icons/loader-bleu.gif'
import Skeleton from '@mui/material/Skeleton';
import { RadioGroup } from "@mui/material";
import ComponentBodyTableSubscriptionPause from "./ComponentBodyTableSubscriptionPause";
// import StepperStore from "../../../../../../../LocalForage/StepperStore";
import * as React from 'react';

const ComponentSubscriptionPause = (props: any) => {
    const [course, setCourse] = useState<any>();
    const { handleLoad, load, isHere, isPlay, isDelete, handleEdit, handleClickOpenPlay, val } = props

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubscriptionCourseRepositorys.getActiveSubscriptionCourseByTeachrForDashboard(teachr.id).then(
                    (course: any) => {
                        setCourse(course);
                        handleLoad()
                    }
                );
            }
        });
    }, [load, handleLoad]);

    const [value, setValue] = useState('');
    const radioGroupRef = useRef<HTMLElement>(null);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value)
        props.courseId((event.target as HTMLInputElement).value)
    }

    return (
      <>
        {course === undefined ? (
          <div className="overflow-y-auto scroll w-full relative  h-[100%] md:mt-4 px-2 //py-2 //bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </div>
        ) : course.length === 0 ? (
          <div className="  md:w-full relative list-teacher h-[95%]  px-2 py-2 bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            <div className="mx-auto">
              <div className="md:w-4/5 mt-8 mx-auto ">
                <p className="text-[#4d4d4d] text-center lg:text-2xl md:text-lg text-base font-bold mx-auto ">
                  Afin de recevoir un paiement via notre plateforme, veuillez
                  déclarer votre premier cours, ainsi que tout autre cours que
                  vous avez déjà dispensé.
                </p>
              </div>
              <div className="mx-auto mt-16 md:w-1/2">
                <img src={ponct_dash} alt="ponct_dash" className="mx-auto" />
              </div>
            </div>
          </div>
        ) : (
          <div className="overflow-y-auto md:mt-4 scroll w-full relative  h-[100%] px-2 //py-2 bg-white  rounded-lg shadow-dashboard-setup-intro-form">
            {/* <div className=""> */}
            <RadioGroup
              ref={radioGroupRef}
              aria-label="ringtone"
              name="ringtone"
              value={value}
              onChange={handleChange}
            >
              <table className="w-full text-sm text-left  text-[#4d4d4d] ">
                <thead className="text-xs md:text-sm lg:text-base font-extrabold text-[#4d4d4d] //uppercase bg-gray-50 border-b-[2px] border-[#D2D2D2]">
                  <tr className=" w-full ">
                    {isHere && <th scope="col " className="py-2 px-1   "></th>}
                    <th scope="col " className="py-2 px-1 w-[16%]  ">
                      Date du debut
                    </th>
                    <th scope="col " className="py-2 px-1 w-[13%] ">
                      Élève
                    </th>
                    <th scope="col " className="py-2 px-1 w-[16%] ">
                      Matière
                    </th>
                    <th scope="col " className="py-2 w-[12%] //border-2">
                      <div className="//text-center">
                        <p>Jours </p>
                        <p className="text-sm hidden md:block lowercase text-grey font-light">
                          Par semaine
                        </p>
                      </div>
                    </th>
                    <th scope="col " className="py-2 ">
                      <div className="text-center">
                        <p>Rémunération</p>
                        <p className="text-sm text-grey font-light  lowercase">
                          Sur une semaine
                        </p>
                      </div>
                    </th>
                    <th scope="col " className="py-2 px-1 text-center w-[12%] ">
                      Durée
                    </th>
                    <th scope="col " className="py-2 ">
                      <div className="text-center">
                        <p>Durée totale </p>
                        <p className="text-sm hidden md:block lowercase text-grey font-light">
                          Par semaine
                        </p>
                      </div>
                    </th>
                  </tr>
                </thead>
                {course
                  ?.sort(function (a: any, b: any) {
                    const date1: any = moment(a.startDate).toDate();
                    const date2: any = moment(b.startDate).toDate();
                    return date1 - date2;
                  })
                  ?.map((punctual: any, key: any) => (
                    <ComponentBodyTableSubscriptionPause
                      punctual={punctual}
                      isHere={isHere}
                      isPlay={isPlay}
                      isDelete={isDelete}
                      handleEdit={handleEdit}
                      handleClickOpenPlay={handleClickOpenPlay}
                      val={val}
                      key={key}
                    />
                  ))}
              </table>
            </RadioGroup>
            {/* </div> */}
          </div>
        )}
      </>
    );
};

export default ComponentSubscriptionPause;
