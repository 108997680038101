import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import ValidateNni from '../../../../Validation/Nni';
import { useDispatch, useSelector } from 'react-redux';
import ValidateSiret from '../../../../Validation/Siret';

const Third = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [isActivity, setIsActivity] = useState<number>()
    const [socialTax, setSocialTax] = useState<number>()
    const [nni, setNni] = useState('')
    const [activityName, setActivityName] = useState('')
    const [error, setError] = useState({
        nni: true,
        isActivity: true,
        socialTax: true,
        activityName: true,
    })
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.third) {

            if (generalProcuration.second.third.isActivity !== undefined) {
                setIsActivity(generalProcuration.second.third.isActivity)
            }
            if (generalProcuration.second.third.activityName !== undefined) {
                setActivityName(generalProcuration.second.third.activityName)
            }

            if (generalProcuration.second.third.socialTax !== undefined) {
                setSocialTax(generalProcuration.second.third.socialTax);
            }
            if (generalProcuration && generalProcuration.second && generalProcuration.second.second) {
                if (generalProcuration.second.third.nni) {
                    setNni(generalProcuration.second.third.nni)

                }
            }
        }

    }, [generalProcuration])
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsActivity(parseInt((event.target as HTMLInputElement).value));
    };
    const handleChangeSocialTax = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSocialTax(parseInt((event.target as HTMLInputElement).value));
    };
    const validate = () => {
        let error_nni = true
        let error_social_tax = true
        let error_is_activity = false
        let error_activity_name = false
        error_nni = !ValidateNni(nni).isValid
        if (isActivity !== undefined && isActivity >= 0) {
            if (isActivity === 1) {
                if (!ValidateSiret(activityName).valid) {
                    error_activity_name = true
                }else{
                    error_activity_name = false
                }
            }
        }
        if (socialTax !== undefined && socialTax >= 0) {
            error_social_tax = false
        } else {
            error_social_tax = true
        }
        setError({
            nni: error_nni,
            isActivity: error_is_activity,
            socialTax: error_social_tax,
            activityName: error_activity_name
        })
        if (!error_social_tax && !error_is_activity && !error_nni && !error_activity_name) {
            return true
        }
        return false

    }
    const handleNext = () => {
        if (validate()) {
            if (generalProcuration.second.third) {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        step: 4,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: {
                                isActivity: isActivity,
                                activityName: activityName,
                                socialTax: socialTax,
                                nni: nni
                            },
                            fourth: generalProcuration.second.fourth!,
                            fifth: generalProcuration.second.fifth!,
                            sixth: generalProcuration.second.sixth!,
                            seventh: generalProcuration.second.seventh!,
                            eighth: generalProcuration.second.eighth!,
                            ninth: generalProcuration.second.ninth!,
                            tenth: generalProcuration.second.tenth!,
                            eleventh: generalProcuration.second.eleventh!
                        }
                    }
                })
            } else {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                         step: 4,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: {
                                isActivity: isActivity,
                                activityName: activityName,
                                socialTax: socialTax,
                                nni: nni
                            }
                        }
                    }
                })
            }
            // props.nextStep(4)
            props.setCurrentStep(4);
        }
    }
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col gap-y-2">
                {error.isActivity ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal lg:text-xl text-base font-semibold' htmlFor="">Avez-vous déjà exercé une activité non salarié en France ?</label>
                <RadioGroup row
                    value={isActivity}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.third ? generalProcuration.second.third.isActivity : null}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
                {
                    isActivity ? 
                    <div className="w-full">
                    {error.activityName ? <p className='text-third pb-1'>Veuillez entrer un numéro de SIRET valide</p> : null}
                    <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                        <label htmlFor="" className='lg:text-base block text-sm'>Quel était votre numéro de SIRET ?</label>
                        <input defaultValue={activityName} onChange={(e) => setActivityName(e.target.value)} type="text" placeholder='Ex: 9xxxxxxxxxxxxxx' className='w-full' />
                    </div>
                </div> : null
                }
            </div>
            <div className="flex  flex-col">
                {error.socialTax ? <p className='text-third pb-1'>Vous devez faire un choix</p> : null}
                <label className='text-normal lg:text-xl text-base font-semibold' htmlFor="">Pour quel mode de versement de vos cotisations sociales souhaitez-vous opter ? </label>
                <RadioGroup row
                    value={socialTax}
                    defaultValue={generalProcuration && generalProcuration.second && generalProcuration.second.third ? generalProcuration.second.third.socialTax : null}
                    name="customized-radios"
                    onChange={handleChangeSocialTax}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Mensuel" />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Trismestriel" />
                </RadioGroup>
            </div>
            <div className="w-full">
                {error.nni ? <p className='text-third pb-1'>Veuillez entrer une valeur</p> : null}
                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                    <label htmlFor="" className='lg:text-base block text-sm'>Quel est votre numéro de sécurité sociale : </label>
                    <input defaultValue={nni} onChange={(e) => setNni(e.target.value)} type="text" placeholder='Ex: 190097521412904' className='w-full' />
                </div>
            </div>
            <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Third;