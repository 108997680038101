import React from 'react';
import NavBar from '../../../Components/Navbar/NavBar';
import { Button } from '@mui/material';
import fonctionnmentIllu from '../../../Assets/Images/simulateur_fonctionnement.svg'
import simulateurIllu from '../../../Assets/Images/simulateur_simulateur.svg'
import simulateurBg from '../../../Assets/Images/simulateur_bg.svg'
import { useNavigate } from 'react-router-dom';
import NavbarHomePage from '../../../Components/Navbar/NavbarHomePage';

const Index = () => {
    const navigate = useNavigate()
    return (
        <div className='h-screen lg:pb-20 overflow-y-auto'>
            <header className='lg:bg-white bg-[rgba(239,246,255,1)]'>
                {/* <NavBar /> */}
                <NavbarHomePage theme = "light" />
            </header>
            <main className='h-full mt-24'>
                <div className='flex flex-col justify-around h-full bg-bottom bg-no-repeat bg-cover lg:px-14' style={{ backgroundImage: `url(${simulateurBg})` }}>
                    <div className='flex flex-col gap-y-2'>
                        <h4 className='text-[#B7B7B7] font-bold lg:text-lg'> FIXEZ VOS TARIFS ET Offrez des cours particuliers à MOITIER PRIX !"</h4>
                        <h1 className='font-[900] text-first lg:text-[32px] lg:leading-[43.65px] uppercase'>Déclarez vos cours particuliers et decouvrez vos avantages</h1>
                        <h2 className='font-extrabold text-first lg:text-[28px] lg:leading-[38.19px]'>Grâce à notre simulateur découvrez le bénéfice net de vos cours <br /> particuliers avec Teach’r après impôts et cotisations sociales. </h2>
                        <h3 className='font-bold text-first lg:text-2xl '>Surprise garantie 😮</h3>
                    </div>
                    <div className='flex flex-col w-4/5 gap-y-8'>
                        <div className='flex border-[3px] shadow-simulateur-index-simu border-radio rounded-[23px] px-4 py-8 bg-white gap-x-4 items-end' >
                            <img src={simulateurIllu} alt="" />
                            <p className='text-2xl font-bold text-normal'>Accéder au nouveau simulateur Teach’r, ici vous pourrez découvrir précisément combien d’impôts et de cotisations sociales vous paierez en donnant vos cours particuliers</p>
                            <div className='flex flex-col items-end gap-y-2'>
                                <span className='text-base font-bold text-radio'>NOUVEAU !</span>
                                <Button className='px-10 text-xl font-extrabold text-white rounded-full bg-radio w-max' onClick={()=>navigate('/teachr/simulate')}>ACCEDER AU SIMULATEUR</Button>
                            </div>
                        </div>
                        <div className='flex border-[3px] shad ow-simulateur-index-func border-first rounded-[23px] px-4 py-8 bg-white gap-x-4 items-end'>
                            <img src={fonctionnmentIllu} alt="" />
                            <p className='text-2xl font-bold text-normal'>Accéder au nouveau simulateur Teach’r, ici vous pourrez découvrir précisément combien d’impôts et de cotisations sociales vous paierez en donnant vos cours particuliers</p>
                            <div className='flex flex-col'>
                                {/* <span>NOUVEAU !</span> */}
                                <Button className='px-10 text-xl font-extrabold text-white rounded-full bg-first w-max' onClick={()=>navigate('/teachr/fonctionnement')}>FONCTIONNEMENT</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default Index;