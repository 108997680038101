// import { FormControlLabel } from '@mui/material';
import { useEffect, useState } from "react";
import StepperStore from "../../../../../../../LocalForage/StepperStore";
// import PunctualCourseRepository from "../../../../../../Repositories/PunctualCourseRepository";
import TeachrRepository from "../../../../../../../Repositories/TeachrRepository";
import { PunctualCourseDashboard } from "../../../../../../../Objects/models/PunctualCourseDashboard";
import UpdateChoiceSubscription from "./EditComponent/EditChoiceSubscription";
import SubscriptionCourseRepositorys from "../../../../../../../Repositories/SubscriptionCourseRepository";
import * as React from 'react';

const EditSubscriptionChoice = (props: any) => {
    const [course, setCourse] = useState<PunctualCourseDashboard[]>();
    const [value, setValue] = useState<any>();
    const [load, setLoad] = useState(false);
    const { backStep } = props


    useEffect(() => {
        setLoad(true)
        StepperStore.getItem("course_id").then((value) => {
            // setLoad(true)
            if (value !== null) {
                setValue(value);
                setLoad(false)
            }
        })
    }, [load])

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubscriptionCourseRepositorys.getActiveSubscriptionCourseByTeachrForDashboard(teachr.id).then(
                    (course: any) => {
                        setCourse(course);
                    }
                );
            }

        })
    }, []);

    return (
        <div className="shadow-dashboard-setup-intro-form md:mt-4 overflow-y-auto list-teacher md:px-20 py-2  h-[80%] px-4 py-4 bg-white rounded-xl">
            <div className="mb-6">
                <h1 className="lg:text-xl md:text-lg text-lg font-extrabold text-dark_blue">
                    Que souhaitez-vous modifier ?
                </h1>
            </div>
            {course?.map((option: PunctualCourseDashboard) => (
                <div>
                    {parseInt(value) === option.id && (
                        <div>
                            <UpdateChoiceSubscription backStep={backStep} option={option} key={option.id} id={option.id} />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default EditSubscriptionChoice;
