import { Button } from '@mui/material';
import React from 'react';

const Intervention = (props:any) => {
    return (
        <div>
            <h2 className='text-base font-extrabold text-first lg:text-2xl'>Périmètre et mode d’intervention</h2>
            <div className="flex flex-col pt-4 gap-y-2">
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>
                Vous devez ensuite séléctionner l’icone stylo en bleu afin de choisir le mode d’intervention : <span className='font-bold'>PRESTATAIRE</span> car nous sommes votre employeur, enfin choisisez <span className="font-bold">France entièr</span>e pour le périmètre d’intervention.
                </p>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-between w-full py-8 pb-4 md:px-8">
                
            <Button onClick={() => props.getStep(2)} className='px-8 font-extrabold uppercase rounded-full text-[#B7B7B7] lg:text-xl'>PRÉCEDENT</Button>
                <Button onClick={()=> props.getStep(4)} className='px-8 font-extrabold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
            </div>
        </div>
    );
};

export default Intervention;