import React, { useEffect, useState } from "react";
import IndexProfile from "./Profile/Index";
import IndexInfo from "./Info/Index";
import Teachr from "../../../Objects/models/Teachr";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Finish from "./Profile/Finish";

const CompleteProfileIndex = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const generalTeachr = useSelector((state: any) => state.teachr);
  const [showInfo, setShowInfo] = useState(true);
  const getStep = (value: number) => {
    setStep(value);
  };

  useEffect(() => {
    step === 5 ? setShowInfo(false) : setShowInfo(true);
  }, [step]);
  if (step === 7) {
    return <Finish />;
  } else {


    return (
      <div className='md:flex w-screen '>
        <div className='complete-profile-left w-full h-screen p-4 lg:p-14 md:p-8 overflow-y-auto overflow-x-hidden relative lg:pb-0'>
          <IndexProfile getStep={getStep} />
        </div>
        {showInfo && (
          <div
            className={`bg-[#F2F5FB] w-full h-screen lg:p-14 p-4 md:p-8 overflow-y-auto overflow-x-hidden relative lg:pb-0 lg:block`}
          >
            <IndexInfo step={step} />
          </div>
        )}
      </div>
    );
  }
};

export default CompleteProfileIndex;
