import { Type } from "class-transformer";
import "reflect-metadata";
import HydraEntity from "./HydraEntity";

export class SubscriptionCourseProposalDashboardUpdate extends HydraEntity {

  
    teachrPrice?: string;

    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;
    
    isFromTeachr?: boolean;

    constructor(
        teachrPrice: string,
    ) {
        super();
        this.teachrPrice = teachrPrice;
    }
}
