
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router';
import errorIcon from "../../../../../Assets/Images/error.png";
import Functions from '../../../../../Helpers/Functions';
import StepperStore from '../../../../../LocalForage/StepperStore';
import TeachrPutTva from '../../../../../Objects/models/TeachrPutTva';
import Teachr_Repository from '../../../../../Repositories/Teachr_Repository';
import { auth } from '../../../../../Services/FirebaseService';
import IndexPrice from './IndexPrice';
import React from 'react';
import { duration } from 'moment';
import {validatePrice} from '../UtilsDashboard/FunctionsDashbord'
import AlertInfo from '../UtilsDashboard/AlertInfo';


const StepPrice = (props: any) => {
    const { handleChangeStep } = props
    const [user] = useAuthState(auth)
    const [price, setPrice] = useState<any>()
    const [cours, setCours] = useState<any>()
    const [teachr, setTeachr] = useState<any>()
    const [duration, setDuration] = useState<any>()
    const [defaultFees, setDefaultFees] = useState<any>()
    const navigate = useNavigate()
    const [error, setError] = useState({
        price: "null"
    })

    useEffect(() => {
        Teachr_Repository.getCurrentTeachr().then((res: any) => {
            setTeachr(res)
            if (res?.defaultFees === null || res?.defaultFees === undefined) {
                setDefaultFees(0.1)
            }else{
                setDefaultFees(parseFloat(res?.defaultFees));
            }
        })
    }, [])


    useEffect(() => {
        (async () => {
            const value: any = await StepperStore.getItem("price" + user?.uid);
            if (value !== null) {
                setPrice(value.price);
            }
            const cours: any = await StepperStore.getItem("cours" + user?.uid);
            setCours(cours)

            const duration: any = await StepperStore.getItem("punctual" + user?.uid);
            const durationSub: any = await StepperStore.getItem("regulier" + user?.uid);
            const durationMonth: any = await StepperStore.getItem("punctualMonth" + user?.uid);
 
            if (cours.cours === "regulier") {
                if (durationSub !== null) {
                    setDuration(durationSub.rangeNumber);
                }
            } else if (cours.cours === "punctual") {
                if (duration !== null) {
                    setDuration(duration.rangeNumber);
                }
            }
            else if (cours.cours === "punctualMonth") {
              if (durationMonth !== null) {
                setDuration(durationMonth.duration);
              }
            }
        })();
    }, [user?.uid]);

    const montantFacture =  ((teachr?.tauxTva === 10? price * 0.9 : teachr?.tauxTva === 20 ? price * 0.8 : price) * 2 *  duration ).toFixed(3)

    const validate = () => {
        let errprice = true;
        if (!validatePrice(price, duration).isValid) {
            setError({ price: validatePrice(price, duration).message });
            errprice = true;
        } else {
            setError({ price: validatePrice(price, duration).message });
            errprice = false;
        }

        if (errprice) {
            return false;
        } else {
            return true;
        }
    };
    const handleSubmit = () => {
        if (validate()) {
            StepperStore.setItem("price" + user?.uid, { price: price });
            handleChangeStep(7)
        }
    };

    const handleBack = () => {
        if (cours.cours === "regulier") {
            handleChangeStep(3)
        } else if (cours.cours === "punctual") {
            handleChangeStep(4)
        } else if (cours.cours === "punctualMonth") {
          handleChangeStep(5);
        }
    }

      const handleChange = (e: any) => {
        // Convert input value to number if not empty, else reset to empty string
        const value = e.target.value === "" ? "" : Number(e.target.value);
        setPrice(value);
      };

    return (
      <div className="overflow-y-auto list-teacher relative h-[100%]  bg-white rounded-lg shadow-dashboard-setup-intro-form">
        <div className="relative h-[100%]">
          <div className="md:space-y-8 space-y-4 h-[90%] /h-[100%] md:p-10 p-6 ">
            <h2 className="font-extrabold /lg:text-3xl md:text-2xl text-xl text-dark_blue">
              Combien coûte une heure de cours à votre élève ?
            </h2>
            <div className="bg-input-color translate-y-6 shadow-md shadow-[rgba(0,0,0,25%)] rounded-md md:py-5 py-3 px-3 md:w-1/2 lg:text-lg md:text-base text-lg">
              <div className="flex p-2 text-base text-grey-900 md:p-0 lg:text-lg md:text-md">
                <div className="w-4/5 ">
                  <input
                    type="number"
                    min="11"
                    className="w-full bg-input-color focus:outline-none focus:ring-0 "
                    placeholder="Ex : 30"
                    onChange={(e) => handleChange(e)}
                    value={price}
                  />
                </div>
                <div className="w-1/6 font-bold ">€/h</div>
              </div>
            </div>
            <div className="flex pb-4 w-full mx-auto space-x-2 text-base translate-y-4 lg:text-lg md:text-md ">
              <p className="text-[#FF724F] /h-6 md:w-2/3 lg:text-lg md:text-md text-base //mb-4 md:-mt-[2px]">
                {error.price === "null" || error.price === ""
                  ? null
                  : validatePrice(price, duration).message}
              </p>
            </div>
            {price === undefined ? (
              ""
            ) : (
              <div className="flex text-base /md:translate-y-10 lg:text-lg md:text-base gap-x-4">
                  <IndexPrice
                    component={
                      <AlertInfo
                        msg={`Sur la base d'un montant de ${Functions.priceTotal(
                          price,
                          defaultFees
                        ).toFixed(
                          2
                        )}€ par heure dispensée. Pour une durée de ${Functions.renderDuration(
                          duration * 2
                        )} de cours, vous allez recevoir ${(
                          (1 - defaultFees) *
                          (teachr?.tauxTva === 10
                            ? price * 0.9
                            : teachr?.tauxTva === 20
                            ? price * 0.8
                            : price) *
                          2 *
                          duration
                        ).toFixed(2)}€ sur votre compte bancaire.`}
                        info="info"
                        customClass="lg:text-lg md:text-base text-sm"
                      />
                    }
                    price={price}
                    teachr={teachr}
                    df={defaultFees}
                  />
                {/* </div> */}
              </div>
            )}
            {teachr?.tauxTva === undefined || teachr?.tauxTva === 0
              ? ""
              : teachr?.tauxTva !== null && (
                  <div className="text-lg translate-y-24 lg:text-lg md:text-md //flex">
                    <div>
                      <p className="text-dark_blue">
                        Le prix que vous mentionnez ici est le prix TTC, en y
                        incluant vos {teachr?.tauxTva}% de TVA
                      </p>
                    </div>
                    <div className="//flex //gap-x-2">
                      <p>
                        Vous souhaitez modifier vos taux de TVA :{" "}
                        <strong
                          className="text-lg lg:text-lg md:text-md //flex text-dark_blue"
                          onClick={() => navigate("/teachr/profile")}
                        >
                          {" "}
                          cliquez ici
                        </strong>
                      </p>
                    </div>
                  </div>
                )}
          </div>

          <div className="absolute md:hidden flex justify-around mx-auto space-x-4 md:translate-y-44 w-full px-4">
            <div className="flex w-1/2 rounded-full bg-white text-orange  border-2 border-orange font-extrabold justify-center">
              <div
                onClick={() => handleBack()}
                className="text-xs pt-2 w-full text-center uppercase"
              >
                Précédent
              </div>
            </div>
            <div className="flex justify-center w-1/2 ">
              <div
                onClick={(e) => handleSubmit()}
                className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
              >
                Suivant
              </div>
            </div>
          </div>

          <div className="md:flex px-10 bottom-0 absolute hidden justify-between mx-auto pb-10 md:w-full">
            <div className="flex justify-center">
              <button
                type="button"
                onClick={() => handleBack()}
                className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
              >
                Précédent
              </button>
            </div>
            <div className="flex justify-center ">
              <button
                type="button"
                onClick={(e) => handleSubmit()}
                className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default StepPrice;
