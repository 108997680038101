import React, { useEffect, useState } from 'react';


import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DateInput from '../../../../Components/Admin/DateInput';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import ValidateSiret from '../../../../Validation/Siret';
import moment from 'moment';


const dateInputStyle = {
    backgroundColor: '#fff',
    padding: '2px 8px',
    boxShadow: 'none',
    border: '1px solid rgba(77,77,77,0.29)',
    borderRadius: '9.3px'

}

const Second = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationSap)
    const [firstName, setFirstName] = useState('')
    const [birthName, setBirthName] = useState('')
    const [birthDate, setBirthDate] = useState('')
    const [signCity, setSignCity] = useState('')
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [siret, setSiret] = useState('')
    const [error, setError] = useState({
        firstName: false,
        birthName: false,
        birthDate: false,
        signCity: false,
        city: false,
        address: false,
        siret: false
    })
    useEffect(() => {
        if (generalProcuration && generalProcuration.first) {
            if (generalProcuration.first.firstName) {
                setFirstName(generalProcuration.first.firstName)
            }
            if (generalProcuration.first.lastName) {
                setBirthName(generalProcuration.first.lastName)
            }
            if (generalProcuration.first.birthDate) {
                setBirthDate(generalProcuration.first.birthDate)
            }
            if (generalProcuration.first.birthCity) {
                setCity(generalProcuration.first.birthCity)
            }
            if (generalProcuration.first.signCity) {
                setSignCity(generalProcuration.first.signCity)
            }
            if (generalProcuration.first.address) {
                setAddress(generalProcuration.first.address)
            }
            if (generalProcuration.first.siret) {
                setSiret(generalProcuration.first.siret)
            }
        }
    }, [generalProcuration])
    const validate = () => {
        let error_firstName = true
        let error_birthName = true
        let error_birthDate = true
        let error_signCity = true
        let error_city = true
        let error_address = true
        let error_siret = true
        if (firstName === '') {
            error_firstName = true
        } else {
            error_firstName = false
        }
        if (birthName === '') {
            error_birthName = true
        } else {
            error_birthName = false
        }
        if (birthDate === '') {
            error_birthDate = true
        } else {
            error_birthDate = false
        }
        if (city === '') {
            error_city = true
        } else {
            error_city = false
        }
        if (address === '') {
            error_address = true
        } else {
            error_address = false
        }
        if (signCity === '') {
            error_signCity = true
        } else {
            error_signCity = false
        }
        error_siret = !ValidateSiret(siret).valid
        setError({
            firstName: error_firstName,
            birthName: error_birthName,
            birthDate: error_birthDate,
            signCity: error_signCity,
            city: error_city,
            address: error_address,
            siret: error_siret
        })
        if (!error_firstName && !error_birthName && !error_birthDate && !error_signCity && !error_city && !error_address && !error_siret) return true
        return false
    }
    const getBirthday = (value: string) => {
        setBirthDate(value)
    }
    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            if (response[0].geometry.location_type === "ROOFTOP") {
                setAddress(response[0].formatted_address)
                setSignCity(response[0].address_components[2].long_name)
            }
        })
    }
    const nextStep = () => {
        if (validate()) {
            if (generalProcuration && generalProcuration.second) {
                dispatch({
                    type: 'SET_SAP_INFO', payload: {
                        first: {
                            firstName: firstName,
                            lastName: birthName,
                            birthDate: birthDate,
                            birthCity: city,
                            siret: siret,
                            address: address,
                            signCity: signCity,
                        },
                        second: generalProcuration.second

                    }
                })
            } else {
                dispatch({
                    type: 'SET_SAP_INFO', payload: {
                        first: {
                            firstName: firstName,
                            lastName: birthName,
                            birthDate: birthDate,
                            birthCity: city,
                            siret: siret,
                            address: address,
                            signCity: signCity,
                        }

                    }
                })
            }
            props.callBack(2)

        }
    }

    return (
        <div className='flex lg:h-full gap-10'>

                <div className='relative w-full '>
                    <div className='flex items-end pb-10 gap-x-4'>
                        <span className='hidden lg:inline'>
                            <svg className='' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="16" fill="#0054A3" />
                                <path d="M20.4263 20.712C21.3063 20.712 21.7463 21.086 21.7463 21.834C21.7463 22.6113 21.3063 23 20.4263 23H12.9903C12.0956 23 11.6483 22.6113 11.6483 21.834C11.6483 21.086 12.0956 20.712 12.9903 20.712H15.2783V10.68L13.1663 11.978C12.961 12.0953 12.7776 12.154 12.6163 12.154C12.3083 12.154 12.0443 12.022 11.8243 11.758C11.619 11.494 11.5163 11.2007 11.5163 10.878C11.5163 10.4527 11.707 10.1227 12.0883 9.888L15.4983 7.776C15.9383 7.512 16.3563 7.38 16.7523 7.38C17.163 7.38 17.493 7.50467 17.7423 7.754C17.9916 8.00333 18.1163 8.348 18.1163 8.788V20.712H20.4263Z" fill="white" />
                            </svg>
                        </span>
                        <h2 className='text-first lg:text-[22px] text-lg font-bold'>Votre procuration :</h2>
                    </div>
                    <div className="grid items-end justify-between w-full lg:grid-cols-2 gap-4 grid-cols-1">
                        <div>
                            {error.firstName ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner un prénom</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block lg:text-base text-sm'>Prénom</label>
                                <input defaultValue={firstName} type="text" onChange={(e) => setFirstName(e.target.value)} className='placeholder:text-[#808080] text-sm lg:text-base bg-transparent w-full block' placeholder='Julie' />
                            </div>
                        </div>
                        <div>
                            {error.birthName ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner un nom</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block lg:text-base text-sm'>Nom de naissance</label>
                                <input defaultValue={birthName} type="text" onChange={(e) => setBirthName(e.target.value)} placeholder='Dupont' className='placeholder:text-[#808080] text-sm lg:text-base bg-transparent' />
                            </div>
                        </div>
                        <div>
                            {error.birthDate ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner une date</p> : null}
                            {generalProcuration && generalProcuration.first && generalProcuration.first.birthDate ? <DateInput defaultValue={moment(generalProcuration.first.birthDate, 'L').format('YYYY-MM-DD')} field='birthday' style={dateInputStyle} callBack={getBirthday} /> : <DateInput field='birthday' style={dateInputStyle} callBack={getBirthday} />}

                        </div>
                        <div>
                            {error.city ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner une ville</p> : null}
                            <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                <label htmlFor="" className='block lg:text-base text-sm'>Ville de naissance</label>
                                <input defaultValue={city} type="text" onChange={(e) => setCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-sm lg:text-base bg-transparent' />
                            </div>
                        </div>

                    </div>
                    <div className='my-4'>
                        {error.siret ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner un numéro de SIRET</p> : null}
                        <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full '>
                            <label htmlFor="" className='block lg:text-base text-sm'>Numéro de SIRET <span className='text-xs'>(SIREN + NIC)</span></label>
                            <input type="text" defaultValue={siret} onChange={(e) => setSiret(e.target.value)} placeholder='XXX XXX XXX XXXXX' className='placeholder:text-[#808080] text-sm lg:text-base bg-transparent w-full' />
                        </div>
                    </div>
                    <div className='w-full'>
                        <hr className='text-[rgba(128,128,128,0.80)] border-dashed my-8' />

                        <div className="flex items-end gap-4 flex-col lg:flex-row">
                            <div className='w-full'>
                                {error.address ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner une adresse valide</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-1 px-5 bg-white w-full'>
                                    <label htmlFor="" className='block lg:text-base text-sm'>Adresse</label>
                                    <AddressComponent default={address} getPlaceId={getAddress} placeholder="Ex:  2 rue de la Seine, 94004" />
                                </div>
                            </div>
                            <div className='w-full'>
                                {error.signCity ? <p className='pb-1 text-third text-sm lg:text-base'>Veuillez renseigner une ville</p> : null}
                                <div className='border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full'>
                                    <label htmlFor="" className='block lg:text-base text-sm'>Procuration faite à</label>
                                    <input defaultValue={signCity} type="text" onChange={(e) => setSignCity(e.target.value)} placeholder='Paris' className='placeholder:text-[#808080] text-sm lg:text-base bg-transparent' />
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="flex justify-end lg:py-10 pt-8">
                        <div className="flex lg:justify-between w-full lg:gap-10 flex-col lg:flex-row gap-y-4">
                            <Button onClick={() => props.callBack(0)} className='flex lg:w-1/3 lg:py-3 py-2 lg:text-xl font-semibold rounded-full gap-x-4 text-normal w-full lg:order-first order-last text-base '>
                                <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 1L1.18919 9.84521C1.09139 9.92523 1.09139 10.0748 1.18919 10.1548L12 19" stroke="#4D4D4D" strokeWidth="1.5" strokeLinecap="round" />
                                </svg>
                                PRÉCÉDENT
                            </Button>
                            <Button onClick={nextStep} className='lg:w-1/3 lg:py-3 py-2 lg:text-xl font-extrabold text-white rounded-full bg-first w-full text-base'>SUIVANT</Button>
                        </div>
                    </div>
                </div>

        </div>
    );
};

export default Second;