const regex = /^[12][0-9]{2}[0-1][0-9](2[AB]|[0-9]{2})[0-9]{3}[0-9]{3}[0-9]{2}$/



const ValidateNni = (nni: string) => {
  let NIR = nni.replace(/\s/g, '').substring(0, 13);
  let key = nni.replace(/\s/g, '').substring(13, 15);
  if (!regex.test(nni.replace(/\s/g, '')) &&
    !["3", "7", "8"].includes(nni.replace(/\s/g, '').charAt(0))) {
    return {
      isValid: false,
      message: "Veuillez entrer un numéro de sécurité sociale valide",
    };
  } else
    if (parseInt(key) !== 97 - (parseInt(NIR) % 97) &&
      !["3", "7", "8"].includes(nni.replace(/\s/g, '').charAt(0))) {
      return {
        isValid: false,
        message: "Veuillez entrer un numéro de sécurité sociale valide",
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }

};

export default ValidateNni;
