// import appStoreIcon from '../../../Assets/button/appstore.png'
// import playStoreIcon from '../../../Assets/button/googleplay.png'
// import qrCodeAppleBtn from '../../../Assets/Images/qr_code_apple.png'
// import qrCodePlaystoreBtn from '../../../Assets/Images/qr_code_androide.png'
import qrCodeAppleBtn from '../../../../Assets/Images/qr_code_apple.svg'
import qrCodePlaystoreBtn from '../../../../Assets/Images/qr_code_androide.svg'
import appStoreIcon from '../../../../Assets/Icons/apple-blue.svg'
import playStoreIcon from '../../../../Assets/Icons/play-blue.svg'
import appleIcon from '../../../../Assets/Icons/apple-blue.svg'
import playIcon from '../../../../Assets/Icons/play-blue.svg'
import cominghistique from "../../../../Assets/Images/cominghistique.png";

const HistoriqueComingsoon = () => {
  return (
    <div className="flex mt-6">
      <div className=" lg:pt-16 pt-8 md:w-2/3 flex flex-col ">
        <div className="w-full ">
        <h1 className="text-dark_blue  w-full lg:text-[32px] md:leading-[50px] text-[20px] md:text-[28px] font-semibold ">
          {" "}
          <span className="font-extrabold">
            Cette version n’est pas encore disponible !{" "}
          </span><br />
          Pour accéder à votre historique, Veuillez télécharger l’application
          mobile !
        </h1>
       <div className="items-end justify-between hidden w-2/3 lg:flex">
          <a href='https://apps.apple.com/fr/app/teachr/id1473591330' target="_blank" rel="noreferrer">
            <img src={qrCodeAppleBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-extrabold text-first '>Télécharger via l’App Store</p>
              <img src={appleIcon} className="w-5" alt="" />
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=fr.teachr.app" target="_blank" rel="noreferrer">
            <img src={qrCodePlaystoreBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-extrabold text-first '>Télécharger via Google play</p>
              <img src={playIcon} className="w-5" alt="" />
            </div>
          </a>
        </div>
        
        <div className="flex items-center justify-between gap-4 py-8 text-center md:w-2/4 lg:hidden">
          <div className=''>
            <img className='' src={appStoreIcon} alt="" />
          </div>
          <div className=''>
            <img className='' src={playStoreIcon} alt="" />
          </div>
        </div>
      </div>

        </div>
      <div className=" w-1/2 md:block hidden"><img src={cominghistique} alt="" /></div>
    </div>
  );
};

export default HistoriqueComingsoon;
