import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const Fourth = (props: any) => {
    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [select, setSelect] = useState(0)
    const [error, setError] = useState(false)
    const [other, setOther] = useState('')
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.fourth) {
            if (generalProcuration.second.fourth.value) {
              setSelect(generalProcuration.second.fourth.value);
              // props.nextStep(5);
            }
            if (generalProcuration.second.fourth.other !== undefined) {
                if (generalProcuration.second.fourth.value === 4 ) {
                    setOther(generalProcuration.second.fourth.other)
                }else{
                    setOther('')
                }
            }
        }
    }, [generalProcuration,other])
    const validate = () => {
        let err_select = true
        if (select > 0) {
             setError(false);
             err_select = false
        }else{
            setError(true);
            err_select = true
        }

        if (!err_select) {
            return true
        }
        
        return false
    }
    const handleNext = () => {
        if (validate()) {
          if (generalProcuration.second.fourth) {
            dispatch({
              type: "SET_AE_INFO",
              payload: {
                step: 5,
                first: generalProcuration.first,
                second: {
                  first: generalProcuration.second.first,
                  second: generalProcuration.second.second,
                  third: generalProcuration.second.third,
                  fourth: { value: select, other: other },
                  fifth: generalProcuration.second.fifth!,
                  sixth: generalProcuration.second.sixth!,
                  seventh: generalProcuration.second.seventh!,
                  eighth: generalProcuration.second.eighth!,
                  ninth: generalProcuration.second.ninth!,
                  tenth: generalProcuration.second.tenth!,
                  eleventh: generalProcuration.second.eleventh!,
                },
              },
            });
          } else {
            dispatch({
              type: "SET_AE_INFO",
              payload: {
                step: 5,
                first: generalProcuration.first,
                second: {
                  first: generalProcuration.second.first,
                  second: generalProcuration.second.second,
                  third: generalProcuration.second.third,
                  fourth: { value: select, other: other },
                },
              },
            });
          }
          // props.nextStep(5);
          props.setCurrentStep(5)
        }
    }
    // , [select, other])
    const nextStep = (value: number) => {
        if (value !== 4) {
            setOther('')
        }
        setSelect(value)
    }
    return (
        <div className=''>
            {error ? <p className='text-third pb-1'>Veuillez sélectionner un organisme</p> : null}
            <label htmlFor="" className='text-normal lg:text-xl text-base font-semibold'>Votre organisme d’assurance maladie ? </label>
            <ul className="flex flex-col lg:ml-8 gap-y-4 py-4 cursor-default mx-auto">
                <li onClick={() => nextStep(1)} className={`${select === 1 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>Agricole </li>
                <li onClick={() => nextStep(2)} className={`${select === 2 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>Non salarié</li>
                <li onClick={() => nextStep(3)} className={`${select === 3 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>ENIM </li>
                <li onClick={() => nextStep(5)} className={`${select === 5 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>Non agricole</li>
                <li onClick={() => nextStep(6)} className={`${select === 6 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>Régime général</li>
                <li onClick={() => nextStep(4)} className={`${select === 4 ? 'border-first bg-[#E8F5FF]' : ' border-[#808080] bg-white'} border rounded-[7px]  py-3 w-full px-4 text-normal text-sm lg:text-lg font-semibold hover:border-first hover:bg-[#E8F5FF]`}>Autre</li>
                {select === 4 && <div className='border rounded-[9.3px] border-[#808080] py-2 px-5 bg-white w-full'>
                   <label htmlFor="" className='lg:text-base block text-sm'>Renseignez votre organisme d’assurance maladie </label>
                   <input defaultValue={other} onChange={(e) => setOther(e.target.value)} type="text" placeholder='Ex: Ameli' className='w-full' />
                </div>}
            </ul>
            <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Fourth;