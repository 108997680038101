import React from "react";
import StepDeclare from "./StepDeclare/StepDeclare";
import StepLocation from "./StepDeclare/StepLocation";
import StepPrice from "./StepDeclare/StepPrice";
import StepConfirmation from "./StepDeclare/StepConfirmation";
import { useEffect, useState } from "react";
import StepperStore from "../../../../LocalForage/StepperStore";
import StepEnd from "./StepDeclare/StepEnd";
import Navbar from "../../../../Components/Admin/Navbar";
import StepPunctual from "./StepDeclare/CourseTypeDeclare/StepPunctual";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../../Services/FirebaseService";
import StepPunctualPastFirst from "./StepDeclare/CourseTypeDeclare/StepPunctualPastFirst";
import StepSubscription from "./StepDeclare/CourseTypeDeclare/StepSubscription";
import { renderSlider } from "./AccueilDashboard/DashboardLayout";
import StepPunctualMonth from "./StepDeclare/CourseTypeDeclare/StepPunctualMonth";
import StepCourseForAll from "./StepDeclare/DeclareAllCourse/StepCourseForAll";
import { AddStudentPage } from "./AddStudentPage";
import { useLocation } from "react-router-dom";

const DeclareCourse = () => {
  const [user] = useAuthState(auth);
  const [currentStep, setCurrentStep] = useState<any>(1);
  const location = useLocation();
  const [state] = useState<any>(location.state);
  
  // const [customFees, setCustomFees] = useState<any>()
  const handleChangeStep = (val: number) => {
    setCurrentStep(val);
  };
  const renderStepScreen = (step: number) => {
    switch (step) {
      case 1:
        if (state === 2) {
          return <StepLocation handleChangeStep={handleChangeStep} state={state} />;
        }else{
          return <StepDeclare handleChangeStep={handleChangeStep} />;
        }
      case 2:
        return <StepLocation handleChangeStep={handleChangeStep} state={state} />;
      case 3:
        return <StepSubscription handleChangeStep={handleChangeStep} />;
      case 4:
        return <StepPunctual handleChangeStep={handleChangeStep} />;
      case 5:
        return <StepPunctualMonth handleChangeStep={handleChangeStep} />;
      case 6:
        return <StepPrice handleChangeStep={handleChangeStep} />;
      case 7:
        return <StepConfirmation handleChangeStep={handleChangeStep} />;
      case 8:
        return <StepEnd handleChangeStep={handleChangeStep} />;
      case 9:
        return <StepPunctualPastFirst handleChangeStep={handleChangeStep} />;
      case 10:
        return <StepCourseForAll handleChangeStep={handleChangeStep} />;
      default:
        return <StepDeclare handleChangeStep={handleChangeStep} />;
    }
  };
  useEffect(() => {
    if (user) {
      if (currentStep !== 10) {
        StepperStore.setItem("activeStep" + user.uid, currentStep);
      }
      StepperStore.removeItem("activeStepundefined");
      StepperStore.removeItem("clientundefined");
    }
  }, [currentStep, user]);

  useEffect(() => {
    (async () => {
      if (user) {
        try {
          const value = await StepperStore.getItem("activeStep" + user.uid);
          switch (value) {
            case null:
              setCurrentStep(1);
              break;
            case 8:
              setCurrentStep(1);
              break;
            default:
              setCurrentStep(value);
              break;
          }
        } catch (err) {
            setCurrentStep(1);
        }
      }
    })();
  }, [user]);
// `${ errorPhone !== "" ? "block" : "hidden" } text-third text-xs md:text-sm pb-1`}
  return (
    <div className="h-screen lg:overflow-hidden bg-[#F4FAFF]">
      <Navbar index={1} />
      <div className={` ${currentStep === 10 ? "" : "grid lg:grid-cols-3 gap-4 grid-cols-1"} mt-4 lg:px-10 px-4 h-[82%]`}>
        <div className={ `${currentStep === 10 ? "" : "lg:col-span-2"} lg:h-[100%]`}>
          {renderStepScreen(currentStep)}
        </div>
        <div className={ currentStep === 10 ? "hidden" : "bg-white md:col-span-1  lg:flex hidden relative rounded-lg lg:shadow-simulator-dashboard /md:p-8 /lg:w-3/5 items-center gap-x- justify-between pt-8 p-1 /min-h-[523px] h-[100%]"}>
          {renderSlider()}
        </div>
      </div>
    </div>
  );
};

export default DeclareCourse;
