import HydraEntity from "./HydraEntity";
import Client from "./Client";
import { Type } from "class-transformer";
import PromotionalCode from "./PromotionalCode";
import "reflect-metadata";

export default class AvailablePromotionalCode extends HydraEntity {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;
    readonly id?: number;

    @Type(() => Client)
    owner?: string | Client;

    @Type(() => PromotionalCode)
    promotionalCode?: PromotionalCode;

    constructor(
        owner: string | Client,
        promotionalCode: PromotionalCode
    ) {
        super();
        this.owner = owner;
        this.promotionalCode = promotionalCode;
    }
}
