import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import SubjectsSelects from "../../../../../../Components/utils/SelectMatiere";
import cours_ponctuel from '../../../../../../Assets/Icons/pastcourse.svg'
import StepperStore from "../../../../../../LocalForage/StepperStore";
import { auth } from "../../../../../../Services/FirebaseService";
import validateSelects from "../../../../../../Validations/MultiStep";
// import Functions from "../../../../../../Helpers/Functions";
// import Slider from '@mui/material/Slider';
// import Indexduration from "../IndexPrice";
// import Teachr_Repository from "../../../../../../Repositories/Teachr_Repository";
// import Functions from "../../../../../../Helpers/Functions";
import { styled, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { InputBase } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
// import ClientRepository from "../../../../../../Repositories/ClientRepository";
import Teachr_Repository from "../../../../../../Repositories/Teachr_Repository";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
// import Client from "../../../../../../Objects/models/Client";
import moment from "moment";


const months = {
    "01": "Janvier",
    "02": "Février",
    "03": "Mars",
    "04": "Avril",
    "05": "Mai",
    "06": "Juin",
    "07": "Juillet",
    "08": "Août",
    "09": "Septembre",
    "010": "Octobre",
    "011": "Novembre",
    "012": "décembre",
};
const TeachrInput = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        fontSize: 14,
    },
}));
const TeachrInputs = styled(InputBase)(({ theme }) => ({
    "& .MuiInputBase-input": {
        borderRadius: 4,
        position: "relative",
        backgroundColor: "transparent",
        border: "none",
        padding: "0",
        fontSize: 17,
    },
}));
const Menu = styled(MenuItem)(({ theme }) => ({
    "&": {
        border: "2px solid #0054A3",
        borderRadius: "5px",
        color: "#0054A3",
        padding: ".5rem",
        paddingLeft: "4rem",
        margin: ".2rem",
        textAlign: "center !important",
    },
    "&.Mui-selected": {
        border: "2px solid #0054A3",
        borderRadius: "5px",
        color: "white",
        backgroundColor: "#0054A3",
    },
}));
function getStyles() {
    return {
        fontWeight: "bold",
        fontFamily: "Nunito",
    };
}
const useStyles = makeStyles({
    select: {
        "& ul": {
            backgroundColor: "F2F5FB !important",
            display: "grid !important",
            gridTemplateColumns: "30% 30% 30% !important",
            gap: "10px !important",
            paddingLeft: "3.5rem ",
            fontFamily: "Nunito",
        },
        "& li": {
            fontSize: 15,
        },
    },
});
const useStylesMobile = makeStyles({
    select: {
        "& ul": {
            backgroundColor: "F2F5FB !important",
            display: "block !important",
            gridTemplateColumns: "30% 30% 30% !important",
            gap: "10px !important",
            paddingLeft: "3rem ",
            paddingRight: "2rem ",
            fontFamily: "Nunito",
        },
        "& li": {
            fontSize: 12,
        },
    },
});
const validateDuration = (value) => {
    if (value === undefined) {
        return {
            isValid: false,
            message: "Veuillez entrer le prix",
        };
    }
    else
        return {
            isValid: true,
            message: "",
        };
}

const StepPunctualPastFirst = (props) => {
    const { handleChangeStep } = props

    const theme = useTheme();
    const classes = useStyles();
    const classe = useStylesMobile();
    const [month, setMonth] = useState();
    const [user] = useAuthState(auth)
    const [duration, setDuration] = useState()
    const [client, setClient] = useState()
    const [matiere, setMatiere] = useState();
    const [dates, setDates] = useState();
    const [datesEnd, setDatesEnd] = useState();
    const [error, setError] = useState({
        matiere: "null",
        duration: "null"
    });

    useEffect(() => {
        Teachr_Repository.getCurrentTeachr().then((teachr) => {
            StepperStore.getItem("client" + user.uid).then((client) => {
                const idClient = parseInt(client.client["@id"].substr(9));
                TeachrRepository.get_client_by_teacher_favorite(teachr?.id).then((client) => {
                    client.clients?.map((res) => {
                        if (res?.id === idClient) {
                            setClient(res);
                        }
                    });
                });
            });
        });
    
        var date = new Date();
        var dateEnd = new Date();
        date.setFullYear(new Date().getFullYear());
        dateEnd.setFullYear(new Date().getFullYear());
        var clientCreationDate = moment(client?.creation_datetime).toDate();
        var endDateOfMonth = moment(clientCreationDate).endOf('month').toDate()
        
        switch (month) {
            case '01':
                date.setMonth(0);
                dateEnd.setMonth(0);
                break;
            case '02':
                date.setMonth(1);
                dateEnd.setMonth(1);
                break;
            case '03':
                date.setMonth(2);
                dateEnd.setMonth(2);
                break;
            case '04':
                date.setMonth(3);
                dateEnd.setMonth(3);
                break;
            case '05':
                date.setMonth(4);
                dateEnd.setMonth(4);
                break;
            case '06':
                date.setMonth(5);
                dateEnd.setMonth(5);
                break;
            case '07':
                date.setMonth(6);
                dateEnd.setMonth(6);
                break;
            case '08':
                date.setMonth(7);
                dateEnd.setMonth(7);
                break;
            case '09':
                date.setMonth(8);
                dateEnd.setMonth(8);
                break;
            case '010':
                date.setMonth(9);
                dateEnd.setMonth(9);
                break;
            case '011':
                date.setMonth(10);
                dateEnd.setMonth(10);
                break;
            case '012':
                date.setMonth(11);
                dateEnd.setMonth(11);
                break;
            default:
                break;
        }
        date.setDate(1);
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        
        dateEnd.setDate(0);
        dateEnd.setHours(0);
        dateEnd.setMinutes(0);
        dateEnd.setSeconds(0);

        if (date > clientCreationDate) {
            setDates(date.toISOString());
            setDatesEnd(dateEnd.toISOString());
        }else{
            setDates(clientCreationDate.toISOString());
            setDatesEnd(endDateOfMonth.toISOString());
        }
    
    }, [client?.creation_datetime, month, user.uid]);
    


    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setMonth(value);
    };
    const getMatiere = (value) => {
        setMatiere(value);
    };
    const validate = () => {
        let errMatiere = true;
        let errduration = true;
        if (!validateSelects(matiere).isValid) {
            setError({
                matiere: validateSelects(matiere).message,
                duration: validateDuration(duration).message,
            });
            errMatiere = true;
        } else {
            setError({
                matiere: validateSelects(matiere).message,
                duration: validateDuration(duration).message,
            });
            errMatiere = false;
        }
        if (!validateDuration(duration).isValid) {
            setError({
                matiere: validateSelects(matiere).message,
                duration: validateDuration(duration).message
            });
            errduration = true;
        } else {
            setError({
                matiere: validateSelects(matiere).message,
                duration: validateDuration(duration).message
            });
            errduration = false;
        }


        if (errMatiere || errduration) {
            return false;
        } else {
            return true;
        }
    };
    const handleChange2 = (e) => {
        let result = e.target.value;

        if (e.target.value.length === 1) {
            result = `0${e.target.value}h`;
        }
        else if (e.target.value.length >= 3) {
            const res1 = result.slice(1, 2);
            const res2 = result.slice(3, 5);
            // result = `${res1}${res2}h${e.target.value.slice(3,5)}`;
            result = `${res1}${res2}h`;
            if (result.length === 4) {
                result = `${result.substr(0, 2)}h30`;;
                setDuration(result);
            }
        }

        setDuration(result);
    };

    const handleKeyDown = (e) => {
        if (duration.length > 4) {
            if (e.key === "0" || e.key === "1" || e.key === "2" || e.key === "3" || e.key === "4" || e.key === "5" || e.key === "6" || e.key === "7" || e.key === "8" || e.key === "9") {
                setDuration(""); // Réinitialise la valeur si l'utilisateur supprime la dernière caractère avec le bouton "Supprimer"
            }
        }
        if ((e.key === "Backspace" || e.key === "Enter" || e.key === "Delete") && e.target.value.length >= 1) {
            setDuration(""); // Réinitialise la valeur si l'utilisateur supprime la dernière caractère avec le bouton "Supprimer"
        }
    }
    const handleFormatDuration = (value) => {
        let val = ""
        if (value.length === 3) {
            val = parseInt(value.substr(0, 2) * 2)
        } else {
            val = value.substr(0, 2)
            // parseInt(val*2) + 
        }
        // parseInt(val*2)
        return (val);
    }
    const handleNext = () => {

        if (validate()) {
            StepperStore.setItem("dataPast" + user?.uid, {
                matiere: matiere,
                duration: handleFormatDuration(duration),
                month: dates,
                monthEnd: datesEnd,
            });
            handleChangeStep(6)
        }
    };

    return (
        <div className="overflow-y-auto pb-6  list-teacher relative md:h-[100%] h-[120%] bg-white  xl:px-14 px-4  rounded-lg shadow-dashboard-setup-intro-form">
            <div className="space-y-4 md:h-[80%]">
                <div className="mt-10">
                    <div className="//w-1/2 flex space-x-3">
                        <div>
                            <img
                                src={cours_ponctuel}
                                alt=""
                                className="rounded-full w-8 "
                            />
                        </div>
                        <div>
                            <p className=" text-dark_blue font-extrabold lg:text-xl mt-1 text-lg">
                                Cours déjà effectués :
                            </p>
                        </div>
                    </div>
                </div>

                <div className="xl:translate-y-10 md:translate-y-2 lg:-translate-y-6 -translate-y-3 grid md:grid-cols-4 grid-cols-1 md:gap-4 gap-2">
                    <div className="md:col-span-2">
                        <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                            <label
                                htmlFor=""
                                className="lg:text-lg md:text-base text-sm font-medium"
                            >
                                {" "}
                                Nombre d’heure par cours
                            </label>
                            <div className=" h-10 md:mt-0 -mt-2 md:translate-y-0 translate-y-4">
                                <div className="w-4/5">
                                    <input
                                        type="text" 
                                        // min="11"
                                        id="firs_firstName"
                                        className="w-full bg-transparent focus:outline-none focus:ring-0 "
                                        placeholder="Ex : 1h30 "
                                        onChange={handleChange2}
                                        value={(duration)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex space-x-2 text-lg translate-y-2 ">
                            <p className="text-[#FF724F] md:h-6 h-10 w-full text-sm md:text-base ">
                                {error.duration === "null" || error.duration === ""
                                    ? null
                                    : validateDuration(duration).message}
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-2">
                        <div className="shadow-md shadow-[rgba(0,0,0,25%)] md:-py-4 bg-input-color rounded-md w-full lg:text-lg md:text-base text-sm">
                            <div className=" w-full translate-y-2 ">
                                <label
                                    htmlFor=""
                                    className="lg:text-lg md:text-base px-3  text-sm font-medium"
                                >
                                    {" "}
                                    Matière
                                </label>
                            </div>
                            <div className="">
                                <SubjectsSelects
                                    callBack={getMatiere}
                                    validation={validateSelects}
                                    value={matiere}
                                    placeholder="Ex : Mathématiques"
                                />
                            </div>
                        </div>
                        <div className="flex space-x-2 text-lg md:translate-y-2 translate-y-2 ">
                            <p className="text-[#FF724F] md:h-6 h-10 w-full text-sm md:text-base ">
                                {error.matiere === "null" || error.matiere === ""
                                    ? null
                                    : validateSelects(matiere).message}
                            </p>
                        </div>
                    </div>
                    <div className="md:col-span-4">
                        <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 md:mt-8 mt-1 px-3 w-full lg:text-lg md:text-base text-sm">
                            <label
                                htmlFor=""
                                className="text-sm font-medium lg:text-lg md:text-base"
                            >
                                Séléctionner le mois :
                            </label>
                            <div className="relative hidden  font-nunito md:block">
                                <Select
                                    sx={{
                                        width: "100%",
                                        paddingLeft: "",
                                        fontFamily: "nunito",
                                        background: "#F2F5FB !important",
                                    }}
                                    // multiple
                                    MenuProps={{ classes: { paper: classes.select } }}
                                    label={"Text"}
                                    value={month}
                                    onChange={handleChange}
                                    input={<TeachrInputs />}
                                    placeholder="Lundi, Mardi, Mercredi..."
                                    className="text-xs"
                                >
                                    {Object.entries(months).map(([key, month]) => (
                                        <Menu
                                            key={month}
                                            value={key}
                                            style={getStyles(month, month, theme)}
                                            placeholder="Lundi, Mardi, Mercredi..."
                                            className="text-sm text-center border lg:text-lg md:text-base"
                                        >
                                            {month}
                                        </Menu>
                                    ))}
                                </Select>
                            </div>
                            <div className="relative mt-1  font-nunito md:hidden">
                                <Select
                                    sx={{
                                        width: "100%",
                                        paddingLeft: "",
                                        fontFamily: "nunito",
                                        background: "#F2F5FB",
                                    }}
                                    // multiple
                                    label={"Text"}
                                    value={month}
                                    onChange={handleChange}
                                    input={<TeachrInput />}
                                    MenuProps={{ classes: { paper: classe.select } }}
                                    placeholder="Lundi, Mardi, Mercredi..."
                                    className="text-xs displayDay"
                                >
                                    {Object.entries(months).map(([key, month]) => (
                                        <Menu
                                            key={month}
                                            value={key}
                                            style={getStyles(month, month, theme)}
                                            placeholder="Lundi, Mardi, Mercredi..."
                                            className="text-sm text-center border lg:text-lg md:text-base "
                                        >
                                            {month}
                                        </Menu>
                                    ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="md:hidden flex justify-around space-x-4 pb-4 translate-y-6">
                <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
                    <div
                        onClick={() => {
                            handleChangeStep(1);
                        }}
                        className="text-xs pt-2 w-full text-center uppercase"
                    >
                        Précédent
                    </div>
                </div>
                <div className="flex justify-center w-1/2 ">
                    <div
                        onClick={() => {
                            handleNext();
                        }}
                        className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-xs border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-[#4d4d4d]  "
                    >
                        Suivant
                    </div>
                </div>
            </div>

            <div className=" hidden //border-2 h-[10%] //xl:translate-y-10 //lg:-translate-y-3 //translate-y-6 //xl:pb-3 //pb-1  md:flex justify-between  //right-0 xl:px-14">
                <div>
                    <button
                        onClick={() => {
                            handleChangeStep(1);
                        }}
                        className="py-2 font-extrabold text-first uppercase rounded-full text-sm px-14 bg-white border-2 border-first"
                    >
                        precedent
                    </button>
                </div>
                <div className="">
                    <button
                        onClick={() => {
                            handleNext();
                        }}
                        className="py-2 font-extrabold text-white uppercase rounded-full text-sm px-14 bg-first border-2 border-first"
                    >
                        SUIVANT
                    </button>
                </div>
            </div>
        </div>
    );
};

export default StepPunctualPastFirst;