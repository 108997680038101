import { Type } from "class-transformer";
import HydraEntity from "./HydraEntity";
import "reflect-metadata";
import Teachr from "./Teachr";

declare class PunctualCourse {}


export default class CourseProposal extends HydraEntity {
    @Type(() => Teachr)
    teachr: string; // 
    teachr_id?: number;
    punctualCourse: PunctualCourse;
    @Type(() => Date)
    startDatetime: Date | string;
    @Type(() => Date)
    endDatetime: Date | string;
    teachrPrice: string;
    description: string | null;
    fees?: number;
    isRefused?: boolean; // the client update this value to true to refuse it
    lateMinutes?: number; // the teachr update this value to signal he is late
    URSSAFPrice?: number;

    number?: number; // used to blur cards is non premium account in AnswerCardSubscriptionCourse
    stripeFees ?: number;

    totalPrice?: string;
    hourlyPrice?: string;

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;
    readonly id?: number;
    @Type(() => Date)
    readonly lastModificationDatetime?: Date | string;
    @Type(() => Date)
    readonly creationDatetime?: Date | string;
    readonly isAccepted?: boolean; // if the proposal has been accepted or not
    courseProposal: any;

    isFromTeachr?:boolean;

    constructor(
        teachr: string,
        punctualCourse: string,
        startDatetime: Date | string,
        endDatetime: Date | string,
        teachrPrice: string,
        description: string | null = null
    ) {
        super();
        this.teachr = teachr;
        this.punctualCourse = punctualCourse;
        this.startDatetime = startDatetime;
        this.endDatetime = endDatetime;
        this.teachrPrice = teachrPrice;
        this.description = description;
    }
}
