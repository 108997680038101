import React, { useEffect, useState } from "react";
import validateCustom from "../../../../Validations/CustomList";
import "antd/dist/antd.css";
import StepperStore from "../../../../LocalForage/StepperStore";
import Functions from "../../../../Helpers/Functions";
import { styled, useTheme } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { InputBase } from "@mui/material";
import Select from "@mui/material/Select";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import DatePickers from "../../../utils/DatePickers";
import { auth } from "../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { TimePicker } from "antd";
require('../../../../Styles/multiselect.css')

const format = "HH:mm";
const days = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
};
const TeachrInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 14,
  },
}));
const TeachrInputs = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent",
    border: "none",
    padding: "0",
    fontSize: 17,
  },
}));
const Menu = styled(MenuItem)(({ theme }) => ({
  "&": {
    border: "2px solid #0054A3",
    borderRadius: "5px",
    color: "#0054A3",
    padding: ".5rem",
    paddingLeft: "4rem",
    margin: ".2rem",
    textAlign: "center !important",
  },
  "&.Mui-selected": {
    border: "2px solid #0054A3",
    borderRadius: "5px",
    color: "white",
    backgroundColor: "#0054A3",
  },
}));
function getStyles() {
  return {
    fontWeight: "bold",
    fontFamily: "Nunito",
  };
}
const useStyles = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "grid !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3.5rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 15,
    },
  },
});
const useStylesMobile = makeStyles({
  select: {
    "& ul": {
      backgroundColor: "F2F5FB !important",
      display: "block !important",
      gridTemplateColumns: "30% 30% 30% !important",
      gap: "10px !important",
      paddingLeft: "3rem ",
      paddingRight: "2rem ",
      fontFamily: "Nunito",
    },
    "& li": {
      fontSize: 12,
    },
  },
});

const CoursRegulier = (props) => {
  moment.locale("fr");
  const classes = useStyles();
  const classe = useStylesMobile();
  const [user] = useAuthState(auth);
  const mostLikelyTime = moment();
  mostLikelyTime.set({ hour: 17, minute: 0 });
  const today = new Date();
  mostLikelyTime.set({ hour: 17, minute: 0 });
  const start = moment().set({ hour: 17, minute: 0 });
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [date, setDate] = useState(today);
  const [startTime, setStartTime] = useState(start);
  const [error, setError] = useState({
    day: "null",
  });
  const getDate = (value) => {
    setDate(value);
  };

  const theme = useTheme();
  const [day, setDay] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setDay(typeof value === "string" ? value.split(",") : value);
  };

  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
  };

  const validate = () => {
    let errday = true;
    if (!validateCustom(day).isValid) {
      setError({ day: validateCustom(day).message });
      errday = true;
    } else {
      setError({ day: validateCustom(day).message });
      errday = false;
    }

    if (errday) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    (async () => {
      const value = await StepperStore.getItem(
        user ? "courseType" + user.uid : "courseType"
      );

      if (value.type === "Régulier") {
        setDate(value.data.date);
        setDay(value.data.day);
        setRangeNumber(value.data.rangeNumber);
      }
    })();
  }, [user]);

  const handleSubmit = (event) => {
    if (validate()) {
      StepperStore.setItem(
        user ? "courseType" + user.uid : "courseType",
        {
          type: "Régulier",
          data: {
            date: date,
            startTime: startTime.toDate().valueOf(),
            day: day,
            rangeNumber: rangeNumber,
          },
        },
        function (err) {}
      );
      props.handleNext();
    }
    event.preventDefault();
  };

  const calculateTotalHours = () => {
    let total = rangeNumber * day.length;
    return total;
  };

  return (
    <div className="mt-4 translate-y-24 space-y- md:space-y-6 lg:translate-y-10 md:translate-y-20">
      <div className="w-4/5 mx-auto ">
        <h5 className="text-lg font-bold lg:text-3xl md:text-2xl text-dark_blue">
          Suivi régulier :
        </h5>
      </div>
      <div className="w-4/5 mx-auto ">
        <div className="mt-4 md:w-2/3 md:mt-6">
          <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
            <label
              htmlFor=""
              className="text-sm font-medium lg:text-lg md:text-base"
            >
              {" "}
              Nombre d'heures par jour
            </label>
            <div className="h-10 -mt-2  md:mt-0">
              <Functions.PrettoSlider
                defaultValue={1}
                step={0.5}
                min={1}
                max={4}
                value={rangeNumber}
                onChange={handleSliderChange}
              />
            </div>
          </div>
          <span className="font-bold text-dark_blue">
            <input
              type="text"
              value={Functions.renderDuration(rangeNumber * 2) + "/jour"}
              onChange={(e) => setRangeNumber(e.target.value)}
            />
          </span>
        </div>
        <div className="z-50 shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 md:mt-8 mt-3 px-3 w-full lg:text-lg md:text-base text-sm">
          <label
            htmlFor=""
            className="text-sm font-medium lg:text-lg md:text-base"
          >
            Choisissez vos jours :
          </label>
          <div className="relative hidden  font-nunito md:block">
            <Select
              sx={{
                width: "100%",
                paddingLeft: "",
                fontFamily: "nunito",
                background: "#F2F5FB !important",
              }}
              multiple
              MenuProps={{ classes: { paper: classes.select } }}
              label={"Text"}
              value={day}
              onChange={handleChange}
              input={<TeachrInputs />}
              placeholder="Lundi, Mardi, Mercredi..."
              className="text-xs"
            >
              {Object.entries(days).map(([key, day]) => (
                <Menu
                  key={day}
                  value={key}
                  style={getStyles(day, day, theme)}
                  placeholder="Lundi, Mardi, Mercredi..."
                  className="text-sm text-center border lg:text-lg md:text-base"
                >
                  {day}
                </Menu>
              ))}
            </Select>
          </div>
          <div className="relative mt-1  font-nunito md:hidden">
            <Select
              sx={{
                width: "100%",
                paddingLeft: "",
                fontFamily: "nunito",
                background: "#F2F5FB",
              }}
              multiple
              label={"Text"}
              value={day}
              onChange={handleChange}
              input={<TeachrInput />}
              MenuProps={{ classes: { paper: classe.select } }}
              placeholder="Lundi, Mardi, Mercredi..."
              className="text-xs displayDay"
            >
              {Object.entries(days).map(([key, day]) => (
                <Menu
                  key={day}
                  value={key}
                  style={getStyles(day, day, theme)}
                  placeholder="Lundi, Mardi, Mercredi..."
                  className="text-sm text-center border lg:text-lg md:text-base "
                >
                  {day}
                </Menu>
              ))}
            </Select>
          </div>
        </div>
        <span className="font-bold text-dark_blue">
          <input
            type="text"
            value={
              "Total : " +
              Functions.renderDuration(calculateTotalHours() * 2) +
              "/semaine"
            }
            onChange={(e) => setRangeNumber(e.target.value)}
          />
        </span>
        <div className="flex w-full mx-auto space-x-2  md:translate-y-1 md:mb-4">
          {error.day === "null" || error.day === ""
            ? " "
            : validateCustom(day).icon}
          <p className="text-[#FF724F] h-6 w-full lg:text-base  md:text-sm text-xs md:-mt-1  ">
            {error.day === "null" || error.day === ""
              ? null
              : validateCustom(day).message}
          </p>
        </div>

        <div className="block w-full gap-8 mx-auto space-y-6 md:space-y-0 md:flex mt-">
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="text-sm font-medium lg:text-lg md:text-base"
              >
                {" "}
                À partir du :
              </label>
              <div className="-mt-6 translate-y-2 md:-mt-3 md:translate-y-0 ">
                <div className="relative -ml-4 ">
                  <DatePickers value={date} callBack={getDate} />
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-1/2">
            <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-sm">
              <label
                htmlFor=""
                className="text-sm font-medium lg:text-lg md:text-base"
              >
                {" "}
                À partir de :
              </label>
              <div className="-ml-3">
                <div
                  className={
                    "flex items-center md:hidden justify-between w-full ml-1 pt-2 rounded-lg "
                  }
                >
                  <TimePicker
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    TimePicker
                    showNow={false}
                    clearIcon=""
                    size="small"
                    suffixIcon=""
                  />
                </div>
                <div
                  className={
                    "md:flex items-center hidden justify-between w-full rounded-lg "
                  }
                >
                  <TimePicker
                    value={startTime}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                    minuteStep={5}
                    bordered={false}
                    format={format}
                    showNow={false}
                    clearIcon=""
                    size="large"
                    suffixIcon=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-around mx-auto space-x-4 translate-y-16 md:hidden md:translate-y-44 md:w-full">
          <div className="flex justify-center w-1/2 font-extrabold bg-white border-2 rounded-full text-orange border-orange">
            <div
              onClick={props.handleBack}
              className="w-full pt-2 text-sm text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit(e)}
              className="w-full px-3 py-2 text-sm font-extrabold text-center uppercase bg-white border-2 rounded-full md:px-4 md:py-2 hover:font-extrabold border-dark_blue text-dark_blue active:bg-dark_blue active:text-white "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="justify-between hidden mx-auto translate-y-20 md:flex md:w-full">
          <div className="flex justify-center space-x-2">
            <button
              type="button"
              onClick={props.handleBack}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center space-x-2 ">
            <button
              type="button"
              onClick={(e) => handleSubmit(e)}
              className=" inline-block md:px-4 md:py-2 py-2 px-3 border-2 text-sm lg:text-[17px] font-bold hover:font-extrabold  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24 "
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursRegulier;
