import React from 'react';
import teachrCreditCard from '../../../../Assets/Images/teachr-credit-card.svg'
import teachrScreen from '../../../../Assets/Images/dashboard-screen.svg'
const Fourth = () => {
    return (
        <div className='flex flex-col gap-y-8'>
            <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8 items-center'>
                <div>
                    <img src={teachrCreditCard} alt="" />
                </div>
                <div className='text-sm text-first lg:text-lg'>
                    <p className='font-bold'>1. Recevez votre paiement</p>
                    <p className='font-semibold'>Une fois validé, recevez votre paiement et accédez à toutes les fonctionnalités de votre tableau de bord</p>
                </div>
            </div>
            <div className='py-8 mx-auto 2xl:py-10'>
                <img src={teachrScreen} alt="" />
            </div>
        </div>
    );
};

export default Fourth;