

export default class InterestedTeachr {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;


    readonly id?: number;

    readonly isContact?: boolean;
    averageNumberHoursPerWeek?: number
    hourlyPrice?: number
    age?: number
    isAcre?: boolean
    netPricePerHour?: number
    netPricePerMonth?: number
    netPricePerYear?: number
    phoneNumber: string
    preferenceForACall?: string
    mail?: string
    isSap?: boolean | undefined
    isAe?: boolean | undefined
    wantProcuration?: boolean | undefined
    ipAddress?: string
    awareOfTeachrWith? : string
    recommandedBy? : string
    constructor(
        phoneNumber: string
    ) {
        this.phoneNumber = phoneNumber
    }

}
