import HydraEntity from "./HydraEntity";
import "reflect-metadata";

export default class CustomFees extends HydraEntity {
  client: Array<String>;
  teachr: Array<String>;
  pricePerHour?: string | null;
  feesPerHour?: string | null;
  isTeachrContact?: boolean;

  readonly id?: number;
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;

  constructor(
    client: Array<String>,
    teachr: Array<String>,
    pricePerHour: string | null,
    feesPerHour: string | null,
    isTeachrContact: boolean
  ) {
    super();
    this.client = client;
    this.teachr = teachr;
    this.pricePerHour = pricePerHour;
    this.feesPerHour = feesPerHour;
    this.isTeachrContact = isTeachrContact;
  }
}
