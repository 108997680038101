import { Type } from "class-transformer";
import "reflect-metadata";
import HydraEntity from "./HydraEntity";
 
export default class Child extends HydraEntity {
    firstName: string;
    lastName: string;
    phone?: string;
    profilePicture?: string;
    parent: string; // @id of a Client
    class?: number; // 11 (CP) 10 9 8 7 6 5 4 3 2 1 0 (Terminal) -1 -2 -3 -4 -5 (Master2). !! Can't be null if isParent = false
    @Type(() => Date)
    birthday?: Date;
    otherInformation?: string;

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    @Type(() => Date)
    readonly creationDatetime?: Date;

    constructor(
        firstName: string,
        lastName: string,
        parent: string,
        niveau: number,
        birthday: Date,
        phone: string,
        otherInformation: string
    ) {
        super();
        this.firstName = firstName;
        this.lastName = lastName;
        this.parent = parent;
        this.class = niveau;
        this.birthday = birthday;
        this.phone = phone;
        this.otherInformation = otherInformation;
    }
}
