import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Checkbox
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import RadioButton from "../../../../../Components/utils/RadioButtonClient";
import SubjectsSelects from "../../../../../Components/utils/SelectMatiere";
import StepperStore from "../../../../../LocalForage/StepperStore";
import { Subject } from "../../../../../Objects/models/Subject";
import { auth } from "../../../../../Services/FirebaseService";
import validateSelects from "../../../../../Validations/MultiStep";
import React from "react";
import AlertInfo from "../UtilsDashboard/AlertInfo";
import { useLocation } from "react-router-dom";

const validateTerms = (value: any) => {
  if (!value) {
    return {
      isValid: false,
      message: "Vous devez vous engager à respecter cette condition pour faire bénéficier de l'Avance Immédiate Crédit d'Impôt à votre élève",
    };
  } else
    return {
      isValid: true,
      message: "",
    };
};


const StepLocation = (props: any) => {
  const { handleChangeStep, state } = props
  const [user] = useAuthState(auth)
  const [matiere, setMatiere] = useState<Subject[]>();
  const [matieres, setMatieres] = useState<Subject[]>();
  const [cours, setCours] = useState<any>();
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [error, setError] = useState({
    matiere: "null",
    termsTeachr: "null"
  });
  const getMatiere = (value: Subject[]) => {
    setMatiere(value);
  };
  useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem("cours" + user?.uid);
      const matiere: any = await StepperStore.getItem("location" + user?.uid);
      StepperStore.removeItem("activeStepundefined")
      if (value !== null) {
        setCours(value);
      }
      if (matiere !== null) {
        getMatiere(matiere.matiere)
        setMatieres(matiere.matiere)
        // setTermsTeachr(matiere.termsTeachr)
      }
    })();
  });
  
  const validate = () => {
    let errMatiere = true;
    let errTermsTeachr = true;
    if (!validateSelects(matiere).isValid) {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errMatiere = true;
    } else {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errMatiere = false;
    }
    if (!validateTerms(termsTeachr).isValid) {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errTermsTeachr = true;
    } else {
      setError({
        matiere: validateSelects(matiere).message,
        termsTeachr: validateTerms(termsTeachr).message,
      });
      errTermsTeachr = false;
    }

    if (errMatiere || errTermsTeachr) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (validate()) {
      StepperStore.setItem("location" + user?.uid, {
        matiere: matiere,
        // termsTeachr: termsTeachr,
      });
      switch (cours.cours) {
        case "punctual":
          handleChangeStep(4);
          break;
        case "punctualMonth":
          handleChangeStep(5);
          break;
        case "regulier":
          handleChangeStep(3);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%]  bg-white rounded-lg shadow-dashboard-setup-intro-form">
      <div className="relative h-[100%]">
        <div className="space-y-4 h-[90%] md:p-10 p-6 ">
          <h2 className="font-extrabold /lg:text-3xl md:text-2xl text-xl text-dark_blue">
            Comment souhaitez-vous effectuer votre cours ?
          </h2>
          <div className="md:translate-y-6  font-nunito md:mx-auto /md:block /hidden">
            <FormControl>
              <RadioGroup row name="customized-radios" defaultValue="female">
                <div className="md:flex w-full">
                  <div className="font-nunito md:w-1/2 ">
                    <FormControlLabel
                      checked
                      sx={{ color: "black", fontFamily: "Nunito-Regular" }}
                      value="female"
                      control={
                        <RadioButton border="1px solid #fff" bg="#219CFF" />
                      }
                      label={
                        <Typography
                          style={{ fontFamily: "Nunito" }}
                          className="md:text-base lg:text-lg text-lg"
                          component="span"
                        >
                          {" "}
                          À domicile
                        </Typography>
                      }
                    />
                  </div>
                  <div className=" md:w-1/2 xl:ml-10 lg:ml-0 md:ml-4 ">
                    <FormControlLabel
                      sx={{ color: "black" }}
                      control={<Radio />}
                      label={
                        <Typography
                          style={{ fontFamily: "Nunito" }}
                          className="md:text-base lg:text-lg text-base text-[#B7B7B7]"
                          component="span"
                        >
                          À distance
                        </Typography>
                      }
                    />
                  </div>
                  <div className="md:block hidden">
                    <AlertInfo
                      msg=" Impossible de choisir un cours à distance pour bénéficier de l’avance immédiate crédit d’impôt (attention)"
                      info="info"
                    />
                    {/* <p className="text-lg text-[#B7B7B7] "></p> */}
                  </div>
                </div>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="xl:translate-y-10 md:translate-y-2 lg:-translate-y-6 -translate-y-0 //mt-8 md:space-y-1 space-y-[1px]">
            <div>
              <div className=" bg-input-color relative rounded-lg mx-auto shadow-md py-1 shadow-[rgba(0,0,0,25%)]">
                <SubjectsSelects
                  callBack={getMatiere}
                  validation={validateSelects}
                  value={matiere}
                  placeholder="Ex : Mathématiques"
                  matieres={matieres}
                />
              </div>
              <div className="flex space-x-2 text-base ///d:translate-y-4 translate-y-2 ">
                {/* {error.matiere === "null" || error.matiere === ""
                ? " "
                : validateSelects(matiere).icon} */}
                <p className="text-[#FF724F] h-6 w-full text-base md:text-base ">
                  {error.matiere === "null" || error.matiere === ""
                    ? null
                    : validateSelects(matiere).message}
                </p>
              </div>
            </div>
          </div>
          <div className="md:translate-y-10 -translate-y-4 ">
            <FormControlLabel
              sx={{
                color: "#4d4d4d",
                fontSize: "24px",
                //marginLeft: "4rem",
                fontFamily: "nunito",
              }}
              checked={termsTeachr}
              control={
                <Checkbox
                  sx={{
                    color: "#4d4d4d",
                    "&.Mui-checked": {
                      color: "#4d4d4d",
                      fontFamily: "nunito",
                    },
                  }}
                  onClick={() => setTermsTeachr(true)}
                />
              }
              label={
                <Typography style={{ fontFamily: "nunito" }} component="span">
                  {" "}
                  <p className="text-[#4d4d4d] text-base">
                    Je m'engage à ce que cette prestation de cours particulier
                    soit réalisée au domicile de mon élève conformément à
                    l'article 199 sexdecies du Code général des impôts (CGI) en
                    France. Je m'engage également à ce que cette prestation soit
                    réalisée aux conditions d'heures et de tarifs définies
                    ci-après.
                  </p>
                </Typography>
              }
            />

            <div className="flex ml-4 space-x-2 md:translate-y-2 -translate-y-2 ">
              <p className="text-[#FF724F] h-6 w-full text-base ">
                {error.termsTeachr === "null" || error.termsTeachr === ""
                  ? null
                  : validateTerms(termsTeachr).message}
              </p>
            </div>
          </div>
        </div>
        <div className="absolute md:hidden  flex justify-around mx-auto space-x-4  w-full px-4">
          <div className={state === 2 ? "hidden" :  "flex w-1/2 rounded-full bg-white text-orange  border-2 border-orange font-extrabold justify-center"}>
            <div
              onClick={() => handleChangeStep(1)}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent
            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit()}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex px-10 bottom-0 absolute hidden justify-between mx-auto pb-10 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleChangeStep(1)}
              className={state === 2 ? "hidden" : "text-sm lg:text-[17px] font-semibold  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"}
            >
              Précédent
            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(e) => handleSubmit()}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "
            >
              Suivant
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepLocation;
