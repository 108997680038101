import { useState } from "react";
import ComponentSubscription from "./ActiveSubscription/ComponentSubscription";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import UpdateSubscriptionChoice from "./EditSubscription/EditSubscriptionChoice";
import ComponentSubscriptionPause from "./ActiveSubscription/ComponentSubscriptionPause";
import UpdateSubscriptionConfirme from "./EditSubscription/EditSubscriptionConfirme";
import { Button } from "@mui/material";
import MettreEnPauseLeCours from "./PauseResumeUpdateSubscriptionCourse/MettreEnPauseLeCours";
import ResumeSubscriptionCourse from "./PauseResumeUpdateSubscriptionCourse/ResumeSubscriptionCourse";
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PopUpUpdateCourse from "./PauseResumeUpdateSubscriptionCourse/PopUpUpdateCourse";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import PauseCircleFilledIcon from '@mui/icons-material/PauseCircleFilled';
import React from "react";
import { LightTooltipNoError } from "../../UtilsDashboard/FunctionsDashbord";


const CourseSubscription = () => {
    const [currentStep, setCurrentStep] = useState<any>(1);
    const [value, setValue] = useState("");
    const [load, setLoad] = useState(false)
    const [isHere, setIsHere] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isPause, setIsPause] = useState(false)
    const [isPlay, setIsPlay] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const handleLoad = () => {
        setLoad(!load)
    }


    const changeComponent = (comp: number) => {
        switch (comp) {
            case 1: return <ComponentSubscription courseId={courseId} handleLoad={handleLoad} load={load} isHere={isHere} isEdit={isEdit} isPause={isPause} isDelete={isDelete} handleEdit={handleEdit} handleClickOpen={handleClickOpen} val={value} />;
            case 2: return <ComponentSubscriptionPause courseId={courseId} handleLoad={handleLoad} load={load} isHere={isHere} isPlay={isPlay} isDelete={isDelete} handleClickOpenPlay={handleClickOpenPlay} val={value} />;
            case 3: return <UpdateSubscriptionChoice backStep={handleBack} />
            case 4: return <UpdateSubscriptionConfirme backStep={handleBack} />;
            default: return <ComponentSubscription />;
        }
    };
    const handleBack = (val: number) => {
        setCurrentStep(val)
    }

    const courseId = (val: string) => {
        setValue(val)
        StepperStore.setItem("course_id", val);
    }

    const handleOpen = (val: boolean) => {
        setOpen(val)
    }

    const handleOpenEdit = (val: boolean) => {
        setOpenEdit(val)
    }

    const handleEdit = () => {
            setOpenEdit(true)
    }
    const handleClickOpen = () => {
            setOpen(true);
    };
    const handleClickOpenPlay = () => {
            setOpen(true);
    };

    const handleLock = () => {
        handleBack(3)
        setIsHere(false)
        setIsEdit(false)
    }

    const handleEditTrue = () => {
        setIsHere(true)
        setIsPause(false)
        setIsDelete(false)
        setIsPlay(false)
        setIsEdit(true)
    }

    const handlePauseTrue = () => {
        setIsHere(true)
        setIsEdit(false)
        setIsDelete(false)
        setIsPlay(false)
        setIsPause(true)
    }
    const handlePlayTrue = () => {
        setIsHere(true)
        setIsEdit(false)
        setIsDelete(false)
        setIsPause(false)
        setIsPlay(true)
    }
    const handleDeleteTrue = () => {
        setIsHere(true)
        setIsEdit(false)
        setIsPlay(false)
        setIsPause(false)
        setIsDelete(true)
    }

    const handleChangeToEnCours = () => {
        setCurrentStep(1)
        setIsHere(false)
        setIsPlay(false)
        setIsDelete(false)
    }

    const handleChangeToPause = () => {
        setCurrentStep(2)
        setIsHere(false)
        setIsEdit(false)
        setIsPause(false)
        setIsDelete(false)
    }

    return (
        <>
            <div className="  h-[100%] -mt-6 md:-mt-0">
                <div className="h-[70%] md:flex md:gap-x-6">
                    <div className="h-[100%] relative  mt-4 overflow-hidden w-full">
                        <div className="flex relative  pb-2 p-1  shadow-dashboard-setup-intro-form">
                            <div className="flex  gap-x-2 md:w-1/4 w-1/2 font-extrabold">
                                <div
                                    className={
                                        currentStep === 2 ?
                                            "bg-white text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1 " :
                                            currentStep === 4 || currentStep === 3 || currentStep === 1 ? "bg-dark_blue text-white shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1" :
                                                "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1"

                                    }
                                    onClick={() => handleChangeToEnCours()}
                                >
                                    <p className=" md:pt-2 pb-1 p-1 md:text-base text-sm text-center ">
                                        En cours
                                    </p>
                                </div>
                                <div
                                    className={
                                        currentStep === 1 ? "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1" :
                                            currentStep === 2 ? "bg-dark_blue text-white shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1" : "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 md:py-0 py-1"

                                    }
                                    onClick={() => handleChangeToPause()}
                                >
                                    <p className=" md:pt-2 pb-1 p-1 md:text-base text-sm text-center ">
                                        En pause
                                    </p>
                                </div>
                            </div>
                            <div className="flex absolute md:right-4 right-0">
                                {currentStep === 4 || currentStep === 3 || currentStep === 1 ?
                                    <div className="cursor-pointer  //-mt-[1px] "  >
                                        <div className=''>
                                            <LightTooltipNoError title="Modifier">
                                                <div onClick={() => handleEditTrue()} className="cursor-pointer" >
                                                    {
                                                        isEdit ?
                                                            <ModeEditOutlineIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} />
                                                            :
                                                            <ModeEditOutlineIcon sx={{ color: "#0054A3", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                                    }
                                                </div>
                                            </LightTooltipNoError>
                                            <PopUpUpdateCourse value={value} handleLoad={handleLoad} handleOpenEdit={handleOpenEdit} openEdit={openEdit} handleLock={handleLock} />
                                        </div>
                                    </div> : ""
                                }
                                {currentStep === 4 || currentStep === 3 || currentStep === 1 ?
                                    <div className="cursor-pointer -mt-[6px]  "  >
                                        <div className=''>
                                            <LightTooltipNoError title="Mettre en pause">
                                                <Button onClick={handlePauseTrue}>
                                                    {
                                                        isPause ?
                                                            < PauseCircleFilledIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} />
                                                            :
                                                            < PauseCircleFilledIcon sx={{ color: "#0054A3", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                                    }
                                                </Button>
                                            </LightTooltipNoError>
                                            <MettreEnPauseLeCours value={value} handleLoad={handleLoad} handleOpen={handleOpen} open={open} />
                                        </div>
                                    </div>
                                    :
                                    <div className=" cursor-pointer -mt-[6px]  " >
                                        <LightTooltipNoError title="Reprendre">
                                            <Button onClick={handlePlayTrue}>
                                                {
                                                    isPlay ?
                                                        <PlayCircleOutlineIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} />
                                                        :
                                                        <PlayCircleOutlineIcon sx={{ color: "#0054A3", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                                }
                                            </Button>
                                        </LightTooltipNoError>
                                        <ResumeSubscriptionCourse value={value} handleLoad={handleLoad} handleOpen={handleOpen} open={open} />
                                    </div>
                                }
                                <div className="">
                                    <LightTooltipNoError title="Supprimer">
                                        <div onClick={() => handleDeleteTrue()} className="cursor-pointer" >
                                            {
                                                isDelete ?
                                                    <DeleteIcon sx={{ color: "white", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} /> :
                                                    <DeleteIcon sx={{ color: "#0054A3", width: "40px", height: "30px", border: 0, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                                            }
                                        </div>
                                    </LightTooltipNoError>

                                </div>
                            </div>
                        </div>
                        {changeComponent(currentStep)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default CourseSubscription;
