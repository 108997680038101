import React from 'react'
import { Link } from 'react-router-dom'

function button(props: any, custom_class: string) {
  let className = 'uppercase  w-max text-center px-4 py-2 no-underline '
  className += props.custom_class 
  return (
    <Link to='/' className={className}>{props.children}</Link>
  )
}

export default button