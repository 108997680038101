import ApiService from "../Services/ApiService";
import AvailablePromotionalCode from "../Objects/models/AvailablePromotionalCode";
import { Route } from "../Objects/enums/RouteEnum";
import HydraCollection from "../Objects/models/HydraCollection";

export default abstract class AvailablePromotionalCodeRepository {
    /**
     * Fetch a availablePromotionalCode stored into the API by his id.
     * @param id
     */
    public static fetchAvailablePromotionalCodeById(id: number) {
        return ApiService.getSingleInstance<AvailablePromotionalCode>(
            Route.AVAILABLE_PROMOTIONAL_CODES_BASE + "/" + id,
            AvailablePromotionalCode
        );
    }

    // /**
    //  * Creates an instance of availablePromotionalCode into the API.
    //  * @param availablePromotionalCode
    //  */
    // public static createAvailablePromotionalCode(
    //     availablePromotionalCode: AvailablePromotionalCode
    // ) {
    //     return ApiService.postInstance<AvailablePromotionalCode>(
    //         Route.AVAILABLE_PROMOTIONAL_CODES_BASE,
    //         AvailablePromotionalCode,
    //         availablePromotionalCode
    //     );
    // }

    public static createAvailablePromotionalCode(clientId: number,availablePromotionalCode: AvailablePromotionalCode) {
        return ApiService.post(
            `/${clientId}/create_available_promotional_code`,
            availablePromotionalCode
        );
    }

    /**
     * Returns all availablePromotionalCodes of the connected user.
     */
    public static getAvailablePromotionalCodes(page: number = 1) {
        return ApiService.getCollection<
            HydraCollection<AvailablePromotionalCode>
        >(
            Route.AVAILABLE_PROMOTIONAL_CODES_BASE,
            HydraCollection,
            true,
            page,
            null
        );
    }
}
