const validateSelect = (value) => {
    if (value === "" || !value) {
        return {
            isValid: false,
            message: "Veuillez sélectionner une valeur",
        };
    } else
        return {
            isValid: true,
            message: "",
        };
};

export default validateSelect