import ApiService from "../Services/ApiService";
import { Route } from "../Objects/enums/RouteEnum";
import URSSAFParent from "../Objects/models/URSSAFParent";
import UsrssafStatusService from "../Objects/models/UrssafStatusService";

export default abstract class URSSAFParentRepository {
    /**
     * Fetch an ursaffParent stored into the API by his id.
     * @param id
     */
    public static fetchURSSAFParentById(id: number) {
        return ApiService.getSingleInstance<URSSAFParent>(
            Route.URSSAF_PARENT_BASE + "/" + id,
            URSSAFParent
        );
    }
    /**
     * Fetch an ursaffParent stored into the API by his id.
     * @param invoiceNumber
     */
    public static fectSubscriptionUrssafStatus(invoiceNumber: string) {
        return ApiService.getSingleInstance<UsrssafStatusService>(
            `/teachrs/${invoiceNumber}/subscription_urssaf_status`,
            UsrssafStatusService
        );
    }

    /**
     * Creates an instance of urssafParent into the API.
     * @param urssafParent
     */
    public static createURSSAFParent(urssafParent: URSSAFParent) {
        return ApiService.postInstance<URSSAFParent>(
            Route.URSSAF_PARENT_BASE,
            URSSAFParent,
            urssafParent
        );
    }
    public static async tpresInscription(data: any) {
        return ApiService.post(
            "/client/urssaf/tpres/create",
            data
        );
    }


    /**
     * Updates the instance of the urssafParent passed as parameter.
     * @param urssafParent
     */
    public static updateURSSAFParent(urssafParent: URSSAFParent) {
        return ApiService.putInstance<URSSAFParent>(
            Route.URSSAF_PARENT_BASE + "/" + urssafParent.id,
            URSSAFParent,
            urssafParent
        );
    }
}
