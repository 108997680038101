import HydraEntity from "./HydraEntity";
import Client from "./Client";
import { Type } from "class-transformer";
import "reflect-metadata";

export default class URSSAFParent extends HydraEntity {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    id?: number;
    @Type(() => Client)
    rattachedClient: string | Client;
    firstName: string;
    lastName: string;
    sex: "MAN" | "WOMAN";
    @Type(() => Date)
    birthday: Date;
    marriedName: string;

    constructor(
        rattachedClient: string | Client,
        firstName: string,
        lastName: string,
        sex: "MAN" | "WOMAN",
        birthday: Date,
        marriedName: string
    ) {
        super();
        this.rattachedClient = rattachedClient;
        this.firstName = firstName;
        this.lastName = lastName;
        this.sex = sex;
        this.birthday = birthday;
        this.marriedName = marriedName;
    }
}
