import ApiService from "../Services/ApiService";
import { SubscriptionCourse } from "../Objects/models/SubscriptionCourse";
import { plainToClass } from "class-transformer";
import { SubscriptionCourseDashboard } from "../Objects/models/SubscriptionCourseDashboard";
import { SubscriptionCourseDashboardUpdate } from "../Objects/models/SubscriptionCourseDashboardUpdate";
import { SubscriptionCoursePause } from "../Objects/models/SubscriptionCoursePause";

export default abstract class SubscriptionCourseRepositorys {

    /**
     * Creates an instance of SubscriptionPack into the API.
     * @param coursePack
     */
    public static createSubscriptionCourse(subscriptionCourse: SubscriptionCourse, clientId?: Number): Promise<unknown> {
        return ApiService.post(`/clients/v2/clients/${clientId}/subscription_courses`, subscriptionCourse);
    }
    public static createSubscriptionCourseByTeachr(subscriptionCourse: SubscriptionCourseDashboard, teachr_id?: Number): Promise<unknown> {
        return ApiService.post(`/teachrs/v2/teachrs/${teachr_id}/subscription_courses`, subscriptionCourse);
    }
    public static getPendingSubscriptionCourse(clientId: Number): Promise<SubscriptionCourse[]> {
        return ApiService
            .get(`/clients/v2/clients/${clientId}/pending_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
    }

    public static getActiveSubscriptionCourse(clientId: number) {
        return ApiService
            .get(`/clients/v2/clients/${clientId}/active_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }

    //CREATED BY Abd 27/02/2023
    public static getAllForSubscriptionUrssafStatus(teachrId: number) {
        return ApiService
            .get(`/teachrs/${teachrId}/get_all_for_subscription_urssaf_status`)
            ;
    }

    /**
     *  update the subscriptionCourse by teachr to dashboard
     * @param subscriptionCourseDashboardUpdate
    */
    public static updateSubscriptionCourseCourseByTeachr(teachrId: number, subCourseProposalId: number, subscriptionCourseDashboardUpdate: SubscriptionCourseDashboardUpdate) {
        return ApiService.putInstance<SubscriptionCourseDashboardUpdate>(
            `/teachrs/${teachrId}/subscription_course/${subCourseProposalId}/update_by_teachr`,
            SubscriptionCourseDashboardUpdate,
            subscriptionCourseDashboardUpdate
        );
    }


    /**
    *  pause the subscriptionCourse by teachr to dashboard
    * @param subscriptionCoursePause
    */
    public static teachrPauseSubscriptionCourse(teachrId: number, subCourseProposalId: number, subscriptionCoursePause: SubscriptionCoursePause) {
        return ApiService.putInstance<SubscriptionCoursePause>(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses/${subCourseProposalId}/pause`,
            SubscriptionCoursePause,
            subscriptionCoursePause
        );
    }
    /**
    *  pause the subscriptionCourse by teachr to dashboard
    * @param subscriptionCoursePause
    */
    // public static teachrResumeSubscriptionCourse(teachrId: number, subCourseProposalId: number, subscriptionCoursePause: SubscriptionCoursePause) {
    //     return ApiService.putInstance<SubscriptionCoursePause>(
    //         `/teachrs/v2/teachrs/${teachrId}/subscription_courses/${subCourseProposalId}/resume`,
    //         SubscriptionCoursePause,
    //         subscriptionCoursePause
    //     );
    // }

    public static teachrResumeSubscriptionCourse(teachrId: number, subscriptionId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/subscription_courses/${subscriptionId}/resume`,);
    }

    public static teachrsGetOnlyChargeForDashboardActive(teachrId: number, chargeId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/only/${chargeId}/charge_for_dashboard`);
    }



    public static getActiveSubscriptionCourseByTeachrForDashboard(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/active_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }
    public static getActiveChargeForSubscriptionCourseByTeachrForDashboard(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/charge_for_dashboard`)
            // .then(body => plainToClass(SubscriptionCourse, body));
            ;
    }
    // public static teachrGetCourseForDasboardSub(teachrId: number) {
    //     return ApiService
    //         .get(`/teachrs/v2/teachrs/${teachrId}/teachr_get_course_for_dasboard_sub`)
    //         // .then(body => plainToClass(SubscriptionCourse, body));
    //         ;
    // }

    public static teachrGetCourseForDasboardSub(teachrId : Number) : Promise< SubscriptionCourseDashboard[]> {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/teachr_get_course_for_dasboard_sub`)
            .then(body => plainToClass(SubscriptionCourseDashboard, body));
    }

    public static getPastSubscriptionCourse(clientId: number) {
        return ApiService
            .get(`/clients/v2/clients/${clientId}/historical_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }
    public static getPastSubscriptionCourseByTeachrForDashboard(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/historical_subscription_courses_for_dashboard`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }
    public static getPastChargeForSubscriptionCourseByTeachrForDashboard(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/charge_for_dashboard_past`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }

    public static cancelSubscriptionCourse(clientId: number, courseId: number) {
        return ApiService.put(`/clients/v2/clients/${clientId}/subscription_courses/${courseId}/cancelled`);
    }

    public static getAllAvailableSubscriptionCourse(teachrId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/available_subscription_courses`);
    }

    // public static async fetchSubCourseByIdForTeachr(teachrId: number, courseId: number): Promise<SubscriptionCourse> {
    //     return ApiService
    //         .get(`/teachrs/v2/teachrs/${teachrId}/subscription_courses/${courseId}`)
    //         .then(body => plainToClass(SubscriptionCourse, body));
    //     ;
    // }

    public static fetchSubCourseByIdForClient(clientId: number, courseId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${clientId}/subscription_courses/${courseId}`);
    }
    public static fetchSubCourseByIdByTeachr(teachrId: number, courseId: number) {
        return ApiService.get(`/teachrs/${teachrId}/teachrs_get_subscription_by_id/${courseId}`);
    }

    public static getActiveSubscriptionCourseForTeachr(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/active_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }

    public static getPastSubscriptionCourseForTeachr(teachrId: number) {
        return ApiService
            .get(`/teachrs/v2/teachrs/${teachrId}/historical_subscription_courses`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }

    // public static getSubscriptionCoursesToNoteTeachr(teachrId) : Promise<SubscriptionCourse | SubscriptionCourse[]> {
    //     return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/subscription_courses_to_note`)
    //         .then(body => plainToClass(SubscriptionCourse, body));
    //     ;
    // }

    public static getSubscriptionCoursesToNoteClient(clientId: number): Promise<SubscriptionCourse | SubscriptionCourse[]> {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_courses_to_note`)
            .then(body => plainToClass(SubscriptionCourse, body));
        ;
    }

    // public static pauseSubscriptionCourse(clientId: number, subscriptionId: number, date) {
    //     return ApiService.put(`/clients/v2/clients/${clientId}/subscription_courses/${subscriptionId}/pause`, date);
    // }

    public static resumeSubscriptionCourse(clientId: number, subscriptionId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_courses/${subscriptionId}/resume`);
    }
    public static async fetchSubCourseByIdForTeachr(teachrId: number, courseId: number): Promise<SubscriptionCourse> {
        return ApiService.getSingleInstance<SubscriptionCourse>(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses/${courseId}`,
            SubscriptionCourse
        );
    }
    static getAllProposalToGivenSubCourse(id: number, id1: number) {
        throw new Error("Method not implemented.");
    }



}
