import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { Skeleton } from '@mui/material';

const SubjectComponent = (props: any) => {
    const [show, setShow] = useState(true)
    return (
        <div>
            {
                !props.subject ? <Skeleton variant="rounded" width={100} height={35} /> : show &&
                <div className={`relative block px-2 py-1 border-2 rounded-lg ${props.customClass ? props.customClass : 'border-first text-first'}`   }>
                    <span className='block '>{props.subject.fr_FR}</span>
                    <ClearIcon onClick = {()=> {setShow(false); props.getSelectsOptions(props.subject)}} className=' absolute -top-2 -right-2  p-[1px] text-lg text-white font-bold cursor-pointer rounded-full bg-third' />
                </div>
            }
        </div>
    );
};

export default SubjectComponent;