import React from 'react'
import NavBarCominsoon from '../../Components/Navbar/NavBarCominsoon'
// import qrCodeAppleBtn from '../../Assets/Images/qr_code_apple.svg'
// import qrCodePlaystoreBtn from '../../Assets/Images/qr_code_androide.svg'
import appStoreIcon from '../../Assets/Icons/apple-blue.svg'
import playStoreIcon from '../../Assets/Icons/play-blue.svg'
// import appleIcon from '../../Assets/Icons/apple-blue.svg'
// import playIcon from '../../Assets/Icons/play-blue.svg'
// import { Button } from '@mui/material'
// import { useAuthState } from 'react-firebase-hooks/auth'
// import { auth } from '../../Services/FirebaseService'

function ComingSoon() {
  
  return (
    <div className='h-screen overflow-hidden'>
      <NavBarCominsoon />
      <div className='flex flex-col h-screen gap-8 px-4 pt-8 bg-bottom bg-no-repeat bg-contain bg-coming-soon md:px-8'>
        <div className='lg:w-1/2 md:w-2/3'>
        <h1 className='text-[#0054A3]  lg:text-[32px] text-[20px] md:text-[28px] font-semibold  '>Maintenance en cours</h1>
        <p className='lg:text-xl md:text-base text-sm font-bold mt-6'>
        Cher(e) utilisateur(trice), <br/><br/>
Nous effectuons actuellement une courte période de maintenance pour améliorer votre expérience sur notre site. Nous serons bientôt de retour en ligne. Merci pour votre compréhension.
<br/><br/>L'équipe de Teach'r
        </p>

        </div>

        {/* <div className="items-end justify-between hidden w-[40%] lg:flex">
          <a href='https://apps.apple.com/fr/app/teachr/id1473591330' target="_blank" rel="noreferrer">
            <img src={qrCodeAppleBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-extrabold text-first '>Télécharger via l’App Store</p>
              <img src={appleIcon} className="w-5" alt="" />
            </div>
          </a>
          <a href="https://play.google.com/store/apps/details?id=fr.teachr.app" target="_blank" rel="noreferrer">
            <img src={qrCodePlaystoreBtn} alt="" className='mx-auto' />
            <div className="flex items-center gap-2 py-4">
              <p className='text-base font-extrabold text-first '>Télécharger via Google play</p>
              <img src={playIcon} className="w-5" alt="" />
            </div>
          </a>
        </div> */}
        <div className="flex items-center justify-between gap-4 py-8 text-center md:w-2/4 lg:hidden">
          <div className=''>
            <img className='' src={appStoreIcon} alt="" />
          </div>
          <div className=''>
            <img className='' src={playStoreIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon