import  { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import listIcon from '../../../../../Assets/Icons/list.svg'
import RegularCandidacyComponent from '../../../../../Components/Dashboard/Course/candidacy/Regular';
import { SubscriptionCourse } from '../../../../../Objects/models/SubscriptionCourse';
import { SubscriptionCourseProposal } from '../../../../../Objects/models/SubscriptionCourseProposal';
import Teachr from '../../../../../Objects/models/Teachr';
import SubscriptionCourseProposalRepository from '../../../../../Repositories/SubscriptionCourseProposalRepository';

const RegularCourseAccepted = (state: {course: SubscriptionCourse | undefined, teachr: Teachr}) => {
    const navigate = useNavigate()
    const [proposal, setProposal] = useState<SubscriptionCourseProposal>()
    useEffect(()=>{
       SubscriptionCourseProposalRepository.fetchSubscriptionCourseProposalByIdTeachr(state.teachr?.id!, state.course?.acceptedProposal_id!).then((response) => {
        setProposal(response)
       })
    },[])
    const getWeeklyHours = () => {
        if (state.course) {
            const duration = state.course.durationPerDay / 2
            const days = state.course.daysOfTheWeek.length
            return  duration * days
        }
        return 0
    }
    return (
        <div className='w-max'>
            {proposal && <RegularCandidacyComponent {... proposal} />}
            <div className='shadow-candidancy-sub px-3 py-4 flex flex-col gap-2 rounded-[10px] mx-auto md:mx-0 my-4 whitespace-nowrap'>
                <p className='text-xl font-bold text-first'>Rémunération horaire :  <span className='text-2xl'>{proposal?.teachrPrice} €</span></p>
                <p className='text-xl font-bold text-first'>Rémunération totale :  <span className='text-2xl'>{proposal?.teachrPrice && (parseFloat(proposal.teachrPrice) * getWeeklyHours()).toFixed(2)} €</span></p>
            </div>
            <div className='shadow-high-record p-4  gap-2 rounded-[10px]  w-full text-xl text-radio '>
                <button onClick={()=> navigate('/teachr/candidatures/eleve', {
                state: {
                   course: proposal,
                   teachrId: state.teachr.id
                }
            })}  className='flex gap-3 mx-auto underline w-max ' > <img src={listIcon} alt="" /> Voir la fiche élève </button>
            </div>
        </div>
    );
};

export default RegularCourseAccepted;