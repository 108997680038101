import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import add from "../../../../../../Assets/Icons/pluss.svg";
import Teachr from "../../../../../../Objects/models/Teachr";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import CourseSubscription from "./CourseSubscription";
import CourseSubscriptionPrevu from "./ActiveSubscription/CourseSubscriptionPrevu";
import ComponentSubscriptionHistoric from "./HistoricsSubscription/CourseSubscriptionHistoric";
import TeachrShowInfo from "../../../../../../Objects/models/TeachrShowInfo";
import Teachr_Repository from "../../../../../../Repositories/Teachr_Repository";
import PopUpInfo from "../../UtilsDashboard/PopUpInfo";
import React from "react";
import { acceptCourse } from "../../UtilsDashboard/FunctionsDashbord";

const SubscriptionCourse = (props: any) => {
    const [component, setComponent] = useState(0)
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    // const MySwal = withReactContent(Swal)
    const [load, setLoad] = useState(false)
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response && response.id) {
                setTeachr(response)
            }
        })
    }, [load])
    const changeComponent = (comp: number) => {
        switch (comp) {
            case 0:
                return <CourseSubscription/>
            case 1:
                return <CourseSubscriptionPrevu/>
            case 2:
                return <ComponentSubscriptionHistoric/>

            default:
                return <CourseSubscription />
        }
    }
    const handleDeclareCourse = () => {
        const showInfo = new TeachrShowInfo(true)
        if (teachr?.id) {
            Teachr_Repository.teachrShowInfo(teachr?.id, showInfo).then((res) => {
                setLoad(!load)
                acceptCourse(teachr, navigate)
                handleClose()
            })
        }
    }

    return (

        <div className="h-[100%] md:flex md:space-x-4 ">
            <div className="relative h-[100%] mt-4 w-full">
                <div className="md:flex pb-6 md:pb-0  justify-between lg:text-xl md:text-lg text-base font-extrabold ">
                    {
                        teachr?.isShowInfos === null || teachr?.isShowInfos === false  || teachr?.isShowInfos === undefined ?
                            <>
                                <div className="mb-4  flex justify-between">
                                    <div
                                        onClick={() => handleClickOpen()}
                                        className="cursor-pointer lg:text-xl md:text-lg text-base text-[#4d4d4d] font-extrabold flex space-x-2"
                                    >
                                        <div>
                                            <img src={add} alt="" className="w-6 md:w-full text-dark_blue" />
                                        </div>
                                        <div className="">
                                            <p>Déclarer un cours régulier 📚‍</p>
                                        </div>
                                    </div>
                                </div>
                                <PopUpInfo handleDeclareCourse={handleDeclareCourse} navigate={navigate} teacher={teachr} handleClose={handleClose} open={open} />
                            </>
                            :
                            <div className="mb-4  flex justify-between">
                                <div
                                    onClick={() => acceptCourse(teachr, navigate)}
                                    className="cursor-pointer lg:text-xl md:text-lg text-base text-[#4d4d4d] font-extrabold flex space-x-2"
                                >
                                    <div>
                                        <img src={add} alt="" className="w-6 md:w-full text-dark_blue" />
                                    </div>
                                    <div className="">
                                        <p>Déclarer un cours régulier 📚‍</p>
                                    </div>
                                </div>
                            </div>
                    }
                    <div className=" gap-x-2  md:w-1/3 flex  ">
                        <div
                            className={
                                component === 1 ? "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" :
                                    component === 0 ? "bg-dark_blue text-white shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" : "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1"

                            }
                            onClick={() => setComponent(0)}
                        >
                            <p className=" hidden md:block md:pt-3 p-1 md:text-base text-sm text-center ">
                                Cours régulier
                            </p>
                            <p className=" md:hidden md:pt-3 p-1 md:text-base text-sm text-center ">
                                Cours
                            </p>
                        </div>
                        <div
                            className={
                                component === 0 ? "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" :
                                    component === 1 ? "bg-dark_blue text-white shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" : "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1"

                            }
                            onClick={() => setComponent(1)}
                        >
                            <p className="hidden md:block md:pt-3 p-1 md:text-base text-sm text-center ">
                                Séances à venir
                            </p>
                            <p className="md:hidden md:pt-3 p-1 md:text-base text-sm text-center ">
                                À venir
                            </p>
                        </div>
                        <div
                            className={
                                component === 0 ? "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" :
                                    component === 2 ? "bg-dark_blue text-white shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1" : "bg-[#FFFFFF] text-[#4D4D4D] shadow-dashboard-btn rounded-xl cursor-pointer md:w-full md:w-1/2 px-2 w-1/3 md:py-0 py-1"

                            }
                            onClick={() => setComponent(2)}
                        >
                            <p className="p-1 text-sm text-center md:pt-3 md:text-base">
                                Historique
                            </p>
                        </div>
                    </div>
                </div>
                {changeComponent(component)}
            </div>
        </div>

    );
};

export default SubscriptionCourse;
