import React from 'react';

const Stepper = (props: {
    current: number
}) => {

    return (
        <div className='flex lg:flex-col  justify-between h-full items-center lg:items-start'>
            <div className='flex lg:items-center lg:pb-3 gap-x-4 flex-col lg:flex-row '>
                <div className="flex flex-col">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#0054A3" />
                        <path d="M20.4263 20.712C21.3063 20.712 21.7463 21.086 21.7463 21.834C21.7463 22.6113 21.3063 23 20.4263 23H12.9903C12.0956 23 11.6483 22.6113 11.6483 21.834C11.6483 21.086 12.0956 20.712 12.9903 20.712H15.2783V10.68L13.1663 11.978C12.961 12.0953 12.7776 12.154 12.6163 12.154C12.3083 12.154 12.0443 12.022 11.8243 11.758C11.619 11.494 11.5163 11.2007 11.5163 10.878C11.5163 10.4527 11.707 10.1227 12.0883 9.888L15.4983 7.776C15.9383 7.512 16.3563 7.38 16.7523 7.38C17.163 7.38 17.493 7.50467 17.7423 7.754C17.9916 8.00333 18.1163 8.348 18.1163 8.788V20.712H20.4263Z" fill="white" />
                    </svg>
                </div>
                <span className={`${props.current >= 0 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold  text-xs hidden lg:block`}>Votre procuration SAP</span>
            </div>
            <div className={`${props.current >= 1 ? 'border-first' : 'border-[#D2D2D2]'} lg:border-dashed lg:h-full border-t-2 lg:border-t-0 lg:border-l-2 lg:ml-4 w-full`} />
            <div className='flex items-center gap-x-4 flex-col lg:flex-row'>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill={props.current >= 2 ? '#0054A3' : '#D2D2D2'} />
                    <path d="M20.3496 20.712C21.2296 20.712 21.6696 21.086 21.6696 21.834C21.6696 22.6113 21.2296 23 20.3496 23H11.9896C11.6082 23 11.3076 22.89 11.0876 22.67C10.8676 22.4353 10.7576 22.1493 10.7576 21.812C10.7576 21.372 10.9262 20.976 11.2636 20.624L16.2796 15.234C16.8956 14.5593 17.3429 13.9433 17.6216 13.386C17.9149 12.814 18.0616 12.2493 18.0616 11.692C18.0616 11.0027 17.8636 10.4747 17.4676 10.108C17.0716 9.74133 16.5069 9.558 15.7736 9.558C15.1722 9.558 14.5856 9.67533 14.0136 9.91C13.4562 10.13 12.8916 10.438 12.3196 10.834C12.1729 10.9367 12.0262 11.0247 11.8796 11.098C11.7329 11.1567 11.5862 11.186 11.4396 11.186C11.1902 11.186 10.9702 11.0687 10.7796 10.834C10.6036 10.5993 10.5156 10.3207 10.5156 9.998C10.5156 9.778 10.5522 9.58733 10.6256 9.426C10.7136 9.26467 10.8456 9.11067 11.0216 8.964C11.6669 8.45067 12.4222 8.04733 13.2876 7.754C14.1529 7.446 15.0182 7.292 15.8836 7.292C17.3942 7.292 18.5969 7.67333 19.4916 8.436C20.4009 9.19867 20.8556 10.2253 20.8556 11.516C20.8556 12.4107 20.6649 13.254 20.2836 14.046C19.9022 14.8233 19.2569 15.696 18.3476 16.664L14.5416 20.712H20.3496Z" fill="white" />
                </svg>

                <div className=' flex-col hidden lg:flex'>
                    <span className={`${props.current >= 2 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold text-xs`}>Annexe 1 </span>
                    <span className={`${props.current >= 2 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold text-xs`}>Fiche de renseignement</span>
                </div>
            </div>
            <div className={`${props.current >= 3 ? 'border-first' : 'border-[#D2D2D2]'} lg:border-dashed lg:h-full border-t-2 lg:border-t-0 lg:border-l-2 lg:ml-4 w-full`} />
            <div className='flex items-center gap-x-4 flex-col lg:flex-row'>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill={props.current >= 3 ? '#0054A3' : '#D2D2D2'} />
                    <path d="M18.3803 15.036C19.3043 15.2707 20.0083 15.718 20.4923 16.378C20.991 17.0233 21.2403 17.83 21.2403 18.798C21.2403 20.1327 20.749 21.196 19.7663 21.988C18.7836 22.78 17.4563 23.176 15.7843 23.176C14.831 23.176 13.9143 23.0293 13.0343 22.736C12.1543 22.4427 11.4063 22.032 10.7903 21.504C10.453 21.2107 10.2843 20.866 10.2843 20.47C10.2843 20.1473 10.3723 19.876 10.5483 19.656C10.739 19.4213 10.959 19.304 11.2083 19.304C11.355 19.304 11.487 19.3333 11.6043 19.392C11.7216 19.436 11.883 19.5167 12.0883 19.634C12.7043 20.0447 13.291 20.36 13.8483 20.58C14.4056 20.8 15.0143 20.91 15.6743 20.91C16.6423 20.91 17.361 20.7193 17.8303 20.338C18.2996 19.942 18.5343 19.348 18.5343 18.556C18.5343 17.7787 18.285 17.2067 17.7863 16.84C17.3023 16.4587 16.547 16.268 15.5203 16.268H14.2663C13.929 16.268 13.665 16.158 13.4743 15.938C13.2983 15.7033 13.2103 15.4393 13.2103 15.146C13.2103 14.838 13.2983 14.574 13.4743 14.354C13.665 14.134 13.929 14.024 14.2663 14.024H15.1463C17.1556 14.024 18.1603 13.2687 18.1603 11.758C18.1603 11.054 17.9476 10.5113 17.5223 10.13C17.097 9.74867 16.5103 9.558 15.7623 9.558C14.6916 9.558 13.5476 9.98333 12.3303 10.834C12.125 10.9513 11.9636 11.0393 11.8463 11.098C11.729 11.142 11.597 11.164 11.4503 11.164C11.201 11.164 10.981 11.054 10.7903 10.834C10.6143 10.5993 10.5263 10.3207 10.5263 9.998C10.5263 9.778 10.563 9.59467 10.6363 9.448C10.7243 9.28667 10.8563 9.12533 11.0323 8.964C11.6483 8.45067 12.3816 8.04733 13.2323 7.754C14.0976 7.446 14.9776 7.292 15.8723 7.292C17.3976 7.292 18.6076 7.666 19.5023 8.414C20.4116 9.162 20.8663 10.1593 20.8663 11.406C20.8516 12.2567 20.6243 13.0047 20.1843 13.65C19.759 14.2807 19.1576 14.7427 18.3803 15.036Z" fill="white" />
                </svg>


                <div className='hidden lg:flex flex-col '>
                    <span className={`${props.current >= 3 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold text-xs`}>Annexe 2 </span>
                    <span className={`${props.current >= 3 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold text-xs`}>Fiche d’information NOVA</span>
                </div>
            </div>
            <div className={`${props.current > 4 ? 'border-first' : 'border-[#D2D2D2]'} lg:border-dashed lg:h-full border-t-2 lg:border-t-0 lg:border-l-2 lg:ml-4 w-full`} />
            <div className='flex items-center lg:pt-3 gap-x-4 flex-col lg:flex-row'>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16" cy="16" r="16" fill={props.current >= 4 ? '#0054A3' : '#D2D2D2'} />
                    <path d="M20.9543 17.764C21.3943 17.764 21.7243 17.8593 21.9443 18.05C22.1643 18.2407 22.2743 18.512 22.2743 18.864C22.2743 19.5973 21.8343 19.964 20.9543 19.964H19.8323V21.702C19.8323 22.1567 19.7003 22.5087 19.4363 22.758C19.187 23.0073 18.857 23.132 18.4463 23.132C18.0356 23.132 17.6983 23.0073 17.4343 22.758C17.185 22.5087 17.0603 22.1567 17.0603 21.702V19.964H11.2963C10.9003 19.964 10.5923 19.854 10.3723 19.634C10.1523 19.3993 10.0423 19.1133 10.0423 18.776C10.0423 18.424 10.1596 18.0867 10.3943 17.764L17.1923 7.996C17.339 7.79067 17.5223 7.63667 17.7423 7.534C17.9623 7.41667 18.1896 7.358 18.4243 7.358C18.8056 7.358 19.1356 7.48267 19.4143 7.732C19.693 7.98133 19.8323 8.33333 19.8323 8.788V17.764H20.9543ZM12.9683 17.764H17.0603V11.846L12.9683 17.764Z" fill="white" />
                </svg>


                <span className={`${props.current >= 4 ? 'text-first' : ' text-[#D2D2D2]'} lg:text-lg font-bold text-xs hidden lg:block`}>Signature ✍️</span>
            </div>
        </div>
    );
};

export default Stepper;