import { Type } from "class-transformer";
import HydraEntityInterface from "../interfaces/HydraEntityInterface";
import HydraEntity from "./HydraEntity";
import "reflect-metadata";

export default class CreditCard extends HydraEntity {
    client: string;
    token?: string;
    isDefault: boolean;
    brand?: string;
    expirationMonth?: string;
    expirationYear?: string;
    last4Digits?: string;

    readonly id?: number;
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    constructor(client: string, token: string, isDefault: boolean) {
        super();
        this.client = client;
        this.token = token;
        this.isDefault = isDefault;
    }
}
