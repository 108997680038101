import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgb(220 38 38)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: '5px',
    fontFamily: "Nunito, 'sans-serif'"
  },
}));
export default function ToolTip(props) {
  return (
    <div>
      <LightTooltip title={props.text}>
        <div>{props.children}</div>
      </LightTooltip>
    </div>
  );
}
