import ActionInterface from "../../Objects/interfaces/ActionInterface";
import { UserCredential } from "firebase/auth";
import Client from "../../Objects/models/Client";
import Teachr from "../../Objects/models/Teachr";

export const USER_FIREBASE_SET_ACCOUNT = "USER_FIREBASE_SET_ACCOUNT";
export const USER_FIREBASE_UNSET_ACCOUNT = "USER_FIREBASE_UNSET_ACCOUNT";
export const USER_CLIENT_SET_ACCOUNT = "USER_CLIENT_SET_ACCOUNT";
export const USER_CLIENT_UNSET_ACCOUNT = "USER_CLIENT_UNSET_ACCOUNT";
export const USER_TEACHR_SET_ACCOUNT = "USER_TEACHR_SET_ACCOUNT";
export const USER_TEACHR_UNSET_ACCOUNT = "USER_TEACHR_UNSET_ACCOUNT";
export const USER_FIREBASE_MESSAGING_SET_TOKEN = "USER_FIREBASE_MESSAGING_SET_TOKEN";

export const userFirebaseSetAccountAction = (
    authMethod: string,
    userCredential: UserCredential
): ActionInterface => {
    return {
        type: USER_FIREBASE_SET_ACCOUNT,
        value: { authMethod: authMethod, userCredential: userCredential },
    };
};

export const userFirebaseUnSetAccountAction = (): ActionInterface => {
    return {
        type: USER_FIREBASE_UNSET_ACCOUNT,
        value: null,
    };
};

export const userClientSetAccountAction = (client: Client): ActionInterface => {
    return {
        type: USER_CLIENT_SET_ACCOUNT,
        value: client,
    };
};

export const userClientUnSetAccountAction = (): ActionInterface => {
    return {
        type: USER_CLIENT_UNSET_ACCOUNT,
        value: null,
    };
};

export const userTeachrSetAccountAction = (teachr: Teachr): ActionInterface => {
    return {
        type: USER_TEACHR_SET_ACCOUNT,
        value: teachr,
    };
};

export const userTeachrUnSetAccountAction = (): ActionInterface => {
    return {
        type: USER_TEACHR_UNSET_ACCOUNT,
        value: null,
    };
};

export const userFirebaseMessagingSetToken = (
    token: string
): ActionInterface => {
    return {
        type: USER_FIREBASE_MESSAGING_SET_TOKEN,
        value: token,
    };
};
