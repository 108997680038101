import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/close-btn.png";
import MultiSelect from "../../../utils/MultiSelectClient";
import StepperStore from "../../../../LocalForage/StepperStore";
import validateFirstName from "../../../../Validations/FirstName";
import Child from "../../../../Objects/models/Child";
import { auth, getJWTToken } from "../../../../Services/FirebaseService";
import ClientRepository from "../../../../Repositories/ClientRepository";
import ApiService from "../../../../Services/ApiService";
import { useAuthState } from "react-firebase-hooks/auth";
import ChildRepository from "../../../../Repositories/ChildRepository";
import { styled } from "@mui/material/styles";
import Functions from "../../../../Helpers/Functions";
import { APIErrorEnum } from "../../../../Objects/enums/APIErrorEnum";
import Button from "@mui/material/Button";
import validateClasse from "../../../../Validations/ValidationClasse";
import moment from "moment";
import DatePickersBirthday from "../../../utils/DatePickerBirthday";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import errorIcon from "../../../../Assets/Images/error.png";

const validateName = (value:any) => {
   if (value.match(/^[0-9]+$/)) {
    return {
      isValid: false,
      message: "Nom de famille invalide",
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    };
  } else {
    return {
      isValid: true,
      message: "",
    };
  }
}
const validateDate = (value:any) => {
  const age:any = moment().subtract(moment(value).format('YYYY'),'year').format('YY')

    if (age < 3) {
      return {
        isValid: false,
        message: "L'élève doit avoir au moins 3 ans",
        icon:<img src={errorIcon} alt="" className="w-4 h-4" />
      };
    }
    else
      return {
        isValid: true,
        message: "",
        icon:""
      };
  };

  function validatePhoneNumber(phone:any) {
    phone = phone.replace(/ +/g, "")
    const character = phone.split("");
    let message:any ;
    switch (character[0]) {
      case "+":
        if (character.length !== 12) {
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        } else {
          if (character[2] === '3' && character[1] === "3") {
            if (character[3] === "7" || character[3] === "6" || character[3] === "1") {
              message = "";
            }else{
              message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
            }
          }else{
            message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>
          }
        }
        
        break;
      case "0":
        if (character.length !== 10) {
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        } else {
          if (character[1] === "7" || character[1] === "6" || character[1] === "1") {
            message = "";
          }else{
            message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
          }
         
        }
  
        break;
      case "7":
        if (character.length !== 9) {
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        } else {
          message = "";
        }
  
        break;
      case "6":
        if (character.length !== 9) {
          message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        } else {
          message = "";
        }
        break;
  
        case "1":
          if (character.length !== 9) {
            message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
          } else {
            message = "";
          }
          break;
  
      default:
        // message = <div className="flex space-x-2 "><img src={errorIcon} alt="" className="w-4 h-4 mt-1 " /> <p>Téléphone invalide</p></div>;
        break;
    }
    return message;
  }
  

const BootstrapButton = styled(Button)({
  //boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.25)",
  textTransform: "none",
  width: "100%",
  fontSize: "24px",
  fontWeight: "bold",
  color: "#0054A3",
  padding: "6px 12px",
  lineHeight: 1.5,
  backgroundColor: "#F2F5FB",
  borderRadius: "15px",
  fontFamily: ["nunito"].join(","),
  "&:hover": {
    backgroundColor: "#0054A3",
    color: "white",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F2F5FB",
    color: "#0054A3",
  },
  "&:focus": {
    boxShadow: "0 0 0 1px .2rem rgba(0,23,55,.5)",
  },
});

const niveauListe: Array<any> = [
  { label: "Maternelle", value: 12 },
  { label: "CP", value: 11 },
  { label: "CE1", value: 10 },
  { label: "CE2", value: 9 },
  { label: "CM1", value: 8 },
  { label: "CM2", value: 7 },
  { label: "6ème", value: 6 },
  { label: "5ème", value: 5 },
  { label: "4ème", value: 4 },
  { label: "3ème", value: 3 },
  { label: "2nde", value: 2 },
  { label: "1ère", value: 1 },
  { label: "Terminale", value: 0 },
  { label: "Licence 1", value: -1 },
  { label: "Licence 2", value: -2 },
  { label: "Licence 3", value: -3 },
  { label: "Master 1", value: -4 },
  { label: "Master 2", value: -5 },
  { label: "Vie active", value: -6 },
{ label: "Retraité", value: -7 },
];

export default function AddChild(props: any) {
  const Swal = require("sweetalert2");
  let timerInterval: any;
  const today = new Date();
  const [opens, setOpens] = React.useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState<any>(today);
  const [niveau, setNiveau] = useState<any>();
  const [phone, setPhone] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [dataChild, setDataChild] = useState("");
  const [child, setChild] = useState<any>([]);
  const [load, setLoad] = useState(false);
  const [user] = useAuthState(auth);
  // const [loadBtn, setLoadBtn] = useState(false);
  const dateBirthday = moment("20111031", "YYYY/MM/DD").toDate()
  const [error, setError] = useState({
    firstName: "null",
    lastName: "null",
    niveau: "null",
    phone: "null",
    birthday: "null",
  });
  const getBithday = (value: any) => {
    setBirthday(value);
  };
  const handleClickOpen = () => {
    setOpens(true);
  };
  const handleClose = () => {
    setOpens(false);
  };
  const getNiveau = (value: any) => {
    setNiveau(value);
  };
  const validate = () => {
    let errlastName = true;
    let errfirstName = true;
    let errniveau = true;
    let errbirthday = true;
    let errphone = true;
    if (!validateFirstName(firstName).isValid) {
      setError({
        firstName:  validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = true;
    } else {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errfirstName = false;
    }
    if (!validateName(lastName).isValid) {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errlastName = true;
    } else {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errlastName = false;
    }
    if (!validateClasse(niveau).isValid) {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errniveau = true;
    } else {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errniveau = false;
    }
    if (!validateDate(birthday).isValid) {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errbirthday = true;
    } else {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errbirthday = false;
    }
    if (validatePhoneNumber(phone) === "") {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errphone = false;
    } else {
      setError({
        firstName: validateFirstName(firstName).message,
        lastName: validateName(lastName).message,
        niveau: validateClasse(niveau).message,
        birthday: validateDate(birthday).message,
        phone: validatePhoneNumber(phone),
      });
      errphone = true;
    }

    if (phone === "" ) {
      if (errfirstName || errlastName || errniveau || errbirthday) {
        return false;
      } else {
        return true;
      }
    } else {
      if (errfirstName || errlastName || errniveau || errbirthday || errphone) {
        return false;
      } else {
        return true;
      }
    }
  };
  const getFormWhos = async () => {
    const value: any = await StepperStore.getItem(
      user ? "formWhos" + user?.uid : "formWhos"
    );
    if (value !== null) {
      setFirstName(value.firstName);
      setLastName(value.lastName);
      setNiveau(value.niveau);
      setBirthday(value.birthday);
      setAdditionalInfo(value.additionalInfo);
      //setPhone(value.phone);
    } else {
      setFirstName("");
      setLastName("");
      setNiveau("");
      setBirthday("");
      setAdditionalInfo("");
      setPhone("");
    }
  };

  const createChildren = async () => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();

        const classe = niveau.value;
        if (clientWithId["@id"]) {
          const childs = new Child(
            firstName,
            lastName === '' ? "Nom de famille" : lastName ,
            clientWithId["@id"],
            classe,
            birthday === "" ? dateBirthday : birthday,
            phone === "" ? "" : Functions.formatPhoneNumber(phone),
            additionalInfo
          );
          await ChildRepository.createChild(childs);
        }
      }
    } catch (error: any) {
      switch (error.description) {
        case APIErrorEnum.CHILD_CANNOT_HAVE_MORE_THAN_TEN:
          Swal.fire({
            icon: "warning",
            title: "Attention !",
            text: "Vous ne pouvez pas avoir plus de 10 enfants.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
          break;

        default:
          break;
      }
    }
  };
  const getChilUserDisconnect = async () => {
    const child: any = await StepperStore.getItem(
      user ? "formWhos" + user?.uid : "formWhos"
    );
    if (child !== null) {
      setDataChild(child.firstName);
    }
  };

  
  const getChildren = async () => {
    try {
      if (user) {
        const jwtToken = await getJWTToken(user);
        ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
        const clientWithId = await ClientRepository.getCurrentClient();
        if (clientWithId.id) {
          const allChildrenCollection = await ChildRepository.getChildren(
            clientWithId.id
          );
          const allChildren = allChildrenCollection;
          setChild(allChildren);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getChilUserDisconnect();
    getChildren();
  }, [load]);
  useEffect(() => {
    getFormWhos();
  }, [user]);

  const handleSubmit = async (event: any) => {
    if (validate()) {
      await StepperStore.setItem(
        user ? "formWhos" + user?.uid : "formWhos",
        {
          firstName: firstName,
          lastName: lastName === '' ? "Nom de famille" : lastName ,
          niveau: niveau,
          birthday:  birthday === "" ? dateBirthday : birthday,
          phone: phone,
          additionalInfo: additionalInfo,
        },
        function () {}
      );
      if (user) {
        await createChildren();
        props.reload();
        // setLoadBtn(true);
      }
      setLoad(!load);
      props.reload();
      handleClose();
    }
    event.preventDefault();
  };

  return (
    <div
      className={
        !user
          ? "  translate-y-8 flex space-x-2 lg:text-lg md:text-md text-sm   w-4/5 md:w-2/3 mx-auto"
          : user && child.length === 0
          ? "  translate-y-8 flex space-x-2 lg:text-lg md:text-md text-sm   w-4/5 md:w-2/3 mx-auto"
          : "font-nunito"
      }
    >
      <BootstrapButton
        className="w-full mx-auto"
        variant="contained"
        onClick={handleClickOpen}
      >
        <PersonAddAlt1Icon />
        {!user ? (
          <input
            className="w-full lg:text-lg md:text-md text-sm  "
            value={dataChild ? dataChild : "Ajouter un enfant"}
            type="button"
          />
        ) : user && child.length === 0 ? (
          <input
            className="w-full lg:text-lg md:text-md text-sm  "
            value={"Ajouter un enfant ..."}
            type="button"
          />
        ) : (
          <input
            className="w-full lg:text-lg md:text-md text-sm  "
            value={"Ajouter une autre personne"}
            type="button"
          />
        )}
      </BootstrapButton>

      <Dialog open={opens} onClose={handleClose}>
        <div className="">
          <DialogTitle>
            <div className="flex justify-between">
              <span className="pl-3 font-bold text-center font-nunito lg:text-xl md:text-lg text-dark_blue">
                Informations de mon enfant{" "}
              </span>
              <img
                src={close}
                alt=""
                className="w-3 h-3 translate-y-2"
                onClick={handleClose}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <div className="p-3 ">
              <div className="grid w-full md:gap-2 font-nunito mx-auto lg:grid-cols-2">
                <div>
                  <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                    <label
                      htmlFor=""
                      className="lg:text-lg md:text-base text-sm font-medium"
                    >
                      {" "}
                      Prénom :
                    </label>
                    <input
                      type="text"
                      id="firs_firstName"
                      className="w-full  lg:text-lg md:text-md text-sm  text-grey-900 bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                      placeholder="Ex : Johny"
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    />
                  </div>
                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 lg:text-lg md:text-md text-sm ">
                    {error.firstName === "null" || error.firstName === ""
                      ? " "
                      : validateFirstName(firstName).icon}
                    <p className="text-[#FF724F] h-6 w-full lg:text-lg md:text-md text-sm mb-4 md:-mt-1">
                      {error.firstName === "null" || error.firstName === ""
                        ? null
                        : validateFirstName(firstName).message}
                    </p>
                  </div>
                </div>

                <div>
                  <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] rounded-md py-1.5 px-3 w-full lg:text-lg md:text-base text-sm">
                    <label
                      htmlFor=""
                      className="lg:text-lg md:text-base text-sm font-medium"
                    >
                      Niveau scolaire :
                    </label>
                    <div className="-mt-7">
                      <div className="relative lg:text-lg md:text-md text-sm md:text-base translate-y-5 -ml-4 ">
                        <MultiSelect
                          data={niveauListe}
                          callBack={getNiveau}
                          placeholder="Ex : CE2"
                          value={niveau}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                    {error.niveau === "null" || error.niveau === ""
                      ? " "
                      : validateClasse(niveau).icon}
                    <p className="text-[#FF724F] h-6 w-full md:text-base text-sm  mb-4 md:-mt-1">
                      {error.niveau === "null" || error.niveau === ""
                        ? null
                        : validateClasse(niveau).message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-4 ">
                <div className="w-full mx-auto">
                  <h5 className="lg:text-xl md:text-lg text-base font-nunito text-dark_blue font-bold">
                    Informations optionnelles
                  </h5>
                </div>
                <div className="grid w-full gap-x-2 gap-y-5 mx-auto lg:grid-cols-2">
                  <div className="">
                    <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                      <label
                        htmlFor=""
                        className="lg:text-lg md:text-base text-sm font-medium"
                      >
                        Nom
                      </label>
                      <input
                        type="text"
                        id="last_firstName"
                        className="w-full  lg:text-lg md:text-md text-sm text-grey-900 bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                        placeholder="Ex : Doe"
                        onChange={(e) => setLastName(e.target.value)}
                        value={lastName}
                      />
                    </div>
                    <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                      {error.lastName === "null" || error.lastName === ""
                        ? " "
                        : validateName(lastName).icon}
                      <p className="text-[#FF724F] h-6 w-full md:text-base text-sm  mb-4 md:-mt-1">
                        {error.lastName === "null" || error.lastName === ""
                          ? null
                          : validateName(lastName).message}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] rounded-md py-2 px-3 w-full lg:text-lg md:text-base text-sm">
                      <label
                        htmlFor=""
                        className="lg:text-lg md:text-base text-sm font-medium"
                      >
                        {" "}
                        Numéro de téléphone :
                      </label>
                      <input
                        type="text"
                        id="firs_firstName"
                        className="w-full font-nunito lg:text-lg md:text-md text-sm text-grey-900 bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                        placeholder="Ex : 06 XX XX XX XX"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                    <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                      {error.phone === "null" || error.phone === "" ? " " : ""}
                      <p className="text-[#FF724F] h-6 w-full lg:text-lg md:text-md text-sm ">
                        {error.phone === "null" || error.phone === ""
                          ? null
                          : validatePhoneNumber(phone)}
                      </p>
                    </div>
                  </div>

                  <div>
                    <div className="bg-input-color shadow-md shadow-[rgba(0,0,0,25%)] rounded-md py-1.5 px-3 w-full lg:text-lg md:text-base text-sm">
                      <label
                        htmlFor=""
                        className="lg:text-lg md:text-base text-sm font-medium"
                      >
                        Date de naissance :
                      </label>
                      <div className="-mt-7">
                        <div className="relative lg:text-lg md:text-md text-sm md:text-base translate-y-5 -ml-4 ">
                          <DatePickersBirthday
                            callBack={getBithday}
                            value={birthday}
                          />
                        </div>
                      </div>
                    </div>
                    <div className=" translate-y-4 flex space-x-2 -mt-3  w-full mx-auto">
                      {error.birthday === "null" || error.birthday === ""
                        ? " "
                        : validateDate(birthday).icon}
                      <p className="text-[#FF724F] h-6 w-full md:text-base text-sm  mb-4 md:-mt-1">
                        {error.birthday === "null" || error.birthday === ""
                          ? null
                          : validateDate(birthday).message}
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="w-full shadow-md shadow-[rgba(0,0,0,25%)]  lg:text-lg md:text-md text-sm text-grey-900 rounded-md py-1 bg-input-color focus:outline-none focus:ring-0 focus:border-grey-200 focus:border-1">
                      <textarea
                        value={additionalInfo}
                        onChange={(e) => setAdditionalInfo(e.target.value)}
                        className="w-full lg:text-lg md:text-md text-sm text-grey-900 font-nunito bg-input-color focus:outline-none focus:ring-0 focus:border-1"
                        placeholder="  Ex : Information à savoir"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="w-1/2 mx-auto mb-4 ">
              <BootstrapButton
                onClick={(e: any) => handleSubmit(e)}
                className="w-full mx-auto mb-4 lg:text-lg md:text-md text-sm  "
                variant="contained"
              >
                Valider
                {/* {loadBtn ? (
              <CircularProgress
                sx={{
                  color: "gray",
                }}
                size={20}
                thickness={4}
                value={100}
              />
            ) : (
              ""
            )}{" "} */}
              </BootstrapButton>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
