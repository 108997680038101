import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import closeCircle from '../../../../../../../Assets/Icons/close_circle.svg'
import Swal from 'sweetalert2';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import SubscriptionCourseRepositorys from '../../../../../../../Repositories/SubscriptionCourseRepository';


export default function ResumeSubscriptionCourse(props: any) {
    const { value, handleLoad, open, handleOpen  } = props
    let timerInterval: any;
    const [load, setLoad] = React.useState(false);
   
    const handleClose = () => {
        handleOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handlSubmit = () => {
        handleLoad()
        setLoad(true)
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            setLoad(true)
            if (teachr.id) {
                TeachrRepository.getCurrentTeachr().then((teachr) => {
                    const val = parseInt(value)
                    if (teachr.id) {
                        SubscriptionCourseRepositorys.teachrResumeSubscriptionCourse(teachr.id, val).then((res) => {
                            setLoad(true)
                            handleClose()
                            Swal.fire({
                                icon: "success",
                                title: "Vous venez de reprendre ce cours",
                                text: "  Votre cours régulier a bien été repris. Vous pourrez retrouver toutes les modifications de celui-ci dans votre tableau de bord.",
                                timer: 5000,
                                willClose: () => {
                                    clearInterval(timerInterval);
                                },
                            });
                            setLoad(false)
                        })
                    }
    
                }).catch((err) => {
                    setLoad(false)
                })
            }
        }).catch((err) => {
            setLoad(false)
        })
    }

    return (
        <div className=''>
             <Dialog
                open={open}
                onClose={handleClose}
                // scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"

            >
                <DialogTitle id="scroll-dialog-title" >
                    <div className='flex w-full //border gap-x-10 //px-10 //items-center relative //justify-between text-base font-extrabold uppercase text-first'>
                        <div className="//w-full w-3/4 //border pt-1">
                            <p className="text-left">Reprendre toutes les Séances de ce cours {value} </p>
                        </div>
                        <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div className="//py-8 //px-10 text-left ">
                            <div className=" //ml-2 mt-4 w-full">
                                <p className='text-lg -mt-6 mb-4 text-normal font-bold'>Si vous préférez plutôt reprendre une seule séance, Veuillez cliquer dans l’onglet “Séances à venir”.
                                    Si vous souhaitez poursuivre la reprise de toutes les Séances du cours régulier, Veuillez cliquer sur “Valider”.

                                </p>
                            </div>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='flex w-full px-4 justify-between //gap-x-8 py-3 //md:pt-8'>
                        <Button onClick={() => handleClose()} className='block bg-[#4d4d4d] font-extrabold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Retour</Button>
                        <Button onClick={() => handlSubmit()} className='block bg-dark_blue font-extrabold   rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >{load ? "Chargement..." : "Valider"}</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}



 