import hour from "../../../../Assets/Images/hour.svg";
import calendar from "../../../../Assets/Images/calender.png";
import load from "../../../../Assets/Icons/load_wait.gif";
import lieu from "../../../../Assets/Images/lieu.png";
import { Address } from "../../../../Objects/models/Address";
import vecteur from "../../../../Assets/Images/vecteur.png";
import ArrowRightCircle from "../../../../Assets/Icons/arrow-right-circle.svg";
import SubjectRepository from "../../../../Repositories/SubjectRepository";
import Child from "../../../../Objects/models/Child";
import { useEffect, useState } from "react";
import AddressRepository from "../../../../Repositories/AddressRepository";
import ChildRepository from "../../../../Repositories/ChildRepository";
import { Subject } from "../../../../Objects/models/Subject";
import "moment/locale/fr";
import distance from "../../../../Assets/Images/distance.png";
import moment from "moment";
import ClientRepository from "../../../../Repositories/ClientRepository";
import WaitingResponse from "../Components/WaitingResponse";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../Assets/Images/btn-close-orange.png";
import CourseProposal from "../../../../Objects/models/CourseProposal";
import CourseProposalRepository from "../../../../Repositories/CourseProposalRepository";
import ResponseTuilesPunctualCourse from "../AnswerTuliles/ResponsTuilesPunctualCourse";
import { PunctualCourse } from "../../../../Objects/models/PunctualCourse";
import PunctualCourseRepository from "../../../../Repositories/PunctualCourseRepository";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Functions from "../../../../Helpers/Functions";
import DialogActions from "@mui/material/DialogActions";
import { CircularProgress } from "@mui/material";

const PonctualCourseCard = (course: PunctualCourse) => {
  
  const [punctualCoursePropsal, setPunctualCoursePropsal] =
    useState<CourseProposal[]>();
  const [address, setAddress] = useState<Address>();
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState<Subject>();
  const [child, setChild] = useState<Child>();
  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);


  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpens = () => {
    setOpens(true);
  };
  const handleCloses = () => {
    setOpens(false);
    setLoading(false);
  };
  const handleClickOpenUpdate = () => {
    setOpenUpdate(true);
  };
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const client = await ClientRepository.getCurrentClient();
      if (course.forChild_id) {
        const child = await ChildRepository.fetchChildById(course.forChild_id);
        setChild(child);
      }
      if (course.address_id) {
        const address = await AddressRepository.fetchAddressById(
          course.address_id
        );
        setAddress(address);
      }
      if (course.subjectAsked_id) {
        const subject = await SubjectRepository.fetchSubjectById(
          course.subjectAsked_id
        );
        setSubject(subject);
      }

      if (client.id && course.id) {
        const punctualCourseProposalCollection =
          await CourseProposalRepository.getCourseProposals(course.id);
        setPunctualCoursePropsal(punctualCourseProposalCollection);
      }
    })();
  }, [course]);

  const renderResponsesValue = () => {
    if (punctualCoursePropsal) {
      return punctualCoursePropsal.length === 0
        ? 0
        : punctualCoursePropsal.length;
    } else {
      return "0";
    }
  };

  const scrollCoursesToRight = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(screen.width, 0);
  };
  const scrollCoursesToLeft = (course: string) => {
    // eslint-disable-next-line no-restricted-globals
    document.getElementById(course)?.scrollBy(-screen.width, 0);
  };

  const handleClick = () => {
    (async () => {
      setLoading(true);
      const client = await ClientRepository.getCurrentClient();
      window.open(
        "mailto:support@teachr.fr?subject=User " +
          client.id +
          " - Modification de la demande de cours régulier " +
          course.id
      );
      handleCloseUpdate();
    })();
  };

  const handleDelete = () => {
    (async () => {
      setLoading(true);
      const client = await ClientRepository.getCurrentClient();
      if (client.id && course.id) {
        PunctualCourseRepository.deletePunctualCourse(course);
        handleCloses();
        window.location.reload();
      }
    })();
  };


  return (
    <>
      {course.isCourseInTheHour === true ? (
        ""
      ) : (
        <div>
          <div className="md:flex">
            <div className="md:mt-[74px]  mt-10 pr-6 hidden md:flex">
              <div className="w-full p-4 mb-20 ml-3 rounded-lg md:w-80 md:ml-0 border-grey shadow-res">
                <div className="mb-4">
                  {subject?.fr_FR ? (
                    <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      Cours ponctuel {Functions.pronom(subject?.fr_FR)}
                      {subject?.fr_FR}
                    </p>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                  <div className="text-sm lg:text-lg md:text-base text-grey">
                    {child?.firstName ? (
                      <span>Pour {child?.firstName} </span>
                    ) : (
                      <Skeleton width="50%" />
                    )}
                  </div>
                </div>
                <div className="space-y-4 ">
                  <div className="flex space-x-4 ">
                    <div className="w-1/4 ">
                      <img src={hour} alt="" className="ml-2" />
                    </div>

                    <div className="w-3/4 ">
                      {course.duration ? (
                        <p className="text-sm font-semibold lg:text-base md:text-base text-dark_blue">
                          Durée : {Functions.renderDuration(course.duration)}
                        </p>
                      ) : (
                        <Skeleton width="75%" />
                      )}
                      <div className="text-sm text-grey">
                        {course.startDatetime && course.endDatetime ? (
                          <span>
                            Entre{" "}
                            {moment(course.startDatetime).format("HH:mm") +
                              " et " +
                              moment(course.endDatetime).format("HH:mm")}
                          </span>
                        ) : (
                          <Skeleton width="75%" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    {course.startDatetime ? (
                      <div className="w-1/4 ">
                        <img src={calendar} alt="" className="ml-2" />
                      </div>
                    ) : (
                      <Skeleton width="25%" />
                    )}
                    {course.startDatetime ? (
                      <div className="w-3/4 pt-2 text-sm lg:text-base md:text-base">
                        {moment(course.startDatetime).format(
                          "dddd DD MMMM YYYY"
                        )}
                      </div>
                    ) : (
                      <Skeleton width="75%" />
                    )}
                  </div>
                  <div className="flex space-x-4">
                    {course.isRemote || course.isRemote === false ? (
                      <div className="w-1/4 ">
                        {course.isRemote ? (
                          <img src={distance} alt="" className="ml-2" />
                        ) : (
                          <img src={lieu} alt="" className="ml-4" />
                        )}
                      </div>
                    ) : (
                      <Skeleton width="25%" />
                    )}

                    <div className="w-3/4">
                      {course.isRemote ? (
                        <p className="text-sm font-semibold translate-y-1 lg:text-base md:text-base text-dark_blue">
                          {course.isRemote ? (
                            "Cours à distance"
                          ) : (
                            <Skeleton width="75%" />
                          )}
                        </p>
                      ) : (
                        <p className="text-sm font-semibold translate-y-1 lg:text-base md:text-base text-dark_blue">
                          {address ? (
                            address?.streetName + ", " + address?.city
                          ) : (
                            <Skeleton width="75%" />
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-around mt-4 space-x-2 translate-y-6 md:py-4 md:translate-y-0 ">
                  <div className="w-1/2 mx-auto ">
                    {/* <Update handleClick={handleClick} loading={loading}/> */}
                    <div className="font-nunito">
                      <span
                        className="text-dark_blue "
                        onClick={handleClickOpenUpdate}
                      >
                        <button className="border-[1px] w-full md:p-2 p-1 text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-bold  rounded-full">
                          modifier
                        </button>
                      </span>
                      <Dialog open={openUpdate} onClose={handleCloseUpdate}>
                        <div className=" md:p-3">
                          <DialogTitle>
                            <div className="flex justify-between mb-3">
                              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                modifier ma demande
                              </span>
                              <img
                                src={close}
                                alt=""
                                className="w-3 h-3 translate-y-1 md:h-6 md:w-6 "
                                onClick={handleCloseUpdate}
                              />
                            </div>
                          </DialogTitle>
                          <DialogContent>
                            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
                              Vous souhaitez modfier votre demande de cours ?
                              Faites nous parvenir votre demande par mail en
                              cliquant sur le bouton “MODIFIER”
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <div className="flex justify-around w-full gap-x-8">
                              <button
                                onClick={() => handleCloseUpdate()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full"
                              >
                                Retour
                              </button>

                              <button
                                onClick={() => handleClick()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full"
                              >
                                {loading ? (
                                  <CircularProgress
                                    sx={{
                                      color: "blue",
                                    }}
                                    size={20}
                                    thickness={4}
                                    value={100}
                                  />
                                ) : (
                                  "Modifier"
                                )}
                              </button>
                            </div>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                  <div className="w-1/2 ">
                    <div className="font-nunito">
                      <span
                        className="text-dark_blue "
                        onClick={handleClickOpens}
                      >
                        <button className="border-[1px] w-full md:p-2 p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full">
                          Supprimer
                        </button>
                      </span>
                      <Dialog open={opens} onClose={handleCloses}>
                        <div className=" md:p-3">
                          <DialogTitle>
                            <div className="flex justify-between mb-3">
                              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                SUPPRIMER MA DEMANDE
                              </span>
                              <img
                                src={close}
                                alt=""
                                className="w-3 h-3 translate-y-1 md:h-6 md:w-6 "
                                onClick={handleCloses}
                              />
                            </div>
                          </DialogTitle>
                          <DialogContent>
                            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
                              Une fois votre demande supprimée, vous n’aurez
                              plus accès aux réponses des Teach’rs et vous
                              devrez créer une nouvelle demande. Êtes-vous sûr
                              de vouloir supprimer votre demande ?
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <div className="flex justify-around w-full gap-x-8">
                              <button
                                onClick={() => handleCloses()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full"
                              >
                                Retour
                              </button>

                              <button
                                onClick={() => handleDelete()}
                                className="border-[1px] w-1/2 md:p-2  p-1  text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full"
                              >
                                {loading ? (
                                  <CircularProgress
                                    sx={{
                                      color: "blue",
                                    }}
                                    size={20}
                                    thickness={4}
                                    value={100}
                                  />
                                ) : (
                                  "supprimer"
                                )}
                              </button>
                            </div>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </div>
              </div>
              <div className="z-10 -mt-2 -ml-3">
                <input
                  type="text"
                  className="z-10 w-6 h-6 font-bold text-center text-white rounded-full bg-orange font-nunito"
                  value={renderResponsesValue()}
                />
              </div>
              {/* </StyledBadge> */}
            </div>
            <div className="md:mt-[74px]  mt-10 pr-6  md:hidden ">
              <div className="w-full p-4 mb-20 ml-3 rounded-lg md:w-80 border- md:ml-0 border-grey shadow-res">
                <div className="mb-4">
                  {subject?.fr_FR ? (
                    <p className="text-sm font-semibold lg:text-lg md:text-base text-dark_blue">
                      Cours ponctuel {Functions.pronom(subject?.fr_FR)}
                      {subject?.fr_FR}
                    </p>
                  ) : (
                    <Skeleton width="100%" />
                  )}
                  <div className="text-sm lg:text-lg md:text-base text-grey">
                    {child?.firstName ? (
                      <span>Pour {child?.firstName} </span>
                    ) : (
                      <Skeleton width="50%" />
                    )}
                  </div>
                </div>
                <div className="space-y-4 ">
                  <div className="flex space-x-4 ">
                    <div className="w-1/4 ">
                      <img src={hour} alt="" className="ml-2" />
                    </div>
                    <div className="w-3/4 ">
                      {course.startDatetime && course.endDatetime ? (
                        <p className="pt-2 text-sm font-semibold lg:text-base md:text-base text-dark_blue">
                          {moment(course.startDatetime).format("HH:mm") +
                            " - " +
                            moment(course.endDatetime).format("HH:mm")}
                        </p>
                      ) : (
                        <Skeleton width="100%" />
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/4 ">
                      <img src={calendar} alt="" className="ml-2" />
                    </div>

                    {course.startDatetime ? (
                      <div className="w-3/4 pt-2 text-sm lg:text-base md:text-base">
                        {moment(course.startDatetime).format(
                          "dddd DD MMMM YYYY"
                        )}
                      </div>
                    ) : (
                      <Skeleton width="75%" />
                    )}
                  </div>
                  <div className="flex space-x-4">
                    <div className="w-1/4 ">
                      {course.isRemote ? (
                        <img src={distance} alt="" className="ml-2" />
                      ) : (
                        <img src={lieu} alt="" className="ml-4" />
                      )}
                    </div>
                    <div className="w-3/4">
                      {course.isRemote ? (
                        <p className="text-sm font-semibold translate-y-1 lg:text-base md:text-base text-dark_blue">
                          {course.isRemote ? (
                            "Cours à distance"
                          ) : (
                            <Skeleton width="75%" />
                          )}
                        </p>
                      ) : (
                        <p className="text-sm font-semibold translate-y-1 lg:text-base md:text-base text-dark_blue">
                          {address ? (
                            address?.streetName + ", " + address?.city
                          ) : (
                            <Skeleton width="75%" />
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-around mt-4 space-x-2 translate-y-6 md:py-4 md:translate-y-0 ">
                  <div className="w-1/2 mx-auto ">
                    <div className="font-nunito">
                      <span
                        className="text-dark_blue "
                        onClick={handleClickOpenUpdate}
                      >
                        <button className="border-[1px] w-full md:p-2 p-1 text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
                          modifier
                        </button>
                      </span>
                      <Dialog open={openUpdate} onClose={handleCloseUpdate}>
                        <div className=" md:p-3">
                          <DialogTitle>
                            <div className="flex justify-between mb-3">
                              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                modifier ma demande
                              </span>
                              <img
                                src={close}
                                alt=""
                                className="w-3 h-3 translate-y-1 md:h-6 md:w-6 "
                                onClick={handleCloseUpdate}
                              />
                            </div>
                          </DialogTitle>
                          <DialogContent>
                            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
                              Vous souhaitez modfier votre demande de cours ?
                              Faites nous parvenir votre demande par mail en
                              cliquant sur le bouton “MODIFIER”
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <div className="flex justify-around w-full gap-x-8">
                              <button
                                onClick={() => handleCloseUpdate()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full"
                              >
                                Retour
                              </button>

                              <button
                                onClick={() => handleClick()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full"
                              >
                                {loading ? (
                                  <CircularProgress
                                    sx={{
                                      color: "blue",
                                    }}
                                    size={20}
                                    thickness={4}
                                    value={100}
                                  />
                                ) : (
                                  "Modifier"
                                )}
                              </button>
                            </div>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                  <div className="w-1/2 ">
                    <div className="font-nunito">
                      <span
                        className="text-dark_blue "
                        onClick={handleClickOpens}
                      >
                        <button className="border-[1px] w-full md:p-2 p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full">
                          Supprimer
                        </button>
                      </span>
                      <Dialog open={opens} onClose={handleCloses}>
                        <div className=" md:p-3">
                          <DialogTitle>
                            <div className="flex justify-between mb-3">
                              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                                SUPPRIMER MA DEMANDE
                              </span>
                              <img
                                src={close}
                                alt=""
                                className="w-3 h-3 translate-y-1 md:h-6 md:w-6 "
                                onClick={handleCloses}
                              />
                            </div>
                          </DialogTitle>
                          <DialogContent>
                            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
                              Une fois votre demande supprimée, vous n’aurez
                              plus accès aux réponses des Teach’rs et vous
                              devrez créer une nouvelle demande. Êtes-vous sûr
                              de vouloir supprimer votre demande ?
                            </p>
                          </DialogContent>
                          <DialogActions>
                            <div className="flex justify-around w-full gap-x-8">
                              <button
                                onClick={() => handleCloses()}
                                className="border-[1px] w-1/2 md:p-2  p-1 text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full"
                              >
                                Retour
                              </button>

                              <button
                                onClick={() => handleDelete()}
                                className="border-[1px] w-1/2 md:p-2  p-1  text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full"
                              >
                                {loading ? (
                                  <CircularProgress
                                    sx={{
                                      color: "blue",
                                    }}
                                    size={20}
                                    thickness={4}
                                    value={100}
                                  />
                                ) : (
                                  "supprimer"
                                )}
                              </button>
                            </div>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </div>
                  </div>
                </div>
                <div className="flex font-nunito md:hidden">
                  <div
                    className="flex w-full mx-auto mt-10 mb-1 text-dark_blue "
                    onClick={handleClickOpen}
                  >
                    <div className=" w-full //shadow-shadow-button text-center   border-[1px] p-2 lg:text-base md:text-base text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
                      Réponses
                    </div>
                    <div className="z-10 -ml-4">
                      <input
                        type="text"
                        className="z-10 w-6 h-6 font-bold text-center text-white rounded-full bg-orange"
                        value={renderResponsesValue()}
                      />
                    </div>
                  </div>
                  {punctualCoursePropsal &&
                  punctualCoursePropsal.length === 0 ? (
                    ""
                  ) : (
                    <Dialog open={open} onClose={handleClose}>
                      <div className="w- ">
                        <div className="bg-white w-[80%] fixed z-50 mb-3">
                          <DialogTitle>
                            <div className="flex bg-white //fixed //mb-3">
                              <span className="text-sm font-bold uppercase text-dark_blue lg:text-lg md:text-base font-nunito">
                                Mes réponses
                              </span>
                              <img
                                src={close}
                                alt=""
                                className="w-3 h-3 translate-x-32 translate-y-1 md:h-6 md:w-6 "
                                onClick={handleClose}
                              />
                            </div>
                          </DialogTitle>
                        </div>
                        <DialogContent>
                          {punctualCoursePropsal ? (
                            <div className="w-full mt-20 ">
                              {punctualCoursePropsal?.map(
                                (proposal: CourseProposal, key) => (
                                  <ResponseTuilesPunctualCourse
                                  course={course}
                                   proposal = {proposal}
                                    key={key}
                                  />
                                )
                              )}
                            </div>
                          ) : (
                            <div className=" mt-[30px] hidden md:flex">
                              <WaitingResponse />
                            </div>
                          )}
                        </DialogContent>
                      </div>
                    </Dialog>
                  )}
                </div>
              </div>
            </div>

            {punctualCoursePropsal && punctualCoursePropsal.length > 0 ? (
              <div className="hidden w-10 my-auto shrink-0 md:flex">
                <div className="my-auto ">
                  <img src={vecteur} alt="gif" className="w-6 " />
                </div>
              </div>
            ) : (
              ""
            )}
            {punctualCoursePropsal ? (
              punctualCoursePropsal.length === 0 ? (
                <div className="hidden mt-20 //ml-48 md:block">
                  <div className="">
                    <p className="w-4/5 mx-auto text-sm font-semibold text-center xl:text-xl lg:text-lg md:text-base text-dark_blue">
                      Veuillez patienter, les Teach'rs sont en train de
                      consulter votre demande de cours. Vous devriez
                      prochainement recevoir des réponses.
                      <br />
                    </p>
                  </div>
                  <div className="w-1/2 mx-auto mt-4 h-1/2">
                    <img
                      src={load}
                      alt="gif"
                      className="mx-auto mt-20 h-28 w-28"
                    />
                  </div>
                </div>
              ) : (
                <div
                  id="punctual-course"
                  className="hidden w-full gap-10 p-10 overflow-x-scroll overflow-y-hidden list-teacher scroll-smooth //mt-10 md:flex"
                >
                  {punctualCoursePropsal?.map(
                    (proposal: CourseProposal, key) => (
                      <ResponseTuilesPunctualCourse course={course}
                      proposal = {proposal} key={key}  />
                    )
                  )}
                </div>
              )
            ) : (
              <div className=" mt-[30px] hidden md:flex">
                <WaitingResponse />
              </div>
            )}
          </div>
          {punctualCoursePropsal && punctualCoursePropsal.length >= 3 ? (
            <div className="flex -translate-y-10">
              <div className="w-1/3"></div>
              <div className="w-2/3 mx-auto">
                <Link
                  to=""
                  className="hidden lg:flex gap-x-4  text-radio font-bold text-[16px] justify-center items-center"
                >
                  <img
                    className="-rotate-180"
                    onClick={(e: any) => {
                      e.preventDefault();
                      scrollCoursesToLeft("punctual-course");
                    }}
                    src={ArrowRightCircle}
                    alt="arrow icon"
                  />
                  Les réponses disponibles{" "}
                  <img
                    onClick={(e: any) => {
                      e.preventDefault();
                      scrollCoursesToRight("punctual-course");
                    }}
                    src={ArrowRightCircle}
                    alt="arrow icon"
                  />
                </Link>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
};

export default PonctualCourseCard;
