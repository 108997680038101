import { Alert, Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from "swiper";
import activationImage1 from '../../../Assets/Images/activation1.svg'
import activationImage2 from '../../../Assets/Images/activation2.svg'
import activationImage3 from '../../../Assets/Images/activation3.svg'
import activationImage4 from '../../../Assets/Images/activation4.svg'
import 'swiper/css';
import "swiper/css/pagination";
import TeachrContactRepository from '../../../Repositories/TeachrContactRepository';
import { useNavigate, useParams } from 'react-router-dom';
import Teachr from '../../../Objects/models/Teachr';
import TeachrContact from '../../../Objects/models/TeachrContact';
import { Address } from '../../../Objects/models/Address';

const Finish = (props: any) => {
    const pagination = {
        clickable: true
    };
    const params = useParams()
    const navigate = useNavigate()
    const [teachrContact, setTeachrContact] = useState<TeachrContact>()
    const [title , setTitle] = useState('Rendez-vous dans votre boîte mail 📧')
    const [buttonTitle , setButtonTitle] = useState('suivant')
    const [teachr, setTeachr] = useState<Teachr>()
    const [address, setAddress] = useState<Address>()
    const [isEnd, setIsEnd] = useState(false)
    useEffect(() => {
        if (params.key) {
            TeachrContactRepository.fetchTeachrContactByKey(params.key).then((response) => {
                if (response) {
                    setTeachrContact(response)
                    setTeachr(response.teachr)
                    if (response.address) {
                        setAddress(response.address)
                    }
                }
            }).catch(() => navigate('/notfound'))

        } else {
            navigate('/notfound')
        }
    }, [])
    useEffect(() => {
        if (teachrContact && address) {
            if (!teachrContact.status) {
                teachrContact.address = address['@id']
                teachrContact.teachr = teachr? teachr['@id']! : null
                teachrContact.status = true
                TeachrContactRepository.updateTeachrContact(teachrContact)
            }
        }
    }, [address, teachr, teachrContact])
    const formatMessage = (value: string) => {
        const regex = /^[aeiouy]+$/
        if (value?.split('')[0].toLocaleLowerCase().match(regex)) {
            return `d'${value}`
        }
        return `de ${value}`
    }
    const renderScreen = () => {
        // if (teachr) {
            return (
                <div className='md:px-4 md:py-8'>
                    <div className='p-4 md:p-8 bg-[#fff] border-[rgba(0,0,0,0.1)]  border-[1px] shadow-dashboard-setup-intro-form mt-10 rounded-xl relative'>
                        <h1 className='p-4 text-2xl font-black lg:text-3xl text-first md:p-0 '>{title}</h1>
                        <Box sx={{
                            '& .swiper-pagination-bullet': {
                                background: '#D9D9D9',
                                opacity: 1
                            },
                            '& .swiper-pagination-bullet-active': {
                                background: '#0054A3'
                            }
                        }}>
                            <Swiper
                                onSlideChange={(swiper) => {swiper.realIndex === 2 ? setTitle("Comment ça marche ?") : swiper.realIndex === 3 ? setTitle("Besoin d’aide ?") : setTitle("Rendez-vous dans votre boîte mail 📧");
                                swiper.isBeginning ? setButtonTitle('suivant') : setButtonTitle("ok")
                                    swiper.isEnd ? setIsEnd(true) : setIsEnd(false)
                                }}
                                loop
                                pagination={pagination}
                                slidesPerView={1}
                                spaceBetween={100}
                                navigation={{
                                    nextEl: ".next-slide"
                                }}
                                modules={[Pagination, Navigation]}
                            >
                                <SwiperSlide>
                                    <div>
                                        <div className="flex flex-col w-full md:items-center gap-x-14 md:flex-row gap-y-4">
                                            <img src={activationImage1} alt="" />
                                            <div>
                                                <h2 className='text-lg font-extrabold lg:text-2xl text-first'>Parcours d’activation </h2>
                                                <p className='py-8 font-bold leading-6 lg:text-lg text-first'>
                                                    Félicitations, votre compte Avance Immédiate vient d’être enregistré grâce aux informations que vous nous avez mentionnées. Vous devez maintenant valider celui-ci afin de bénéficier de l’Avance Immédiate Crédit d’Impôt.
                                                    {/* <Alert severity="warning"  className='py-8 font-bold leading-6 lg:text-lg font-nunito'>
                                                        Information en date du 25/07/2024 <br/>
                                                        Les serveurs de l'URSSAF rencontrent actuellement des difficultés pour vous faire parvenir les mails d'activation de compte si vous n'en possédez pas encore un. 
                                                        Le mail peut prendre jusqu'à 72h pour vous parvenir. Si vous ne l'avez pas encore reçu passé ce délai, vous pouvez demander un nouvel envoi en cliquant ici : <a href='https://www.particulier.urssaf.fr/#/lien-activation-compte'>https://www.particulier.urssaf.fr/#/lien-activation-compte</a> 
                                                    </Alert> */}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='w-full'>
                                        <div className="flex flex-col w-full md:items-center gap-x-14 md:flex-row gap-y-4">
                                            <img src={activationImage2} alt="" />
                                            <div>
                                                <h2 className='text-lg font-extrabold lg:text-2xl text-first'>Parcours d’activation </h2>
                                                <p className='py-8 font-bold leading-6 lg:text-lg text-first'>
                                                    Vous venez de recevoir un mail de la part de l’URSSAF qui vous permet de valider votre compte CESU+. Vous disposez de 7 jours pour valider votre compte après réception de celui-ci.
                                                    <br />L'e-mail de validation provient de l'adresse mail suivante : "Veuillez-ne-pas-repondre@urssaf.fr" <br />
                                                    Une fois votre mot de passe renseigné sur le site de l’Urssaf, il vous sera demandé de vous connecter en utilisant un identifiant et votre mot de passe.
                                                    Veuillez noter que votre identifiant est l’adresse mail que vous venez d’utiliser dans le formulaire.
                                                    Pour toute question, vous pouvez contacter notre équipe à l’adresse adresse mail suivante : "support@teachr.fr"
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="flex flex-col w-full md:items-center gap-x-14 md:flex-row gap-y-4">
                                            <img src={activationImage3} alt="" />
                                            <div>
                                                <h2 className='text-lg font-extrabold lg:text-2xl text-first '>Demandes de paiements et plafond du crédit d'impôts</h2>
                                                <p className='py-8 font-bold leading-6 lg:text-lg text-first'>
                                                Une fois votre compte activé vous recevrez alors les demandes de paiement de la part {teachr?.firstName? formatMessage(teachr.firstName): "de votre professeur"} sur votre compte et aurez 48h pour les valider ou les contester. Passé ce délai, la demande de paiement sera considérée comme automatiquement validée et le processus de paiement enclenché.
                                                <br />
                                                Nous vous invitons à consulter les plafonds de crédit d'impôt dépendant de votre situation en vous rendant sur ce lien : https://www.impots.gouv.fr/portail/particulier/emploi-domicile
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div>
                                        <div className="flex flex-col w-full md:items-center gap-x-14 md:flex-row gap-y-4">
                                            <img src={activationImage4} alt="" />
                                            <div>
                                                <p className='py-8 font-bold leading-6 lg:text-lg text-first'>
                                                Si vous avez besoin d’une quelconque information concernant votre compte CESU+ ou le service Avance Immédiate Crédit d’Impôt, Teach’r est à votre disposition pour vous aider par mail à l’adresse support@teachr.fr ou par téléphone au 01.84.80.26.15
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </Box>
                        <div className="flex justify-end">
                            <Button onClick={() => { isEnd && props.callBack(true) }} className='w-full py-2 text-lg font-extrabold text-white uppercase rounded-full px-14 bg-first next-slide md:w-max'>{buttonTitle}</Button>
                        </div>
                    </div>
                </div>)
        // }
        // return null
    }
    return (
        <div className='lg:w-3/4'>
            {renderScreen()}
        </div>
    );
};

export default Finish;