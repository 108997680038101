const SET_INFO = 'SET_AE_STEP';

const initialState = 0

export default  function StepperAeReducers(state = initialState, action:{type: string, payload: number}) {
    switch (action.type) {
      case SET_INFO:
        return action.payload;
      default:
        return state
    }
  }