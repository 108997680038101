import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
// import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import cours_regulier from "../../../../../Assets/Images/cours_regulier.png";
// import stage_revision from "../../../../../Assets/Icons/stage_d.svg";
import closeCircle from '../../../../../Assets/Icons/close_circle.svg'
import StepperStore from '../../../../../LocalForage/StepperStore';
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
import ComponentChildDeclareCourse from './ComponentChildDeclareCourse';
import ChildRepository from '../../../../../Repositories/ChildRepository';
import { auth } from '../../../../../Services/FirebaseService';
import { useAuthState } from 'react-firebase-hooks/auth';

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  handleClickOpen: (open?: boolean) => void;
}

function ConfirmationDialogRaw(props: ConfirmationDialogRawProps) {
  const { onClose, handleClickOpen, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [clientTiersPrest, setClientTiersPrest] = React.useState<any>();
  const [teachrId, setTeachrId] = React.useState<any>();
  const radioGroupRef = React.useRef<HTMLElement>(null);
  const [load, setLoad] = React.useState(false)
  const [isload, setIsLoad] = React.useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLoad = () => {
    setIsLoad(!isload);
  }
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };
  const handleOk = () => {
    onClose(value);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  React.useEffect(() => {
    TeachrRepository.getCurrentTeachr().then((teachr) => {
      setTeachrId(teachr)
    })
  }, [])
  React.useEffect(() => {
    handleLoad()
    if (teachrId) {
      TeachrRepository.get_client_by_teacher_favorite(teachrId.id).then((client) => {
        setClientTiersPrest(client.clients)
      })
    }
  }, [teachrId])

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", height: 500 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <div>
        <div className="flex justify-between">
          <h1 className="font-extrabold uppercase p-4 text-dark_blue md:text-lg text-base">
            Mes élèves
          </h1>
          <div className="right-4 //w-1/4 // top-3 absolute">
            <img
              onClick={() => handleOk()}
              className="cursor-pointer"
              src={closeCircle}
              alt=""
            />
          </div>
        </div>
      </div>
      <DialogContent>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleChange}
        >
          {clientTiersPrest?.map((child: any, index: any) => (
            <div className="flex" key={child.id || index}>
              <ComponentChildDeclareCourse
                child={child}
                teachrId={teachrId}
                handleLoad={handleLoad}
                handleOk={handleOk}
                handleClickOpen={handleClickOpen}
                setLoad={setLoad}
                load={load}
              />
            </div>
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <button
          disabled={load}
          onClick={handleOk}
          className={load ? "py-1 w-4/5 mx-auto font-extrabold uppercase text-[#4d4d4d] border-[#4d4d4d] border-2 md:text-base text-sm rounded-full opacity-50" : "py-1 w-4/5 mx-auto font-extrabold uppercase text-first rounded-full md:text-base text-sm border-first border-2 my-2 hover:bg-first hover:text-white"}
        >
          Séléctionner
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default function ConfirmationDialog(props: any) {
  const { handleChangeStep } = props
  const [user] = useAuthState(auth)
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [child, setChild] = React.useState<any>();
  const [stepper, setStepper] = React.useState<any>(0)
  const [error, setError] = React.useState({
    value: 'null'
  })
  const validate = (val: any) => {
    if (val === "") {
      return {
        isValid: false,
        message: "Veuillez ajouter un élève",
      };
    } else
      return {
        isValid: true,
        message: "",
      };
  }
  const validateA = () => {
    let errvalue = true;
    if (!validate(value).isValid) {
      setError({ value: validate(value).message });
      errvalue = true;
    } else {
      setError({ value: validate(value).message });
      errvalue = false;
    }

    if (errvalue) {
      return false;
    } else {
      return true;
    }
  };
  const handleNextPunctual = () => {
    if (validateA()) {
      handleChangeStep(2);
      StepperStore.setItem("cours" + user?.uid, {
        cours: "punctual",
        // client: child?.parent,
        client: parseInt(child?.parent["@id"].substr(9))
      });
    }
  }
  const handleNextPunctualMonth = () => {
    if (validateA()) {
      handleChangeStep(2);
      StepperStore.setItem("cours" + user?.uid, {
        cours: "punctualMonth",
        // client: child?.parent,
        client: parseInt(child?.parent["@id"].substr(9))
      });
    }
  }
  const handleNextCourseForAll = () => {
    // if (validateA()) {
      handleChangeStep(10);
    //   StepperStore.setItem("cours" + user?.uid, {
    //     cours: "punctualMonth",
    //     client: child?.parent,
    //     client: parseInt(child?.parent["@id"].substr(9))
    //   });
    // }
  }

  

  const handleClickOpen = () => {
    setOpen(true);
  };
  React.useEffect(() => {
    (async () => {
      const value: any = await StepperStore.getItem("student" + user?.uid);
      StepperStore.removeItem("activeStepundefined")
      if (value !== null) {
        setValue(value.value);
      }
      StepperStore.setItem("client" + user?.uid, {
        // client: child?.parent
        client: parseInt(child?.parent["@id"].substr(9)),
      });
    })();
  }, [child?.parent, user?.uid]);
  React.useEffect(() => {
    if (value !== "") {
      const id = parseInt(value)
      ChildRepository.fetchChildById(id).then((res) => {
        setChild(res)
      }).catch((re) => console.warn(re))
    }
  }, [value])
  const handleClose = (newValue?: string) => {
    if (newValue) {
      setValue(newValue);
    }
    StepperStore.setItem("student" + user?.uid, {
      value: newValue,
      child: child
    })
    setOpen(false)
  };

  return (
    <div>
      <div className="mt-2 //border-2">
        <Box>
          <List component="div" role="group">
            <div
              onClick={handleClickOpen}
              className="text-dark_blue p-1 lg:text-lg md:text-base text-base font-extrabold text-center md:w-1/2 px-auto border-2 border-dark_blue rounded-[24px] hover:bg-white hover:text-first cursor-pointer"
            >
              {child === undefined ? "Ajouter" : child.firstName}
            </div>
            <ConfirmationDialogRaw
              id="ringtone-menu"
              keepMounted
              open={open}
              onClose={handleClose}
              value={value}
              handleClickOpen={handleClickOpen}
            />
          </List>
        </Box>
      </div>
      <div className="//lg:right-2 //hidden md:flex  //right-4 //top-56 //lg:top-24 //absolute">
        <p className="text-[#FF724F] h-6 w-full text-base md:text-base ">
          {error.value === "null" || error.value === ""
            ? null
            : validate(value).message}
        </p>
      </div>
      <div>
        <div className="xl:mt-4 mt-2">
          <p className="text-dark_blue lg:text-lg md:text-base text-base font-bold">
            De quel type de cours s’agit-il ?{" "}
          </p>
        </div>
        <div className="w-full relative  mx-auto md:translate-y-6 grid gap-6 mt-4 md:mt-0 md:space-y-0 lg:grid-cols-2 lg:px-0 md:px-10">
          <div
            onClick={() => {
              handleNextPunctual();
            }}
            className="bg-input-color pt-2 p-1 shadow-shadow-button cursor-pointer text-dark_blue  hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around"
          >
            <div className="  pl-3 w-1/4">
              <img
                src={cours_regulier}
                alt=""
                className="rounded-full w-10 mt-[4px]"
              />
            </div>
            <div className="  w-3/4 cursor-pointer">
              <div>
                <p className="  xl:text-base text-base  font-bold cursor-pointer">
                  Cours ponctuel
                </p>
              </div>
              <div className="">
                <span className="text-sm">
                  Un cours à venir, à une date précise
                </span>
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              handleNextPunctualMonth();
            }}
            className="bg-input-color pt-2 p-1 shadow-shadow-button cursor-pointer text-dark_blue  hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around"
          >
            <div className="  pl-3 w-1/4">
              <img
                src={cours_regulier}
                alt=""
                className="rounded-full w-10 mt-[4px]"
              />
            </div>
            <div className="  w-3/4 cursor-pointer">
              <div>
                <p className="  xl:text-base text-base  font-bold cursor-pointer">
                  Plusieurs cours
                </p>
              </div>
              <div className="">
                <span className="text-sm">
                  Déclarer plusieurs heures de cours étalées sur plusieurs jours
                </span>
              </div>
            </div>
          </div>
          {/* <div  onClick={() => {  handleNextCourseForAll(); }} className="bg-input-color pt-2 p-1 shadow-shadow-button cursor-pointer text-dark_blue  hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around" >
            <div className="  pl-3 w-1/4">
              <img  src={cours_regulier} alt="" className="rounded-full w-10 mt-[4px]"  />
            </div>
            <div className="  w-3/4 cursor-pointer">
              <div>
                <p className="  xl:text-base text-base  font-bold cursor-pointer">
                  Plusieurs élèves
                </p>
              </div>
              <div className="">
                <span className="text-sm">
                  Déclarez les cours de tous vos élèves
                </span>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
