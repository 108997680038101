let IBAN = require("iban");
const ValidateIbanAici = (iban) => {
  if (!IBAN.isValid(iban)) {
    return {
      isValid: false,
      message: "Veuillez entrer un IBAN valide",
    };
  } else {
    return {
      isValid: true,
      message: "",
    };
  }
};

export default ValidateIbanAici;