import * as React from 'react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import SubjectRepository from '../../../../../../../Repositories/SubjectRepository';
import { Subject } from '../../../../../../../Objects/models/Subject';
import ChildRepository from '../../../../../../../Repositories/ChildRepository';
import Child from '../../../../../../../Objects/models/Child';
import Functions from '../../../../../../../Helpers/Functions';
import PunctualCourseRepository from "../../../../../../../Repositories/PunctualCourseRepository";
import UsrssafStatusService from "../../../../../../../Objects/models/UrssafStatusService";
import SubscriptionCourseProposalRepository from '../../../../../../../Repositories/SubscriptionCourseProposalRepositorys';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import { FormControlLabel } from "@mui/material";
import RadioButtonDashboard from '../../../UtilsDashboard/RadioButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { LightTooltip, LightTooltipNoError, translateDay } from '../../../UtilsDashboard/FunctionsDashbord';

const validateValue = (value: any) => {
    if (value === '') {
        return {
            isValid: false,
            message: "Veuillez séléctionner un cours",
        };
    }
    else
        return {
            isValid: true,
            message: "",
        };
};

const ComponentBodyTableSubscriptionPause = (props: any) => {
    const { punctual, isHere, isPlay, isDelete, handleEdit, handleClickOpenPlay, val } = props
    const [subject, setSubject] = useState<Subject>()
    const [status, setStatus] = useState<UsrssafStatusService>()
    const [proposal, setProposal] = useState<any>()
    const [child, setChild] = useState<Child>()
    const [error, setError] = useState({
        value: "null"
    })

    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {
                SubjectRepository.fetchSubjectById(punctual.subjectAsked_id).then((subject) => {
                    setSubject(subject)
                })
                SubscriptionCourseProposalRepository.fetchSubscriptionCourseProposalByIdTeachr(teachr.id, punctual.acceptedProposal_id).then((res) => {
                    setProposal(res)
                    setProposal(res)
                })
                ChildRepository.fetchChildByIdForTeachr(punctual.forChild_id, punctual.directAskedTeachrId).then((child) => {
                    setChild(child)
                })
                if (punctual?.urssafServiceStatus !== null) {
                    PunctualCourseRepository.getUsrssafStatusServiceIdById(punctual?.urssafServiceStatus).then((status) => {
                        setStatus(status)
                    }).catch((err) => {
                        console.warn(err);
                    })
                }


            }

        })
    }, [])


    return (
        <>
            <tbody className="text-[#4D4D4D] font-bold overflow-y-auto scroll">
                {punctual.resumesAt !== null &&
                    <tr className="//bg-[#808080] bg-gray-50">
                        {isHere &&
                            <>
                                {isPlay ?
                                    <>
                                        {
                                            error.value === "null" || error.value === "" ?
                                                <LightTooltipNoError title={error.value === "null" || error.value === "" ? "Reprendre " : validateValue(val).message}>
                                                    <td className=" //py-2 px-1">
                                                        <FormControlLabel
                                                            value={punctual?.id}
                                                            key={punctual?.id}
                                                            onClick={handleClickOpenPlay}
                                                            control={<PlayCircleOutlineIcon sx={{ color: "#0054A3" }} />}
                                                            labelPlacement="start"
                                                            label=""
                                                        />
                                                    </td>
                                                </LightTooltipNoError>
                                                :
                                                <LightTooltip title={error.value === "null" || error.value === "" ? "Reprendre " : validateValue(val).message}>
                                                    <td className=" //py-2 px-1">
                                                        <FormControlLabel
                                                            value={punctual?.id}
                                                            key={punctual?.id}
                                                            onClick={handleClickOpenPlay}
                                                            control={<PlayCircleOutlineIcon sx={{ color: "#0054A3" }} />}
                                                            labelPlacement="start"
                                                            label=""
                                                        />
                                                    </td>
                                                </LightTooltip>
                                        }
                                    </> :
                                    isDelete ?
                                        <>
                                            {
                                                error.value === "null" || error.value === "" ?
                                                    <LightTooltipNoError title={error.value === "null" || error.value === "" ? "Supprimer " : validateValue(val).message}>
                                                        <td className=" //py-2 px-1">
                                                            <FormControlLabel
                                                                value={punctual?.id}
                                                                key={punctual?.id}
                                                                onClick={handleEdit}
                                                                control={<RadioButtonDashboard image={<DeleteIcon sx={{ color: "#0054A3" }}/>} />}
                                                                labelPlacement="start"
                                                                label=""
                                                            />
                                                        </td>
                                                    </LightTooltipNoError>
                                                    :
                                                    <LightTooltip title={error.value === "null" || error.value === "" ? "Supprimer " : validateValue(val).message}>
                                                        <td className=" //py-2 px-1">
                                                            <FormControlLabel
                                                                value={punctual?.id}
                                                                key={punctual?.id}
                                                                onClick={handleEdit}
                                                                control={<RadioButtonDashboard image={<DeleteIcon sx={{ color: "#0054A3" }}/>} />}
                                                                labelPlacement="start"
                                                                label=""
                                                            />
                                                        </td>
                                                    </LightTooltip>
                                            }
                                        </> : ""
                                }
                            </>

                        }
                        <td className="py-2 px-1 hidden lg:flex">{moment(punctual.startDate).format('dddd DD/MM/YYYY')}</td>
                        <td className="py-2 px-1 lg:hidden">{moment(punctual.startDate).format('DD/MM/YY')}</td>
                        <td className="py-2 px-1">{child?.firstName}</td>
                        <td className="py-2 px-1">{subject?.fr_FR}</td>
                        <td className="py-2 px-1 //w-6 //border-2">{punctual?.daysOfTheWeek.map((day: string, key: any) => <span key={key} className="after:content-[','] last:after:content-['']">&#160;{translateDay(day)}</span>)}</td>
                        <td className="py-2 px- text-center //flex justify-around">
                            <div>{proposal?.totalDailyPrice}€
                            </div>
                        </td>
                        <td className="py-2 px-1 text-center //text-center">{Functions.renderDuration(punctual.durationPerDay)}</td>
                        <td className="py-2 px-1 text-center //text-center">{Functions.renderDuration(punctual.durationPerDay * punctual?.daysOfTheWeek.length)}</td>
                    </tr>}
            </tbody>
        </>
    );
};

export default ComponentBodyTableSubscriptionPause;