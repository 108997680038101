import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import Functions from '../../../../Helpers/Functions';
import Teachr from '../../../../Objects/models/Teachr';
import ImageRepository from '../../../../Repositories/ImageRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import uploadIcon from '../../../../Assets/Icons/upload.svg'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const fileTypes = ["PDF", "PNG", "JPEG", "JPG"];
const Fourth = (props: any) => {
    const dispatch = useDispatch()
    const [file, setFile] = useState<File | null>();
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    const generalTeachr = useSelector((state: any) => state.teachr)
    const navigate = useNavigate()
    useEffect(() => {
        if (generalTeachr) {
            if (!generalTeachr.teachr) {
                navigate('/teachr/login')
            } else {
                setTeachr(generalTeachr.teachr)
            }
        } else {
            navigate('/teachr/login')
        }
    }, [generalTeachr])
    const handleChange = (value: any) => {
        if (value) {
            setError(false)
            if (value.type.search("image") === 0) {
                const fileType = value.type.split('/')
                Functions.resizeFile(value, true,fileType[1]).then((response: any) => {
                    setFile(response);
                })
            } else {
                setFile(value)
            }
        }
    };
    const uploadFile = () => {
        if (file && teachr) {
            setLoading(true)
            ImageRepository.createImage(new File([file], 'proofissignSap', { type: file.type })).then((response) => {
                teachr.proofSignSap = response['@id']
                if (teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE['@id']
                }
                if (teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity['@id']
                }
                if (teachr.proofMandat) {
                    teachr.proofMandat = teachr.proofMandat['@id']
                }
                TeachrRepository.updateTeachr(teachr).then((teachrResponse) => {
                    dispatch({
                        type: 'SET_TEACHR_INFO',
                        payload: {
                            teachr: teachrResponse
                        }
                    })
                    props.nextStep(4)
                    setLoading(false)
                }).catch((error)=> {
                    console.warn(error)
                    setLoading(false)})
            }).catch((error) => {
                console.warn(error)
                setLoading(false)
            })
        }
    }
    return (
        <div className='flex flex-col lg:border border-[rgba(146,211,255,0.54)] lg:shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-4'>
            <h4 className='text-lg font-extrabold lg:text-[22px] text-first'>NOVA : Arrêté préfectoral</h4>
            <div className="flex flex-col gap-y-8">
                <p className='text-base font-semibold text-normal lg:text-lg'>Veuillez nous transmettre votre arrêté préfectoral délivré par NOVA à la suite de votre déclaration.</p>
                {
                    error ?
                        <FileUploader
                            classes='focus-within:bg-first lg:order-first'
                            label="qdqsdsqsd"
                            hoverTitle="qdsdqsdqsdsdsqdsq"
                            children={
                                <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-third bg-[#F2F5FB] p-4  gap-y-8'>
                                    <p className='text-third'>Veuillez insérer un fichier au format : ”PDF, PNG ou JPEG” ❌</p>
                                    <p className='text-sm font-semibold text-center text-normal'>
                                        glissez  ou choisissez <a href="" className='font-bold text-radio'>un fichier</a>
                                    </p>
                                </div>
                            }
                            onTypeError={(value: any) => setError(true)}
                            handleChange={handleChange}
                            name="file"
                            types={fileTypes} 
                        />

                        :
                        file ?
                            <div className='flex items-center flex-col  rounded-[22px] border-dashed py-8 border-2 border-first bg-[#F2F5FB] p-4  gap-y-8 '>
                                <div className="flex items-center gap-x-4">
                                    <a href={URL.createObjectURL(file)}>“{file.name}” </a>
                                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.4388 0C3.78541 0 0 3.81286 0 8.5C0 13.1871 3.78541 17 8.4388 17C13.0922 17 16.8776 13.1871 16.8776 8.5C16.8776 3.81286 13.0922 0 8.4388 0ZM14.258 6.21471L7.55393 12.9674C7.3502 13.1726 7.06689 13.2904 6.77877 13.2904V13.7481L6.76912 13.2904C6.47738 13.2879 6.19287 13.1653 5.99034 12.9528L2.60638 9.42286C2.18686 8.9845 2.19891 8.28386 2.63411 7.86129C2.83905 7.66093 3.1103 7.55164 3.39481 7.55164C3.69499 7.55164 3.97588 7.67186 4.18444 7.88921L6.79203 10.6116L12.7076 4.65314C12.915 4.44429 13.1898 4.33014 13.4828 4.33014C13.7757 4.33014 14.0506 4.4455 14.258 4.65314C14.6847 5.08421 14.6847 5.78486 14.258 6.21471Z" fill="#0054A3" />
                                    </svg>
                                </div>
                                <Button onClick={() => setFile(null)} className='mx-auto text-xs font-semibold underline text-normal w-max'>Supprimer le fichier</Button>
                            </div> :
                            <FileUploader
                                classes='focus-within:bg-first'
                                label="qdqsdsqsd"
                                hoverTitle="qdsdqsdqsdsdsqdsq"
                                children={
                                    <div className='flex items-center  rounded-[3px] border-dashed py-8 border-2 border-[#B7B7B7] bg-white p-4  gap-x-8 justify-center'>
                                        <img src={uploadIcon} alt="" />
                                        <p className='text-sm font-semibold text-center text-normal'>
                                            Faites glisser votre arrêté préfectoral ou choisissez <a href="" className='font-bold text-first'>un fichier</a>
                                        </p>
                                    </div>
                                }
                                onTypeError={(value: any) => setError(true)}
                                handleChange={handleChange}
                                name="file"
                                types={fileTypes}
                            />

                }
            </div>
            <div className="flex justify-between pt-8 flex-col lg:flex-row gap-y-4">
                <Button disabled={loading} onClick={() => props.nextStep(2)} className='text-[#B7B7B7] lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 disabled:opacity-30 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
                <Button disabled={loading} onClick={uploadFile} className='px-8 py-2 font-extrabold text-white rounded-full bg-radio disabled:opacity-30 w-full lg:w-max'>SUIVANT </Button>

            </div>
        </div>
    );
};

export default Fourth;