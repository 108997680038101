import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import info from "../../../../../Assets/Images/infos.jpg";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../../../Assets/Images/checkclose.png";
// import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// import Functions from "../../../../../Helpers/Functions";
// import { useNavigate } from "react-router-dom";
// import CoursPonctuel from "../CoursPonctuel";
// import useState from 'react';

export default function IndexPrice(props: any) {
    const [open, setOpen] = React.useState(false);
    // const navigate = useNavigate()
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const { df, price, component } = props

    return (
        <div className="font-nunito">
            <span className="text-dark_blue " onClick={handleClickOpen}>
                {/* <InfoOutlinedIcon/> */}
                {props.component}
            </span>
            <Dialog open={open} onClose={handleClose}>
                <div className="border-dark_blue border-1 p-3">
                    <DialogTitle>
                        <div className="flex justify-between mb-3">
                            <span className="text-dark_blue font-extrabold uppercase font-nunito lg:text-[22px] md:text-lg text-base">
                                Comment cela est-il possible ?
                            </span>
                            <img
                                src={close}
                                alt=""
                                className="md:w-6 w-6 md:h-6 h-6 //translate-y-2"
                                onClick={handleClose}
                            />
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <p className="text-[#4d4d4d] font-nunito lg:text-lg text-base">
                            En facturant votre cours à {price === undefined ? "70" : (price * 2).toFixed(2)}€, votre élève ne paiera que la moitié. Les frais de gestion de Teach'r étant de {df*100}% du montant facturé, Soit {price === undefined ? "70" : (price * 2 * df).toFixed(2)}€, vous gagnerez donc {price === undefined ? "70" : (price * 2).toFixed(2)}€ - {price === undefined ? "70" : (price * 2 * df).toFixed(2)}€ = {price === undefined ? "70" : (price * 2 * (1-df)).toFixed(2)}€ par heure de cours facturée à ce prix-là.
                        </p>
                    </DialogContent>
                    <DialogActions>
                        <div className="md:w-1/2 w-full mb-4 mx-auto  "
                            onClick={handleClose}
                        >
                            <button className=" bg-white rounded-full py-2 text-dark_blue border-[2px] font-extrabold  shadow-shadow-button uppercase px-4 w-full hover:bg-dark_blue hover:text-white hover:border-white active:bg-dark_blue active:text-white active:border-white">
                                D'accord
                            </button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
