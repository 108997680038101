

export default class Simulator {

    externalIncomeTax: number
    teachrIncomeTax: {
        classic: number,
        liberatoire: number
    }
    turnover: {
        classic: number,
        liberatoire: number
    }
    hourlyPay: {
        classic: number,
        liberatoire: number
    }
    constructor(
        teachrIncomeTax: {
            classic: number,
            liberatoire: number
        },
        externalIncomeTax: number,
        turnover: {
            classic: number,
            liberatoire: number
        },
        hourlyPay: {
            classic: number,
            liberatoire: number
        }
    ) {

        this.teachrIncomeTax = teachrIncomeTax
        this.externalIncomeTax = externalIncomeTax
        this.turnover = turnover
        this.hourlyPay = hourlyPay
    }

}
