import { Box, CircularProgress, CircularProgressProps, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material/styles';
import CircularStep from './CircularStep';
import LinearStep from './LinearStep';

// const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
//     height: 4,
//     borderRadius: 5,
//     [`&.${linearProgressClasses.colorPrimary}`]: {
//         backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
//     },
//     [`& .${linearProgressClasses.bar}`]: {
//         borderRadius: 5,
//         background: theme.palette.mode === 'light' ? '#0054A3' : '#0054A3',

//     }
// }));
const Stepper = (props: any) => {
    const renderCircle = (key: number) => {
        if (key > props.currentStep) {
            return (<CircularStep value={key + 1} complete={false} />)
        } else if (props.currentStep === key) {
            return (<CircularStep value={key + 1} active complete={false} />)
        } else {
            return (<CircularStep value={key + 1} complete={true} />)
        }
    }
    const goBack = (current:number, step:number)=>{
        if (step < current && current <= 3) {
            if (step >= 2) {
                step = step - 1
                props.nextStep(step)
            } else {
                props.nextStep(step)
            }
        }
    }
    return (
        <div>
            <div className='flex-col hidden lg:flex'>
                {props.steps.map((step: any, key: number) => (
                    <div key={key} className='flex h-[118px] gap-8 items-center'>
                        <p onClick={()=>goBack(props.currentStep, key)} className={`w-full cursor-pointer text-xl font-bold text-right whitespace-nowrap  ${props.currentStep >= key  ? 'text-first' : 'text-input-gray'}`}>{step}</p>
                        <div>
                            {renderCircle(key)}
                            {key !== props.steps.length - 1 && <LinearStep value={props.currentStep >= key - 1 ? 100 : 0} complete={props.currentStep >= key + 1 ? true : false} />}

                        </div>
                    </div>
                ))}
            </div>
            <div className='w-full px-4 py-8 lg:hidden md:px-8'>
                <div className='flex justify-center w-full'>
                    {props.steps.map((step: any, key: number) => (
                        <div key={key} className={key !== props.steps.length - 1 ? 'md:w-[27.666667%] w-full' : 'w-max'}>
                            <div className='flex items-center w-full'>
                                <div>
                                    {renderCircle(key)}
                                </div>
                                <div className='w-full'>
                                    {key !== props.steps.length - 1 && <LinearStep value={props.currentStep >= 1 ? 100 : 0} complete={props.currentStep >= key + 1 ? true : false} />}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex justify-between w-full gap-2 md:gap-0">
                    {props.steps.map((step: any, key: number) => (
                        <div>
                            <p className={`w-full text-xs font-bold  ${props.currentStep >= key  ? 'text-first' : 'text-input-gray'}`}>{step}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Stepper;