import moment from "moment";
import errorIcon from "../Assets/Images/error.png";
const validateDate = (value) => {
  const age = moment().subtract(moment(value).format('YYYY'),'year').format('YY')
    if (value === "") {
      return {
        isValid: false,
        message: "Entrez la date de naissance",
        icon:<img src={errorIcon} alt="" className="w-4 h-4" />
      };
    }
    if (age < 3) {
      return {
        isValid: false,
        message: "L'élève doit avoir au moins 3 ans",
        icon:<img src={errorIcon} alt="" className="w-4 h-4" />
      };
    }
    else
      return {
        isValid: true,
        message: "",
        icon:""
      };
  };
  
  function getLastDayOfMonth() {
    const today = new Date(); // Obtenez la date d'aujourd'hui
    const year = today.getFullYear(); // Année courante
    const month = today.getMonth(); // Mois courant (janvier = 0, décembre = 11)

    // Créez une nouvelle date représentant le dernier jour du mois courant
    // En mettant le jour à '0', JavaScript ajuste la date au dernier jour du mois précédent
    // donc, en ajoutant 1 au mois et en mettant le jour à 0, on obtient le dernier jour du mois courant
    const lastDayOfMonth = new Date(year, month + 1, 0);

    return lastDayOfMonth;
  }

  // Exemple d'utilisation
  const lastDay = getLastDayOfMonth();

  export const validateDateTime = (dateTime) => {
    const date = new Date(dateTime);
    if (!date) {
      return {
        isValid: false,
        message: "Veuillez renseigner une date",
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };
  export const validateEndDateTime = (startDateTime, endDateTime) => {
    let endDay = moment(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)).format("YYYY-MM-DD")
    const endDate = moment(new Date(endDateTime)).format("YYYY-MM-DD");
    const startDate = moment(new Date(startDateTime)).format("YYYY-MM-DD");
    const nows = moment(new Date()).format("YYYY-MM-DD");
    if (endDate < nows) {
      return {
        isValid: false,
        message:
          "La fin de la période des cours doit être aujourd'hui ou plus tard dans le mois.",
      };
    } else if (startDate === endDate) {
      if (endDate === endDay) {
        return {
          isValid: true,
          message: "",
        };
      } else {
        return {
          isValid: false,
          message:
            "Veuillez modifier la date de fin. Si vous souhaitez avoir un cours du mois le même jour.",
        };
      }
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };
  
export const validateDate2 = (date) => {
  let array = [];
  const nows = moment().format("YYYY-MM-DD");
  if (date.length === 0) {
    return {
      error: true,
      message: "Veuillez renseigner une date",
    };
  } else if (date.length === 1) {
    return {
      error: true,
      message: "Veuillez renseigner une date de fin",
    };
  } else if (date.length === 2) {
    date.map((day) => {
      array.push(moment(day?.toDate?.().toString()).format("YYYY-MM-DD"));
    });
    if (array[1] < nows) {
      return {
        error: true,
        message:
          "La fin de la période des cours doit être aujourd'hui ou plus tard dans le mois.",
      };
    } else {
      return {
        error: false,
        message: "",
      };
    }
  } else {
    return {
      error: false,
      message: "",
    };
  }
};


  export default validateDate;