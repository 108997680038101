import React, { useState } from "react";
import cours_regulier from "../../../Assets/Images/cours_regulier.png";
import stage_revision from "../../../Assets/Images/stage_revision.png";
import cours_ponctuel from "../../../Assets/Images/cours_ponctuel.png";
import cours_urgence from "../../../Assets/Images/cours_en urgence.png";
import CoursRegulier from "./ChoiceCourse/CoursRegulier";
import StageRevision from "./ChoiceCourse/StageRevision";
import CoursPonctuel from "./ChoiceCourse/CoursPonctuel";
// import Index from "./ChoiceCourse/courses/Index";
import CourUrgence from "./ChoiceCourse/CourUrgence";

const ChoiceCourse = (props: {
  currentComponent?: any;
  handleNext?: any;
  handleBack?: any;
}) => {
  // const [show, setShow] = useState(false);
  const [choice, setChoice] = useState(false)
  const { handleNext, handleBack, currentComponent } = props;
  // const [component, setComponent] = useState(<CoursRegulier
  //   handleNext={handleNext}
  //   handleBack={handleBack}
  // />);


  const handleSubmit = () => {
    setChoice(true)
  };

  return (
    <div>
      {/* {show ? (
        component
      ) : ( */}
        <div className="space-y-2  md:space-y-6 lg:translate-y-10 translate-y-32 md:translate-y-20">
          <div className=" w-4/5 mx-auto -translate-y-4">
            <h1 className="lg:text-3xl  md:text-2xl text-lg text-dark_blue font-bold">
              Choisissez votre type de cours :
            </h1>
          </div>
          <div className="w-4/5 mx-auto md:translate-y-6 grid grid-cols-1 gap-6  md:space-y-0 md:grid-cols-2">
            <div
              onClick={(event) =>
                props.currentComponent(
                  3,
                  <CoursRegulier
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                )
              }
              className="bg-input-color shadow-shadow-button cursor-pointer text-dark_blue  hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around p-3"
            >
              <div className="-space-y-  w-full pl-2 md:pl-0 pt-2 cursor-pointer">
                <div>
                  <p className="xl:text-xl lg:text-lg md:text-base text-sm  font-bold cursor-pointer">
                    Suivi régulier
                  </p>
                </div>
                <div className="">
                  <span className="text-sm  ">
                    Suivi avec le même professeur
                  </span>
                </div>
              </div>
              <div className="">
                <img
                  src={cours_regulier}
                  alt=""
                  className="rounded-full w-14"
                />
              </div>
            </div>
            <div
              onClick={(event) =>
                props.currentComponent(
                  3,
                  <StageRevision
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                )
              }
              className=" bg-input-color shadow-shadow-button cursor-pointer text-dark_blue hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around p-3"
            >
              <div className="-space-y-  w-full pl-2 md:pl-0 pt-2  ">
                <div>
                  <p className=":text-xl lg:text-lg md:text-base text-sm   font-bold ">
                    Stage de révisions
                  </p>
                </div>
                <div className="">
                  <span className="text-sm truncate">
                    Une aide étalée sur plusieurs jours
                  </span>
                </div>
              </div>
              <div className="">
                <img
                  src={stage_revision}
                  alt=""
                  className="rounded-full w-14"
                />
              </div>
            </div>
            <div
              onClick={(event) =>
                props.currentComponent(
                  3,
                  <CoursPonctuel
                    handleNext={handleNext}
                    handleBack={handleBack}
                  />
                )
              }
              className=" bg-input-color shadow-shadow-button text-dark_blue cursor-pointer hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around p-3"
            >
              <div className=" -space-y-  w-full pl-2 md:pl-0 pt-2   ">
                <div>
                  <p className=":text-xl lg:text-lg md:text-base text-sm   font-bold ">
                    Cours ponctuel
                  </p>
                </div>
                <div className="">
                  <span className="text-sm ">Un seul cours, à la carte</span>
                </div>
              </div>
              <div className="mt-3 md:mt-2">
                <img
                  src={cours_ponctuel}
                  alt=""
                  className="rounded-full w-14"
                />
              </div>
            </div>

            <div
              onClick={(event) =>
                props.currentComponent(
                  3,
                  <CourUrgence
                    handleNext={handleNext}
                    handleBack={handleBack}
                    currentComponent={currentComponent}
                  />
                )
              }
              className="  bg-input-color shadow-shadow-button text-dark_blue cursor-pointer  hover:text-white  hover:bg-dark_blue rounded-xl flex justify-around p-3"
            >
              <div className=" -space-y-  w-full pl-2 md:pl-0 pt-2  ">
                <div>
                  <p className=":text-xl lg:text-lg md:text-base text-sm font-bold">
                    Cours en urgence
                  </p>
                </div>
                <div className="">
                  <span className="text-sm ">Prenez un cours dans l'heure</span>
                </div>
              </div>
              <div className="mt-4 md:mt-2">
                <img src={cours_urgence} alt="" className="rounded-full w-14" />
              </div>
            </div>
          </div>
          <div className="w-4/5 mx-auto text-grey lg:text-lg md:text-base text-sm md:translate-y-10 translate-y-4 -mb-4 text-center">
            <p>{choice ? "Veuillez choisir un type de cours" : null}</p>
          </div>
          <div className="md:hidden  flex justify-around mx-auto space-x-4 w-4/5 translate-y-10 md:translate-y-44 md:w-full">
            <div className="flex w-1/2 rounded-full bg-white text-orange border-2 border-orange font-extrabold justify-center">
              <div
                onClick={handleBack}
                className="text-xs pt-2 w-full text-center uppercase"
              >
                Précédent

              </div>
            </div>
            <div className="flex justify-center w-1/2 ">
              <div
                onClick={(e) => handleSubmit()}
                className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-dark_blue text-dark_blue uppercase rounded-full active:bg-dark_blue active:text-white  "
              >
                Suivant
              </div>
            </div>
          </div>
          <div className="md:flex hidden mt-4 md:translate-y-36 translate-y-10  justify-around md:w-full mx-auto">
            <div className="flex space-x-2 justify-center">
              <button
                type="button"
                onClick={handleBack}
                className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d]  leading-tight uppercase hover:text-dark_blue  focus:outline-none focus:ring-0 active:text-blue-800 transition duration-150 ease-in-out md:w-36 w-24"
              >
                Précédent
              </button>
            </div>
            <div className="flex justify-center space-x-2 ">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className={
                  " inline-block md:px-4 md:py-2 py-2 px-3 border-2 font-bold hover:font-extrabold text-sm lg:text-[17px]  hover:text-white hover:bg-dark_blue    border-dark_blue text-dark_blue  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-dark_blue transition duration-150 ease-in-out md:w-36 w-24 "
                }
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      {/* )} */}
    </div>
  );
};

export default ChoiceCourse;
