import React, { useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import StepperStore from '../../../../LocalForage/StepperStore'
import TeachrRepository from '../../../../Repositories/TeachrRepository'
import ApiService from '../../../../Services/ApiService'
import { auth, getJWTToken } from '../../../../Services/FirebaseService'
import Teachr from '../../../../Objects/models/Teachr'
import { useDispatch } from 'react-redux'

export default function Last() {
  const [user, loading] = useAuthState(auth)
  const [teachr, setTeachr] = useState<Teachr>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    if (!loading) {
      if (user) {
        (async () => {
          // StepperStore.setItem("currentStep" + user.uid, 1, function (err) { })
          const jwtToken = await getJWTToken(user)
          ApiService.disableAuth()
          ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
          TeachrRepository.getCurrentTeachr().then((response) => {
            dispatch({
              type: 'SET_TEACHR_INFO',
              payload: {
                teachr: response,
                authorization: "teachr " + jwtToken
              }
            })
            setTeachr(response)
          }).catch(() => {
            ApiService.disableAuth()
            navigate("/teachr/register/next")
          })
        })()
      }
    }
  }, [user])
  const goToCourse = () => {
    if (teachr) {
      if (teachr.signSap) {
        navigate("/teachr/cours")
      } else {
        if (teachr.proofSignSap && teachr.proofMandat) {
          navigate("/teachr/cours")
        } else {
          navigate("/teachr/profile/complete")
        }

      }
    }
  }
  return (
    <div className="bg-center bg-no-repeat bg-contain last-step-height md:bg-right-bottom bg-bg-register md:h-full ">
      <p className="pb-10 lg:leading-[61px] lg:text-[45px] leading-[30px] text-3xl text-white font-extrabold">
        Inscription terminée !{" "}
      </p>
      <div className="flex flex-col md:w-2/4 gap-y-8">
        <p className="text-base text-white lg:text-xl ">
          Il ne vous reste plus qu'à compléter votre profil, afin que notre
          équipe valide votre compte !
        </p>
        {/* <p className='text-base text-white lg:text-xl '>Vous pouvez réaliser cet entretien quand vous le souhaitez et où vous le souhaitez. </p>
        <p className='text-base text-white lg:text-xl '>⏱️ Durée ≈ 10 minutes </p> */}
        <button
          onClick={goToCourse}
          className="w-full px-4 py-2 mt-10 text-xl font-extrabold text-center text-white uppercase rounded-full text bg-second"
        >
          Compléter votre profil
        </button>
        {/* <p onClick={goToCourse} className='mt-2 font-extrabold text-center text-white underline cursor-pointer'>J'ai déjà passé l'entretien</p> */}
      </div>
    </div>
  );
}
