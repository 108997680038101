import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TeachrRepository from '../../../../../Repositories/TeachrRepository';
// import Teachr from '../../../../../Objects/models/Teachr';
import value from '../../../../../Assets/Images/Validation_1.gif'

const ClientValidate = () => {
    const params = useParams()
    const uid: any = (params.id)
    const uidClient: any = (params.idclient)
    const idTeachr: number = parseInt(uid);
    const idClient: number = parseInt(uidClient);
    const [load, setLoading] = useState(false)
    const [current, setCurrent] = useState(1)

    const validateAccount = () => {
        // setLoading(true)
        // TeachrRepository.addFavoriteTeachr(idTeachr, idClient).then((res) => {
        //     setLoading(true)
        //     setCurrent(2)
        // }).catch((err) => {
        //     setLoading(false)
        // })
    }

    const handleShowComp = (val: number) => {
        switch (val) {
            case 1:
                return (
                    <div>
                        <h1 className='text-lg font-black lg:text-3xl text-first'>Pour commencer à bénéficierez de l'Avance Immédiate Crédit d'Impôt pour vos cours avec <span className=""> votre professeur particulier </span></h1>
                        <p className='my-8 text-base font-bold lg:text-lg text-grey'>Votre professeur particulier vous demande de confirmer votre statut de client, ce qui lui permettra de déclarer légalement les cours à l'URSSAF, assurant ainsi la transparence financière et le respect des obligations fiscales. Cette démarche renforce la confiance entre vous et votre professeur, créant un environnement propice à l'apprentissage. De plus, elle facilite l'obtention de documents administratifs et offre des avantages pour les deux parties.</p>
                        <div className='md:w-1/2 mx-auto translate-y-10'>
                            <input disabled={load} type="button" value={load ? "Chargement.." : "Valider l'ajout"} className='border-2 uppercase cursor-pointer bg-first border-first text-white w-full mx-auto py-2 rounded-full lg:text-lg font-extrabold' onClick={() => { validateAccount() }} />
                        </div>
                    </div>
                )
            case 2:
                return (
                    <div>
                        <h1 className='text-lg font-black lg:text-3xl text-first'>Félicitations, vous avez achevé la validation de votre ajout à la liste de votre professeur, ce qui lui permettra dorénavant de déclarer vos cours. </h1>
                        <div className='md:w-1/2 mx-auto mt-4'>
                            <img src={value} alt="" />
                        </div>
                    </div>
                )
            default:
                break;
        }
    }

    return (
        <div className='bg-[#F4FAFF] w-full h-screen p-4 md:p-10 relative'>
            <div className='md:w-4/6 mx-auto /h-[100%]'>
                {handleShowComp(current)}
            </div>
        </div>
    );
};

export default ClientValidate;