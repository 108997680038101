import { Button, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import React from 'react';
import RadioButton from '../../../Components/utils/RadioButton';
import Layout from './Layout';

const Last = (props:any) => {
    const nextStep = ()=>{
        props.nextStep(4)
    }
    const prevStep = ()=>{
        props.nextStep(2)
    }
    return (
        <div>
            <Layout prev = {<Button onClick={()=> prevStep()} className='w-full font-extrabold uppercase bg-white border-2 border-solid rounded-full text-first md:text-base px-14 md:w-max border-first'>precedent</Button>} next = {<Button onClick={()=> nextStep()} className='py-2 text-lg font-extrabold text-white uppercase rounded-full px-14 bg-first'>SUIVANT</Button>}>
                <div>
                    <h1 className='pb-8 text-2xl font-bold text-first'>Réglez vos préférences :</h1>
                    <p className='text-xl font-bold leading-7 text-first'>Souhaitez-vous valider manuellement chaque cours particulier ? <br />
                        Veuillez noter que dans tous les cas, une fois qu’une demande de paiement est faite auprès de l’URSSAF, vous aurez 48h pour la valider ou la contester. <span className='text-third'>* </span></p>
                    <div className="flex flex-col py-14">
                        <FormControl>
                            <RadioGroup
                                defaultValue="0"
                                name="customized-radios"
                            >
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="0" control={<RadioButton border='2px solid #0054A3' bg='#0054A3' />} label="Non, je souhaite que le professeur déclare tous mes cours et je n’ai pas besoin de confirmer à mon tour les cours particuliers. " />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="1" control={<RadioButton border='2px solid #0054A3' bg='#0054A3' />} label="Oui, je souhaite recevoir une confirmation par mail pour chaque cours. " />
                                <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: '18px' } }} value="2" control={<RadioButton border='2px solid #0054A3' bg='#0054A3' />} label="Oui, je souhaite recevoir une confirmation par mail pour chaque cours ainsi qu’une confirmation par SMS à effectuer." />
                            </RadioGroup>
                        </FormControl>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Last;