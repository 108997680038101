import { SetStateAction, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import AvatarF from '../../../../Assets/Images/profile__image.png'
import AvatarH from '../../../../Assets/Images/avatar-h.svg'
import startIcon from '../../../../Assets/Icons/star.svg'
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
// import emptyComment from '../../../../Assets/Images/empty-comment.svg'
import Teachr from '../../../../Objects/models/Teachr';
import moment from 'moment';
import AddressRepository from '../../../../Repositories/AddressRepository';
import { Address } from '../../../../Objects/models/Address';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import closeCircle from '../../../../Assets/Icons/close_circle.svg'
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import ReviewClientRepository from '../../../../Objects/models/ReviewClientRepository';
import ReviewClient from '../../../../Objects/models/ReviewClient';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
// import Reviews from '../../../../Components/Dashboard/Account/Reviews';
import UploadButton from '../../../../Components/utils/UploadButton';
import Functions from '../../../../Helpers/Functions';
import ImageRepository from '../../../../Repositories/ImageRepository';
import Teachr_Repository from '../../../../Repositories/Teachr_Repository';
import { useNavigate } from 'react-router-dom';
import FacturationUpdate from './IsAe/FacturationUpdate';
// import ValidateIban from '../../../../Validation/Iban';
import ValidateSiret from '../../../../Validation/Siret';
import { useDispatch } from 'react-redux';
import AccountInformation from './AccountInformation';

const Profile = () => {
    const MySwal = withReactContent(Swal)
    const dispatch = useDispatch()
    const [profileLoading, setProfileLoading] = useState(false)
    const [teachr, setTeachr] = useState<Teachr>()
    const [siret, setSiret] = useState('')
    const [address, setAddress] = useState<Address>()
    const [errorSiret, setErrorSiret] = useState(false)
    const [errorAddress, setErrorAddress] = useState(false)
    const [reviews, setReview] = useState<ReviewClient[]>()
    const [average, setAverage] = useState(0)
    const [tagHeight, setTagHeight] = useState(0)
    const [file, setFile] = useState();
    const [image, setImage] = useState<string>();
    const [erroImage, setErrorImage] = useState('')
    const [openDialog, setOpenDialog] = useState(false)
    const [loading, setLoading] = useState(false)

    const getFile = (value: File, error: boolean) => {
        if (error) {
            setErrorImage('Mauvais format de fichier')
        } else {
            setErrorImage('')
            const fileType = value.type.split('/')
            Functions.resizeFile(value, true, fileType[1]).then((response: any) => {
                setFile(response);
                setImage(URL.createObjectURL(response))
            })
        }
    }

    const navigate = useNavigate()

    const [description, setDescription] = useState({
        value: '',
        editMode: false
    })

    useEffect(() => {
        const TagElement = document.getElementById('profile-left')
        if (TagElement) {
            setTagHeight(TagElement.offsetHeight)
        }
    })
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response) {
                setTeachr(response)
                setDescription({
                    value: response?.description,
                    editMode: false
                })
                ReviewClientRepository.getReviewsClient(1, ["punctualCourse.acceptedProposal.teachr.id=" + response.id]).then((responseReview) => {
                    if (responseReview) {
                        setReview(responseReview.members)
                    }
                })
            }
        })
        AddressRepository.getDefaultAddress().then((response) => setAddress(response))
    }, [])
    useEffect(() => {
        if (image && teachr) {
            setProfileLoading(true)
            ImageRepository.createImage(file).then((response) => {
                teachr.profilePicture = response['@id']
                if (teachr.proofIdentity) {
                    teachr.proofIdentity = teachr.proofIdentity['@id']
                }
                if (teachr.proofIdentity2) {
                    teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                }
                if (teachr.proofIsAE) {
                    teachr.proofIsAE = teachr.proofIsAE['@id']
                }
                if (teachr.proofSignSap) {
                    teachr.proofSignSap = teachr.proofSignSap['@id']
                }
                if (teachr && teachr.proofMandat) {
                    teachr.proofMandat = teachr.proofMandat['@id']
                }
                TeachrRepository.updateTeachr(teachr).then(() => {
                    setProfileLoading(false)
                }).catch(() => {
                    setProfileLoading(false)
                })
            })
        }
    }, [image])

    const formatPhone = (phone: string | null | undefined) => {
        if (phone) {

            return '0' + phone.slice(3, 4) + '-' + phone.slice(4, 6) + '-' + phone.slice(6, 8) + '-' + phone.slice(8, 10) + '-' + phone.slice(10, 12)


        }
        return
    }
    useEffect(() => {
        averageReview()
    }, [reviews])
    const averageReview = () => {
        let notes = 0
        let total = 0

        if (reviews) {
            reviews.map((review) => {
                total = reviews.length
                notes += review.note!
            })
            setAverage(notes / total)
        }
    }

    const validateWithCode = (code: string) => {
        return Teachr_Repository.teachrValidateAccountWithCode(teachr!.id!, code!)
    }
    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            const addressObj = new Address(
                response[0].place_id,
                response[0].address_components[0].long_name,
                response[0].address_components[1].long_name,
                response[0].address_components[2].long_name,
                response[0].address_components[3].long_name,
                response[0].address_components[4].long_name,
                response[0].address_components[5].long_name,
                response[0].address_components[6].long_name,
                response[0].geometry.location.lat().toString(),
                '',
                response[0].formatted_address,
                "",
                true)
            addressObj.sessionToken = ''
            setAddress(addressObj)
            setErrorAddress(false)
        })
    }
    const showEditWidget = () => {
        let defaultAddress: Address | null = null
        const getPlaceId = (placeId: string) => {
            geocodeByPlaceId(placeId).then((response) => {
                const addressObject = new Address(
                    response[0].place_id,
                    response[0].address_components[0].long_name,
                    response[0].address_components[1].long_name,
                    response[0].address_components[2].long_name,
                    response[0].address_components[3].long_name,
                    response[0].address_components[4].long_name,
                    response[0].address_components[5].long_name,
                    response[0].address_components[6].long_name,
                    response[0].geometry.location.lat().toString(),
                    '',
                    response[0].formatted_address,
                    teachr?.['@id']!,
                    true)
                addressObject.sessionToken = ''
                defaultAddress = addressObject
            })
        }

        const createAddress = () => {
            let submitError = true
            AddressRepository.createAddress(defaultAddress!).then(() => {
                submitError = true
                return true
            })
                .catch((error) => {
                    submitError = false
                    return false
                })
            return submitError
        }
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            html:
                <div className='h-full'>
                    <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                        <p className=''>Modifier mon adresse</p>
                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                            <label htmlFor="">Adresse</label>
                            <AddressComponent getPlaceId={getPlaceId} />
                        </div>
                        <div className="flex justify-end py-4">
                            <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 font-bold uppercase bg-transparent border-2 border-solid rounded-full text-first lg:w-1/4 border-first'>Modifier</Button>
                        </div>
                    </div>
                </div>,
            preConfirm: () => {
                if (defaultAddress) {
                    return true
                } else {
                    Swal.showValidationMessage('Veuillez entrer une adresse valide')
                    return false
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                MySwal.fire({
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    html:
                        <div className='h-full'>
                            <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                                <p className=''>Modifier mon adresse</p>
                                <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <p>Êtes-vous sûr de vouloir changer d'adresse, celle-ci remplacera la précédente : {teachr?.address?.formattedAddress}</p>
                                <div className="flex justify-between gap-8 py-4">
                                    <Button onClick={() => Swal.close()} className='w-full px-8 py-2 font-bold uppercase bg-transparent border-2 border-solid rounded-full text-third border-third'>Annuler</Button>
                                    <Button onClick={() => Swal.clickConfirm()} className='w-full px-8 py-2 font-bold uppercase bg-transparent border-2 border-solid rounded-full text-first border-first'>Confirmer</Button>
                                </div>
                            </div>
                        </div>
                }).then((result) => {
                    if (result.isConfirmed) {
                        if (!createAddress()) {
                            Swal.showValidationMessage('Erreur: nous rencontrons un problème')
                        }
                    } else {
                        showEditWidget()
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('h-full')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }

    const validSiret = () => {
        let error = false
        if (teachr) {
            if (!ValidateSiret(teachr.RCS).valid) {
                return (error = false);
            }
        }
        return true
    }

    const showValidateWidget = () => {
        if (teachr) {
            if (teachr.proofMandat && validSiret() && teachr.address) {
                MySwal.fire({
                    showCancelButton: false,
                    showConfirmButton: false,
                    html:
                        <div className='h-full'>
                            <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                                <p className=''>Valider mon compte</p>
                                <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                            </div>
                            <div className='flex flex-col gap-4'>
                                <span className=''>Merci de bien vouloir renseigner le code de validation de votre compte qui vous a été transmis par l’un de nos administrateurs.</span>
                                <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                                    <label className='block lg:text-base'>Mon code</label>
                                    <input id="swal-input-code" className={` w-full lg:text-base bg-transparent placeholder:text-grey`} type="text" placeholder='...' />
                                </div>
                                <div className="flex py-8">
                                    <Button onClick={() => Swal.clickConfirm()}
                                        className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>
                                        Valider
                                    </Button>
                                </div>
                            </div>
                        </div>,
                    preConfirm: () => {
                        const code = (MySwal!.getPopup()!.querySelector('#swal-input-code')! as HTMLInputElement).value;
                        if (!code) {
                            Swal.showValidationMessage(`Erreur : code invalide.`);
                            return;
                        }
                        return validateWithCode(code).then(response => {
                            if (response[0] === "BAD_CODE") {
                                Swal.showValidationMessage(`Erreur : code invalide.`)
                            } else {
                                return { response: response[0] }
                            }
                        })
                    }
                    , allowOutsideClick: () => !Swal.isLoading()
                }).then((result) => {
                    if (result.isConfirmed) {
                        MySwal.fire({
                            showCancelButton: false,
                            showConfirmButton: false,
                            html:
                                <div className='h-full'>
                                    <div className='flex items-center justify-between pb-8 text-xl font-extrabold uppercase text-first'>
                                        <p className=''>Valider mon compte</p>
                                        <img src={closeCircle} className="cursor-pointer" onClick={() => Swal.close()} alt="" />
                                    </div>
                                    <div className='flex flex-col gap-4'>
                                        <div className='bg-[#F2F5FB] rouded-lg text-left p-3'>
                                            <span>Félicitations, votre compte est désormais validé 🥳</span>
                                            <span>Vous pouvez dès maintenant accéder à votre Tableau de bord et profiter de l'avance immédiate. </span>
                                        </div>
                                        <div className="flex py-8">
                                            <Button onClick={() => { navigate('/teachr/dashboard'); MySwal.close() }} className='w-4/5 py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>Tableau de bord</Button>
                                        </div>
                                    </div>
                                </div>,
                        })
                    }
                })
            } else if (!teachr.proofMandat) {
                navigate('/teachr/profile/complete')
            } else {
                setOpenDialog(true);
            }
        }

        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('h-half')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')

    }

    const updateDescription = () => {
        if (teachr) {
            teachr.description = description.value
            if (teachr.proofIdentity) {
                teachr.proofIdentity = teachr.proofIdentity['@id']
            }
            if (teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap['@id']
            }
            if (teachr && teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat['@id']
            }
            TeachrRepository.updateTeachr(teachr).then((succes) => {
            }).catch((error) => { console.warn(error) })
        }

    }
    const getProfileImage = () => {
        if (teachr) {
            if (image) {
                return (<img className='w-full h-full rounded-full' src={image} alt="profile" />)
            } else {
                if (!teachr.profilePicture) {
                    if (teachr.sex === 'MAN') {
                        return (<img className='w-full h-full rounded-full' src={AvatarH} alt="profile" />)
                    }
                    return (<img className='w-full h-full rounded-full' src={AvatarF} alt="profile" />)
                } else {
                    return (<img className='w-full h-full rounded-full' src={teachr.profilePicture.contentUrl!} alt="profile" />)
                }
            }

        }
    }
    const UpdateTeachr = () => {
        if (teachr) {
            setLoading(true)
            if (!teachr.address && !teachr.RCS) {
                if (address) {
                    setErrorAddress(false)
                    if (ValidateSiret(siret)) {
                        setErrorSiret(false)

                        AddressRepository.createAddress(address).then((response) => {
                            teachr.RCS = siret
                            teachr.address = response['@id']
                            if (teachr.proofIdentity) {
                                teachr.proofIdentity = teachr.proofIdentity['@id']
                            }
                            if (teachr.proofIsAE) {
                                teachr.proofIsAE = teachr.proofIsAE['@id']
                            }
                            if (teachr.proofSignSap) {
                                teachr.proofSignSap = teachr.proofSignSap['@id']
                            }
                            if (teachr && teachr.proofMandat) {
                                teachr.proofMandat = teachr.proofMandat['@id']
                            }
                            TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                                dispatch({
                                    type: "SET_TEACHR_INFO",
                                    payload: {
                                        teachr: reponseTeachr,
                                    },
                                });
                                setLoading(false)
                                setOpenDialog(false)
                                showValidateWidget()

                            }).catch(() => setLoading(false))
                        }).catch(() => setLoading(false))
                    } else {
                        setLoading(false)
                        setErrorSiret(true)

                    }
                } else {
                    setLoading(false)
                    setErrorAddress(true)
                }
            } else if (teachr.address) {
                if (ValidateSiret(siret)) {
                    setErrorSiret(false)
                    teachr.RCS = siret
                    if (teachr.proofIdentity) {
                        teachr.proofIdentity = teachr.proofIdentity['@id']
                    }
                    if (teachr.proofIsAE) {
                        teachr.proofIsAE = teachr.proofIsAE['@id']
                    }
                    if (teachr.proofSignSap) {
                        teachr.proofSignSap = teachr.proofSignSap['@id']
                    }
                    if (teachr && teachr.proofMandat) {
                        teachr.proofMandat = teachr.proofMandat['@id']
                    }
                    TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                        dispatch({
                            type: "SET_TEACHR_INFO",
                            payload: {
                                teachr: reponseTeachr,
                            },
                        });
                        setLoading(false)
                        setOpenDialog(false)
                        showValidateWidget()
                    }).catch(() => setLoading(false))
                } else {
                    setLoading(false)
                    setErrorSiret(true)
                }
            } else {
                if (address) {
                    setErrorAddress(false)
                    AddressRepository.createAddress(address).then((response) => {
                        teachr.address = response['@id']
                        if (teachr.proofIdentity) {
                            teachr.proofIdentity = teachr.proofIdentity['@id']
                        }
                        if (teachr.proofIsAE) {
                            teachr.proofIsAE = teachr.proofIsAE['@id']
                        }
                        if (teachr.proofSignSap) {
                            teachr.proofSignSap = teachr.proofSignSap['@id']
                        }
                        if (teachr && teachr.proofMandat) {
                            teachr.proofMandat = teachr.proofMandat['@id']
                        }
                        TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                            dispatch({
                                type: "SET_TEACHR_INFO",
                                payload: {
                                    teachr: reponseTeachr,
                                },
                            });
                            setLoading(false)
                            setOpenDialog(false)
                            showValidateWidget()
                        }).catch(() => setLoading(false))
                    }).catch(() => setLoading(false))
                } else {
                    setLoading(false)
                    setErrorAddress(true)
                }
            }

        }
    }
    return (
      <div className="">
        <Dialog
          fullWidth
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiPaper-root": {
              borderRadius: "42px",
              position: "relative",
              overflow: "visible",
              boxShadow: "4px 10px 20px 0px rgba(0,0,0,0.25)",
            },
          }}
        >
          <div className="flex flex-col p-4 md:p-4 gap-y-4">
            <DialogTitle id="alert-dialog-title">
              <h2 className="mt-10 font-extrabold text-center uppercase text-first lg:text-2xl">
                Informations supplémentaires
              </h2>
              <h3 className="mt-10 text-base font-semibold text-center text-first">
                Pour bénéficier de l'avance de crédit d'impôt immédiat, nous
                vous serions reconnaissants de nous fournir les informations
                suivantes.
              </h3>
            </DialogTitle>
            <DialogContent className="flex flex-col py-10 text-center gap-y-8">
              <div className="flex flex-col gap-y-8">
                {teachr && !teachr.address ? (
                  <div>
                    {errorAddress ? (
                      <p className="text-third">
                        Merci de renseigner une adresse
                      </p>
                    ) : null}
                    <div className="flex rounded-xl border border-[rgba(183,183,183,0.64)] p-3">
                      <div className="w-full">
                        <AddressComponent
                          getPlaceId={getAddress}
                          placeholder="Adresse postale :"
                        />
                      </div>
                      <span className="cursor-pointer">
                        <svg
                          width="27"
                          height="27"
                          viewBox="0 0 27 27"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M26.0844 24.5899L15.984 14.6847C17.1924 13.2054 17.9209 11.3318 17.9209 9.29262C17.9209 4.5262 13.9665 0.648682 9.10635 0.648682C4.24555 0.648682 0.291504 4.5262 0.291504 9.29262C0.291504 14.0587 4.24555 17.9366 9.10635 17.9366C11.1124 17.9366 12.9578 17.2684 14.4408 16.1575L24.562 26.0828C24.7722 26.289 25.0478 26.392 25.3231 26.392C25.5987 26.392 25.8743 26.289 26.0844 26.0828C26.5051 25.6703 26.5051 25.0021 26.0844 24.5899ZM2.44476 9.29262C2.44476 5.69052 5.43316 2.76027 9.10635 2.76027C12.7792 2.76027 15.7673 5.69052 15.7673 9.29262C15.7673 11.0695 15.0382 12.6807 13.8608 13.8596C13.8149 13.8918 13.7691 13.9253 13.7281 13.966C13.7024 13.9909 13.6832 14.0192 13.6609 14.0463C12.4686 15.1458 10.8684 15.8253 9.10635 15.8253C5.43347 15.825 2.44476 12.8947 2.44476 9.29262Z"
                            fill="#4D4D4D"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                ) : null}
                {teachr && !validSiret() ? (
                  <div>
                    {errorSiret ? (
                      <p className="text-third">
                        {ValidateSiret(teachr?.RCS).message}
                      </p>
                    ) : null}
                    <input
                      onChange={(e) => setSiret(e.target.value)}
                      type="text"
                      className="w-full rounded-xl border border-[rgba(183,183,183,0.64)] p-3"
                      placeholder="SIRET : 91234567800016"
                    />
                  </div>
                ) : null}
              </div>
            </DialogContent>
            <DialogActions className="">
              <Button
                disabled={loading}
                onClick={UpdateTeachr}
                className="w-full px-8 py-2 font-extrabold text-white rounded-full bg-radio disabled:opacity-30 lg:w-max"
              >
                Confirmer{" "}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
        <div className="md:flex px-2 gap-8 w-full">
          <div
            className="md:w-2/3 w-full  space-y-3 /flex /flex-col  /gap-8 /md:w-1/2 /md:gap-8"
            id="profile-left"
          >
            {teachr ? (
              <div className="flex flex-col p-4 rounded-lg lg:flex-row lg:gap-14 shadow-account text-normal">
                <div>
                  <div className="relative h-[136px] w-[136px]   mx-auto">
                    {profileLoading && (
                      <div className="absolute w-full top-1/3">
                        <div className="mx-auto w-max">
                          <CircularProgress size="2rem" />
                        </div>
                      </div>
                    )}
                    {getProfileImage()}
                    <div className="absolute top-2 right-2">
                      <UploadButton
                        getFile={getFile}
                        button={
                          <AddIcon className="p-[2px] text-2xl text-white font-bold cursor-pointer rounded-full bg-third" />
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col justify-center w-full lg:justify-between lg:flex-row-reverse">
                  <div className="flex flex-col items-center justify-center py-2 text-sm lg:justify-between text-first">
                    <div>
                      <div className="flex rounded-full justify-center w-max gap-2 items-center bg-[rgba(0,0,0,0.1)] p-2 mx-auto">
                        <img src={startIcon} alt="" />
                        <p className="text-[16px] font-bold text-black">
                          {average === 0 ? "N/A" : average.toFixed(1)}
                        </p>
                      </div>
                      <h1 className="py-2">
                        Membre depuis{" "}
                        {moment(teachr?.creationDatetime).format("YYYY")}
                      </h1>
                    </div>
                    <div className="">
                      <Button
                        onClick={() => showEditWidget()}
                        className=" bg-transparent rounded-full py-1 text-[12px] text-first border-solid border-[2px] border-first  font-bold no-underline uppercase px-2"
                      >
                        <span className="uppercase">éditer mon adresse</span>
                      </Button>
                    </div>
                  </div>
                  <div className="py-4">
                    <h1 className="text-xl font-bold capitalize text-first">
                      <span className="font-extrabold uppercase">
                        {teachr?.lastName}
                      </span>{" "}
                      {teachr?.firstName} <br />
                      {moment()
                        .subtract(
                          moment(teachr?.birthday).format("YYYY"),
                          "year"
                        )
                        .format("YY")}{" "}
                      ans
                    </h1>
                    <ul className="flex flex-col justify-center gap-2 pt-4 lg:justify-start">
                      <li className="text-sm">
                        <EmailIcon className="mr-2 text-first" />
                        {teachr?.mail}
                      </li>
                      <li className="text-sm">
                        <LocalPhoneIcon className="mr-2 text-first" />
                        {formatPhone(teachr?.phone)}
                      </li>
                      <li className="text-sm">
                        <LocationOnIcon className="mr-2 text-first" />
                        {address?.formattedAddress}{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <Skeleton variant="rounded" width={"100%"} height={200} />
            )}
            {teachr ? (
              <div className="p-4 my-2 rounded-lg lg:px-12 lg:pb-12 shadow-account text-first">
                <h1 className="py-2 font-extrabold uppercase text-first">
                  Ma description <DescriptionIcon />
                </h1>
                <div className="py-4">
                  <textarea
                    readOnly={description.editMode ? false : true}
                    value={description.value}
                    onChange={(e) =>
                      setDescription({ value: e.target.value, editMode: true })
                    }
                    className="text-left lg:h-[160px] md:h-[100px] h-[160px] w-full resize-none border-2 border-[rgba(0,0,0,0.3)]  p-3 text-normal outline-none rounded-xl"
                    placeholder="Expliquez ici à l’élève ou parent pourquoi il devrait retenir votre candidature et pas une autre. Pensez à soigner l’orthographe 😉"
                  ></textarea>
                </div>
                <div className="flex justify-end">
                  {!description.editMode ? (
                    <Button
                      onClick={() =>
                        setDescription({
                          value: description.value,
                          editMode: true,
                        })
                      }
                      className=" bg-transparent rounded-full py-1 text-[12px] text-first border-solid border-[2px] font-bold border-first no-underline uppercase px-2"
                    >
                      éditer ma description
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        setDescription({
                          value: description.value,
                          editMode: false,
                        });
                        updateDescription();
                      }}
                      className=" bg-transparent rounded-full py-1 text-[12px] text-first border-solid border-[2px] font-bold border-first no-underline uppercase px-2"
                    >
                      Valider ma description
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <Skeleton variant="rounded" width={"100%"} height={200} />
            )}
            {/* <FacturationUpdate /> */}
            {teachr && teachr.isAE && teachr.signSap ? null : (
              <Button
                onClick={showValidateWidget}
                className="w-full uppercase"
                style={{
                  background:
                    "linear-gradient(180deg, #0074E4 0%, #219CFF 100%)",
                  boxSizing: "border-box",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: 4,
                  color: "white",
                  height: 32,
                }}
              >
                <span className="uppercase">Valider mon compte</span>
              </Button>
            )}
            <div className="pb-5" />
          </div>
          {/* <div className="border-2 w-1/2">
            <AccountInformation />
          </div> */}
          {/* <AccountInformation /> */}
          {/* {reviews ? reviews.length > 0 ?
                    <div className='px-4 pt-4 mb-2 rounded-lg lg:w-1/3 shadow-account' style={{ height: `${tagHeight - 8}px` }}>
                        <div>
                            <h1 className='py-2 text-xl font-extrabold uppercase text-first'>mes avis</h1>
                            <div className='flex items-center gap-2 text-base font-bold text-first'>
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.89919 14.3704L9.43991 14.1267L8.97664 14.3627L4.32593 16.7316L5.24878 11.6523L5.34352 11.1309L4.96523 10.7597L1.26973 7.13397L6.43713 6.43683L6.95693 6.3667L7.19474 5.89921L9.55023 1.26869L11.8272 5.93343L12.0572 6.40471L12.5757 6.48344L17.7292 7.26599L13.9721 10.8314L13.5878 11.1961L13.6737 11.7189L14.5111 16.8172L9.89919 14.3704Z" stroke="#0054A3" strokeWidth="2" />
                                </svg>
                                {reviews?.length > 1 ? reviews?.length + " commentaires" : reviews?.length + " commentaire"}
                            </div>
                        </div>
                        <div className="flex flex-col pr-4 my-8 overflow-y-auto gap-y-2 scroll h-[80%]" >
                            {
                                reviews.map((review) => (<Reviews review={review} />))
                            }

                        </div>
                    </div>
                    :
                    <div>
                        <h2 className='text-lg font-bold text-center text-first lg:text-2xl'>Aucun teach’r n’a laissé de commentaires pour l’instant</h2>
                        <div className="py-8">
                            <img src={emptyComment} alt="" className='mx-auto' />
                        </div>
                    </div>
                    :
                    <Skeleton variant="rounded" className='lg:w-1/3' height={tagHeight} />
                } */}
        </div>
      </div>
    );
};

export default Profile;