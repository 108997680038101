import validator from 'validator';
import errorIcon from "../Assets/Images/error.png";

const validateEmail = (value) => {
  const email = value.replace(/\s/g, '')
  if (validator.isEmpty(email)) {
    return {
      isValid: false,
      message: 'Remplir ce champ',
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    }
  }
  if (!validator.isEmail(email)) {
    return {
      isValid: false,
      message: 'Mail invalide',
      icon:<img src={errorIcon} alt="" className="w-4 h-4" />
    }
  }else
  return {
    isValid: true,
    message: ''
  }
};

export default validateEmail