import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Functions from "../../../../../Helpers/Functions"

const Dashboard = (props: any) => {
    const [status, setStatus] = useState('')
    useEffect(() => {
        if (props.teachr) {
            if (props.teachr.businessType) {
                setStatus(props.teachr.businessType)
            }
        }
    }, [props])
    const navigate = useNavigate()
    return (
        <div className='flex flex-col px-4 py-8 rounded-lg lg:p-10 shadow-profile-isAe gap-y-10 md:px-8 '>
            <h3 className='text-base font-extrabold text-first lg:text-2xl'>Votre Tableau de bord :</h3>
            <div className=''>
                <h4 className='font-bold text-first lg:text-lg'>Vous êtes {Functions.renderBusinessType(status)} avec Teach’r, vous pouvez désormais faire <br />bénéficier dispositif du crédit d’impôt instantané à vos élèves ! 🥳</h4>
                <p className='text-sm font-semibold lg:text-[16px] text-normal'>Rejoignez dès maintenant notre nouvelle plateforme de déclaration de cours particulier.</p>
            </div>
            <Button className='px-20 mx-auto font-extrabold uppercase border border-solid rounded-full text-first border-first w-max ' onClick={() => navigate('/teachr/dashboard')}> Tableau de bord</Button>
        </div>
    );
};

export default Dashboard;