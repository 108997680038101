import { Button } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from './Layout';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Finish = (props: any) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isCopy, setIsCopy] = useState(false)
    dispatch({
        type: 'HIDE_LOADER'
    })
    return (
        <div className='w-full'>
            <Layout>
                <div>
                    <h1 className='pb-8 text-lg font-bold lg:text-2xl md:text-2xl text-first'>Félicitations 🥳</h1>
                    <div className="flex flex-col gap-8">
                        <p className='font-bold md:leading-7 md:text-xl text-first'>Votre questionnaire a bien été envoyé à votre client. <br /> Une fois validé, le statut de votre élève changera directement sur le tableau de bord.
                        </p>
                        <p className='font-bold md:text-xl text-first md:leading-7'>Afin de respecter les données confidentielles de vos clients, sans réponse de sa part d’ici 30 jours, nous serons dans l’obligation de supprimer votre demande d’ajout de cet élève. Vous devrez alors recommencer le processus.
                        </p>
                        <div className='flex flex-col gap-y-4 py-4'>
                            <p className='font-bold md:leading-7 md:text-xl text-first'>Un lien exclusif a été créé pour votre élève. Si cela vous convient, vous pouvez le copier et le partager avec lui.</p>
                            <div className={`${isCopy ? 'border-first' : 'border-[rgba(183,183,183,0.64)]'} p-4 rounded-md border flex items-center justify-between`}>
                                <p className={`${isCopy ? 'text-first' : 'text-[808080#]'} lg:text-lg text-sm`}>{props.link ? props.link : null}</p>
                                <CopyToClipboard text={props.link ? props.link : ''} onCopy={()=>setIsCopy(true)}>
                                    <span className='cursor-pointer'>
                                        <svg width="32" height="40" viewBox="0 0 32 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_701_3)">
                                                <path d="M9.99999 26C9.40703 25.9995 8.82752 25.8233 8.33466 25.4936C7.84179 25.1639 7.45768 24.6956 7.23084 24.1477C7.00399 23.5999 6.94459 22.9971 7.06013 22.4155C7.17566 21.8339 7.46096 21.2996 7.87999 20.88L8.87999 19.88C9.45111 19.3348 10.2104 19.0305 11 19.0305C11.7896 19.0305 12.5489 19.3348 13.12 19.88C13.6818 20.4425 13.9973 21.205 13.9973 22C13.9973 22.795 13.6818 23.5575 13.12 24.12L12.12 25.12C11.5579 25.6828 10.7954 25.9993 9.99999 26ZM11 21C10.8684 20.9993 10.7379 21.0245 10.6161 21.0743C10.4942 21.124 10.3834 21.1974 10.29 21.29L9.28999 22.29C9.10374 22.4774 8.99919 22.7309 8.99919 22.995C8.99919 23.2592 9.10374 23.5127 9.28999 23.7C9.47735 23.8863 9.7308 23.9908 9.99499 23.9908C10.2592 23.9908 10.5126 23.8863 10.7 23.7L11.7 22.7C11.837 22.5603 11.93 22.3834 11.9675 22.1912C12.0049 21.9991 11.9851 21.8002 11.9106 21.6192C11.8361 21.4382 11.7101 21.283 11.5482 21.173C11.3863 21.0629 11.1957 21.0028 11 21Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M15 21C14.407 20.9995 13.8275 20.8233 13.3347 20.4936C12.8418 20.1639 12.4577 19.6956 12.2308 19.1477C12.004 18.5999 11.9446 17.9971 12.0601 17.4155C12.1757 16.8339 12.461 16.2996 12.88 15.88L13.88 14.88C14.4511 14.3348 15.2104 14.0305 16 14.0305C16.7896 14.0305 17.5489 14.3348 18.12 14.88C18.6818 15.4425 18.9973 16.205 18.9973 17C18.9973 17.795 18.6818 18.5575 18.12 19.12L17.12 20.12C16.5579 20.6828 15.7954 20.9993 15 21ZM16 16C15.8684 15.9993 15.7379 16.0245 15.6161 16.0743C15.4942 16.124 15.3834 16.1974 15.29 16.29L14.29 17.29C14.1037 17.4774 13.9992 17.7309 13.9992 17.995C13.9992 18.2592 14.1037 18.5127 14.29 18.7C14.4773 18.8863 14.7308 18.9908 14.995 18.9908C15.2592 18.9908 15.5126 18.8863 15.7 18.7L16.7 17.7C16.837 17.5603 16.93 17.3834 16.9675 17.1912C17.0049 16.9991 16.9851 16.8002 16.9106 16.6192C16.8361 16.4382 16.7101 16.283 16.5482 16.173C16.3863 16.0629 16.1957 16.0028 16 16Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M12 22C11.8014 22.0012 11.6069 21.9432 11.4414 21.8333C11.2759 21.7235 11.1468 21.5669 11.0706 21.3835C10.9944 21.2001 10.9746 20.9981 11.0137 20.8033C11.0528 20.6086 11.149 20.4299 11.29 20.29L13.29 18.29C13.4813 18.1262 13.7274 18.0406 13.979 18.0503C14.2307 18.06 14.4695 18.1644 14.6475 18.3425C14.8256 18.5205 14.93 18.7593 14.9397 19.011C14.9494 19.2626 14.8638 19.5087 14.7 19.7L12.7 21.7C12.5154 21.8884 12.2638 21.9963 12 22Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M23 12H19C18.2043 12 17.4413 11.6839 16.8787 11.1213C16.3161 10.5587 16 9.79561 16 8.99996V4.99996C15.9988 4.80133 16.0569 4.60686 16.1667 4.44134C16.2765 4.27582 16.4331 4.14675 16.6165 4.07059C16.7999 3.99442 17.0019 3.97461 17.1967 4.01368C17.3914 4.05275 17.5701 4.14893 17.71 4.28996L23.71 10.29C23.851 10.4298 23.9472 10.6085 23.9863 10.8033C24.0253 10.998 24.0055 11.2 23.9294 11.3834C23.8532 11.5669 23.7241 11.7235 23.5586 11.8333C23.3931 11.9431 23.1986 12.0011 23 12ZM18 7.40996V8.99996C18 9.26517 18.1053 9.51953 18.2929 9.70706C18.4804 9.8946 18.7348 9.99996 19 9.99996H20.59L18 7.40996Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M21 32H5C4.20435 32 3.44129 31.6839 2.87868 31.1213C2.31607 30.5587 2 29.7957 2 29V7.00002C2 6.20437 2.31607 5.44131 2.87868 4.8787C3.44129 4.31609 4.20435 4.00002 5 4.00002H17C17.1316 3.99926 17.2621 4.02448 17.3839 4.07425C17.5057 4.12401 17.6166 4.19734 17.71 4.29002L23.71 10.29C23.8027 10.3835 23.876 10.4943 23.9258 10.6161C23.9755 10.7379 24.0008 10.8684 24 11V29C24 29.7957 23.6839 30.5587 23.1213 31.1213C22.5587 31.6839 21.7956 32 21 32ZM5 6.00002C4.73478 6.00002 4.48043 6.10537 4.29289 6.29291C4.10536 6.48045 4 6.7348 4 7.00002V29C4 29.2652 4.10536 29.5196 4.29289 29.7071C4.48043 29.8947 4.73478 30 5 30H21C21.2652 30 21.5196 29.8947 21.7071 29.7071C21.8946 29.5196 22 29.2652 22 29V11.41L16.59 6.00002H5Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M29 7.99996H25C24.2043 7.99996 23.4413 7.68389 22.8787 7.12128C22.3161 6.55867 22 5.79561 22 4.99996V0.999956C21.9988 0.801332 22.0569 0.606863 22.1667 0.441343C22.2765 0.275823 22.4331 0.146752 22.6165 0.0705855C22.7999 -0.00558127 23.0019 -0.0253928 23.1967 0.0136769C23.3914 0.0527466 23.5701 0.148927 23.71 0.289956L29.71 6.28996C29.851 6.42983 29.9472 6.60853 29.9863 6.80328C30.0253 6.99802 30.0055 7.19999 29.9294 7.38344C29.8532 7.56688 29.7241 7.72349 29.5586 7.83329C29.3931 7.94309 29.1986 8.00111 29 7.99996ZM24 3.40996V4.99996C24 5.26517 24.1053 5.51953 24.2929 5.70706C24.4804 5.8946 24.7348 5.99996 25 5.99996H26.59L24 3.40996Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                                <path d="M27 28H23C22.7348 28 22.4804 27.8947 22.2929 27.7071C22.1054 27.5196 22 27.2652 22 27C22 26.7348 22.1054 26.4804 22.2929 26.2929C22.4804 26.1054 22.7348 26 23 26H27C27.2652 26 27.5196 25.8947 27.7071 25.7071C27.8946 25.5196 28 25.2652 28 25V7.41002L22.59 2.00002H11C10.7348 2.00002 10.4804 2.10537 10.2929 2.29291C10.1054 2.48045 10 2.7348 10 3.00002V5.00002C10 5.26523 9.89464 5.51959 9.70711 5.70712C9.51957 5.89466 9.26522 6.00002 9 6.00002C8.73478 6.00002 8.48043 5.89466 8.29289 5.70712C8.10536 5.51959 8 5.26523 8 5.00002V3.00002C8 2.20437 8.31607 1.44131 8.87868 0.878696C9.44129 0.316087 10.2044 1.67143e-05 11 1.67143e-05H23C23.1316 -0.000744179 23.2621 0.0244809 23.3839 0.0742455C23.5057 0.12401 23.6166 0.197335 23.71 0.290017L29.71 6.29002C29.8027 6.38346 29.876 6.49427 29.9258 6.61611C29.9755 6.73795 30.0008 6.86841 30 7.00002V25C30 25.7957 29.6839 26.5587 29.1213 27.1213C28.5587 27.6839 27.7956 28 27 28Z" fill={isCopy ? '#0054A3' : '#808080'} />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_701_3">
                                                    <rect width="32" height="40" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </span>
                                </CopyToClipboard>

                            </div>
                        </div>
                        <div className="w-full pt-8">
                            <Button className='block w-full py-3 text-xs font-extrabold text-white rounded-full bg-first md:text-lg' onClick={() => navigate("/teachr/dashboard/student")}>SUIVRE LE STATUT DE LA DEMANDE</Button>
                        </div>
                    </div>
                </div>
            </Layout>
        </div>
    );
};

export default Finish;