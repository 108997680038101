 import { styled } from '@mui/material/styles';
import stepIcon from '../../Assets/Images/step_icon.svg'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    background: theme.palette.mode === 'light' ? '#92D3FF' : '#92D3FF',

  }
}));
function Stepper(props) {
  const formatStep = () => {
    return props.currentStep * 100 / props.step
  }
  return (
    <div className='px-5 py-5 lg:px-12'>
      <div className='relative'>
        <BorderLinearProgress sx={{
          overflow: 'visible',
          '& .MuiLinearProgress-bar':{
            transform: 'translateX(0) !important',
            width: `${formatStep()}%`,
            transition: 'width 1s'
          },
          '& .MuiLinearProgress-bar::after':{
            content:`url(${stepIcon})`,
            position: 'absolute',
            right: '-5px',
            top: '-29px'
          }
        }} variant="determinate" value={formatStep()}></BorderLinearProgress>
        {/* <div className={`absolute w-full -left-3 translate-x-[${formatStep()}%] -bottom-4`}>
          <img src={stepIcon}  alt="" />
        </div> */}
      </div>

      {/* <button onClick={() => nextStep()} className='flex items-center justify-center px-4 py-2 my-4 text-white no-underline uppercase rounded-full bg-second gap-x-3'>suivant</button>
            <button onClick={() => prevStep()} className='flex items-center justify-center px-4 py-2 my-4 text-white no-underline uppercase rounded-full bg-second gap-x-3'>précédent</button> */}

      <div className='pt-10'>
        {props.children[props.currentStep - 1]}
      </div>
    </div>
  )
}

export default Stepper