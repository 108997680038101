import hour from "../../../Assets/Images/hour.png";
import calendar from "../../../Assets/Images/calender.png";
import lieu from "../../../Assets/Images/lieu.png";
import pic from "../../../Assets/Images/pic.png";
import money from "../../../Assets/Images/money.png";
import toch from "../../../Assets/Images/toch.png";
import Update from "./Update";
import Delete from "./Delete";
import { Link } from "react-router-dom";

const CoursePrevuSubscription = () => {
  return (
    <div className="md:flex shrink-0 " >
      <div className="md:mt-[74px] mt-10 pr-6 ">
        <div className="rounded-lg w-full md:ml-0 ml-3 border-grey p-4 mb-20 shadow-res">
          <div className="">
            <p className="md:text-[20px]  text-dark_blue font-semibold">
              Prochain cours : 19 avril 2022
            </p>
          </div>
          <div className="flex gap-x-8">
            <div className="space-y- ">
              <div className="flex  space-x-4">
                <div>
                  <img src={pic} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg  text-dark_blue font-semibold">
                    Cours régulier de Français
                  </p>
                  <div className="text-base //md:-translate-y-6 //-translate-y-3 text-grey">
                    <span>Pour Louna</span>
                  </div>
                </div>
              </div>
              <div className="flex  space-x-4">
                <div>
                  <img src={hour} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg  text-dark_blue font-semibold">
                    À partir de 17h00
                  </p>
                  <div className="text-base //md:-translate-y-6 //-translate-y-3 text-grey">
                    <span>2h00/jour</span>
                  </div>
                </div>
              </div>
              <div className="flex  space-x-4">
                <div>
                  <img src={calendar} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg  text-dark_blue font-semibold">
                    Lundi, Mercredi, Dimanche
                  </p>
                  <div className="text-base //md:-translate-y-6 block //-translate-y-3 text-grey">
                    <span>À partir du jeudi 17 décembre</span> <br></br>
                    <Link to="/">Voir les cours passés</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y- ">
              <div className="flex  py-2.5 space-x-7">
                <div className="-mt-2">
                  <img src={lieu} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg  text-dark_blue font-semibold">
                  Rue d’Anthoine, Marseille
                  </p>
                </div>
              </div>
              <div className="flex   space-x-4">
                <div>
                  <img src={money} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg text-dark_blue font-semibold">
                    Prix par séance : 140.00€
                  </p>
                  <div className="text-base //md:-translate-y-6 //-translate-y-3 text-grey">
                    <span>Soit après crédit d’impôt : 70.00€</span>
                  </div>
                </div>
              </div>
              <div className="flex  space-x-4">
                <div>
                  <img src={toch} alt="" />
                </div>
                <div className="">
                  <p className="md:text-lg text-dark_blue font-semibold">
                    Prof : Léa, Teach’r Favoris
                  </p>
                  <div className="text-base //md:-translate-y-6 //-translate-y-3 text-grey">
                    <span>Voir Profil</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-around md:py-4 space-x-8 md:translate-y-0 translate-y-6 ">
            <div className=" w-full">
              <Update />
            </div>
            <div className=" w-full">
              <Delete />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoursePrevuSubscription;
