import { Button } from '@mui/material';
import React from 'react';

const Acount = (props: any) => {
    return (
        <div>
            <h2 className='text-base font-extrabold text-first lg:text-2xl'>Création de compte </h2>
            <div className="flex flex-col pt-4 gap-y-2">
                <p className='text-sm font-semibold text-first lg:text-lg lg:leading-[24.55px]'>Rendez-vous sur le site NOVA et créez votre compte en <a href="" className='font-bold underline text-first'>cliquant ici</a>. Après avoir créé votre compte, vous arriverez sur votre tableau de bord.
                    Cliquez sur <span className='font-bold'>"Mes demandes"</span> sur la gauche, puis sur <span className='font-bold'>"Créer une nouvelle demande.</span></p>
                <p className='text-sm text-first lg:text-lg lg:leading-[24.55px]'>Si vous êtes auto-entrepreneur pour un autre secteur d’activité que les cours particuliers, noubliez pas de changer votre code APE par celui-ci : <span className='font-bold'>8559B ou 8552Z</span>, dont l'activité principale est "Activités de soutien scolaire à domicile". Sans celui-ci, l’accès au crédit d’impôt risque de vous être refusé.</p>
            </div>
            <div className="absolute left-0 flex justify-end w-full px-8 pt-4">
                <Button onClick={() => props.getStep(2)} className='px-8 font-extrabold uppercase rounded-full text-first lg:text-xl'>SUIVANT</Button>
            </div>
        </div>
    );
};

export default Acount;