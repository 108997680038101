import validator from "validator";
import errorIcon from "../Assets/Images/error.png";

const validateSelects = (value) => {
  if (!value || !value?.fr_FR ) {
    return {
      isValid: false,
      message: "Sélectionnez une matière",
      icon: <img src={errorIcon} alt="" className="w-4 h-4 translate-y-1" />,
    };
  } else
    return {
      isValid: true,
      message: "",
      icon: "",
    };
};

export default validateSelects;
