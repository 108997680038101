import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddressComponent from '../../../../Components/utils/Address';
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { Address } from '../../../../Objects/models/Address';
import Teachr from '../../../../Objects/models/Teachr';
import { useDispatch, useSelector } from 'react-redux';
import ValidateSiret from '../../../../Validation/Siret';
import AddressRepository from '../../../../Repositories/AddressRepository';
import TeachrRepository from '../../../../Repositories/TeachrRepository';
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Fifth = (props: any) => {
    const dispatch = useDispatch()
    const [teachr, setTeachr] = useState<Teachr>()
    const [siret, setSiret] = useState('')
    const [address, setAddress] = useState<Address>()
    const generalTeachr = useSelector((state: any) => state.teachr)
    const [errorSiret, setErrorSiret] = useState(false)
    const [errorAddress, setErrorAddress] = useState(false)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (generalTeachr) {
            setTeachr(generalTeachr.teachr)
        }
    }, [generalTeachr])

    const getAddress = (value: string) => {
        geocodeByPlaceId(value).then((response) => {
            const addressObj = new Address(
                response[0].place_id,
                response[0].address_components[0].long_name,
                response[0].address_components[1].long_name,
                response[0].address_components[2].long_name,
                response[0].address_components[3].long_name,
                response[0].address_components[4].long_name,
                response[0].address_components[5].long_name,
                response[0].address_components[6].long_name,
                response[0].geometry.location.lat().toString(),
                '',
                response[0].formatted_address,
                "",
                true)
            addressObj.sessionToken = ''
            setAddress(addressObj)
            setErrorAddress(false)
        })
    }

    const validSiret = () => {
        let error = false
        if (!ValidateSiret(siret).valid) {
            return (error = false);
        }
        return true
    }

    const handleSubmit = () => {
        if (teachr) {
            setLoading(true)
            if (!teachr.address && !teachr.RCS) {
                if (address) {
                    setErrorAddress(false)
                    if (validSiret()) {
                        setErrorSiret(false)
                        AddressRepository.createAddress(address).then((response) => {
                            teachr.RCS = siret
                            teachr.address = response['@id']
                            if (teachr.proofIdentity) {
                                teachr.proofIdentity = teachr.proofIdentity['@id']
                            }
                            if (teachr.proofIdentity2) {
                                teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                            }
                            if (teachr.proofIsAE) {
                                teachr.proofIsAE = teachr.proofIsAE['@id']
                            }
                            if (teachr.proofSignSap) {
                                teachr.proofSignSap = teachr.proofSignSap['@id']
                            }
                            if (teachr && teachr.proofMandat) {
                                teachr.proofMandat = teachr.proofMandat['@id']
                            }
                            TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                                dispatch({
                                    type: "SET_TEACHR_INFO",
                                    payload: {
                                        teachr: reponseTeachr,
                                    },
                                });
                                props.nextStep(5)
                                setLoading(false)

                            }).catch((error) => {
                            console.warn(error)
                        setLoading(false)
                        })
                        }).catch((error) => {
                            console.warn(error)
                        setLoading(false)
                        })
                    } else {
                        setLoading(false)
                        setErrorSiret(true)
                    }
                } else {
                    setLoading(false)
                    setErrorAddress(true)
                }
            } else if (teachr.address) {
                if (validSiret()) {
                    setErrorSiret(false)
                    teachr.RCS = siret
                    if (teachr.proofIdentity) {
                        teachr.proofIdentity = teachr.proofIdentity['@id']
                    }
                    if (teachr.proofIdentity2) {
                        teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                    }
                    if (teachr.proofIsAE) {
                        teachr.proofIsAE = teachr.proofIsAE['@id']
                    }
                    if (teachr.proofSignSap) {
                        teachr.proofSignSap = teachr.proofSignSap['@id']
                    }
                    if (teachr && teachr.proofMandat) {
                        teachr.proofMandat = teachr.proofMandat['@id']
                    }
                    TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                        dispatch({
                            type: "SET_TEACHR_INFO",
                            payload: {
                                teachr: reponseTeachr,
                            },
                        });
                        props.nextStep(5)
                        setLoading(false)
                    }).catch((error) => {
                            console.warn(error)
                        setLoading(false)
                        })
                } else {
                    setLoading(false)
                    setErrorSiret(true)
                }
            } else {
                if (address) {
                    setErrorAddress(false)
                    AddressRepository.createAddress(address).then((response) => {
                        teachr.address = response['@id']
                        if (teachr.proofIdentity) {
                            teachr.proofIdentity = teachr.proofIdentity['@id']
                        }
                        if (teachr.proofIdentity2) {
                            teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                        }
                        if (teachr.proofIsAE) {
                            teachr.proofIsAE = teachr.proofIsAE['@id']
                        }
                        if (teachr.proofSignSap) {
                            teachr.proofSignSap = teachr.proofSignSap['@id']
                        }
                        if (teachr && teachr.proofMandat) {
                            teachr.proofMandat = teachr.proofMandat['@id']
                        }
                        TeachrRepository.updateTeachr(teachr).then((reponseTeachr) => {
                            dispatch({
                                type: "SET_TEACHR_INFO",
                                payload: {
                                    teachr: reponseTeachr,
                                },
                            });
                            props.nextStep(5)
                            setLoading(false)
                        }).catch((error) => {
                            console.warn(error)
                        setLoading(false)
                        })
                    }).catch  ((error) => {
                            console.warn(error)
                        setLoading(false)
                        })
                } else {
                    setLoading(false)
                    setErrorAddress(true)
                }
            }

        }
    }
    if (teachr) {
        return (
            <div className='flex flex-col lg:border border-[rgba(146,211,255,0.54)] lg:shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-4'>
                <h4 className='text-lg font-extrabold lg:text-[22px] text-first'> Conditions de service Teach’r</h4>
                <div className="flex flex-col gap-y-8">
                    {
                        teachr.address ? null :
                            <div>
                                {errorAddress ? <p className='text-third'>Merci de renseigner une adresse</p> : null}
                                <div className="flex rounded-xl border border-[rgba(183,183,183,0.64)] p-3">
                                    <div className="w-full">
                                        <AddressComponent getPlaceId={getAddress} placeholder="Adresse postale :" />
                                    </div>
                                    <span className='cursor-pointer'><svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M26.0844 24.5899L15.984 14.6847C17.1924 13.2054 17.9209 11.3318 17.9209 9.29262C17.9209 4.5262 13.9665 0.648682 9.10635 0.648682C4.24555 0.648682 0.291504 4.5262 0.291504 9.29262C0.291504 14.0587 4.24555 17.9366 9.10635 17.9366C11.1124 17.9366 12.9578 17.2684 14.4408 16.1575L24.562 26.0828C24.7722 26.289 25.0478 26.392 25.3231 26.392C25.5987 26.392 25.8743 26.289 26.0844 26.0828C26.5051 25.6703 26.5051 25.0021 26.0844 24.5899ZM2.44476 9.29262C2.44476 5.69052 5.43316 2.76027 9.10635 2.76027C12.7792 2.76027 15.7673 5.69052 15.7673 9.29262C15.7673 11.0695 15.0382 12.6807 13.8608 13.8596C13.8149 13.8918 13.7691 13.9253 13.7281 13.966C13.7024 13.9909 13.6832 14.0192 13.6609 14.0463C12.4686 15.1458 10.8684 15.8253 9.10635 15.8253C5.43347 15.825 2.44476 12.8947 2.44476 9.29262Z" fill="#4D4D4D" />
                                    </svg>
                                    </span>
                                </div>
                            </div>
                    }
                    {
                        teachr.RCS ? null :
                            <div>
                                {errorSiret ? <p className='text-third'>{ValidateSiret(siret).message}</p> : null}
                                <input onChange={(e) => setSiret(e.target.value)} type="text" className='w-full rounded-xl border border-[rgba(183,183,183,0.64)] p-3' placeholder='SIRET : 91234567800016' />
                                {(teachr?.proofIsAE && errorSiret) && <Stack sx={{ width: '100%', marginTop: 2}} spacing={2}>
                                    <Alert variant="outlined" severity="info" sx={{fontFamily: 'nunito'}}>
                                        Vous nous avez transmis une procuration en vue de créer votre auto-entreprise : vous n'avez donc pas encore de numéro de SIRET. Veuillez attendre que l'un de nos conseiller vous recontacte pour vous transmettre ce numéro.
                                    </Alert>
                                </Stack>
                                }
                            </div>
                    }
                </div>
                <div className="flex justify-between pt-8 flex-col lg:flex-row gap-y-4">
                    <Button disabled={loading} onClick={() => props.nextStep(3)} className='text-[#B7B7B7] lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 disabled:opacity-30 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
                    <Button disabled={loading} onClick={handleSubmit} className='text-white bg-radio font-extrabold rounded-full py-2 px-8 disabled:opacity-30 w-full lg:w-max'>SUIVANT </Button>

                </div>
            </div>
        );
    }
    return (<div></div>)

};

export default Fifth;