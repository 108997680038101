import picture from "../../../../Assets/Images/picture.png";
// import star from "../../../../Assets/Images/groupstar.png";
import toch from "../../../../Assets/Images/toch.png";
import money from "../../../../Assets/Images/money.png";
import { useNavigate } from "react-router-dom";
// import { SubscriptionCourseProposal } from "../../../../Objects/models/SubscriptionCourseProposal";
import { useEffect, useState } from "react";
import Teachr_Repository from "../../../../Repositories/Teachr_Repository";
// import Teachr from "../../../../Objects/models/Teachr";
// import TeachrHours from "../../../../Objects/models/TeachrHours";
import Functions from "../../../../Helpers/Functions";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
// import { Button } from "react-bootstrap";
// import { any } from "prop-types";
import { PackProposal } from "../../../../Objects/models/PackProposal";
import Skeleton from "@mui/material/Skeleton";
import { CoursePack } from "../../../../Objects/models/CoursePack";
// import { CoursePack } from "../../../../Objects/models/CoursePack";

function ResponseTuilesCoursePack(props: { proposal: PackProposal, course: CoursePack }) {
  const proposal = props.proposal
  const course = props.course
  const [teachr, setTeachr] = useState<any>();
  const [teachrHours, setTeachrHours] = useState<any>();
  const navigate = useNavigate();
  const handleChange = () => {
    navigate("/user/profileteachrpackcourse", {
      state: { teachr, teachrHours, proposal, course },
    });
  };






  useEffect(() => {
    (async () => {
      if (proposal.teachr_id) {
        const teachrData = await Teachr_Repository.fetchTeachrByIdClient(
          proposal.teachr_id
        );
        setTeachr(teachrData);

        // const teachrHours = await Teachr_Repository.getTeachrHours(
        //   proposal.teachr_id
        // );
        const teachrHours = await Teachr_Repository.getTeachrHours(
          proposal.teachr_id
        );

        setTeachrHours(teachrHours);
      }
    })();
  }, [proposal]);



  return (
    <div
      onClick={() => handleChange()}
      className="p-4 mt-12 ml-1 rounded-lg shrink-0 md:mt-2 md:w-80 md:ml-0 bg-bg-color-tuile border-grey shadow-res"
    >
      <div className="mx-auto rounded-full md:-translate-y-16 -translate-y-14 md:h-20 md:w-20 w-14">
        <img
          src={teachr?.profilePictureURL ? teachr?.profilePictureURL : picture}
          alt=""
          className="rounded-full md:h-20 md:w-20 h-14 w-14"
        />
      </div>
      <div className="mx-auto -mt-12 md:-mt-14">
        <div className="w-1/2 mx-auto //md:pl-2">
          {teachr?.firstName ? (
            <p className="text-sm font-bold text-center lg:text-lg md:text-base text-dark_blue">
              {teachr?.firstName}
            </p>
          ) : (
            <Skeleton width="50%" />
            // 'pour'
          )}
        </div>
        <div className="w-1/2 mx-auto">
          {teachr?.averageNote === 0 ? "" :
            teachr?.averageNote ? (
              <Rating
                name="half-rating-read"
                defaultValue={teachr?.averageNote}
                precision={0.1}
                readOnly
              />
            ) : (
              <Skeleton width="50%" />
            )}
        </div>
        <div className="mb-2">
          {teachrHours?.totalHours ? (
            <p className="text-sm text-center">
              {Functions.renderTeachrHours(teachrHours?.totalHours)}
            </p>
          ) : (
            <div className="w-1/2 mx-auto ">
              <Skeleton width="100%" />
            </div>
            // 'azert'
          )}
        </div>
      </div>
      <div className={proposal.description ? "" : " translate-y-4 "}>
        <div className="flex space-x-4 //mt-2 //translate-y-4">
          <div className="w-1/4 pl-2 ">
            <img src={toch} alt="" className="w-8 md:w-12 " />
          </div>
          <div className="w-3/4 ">
            {teachr?.whereStudies ? (
              <p className="md:text-[16px] md:texte-base text-sm  text-dark_blue font-semibold">
                {teachr?.whereStudies}
              </p>
            ) : (
              <Skeleton width="50%" />
            )}
          </div>
        </div>
        <div className="flex space-x-4 ">
          <div className="w-1/4 pt-4 pl-3 ">
            <img src={money} alt="" className="w-8 md:w-10 " />
          </div>
          <div className="w-3/4 ">
            {proposal.teachrPrice ? props.course.isRemote ? "" : (
              <p className="text-sm font-semibold md:text-base text-dark_blue">
                Prix par heure après crédit d’impôt :{" "}
                {(Functions.renderPackProposalPrice(parseFloat(proposal.teachrPrice), course.durationPerDay, course.dates.length, proposal.fees! / 100, proposal.URSSAFPrice! / 100).hour / 2).toFixed(2) +
                  "€"}

              </p>
            ) : (
              <Skeleton width="100%" />
            )}
            <div className="text-sm md:text-base text-grey">
              {proposal.teachrPrice ? (
                <span>
                  {/* Soit après crédit d’impôt :{" "} */}
                  <span>
                    Prix par heure {props.course.isRemote ? " " : "avant crédit d’impôt "}:{" "}
                    {(Functions.renderPackProposalPrice(parseFloat(proposal.teachrPrice), course.durationPerDay, course.dates.length, proposal.fees! / 100, proposal.URSSAFPrice! / 100).hour).toFixed(2) +
                  "€"}
                  </span>
                </span>
              ) : (
                <Skeleton width="100%" />
              )}
            </div>
          </div>
        </div>
      </div>
      {proposal.description ? (
        <div className="flex mt-4 text-sm lg:text-base">
          <div className="w-full -mt-2 space-y-2">
            <div>
              <p className="text-dark_blue "> Message de candidature : </p>
            </div>
            {/* <Skeleton width="50%" />  */}
            <div className=" w-[100%] text-grey h-20 pt-1 whitespace-pre-wrap overflow-hidden ">
              {proposal.description ? (
                <p className="w-[100%]">{proposal.description}</p>
              ) : (
                <Skeleton width="50%" />
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex mt-4 text-sm lg:text-base">
          <div className="w-full -mt-2 space-y-2">
            <div>
              <p className="text-dark_blue "> Description : </p>
            </div>
            {/* <Skeleton width="50%" />  */}
            <div className=" w-[100%] text-grey h-20 pt-1 whitespace-pre-wrap overflow-hidden ">
              {teachr?.description ? (
                <p className="w-[100%]">{teachr?.description}</p>
              ) : (
                <Skeleton width="50%" />
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={"flex -translate-y-2 justify-around mb-2"}
      >
        <div>
          <button
            onClick={() => handleChange()}
            className="border-[1px] p-1 md:text-[16px] translate-y-4 text-sm text-btn-color-tuile px-4 border-btn-color-tuile font-bold active:text-white active:bg-btn-color-tuile active:border-btn-color-tuile  rounded-full"
          >
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
}

export default ResponseTuilesCoursePack;
