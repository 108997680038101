
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import loader from '../../Assets/Icons/loader-bleu.gif'
const Loader = () => {
  const generalLoader = useSelector((state: any) => state.loader)
  const [type, setType] = useState("")
  useEffect(() => {
    if (generalLoader.type) {
      setType(generalLoader.type)
    } else {
      setType("")
    }
  }, [generalLoader])
  const displayLoaderScreen = (type: string) => {
    if (type !== "") {
      switch (type) {
        case "dashboard-aici":
          return (
            <div className='fixed z-50 flex items-center justify-center w-screen h-screen overflow-hidden bg-white'>
              <div className='w-1/3 lg:w-max'>
                <img src={loader} alt="chargement" />
                <p className='py-8 text-center text-first'>Chargement ...</p>
              </div>
            </div>
          )

        default:
          return
      }
    }
    return
  }
  return (
    <div>
      {displayLoaderScreen(type)}
    </div>
  );
};

export default Loader;