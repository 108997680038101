import { Button, FormControlLabel, RadioGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import RadioButton from "../../../../Components/utils/RadioButton";
import { validatePhoneNumber } from "../../../../Validation/Phone";
import Functions from "../../../../Helpers/Functions";
import { useDispatch, useSelector } from "react-redux";

const First = (props: any) => {
  const dispatch = useDispatch();
  const generalProcuration = useSelector((state: any) => state.procurationAe);
  const [gender, setGender] = useState(0);
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [error, setError] = useState({
    phone: false,
    country: false,
    nationality: false,
  });
  useEffect(() => {
    if (generalProcuration && generalProcuration.second) {
      if (generalProcuration.second.first.gender) {
        setGender(generalProcuration.second.first.gender);
      }
      if (generalProcuration.second.first.phone) {
        setPhone(generalProcuration.second.first.phone);
      }
      if (generalProcuration.second.first.country) {
        setCountry(generalProcuration.second.first.country);
      }
      if (generalProcuration.second.first.nationality) {
        setNationality(generalProcuration.second.first.nationality);
      }
    }
  }, [generalProcuration]);
  const validate = () => {
    let error_phone = true;
    let error_country = true;
    let error_nationality = true;

    if (validatePhoneNumber(Functions.formatPhoneNumber(phone)) !== "") {
      error_phone = true;
    } else {
      error_phone = false;
    }
    if (country === "") {
      error_country = true;
    } else {
      error_country = false;
    }
    if (nationality === "") {
      error_nationality = true;
    } else {
      error_nationality = false;
    }
    setError({
      phone: error_phone,
      country: error_country,
      nationality: error_nationality,
    });
    if (!error_phone && !error_country && !error_nationality) {
      return true;
    }
    return false;
  };

  const handleNext = () => {
    if (gender >= 0 && validate()) {
      if (generalProcuration.second) {
        dispatch({
          type: "SET_AE_INFO",
          payload: {
            step: 2,
            first: generalProcuration.first,
            second: {
              first: {
                gender: gender,
                phone: phone,
                country: country,
                nationality: nationality,
              },
              second: generalProcuration.second.second!,
              third: generalProcuration.second.third!,
              fourth: generalProcuration.second.fourth!,
              fifth: generalProcuration.second.fifth!,
              sixth: generalProcuration.second.sixth!,
              seventh: generalProcuration.second.seventh!,
              eighth: generalProcuration.second.eighth!,
              ninth: generalProcuration.second.ninth!,
              tenth: generalProcuration.second.tenth!,
              eleventh: generalProcuration.second.eleventh!,
            },
          },
        });
      } else {
        dispatch({
          type: "SET_AE_INFO",
          payload: {
            step: 2,
            first: generalProcuration.first,
            second: {
              first: {
                gender: gender,
                phone: phone,
                country: country,
                nationality: nationality,
              },
            },
          },
        });
      }
      //  props.nextStep(2);
      props.setCurrentStep(2);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(parseInt((event.target as HTMLInputElement).value));
  };

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex gap-4 items-end flex-col lg:flex-row">
        <div className="w-full">
          {error.country ? (
            <p className="text-third pb-1">Veuillez renseigner un pays</p>
          ) : null}
          <div className="border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full">
            <label htmlFor="" className="block text-base">
              Pays de naissance
            </label>
            <input
              type="text"
              defaultValue={country}
              placeholder="France"
              onChange={(e) => setCountry(e.target.value)}
              className="w-full"
            />
          </div>
        </div>

        <div className="w-full">
          {error.phone ? (
            <p className="text-third pb-1">
              Veuillez renseigner un numéro de téléphone
            </p>
          ) : null}
          <div className="border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full">
            <label htmlFor="" className="block text-base">
              Numéro de téléphone{" "}
            </label>
            <input
              type="text"
              defaultValue={phone}
              placeholder="0X XX XX XX"
              onChange={(e) => setPhone(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        {error.nationality ? (
          <p className="text-third pb-1">
            Veuillez renseigner votre nationalité
          </p>
        ) : null}
        <div className="border rounded-[9.3px] border-[rgba(77,77,77,0.29)] py-2 px-5 bg-white w-full">
          <label htmlFor="" className="block text-base">
            Nationalité
          </label>
          <input
            type="text"
            defaultValue={nationality}
            placeholder="Française"
            onChange={(e) => setNationality(e.target.value)}
            className="w-full"
          />
        </div>
      </div>
      <div className="flex  lg:items-center lg:flex-row flex-col gap-x-10">
        <label
          className="lg:text-xl font-semibold text-normal text-base"
          htmlFor=""
        >
          Genre :
        </label>
        <RadioGroup
          row
          value={gender}
          defaultValue={
            generalProcuration &&
            generalProcuration.second &&
            generalProcuration.second.first
              ? generalProcuration.second.first.gender
              : null
          }
          name="customized-radios"
          onChange={handleChange}
        >
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#4D4D4D",
                fontFamily: "Nunito",
                fontSize: "16px",
              },
            }}
            value="0"
            control={<RadioButton border="1px solid #0054A3" bg="#0054A3" />}
            label="Femme"
          />
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#4D4D4D",
                fontFamily: "Nunito",
                fontSize: "16px",
              },
            }}
            value="1"
            control={<RadioButton border="1px solid #0054A3" bg="#0054A3" />}
            label="Homme"
          />
          {/* <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="2" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non précisé" /> */}
        </RadioGroup>
      </div>
      <Button
        disabled={props.loading}
        onClick={handleNext}
        className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
        style={{
          background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
        }}
      >
        SUIVANT
      </Button>
    </div>
  );
};

export default First;
