import React, { useState } from 'react';
import { Avatar, Tooltip } from '@mui/material';
import moment from 'moment';
import { capitalizeFirstLetter, stepper } from "../../UtilsDashboard/FunctionsDashbord";
import { statusTpdEnum } from '../../../../../../Enum/Enum';
import CopyToClipboard from 'react-copy-to-clipboard';
import config from '../../../../../../config';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface statusInterface {
  valid: boolean;
  status: boolean;
}

const CompChild = (props: any) => {
  const { child } = props
  const isTrue = true;
  const isTeachrContact = child?.is_teachr_contact;
  const pricePerHour = child?.price_per_hour;
  const feesPerHour = child?.fees_per_hour;
  const fullName = `${capitalizeFirstLetter(!child?.studentName ? child?.mail : child?.studentName)} ${(child?.name ? child?.name?.toUpperCase() : "")}`;
  const initFullName = `${(!child?.studentName ? child?.mail : child?.studentName).charAt(0).toUpperCase()}${(child?.name ? child?.name[0]?.toUpperCase() : "")}`;
  const firstCondition = !isTeachrContact && (!pricePerHour || !feesPerHour);
  const statusTDP: statusInterface = {
    valid:
      child?.etatTdp === statusTpdEnum.VALIDE.etat &&
      child?.codeTdp === statusTpdEnum.VALIDE.status,
    status: child?.status,
  };
  const mode = config.debugMode;

  const [isCopy, setIsCopy] = useState(false);


  return (
    <tr className="flex w-full items-center group/item hover:bg-slate-100 hover:rounded-xl ">
      <td className="py-2 md:w-2/5 w-2/4 flex items-center gap-x-2 truncate">
        <CopyToClipboard
          text={
            `https://app.teachr.fr/client/complete-infos/${child?.key}`
              ? `https://${mode ? "staging" : "app"
              }.teachr.fr/client/complete-infos/${child?.key}`
              : ""
          }
          onCopy={() => setIsCopy(true)}
        >
          <Tooltip
            title={
              isCopy
                ? "Copié"
                : `Cliquez sur ce lien pour copier et envoyer ce formulaire à votre élève : https://${mode ? "staging" : "app"
                }.teachr.fr/client/complete-infos/${child?.key}`
            }
          >
            {/* <ContentCopyIcon sx={{ color: "#4d4d4d" }} /> */}
            <Avatar
              sx={{
                bgcolor: "#c7e4ff",
                color: "#0054A3",
                border: "2px solid #0054A3",
              }}
              className="hidden md:flex w-10 h-10 font-nunito font-bold cursor-copy"
            >
              {initFullName}
            </Avatar>
          </Tooltip>
        </CopyToClipboard>
        <div className="ps-3">
          <div
            className={`${"text-[#0054A3]"} md:text-base l/g:text-lg text-sm font-semibold`}
          >
            {fullName}
          </div>
          <p
            className={`${"text-[#0054A3]"} font-normal md:text-sm text-xs cursor-text text-gray-500 `}
          >
            {child?.mail}
          </p>
        </div>
      </td>
      <td className="py-2 md:w-1/5 w-1/4 md:px-3 md:text-base l/g:text-lg text-sm">
        {child.createdAt?.date !== null
          ? moment(child.createdAt?.date).format("DD/MM/YYYY")
          : moment().format("DD/MM/YYYY")}
      </td>
      <td className=" py-2 md:w-2/5 w-1/4 md:px-2">{stepper(statusTDP)}</td>
    </tr>
  );
};

export default CompChild;