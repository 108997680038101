import React from 'react';
import aeEuro from '../../../../Assets/Images/ae-euro.svg'
import aeEuroPlus from '../../../../Assets/Images/ae-euro-plus.svg'
const Second = () => {
    return (
        <div className='flex flex-col gap-y-8 '>
            <h3 className='p-2 text-lg font-bold bg-white lg:text-[22px] text-first'>2. Indispensable pour profiter de l’avance immédiate</h3>
            <div className='flex flex-col gap-y-4'>
                <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8'>
                    <div>
                        <img src={aeEuro} alt="" />
                    </div>
                    <div className='text-sm text-first lg:text-lg'>
                        <p>
                            En Juin 2022, l’État a créé l’avance immédiate : Le parent paie alors immédiatement 50% de ses cours particuliers au moment du paiement. Plus besoin d’attendre l’année suivante.
                        </p>
                    </div>
                </div>
                <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8'>
                    <div>
                        <img src={aeEuroPlus} alt="" />
                    </div>
                    <div className='text-sm text-first lg:text-lg'>
                        <p>
                            Concrètement vous allez pouvoir fixer le prix de cours que vous souhaitez tout en sachant que le parent de votre élève ne payera que la moitié. Par exemple si vous décidez de proposer un cours à 40€/h c’est seulement 20€ que le parent devra verser à la plateforme.
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-col gap-y-4'>
                <h3 className='text-base font-bold lg:text-xl text-first'>Les impératifs :</h3>
                <div className="flex items-center gap-x-4">
                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 0.5C6.49745 0.5 0 6.99745 0 15C0 23.0025 6.49745 29.5 14.5 29.5C22.5025 29.5 29 23.0025 29 15C29 6.99745 22.5025 0.5 14.5 0.5ZM14.5 2.43333C21.4358 2.43333 27.0667 8.06417 27.0667 15C27.0667 21.9358 21.4358 27.5667 14.5 27.5667C7.56417 27.5667 1.93333 21.9358 1.93333 15C1.93333 8.06417 7.56417 2.43333 14.5 2.43333ZM13.1307 18.6767L19.3836 12.4238C19.7606 12.0463 20.373 12.0463 20.7505 12.4238C21.1279 12.8008 21.1279 13.4137 20.7505 13.7907L13.8141 20.727C13.4367 21.1045 12.8248 21.1045 12.4473 20.727L7.9837 16.2639C7.6067 15.8869 7.6067 15.274 7.9837 14.897C8.36118 14.5196 8.97357 14.5196 9.35105 14.897L13.1307 18.6767Z" fill="#0054A3" />
                    </svg>
                    <p className='text-base font-medium text-first lg:text-xl'>Disposer d’un statut juridique (auto entrepreneur, SARL, SAS ...). </p>
                </div>
                <div className="flex items-center gap-x-4">
                    <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 0.5C6.49745 0.5 0 6.99745 0 15C0 23.0025 6.49745 29.5 14.5 29.5C22.5025 29.5 29 23.0025 29 15C29 6.99745 22.5025 0.5 14.5 0.5ZM14.5 2.43333C21.4358 2.43333 27.0667 8.06417 27.0667 15C27.0667 21.9358 21.4358 27.5667 14.5 27.5667C7.56417 27.5667 1.93333 21.9358 1.93333 15C1.93333 8.06417 7.56417 2.43333 14.5 2.43333ZM13.1307 18.6767L19.3836 12.4238C19.7606 12.0463 20.373 12.0463 20.7505 12.4238C21.1279 12.8008 21.1279 13.4137 20.7505 13.7907L13.8141 20.727C13.4367 21.1045 12.8248 21.1045 12.4473 20.727L7.9837 16.2639C7.6067 15.8869 7.6067 15.274 7.9837 14.897C8.36118 14.5196 8.97357 14.5196 9.35105 14.897L13.1307 18.6767Z" fill="#0054A3" />
                    </svg>
                    <p className='text-base font-medium text-first lg:text-xl'>Disposer d’un arrêté préfectoral agrée service à la personne </p>
                </div>
            </div>
        </div>
    );
};

export default Second;