import React from "react";
import { useState } from "react";
import ComponentPoncual from "./Component/ComponentPoncual";
import UpdatePonctuelChoice from "../EditPunctual/EditPunctualChoice";
import UpdateConfirme from "../EditPunctual/EditConfirme";
import { Button } from "@mui/material";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutlineTwoTone";
import DeleteIcon from "@mui/icons-material/DeleteTwoTone";
import StepEnd from "../../../StepDeclare/StepEnd";
import { LightTooltipNoError } from "../../../UtilsDashboard/FunctionsDashbord"


const CoursePonctual = () => {
    const [currentStep, setCurrentStep] = useState<any>(1);
    const [load, setLoad] = useState(false)
    const [isHere, setIsHere] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    const changeComponent = (comp: number) => {
        switch (comp) {
            case 1: return <ComponentPoncual handleLoad={handleLoad} load={load} isHere={isHere} isEdit={isEdit} isDelete={isDelete} handleEdit={handleEdit} handleLockDelete={handleLockDelete} />;
            case 2: return <UpdatePonctuelChoice nextStep={handleBack} backStep={handleBack} />;
            case 3: return <UpdateConfirme backStep={handleBack} />;
            case 4: return <StepEnd handleBack={handleBack} />;
            default: return <ComponentPoncual />;
        }
    };
    const handleLoad = (val: boolean) => {
        setLoad(val)
    }
    const handleBack = (val: number) => {
        setCurrentStep(val)
    }
    const handleLock = () => {
        handleBack(2)
        setIsHere(false)
        setIsEdit(false)
    }
    const handleLockDelete = () => {
        setIsHere(false)
        setIsDelete(false)
    }
    const handleEditTrue = () => {
        if (isEdit && isHere) {
            setIsHere(false)
            setIsDelete(false)
            setIsEdit(false)
        } else {
            setIsHere(true)
            setIsDelete(false)
            setIsEdit(true)
        }
    }
    const handleDeleteTrue = () => {
        if (isDelete && isHere) {
            setIsHere(false)
            setIsEdit(false)
            setIsDelete(false)
        } else {
            setIsHere(true)
            setIsEdit(false)
            setIsDelete(true)

        }
    }
    const handleEdit = () => {
        handleLoad(true)
        handleLock()
    }

    const showButton = () => {
        return (
            <div className="flex absolute /lg:right-4 right-0  ">
                <div className={"cursor-pointer "} >
                    <LightTooltipNoError title={"Modifier"}>
                        <Button onClick={() => handleEditTrue()}>
                            {
                                isEdit || currentStep === 2 || currentStep === 3 ?
                                    <ModeEditOutlineIcon sx={{ color: "white", width: "50px", height: "40px", border: 2, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} />
                                    :
                                    <ModeEditOutlineIcon sx={{ color: "#0054A3", width: "50px", height: "40px", border: 2, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                            }
                        </Button>
                    </LightTooltipNoError>
                </div>
                <div className="cursor-pointer ">
                    <LightTooltipNoError title={"Supprimer"}>
                        <Button disabled={isEdit && currentStep === 2} onClick={() => handleDeleteTrue()}>
                            {
                                isDelete ?
                                    <DeleteIcon sx={{ color: "white", width: "50px", height: "40px", border: 2, borderRadius: 2, bgcolor: '#0054A3', boxShadow: 3 }} /> :
                                    <DeleteIcon sx={{ color: "#0054A3", width: "50px", height: "40px", border: 2, borderRadius: 2, bgcolor: 'white', boxShadow: 3 }} />
                            }
                        </Button>
                    </LightTooltipNoError>
                </div>
            </div>
        )
    }


    return (
        <>
            <div className="h-14 relative">
                {showButton()}
            </div>
            <div className="h-[100%]">
                {changeComponent(currentStep)}
            </div>
        </>
    );
};

export default CoursePonctual;
