import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppPropsInterface from "../Objects/interfaces/AppPropsInterface";
import FormLayout from "../Components/NewCourse/FormLayout";
import SignUpClient from "../Screens/Client/Auth/SignUp";
import Welcome from "../Screens/Client/Auth/Welcome";
import LastStep from "../Components/NewCourse/Forms/LastStep";
import LoginClient from "../Screens/Client/Auth/Login";
import Register from "../Screens/Teacher/Auth/Register/Index";
import Login from "../Screens/Teacher/Auth/Login";
import CourseIndex from "../Screens/Teacher/Auth/Register";
import ComingSoon from "../Screens/Teacher/ComingSoon";
import DahboardIndex from "../Screens/Teacher/Dashboard/Index";
import Erreur from "../Components/Pages/Erreur404";
import ComingSoons from "../Screens/Client/ComingSoon";
import TabPanel from "../Screens/Client/Courses/TabPanel";
import ProfileTeacher from "../Screens/Client/Courses/ProfileTeachrForDemand/ProfileTeacher";
import ProfileTeacherPunctualCourse from "../Screens/Client/Courses/ProfileTeachrForDemand/ProfileTeachrPunctual";
import CompleteProfile from "../Screens/Client/Auth/CompleteProfile";
import ProfileTeacherCoursePack from "../Screens/Client/Courses/ProfileTeachrForDemand/PorfileTeachrCoursePack";
import CourseSelect from "../Screens/Teacher/Dashboard/Course/details/Index";
import ProfileIndex from "../Screens/Teacher/Dashboard/Account/Index";
import Confirm from "../Screens/Teacher/Dashboard/Course/details/Confirm";
import CandidacyIndex from "../Screens/Teacher/Dashboard/Candidacy/Index";
import StudentIndex from "../Screens/Teacher/Dashboard/Candidacy/Child/Index";
import ConfirmationStep from "../Screens/Client/Paiement/StepPayment/ConfirmationStep";
import DemandeCourse from "../Screens/Client/Paiement/DemandeCourse";
import ProfileTeacherPunctualActive from "../Screens/Client/Courses/ProfileTeachrForActive/ProfileTeachrPunctualActive";
import ProfileTeacherCoursePackActive from "../Screens/Client/Courses/ProfileTeachrForActive/ProfileTeachrCoursePackActive";
import ProfileTeachrSubscriptionCourseActive from "../Screens/Client/Courses/ProfileTeachrForActive/ProfileTeachrSubscriptionCourseActive";
import TimerClient from "../Components/NewCourse/TimerClient";
import DashboardIndex from "../Screens/Teacher/Admin/Setup/Index"
import AiciHome from "../Screens/Teacher/Admin/Index"
import Index from "../Screens/Client/Aici/Index";
import { AddStudentPage } from "../Screens/Teacher/Admin/Dashboard/AddStudentPage";
import DashboardIndexCourse from "../Screens/Teacher/Admin/Dashboard/Index"
import IndexCourseType from "../Screens/Teacher/Admin/Dashboard/Courses";
import DeclareCourse from "../Screens/Teacher/Admin/Dashboard/DeclareCourse";
import PresentationIndex from "../Screens/Teacher/Admin/Presentation/Index";
import Home from "../Screens/Teacher/Guide/Home";
import MonaBanq from "../Screens/MonaBanq";
import NovaGuide from "../Screens/Teacher/Dashboard/Account/StatusAE/Nova/tuto/Index";
import Simulateur from '../Screens/Teacher/AfterVideo/Index'
import SimulateurV2 from '../Screens/Teacher/Simulateur/Index'
import SimulateurV2Simulate from '../Screens/Teacher/Simulateur/simulate/Index'
import SimulateurV2Fonctionnement from '../Screens/Teacher/Simulateur/Fonction/Index'
import MeetIndex from '../Screens/Teacher/AfterVideo/Meet/Index'
import ProcurationIndex from '../Screens/Teacher/Procuration/Index'
import ProcurationSapIndex from '../Screens/Teacher/Procuration/Sap/index'
import CompleteProfileIndex from "../Screens/Teacher/CompleteProfile/Index";
import ClientValidate from "../Screens/Teacher/Admin/Dashboard/AddStudent/ClientValidate";
import ExternalLogin from "../Screens/Teacher/Auth/ExternalLogin";
import TeachrHomePage from "../Components/Pages/TeachrHomePage";
import AiciPage from "../Components/Pages/AiciPage";
import CesuPageAds from "../Components/Pages/CesuPageAds";
import PpndPageAds from "../Components/Pages/PpndPageAds";
import AeNoCiPageAds from "../Components/Pages/AeNoCiPageAds";
import AeCiPageAds from "../Components/Pages/AeCiPageAds";
// import Maintenance from "../Components/Pages/Maintenance";


export default class RootNavigator extends React.Component<AppPropsInterface> {

  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TeachrHomePage />} />
          {/* <Route path="/" element={<Maintenance />} /> */}
          <Route path="/aici/infos" element={<AiciPage />} />
          <Route path="/informations/ce" element={<CesuPageAds />} />
          <Route path="/informations/nd" element={<PpndPageAds />} />
          <Route path="/informations/aesci" element={<AeNoCiPageAds />} />
          <Route path="/informations/aeci" element={<AeCiPageAds />} />
          <Route path="*" element={<Erreur />} />
          <Route path="/client/complete-infos/:key" element={<Index />} />
          <Route path="/client/complete-infos" element={<Index />} />
          <Route path="/client/:idclient/validate/:id" element={<ClientValidate />}/>
          <Route path="/coming-soon" element={<ComingSoon />} />
          <Route path="user"> <Route path="/user/signup"   element={<SignUpClient {...this.props} />} />
            <Route path="/user/welcome" element={<Welcome />} />
            <Route path="/user/login" element={<LoginClient />} />
            <Route path="/user/completeprofile" element={<CompleteProfile {...this.props} />}/>
            <Route path="/user/laststep" element={<LastStep />} />
            <Route path="/user/response" element={<TabPanel />} />
            <Route path="/user/profileteachr" element={<ProfileTeacher />} />
            <Route path="/user/profileteachrpackcourse" element={<ProfileTeacherCoursePack />}/>
            <Route path="/user/profileteachrpunctual" element={<ProfileTeacherPunctualCourse />}/>
            <Route path="/user/profileteachrpunctualactive" element={<ProfileTeacherPunctualActive />}/>
            <Route path="/user/coursepackactive" element={<ProfileTeacherCoursePackActive />}/>
            <Route path="/user/coursesubscription" element={<ProfileTeachrSubscriptionCourseActive />}/>
            <Route path="/user/profileteachrpackcourse" element={<ProfileTeacherCoursePack />}/>
            <Route path="/user/profileteachrpunctual" element={<ProfileTeacherPunctualCourse />}/>
            <Route path="/user/profileteachrpunctualactive" element={<ProfileTeacherPunctualActive />}/>
            <Route path="/user/coursepackactive" element={<ProfileTeacherCoursePackActive />}/>
            <Route path="/user/coursesubscription" element={<ProfileTeachrSubscriptionCourseActive />}/>
            <Route path="/user/profileteachrpunctual" element={<ProfileTeacherPunctualCourse />}/>
            <Route path="/user/formlayout" element={<FormLayout />} />
            <Route path="/user/coming-soon" element={<ComingSoons />} />
            <Route path="/user/confirmpayment" element={<ConfirmationStep />} />
            <Route path="/user/timer" element={<TimerClient />} />
            <Route path="/user/courseprvu" element={<DemandeCourse />} />
            <Route path="/user/courseprvu" element={<DemandeCourse />} />
          </Route>
          <Route path="teachr">
            <Route path="/teachr/tuto-ae" element={<Home />} />
            <Route path="/teachr/auto-entrepreneur/nova/guide" element={<NovaGuide />}/>
            <Route path="/teachr/register" element={<CourseIndex />} />
            <Route path="/teachr/login" element={<Login />} />
            <Route path="/teachr/login/:token" element={<ExternalLogin />} />
            <Route path="/teachr/register/next" element={<Register />} />
            <Route path="/teachr/profile/complete" element={<CompleteProfileIndex />}/>
            <Route path="/teachr/cours" element={<DahboardIndex />} />
            <Route path="/teachr/cours/postuler/" element={<CourseSelect />} />
            <Route path="/teachr/profile" element={<ProfileIndex />} />
            <Route path="/teachr/cours/postuler/confirm" element={<Confirm />}/>
            <Route path="/teachr/candidatures" element={<CandidacyIndex />} />
            <Route path="/teachr/candidatures/eleve" element={<StudentIndex />}/>
            <Route path="/teachr/avance-immediate" element={<Simulateur />} />
            <Route path="/teachr/simulateur" element={<SimulateurV2 />} />
            <Route path="/teachr/simulate" element={<SimulateurV2Simulate />} />
            <Route path="/teachr/fonctionnement" element={<SimulateurV2Fonctionnement />}/>
            <Route path="/teachr/avance-immediate/en-profiter" element={<MeetIndex />}/>
            <Route path="/teachr/procuration/ae" element={<ProcurationIndex />}/>
            <Route path="/teachr/procuration/sap" element={<ProcurationSapIndex />}/>
            <Route path="dashboard">
              <Route path="/teachr/dashboard" element={<AiciHome />} />
              <Route path="/teachr/dashboard/presentation" element={<PresentationIndex />}/>
              <Route path="/teachr/dashboard/setup" element={<DashboardIndex />}/>
              <Route path="/teachr/dashboard" element={<DashboardIndexCourse />}/>
              <Route path="/teachr/dashboard/declare" element={<DeclareCourse />}/>
              <Route path="/teachr/dashboard/coursetype" element={<IndexCourseType />}/>
              <Route path="/teachr/dashboard/student" element={<AddStudentPage />}/>
            </Route>
          </Route>
          <Route path="Partenaires/monabanq" element={<MonaBanq />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
