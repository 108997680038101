import { useEffect, useState } from "react";
import "antd/dist/antd.css";
import hours from "../../../../../../Assets/Icons/hour.svg";
import moment from "moment";
import StepperStore from "../../../../../../LocalForage/StepperStore";
import Functions from "../../../../../../Helpers/Functions";
import DatePickers from "../../../../../../Components/utils/DatePickers";
import { auth } from "../../../../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import CustomFeesRepository from "../../../../../../Repositories/CustomFeesRepository";
import { extractDateTimeFromString } from "../../UtilsDashboard/FunctionsDashbord";



const StepPunctual = (props) => {
  const { handleChangeStep } = props
  const [user] = useAuthState(auth)
  moment.locale("fr");
  const today = new Date();
  const tomorrow = today.setDate(today.getDate());
  const start = moment().add(0.2, "hours");
  const end = moment().add(1.7, "hours");
  let minute = start.minute(); // Obtenez les minutes
  let minuteEnd = end.minute(); // Obtenez les minutes
  if (minute % 5 !== 0) { // Si les minutes ne sont pas divisibles par 5
    minute += (5 - (minute % 5)); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  if (minuteEnd % 5 !== 0) { // Si les minutes ne sont pas divisibles par 5
    minuteEnd += (5 - (minuteEnd % 5)); // Ajoutez le nombre de minutes restantes pour atteindre le prochain multiple de 5
  }
  const heureAvecMinute = moment(start).minute(minute); // Créez une nouvelle instance Moment avec les minutes mises à jour
  const heureAvecMinuteEnd = moment(end).minute(minuteEnd); // Créez une nouvelle instance Moment avec les minutes mises à jour
  const [dateTimeTdp, setDateTimeTdp] = useState();
  const [date, setDate] = useState(tomorrow);
  const [rangeNumber, setRangeNumber] = useState(1.5);
  const [startTime, setStartTime] = useState(heureAvecMinute.toDate());
  const [endTime, setEndTime] = useState(heureAvecMinuteEnd.toDate());
  const [customFees, setCustomFees] = useState()
  const [isCustomFees, setIsCustomFees] = useState({isOk : false, noOk : false})
  const [error, setError] = useState({
    endTime: "",
    // errorDateTdp: ""
  });
  const getDate = (value) => {
    setDate(value);
  };
  const handleSliderChange = (event, newValue) => {
    setRangeNumber(newValue);
    setEndTime(moment(startTime).add(newValue, "hours").toDate());
  };
  const handleChange = (value) => {
    let startTimes = moment(value);
    let startDate = moment(date);
    let startDateTime = startDate
      .set("hour", startTimes.hours())
      .set("minutes", startTimes.minutes())
    if (startDateTime < startTime) {
      setStartTime(heureAvecMinute.toDate());
      setEndTime(
        moment(heureAvecMinute).add(rangeNumber, "hours").toDate()
      );
    } else {
      setStartTime(value);
      setEndTime(
        moment(value).add(rangeNumber, "hours").toDate()
      );
    }
  };
  const validateDateTime = (value) => {
    const hour = moment(endTime).hours() - moment(startTime).hours();
    const minute = (moment(endTime).minutes() - moment(startTime).minutes()) / 60;
    const duration = hour + minute;
    if (duration < value) {
      return {
        isValid: false,
        message:
          "Horaires de cours particulier non valides, veuillez sélectionner une plage horaire entre 00h01 et 23h59 sans chevauchement de journées.",
      };
    } else {
      return {
        isValid: true,
        message: "",
      };
    }
  };
  // const validateDate = (value) => {
  //   let startTimeValid = moment(startTime);
  //   let startDateTime = moment(date)?.set("hour", startTimeValid.hours()).set("minutes", startTimeValid.minutes()).format("DD/MM/YYYY HH:mm:ss");
  //   // let startDateTime = moment(date)?.set("hour", startTimeValid.hours()).set("minutes", startTimeValid.minutes());
  //   if (value > startDateTime) {
  //     return {
  //       isValid: false,
  //       message: "Votre client a validé son compte le "+value+". Veuillez sélectionner une date et une heure de cours ultérieure à cette date.",
  //     };
  //   } else {
  //     return {
  //       isValid: true,
  //       message: "",
  //     };
  //   }
  // }

  const validate = () => {
    let errEnd = true;
    // let errDate = true;
    if (!validateDateTime(rangeNumber).isValid) {
      setError({ endTime: validateDateTime(rangeNumber).message });
      errEnd = true;
    } else {
      setError({ endTime: validateDateTime(rangeNumber).message });
      errEnd = false;
    }
    // if (!validateDate(dateTimeTdp).isValid) {
    //   setError({ endTime: validateDateTime(rangeNumber).message });
    //   errDate = true;
    // } else {
    //   setError({ endTime: validateDateTime(rangeNumber).message });
    //   errDate = false;
    // }

    if (errEnd) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    StepperStore.removeItem("activeStepundefined")
    StepperStore.getItem("cours" + user.uid).then((client) => {
      if (client !== null) {
        StepperStore.getItem("description_tdp" + client?.client).then((res) => {
          if (res !== null) {
            const dateTime = extractDateTimeFromString(res);
            setDateTimeTdp(dateTime);
          }
        })
      }
    });
  }, [])

  const handleSubmit = () => {
    if (validate()) {
      StepperStore.setItem("punctual" + user.uid, {
        date: date,
        rangeNumber: rangeNumber,
        startTime: startTime,
        endTime: endTime,
        customFees: customFees
      });
      if (isCustomFees.isOk) {
        if(customFees){
          if(customFees?.is_teachr_contact){
            handleChangeStep(6);
          } else {
            handleChangeStep(7)
          }
        }
      } else {
        if(isCustomFees.noOk) {
          handleChangeStep(6)
        }
      }
    }
  };


  useEffect(() => {
    if (user) {
      StepperStore.getItem("cours" + user.uid).then((res) => {
        // const idClient = parseInt(res?.client["@id"].substr(9));
        const idClient = res?.client;
        TeachrRepository.getCurrentTeachr().then((teachr) => {
          if (teachr?.id) {
            CustomFeesRepository.getCustomFeesSingle(idClient, teachr?.id).then((res) => {
              if(res[0]){
                setIsCustomFees({isOk : true, noOk : false})
                setCustomFees(res[0]);
              }
            }).catch((err) => {
              setIsCustomFees({isOk : false, noOk : true})
            })
          }
        });
      });
    }
  })

  return (
    <div className="overflow-y-auto list-teacher relative h-[100%]  bg-white rounded-lg shadow-dashboard-setup-intro-form">
      <div className='relative h-[100%]'>
        <div className="space-y-4 h-[90%] /h-[100%] md:p-10 p-6 ">
          <h2 className="font-extrabold /lg:text-3xl md:text-2xl text-xl text-dark_blue">Cours ponctuel</h2>
          <div className="mx-auto w-full translate-y-6 ">
            <div className=" md:w-2/3 w-full ">
              <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md py-[2px] px-3 w-full lg:text-lg md:text-base text-base">
                <label
                  htmlFor=""
                  className="lg:text-lg md:text-base text-base font-medium"
                >
                  {" "}
                  Durée du cours
                </label>
                <div className=" md:h-9 h-11 md:-translate-y-1 md:mt-0 -mt-2">
                  <Functions.PrettoSlider
                    defaultValue={1}
                    step={0.25}
                    min={0.25}
                    max={4}
                    marks
                    value={rangeNumber}
                    onChange={handleSliderChange}
                  />
                </div>
              </div>
              <span className="font-bold bg-transparent text-dark_blue">
                <input
                  className="bg-transparent"
                  type="text"
                  value={Functions.renderDuration(rangeNumber * 2)}
                  onChange={(e) => setRangeNumber(e.target.value)}
                />
              </span>
            </div>
            <div className="w-full md:mt-8 mt-4 gap-8 md:space-y-0 space-y-6  md:flex block mt- mx-auto">
              <div className="md:w-1/2">
                <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito py-2 h-full -pb-1 px-3 w-full lg:text-lg md:text-base text-base">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-base font-medium"
                  >
                    {" "}
                    Date du cours :
                  </label>
                  <div className="md:-mt-3 -mt-6 md:translate-y-0 translate-y-2  ">
                    <div className="relative  -ml-4 ">
                      <DatePickers value={date} callBack={getDate} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2">
                <div className="shadow-md shadow-[rgba(0,0,0,25%)] bg-input-color rounded-md font-nunito  py-2 md:py-1 -pb-1 px-3 w-full lg:text-lg md:text-base text-base">
                  <label
                    htmlFor=""
                    className="lg:text-lg md:text-base text-base font-medium"
                  >
                    {" "}
                    Horaires du cours
                  </label>
                  <div className="flex mt-2">
                    <div className="w-full relative">
                      <div className="md:w-[90%] //border flex">
                        <div className=" w-1/4 ">
                          <DatePicker
                            selected={startTime}
                            value={startTime}
                            onChange={(newValue) => {
                              handleChange(newValue)
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            timeCaption=""
                            locale="fr"
                            placeholderText="Sélectionner l'heure"
                            customInput={<input className='//border-2 //text-center mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-base' type="text" />}
                            // timeInputLabel="Heure :"
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                              <div className="time-picker-header border-4 border-red-2 flex justify-between items-center h-12 bg-red-400 px-4">
                                <button className="text-red-700 text-2xl" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>{'<'}</button>
                                <span className="text-red-800 text-xl font-bold">{format(date, 'MMMM yyyy', { locale: 'fr' })}</span>
                                <button className="text-red-700 text-2xl" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{'>'}</button>
                              </div>
                            )}
                          />
                        </div>
                        <div className="" >
                          <span className="lg:text-xl md:text-base">à</span>
                        </div>
                        <div className="//text-base //w-1/2 //mt-2.5 //xl:ml-5 //lg:ml-2//ml-5 w-1/4 ">
                          <DatePicker
                            selected={endTime}
                            onChange={(newValue) => {
                              handleChange(newValue)
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            disabled={true}
                            timeIntervals={15}
                            dateFormat="HH:mm"
                            timeFormat="HH:mm"
                            timeCaption=""
                            locale="fr"
                            placeholderText="Sélectionner l'heure"
                            customInput={<input className=' text-[#808080] text-right mx-auto w-[100%] bg-transparent lg:text-lg  md:text-base text-base' type="text" />}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="-mt-2 md:mt-0">
                      <img
                        src={hours}
                        alt="calendar icon"
                        className="md:w-8 xl:w-10 w-7 md:-translate-x-3 //-translate-x-6 xl:-mt-2 "
                      />

                    </div>
                    {/* <div>
                  <img src={hours} alt="calendar icon" classname="w-6 -mt-4 -translate-y-4" />
                  </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="flex space-x-2 text-base md:translate-y-4 translate-y-2 ">
            <p className="text-[#FF724F] h-6 w-full text-base md:text-base ">
              {error.endTime === "null" || error.endTime === ""
                ? null
                : validateDateTime(rangeNumber).message}
            </p>

          </div>
          {/* <div className="flex space-x-2 text-base md:translate-y-4 translate-y-4 ">
            <p className="text-[#FF724F] h-6 w-full text-base md:text-base ">
              {error.errorDateTdp === "null" || error.errorDateTdp === ""
                ? null
                : validateDate(dateTimeTdp).message}
            </p>
          </div> */}
        </div>

        <div className="absolute md:hidden flex justify-around mx-auto space-x-4 md:translate-y-44 w-full px-4">
          <div className="flex w-1/2 rounded-full bg-white text-orange  border-2 border-orange font-extrabold justify-center">
            <div
              onClick={() => handleChangeStep(2)}
              className="text-xs pt-2 w-full text-center uppercase"
            >
              Précédent

            </div>
          </div>
          <div className="flex justify-center w-1/2 ">
            <div
              onClick={(e) => handleSubmit()}
              className="md:px-4 md:py-2 py-2 px-3 border-2 bg-white w-full text-center font-extrabold hover:font-extrabold text-sm border-first text-first uppercase rounded-full active:bg-first active:text-white  "
            >
              Suivant
            </div>
          </div>
        </div>
        <div className="md:flex px-10 bottom-0 absolute hidden justify-between mx-auto pb-10 md:w-full">
          <div className="flex justify-center">
            <button
              type="button"
              onClick={() => handleChangeStep(2)}
              className="  text-sm lg:text-[17px] font-semibold  text-[#4d4d4d] uppercase hover:text-first  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24"
            >
              Précédent

            </button>
          </div>
          <div className="flex justify-center ">
            <button
              type="button"
              onClick={(e) => handleSubmit()}
              className="inline-block md:px-4 md:py-2 py-2 px-4 border-2 font-extrabold hover:font-extrabold text-sm lg:text-[17px] hover:text-white hover:bg-first    border-first text-first  leading-tight uppercase rounded-full  focus:outline-none focus:ring-0 active:text-first transition duration-150 ease-in-out md:w-36 w-24 "

            >
              Suivant
            </button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default StepPunctual;
