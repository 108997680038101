import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DateTime from '../../../UtilsDashboard/DateTime';
import closeCircle from '../../../../../../../Assets/Icons/close_circle.svg'
import Swal from 'sweetalert2';
import moment from 'moment';
import { SubscriptionCoursePause } from '../../../../../../../Objects/models/SubscriptionCoursePause';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import SubscriptionCourseRepositorys from '../../../../../../../Repositories/SubscriptionCourseRepository';


export default function MettreEnPauseLeCours(props: any) {
    const { value, handleLoad, open, handleOpen  } = props
    const today = new Date();
    let timerInterval: any;
    const [date, setDate] = React.useState(today);
    const getDate = (value: any) => {
        setDate(value)
    };
    const handleClose = () => {
        handleOpen(false);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handlSubmit = () => {
        handleLoad()
        const id = parseInt(value)
        let startDate = moment(date).format("Y-MM-DD");
        const subscription = new SubscriptionCoursePause(
            startDate
        )
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            handleLoad()
            if (teachr.id) {
                SubscriptionCourseRepositorys.teachrPauseSubscriptionCourse(teachr.id, id, subscription).then((res) => {
                    handleLoad()
                    handleClose()
                    Swal.fire({
                        icon: "success",
                        title: "Cours mis en pause",
                        text: "  Votre cours régulier a bien été mis en pause. Vous pourrez retrouver toutes les modifications de celui-ci dans votre tableau de bord.",
                        timer: 5000,
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    });
                    handleLoad()
                    // Swal.close()
                }).catch((err) => {
                    handleLoad()
                })
            }
        }).catch((err) => {
            handleLoad()
        })
    }

    return (
        <div className=''>
            <Dialog
                open={open}
                onClose={handleClose}
                // scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"

            >
                <DialogTitle id="scroll-dialog-title" >
                    <div className='flex w-full //border gap-x-10 //px-10 //items-center relative //justify-between text-base font-extrabold uppercase text-first'>
                        <div className="//w-full w-3/4 //border pt-1">
                            <p className="text-left">Mettre toutes les Séances de ce cours {value} en pause</p>
                        </div>
                        <div className="right-0 //w-1/4 //border -top-0 absolute">
                            <img onClick={() => handleClose()} className='cursor-pointer' src={closeCircle} alt="" />
                        </div>
                    </div>
                </DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <div className="//py-8 //px-10 text-left ">
                            <div className=" //ml-2 mt-4 w-full">
                                <p className='text-lg -mt-6 mb-4 text-normal font-bold'>Si vous préférez plutôt mettre une seule séance, Veuillez cliquer dans l’onglet “Séances à venir”.
                                    Si vous souhaitez poursuivre la mise en pause de toutes les Séances du cours régulier, Veuillez entrer la date de reprise.

                                </p>
                                <div>
                                    <p className="text-dark_blue font-bold">
                                        Date de reprise :
                                    </p>
                                </div>
                                <div className="border-2 border-dark_blue rounded-lg md:py-2">
                                    <DateTime value={date} callBack={getDate} />
                                </div>
                            </div>

                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Subscribe</Button> */}
                    <div className='flex w-full px-4 justify-between //gap-x-8 py-3 //md:pt-8'>
                        <Button onClick={() => handleClose()} className='block bg-[#4d4d4d] font-extrabold rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Retour</Button>
                        <Button onClick={() => handlSubmit()} className='block bg-dark_blue font-extrabold   rounded-full md:px-14 px-8 text-white  border-solid border-[1px] border-first  uppercase  w-max' >Valider</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}