



const ValidateSiret = (siret: string  | null | undefined) => {
    if (!siret) {
        return {
            valid: false,
            message: "Veuillez renseigner un numéro de SIRET"
        }
    }
    if ((siret.length < 14) || isNaN(Number(siret))) {
        return {
          valid: false,
          message:
            "Le numéro SIRET doit être constitué de 14 chiffres et ne doit pas inclure d'espaces",
        };
        // SIRET number must be a 14 digit number
    }else if (siret.length > 14) {
        return {
          valid: false,
          message:
            "Le numéro SIRET ne doit pas excéder 14 chiffres et ne doit pas inclure d'espaces",
        };
    }

    // We apply Luhn algorithm (or "modulus 10")
    let sum = 0;
    for (let counter = 0; counter < siret.length; counter++) {
        let digit: number;
        if ((counter % 2) === 0) { // For every 2nd digit starting from the end
            digit = 2 * Number(siret.charAt(counter)); // We multiply it by 2
            if (digit > 9) {
                digit -= 9; // If the result is > 9, we add the two digits of the result
            }
        } else {
            digit = Number(siret.charAt(counter));
        }
        sum += digit;
    }
    if (sum % 10 !== 0) {
        return {  
            valid: false,
            message: "Le numéro de SIRET est invalide"
          }
    }else{
        return  {
            valid: true,
            message: ""
        };
    }
};

export default ValidateSiret;
