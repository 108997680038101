import ActionInterface from "../../Objects/interfaces/ActionInterface";
import Error from "../../Objects/models/Error";

export const GENERAL_SET_CONNECTION_TYPE = "GENERAL_SET_CONNECTION_TYPE";
export const GENERAL_SET_OPENED_BY_NOTIFICATION =
    "GENERAL_SET_OPENED_BY_NOTIFICATION";
export const GENERAL_SET_NOTIFICATION_AUTHORIZATION =
    "GENERAL_SET_NOTIFICATION_AUTHORIZATION";
export const GENERAL_SET_PAGE_TO_OPEN = "GENERAL_SET_PAGE_TO_OPEN";
export const GENERAL_SET_ERROR = "GENERAL_SET_ERROR";
export const GENERAL_DISMISS_ERROR = "GENERAL_DISMISS_ERROR";

export const GENERAL_ALREADY_LAUNCHED_TEACHR =
    "GENERAL_ALREADY_LAUNCHED_TEACHR";
export const GENERAL_ALREADY_LAUNCHED_CLIENT =
    "GENERAL_ALREADY_LAUNCHED_CLIENT";
export const GENERAL_ALREADY_LAUNCHED_CLIENT_REGULAR_COURSES =
    "GENERAL_ALREADY_LAUNCHED_CLIENT_REGULAR_COURSES";
export const GENERAL_ALREADY_LAUNCHED_TEACHR_COURSE_PACK =
    "GENERAL_ALREADY_LAUNCHED_TEACHR_COURSE_PACK";
export const GENERAL_ALREADY_AGREED_CONVERSATION =
    "GENERAL_ALREADY_AGREED_CONVERSATION";
export const GENERAL_SET_HAS_TO_RELOAD_REVIEWS =
    "GENERAL_SET_HAS_TO_RELOAD_REVIEWS";
export const GENERAL_SET_UNREAD_MESSAGES = "GENERAL_SET_UNREAD_MESSAGES";

export const generalSetNotificationAuthorization = (
    isAuthorized: boolean
): ActionInterface => {
    return {
        type: GENERAL_SET_NOTIFICATION_AUTHORIZATION,
        value: isAuthorized,
    };
};


export const generalSetError = (error: Error): ActionInterface => {
    return {
        type: GENERAL_SET_ERROR,
        value: error,
    };
};

export const generalDismissError = (): ActionInterface => {
    return {
        type: GENERAL_DISMISS_ERROR,
        value: undefined,
    };
};

export const generalAlreadyLaunchedTeachr = (): ActionInterface => {
    return {
        type: GENERAL_ALREADY_LAUNCHED_TEACHR,
        value: false,
    };
};

export const generalAlreadyLaunchedClient = (): ActionInterface => {
    return {
        type: GENERAL_ALREADY_LAUNCHED_CLIENT,
        value: false,
    };
};

export const generalAlreadyLaunchedTeachrCoursePack = (): ActionInterface => {
    return {
        type: GENERAL_ALREADY_LAUNCHED_TEACHR_COURSE_PACK,
        value: false,
    };
};

export const generalAlreadyLaunchedClientRegularCourses = (): ActionInterface => {
    return {
        type: GENERAL_ALREADY_LAUNCHED_CLIENT_REGULAR_COURSES,
        value: false,
    };
};

export const generalAlreadyAgreedConversation = (): ActionInterface => {
    return {
        type: GENERAL_ALREADY_AGREED_CONVERSATION,
        value: false,
    };
};


export const generalSetHasToReloadReviews = (
    hasToReload: boolean
): ActionInterface => {
    return {
        type: GENERAL_SET_HAS_TO_RELOAD_REVIEWS,
        value: hasToReload,
    };
};
export const generalSetUnreadMessages = (
    unreadMessages: boolean
): ActionInterface => {
    return {
        type: GENERAL_SET_UNREAD_MESSAGES,
        value: unreadMessages,
    };
};



