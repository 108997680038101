import { useLocation } from 'react-router-dom';
import NavBar from '../../../../../Components/Dashboard/NavBar';
import Loader from '../../../../../Components/utils/Loader';
import PackDetails from './Pack';
import PunctualDetails from './Punctual';
import RegularDetails from './Regular';

const Index = () => {
    const location = useLocation();
    return (
        <div>
            <Loader />
             <NavBar active={2} />
            <div className='px-4'>
            {location.state?.courseType! === 'regular' && <RegularDetails {...location.state?.regular!} />}
            {location.state?.courseType! === 'punctual' && <PunctualDetails {...location.state?.punctual!} />}
            {location.state?.courseType! === 'pack' && <PackDetails {...location.state?.pack!} />}
            </div>
        </div>
    );
};

export default Index;