import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Third = (props:any) => {
    const navigate = useNavigate()
    return (
        <div className='flex flex-col lg:border border-[rgba(146,211,255,0.54)] lg:shadow-profile-complete-select rounded-lg lg:p-8 p-4 gap-y-8'>
            <h4 className='text-lg font-extrabold lg:text-[22px] text-first'>Déclaration NOVA</h4>
            <div className='flex flex-col gap-y-4'>
                <p className='text-base font-semibold text-normal lg:text-lg'>Nova est le service de démarches en ligne des organismes de services à la personne. La déclaration de service à la personne est obligatoire. </p>
                <p className='text-base font-semibold text-normal lg:text-lg'>Vous pouvez décider de faire la déclaration par vous même et accédez au <a className='font-bold underline text-radio' href="/teachr/auto-entrepreneur/nova/guide">tutoriel</a> ou nous déléguer ce service, <a href="/teachr/procuration/sap" className='font-bold underline text-radio'>cliquez ici</a> pour nous envoyez la procuration pour que nous puissons faire les démarches à vote place </p>
            </div>
            <div className="flex justify-between flex-col lg:flex-row gap-y-4 lg:items-start">
                <Button onClick={()=> props.nextStep(0)} className='text-[#B7B7B7] lg:text-lg text-sm font-extrabold rounded-full py-2 px-8 order-last lg:order-first w-full lg:w-max'>PRÉCÉDENT</Button>
               <div className='flex flex-col items-center gap-y-4'>
               <Button onClick={()=> navigate("/teachr/procuration/sap")} className='px-8 py-2 font-extrabold text-white rounded-full bg-radio w-full lg:w-max'>Procuration</Button>
                <Button onClick={()=> props.nextStep(3)} className='px-8 py-2 font-extrabold text-radio rounded-full  w-full lg:w-max'>J'ai déjà une déclaration</Button>
               </div>

            </div>
        </div>
    );
};

export default Third;