import ApiService from "../Services/ApiService";
import { PackProposal } from "../Objects/models/PackProposal";

export default abstract class PackProposalRepository {
    public static fetchPackProposalByIdClient(id: number) {
        return ApiService.get(`/clients/v2/pack_proposals/${id}`);
    }

    public static fetchPackProposalByIdTeachr(id: number) {
        return ApiService.get(
            `/teachrs/v2/teachrs/${id}/pending_pack_proposals`
        );
    }

    public static fetchAllCoursePackProposals(packid: number, isClient: boolean) {
        if (isClient) {
            return ApiService.get(
                `/clients/v2/course_packs/${packid}/pack_proposals`
            );
        } else {
            return ApiService.get(
                `/teachrs/v2/course_packs/${packid}/pack_proposals`
            );
        }
    }

    public static createPackProposal(
        packProposal: PackProposal,
        coursePackId: number
    ) {
        return ApiService.post(
            `/teachrs/v2/course_packs/${coursePackId}/pack_proposals`,
            packProposal
        );
    }

    /**
     * Returns all packProposals
     */
    public static getPackProposals(id: number) {
        return ApiService.get(`/teachrs/v2/pack_proposals/${id}`);
        
    }


    /**
     * Try to accept a packProposal (payment process).
     */
    public static async tryAcceptPackProposal(packProposal: PackProposal) {
        return ApiService.put(
            `/clients/v2/pack_proposals/${packProposal.id}/try-accept`
        );
    }

    public static async acceptPackProposalAfter3dSecure(
        packProposal: PackProposal
    ) {
        return ApiService.put(
            `/clients/v2/pack_proposals/${packProposal.id}/accept-after-3d-secure`
        );
    }

    /**
     * refuse a packProposal.
     */
    public static refusePackProposal(packProposal: PackProposal) {
        return ApiService.put(
            `/clients/v2/pack_proposals/${packProposal.id}/refused`
        );
    }

    public static deletePackProposal(packProposalId: number) {
        return ApiService.deleteInstance(
            `/teachrs/v2/pack_proposals/${packProposalId}`
        );
    }
}
