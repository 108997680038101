import { Button } from '@mui/material';
import React, { useState } from 'react';
import ReactCodeInput from "react-pin-input";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Functions from '../../../../Helpers/Functions';
// const inputHover = {
//     'input:focus': {
//         outline: '0.5px solid #0054A3'
//     }
// }
const style = {
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '15px',
        height: '100%'
    },
    inputStyle: {
        fontFamily: 'Nunito',
        fontWeight: '400',
        outline: 'none',
        width: '100%',
        borderRadius: '15px',
        height: '100%',
        backgroundColor: '#F2F5FB',
        color: '#4E494A',
        border: ' 0.5px solid ',
        borderColor: 'rgba(128, 128, 128, 0.35)',
        textAlign: 'center',
        margin: '0'
    },
    inputStyleInvalid: {
        fontFamily: 'Nunito',
        fontWeight: '400',
        outline: 'none',
        width: '100%',
        borderRadius: '15px',
        height: '100%',
        backgroundColor: '#F2F5FB',
        color: '#4E494A',
        border: ' 0.5px solid ',
        borderColor: '#FF724F',
        textAlign: 'center',
        margin: '0'
    }
}
const Code = (props) => {
    const [isPinCodeValid, setIsPinCodeValid] = useState(true);
    const handleSubmit = (code) => {
        props.code.confirm(code).then((result) => {
          }).catch((error) => {
            console.warn(error)
           setIsPinCodeValid(false)
          });
    }

    return (
        <div className='flex flex-col gap-8'>
            <div>
                <p className='block pb-4 overflow-hidden font-bold text-first whitespace-nowrap text-ellipsis w-max'>Un code vient d’être envoyé au  {Functions.formatPhoneNumber(props.phone)}</p>
                <div className="w-full md:w-2/3 h-[45px] md:h-[53px]">
                    <ReactCodeInput
                        inputStyle={style.inputStyle}
                        style={style.container}
                        inputFocusStyle={{ borderColor: '#0054A3' }}
                        type="pin"
                        length={6}
                        onComplete={(value, index) => { handleSubmit(value) }}
                    />
                </div>
            </div>

            {!isPinCodeValid &&
                <div className='flex items-center gap-1 text-sm text-third'>
                    <HighlightOffIcon />
                    <p className=''>Le code est invalide, renvoyez un nouveau code ou choisissez un autre moyen de connexion</p>
                </div>
            }

            <div>
                <p className='p-1 pl-3 text-sm text-normal'>Pas de Code?</p>
                <Button className='block  bg-transparent rounded-full md:px-20 px-8 text-first  border-solid border-[1px] border-first  uppercase  w-max'>renvoyer</Button>
            </div>
        </div>
    );
};

export default Code;