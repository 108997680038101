
import profileImage from '../../../../../Assets/Images/profile__image.png'
import ReviewTeachr from '../../../../../Objects/models/ReviewTeachr';

const Comment = (review: ReviewTeachr) => {
    return (
        <div className=''>
            <div className='bg-[#F2F9FF] px-4 rounded-lg'>
                <div className="flex items-center gap-3 py-4">
                    <img className='w-[54px]' src={profileImage} alt="" />
                    <div>
                        <h3 className='text-first text-[18px] font-bold capitalize'>{review.owner?.firstName}{review.owner?.lastName}</h3>
                        <p className='capitalize text-[14px] text-normal'>teachr'r anglais</p>
                    </div>
                </div>
                <div className='py-4 text-normal'>
                    <p>{review.studentRemarks}</p>
                </div>
            </div>
        </div>
    );
};

export default Comment;