export default class TeachrValidateClientAici {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    clientValidate: boolean

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    readonly creationDatetime?: Date;

    constructor(
        clientValidate: boolean,
    ) {
        this.clientValidate = clientValidate;
    }

}
