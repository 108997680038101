import { Button, FormControlLabel, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import RadioButton from '../../../../Components/utils/RadioButton';
import { useDispatch, useSelector } from 'react-redux';

const Ninth = (props: any) => {

    const dispatch = useDispatch()
    const generalProcuration = useSelector((state: any) => state.procurationAe)
    const [firstResidence, setFirstResidence] = useState<number>(0)
    const handleChangeActivity = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstResidence(parseInt((event.target as HTMLInputElement).value));
    };
    useEffect(() => {
        if (generalProcuration && generalProcuration.second && generalProcuration.second.ninth !== undefined) {
            setFirstResidence(generalProcuration.second.ninth)
        }
    }, [generalProcuration])
    // useEffect(
        const handleNext = () => {
        // props.callBack({
        //     firstResidence: firstResidence
        // })
        if (firstResidence !== undefined && firstResidence >= 0) {
            if (generalProcuration.second.ninth !== undefined) {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        step:10,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: generalProcuration.second.third,
                            fourth: generalProcuration.second.fourth,
                            fifth: generalProcuration.second.fifth,
                            sixth: generalProcuration.second.sixth,
                            seventh: generalProcuration.second.seventh,
                            eighth: generalProcuration.second.eighth,
                            ninth: firstResidence,
                            tenth: generalProcuration.second.tenth!,
                            eleventh: generalProcuration.second.eleventh!
                        }
                    }
                })
            } else {
                dispatch({
                    type: 'SET_AE_INFO', payload: {
                        step:10,
                        first: generalProcuration.first,
                        second: {
                            first: generalProcuration.second.first,
                            second: generalProcuration.second.second,
                            third: generalProcuration.second.third,
                            fourth: generalProcuration.second.fourth,
                            fifth: generalProcuration.second.fifth,
                            sixth: generalProcuration.second.sixth,
                            seventh: generalProcuration.second.seventh,
                            eighth: generalProcuration.second.eighth,
                            ninth: firstResidence
                        }
                    }
                })
            }
            // props.nextStep(10)
            props.setCurrentStep(10)
        }
    }
    // , [firstResidence])
    return (
        <div className='flex flex-col gap-y-8'>
            <div className="flex flex-col">
                <label className='text-normal text-base lg:text-xl font-semibold' htmlFor="">Par défaut votre résidence principale est insaisissable, souhaitez vous <span className='font-bold' >renoncer à cette insaisissabilité ?</span> </label>
                <RadioGroup row
                    value={firstResidence}
                    defaultValue={generalProcuration && generalProcuration.second ? generalProcuration.second.ninth : null}
                    name="customized-radios"
                    onChange={handleChangeActivity}
                >
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="1" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Oui " />
                    <FormControlLabel sx={{ '& .MuiFormControlLabel-label': { color: '#4D4D4D', fontFamily: 'Nunito', fontSize: "16px" } }} value="0" control={<RadioButton border='1px solid #0054A3' bg='#0054A3' />} label="Non" />
                </RadioGroup>
            </div>
              <Button
              disabled={props.loading}
              onClick={handleNext}
              className="text-white lg:text-xl rounded-full px-10 lg:py-3 mx-auto block mt-14 w-full lg:w-max py-2 disabled:opacity-30"
              style={{
                background: "linear-gradient(180deg, #0054A3 0%, #219CFF 100%)",
              }}
            >
              SUIVANT
            </Button>
        </div>
    );
};

export default Ninth;