import validator from 'validator';
const ValidateBIC = (bic) => {
    if (!validator.isBIC(bic)) {
        return {
            isValid: false,
            message: "Veuillez entrer un BIC valide",
        };
    } else
        return {
            isValid: true,
            message: "",
        };
};

export default ValidateBIC;