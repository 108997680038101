import React, { useEffect, useRef, useState } from 'react';

import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';




const  Seventh = (props: any) => {
    const navigate = useNavigate()  
    
    return (
        <div className='flex h-full gap-10'>
            
            <div className='relative w-full'>
                <div className='flex items-center pb-10 gap-x-4'>
                    <span className="hidden lg:inline">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16" cy="16" r="16" fill="#0054A3" />
                        <path d="M16.9503 13.056C17.8743 13.056 18.6956 13.2687 19.4143 13.694C20.133 14.1047 20.6903 14.6913 21.0863 15.454C21.497 16.202 21.7023 17.06 21.7023 18.028C21.7023 19.0253 21.475 19.92 21.0203 20.712C20.5803 21.4893 19.9643 22.098 19.1723 22.538C18.395 22.978 17.5076 23.198 16.5103 23.198C14.545 23.198 13.027 22.538 11.9563 21.218C10.9003 19.8833 10.3723 17.984 10.3723 15.52C10.3723 13.8333 10.6216 12.374 11.1203 11.142C11.619 9.89533 12.3303 8.942 13.2543 8.282C14.193 7.622 15.293 7.292 16.5543 7.292C17.3903 7.292 18.2263 7.446 19.0623 7.754C19.913 8.04733 20.6316 8.45067 21.2183 8.964C21.409 9.12533 21.541 9.28667 21.6143 9.448C21.7023 9.59467 21.7463 9.778 21.7463 9.998C21.7463 10.3207 21.651 10.5993 21.4603 10.834C21.2843 11.054 21.0716 11.164 20.8223 11.164C20.6756 11.164 20.5363 11.142 20.4043 11.098C20.2723 11.0393 20.111 10.9513 19.9203 10.834C18.703 9.98333 17.6176 9.558 16.6643 9.558C15.5936 9.558 14.7503 10.0567 14.1343 11.054C13.5183 12.0367 13.1956 13.4227 13.1663 15.212C13.5036 14.552 14.0096 14.0313 14.6843 13.65C15.359 13.254 16.1143 13.056 16.9503 13.056ZM16.3563 20.954C17.163 20.954 17.8083 20.6973 18.2923 20.184C18.7763 19.656 19.0183 18.9667 19.0183 18.116C19.0183 17.2653 18.7763 16.5833 18.2923 16.07C17.8083 15.5567 17.163 15.3 16.3563 15.3C15.535 15.3 14.8676 15.564 14.3543 16.092C13.8556 16.6053 13.6063 17.28 13.6063 18.116C13.6063 18.9667 13.8556 19.656 14.3543 20.184C14.8676 20.6973 15.535 20.954 16.3563 20.954Z" fill="white" />
                    </svg>
                    </span>
                    <h2 className='text-first lg:text-[22px] text-lg font-bold'>Terminé ! 🎉</h2>
                </div>
                <div className="flex flex-col text-base lg:text-xl font-semibold gap-y-8 text-normal">
                    {/* <p>Tous vos documents sont maintenant finalisés.</p> */}

                    <p>Votre procuration a bien été reçue. Celle-ci sera traitée dans les meilleurs délais par notre équipe. Nous reviendrons vers vous dès que possible pour l’activation de votre compte. Si vous avez une question, vous pouvez nous écrire à l’adresse support@teachr.fr</p>

                </div>
                <div className="flex justify-center py-10">
                <a  target= "_blank" download href = {props.file}  className='px-10 py-3 text-base lg:text-xl font-extrabold text-white rounded-full bg-first'>Télécharger ma procuration</a>
                </div>
                {/* <Button onClick={() => { navigate("/teachr/profile/complete"); }} className='w-full py-2 mx-auto mt-1 mb-1 text-sm font-extrabold text-center text-white uppercase transition duration-150 ease-in-out border-2 rounded-full cursor-pointer bg-dark_blue border-dark_blue'>conditions de services</Button> */}
                {/* <div className='text-base font-semibold text-center text-normal'>
                    En attendant vous pouvez des à présent télécharger
                    la plaquette de renseignement pour vos élèves 
                    <a href="" className='ml-2 font-bold underline text-first'> cliquant ici</a>
                </div> */}
            </div>
            
        </div>
    );
};

export default Seventh;