import React from 'react';
import Alert, { AlertColor } from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { AlertTitle } from '@mui/material';

interface alertModel {
    info: AlertColor,
    msg: string,
    customClass?: string,
    title?: string
}

const AlertInfo = (props: alertModel) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert variant="outlined" severity={props.info} sx={{ fontFamily: 'nunito' }} className={props.customClass} >
         <AlertTitle className='font-bold lg:text-lg md:text-base font-nunito' >{props.title}</AlertTitle>
        {props.msg}
      </Alert>
    </Stack>
  );
};

export default AlertInfo;