import React, { useEffect, useState, useRef } from "react";
import NavBar from "../../../Components/Dashboard/NavBar";
import Stepper from "./Stepper";
import First from "./First";
import Second from "./Second";
import { Button, Skeleton } from "@mui/material";
import Third from "./Third";
import FouthIndex from "./Fourth/Index";
import FiveIndex from "./Fifth/Index";
import Sixth from "./Sixth";
import Seventh from "./Seventh";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import mandataireSign from "../../../Assets/Images/sign.png";
import Teachr from "../../../Objects/models/Teachr";
import { useNavigate } from "react-router";
import ImageRepository from "../../../Repositories/ImageRepository";
import TeachrRepository from "../../../Repositories/TeachrRepository";

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface ChildComponentHandles {
  handleBack: () => void;
}
const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [teachr, setTeachr] = useState<Teachr>();
  const [file, setFile] = useState<any>();
  const [proofIentity, setProofIdentity] = useState<File>();
  const [proofIentity2, setProofIdentity2] = useState<File>();
  const [pdfFile, setPdfFile] = useState<any>(
    process.env.PUBLIC_URL +
    "/Pouvoir_procuration_creation_auto-entreprise-TeachR_VDEF.pdf"
  );
  const [pdfDoc, setPdfDoc] = useState<PDFDocument | null>(null);
  const [numPages, setNumPages] = useState<any>(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const generalTeachr = useSelector((state: any) => state.teachr);
  const [signUrl, setSignUrl] = useState<string>();
  const [signText, setSignText] = useState<string>();
  const [isSign, setIsSign] = useState(false);
  const [isSignText, setIsSignText] = useState(false);
  const [downloadLink, setDownloadLink] = useState<string>();
  const [showDoc, setShowDoc] = useState(false)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth - 35);
  const [stepSecond, setStepSecond] = useState(1)
  const childRef = useRef<ChildComponentHandles>(null);
  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }
  const generalStepperAe = useSelector((state: any) => state.stepperAe)
  const generalProcuration = useSelector((state: any) => state.procurationAe)
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth - 35);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (generalStepperAe) {
      if (generalStepperAe >= 5) {
        setPage(1)
      } else {
        setPage(generalStepperAe)
      }
      setCurrentStep(generalStepperAe)
    }
  }, [generalStepperAe])
  useEffect(() => {
    (async () => {
      const response = await fetch(pdfFile);
      const arrayBuffer = await response.arrayBuffer();
      const pdfDocument = await PDFDocument.load(arrayBuffer);
      setPdfDoc(pdfDocument);
      if (generalProcuration) {
        if (pdfDocument) {
          const firstNameField1 = pdfDocument.getForm().getTextField("text_2szbk");
          const birthNameField1 = pdfDocument.getForm().getTextField("text_3ggbf");
          const firstNameField2 = pdfDocument.getForm().getTextField("text_9khzw");
          const birthNameField2 = pdfDocument.getForm().getTextField("text_10zhxm");
          const birthDateField1 = pdfDocument.getForm().getTextField("text_4uena");
          const birthDateField2 = pdfDocument.getForm().getTextField("text_13mwhy");
          const cityBirthField1 = pdfDocument.getForm().getTextField("text_5pkzj");
          const cityBirthField2 = pdfDocument.getForm().getTextField("text_15zpyt");
          const addressField1 = pdfDocument.getForm().getTextField("text_6zwby");
          const addressField2 = pdfDocument.getForm().getTextField("text_47frij");
          const signCityField = pdfDocument.getForm().getTextField("text_7kchz");
          const genderField = pdfDocument.getForm().getDropdown("dropdown_12tbys");
          const phoneField = pdfDocument.getForm().getTextField("text_1htbt");
          const nationalityField = pdfDocument.getForm().getTextField("text_2lkfd");
          const countryField = pdfDocument.getForm().getTextField("text_14gqys");
          const situationField = pdfDocument.getForm().getRadioGroup("radio_group_37rzdc");
          const isActivityField = pdfDocument.getForm().getRadioGroup("radio_group_68drcz");
          const activityNameField = pdfDocument.getForm().getTextField("text_66kbwq");
          const otherField = pdfDocument.getForm().getTextField("text_1nung");
          const socilaTaxField = pdfDocument.getForm().getRadioGroup("radio_group_1fgsy");
          const otherChiefField = pdfDocument.getForm().getRadioGroup("radio_group_73axhk");
          const nniField = pdfDocument.getForm().getTextField("text_34jxhh");
          const socialOrganismField = pdfDocument.getForm().getRadioGroup("radio_group_45munl");
          const isAeField = pdfDocument.getForm().getRadioGroup("radio_group_51dxxa");
          const otherActivityField = pdfDocument.getForm().getRadioGroup("radio_group_53iyyl");
          const fixAddressField = pdfDocument.getForm().getRadioGroup("radio_group_55qzhv");
          const homeSocityField = pdfDocument.getForm().getRadioGroup("radio_group_57wzmj");
          const activitiesOptionsField = pdfDocument.getForm().getTextField("text_44bwe");
          const otherChiefFirstNameField = pdfDocument.getForm().getTextField("text_71bthn");
          const otherChiefNameField = pdfDocument.getForm().getTextField("text_72flsj");
          const otherChiefNationalField = pdfDocument.getForm().getTextField("text_75ofjg");
          const otherChiefbirthCountryField = pdfDocument.getForm().getTextField("text_76knwj");
          const otherChiefgenderField = pdfDocument.getForm().getDropdown("dropdown_74dqgu");
          const firstResidenceField = pdfDocument.getForm().getRadioGroup("radio_group_59psyj");
          const commercialNameField = pdfDocument.getForm().getRadioGroup("radio_group_61dyou");
          const salariesField = pdfDocument.getForm().getRadioGroup("radio_group_63tvft");
          const domainNameField = pdfDocument.getForm().getRadioGroup("radio_group_65cldx");
          const paimentModeField = pdfDocument.getForm().getRadioGroup("radio_group_67ihup");
          const savePersonnalInfoField = pdfDocument.getForm().getRadioGroup("radio_group_71kfss");
          const saveSirenField = pdfDocument.getForm().getRadioGroup("radio_group_69lcym");
          const domainNamesecondSaveField = pdfDocument.getForm().getTextField("text_49atxb");
          const salariesNumberField = pdfDocument.getForm().getTextField("text_50qete");
          const firstSave = generalProcuration.first
          const secondSave = generalProcuration.second

          if (firstSave) {
            if (firstSave.firstName) {
              firstNameField1.setText(firstSave.firstName);
              firstNameField2.setText(firstSave.firstName);
            }
            if (firstSave.lastName && firstSave.lastName !== "") {
              birthNameField1.setText(firstSave.lastName);
              birthNameField2.setText(firstSave.lastName);
            }
            if (firstSave.birthDate && firstSave.birthDate !== "") {
              birthDateField1.setText(firstSave.birthDate);
              birthDateField2.setText(firstSave.birthDate);
            }
            if (firstSave.birthCity && firstSave.birthCity !== "") {
              cityBirthField1.setText(firstSave.birthCity);
              cityBirthField2.setText(firstSave.birthCity);
            }
            if (firstSave.address && firstSave.address !== "") {
              addressField1.setText(firstSave.address);
              addressField2.setText(firstSave.address);
            }
            if (firstSave.signCity && firstSave.signCity !== "") {
              signCityField.setText(firstSave.signCity);
            }
          }
          if (secondSave) {
            if (secondSave.first) {
              if (secondSave.first.phone && secondSave.first.phone !== "") {
                phoneField.setText(secondSave.first.phone);
              }
              if (secondSave.first.country && secondSave.first.country !== "") {
                countryField.setText(secondSave.first.country);
              }
              if (secondSave.first.nationality && secondSave.first.nationality !== "") {
                nationalityField.setText(secondSave.first.nationality);
              }
              if (secondSave.first.gender !== undefined) {
                switch (secondSave.first.gender) {
                  case 0:
                    genderField.select("Féminin");
                    break;
                  case 1:
                    genderField.select("Masculin");
                    break;

                  default:
                    genderField.select("Féminin");
                    break;
                }
              }
            }
            if (secondSave.second) {
              switch (secondSave.second) {
                case 1:
                  situationField.select("Value_zpyl");
                  break;
                case 2:
                  situationField.select("Value_stfi");
                  break;
                case 3:
                  situationField.select("Value_vvki");
                  break;
                case 4:
                  situationField.select("Value_gati");
                  break;
                case 5:
                  situationField.select("Value_qtvb");
                  break;
                case 6:
                  situationField.select("Value_qatz");
                  break;

                default:
                  situationField.select("Value_zpyl");
                  break;
              }
            }
            if (secondSave.third) {
              if (secondSave.third.isActivity !== undefined) {
                switch (secondSave.third.isActivity) {
                  case 0:
                    isActivityField.select("Value_vvog");
                    break;
                  case 1:
                    isActivityField.select("Value_adqg");
                    activityNameField.setText(secondSave.third.activityName)
                    break;
                  default:
                    isActivityField.select("Value_vvog");
                    break;
                }
              }
              if (secondSave.third.socialTax !== undefined) {
                switch (secondSave.third.socialTax) {
                  case 0:
                    socilaTaxField.select("Value_yibd");
                    break;
                  case 1:
                    socilaTaxField.select("Value_ruy");
                    break;
                  default:
                    socilaTaxField.select("Value_ruy");
                    break;
                }
              }
              if (secondSave.third.nni && secondSave.third.nni !== "") {
                nniField.setText(secondSave.third.nni);
              }
            }
            if (secondSave?.fourth?.value) {
              switch (secondSave?.fourth?.value) {
                case 1:
                  socialOrganismField.select("Value_elbp");
                  break;
                case 2:
                  socialOrganismField.select("Value_ccea");
                  break;
                case 3:
                  socialOrganismField.select("Value_uzwh");
                  break;
                case 4:
                  socialOrganismField.select("Value_mdl");
                  otherField.setText(secondSave.fourth.other);
                  break;
                case 5:
                  socialOrganismField.select("Value_qzzc");
                  break;
                case 6:
                  socialOrganismField.select("Value_hplx");
                  break;

                default:
                  socialOrganismField.select("Value_elbp");
                  break;
              }
            }
            if (secondSave.fifth !== undefined) {
              switch (secondSave.fifth) {
                case 0:
                  isAeField.select("Value_qmgq");
                  break;
                case 1:
                  isAeField.select("Value_dfii");
                  break;
                default:
                  isAeField.select("Value_qmgq");
                  break;
              }
            }
            if (secondSave.sixth) {
              if (secondSave.sixth.otherActivity !== undefined) {
                switch (secondSave.sixth.otherActivity) {
                  case 0:
                    otherActivityField.select("Value_ul");
                    break;
                  case 1:
                    otherActivityField.select("Value_zzea");
                    break;
                  default:
                    otherActivityField.select("Value_ul");
                    break;
                }
              }
              if (secondSave.sixth.activitiesOptions && secondSave.sixth.activitiesOptions !== "") {
                activitiesOptionsField.setText(secondSave.sixth.activitiesOptions);
              }
            }
            if (secondSave.seventh) {
              if (secondSave.seventh.fixAddress !== undefined) {
                switch (secondSave.seventh.fixAddress) {
                  case 0:
                    fixAddressField.select("Value_rtku");
                    break;
                  case 1:
                    fixAddressField.select("Value_plmt");
                    break;
                  default:
                    fixAddressField.select("Value_rtku");
                    break;
                }
              }
              if (secondSave.seventh.homeSocity !== undefined) {
                switch (secondSave.seventh.homeSocity) {
                  case 0:
                    homeSocityField.select("Value_giai");
                    break;
                  case 1:
                    homeSocityField.select("Value_zpxw");
                    break;
                  default:
                    homeSocityField.select("Value_giai");
                    break;
                }
              }
            }
            if (secondSave.eighth) {
              if (secondSave.eighth.otherChief) {
                otherChiefField.select("Value_dbb");
                if (secondSave.eighth.firstName && secondSave.eighth.firstName !== "") {
                  otherChiefFirstNameField.setText(secondSave.eighth.firstName);
                }
                if (secondSave.eighth.firstName && secondSave.eighth.firstName !== "") {
                  otherChiefNameField.setText(secondSave.eighth.firstName);
                }
                if (secondSave.eighth.firstName && secondSave.eighth.firstName !== "") {
                  otherChiefNationalField.setText(secondSave.eighth.firstName);
                }
                if (secondSave.eighth.firstName && secondSave.eighth.firstName !== "") {
                  otherChiefbirthCountryField.setText(secondSave.eighth.firstName);
                }
                if (secondSave.eighth.gender !== undefined) {
                  switch (secondSave.eighth.gender) {
                    case 0:
                      otherChiefgenderField.select("Féminin");
                      break;
                    case 1:
                      otherChiefgenderField.select("Masculin");
                      break;

                    default:
                      otherChiefgenderField.select("Féminin");
                      break;
                  }
                }
              } else {
                otherChiefField.select("Value_ausx");
              }
            }
            if (secondSave.ninth !== undefined) {
              switch (secondSave.ninth) {
                case 0:
                  firstResidenceField.select("Value_ntgw");
                  break;
                case 1:
                  firstResidenceField.select("Value_ypo");
                  break;

                default:
                  firstResidenceField.select("Value_ntgw");
                  break;
              }
            }
            if (secondSave.tenth) {
              if (secondSave.tenth.commercialName !== undefined) {
                switch (secondSave.tenth.commercialName) {
                  case 0:
                    commercialNameField.select("Value_sfkw");
                    break;
                  case 1:
                    commercialNameField.select("Value_gryw");
                    break;

                  default:
                    commercialNameField.select("Value_sfkw");
                    break;
                }
              }
              if (secondSave.tenth.salaries !== undefined) {
                switch (secondSave.tenth.salaries) {
                  case 0:
                    salariesField.select("Value_bnsk");
                    break;
                  case 1:
                    salariesField.select("Value_qpgv");
                    break;

                  default:
                    salariesField.select("Value_bnsk");
                    break;
                }
              }
              if (secondSave.tenth.salaryNumber !== undefined) {
                salariesNumberField.setText(secondSave.tenth.salaryNumber.toString());
              }
              if (
                secondSave.tenth.domainNamesecondSave &&
                secondSave.tenth.domainNamesecondSave !== "" &&
                secondSave.tenth.domainName === 1
              ) {
                domainNamesecondSaveField.setText(secondSave.tenth.domainNamesecondSave);
              } else {
                domainNamesecondSaveField.setText("");
              }
              if (secondSave.tenth.domainName !== undefined) {
                switch (secondSave.tenth.domainName) {
                  case 0:
                    domainNameField.select("Value_lwgq");
                    break;
                  case 1:
                    domainNameField.select("Value_boxp");
                    break;

                  default:
                    domainNameField.select("Value_lwgq");
                    break;
                }
              }
            }
            if (secondSave.eleventh) {
              if (secondSave.eleventh.paiementMode !== undefined) {
                switch (secondSave.eleventh.paiementMode) {
                  case 0:
                    paimentModeField.select("Value_byhs");
                    break;
                  case 1:
                    paimentModeField.select("Value_qaka");
                    break;

                  default:
                    paimentModeField.select("Value_byhs");
                    break;
                }
              }
              if (secondSave.eleventh.savePersonnalInfo !== undefined) {
                switch (secondSave.eleventh.savePersonnalInfo) {
                  case 0:
                    savePersonnalInfoField.select("Value_zbzj");
                    break;
                  case 1:
                    savePersonnalInfoField.select("Value_ltqx");
                    break;

                  default:
                    savePersonnalInfoField.select("Value_zbzj");
                    break;
                }
              }
              if (secondSave.eleventh.saveSiren !== undefined) {
                switch (secondSave.eleventh.saveSiren) {
                  case 0:
                    saveSirenField.select("Value_boeh");
                    break;
                  case 1:
                    saveSirenField.select("Value_lpyp");
                    break;

                  default:
                    saveSirenField.select("Value_boeh");
                    break;
                }
              }
            }
          }
          if (generalProcuration.step) {
            setStepSecond(generalProcuration.step);
          }

        }
      }
    })();

  }, [generalProcuration])
  useEffect(() => {
    if (generalTeachr) {
      if (generalTeachr.teachr) {
        if (generalTeachr.teachr.proofIsAE || generalTeachr.teachr.proofSignSap) {
          if (generalTeachr.teachr.proofIsAE) {
            setDownloadLink(generalTeachr.teachr.proofIsAE.contentUrl);
          } else {
            setDownloadLink(generalTeachr.teachr.proofSignSap.contentUrl);
          }
          setCurrentStep(6);
          dispatch({
            type: 'SET_AE_STEP', payload: 6
          })
        } else {
          dispatch({
            type: 'SET_AE_STEP', payload: 0
          })
        }
        setTeachr(generalTeachr.teachr);
      } else {
        navigate("/teachr/login");
      }
    } else {
      navigate("0");
    }
  }, [generalTeachr]);

  useEffect(() => {
    (async () => {
      if (pdfDoc) {
        const pdfBytes = await pdfDoc.save();
        const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
        setFile(pdfBytes);
        const pdfUrl = URL.createObjectURL(pdfBlob);
        setPdfFile(pdfUrl);
      }
    })();
  }, [pdfDoc, isSignText])


  const nextStep = async (secondSave: number) => {
    if (secondSave <= 3) {
      if (secondSave === 3) {
        if (proofIentity && teachr) {
          setLoading(true);
          ImageRepository.createImage(new File([proofIentity], "proof-is-ae-identity", { type: "application/pdf" })).then((response) => {
            teachr.proofIdentity = response["@id"];
            if (teachr.proofSignSap) {
              teachr.proofSignSap = teachr.proofSignSap["@id"];
            }
            if (teachr && teachr.proofIsAE) {
              teachr.proofIsAE = teachr.proofIsAE['@id']
            }
            if (teachr && teachr.proofMandat) {
              teachr.proofMandat = teachr.proofMandat['@id']
            }
            TeachrRepository.updateTeachr(teachr).then((response) => {
              dispatch({
                type: "SET_TEACHR_INFO",
                payload: {
                  teachr: response
                },
              });
              dispatch({
                type: 'SET_AE_STEP', payload: secondSave
              })
              setCurrentStep(secondSave);
              setPage(secondSave);
              setLoading(false);
            }).catch((err) => {
                console.warn(err)
              })
          }).catch((error) => {
            if (error?._description === "Invalid filename provided") {
              dispatch({
                type: "SHOW_ERROR",
                payload:
                  "Votre fichier est trop volumineux. Merci de le compresser avant de continuer.",
              });
            }
            dispatch({
              type: "SHOW_ERROR",
              payload:
                "Votre fichier est trop volumineux. Merci de le compresser avant de continuer.",
            });
            setLoading(false);
          });
          if (proofIentity2) {
            ImageRepository.createImage(new File([proofIentity2], "proof-is-ae-identity2", { type: "application/pdf" })).then((response2) => {
              teachr.proofIdentity2 = response2["@id"];
              if (teachr.proofSignSap) {
                teachr.proofSignSap = teachr.proofSignSap["@id"];
              }
              if (teachr && teachr.proofIsAE) {
                teachr.proofIsAE = teachr.proofIsAE["@id"];
              }
              if (teachr && teachr.proofMandat) {
                teachr.proofMandat = teachr.proofMandat["@id"];
              }
              TeachrRepository.updateTeachr(teachr).then((response) => {
                dispatch({
                  type: "SET_TEACHR_INFO",
                  payload: {
                    teachr: response
                  },
                });
                dispatch({
                  type: 'SET_AE_STEP', payload: secondSave
                })
                setCurrentStep(secondSave);
                setPage(secondSave);
                setLoading(false);
              }).catch((err) => {
                console.warn(err)
              })

            }).catch((error) => {
              if (error?._description === "Invalid filename provided") {
                dispatch({
                  type: "SHOW_ERROR",
                  payload:
                    "Le fichier supplémentaire que vous avez ajouté est trop volumineux. Merci de le compresser avant de continuer.",
                });
              }
              dispatch({
                type: "SHOW_ERROR",
                payload:
                  "Le fichier supplémentaire que vous avez ajouté est trop volumineux. Merci de le compresser avant de continuer.",
              });
              setLoading(false);
            });
          }
        }
      } else {
        dispatch({
          type: 'SET_AE_STEP', payload: secondSave
        })
        setCurrentStep(secondSave);
        setPage(secondSave);
      }
    } else {
      if (secondSave >= 5) {
        if (secondSave === 6) {
          if (file && teachr && isSign && isSignText) {
            setLoading(true);
            ImageRepository.createImage(
              new File([file], "proof-is-ae", { type: "application/pdf" })
            ).then((response) => {
                if (teachr.proofIdentity) {
                  teachr.proofIdentity = teachr.proofIdentity['@id']
                }
                if (teachr.proofIdentity2) {
                  teachr.proofIdentity2 = teachr.proofIdentity2['@id']
                }
                if (teachr.proofSignSap) {
                  teachr.proofSignSap = teachr.proofSignSap['@id']
                }
                if (teachr && teachr.proofMandat) {
                  teachr.proofMandat = teachr.proofMandat['@id']
                }
                teachr.proofIsAE = response["@id"];
                TeachrRepository.updateTeachr(teachr).then((teachrResponse) => {
                  dispatch({
                    type: "SET_TEACHR_INFO",
                    payload: {
                      teachr: teachrResponse,
                    },
                  });
                  dispatch({
                    type: 'SET_AE_STEP', payload: secondSave
                  })
                  setCurrentStep(secondSave);
                  setLoading(false);
                }).catch((error) => {
                  console.warn(error);
                  setLoading(false);
                })
              })
              .catch((error) => {
                console.warn(error)
                setLoading(false);
              });
          }
        } else {
          setPage(1);
          dispatch({
            type: 'SET_AE_STEP', payload: secondSave
          })
          setCurrentStep(secondSave);
        }
      } else {
        dispatch({
          type: 'SET_AE_STEP', payload: secondSave
        })
        setCurrentStep(secondSave);
        setPage(secondSave + 1);
      }
    }
  };
  const nextPage = (secondSave: number) => {
    setPage(secondSave);
  };
  const getSignature = (secondSave: any) => {
    setSignUrl(secondSave);
  };
  const getSignatureText = (secondSave: any) => {
    setSignText(secondSave);
  };
  const getProofIdentity = (secondSave: any) => {
    setProofIdentity(secondSave);
  };
  const getProofIdentity2 = (secondSave: any) => {
    setProofIdentity2(secondSave);
  };

  useEffect(() => {
    (async () => {
      if (pdfDoc) {
        const pages = pdfDoc.getPages();
        const currentPage = pages[0];
        const { width, height } = currentPage.getSize();

        if (!isSign) {
          if (signUrl) {
            const dateField = pdfDoc.getForm().getTextField("text_8ixqv");
            dateField.setText(moment().format("L"));
            const imageBytes1 = await fetch(signUrl).then((res) =>
              res.arrayBuffer()
            );
            const image1 = await pdfDoc.embedPng(imageBytes1);
            const imageBytes2 = await fetch(mandataireSign).then((res) =>
              res.arrayBuffer()
            );
            const image2 = await pdfDoc.embedPng(imageBytes2);
            let imageWidth = 100;
            let imageHeight = 30;
            currentPage.drawImage(image1, {
              x: imageWidth + 20,
              y: 90,
              width: imageWidth,
              height: imageHeight,
            });
            imageWidth = 230;
            imageHeight = 40;
            currentPage.drawImage(image2, {
              x: width - imageWidth - 10,
              y: 80,
              width: imageWidth,
              height: imageHeight,
            });
            setIsSign(true);

          }
        }
      }
    })();
  }, [signUrl]);
  useEffect(() => {
    (async () => {
      if (pdfDoc) {
        const pages = pdfDoc.getPages();
        const currentPage = pages[0];
        if (!isSignText) {
          if (signText) {
            const imageBytes = await fetch(signText).then((res) =>
              res.arrayBuffer()
            );
            const image = await pdfDoc.embedPng(imageBytes);
            const imageWidth = 100;
            const imageHeight = 20;
            currentPage.drawImage(image, {
              x: 10,
              y: imageWidth,
              width: imageWidth,
              height: imageHeight,
            });
            setPdfDoc(pdfDoc)
            setIsSignText(true);
          }
        }
      }
    })();
  }, [signText]);
  const getInfos = () => {

  }
  const renderScreen = () => {
    switch (currentStep) {
      case 0:
        if (teachr && teachr.proofIsAE) {
          return <Seventh file={downloadLink} callBack={nextStep} />;
        } else {
          return <First callBack={nextStep} />;
        }
      case 1:
        return (
          <Second prevStep={prevStep} sendData={getInfos} callBack={nextStep} />
        );
      case 2:
        if (teachr && teachr.proofIdentity !== null) {
          dispatch({
            type: 'SET_AE_STEP', payload: 3
          })
          setCurrentStep(3);
          setPage(3);
          break;
        } else {
          return (
            <Third prevStep={prevStep} loading={loading} getProofIdentity={getProofIdentity} getProofIdentity2={getProofIdentity2} callBack={nextStep} />
          );
        }
      case 3:
        return (
          <FouthIndex prevStep={getPrevStep} nextPage={nextPage} sendData={getInfos} callBack={nextStep} ref={childRef} />
        );
      case 4:
        if (pdfFile) {
          return (<FiveIndex file={pdfFile} prevStep={prevStep} nextPage={nextPage} callBack={nextStep} />
          );
        }
        break
      case 5:
        return (
          <Sixth loading={loading} prevStep={prevStep} getSignatureText={getSignatureText} getSignature={getSignature} callBack={nextStep} />
        );
      case 6:
        return <Seventh file={downloadLink} callBack={nextStep} />;
      default:
        return <First prevStep={prevStep} callBack={nextStep} />;
    }
  };
  const getPrevStep = (secondSave: number) => {
    setCurrentStep(secondSave)
  };
  const prevStep = () => {
    if (stepSecond && stepSecond > 1) {
      if (childRef.current) {
        childRef.current.handleBack();
      }
    } else {
      if (currentStep > 0 && currentStep < 6) {
        if (currentStep === 3) {
          if (teachr && teachr.proofIdentity !== null) {
            setCurrentStep(currentStep - 2);
          } else {
            setCurrentStep(currentStep - 1);
          }
        } else {
          setCurrentStep(currentStep - 1);
        }
      }
    }
  };

  return (
    <div className="h-screen lg:overflow-hidden overflow-y-auto bg-[#F5FAFF] lg:bg-white">
      <header className="lg:bg-white bg-[#F5FAFF]">
        <NavBar active={3} />
      </header>
      <div className="lg:h-full pb-14 lg:flex bg-[#F5FAFF] lg:bg-white">
        <div className='lg:h-full px-4 lg:pt-10 lg:pb-40 lg:w-1/4 md:px-8 lg:px-10'>
          <h1 className='text-first font-extrabold lg:text-[22px] mb-8 text-lg'>ESPACE PROCURATION 📄</h1>
          <Stepper current={currentStep} />
        </div>
        <div className="w-full overflow-y-scroll overflow-x-hidden bg-[#F5FAFF] px-4 md:px-8 lg:px-10 pt-10">
          <div className="lg:flex justify-between hidden">
            {currentStep > 0 &&
              <div
                className="flex items-center cursor-pointer gap-x-4"
                onClick={prevStep}
              >
                <span>
                  <svg
                    width="39"
                    height="34"
                    viewBox="0 0 39 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      width="39"
                      height="34"
                      rx="8"
                      fill="#219CFF"
                      fill-opacity="0.14"
                    />
                    <path
                      d="M31 17.85C31.4694 17.85 31.85 17.4694 31.85 17C31.85 16.5306 31.4694 16.15 31 16.15L31 17.85ZM10.399 16.399C10.067 16.7309 10.067 17.2691 10.399 17.601L15.8083 23.0104C16.1403 23.3424 16.6785 23.3424 17.0104 23.0104C17.3424 22.6785 17.3424 22.1403 17.0104 21.8083L12.2021 17L17.0104 12.1917C17.3424 11.8597 17.3424 11.3215 17.0104 10.9896C16.6785 10.6576 16.1403 10.6576 15.8083 10.9896L10.399 16.399ZM31 16.15L11 16.15L11 17.85L31 17.85L31 16.15Z"
                      fill="#0054A3"
                    />
                  </svg>
                </span>
                <span className="text-first font-bold lg:text-[22px]">
                  Précédent
                </span>
                <span></span>
              </div>
            }
          </div>
          <div className='lg:py-10'>
            <div className='flex justify-between lg:gap-10 gap-y-4 flex-col lg:flex-row'>
              {currentStep !== 0 &&
                <div className='w-max hidden lg:block'>
                  <Document
                    error='Impossible de charger le document.'
                    // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                    file={pdfFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      // renderMode='none'
                      pageNumber={page}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </Document>
                </div>
              }
              <div className={currentStep !== 0 ? 'lg:w-1/2' : 'w-full'}>
                {renderScreen()}
              </div>
              {currentStep !== 0 ?
                <div className='lg:hidden text-center'>
                  <p onClick={() => setShowDoc(!showDoc)} className='underline border-radio text-radio font-bold text-lg  py-2'>{showDoc ? 'Fermer le document' : 'Voir les modifications sur le document'}</p>
                  <div className={`${loading ? 'block' : 'hidden'} flex items-center gap-x-2 justify-center`}>
                    <span className="animate-spin">
                      <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2.67991 14.238C2.13795 14.7791 2.13795 15.6569 2.67991 16.1984C3.22235 16.7398 4.10123 16.7398 4.6432 16.1984C5.18516 15.6569 5.18516 14.779 4.6432 14.238C4.10123 13.696 3.22238 13.696 2.67991 14.238Z" fill="#808080" />
                        <path d="M3.27912 10.5341C3.27912 9.67331 2.57994 8.97412 1.71769 8.97412C0.855438 8.97412 0.15625 9.67331 0.15625 10.5341C0.15625 11.3959 0.855438 12.0941 1.71769 12.0941C2.57994 12.0941 3.27912 11.396 3.27912 10.5341Z" fill="#808080" />
                        <path d="M3.65666 4.11816C2.69869 4.11816 1.92188 4.89404 1.92188 5.85151C1.92188 6.80851 2.69869 7.58435 3.65666 7.58435C4.61509 7.58435 5.39144 6.80851 5.39144 5.85151C5.39141 4.89401 4.61509 4.11816 3.65666 4.11816Z" fill="#808080" />
                        <path d="M8.34566 2C7.292 2 6.4375 2.85397 6.4375 3.90669C6.4375 4.95937 7.29194 5.81334 8.34566 5.81334C9.40028 5.81334 10.2542 4.95937 10.2542 3.90669C10.2547 2.85397 9.40028 2 8.34566 2Z" fill="#808080" />
                        <path d="M8.34662 15.5728C7.67625 15.5728 7.13281 16.1157 7.13281 16.7866C7.13281 17.456 7.67628 17.9995 8.34662 17.9995C9.0175 17.9995 9.56141 17.456 9.56141 16.7866C9.56141 16.1157 9.0175 15.5728 8.34662 15.5728Z" fill="#808080" />
                        <path d="M14.9765 9.66748C14.4976 9.66748 14.1094 10.0551 14.1094 10.5341C14.1094 11.0131 14.4976 11.4008 14.9765 11.4008C15.456 11.4008 15.8446 11.0131 15.8446 10.5341C15.8446 10.0551 15.456 9.66748 14.9765 9.66748Z" fill="#808080" />
                        <path d="M12.2969 14.4824C11.8906 14.8881 11.8906 15.5468 12.2969 15.9531C12.7036 16.3593 13.3622 16.3593 13.7694 15.9531C14.1757 15.5468 14.1757 14.8882 13.7694 14.4824C13.3622 14.0762 12.7036 14.0762 12.2969 14.4824Z" fill="#808080" />
                        <path d="M13.1001 6.47799C13.4838 6.47799 13.7944 6.16792 13.7944 5.78464C13.7944 5.40183 13.4838 5.09127 13.1001 5.0918C12.7173 5.0918 12.4058 5.40183 12.4063 5.78464C12.4063 6.16792 12.7173 6.47799 13.1001 6.47799Z" fill="#808080" />
                      </svg>
                    </span>

                    <span className='text-[#808080] font-medium text-sm'>Enregistrement automatique</span>
                  </div>
                </div> : null
              }
              {currentStep !== 0 ?
                <div className={`${showDoc ? 'block' : 'hidden'} w-full lg:hidden`}>
                  <Document
                    error='Impossible de charger le document.'
                    // loading = {<Skeleton sx={{ bgcolor: 'rgba(217,217,217,0.58)' }} animation="wave" variant="rectangular" width={793.7} height={1122} />} 
                    file={pdfFile}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      // renderMode='none'
                      pageNumber={page}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={windowWidth}
                    />
                  </Document>
                </div> : null
              }
            </div>
          </div>
        </div>
      </div>
      {/* <div className="lg:hidden pb-14 relative h-full px-4 md:px-8">
        <div className="absolute top-1/3">
          <h1 className="text-first font-extrabold text-lg text-center">
            Cette Version n'est pas disponible sur des petits écrans
          </h1>
        </div>
      </div> */}
    </div>
  );
};

export default Index;
