import picture from "../../../Assets/Images/picture.png";
import toch from "../../../Assets/Images/toch.png";
import money from "../../../Assets/Images/money.png";
// import { useNavigate } from "react-router-dom";
// import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';

// const StyledRating = styled(Rating)({
//   '& .MuiRating-iconFilled': {
//     color: '#ff6d75',
//   },
//   '& .MuiRating-iconHover': {
//     color: '#ff3d47',
//   },
// });


function Response() {
  // const navigate = useNavigate();
  // const handleChange = () => {
  //   navigate("/profileteachr")
  // };
  

  return (
    <div className="rounded-lg shrink-0 md:w-[315px] w-[98%] md:ml-0 ml-1 bg-bg-color-tuile border-grey p-4  shadow-res">
      <div className="md:-translate-y-16 -translate-y-14 md:h-20 w-20 md:mx-auto ml-16 rounded-full ">
      <img src={picture} alt=""  className="md:h-20 md:w-20 h-14 w-14  rounded-full"/>
      </div>
      <div className="md:-mt-14 -mt-12   mx-auto">
        <div className="md:mx-auto  ml-8   md:pl-2 w-1/2">
        <Rating name="half-rating-read" defaultValue={5} precision={0.1} readOnly />
        
   
        </div>
        <div className="">
          <p className="text-center text-[10px]">20 heures²</p>
        </div>
      </div>
      <div className="flex space-x-4">
        <div>
          <img src={toch} alt="" className="md:w-10 w-8 "/>
        </div>
        <div className="">
          <p className="md:text-[16px] text-sm  text-dark_blue font-semibold">
            HEC
          </p>
          <div className="md:text-[14px] text-sm -translate-y-4 text-grey">
            <span>Michelle</span>
          </div>
        </div>
      </div>
      <div className="flex space-x-4">
        <div>
          <img src={money} alt="" className="md:w-10 w-8 "/>
        </div>
        <div className="">
          <p className="md:text-lg  text-sm text-dark_blue font-semibold">
            Prix par séance : 110 €
          </p>
          <div className="md:text-base text-sm -translate-y-4 text-grey">
            <span>Soit après crédit d’impôt : 110 € </span>
          </div>
        </div>
      </div>
      <div className="flex text-[14px]">
        <div>
          <p className="text-dark_blue ">Message de candidature :</p>
          <p className="text-grey leading-[19px]  -translate-y-2 md:h-full h-20 overflow-hidden" >
            Lorem ipsum, consequatur deleniti, aperiam maiores quae fugiat beatae eveniet debitis error quo vel nam! Non.
          </p>
        </div>
      </div>
      <div className="flex -translate-y-2 justify-around">
        <div>
          <button
            // onClick={() => handleChange()}
            className="border-[1px] p-1 md:text-[16px] translate-y-4 text-sm text-btn-color-tuile px-4 border-btn-color-tuile font-bold active:text-white active:bg-btn-color-tuile active:border-btn-color-tuile  rounded-full"
          >
            En savoir plus
          </button>
        </div>
      </div>
    </div>
  );
}

export default Response;

