import React from 'react';
import teachrImg from '../../../../Assets/Images/teachr-ae.svg'
import studentImg from '../../../../Assets/Images/student-ae.svg'
import booksImg from '../../../../Assets/Images/books-ae.svg'
const First = () => {
    return (
        <div className='flex flex-col gap-y-8 '>
            <h3 className='p-2 text-lg font-bold bg-white lg:text-[22px] text-first'>1. Le seul moyen d’accéder à votre tableau de bord </h3>
            <p className='text-base font-medium text-first lg:text-xl'>En effet pour pouvoir profiter du Tableau de bord et de faire bénéficier de cours à moitié prix pour vos élèves vous devez obtenir un statut juridique (auto entrepreneur, SARL, SAS ...). </p>
            <h4 className='text-base font-bold text-first lg:text-xl'>Le Tableau de bord comment ça marche ? </h4>
            <div className='flex flex-col gap-y-4'>
                <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8'>
                    <div>
                        <img src={teachrImg} alt="" />
                    </div>
                    <div className='text-sm text-first lg:text-lg'>
                        <p className='font-bold'>1. Créez votre compte professeur</p>
                        <p className='font-semibold'>Après avoir finalisé votre compte, accédez à votre tableau de bord</p>
                    </div>
                </div>
                <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8'>
                    <div>
                        <img src={studentImg} alt="" />
                    </div>
                    <div className='text-sm text-first lg:text-lg'>
                        <p className='font-bold'>2. Ajoutez votre premier élève et son parent </p>
                        <p className='font-semibold'>Ajouter les informations de votre client, celui-ci finalise son inscription, vous êtes ensuite alerté des la validation de ses informations</p>
                    </div>
                </div>
                <div className='flex p-3 bg-[rgba(255,255,255,0.62)] rounded-[10px] gap-x-8'>
                    <div>
                        <img src={booksImg} alt="" />
                    </div>
                    <div className='text-sm text-first lg:text-lg'>
                        <p className='font-bold'>3.  Déclarez un cours</p>
                        <p className='font-semibold'>Indiquer les information de votre cours. <br />
                            Le parent reçoit une demande de confirmation et valide votre demande.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default First;