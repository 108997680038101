import { Type } from "class-transformer";
import "reflect-metadata";
import DisplayableCourse from "../interfaces/DisplayableCourse";
import HydraEntity from "./HydraEntity";
import CourseProposal from "./CourseProposal";
import AvailablePromotionalCode from "./AvailablePromotionalCode";
import { Subject } from "./Subject";
import Child from "./Child";
import Client from "./Client";

declare class ReviewClient {}

export class PunctualCourseDashboard extends HydraEntity implements DisplayableCourse {
  duration?: number | string;
  @Type(() => Subject)
  // subjectAsked: string;
  subjectAsked_id?: number;

  /**
   * The child associated to the course
   */
  @Type(() => Child)
  forChild?: string;
  forChild_id?: number;

  /**
   * The client associated to the course
   */
  @Type(() => Client)
  forClient?: string;
  forClient_id?: number;

  clientReview?: ReviewClient;

  /**
   * If the fiscal reduction is asked or not.
   */
  isFiscalReductionEnabled?: boolean;
  /**
   * If the course is in the hour or not.
   */
  isCourseInTheHour?: boolean;
  /**
   * If the course is direct or not (destined to a specific Teach’r).
   */
  isDirectCourse?: boolean;
  //isParent?:boolean
  /**
   * The subject of the course.
   * See list of available subjects
   * @link https://github.com/baudev/TeachR_API/blob/develop/src/Enum/SubjectEnum.php
   */
  /**
   * The chapter of the course.
   */
  chapter?: string;
  teachrPrice?: string | any;
  subjectAskedObject?: any;
  allCourse?: boolean;
  /**
   * The start datetime of the course.
   */
  // @Type(() => Date)
  startDateTime?: Date | string;
  startDatetime?: Date | string;

  // startDatetime?: Date | string;
  endDatetime?: Date | string;
  /**
   * The end datetime of the course.
   */
  // @Type(() => Date)
  endDateTime?: Date | string;
  /**
   * The preferred teach\r sex.
   * See list of available sexs
   * @link https://github.com/baudev/TeachR_API/blob/develop/src/Enum/Sex.php
   */
  sexPreference?: string;
  /**
   * Extra information concerning the course (the chapter).
   */
  chapterExtraInformation?: string;
  /**
   * Extra information concerning the course in global.
   */
  globalExtraInformation?: string;
  /**
   * Address where the course takes place.
   * @id of the address
   */
  address?: string;

  address_id?: number;

  /**
   * The home assignment image.
   * @id of the image
   */
  homeAssignment?: string; // TODO see how to handle file upload

  /**
   * The home assignment image.
   * @id of the teach'r
   */
  directAskedTeachr?: string;
  // chapterExtraInformation?: string;
  // globalExtraInformation?: string;

  teachr_id?: number;
  isDirectCourseRefused?: boolean;
  isDirectCourseRefusedReason?: string;

  /**
   * If the course is remote or not.
   */
  isRemote?: boolean = false;

  /**
   * The payment mean for the client.
   */
  paymentMean?: "CASH" | "CARD" | "CESU";

  teachrProposals?: Array<CourseProposal>;
  /**
   * The promotional code applied to the course.
   */
  @Type(() => AvailablePromotionalCode)
  availablePromotionalCode?: string | AvailablePromotionalCode;

  @Type(() => CourseProposal)
  acceptedProposal?: CourseProposal; // | string;
  acceptedProposal_id?: number; // | string;

  // Concerning the Stripe payment
  paymentIntentId?: string;
  paymentIntentClientSecretId?: string;
  paymentIntentNeedAuthentification?: string;
  forChildFirstName?: string;
  forChildLastName?: string;
  subjectAsked?: string;
  aiciActivated?: boolean;
  isNew?: boolean;
  // urssafServiceStatus?:any;

  isPunctualMonth?: boolean;
  isPassedCourse?: boolean;

  id?: number;
  @Type(() => Date)
  readonly lastModificationDatetime?: Date;
  @Type(() => Date)
  readonly creationDatetime?: Date;
  readonly "@id"?: string;
  readonly "@type"?: string;
  readonly "@context"?: string;

  isNewDate?: boolean; // only used to display PunctualCourse in front, doesn't exist in db

  constructor(
    forChild_id: number,
    chapter: string,
    address_id: number,
    subjectAsked_id: number,
    startDateTime: Date | string,
    endDateTime: Date | string,
    isRemote?: boolean,
    teachr_id?: number,
    isCourseInTheHour?: boolean,
    duration?: number | string,
    isDirectCourse?: boolean,
    isFiscalReductionEnabled?: boolean,
    paymentMean?: "CASH" | "CARD" | "CESU",
    aiciActivated?: boolean,
    chapterExtraInformation?: string,
    globalExtraInformation?: string
  ) {
    super();
    this.forChild_id = forChild_id;
    this.chapter = chapter;
    this.address_id = address_id;
    this.subjectAsked_id = subjectAsked_id;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.isRemote = isRemote;
    this.teachr_id = teachr_id;
    this.isCourseInTheHour = isCourseInTheHour;
    this.duration = duration;
    this.isDirectCourse = isDirectCourse;
    this.isFiscalReductionEnabled = isFiscalReductionEnabled;
    this.paymentMean = paymentMean;
    this.aiciActivated = aiciActivated;
    this.chapterExtraInformation = chapterExtraInformation;
    this.globalExtraInformation = globalExtraInformation;
  }
}
