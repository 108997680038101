import React, { useEffect, useState } from "react";
import iconGoogle from "../../../Assets/Images/search 2.png";
import FirstImage from "../../../Assets/Images/FirstImage (1).png";
import Loader from "../../../Components/utils/Loader";
import {
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import validateName from "../../../Validations/Name";
import validateFirstName from "../../../Validations/FirstName";
import validatePhoneNumber from "../../../Validations/Number";
import validateEmail from "../../../Validations/Email";
import errorIcon from "../../../Assets/Images/error.png";
import {
  auth,
  FirebaseAuthServiceErrorCode,
  getJWTToken,
  registerWithEmailAndPassword,
  signInWithGoogle,
} from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import validatePassword from "../../../Validations/Password";
import {
  browserSessionPersistence,
  UserCredential,
} from "firebase/auth";

import ClientRepository from "../../../Repositories/ClientRepository";
import Client from "../../../Objects/models/Client";
import ActionInterface from "../../../Objects/interfaces/ActionInterface";
import StepperStore from "../../../LocalForage/StepperStore";
import ApiService from "../../../Services/ApiService";
import NavBar from "../../../Components/Navbar/NavBar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

function SignUp(props: { dispatch: (arg0: ActionInterface) => void }) {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [terms, setTerms] = useState(false);
  const [termsTeachr, setTermsTeachr] = useState(false);
  const [termsMarket, setTermsMarket] = useState(false);
  const [remember, setRemember] = useState(false);

  useEffect(() => {
    if (user) {
      //navigate("/user/welcome");
    }
  }, [user]);

  const Swal = require("sweetalert2");
  let timerInterval: any;
  const [form, setForm] = useState({
    name: "",
    firstName: "",
    phone: "+33",
    email: "",
    password: "",
    cPassword: "",
  });
  const [error, setError] = useState({
    name: "null",
    firstName: "null",
    phone: "null",
    email: "null",
    terms: "null",
    termsTeachr: "null",
    password: "null",
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const togglePasswordVisiblity2 = () => {
    setPasswordShown2(passwordShown2 ? false : true);
  };

  const validateTerms = (value: any) => {
    if (!value) {
      return {
        isValid: false,
        message: "Veuillez accepter les conditions d'utilisation",
      };
    } else
      return {
        isValid: true,
        message: "",
      };
  };

  const validate = () => {
    let erremail = true;
    let errname = true;
    let errfirstName = true;
    let errphone = true;
    let errterms = true;
    let errtermsTeachr = true;
    let errpassword = true;

    if (!validateEmail(form.email).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      erremail = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      erremail = false;
    }
    if (!validateName(form.name).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errname = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errname = false;
    }
    if (!validateFirstName(form.firstName).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errfirstName = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errfirstName = false;
    }
    if (!validatePhoneNumber(form.phone).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errphone = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errphone = false;
    }
    if (!validateTerms(terms).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errterms = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errterms = false;
    }
    if (!validateTerms(termsTeachr).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errtermsTeachr = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errtermsTeachr = false;
    }
    if (!validatePassword(form.password, form.cPassword).isValid) {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errpassword = true;
    } else {
      setError({
        email: validateEmail(form.email).message,
        name: validateName(form.name).message,
        firstName: validateFirstName(form.firstName).message,
        phone: validatePhoneNumber(form.phone).message,
        terms: validateTerms(terms).message,
        termsTeachr: validateTerms(termsTeachr).message,
        password: validatePassword(form.password, form.cPassword).message,
      });
      errpassword = false;
    }

    if (
      errname ||
      errfirstName ||
      errphone ||
      erremail ||
      errterms ||
      errpassword ||
      errtermsTeachr
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleFirbaseAuth = async (userCredential: UserCredential) => {
    let jwtToken = userCredential.user.getIdToken();
    let user = userCredential.user;
    var level_ = undefined; //TODO condition if parent
    let client = new Client(
      await jwtToken,
      form.firstName,
      form.name,
      form.phone,
      terms,
      "firebaseMessagingToken", // TODO registrationToken
      true, // TODO parent/child
      false,
      level_
    );
    client.isRelationTeachr = true;
    if (form.email) {
      client.mail = form.email;
    }
    ClientRepository.createClient(client)
      .then((response) => {
        getJWTToken(user).then((token) => {
    
          ApiService.enableAuth(undefined, undefined, "client " + token);
          navigate("/user/welcome");
        });
        // ApiService.enableAuth(client, user, undefined);
        // props.dispatch(userClientSetAccountAction(client));
      })
      .catch((error) => {
        console.warn(error);
      });
      
  };
  const registerWithGoogle = async () => {
    try {
      await signInWithGoogle();
      navigate("/user/completeprofile");
    } catch (error) {
      console.warn(error);
    }
  };

  const createUser = () => {
    registerWithEmailAndPassword(form.email, form.password)
      .then((request) => {
        if (request) {
          return handleFirbaseAuth(request);
        }
      })
      .catch((error: any) => {
        // ApiService.disableAuth();
        document.getElementById("teachr-loader")?.classList.add("hidden");
        if (error.code === FirebaseAuthServiceErrorCode.EMAIL_ALREADY_UN_USE) {
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: "L'adresse mail est déjà utilisée. Un mail a été renvoyé.",
            timer: 2000,
            willClose: () => {
              clearInterval(timerInterval);
            },
          });
        }
      });
  };

  const handleSubmit = (event: any) => {
    if (validate()) {
      StepperStore.setItem(
        user ? "user" + user?.uid : "user",
        {
          name: form.name,
          firstName: form.firstName,
          phone: form.phone,
          email: form.email,
          //password: form.password,
          //cPassword: form.cPassword,
        },
        function () {}
      );
      document.getElementById('teachr-loader')?.classList.remove('hidden')
      // createUser();
      if (remember) {
        createUser()
      } else {
        auth.setPersistence(browserSessionPersistence).then(() => {
          createUser()
        })
      }
      // auth
      //   .setPersistence(
      //     remember ? indexedDBLocalPersistence : browserSessionPersistence
      //   )
      //   .then(() => {
      //     document.getElementById("teachr-loader")?.classList.remove("hidden");
      //   });
      StepperStore.clear();
    }
    event.preventDefault();
  };

  return (
    <div className="bg-[#004066] md:overflow-hidden  relative h-full md:h-screen font-nunito">
      <div className="z-40 w-full bg-white  //-mt-4 first-letter: mt lg:bg-transparent">
        <Loader />
        <NavBar theme="dark" />
      </div>
      <div className=" lg:-mt-6 xl:mt-0 md:mt-0 //translate-y-16">
        <p className="w-4/5 mt-3 ml-10 text-xl font-bold text-white lg:text-3xl md:w-full md:text-2xl lg:mt-0 md:mt-0 md:ml-24 font-nunito">
          Réserver un cours particulier n’a jamais été aussi simple
        </p>
        <div className="block mt-10 ml-10 md:flex md:ml-24">
          <div className=" md:w-1/2">
            <p className="pb-2 text-sm font-bold text-white -translate-y-6 lg:text-xl md:text-lg">
              S'inscrire en un clic !
            </p>

            <button
              onClick={() => registerWithGoogle()}
              className="flex items-center justify-center px-4 py-2 mb-4 -mt-4 text-black no-underline bg-white rounded-md //lg:-mt-10 gap-x-3 w-max"
            >
              <img src={iconGoogle} alt="" /> S’inscrire avec Google
            </button>
            <p className="mb-8 text-white">
              Ou remplir les champs avec vos informations
            </p>
            <div className="flex flex-col pr-10 -mt-4 gap-x-2 gap-y-4">
              <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="">Prénom</label>
                    <input
                      type="text"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : John"
                      onChange={(e) =>
                        setForm({
                          name: form.name,
                          firstName: e.target.value,
                          phone: form.phone,
                          email: form.email,
                          password: form.password,
                          cPassword: form.cPassword,
                        })
                      }
                    />
                  </div>
                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                    {error.firstName === "null" || error.firstName === ""
                      ? " "
                      : // <img src={errorIcon} alt="" className="w-4 h-4" />
                        ""}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.firstName === "null" || error.firstName === ""
                        ? null
                        : error.firstName}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="">Nom</label>
                    <input
                      type="text"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : Pierre"
                      onChange={(e) =>
                        setForm({
                          name: e.target.value,
                          firstName: form.firstName,
                          phone: form.phone,
                          email: form.email,
                          password: form.password,
                          cPassword: form.cPassword,
                        })
                      }
                    />
                  </div>
                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                    {error.name === "null" || error.name === "" ? (
                      " "
                    ) : (
                      <img src={errorIcon} alt="" className="w-4 h-4" />
                    )}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.name === "null" || error.name === ""
                        ? null
                        : error.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="">Adresse email</label>
                    <input
                      type="email"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : mail@gmail.com"
                      onChange={(e) =>
                        setForm({
                          name: form.name,
                          firstName: form.firstName,
                          phone: form.phone,
                          email: e.target.value,
                          password: form.password,
                          cPassword: form.cPassword,
                        })
                      }
                    />
                  </div>
                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                    {error.email === "null" || error.email === "" ? (
                      " "
                    ) : (
                      <img src={errorIcon} alt="" className="w-4 h-4" />
                    )}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.email === "null" || error.email === ""
                        ? null
                        : error.email}
                    </p>
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="">Numéro de téléphone</label>
                    <input
                      type="phone"
                      className="block w-full bg-transparent border-none focus:outline-none"
                      placeholder="Ex : +33 7 55 27 96 87"
                      value={form.phone}
                      onChange={(e) =>
                        setForm({
                          name: form.name,
                          firstName: form.firstName,
                          phone: e.target.value,
                          email: form.email,
                          password: form.password,
                          cPassword: form.cPassword,
                        })
                      }
                    />
                  </div>
                  <div className="flex w-full mx-auto -mt-3 space-x-2 translate-y-4 ">
                    {error.phone === "null" || error.phone === "" ? (
                      " "
                    ) : (
                      <img src={errorIcon} alt="" className="w-4 h-4" />
                    )}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.phone === "null" || error.phone === ""
                        ? null
                        : error.phone}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-y-4 gap-x-2">
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="" className="md:truncate">
                      Mot de passe
                    </label>
                    <div className="flex">
                      <input
                        type={passwordShown ? "text" : "password"}
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="**********"
                        onChange={(e) =>
                          setForm({
                            name: form.name,
                            firstName: form.firstName,
                            phone: form.phone,
                            email: form.email,
                            password: e.target.value,
                            cPassword: form.cPassword,
                          })
                        }
                      />
                      <div className="m-auto">
                        {passwordShown ? (
                          <VisibilityIcon
                            onClick={togglePasswordVisiblity}
                            className="text-normal"
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={togglePasswordVisiblity}
                            className="text-normal"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full px-3 py-2 bg-white rounded-md">
                    <label htmlFor="" className="md:truncate">
                      Confirmer mot de passe
                    </label>
                    <div className="flex">
                      <input
                        type={passwordShown2 ? "text" : "password"}
                        className="block w-full bg-transparent border-none focus:outline-none"
                        placeholder="**********"
                        onChange={(e) =>
                          setForm({
                            name: form.name,
                            firstName: form.firstName,
                            phone: form.phone,
                            email: form.email,
                            password: form.password,
                            cPassword: e.target.value,
                          })
                        }
                      />
                      <div className="m-auto">
                        {passwordShown2 ? (
                          <VisibilityIcon
                            onClick={togglePasswordVisiblity2}
                            className="text-normal"
                          />
                        ) : (
                          <VisibilityOffIcon
                            onClick={togglePasswordVisiblity2}
                            className="text-normal"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full mx-auto space-x-2 -translate-y-2 ">
                {error.password === "null" || error.password === "" ? (
                  " "
                ) : (
                  <img src={errorIcon} alt="" className="w-4 h-4" />
                )}
                <p className="text-[#FF724F] h-6 w-full text-sm ">
                  {error.password === "null" || error.password === ""
                    ? null
                    : error.password}
                </p>
              </div>
              <div className="hidden text-lg font-bold //md:-mt-1 md:-translate-y-4 md:flex translate-y-28 ">
                <button
                  type="submit"
                  onClick={(e) => handleSubmit(e)}
                  className="flex my-4 bg-[#219CFF] text-sm  rounded-full py-2 text-white w-full  items-center justify-center gap-x-3 no-underline uppercase px-4"
                >
                  Valider votre inscription
                </button>
              </div>
            </div>
          </div>
          <div className="md:w-2/3 md:translate-x-4 lg:translate-x-20 xl:-translate-y-32 md:translate-y-4">
            <div className="hidden md:flex xl:mt-4 lg:-mt-14">
              <img
                className="md:h-[85%] md:w-[105%]"
                src={FirstImage}
                alt="downloadimage"
              />
            </div>
            <div className="w-full md:-ml-16 md:flex">
              <div className="mt-8  md:-mt-6 md:w-1/2">
                {/* -translate-y-16 lg:-translate-y-6 md:translate-y-4 md:-ml- lg:ml-0 -ml-20 */}
                {/* -translate-y-24 font-nunito  -ml-20 md:-ml- md:translate-y-8 lg:ml-0 lg:-translate-y-16 */}
                <div className="space-y-6 ">
                  <FormControlLabel
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      // marginLeft: "4rem",
                      fontFamily: "Nunito",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                            fontFamily: "Nunito",
                          },
                        }}
                        onClick={() => setTerms(!terms)}
                      />
                    }
                    label={
                      <Typography style={{ fontFamily: "nunito" }}>
                        {" "}
                        <a
                          target={"_blank"}
                          href="https://www.teachr.fr/policy"
                          className="text-white underline"
                          rel="noreferrer"
                        >
                          J’accepte la politique des données.
                        </a>{" "}
                      </Typography>
                    }
                  />

                  <div className="flex ml-4 space-x-2 -translate-y-6 ">
                    {error.terms === "null" || error.terms === "" ? (
                      " "
                    ) : (
                      <img src={errorIcon} alt="" className="w-4 h-4" />
                    )}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.terms === "null" || error.terms === ""
                        ? null
                        : error.terms}
                    </p>
                  </div>
                </div>
                <div className="-mt-10">
                  <FormControlLabel
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      //marginLeft: "4rem",
                      fontFamily: "nunito",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                            fontFamily: "nunito",
                          },
                        }}
                        onClick={() => setTermsTeachr(!termsTeachr)}
                      />
                    }
                    label={
                      <Typography style={{ fontFamily: "nunito" }}>
                        {" "}
                        <a
                          target={"_blank"}
                          href="https://www.teachr.fr/cgu_cgv"
                          className="text-white underline"
                          rel="noreferrer"
                        >
                          J'accepte les CGUs et CGVs.
                        </a>
                      </Typography>
                    }
                  />

                  <div className="flex ml-4 space-x-2 -translate-y-2 ">
                    {error.termsTeachr === "null" ||
                    error.termsTeachr === "" ? (
                      " "
                    ) : (
                      <img src={errorIcon} alt="" className="w-4 h-4" />
                    )}
                    <p className="text-[#FF724F] h-6 w-full text-sm ">
                      {error.termsTeachr === "null" || error.termsTeachr === ""
                        ? null
                        : error.termsTeachr}
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-3  md:-mt-6 md:w-1/2">
                {/* -translate-y-16 lg:-translate-y-6 md:translate-y-4 md:-ml- lg:ml-0 -ml-20 */}
                {/* -translate-y-24 font-nunito  -ml-20 md:-ml- md:translate-y-8 lg:ml-0 lg:-translate-y-16 */}
                <div className="space-y-6 ">
                  <FormControlLabel
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      // marginLeft: "4rem",
                      fontFamily: "Nunito",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                            fontFamily: "Nunito",
                          },
                        }}
                        onClick={() => setTermsMarket(!termsMarket)}
                      />
                    }
                    label={
                      <Typography style={{ fontFamily: "nunito" }}>
                        Rester connecté
                      </Typography>
                    }
                  />
                </div>
                <div className="-mt-10">
                  <FormControlLabel
                    sx={{
                      color: "white",
                      fontSize: "24px",
                      //marginLeft: "4rem",
                      fontFamily: "nunito",
                    }}
                    control={
                      <Checkbox
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "white",
                            fontFamily: "nunito",
                          },
                        }}
                        onClick={() => setRemember(!remember)}
                      />
                    }
                    label={
                      <Typography style={{ fontFamily: "nunito" }}>
                        J'accepte recevoir des offres promotionnelles de la part
                        de Teach'r
                      </Typography>
                    }
                  />
                </div>
              </div>
            </div>
            <div className="block w-4/5 pb-4 mx-auto text-lg font-bold -translate-x-5 md:-mt-4 //translate-y-28 md:hidden ">
              <button
                type="submit"
                onClick={(e) => handleSubmit(e)}
                className="flex my-4 bg-[#219CFF] text-sm  rounded-full py-2 text-white w-full  items-center justify-center gap-x-3 no-underline uppercase px-4"
              >
                Valider votre inscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
