import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import NavBar from '../../../Components/Navbar/NavBar'
import validateEmail from '../../../Validation/Email'
import validatePassword from '../../../Validation/Password'
import googleIcon from '../../../Assets/Icons/google.svg'
import appleIcon from '../../../Assets/Icons/apple.svg'
import facebookIcon from '../../../Assets/Icons/facebook.svg'
import SmsIcon from '../../../Assets/Icons/sms.svg';
import ClearIcon from '@mui/icons-material/Clear';
import { auth, FirebaseAuthServiceErrorCode, getJWTToken, loginWithPhoneNumber, sendPasswordReset, signInWithApple, signInWithFacebook, signInWithGoogle } from "../../../Services/FirebaseService";
import { useAuthState } from "react-firebase-hooks/auth";
import { ConfirmationResult, getAuth, browserSessionPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import ApiService from '../../../Services/ApiService'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { validatePhoneNumber } from '../../../Validations/Phone'
import Code from './Phone/code'
import TeachrRepository from '../../../Repositories/TeachrRepository'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ClientRepository from '../../../Repositories/ClientRepository'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import StepperStore from '../../../LocalForage/StepperStore'
// import Password from 'antd/lib/input/Password'
import Functions from '../../../Helpers/Functions'
// import Stepper from '../../../Components/utils/Stepper'

function Login() {

    const MySwal = withReactContent(Swal)
    let timerInterval: any
    const navigate = useNavigate()
    let restEmail = ''
    let phone = ''
    const [user] = useAuthState(auth)
    const [remember, setRemember] = useState(false)
    useEffect(() => {
        if (user) {
            (async () => {
                Swal.close()
                const jwtToken = await getJWTToken(user)
                ApiService.enableAuth(undefined, undefined, "teachr " + jwtToken)
                TeachrRepository.getCurrentTeachr().then(() => navigate("/teachr/cours")).catch(() => {
                    ApiService.disableAuth()
                ApiService.enableAuth(undefined, undefined, "client " + jwtToken);
                ClientRepository.getCurrentClient().then((response) => {
                    navigate("/user/response")
                }).catch(() => {
                    ApiService.disableAuth()
                    navigate("/user/completeprofile")
                })
                })
            })()
        }
    }, [navigate, user]);
    const [showPass, setShowPass] = useState(false)
    const [form, setForm] = useState({
        email: '',
        password: ''
    })
    const [errors, setErrors] = useState({
        'email': '',
        'password': ''
    })
    const validate = () => {
        let erremail = true
        let errpass = true
        if (!validateEmail(form.email).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            erremail = false
        }
        if (!validatePassword(form.password, form.password).isValid) {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = true
        } else {
            setErrors({
                'email': validateEmail(form.email).message,
                'password': validatePassword(form.password, form.password).message,
            })
            errpass = false
        }


        if (errpass || erremail)
            return false
        else return true
    }
    const login = async () => {
        const auth = getAuth()
        signInWithEmailAndPassword(auth, form.email, form.password).then((res) => {
        }).catch((error) => {
            switch (error.code) {
                case FirebaseAuthServiceErrorCode.USER_NOT_FOUND:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.WRONG_PASSWORD:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Email ou mot de passe incorrect!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                case FirebaseAuthServiceErrorCode.USER_DISABLED:
                    Swal.fire({
                        icon: 'warning',
                        title: 'Oops...',
                        text: 'Votre compte a été désactié!',
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
                default:
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: "quelque chose s'est mal passé!",
                        timer: 2000,
                        willClose: () => {
                            clearInterval(timerInterval)
                        }
                    })
                    break;
            }
        })
    }
    const handleSubmit = (event: any) => {
        // if(event.which === 13){
        event.preventDefault()
        if (validate()) {
            // auth.setPersistence(remember ? indexedDBLocalPersistence : browserSessionPersistence).then(() =>
            // )
            if (remember) {
                login()
            } else {
                auth.setPersistence(browserSessionPersistence).then(() => {
                    login()
                })
            }
        }

        // }

    }
    const setPhoneNumber = () => {
        Swal.clickConfirm()
        StepperStore.setItem('loginByPhone', phone)
    }
    const showPhoneLoginWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            customClass: {
                container: 'm-0'
            },
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Téléphone</label>
                            <input type="text" onChange={(e) => phone = e.target.value} className='block w-full bg-transparent border-none focus:outline-none' placeholder='Ex. 06 XX XX XX XX' />
                        </div>
                        <Button onClick={() => setPhoneNumber()} className='w-full px-8 py-2 mx-auto my-8 text-sm uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first whitespace-nowrap md:text-base' >ENVOYER LE CODE DE VERIFICATION</Button>
                    </div>
                </div>,
            preConfirm: (confirmationCode) => {
                if (validatePhoneNumber(phone) === '') {
                    return loginWithPhoneNumber(Functions.formatPhoneNumber(phone)).then((response: any) => {
                        return response
                    }).catch((error: any) => {
                        Swal.showValidationMessage("Nous rencontrons actuellement un problème technique. Veuillez re-essaayer plus tard ou nous contacter par mail à l'adresse support@teachr.fr")
                    })

                } else {
                    return Swal.showValidationMessage(validatePhoneNumber(phone))
                }

            },
        }).then((result) => {
            if (result.isConfirmed) {
                showPhoneCodeWidget(result.value!)
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showPhoneCodeWidget = (code: ConfirmationResult) => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>CONNEXION VIA SMS</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <Code phone={phone} code={code} />
                    </div>
                </div>
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[602px]')
        MySwal.getPopup()?.classList.add('md:p-4')
    }
    const showResetPasswordWidget = () => {
        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: true,
            html:
                <div>
                    <div className='flex items-center justify-between text-xl font-extrabold uppercase text-first'>
                        <p>MOT DE PASSE OUBLIé</p>
                        <ClearIcon onClick={() => Swal.close()} className='font-extrabold cursor-pointer' />
                    </div>
                    <div className="flex flex-col gap-8 py-8 text-left">
                        <p className='font-semibold text-first text-[20px]'>Entrez l’adresse mail associée à votre compte.
                            Vous allez recevoir un lien pour réinitialiser votre mot de passe </p>
                        {errors.email === '' ? null : <p className='py-2 font-bold text-red-600'>{errors.email}</p>}
                        <div className='px-3 py-2 rounded-md bg-bg-input-light'>
                            <label htmlFor="">Email</label>
                            <input onChange={(e) => restEmail = e.target.value} type="text" className='block w-full bg-transparent border-none focus:outline-none' placeholder='mail@mail.com' />
                        </div>
                        <Button onClick={() => { Swal.enableInput(); Swal.clickConfirm() }} className='block px-8 py-2 my-8 uppercase bg-transparent border-2 border-solid rounded-full md:px-20 text-first border-first w-max' >envoyer le lien</Button>
                    </div>
                </div>,
            preConfirm: () => {
                if (validateEmail(restEmail).isValid) {
                    return sendPasswordReset(restEmail).then((res) => {
                    })
                        .catch((error) => {
                            if (error.code === FirebaseAuthServiceErrorCode.USER_NOT_FOUND) {
                                Swal.showValidationMessage('Aucun compte associé à cette adresse mail')
                            }
                        })
                }
                Swal.showValidationMessage(validateEmail(restEmail).message)
            },
        }).then((result) => {

            if (result.isConfirmed) {

                Swal.fire({
                    icon: 'success',
                    title: 'Envoyé!',
                    text: "Nous vous avons envoyé un e-mail de réinitialisation du mot de passe. Veuillez consulter votre boîte e-mail",
                    timer: 5000,
                    showConfirmButton: false,
                    willClose: () => {
                        clearInterval(timerInterval)
                    }
                })
            }
        })
        MySwal.getPopup()?.classList.add('shadow-account')
        MySwal.getPopup()?.classList.add('rounded-2xl')
        MySwal.getPopup()?.classList.add('w-[701px]')
        MySwal.getPopup()?.classList.add('p-4')
    }
    return (
        <div className='h-screen //overflow-hidden'>
            <NavBar theme="light" />
            <div className="flex md:w-full mx-auto md:mx- lg:gap-8 lg:pl-[140px] px-4 md:px-0 md:pl-8 ">
                <div className='w-full md:w-1/2'>
                    <p className='pb-4 text-lg font-bold xl:text-3xl lg:text-2xl md:text-xl text-first font-nunito'>CONNEXION</p>
                    <div className="flex flex-col gap-y-1 md:gap-y- md:w-4/5">
                        <div className='w-full p-2 text-sm rounded-md md:p-4 md:py-2 bg-bg-input-light lg:text-lg md:text-base'>
                            <label htmlFor="">Adresse email</label>
                            <input placeholder='Ex : exemple@exemple.com' className='block w-full bg-transparent border-none focus:outline-none' onChange={(e: any) => setForm({ email: e.target.value, password: form.password })} />
                        </div>
                        <div className='py-2 -mt-2 text-[#FF724F]'>
                            {errors.email === '' ? null : <p >{errors.email}</p>}
                        </div>
                        <div>

                            <div className='relative w-full p-2 text-sm rounded-md md:p-4 md:py-2 bg-bg-input-light lg:text-lg md:text-base'>
                                <label htmlFor="">Mot de passe</label>
                                <input type={showPass ? "text" : 'password'} className='block w-full bg-transparent border-none focus:outline-none' onChange={(e: any) => setForm({ email: form.email, password: e.target.value })} placeholder='********' />
                                <span className='absolute cursor-pointer top-1/3 right-3' onClick={() => setShowPass(!showPass)}>{showPass ? < VisibilityIcon className='text-normal' /> : <VisibilityOffIcon className='text-normal' />}</span>
                            </div>

                            <div className='py-2 text-[#FF724F]'>
                                {errors.email === '' ? null : <p >{errors.password}</p>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <FormControlLabel
                            sx={{
                                color: "gray    ",
                                fontSize: "24px",
                                // marginLeft: "4rem",
                                fontFamily: "Nunito"
                            }}
                            control={
                                <Checkbox
                                    sx={{
                                        color: "gray    ",
                                        "&.Mui-checked": {
                                            color: "gray  ",
                                            fontFamily: "Nunito",
                                        },
                                    }}
                                    onClick={() => setRemember(!remember)}
                                />
                            }
                            label={<Typography style={{ fontFamily: 'nunito' }}>Rester connecté </Typography>}
                        />
                    </div>
                    <div className='pt-4'>
                        <p className='text-sm text-grey lg:text-lg md:text-base'>Mot de passe oublié ? <Link to='#' onClick={() => showResetPasswordWidget()} className='ml-2 font-bold underline text-first'>Cliquez ici</Link></p>
                    </div>
                    <div className='w-4/5 mx-auto md:mx-0 md:w-3/4 text-[#808080] md:pt-4 py-4'>
                        <div className="flex items-center justify-between gap-4 text-sm text-grey lg:text-lg md:text-base"><hr className='w-full' /><span className='w-full whitespace-nowrap'>Ou se connecter avec:</span><hr className='w-full' /></div>
                        <div className="flex justify-center gap-8 pt-4 md:gap-14">
                            <img src={googleIcon} onClick={signInWithGoogle} alt="" className='w-[43px] cursor-pointer' />
                            <img src={appleIcon} onClick={signInWithApple} alt="" className='w-[43px] cursor-pointer' />
                            <img src={facebookIcon} onClick={signInWithFacebook} alt="" className='w-[43px] cursor-pointer' />
                            <img src={SmsIcon} alt="" onClick={() => showPhoneLoginWidget()} className='w-[43px] cursor-pointer' />
                        </div>
                    </div>
                    <div className='w-4/5 mx-auto -ml-1 md:-ml-5 md:pt-'>
                        <div className="flex justify-between gap-x-4">
                            <div className='w-4/5 md:w-full'>
                                <label htmlFor="" className='block pb-2 text-sm font-bold text-first lg:text-md whitespace-nowrap'>Vous n'avez pas de compte ?</label>
                                <Link to='/user/signup' className=' bg-first block text-center rounded-full py-2 border-solid border-[1px] border-first text-white font-extrabold  uppercase px-4 w-full' >JE M'INSCRIS</Link>
                            </div>
                            <div className='self-end w-4/5 md:w-full'>
                                <Button className=' bg-transparent rounded-full py-2 text-first  border-solid border-[1px] border-first  uppercase px-8 w-full font-extrabold font-nunito' onClick={(e) => handleSubmit(e)} >CONNEXION</Button>
                            </div>

                        </div>

                        {/* <div className=' text-radio items-center lg:text-[21px] font-semibold py-8'>
                            <Link to="" className='underline'>Continuer en tant que visiteur <VisibilityIcon /></Link>

                        </div> */}
                    </div>
                </div>
                <div className='hidden w-1/2 h-screen bg-left-top bg-no-repeat md:block bg-login-il'>
                </div>
            </div>

            <div id="recaptcha-container"></div>
        </div>
    )
}

export default Login