import React, { useEffect, useRef } from 'react';
import ReactApexChart from 'react-apexcharts';

const ComponentYearPriceChart = (props) => {
    const { durationByYear, durationByYear2, durationByYear3, durationByYear4, priceYear2, priceYear, priceYear3 } = props

    const [chartData, setChartData] = React.useState({ series: [], options: {} });
    React.useEffect(() => {
    const dataRegle = [
        durationByYear["2023"],
        durationByYear["2024"],
        durationByYear["2025"],
        durationByYear["2026"],
    ]
    const dataEnCours = [
        durationByYear2["2023"],
        durationByYear2["2024"],
        durationByYear2["2025"],
        durationByYear2["2026"]
    ]
    const dataError = [
        durationByYear3["2023"],
        durationByYear3["2024"],
        durationByYear3["2025"],
        durationByYear3["2026"]
    ]
    const chartOptions = {
        series: [
            {
                name: 'En cours',
                data: dataEnCours
            },
            {
                name: 'Réglés',
                data: dataRegle
            },
            // {
            //     name: 'Recouverte',
            //     data: dataRecover
            // },
            {
                name: 'Réfusés',
                data: dataError
            },

        ],
        chart: {
            height: 350,
            type: 'area',
            toolbar: {
                show: false, // Définir à true si vous souhaitez afficher la barre d'outils, false pour la masquer
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'string',
            categories: [ "2023", "2024", "2025", "2026"]
        },
        yaxis: {
            title: {
                // text: "Rémunération horaire NET d'impôts",
                style: {
                    color: "#4D4D4D",
                    fontFamily: "nunito",
                    fontSize: "14px",
                    fontWeight: 600
                },
                // offsetX: -11,
            },
            min: 0,
            // max: yaxisMax,
            tickAmount: 10,
            labels: {
                show: true,
                formatter: function (value) {
                    return value.toFixed(2) + '€'; // Formatage avec des séparateurs de milliers
                },
                style: {
                    colors: "#808080",
                    fontSize: '12px',
                    fontFamily: 'nunito',
                    fontWeight: 400,
                },
            },
        },
        grid: {
            position: 'back',
            borderColor: 'rgba(0,0,0,0.22)',
            strokeDashArray: 5,
            xaxis: {
                lines: {
                    show: true,
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm'
            },
        },
        legend: {
            position: 'top',
            formatter: function (seriesName, value) {
                if (seriesName === 'En cours') {
                    return seriesName + ' (' + priceYear2 + '€)';
                } else if (seriesName === 'Réglés') {
                    return seriesName + ' (' + priceYear + '€)';
                } 
                else if (seriesName === 'Réfusés') 
                    return seriesName + ' (' + priceYear3 + '€)';
            }
        }
    };
        setChartData({ ...chartData, series: chartOptions.series, options: chartOptions });
    }, [durationByYear, durationByYear2, durationByYear3, priceYear, priceYear2, priceYear3])

    return (
        <div className = 'h-full w-full ' id = "chart">
            <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} className="w-full md:border-none rounded-lg border-first border-2 md:mt-0 md:shadow-none shadow-lg mt-4" />
        </div>
    );
};

export default ComponentYearPriceChart;