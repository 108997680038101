import moment from 'moment';
import { useEffect, useState } from 'react';
import SubjectRepository from '../../../../../../../Repositories/SubjectRepository';
import { Subject } from '../../../../../../../Objects/models/Subject';
import ChildRepository from '../../../../../../../Repositories/ChildRepository';
import Child from '../../../../../../../Objects/models/Child';
import Functions from '../../../../../../../Helpers/Functions';
import SubscriptionCourseProposalRepository from '../../../../../../../Repositories/SubscriptionCourseProposalRepositorys';
import TeachrRepository from '../../../../../../../Repositories/TeachrRepository';
import URSSAFParentRepository from '../../../../../../../Repositories/URSSAFParentRepository';
import * as React from 'react';
import { LightTooltipCours, LightTooltipNoError, LightTooltipPaye } from '../../../UtilsDashboard/FunctionsDashbord';


const ComponentBodyTableSubscriptionPrevu = (props: any) => {
    const { course } = props
    const [subject, setSubject] = useState<Subject>()
    const [proposal, setProposal] = useState<any>()
    const [urssafStatus, setUrssafStatus] = useState<any>()
    const [child, setChild] = useState<Child>()
    const today = moment(course?.startDateOccurrence);
    // const yesterday = today.clone().subtract(1, 'days');


    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((teachr) => {
            if (teachr.id) {

                SubjectRepository.fetchSubjectById(course?.subjectAsked_id).then((subject) => {
                    setSubject(subject)
                })
                ChildRepository.fetchChildByIdForTeachr(course.forChild_id, teachr.id).then((child) => {
                    setChild(child)
                })
                SubscriptionCourseProposalRepository.fetchSubscriptionCourseProposalByIdTeachr(teachr.id, course?.acceptedProposal_id).then((res) => {
                    setProposal(res)
                })
                if (course.invoiceNumber !== null) {
                    URSSAFParentRepository.fectSubscriptionUrssafStatus(course.invoiceNumber).then((res) => {
                        setUrssafStatus(res);
                    })
                }
            }
        })
    }, [course])



    return (
        <>
            {
                course?.resumesAt == null && course.invoiceNumber === null &&
                <tbody className="text-[#4D4D4D] font-bold">
                    <tr className="bg-white">
                        <td className="py-2 px-1 hidden lg:flex">{today.format('dddd DD/MM/YYYY')}</td>
                        <td className="py-2 px-1 lg:hidden">{today.format('DD/MM/YY')}</td>
                        <td className="py-2 px-1">{child?.firstName}</td>
                        <td className="py-2 px-1">{subject?.fr_FR}</td> <td className="py-2 px- text-center //flex justify-around">
                            <div>
                                {proposal?.teachrPrice}€
                            </div>
                        </td>
                        <td className="py-2 px-1"><div className="border-b-2 w-5 mx-auto //-translate-y-2" ></div></td>
                        <td className="py-2 px- text-center //flex justify-around">
                            <div>
                                {proposal?.totalDailyPrice}€
                            </div>
                        </td>
                        <td className="py-2 px-1 text-center //text-center">{Functions.renderDuration(course?.durationPerDay)}</td>
                        <td className="py-2 px-2 text-center">
                            {course.invoiceNumber === null ?
                                <div className="text-center rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                    <LightTooltipCours title="Votre cours est prévu">
                                        <div className="text-center w-full">  Cours prévu</div>
                                    </LightTooltipCours>
                                </div>
                                : urssafStatus?.status === "INTEGRATED" || urssafStatus?.status === "WAITING_FOR_VALIDATION" || urssafStatus?.status === "10"  ?
                                    <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                        <LightTooltipCours title={urssafStatus?.status === "INTEGRATED" ? "La demande de paiement a été reçue et a passé les différents contrôle de cohérence" : "Le particulier a été prévenu que la demande de paiement est à valider/rejeter par lui"}>
                                            <div className="text-center w-full ">En cours</div>
                                        </LightTooltipCours>
                                    </div>
                                    : urssafStatus?.status === 'VALIDATED' ?
                                        <div className="text-center w-full rounded-full cursor-pointer bg-[#E8FFEF] text-[#3D8E67] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                            <LightTooltipPaye title={urssafStatus?.statusDescription}>
                                                <div className="text-center w-full">Validé</div>
                                            </LightTooltipPaye>
                                        </div>
                                        : urssafStatus?.status === 'PAID' ?
                                            <div className="text-center w-full rounded-full cursor-pointer bg-[#E8FFEF] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                <LightTooltipPaye title="Le virement de paiement de la demande de paiement au prestataire est lancé ">
                                                    <div className="text-center w-full">Payé</div>
                                                </LightTooltipPaye>
                                            </div>
                                            : urssafStatus?.status === 'COLLECTED' ?
                                                <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                    <LightTooltipPaye title="La demande de paiement a été payée avec succès par le particulier">
                                                        <div className="text-center w-full">Payé</div>
                                                    </LightTooltipPaye>
                                                </div>
                                                : urssafStatus?.status === 'COVERED' ?
                                                    <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                        <LightTooltipPaye title="La demande de paiement en impayé a été recouvrée par le prestataire">
                                                            <div className="text-center w-full">Récouvré</div>
                                                        </LightTooltipPaye>
                                                    </div>
                                                    : urssafStatus?.status === 'CANCELED' || urssafStatus?.status === 'CANCELED 111' || urssafStatus?.status === 'CANCELED 112' ?
                                                        <div className="text-center w-full rounded-full cursor-pointer bg-[#F4F4F8] text-[#4d4d4d] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                            <LightTooltipNoError title={urssafStatus?.status === 'CANCELED' ? "La demande de paiement a été annulée par le particulier" : urssafStatus?.status === 'CANCELED 111' ? "La demande de paiement a été annulée après impayé du particulier" : 'La demande de paiement a été annulée après recouvrement par le prestataire'}>
                                                                <div className="text-center w-full">Annulé</div>
                                                            </LightTooltipNoError>
                                                        </div>
                                                        : urssafStatus?.status === 'REFUSED' || urssafStatus?.status === 'REFUSAL_OF_DIRECT_DEBIT' ?
                                                            <div className="text-center w-full rounded-full cursor-pointer bg-[#FFE0D9] text-[#FF724F] ] font-bold  //mx-auto px-[6px] py-[6px] flex space-x-2">
                                                                <LightTooltipNoError title={urssafStatus?.status === 'REFUSED' ? "La demande de paiement est refusée par le particulier : il a renseigné son rejet et un code motif associé" : "Le prélèvement associé à la demande de paiement est revenu en erreur . La demande de paiement est en impayée"}>

                                                                    <div className="text-center w-full">Refusé</div>
                                                                </LightTooltipNoError>
                                                            </div>
                                                            :
                                                            <div className=" text-center w-full rounded-full cursor-pointer bg-[#FFE0D9] text-[#FF724F] font-bold  //mx-auto px-[6px] py-[6px] //w-1/2 flex space-x-2">
                                                                <LightTooltipNoError title={urssafStatus?.error[0].message}>
                                                                    <div className="text-center w-full">
                                                                        Erreur
                                                                    </div>
                                                                </LightTooltipNoError>
                                                            </div>}
                        </td>
                    </tr>
                </tbody>
            }
        </>
    );
};

export default ComponentBodyTableSubscriptionPrevu;