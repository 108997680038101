import ApiService from "../Services/ApiService";
import { SubscriptionCourseProposal } from "../Objects/models/SubscriptionCourseProposal";
import { plainToClass } from "class-transformer";

export default abstract class SubscriptionCourseProposalRepository {

    /**
     * Creates an instance of SubscriptionPack into the API.
     * @param coursePack
     */
    public static createSubscriptionCourseProposal(subCourseProposal: SubscriptionCourseProposal,teachrId? : number,courseId?: number) {
        return ApiService.post(`/teachrs/v2/teachrs/${teachrId}/subscription_courses/${courseId}/proposals`, subCourseProposal);
    }

    public static getAllPendingSubscriptionCourseProposals(teachrId: number) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/pending_subscription_course_proposals`)
            .then((body:any) => plainToClass(SubscriptionCourseProposal, body));
    }

    public static getAllProposalToGivenSubCourse(clientId: number, courseId: number) {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_courses/${courseId}/proposals`)
            .then((body:any) => plainToClass(SubscriptionCourseProposal, body));
        ;
    }

    public static fetchSubscriptionCourseProposalByIdClient(clientId: number,proposalId: number ) {
        return ApiService.get(`/clients/v2/clients/${clientId}/subscription_course_proposals/${proposalId}`);
    }

    public static fetchSubscriptionCourseProposalByIdTeachr(teachrId: number,proposalId: number ) {
        return ApiService.get(`/teachrs/v2/teachrs/${teachrId}/subscription_course_proposals/${proposalId}`);
    }

    /**
     * refuse a subCourseProposal.
     */
    public static refuseSubCourseProposal(clientId: number, subCourseProposalId: number) {
        return ApiService.put(`/clients/v2/clients/${clientId}/subscription_courses_proposals/${subCourseProposalId}/refused`);
    }

    /**
     * Try to accept a subCourseProposal (payment process).
     */
    public static async tryAcceptSubCourseProposal(clientId: number, subCourseProposalId: number) {
        return ApiService.put(
            `/clients/v2/clients/${clientId}/subscription_courses_proposals/${subCourseProposalId}/try-accept`
        );
    }

    public static async acceptSubCourseProposalAfter3dSecure(clientId: number, subCourseProposalId: number) {
        return ApiService.put(`/clients/v2/clients/${clientId}/subscription_course_proposals/${subCourseProposalId}/accept-after-3d-secure`);
    }

    public static deleteSubscriptionCourseProposal(teachrId: number, subCourseProposalId: number) {
        return ApiService.deleteInstance(
            `/teachrs/v2/teachrs/${teachrId}/subscription_courses_proposals/${subCourseProposalId}`
        );
    }


}
