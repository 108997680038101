import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const AddressComponent = (props) => {
  const [editMode, setEditMode] = useState(false)
  useEffect(() => {
    if (props.default) {
      setEditMode(true)
    }
  }, [props.default])
  const renderScreen = () => {
    if (editMode) {
      return (
        <div className="flex justify-between">
          <div>
            <p>{props.default}</p>
          </div>
          <div onClick={() => { setEditMode(false) }} className={`cursor-pointer`}>
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.72152 0C14.6972 0 18.7344 4.03714 18.7344 8.98714C18.7344 13.9629 14.6972 18 9.72152 18C4.77152 18 0.734375 13.9629 0.734375 8.98714C0.734375 4.03714 4.77152 0 9.72152 0ZM9.72152 7.49571L11.8815 5.36143C12.8587 4.37143 14.3501 5.86286 13.3858 6.86572L11.2258 8.98714L13.3858 11.1471C14.3629 12.1243 12.8587 13.6157 11.8815 12.6514L9.72152 10.4914L7.58723 12.6514C6.59723 13.6286 5.11866 12.1243 6.08295 11.1471L8.21723 8.98714L6.08295 6.85286C5.09295 5.86286 6.58438 4.35857 7.58723 5.34857L9.72152 7.49571Z" fill="#808080" />
            </svg>

          </div>
        </div>
      )
    }
    return (
      <GooglePlacesAutocomplete
        apiKey="AIzaSyANSjx8ZFHJequpxZ4vyxnXLyTqv90mI2U"
        backspaceRemovesValue
        apiOptions={{ language: "fr", region: "fr" }}
        autocompletionRequest={{}}
        searchOptions={
          {
            types: ['address'], // Limitez la saisie semi-automatique aux adresses uniquement
          }
        }
        selectProps={{
          isClearable: true,
          placeholder: props.placeholder ? props.placeholder : "Ex : 22 rue auber, Paris",
          className: " border-none focus:outline-none block bg-transparent w-full font-normal h-max",
          // value: address.formatted_address,
          onChange: (search) => {
            props.getPlaceId(search.value.place_id)
          },

          styles: {
            input: (provided) => ({
              ...provided,
              padding: '0',
            }),
            dropdownIndicator: (provided) => ({
              ...provided,
              border: "none",
              color: "transparent",
              display: 'none',
            }),
            placeholder: (provided) => ({
              ...provided,
              border: "none",
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              border: "none",
              display: 'none',
              color: "white",
            }),
            noOptionsMessage: (provided) => ({
              ...provided,
              border: "none",
              color: "white",
            }),
            loadingMessage: (provided) => ({
              ...provided,
              border: "none",
              color: "white",
            }),
            menu: (provided) => ({
              ...provided,
              fontFamily: "Nunito",
            }),
            control: (provided, state) => ({
              ...provided,
              border: 0,
              minHeight: 0,
              height: '2%',
              // This line disable the blue border
              //fontSize:"16px",
              boxShadow: "none",
              backgroundColor: "none",
              fontFamily: "Nunito",
            }),
          },
        }}
      />
    )

  }
  return (
    <div>
      {renderScreen()}
    </div>
  );
};

export default AddressComponent;