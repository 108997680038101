let IBAN = require("iban");
const ValidateIban = (iban) => {
    if (!IBAN.isValid(iban)) {
      return {
        isValid: false,
        message: "Veuillez entrer un IBAN valide",
      };
    } else {
      const regex = /^FR/;
      if (regex.test(iban)) {
        return {
          isValid: true,
          message: "",
        };
      } else {
        return {
          isValid: false,
          message: "Veuillez entrer un IBAN français",
        };
      }
    }

   
};

export default ValidateIban;