import moment from 'moment';
const validateDateAici = (value: string) => {
  if (moment(value, 'L').isValid()) {
    const year = moment(value, 'L').year()
    if (year < 1900 || year > 2005) {
      return {
        isValid: false,
        message: "Vous devez être né entre 1901 et 2005",
      };
    }
    return {
      isValid: true,
      message: "",
    }
  } else {
    return {
      isValid: false,
      message: "Veuillez entrez une date valide",
    };
  }
};

export default validateDateAici;
