import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import close from "../../../Assets/Images/btn-close-orange.png";
// import { any } from "prop-types";
// import ClientRepository from "../../../Repositories/ClientRepository";

export default function Update(props: any) {
  const [open, setOpen] = React.useState(false);
  const { handleClick } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleClick = () => {
  //   (async () => {
  //     setLoading(true);
  //     const client = await ClientRepository.getCurrentClient();
  //     window.open(
  //       "mailto:support@teachr.fr?subject=User " +
  //         client.id +
  //         " - Modification de la demande de cours régulier " +
  //         course.id
  //     );
  //     handleClose();
  //   })();
  // };


  return (
    <div className="font-nunito">
      <span className="text-dark_blue " onClick={handleClickOpen}>
        <div className="border-[1px] md:p-2 p-1 md:text-[16px] text-xs text-center text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full">
          Modifier
        </div>
      </span>
      <Dialog open={open} onClose={handleClose}>
        <div className=" md:p-3 ">
          <DialogTitle>
            <div className="flex justify-between mb-3">
              <span className="text-dark_blue font-bold md:text-[22px] text-sm uppercase font-nunito">
                modifier ma demande
              </span>
              <img
                src={close}
                alt=""
                className="md:h-6 h-3 md:w-6 w-3 translate-y-1  "
                onClick={handleClose}
              />
            </div>
          </DialogTitle>
          <DialogContent>
            <p className="md:text-[20px] text-sm text-grey font-nunito lg:leading-9 ">
              Vous souhaitez modfier votre demande de cours ? Faites nous
              parvenir votre demande par mail en cliquant sur le bouton
              “MODIFIER”
            </p>
          </DialogContent>
          <DialogActions>
            <div className="flex w-full justify-around gap-x-8">
              <button
                onClick={() => handleClose()}
                className="border-[1px] w-1/2 md:p-2  p-1 md:text-xl text-sm text-orange border-orange active:text-white active:bg-orange uppercase font-semibold  rounded-full"
              >
                Retour
              </button>

              <button
                onClick={() => handleClick()}
                className="border-[1px] w-1/2 md:p-2  p-1 md:text-xl text-sm text-dark_blue px-3 border-dark_blue uppercase active:bg-dark_blue active:border-dark_blue active:text-white font-semibold  rounded-full"
              >
                Modifier
              </button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
