import { Route } from "../Objects/enums/RouteEnum";
import InterestedTeachr from "../Objects/models/InterestedTeachr";
import Simulator from "../Objects/models/Simulator";
import ApiService from "../Services/ApiService";
// import HydraCollection from "../Objects/models/HydraCollection";
// import { plainToClass } from "class-transformer";

interface SimulatorRequest {
    isAcre: boolean,
    hourlyPrice: number,
    weekHours: number,
    external_income: number,
    childs: number,
    last_income: number,
    adult: number,
    education: number
}
export default class InterestedTeachrRepository {
    /**
     * Creates an instance of Teachr into the API.
     * @param interestedTeachr
     */
    public static createInterestedTeachr(interestedTeachr: InterestedTeachr) {
        return ApiService.postInstance<InterestedTeachr>(
            Route.INTERESTEDTEACHR_BASE,
            InterestedTeachr,
            interestedTeachr
        );
    }
    public static updateInterestedTeachr(interestedTeachr: InterestedTeachr) {
        return ApiService.putInstance<InterestedTeachr>(
            Route.INTERESTEDTEACHR_BASE +'/' + interestedTeachr.id,
            InterestedTeachr,
            interestedTeachr
        );
    }
    public static simulate(object: SimulatorRequest) {
        return ApiService.postInstance<Simulator>(
            Route.INTERESTEDTEACHR_BASE +'/simulate',
            Simulator,
            object
        );
    }
    
}