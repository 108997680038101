

import * as React from 'react';
import { useEffect, useState } from "react";
import CoursePonctual from "./ActivesPunctuals/CoursePonctual";
import { useNavigate } from "react-router-dom";
import add from "../../../../../../Assets/Icons/add.svg";
import Teachr from "../../../../../../Objects/models/Teachr";
import TeachrRepository from "../../../../../../Repositories/TeachrRepository";
import TeachrShowInfo from "../../../../../../Objects/models/TeachrShowInfo";
import Teachr_Repository from "../../../../../../Repositories/Teachr_Repository";
import PopUpInfo from "../../UtilsDashboard/PopUpInfo";
import CoursePonctualPast from "./HistoricsPunctuals/CoursePonctualPast";
import { acceptCourse } from "../../UtilsDashboard/FunctionsDashbord";
import { Skeleton } from '@mui/material';
import ValidIban from '../../UtilsDashboard/ValidIban';
import StepCourseForAll from '../../StepDeclare/DeclareAllCourse/StepCourseForAll';
import config from '../../../../../../config';

const Index = () => {
    const mode = process.env.REACT_APP_BUILD_ENV === 'prod' ? false : true
    const [component, setComponent] = useState(1)
    const navigate = useNavigate()
    const [teachr, setTeachr] = useState<Teachr>()
    const [load, setLoad] = useState(false)
    const [open, setOpen] = useState(false);
    const [opens, setOpens] = useState(false);
    const [clientTiersPrestation, setClientTiersPrestation] = useState<any>();
    const handleOpen = () => {
        setOpens(true);
    };
    const handleCloses = () => {
        setOpens(false);
    };
    const handleNavigate = () => {
        if (teachr?.iban) {
            navigate("/teachr/dashboard/declare");
        } else {
            handleOpen();
        }
        // handleClose();
    };
    const handleNavigateManyCourse = () => {
        if (teachr?.iban) {
            setComponent(3);
        } else {
            handleOpen();
        }
        // handleClose();
    };
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        TeachrRepository.getCurrentTeachr().then((response) => {
            if (response && response.id) {
                setTeachr(response)
                TeachrRepository.get_client_by_teacher_favorite(response?.id).then(
                    (client: any) => {
                        setClientTiersPrestation(client.clients);
                    }
                );
            }
        })
    }, [load])
    const handleDeclareCourse = () => {
        const showInfo = new TeachrShowInfo(true)
        if (teachr?.id) {
            Teachr_Repository.teachrShowInfo(teachr?.id, showInfo).then((res) => {
                setLoad(!load)
                // acceptCourse(teachr, navigate)
                handleClose()
            })

        }
    }
    const handleChangeStep = (val: number) => {
        setComponent(val)
    }
    const showComponentPunctualCourse = (comp: number) => {
        switch (comp) {
            case 1: return <CoursePonctual />
            case 2: return <CoursePonctualPast />
            case 3: return <StepCourseForAll handleChangeStep={handleChangeStep} />;
            default: return <CoursePonctual />
        }
    }
    const showBarreCourse = () => {
        return (
            <div className="lg:flex pb-6 lg:pb-0  justify-between xl:text-xl lg:text-lg text-base font-extrabold ">
                <ValidIban handleOpen={handleOpen} handleCloses={handleCloses} opens={opens} teachr={teachr} />
                {!teachr ? <Skeleton variant="rectangular" width="100%" height="100%" /> :
                    clientTiersPrestation?.length === 0 || component === 3 ? <div></div> :
                        !teachr?.isShowInfos ?
                            <>
                                <div className="mb-4 md:flex items-center gap-x-4 justify-between">
                                    <div onClick={() => handleClickOpen()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2">
                                        <div>
                                            <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                        </div>
                                        <div className="text-dark_blue">
                                            <p>Déclarer un cours 📚‍</p>
                                        </div>
                                    </div>
                                    {/* {(!mode && (teachr?.id === 9807 || teachr?.id === 9713)) && <div onClick={() => handleClickOpen()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2">
                                        <div>
                                            <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                        </div>
                                        <div className="text-dark_blue">
                                            <p>Déclarer plusieurs cours 📚‍</p>
                                        </div>
                                    </div>}
                                    {mode &&  */}
                                    <div onClick={() => handleClickOpen()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2">
                                        <div>
                                            <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                        </div>
                                        <div className="text-dark_blue">
                                            <p>Déclarer plusieurs cours 📚‍</p>
                                        </div>
                                    </div>
                                    {/* } */}
                                </div>
                                <PopUpInfo handleDeclareCourse={handleDeclareCourse} teachr={teachr} navigate={navigate} handleClose={handleClose} open={open} />
                            </>
                            :
                            <div className="mb-4 md:flex items-center gap-x-4 justify-between">
                                <div onClick={() => handleNavigate()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2" >
                                    <div>
                                        <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                    </div>
                                    <div className="text-dark_blue">
                                        <p>Déclarer un cours 📚‍</p>
                                    </div>
                                </div>
                                {/* {(!mode && (teachr?.id === 9807 || teachr?.id === 9713)) && <div onClick={() => handleNavigateManyCourse()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2" >
                                    <div>
                                        <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                    </div>
                                    <div className="text-dark_blue">
                                        <p>Déclarer plusieurs cours 📚‍</p>
                                    </div>
                                </div>}
                                {mode && */}
                                 <div onClick={() => handleNavigateManyCourse()} className="cursor-pointer xl:text-xl border-b-2 border-first rounded-lg text-lg text-[#4d4d4d] font-extrabold flex items-center gap-x-2" >
                                    <div>
                                        <img src={add} alt="" className="lg:w-6 w-5 lg:mt-0 mt-2 text-dark_blue font-extrabold" />
                                    </div>
                                    <div className="text-dark_blue">
                                        <p>Déclarer plusieurs cours 📚‍</p>
                                    </div>
                                </div>
                                {/* } */}
                            </div>
                }
                {component !== 3 && <div className="gap-x-2 xl:w-1/4 lg:w-2/5 w-full flex  border-b-[2px] border-first rounded-lg bg-white shadow-lg ">
                    <div className={component === 2 ? "text-[#4D4D4D]  rounded-lg border-transparent cursor-pointer lg:w-full w-1/2 px-2 /py-1 lg:py-0 " : "bg-[#c7e4ff] text-first  rounded-lg border-transparent cursor-pointer lg:w-full w-1/2 px-2 /py-1 lg:py-0 "} onClick={() => setComponent(1)}>
                        <p className=" lg:pt-2 p-1 xl:text-lg text-base text-center ">Cours prévus</p>
                    </div>
                    <div className={component === 1 ? "text-[#4D4D4D]  rounded-lg border-transparent cursor-pointer lg:w-full w-1/2 px-2 /py-1 lg:py-0 " : "bg-[#c7e4ff] text-first  rounded-lg border-transparent cursor-pointer lg:w-full w-1/2 px-2 /py-1 lg:py-0 "} onClick={() => setComponent(2)}>
                        <p className=" lg:pt-2 p-1 xl:text-lg text-base text-center">Historique</p>
                    </div>
                </div>}
            </div>
        )
    }
    return (
        <div className="h-[100%] relative w-full">
            {showBarreCourse()}
            <div className="h-[100%]">
                {showComponentPunctualCourse(component)}
            </div>
        </div>
    );
};

export default Index;
