export default class TeachrRCS {

    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;

    RCS: string

    readonly id?: number;
    readonly lastModificationDatetime?: Date;
    readonly creationDatetime?: Date;

    constructor(
        RCS: string,
    ) {
        this.RCS = RCS;
    }

}
