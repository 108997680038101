import { Expose, Transform, Type } from "class-transformer";
import "reflect-metadata";
import HydraView from "./HydraView";

export default class HydraCollection<K> {
    readonly "@id"?: string;
    readonly "@type"?: string;
    readonly "@context"?: string;
    
    @Expose({ name: "hydra:member" })
    members!: K[];

    @Expose({ name: "hydra:totalItems" })
    readonly totalItems!: number;

    @Type(() => HydraView)
    @Expose({ name: "hydra:view" })
    readonly pages?: HydraView;
}
